import { gql } from "apollo-angular";

export const upsertBucketRoundingMutation = gql`
mutation UpsertBucketRounding($bucketRounding: bucket_rounding_insert_input!) {
  insert_bucket_rounding_one(object: $bucketRounding, on_conflict: {constraint: bucket_rounding_bucket_id_key, update_columns: [round_by, round_type]}) {
    round_type
    round_by
    id
    bucket_id
  }
}
`;
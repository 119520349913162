<div mat-dialog-title class="!flex items-center justify-between">
  <h2>{{ headerText }}</h2>
  <button
    mat-icon-button
    color="primary"
    [matDialogClose]="matDialogCloseValue"
  >
    <mat-icon>clear</mat-icon>
  </button>
</div>

import { DateTime } from 'luxon';

export function tomorrowMidnight(): DateTime {
  return DateTime.utc()
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .plus({ day: 1 });
}

export function tomorrowMidnightISO(): string {
  return DateTime.utc()
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .plus({ day: 1 })
    .toISO();
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  combineLatest,
  concat,
  EMPTY,
  forkJoin,
  from,
  of,
  throwError,
} from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  map,
  switchMap,
  tap,
  toArray,
} from 'rxjs/operators';

import { UserSelectors } from '@roadrunner/shared/data-access-user';
import { trpcClient } from '@roadrunner/shared/util-trpc';
import { DataService } from '../../apollo/data.service';
import { SwitchToBaseRatesGQL } from '../../apollo/mutations/update-base-rate-method/switch-to-base-rates.mutation.generated';
import { SwitchToBaseSlicesGQL } from '../../apollo/mutations/update-base-rate-method/switch-to-base-slices.mutation.generated';
import { UpsertBucketBaseRate } from '../../apollo/mutations/upsert-bucket-base-rates/upsert-bucket-base-rates.variable';
import { UpsertBucketRateException } from '../../apollo/mutations/upsert-bucket-rate-exceptions/upsert-bucket-rate-exceptions.variable';
import { GetBucketByIdGQL } from '../../apollo/queries/buckets/get-bucket-by-id/get-bucket-by-id.query.generated';
import { IOffsetBucket } from '../../apollo/queries/buckets/get-offset-bucket-list/get-offset-bucket-list.interface';
import { GetOffsetBucketListGQL } from '../../apollo/queries/buckets/get-offset-bucket-list/get-offset-bucket-list.query.generated';
import { GetProductParamsByBucketIdGQL } from '../../apollo/queries/buckets/get-product-param-by-bucket-id/get-product-param-by-bucket-id.query.generated';
import { GetOffsetBucketGQL } from '../../apollo/queries/get-offset-bucket/get-offset-bucket.query.generated';
import { ProductTypesGQL } from '../../apollo/queries/products/product-types/product-types.query.generated';
import { ProductTypeListItem } from '../../models/view-models/products/product-type.view-model';
import { batchRequests } from '../../shared/utility/batch-requests';
import { buildParameterFilters } from '../../shared/utility/parameter-filters';
import { selectProductParameters } from '../rate/rate.selectors';
import { GetPayeesGQL } from '../rate/services/get-payees.query.generated';
import { selectChosenProgram } from '../user/user.selectors';
import * as BucketActions from './bucket.actions';
import {
  selectAppliedBaseParameters,
  selectBaseParametersInSortOrder,
  selectBucketOffset,
  selectBucketRounding,
  selectChosenBucketID,
  selectParameterKeyEntities,
  selectProductTypes,
  selectRateFactorsParameters,
  selectSavedBucketDiscounts,
  selectSavedBucketMax,
  selectSavedBucketMin,
  selectSavedBucketName,
  selectSavedBucketOffset,
  selectSavedBucketRounding,
  selectSavedCmsBucketNumber,
  selectSavedPayeeCode,
  selectSavedReserves,
  selectStateRateFactors,
  selectStateRateSliceFactorGroups,
  selectUnusedParameters,
} from './bucket.selectors';
import { BucketsService } from './services/buckets.service';

@Injectable()
export class BucketEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private dataService: DataService,
    private bucketsService: BucketsService,
    private router: Router,
    private getBucketByIdGQL: GetBucketByIdGQL,
    private getOffsetBucketListGQL: GetOffsetBucketListGQL,
    private getProductParamsByBucketIdGQL: GetProductParamsByBucketIdGQL,
    private getOffsetBucketGQL: GetOffsetBucketGQL,
    private getProductTypesGQL: ProductTypesGQL,
    private switchToBaseSlicesGQL: SwitchToBaseSlicesGQL,
    private switchToBaseRatesGQL: SwitchToBaseRatesGQL,
    private getPayeesGQL: GetPayeesGQL
  ) {}

  loadBucket$ = createEffect(() => {
    const mapToData = map(<T>(response: ApolloQueryResult<T>) => response.data);

    return this.actions$.pipe(
      ofType(BucketActions.loadBucket),
      concatLatestFrom((_) => [
        this.store.pipe(select(selectProductTypes)),
        this.store.pipe(select(selectChosenProgram)),
      ]),
      switchMap(([{ bucketId }, productTypes, program]) => {
        return forkJoin({
          bucket: this.getBucketByIdGQL.fetch({ bucketId }).pipe(mapToData),
          productParameters: this.getProductParamsByBucketIdGQL
            .fetch({ bucketId })
            .pipe(mapToData),
          siblingRateSlices: trpcClient.rateSlice.siblings.query({
            rateSliceId: bucketId,
          }),
          baseParameters: trpcClient.rateSlice.baseParameters.query({
            rateSliceId: bucketId,
          }),
          rateSliceFactors: trpcClient.rateSliceFactors.get.query({
            rateSliceId: bucketId,
          }),
          offsetBucket: this.getOffsetBucketGQL
            .fetch({ bucketId })
            .pipe(
              map((res) => res?.data?.bucket_by_pk?.configured_offset ?? null)
            ),
          offsetBucketList: this.getOffsetBucketListGQL
            .fetch({ currentBucketId: bucketId })
            .pipe(
              map((res) => {
                const buckets =
                  res?.data?.bucket_by_pk?.product?.buckets
                    ?.filter(
                      (b) =>
                        !b.bucket_offset &&
                        (!b.configured_offset ||
                          b.configured_offset.bucket_id === bucketId) &&
                        res?.data?.bucket_by_pk?.product?.dealer_rounding
                          ?.offset_bucket_id !== b.id
                    )
                    .map(
                      (b): IOffsetBucket => ({
                        id: b.id,
                        name: b.name,
                        sort_order: b.sort_order,
                      })
                    ) ?? [];
                return { buckets };
              })
            ),
          productTypes:
            productTypes.length > 0
              ? of(productTypes)
              : // TODO: remove !
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.getProductTypesGQL.fetch({ programId: program!.id }).pipe(
                  map((response): ProductTypeListItem[] => {
                    return response.data.product_type.map(
                      (pt): ProductTypeListItem => {
                        return {
                          id: pt.id,
                          name: pt.type,
                          description: pt.description,
                          products: pt.products,
                        };
                      }
                    );
                  })
                ),
          payees: this.getPayeesGQL.fetch(),
        }).pipe(
          map((responses) => {
            return BucketActions.loadBucketSuccess({
              baseParameters: responses.baseParameters,
              bucket: responses.bucket,
              rateSliceFactors: responses.rateSliceFactors[0],
              rateSliceFactorGroups: responses.rateSliceFactors[1],
              offsetBucket: responses.offsetBucket,
              offsetBucketList: responses.offsetBucketList,
              productParameters: responses.productParameters,
              productTypes: responses.productTypes,
              siblingRateSlices: responses.siblingRateSlices,
              payees: responses.payees.data.payee,
            });
          }),
          catchError((err) => {
            return throwError(err);
          })
        );
      })
    );
  });

  saveMinMax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BucketActions.minMaxSave),
      concatLatestFrom((_) => [
        this.store.pipe(select(selectChosenBucketID)),
        this.store.pipe(select(selectSavedBucketMin)),
        this.store.pipe(select(selectSavedBucketMax)),
      ]),
      switchMap(([{ min, max }, bucketId, oldMin, oldMax]) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.dataService.saveBucketMinMax(bucketId!, min, max).pipe(
          map(() => {
            return BucketActions.minMaxSaveSuccess({
              oldMin,
              oldMax,
              newMin: min,
              newMax: max,
            });
          }),
          catchError((error) => {
            return throwError(error);
          })
        );
      })
    )
  );

  saveOffsetRounding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BucketActions.offsetRoundingSave),
      concatLatestFrom((_) => [
        this.store.pipe(select(selectChosenBucketID)),
        this.store.pipe(select(selectBucketRounding)),
        this.store.pipe(select(selectBucketOffset)),
        this.store.pipe(select(selectSavedBucketOffset)),
        this.store.pipe(select(selectSavedBucketRounding)),
        this.store.pipe(select(UserSelectors.selectUserLogInfo)),
      ]),
      switchMap(
        ([
          _nev,
          bucketId,
          rounding,
          offset,
          savedOffset,
          savedRounding,
          userLogInfo,
        ]) => {
          return combineLatest([
            this.dataService.saveRounding(
              !!rounding,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              bucketId!,
              rounding?.roundTo ?? null,
              rounding?.roundingType ?? null
            ),
            this.dataService.saveOffset(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              bucketId!,
              savedOffset.offsetBucketId,
              offset?.offsetBucketId ?? null,
              offset?.offsetType ?? null,
              userLogInfo.user_name ?? '',
              userLogInfo.user_email ?? ''
            ),
          ]).pipe(
            map(([newRounding, newOffset]) => {
              return BucketActions.offsetRoundingSaveSuccess({
                newOffset,
                newRounding,
                oldOffset: savedOffset,
                oldRounding: savedRounding,
              });
            }),
            catchError((error) => {
              return throwError(error);
            })
          );
        }
      )
    )
  );

  updateBucketSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BucketActions.updateBucketSettings),
      concatLatestFrom((_) => [
        this.store.pipe(select(selectSavedBucketName)),
        this.store.pipe(select(selectSavedCmsBucketNumber)),
        this.store.pipe(select(selectSavedReserves)),
        this.store.pipe(select(selectSavedPayeeCode)),
      ]),
      switchMap(
        ([
          { bucketId, newName, cmsBucketNumber, reserves, payee },
          oldName,
          oldCmsBucketNumber,
          oldReserves,
          oldPayee,
        ]) => {
          return this.dataService
            .updateBucket(
              bucketId,
              newName,
              cmsBucketNumber,
              reserves,
              payee.id
            )
            .pipe(
              map((newName) => {
                return BucketActions.updateBucketSettingsSuccess({
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  oldName: oldName!,
                  newName,
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  oldPayee: oldPayee!,
                  newPayee: payee,
                  oldCmsBucketNumber,
                  newCmsBucketNumber: cmsBucketNumber,
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  oldReserves: oldReserves!,
                  newReserves: reserves,
                });
              }),
              catchError((error) => {
                return throwError(error);
              })
            );
        }
      )
    )
  );

  saveBucketBundleDiscounts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BucketActions.bundleDiscountsSave),
      concatLatestFrom((_) => [
        this.store.pipe(select(selectChosenBucketID)),
        this.store.pipe(select(selectSavedBucketDiscounts)),
      ]),
      switchMap(([action, bucketId, oldDiscounts]) => {
        return (
          this.dataService
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .saveBucketBundleDiscounts(bucketId!, action.discounts)
            .pipe(
              map((_response) => {
                return BucketActions.bundleDiscountsSaveSuccess({
                  oldDiscounts,
                  newDiscounts: action.discounts,
                });
              }),
              catchError((error) => {
                return throwError(error);
              })
            )
        );
      })
    );
  });

  loadBaseRates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BucketActions.baseRatesPaged),
      concatLatestFrom((_) => [
        this.store.pipe(select(selectChosenBucketID)),
        this.store.pipe(select(selectAppliedBaseParameters)),
      ]),
      switchMap(([action, bucketId, baseParams]) => {
        const parameterFilters = buildParameterFilters(
          action.filterModel,
          baseParams
        );
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const skip = action.startRow!;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const take = action.endRow! - action.startRow! + 1;

        if (parameterFilters.length === 0) {
          return of(
            BucketActions.baseRatesPageLoadSuccess({
              rowData: [],
              rowCount: 0,
            })
          );
        }

        return from(
          trpcClient.baseRates.get.query({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            bucketId: bucketId!,
            parameterFilters,
            skip,
            take,
          })
        ).pipe(
          map((response) => {
            return BucketActions.baseRatesPageLoadSuccess({
              rowData: response.nodes,
              rowCount: response.totalCount,
            });
          }),
          catchError((error) => {
            return of(BucketActions.baseRatesPageLoadFailure({ error }));
          })
        );
      })
    );
  });

  saveBucketBaseRate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BucketActions.baseRateCellValueChanged),
      concatLatestFrom((_) =>
        this.store.pipe(select(selectParameterKeyEntities))
      ),
      concatMap(([action, parameterKeysLookup]) => {
        const upsertBaseRates$ = concat(
          ...batchRequests(
            action.bucketRateIds,
            (id): UpsertBucketBaseRate => {
              return { bucket_rate_id: id, base: action.newBase };
            },
            (batch) => this.dataService.upsertBucketBaseRates(batch)
          )
        ).pipe(toArray());

        const parameterKeys = action.parameterKeyIds.map((pkid) => {
          return parameterKeysLookup[pkid]?.parameterKey ?? `${pkid}`;
        });

        return upsertBaseRates$.pipe(
          map(() =>
            BucketActions.baseRateSaveSuccess({
              parameterKeys,
              oldBase: action.oldBase,
              newBase: action.newBase,
            })
          ),
          catchError((error) =>
            of(BucketActions.baseRateSaveFailure({ error }))
          )
        );
      })
    );
  });

  saveBaseParameters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BucketActions.saveBaseParameters),
      concatLatestFrom((_) => [
        this.store.pipe(select(selectChosenBucketID)),
        this.store.pipe(select(selectProductParameters)),
        this.store.pipe(select(selectBaseParametersInSortOrder)),
      ]),
      concatMap(
        ([{ baseParameters }, bucketId, productParams, oldBaseParameters]) => {
          const parameterIdsToAdd = baseParameters
            .filter(
              (bp) =>
                !oldBaseParameters.some(
                  (old) => old.parameterId === bp.parameterId
                )
            )
            .map((bp) => bp.parameterId);
          const parameterIdsToDelete = oldBaseParameters
            .filter(
              (old) =>
                !baseParameters.some((bp) => bp.parameterId === old.parameterId)
            )
            .map((bp) => bp.parameterId);
          return from(
            trpcClient.baseRates.saveBaseRateParameters.mutate({
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              bucketId: bucketId!,
              parameterIdsToAdd,
              parameterIdsToDelete,
              orderedParameterIds: baseParameters.map((bp) => bp.parameterId),
            })
          ).pipe(
            map(() => {
              const parametersAdded =
                productParams
                  ?.filter((pp) => parameterIdsToAdd.includes(pp.parameterId))
                  .map((pp) => ({
                    parameterId: pp.parameterId,
                    parameterName: pp.parameterName,
                  })) ?? [];
              const parametersDeleted =
                productParams
                  ?.filter((pp) =>
                    parameterIdsToDelete.includes(pp.parameterId)
                  )
                  .map((pp) => ({
                    parameterId: pp.parameterId,
                    parameterName: pp.parameterName,
                  })) ?? [];
              return BucketActions.saveBaseParameterSuccess({
                parameterIds: baseParameters.map((bp) => bp.parameterId),
                parametersAdded,
                parametersDeleted,
              });
            }),
            catchError((error) =>
              of(BucketActions.saveBaseParameterFailure({ error }))
            )
          );
        }
      )
    );
  });

  loadExceptions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BucketActions.exceptionsPaged),
      concatLatestFrom((_) => [
        this.store.pipe(select(selectChosenBucketID)),
        this.store.pipe(select(selectAppliedBaseParameters)),
        this.store.pipe(select(selectRateFactorsParameters)),
      ]),
      switchMap(([action, bucketId, baseParams, rateFactorParams]) => {
        if (!bucketId) {
          return EMPTY;
        }

        const parameters = buildParameterFilters(
          action.filterModel,
          baseParams.concat(rateFactorParams)
        );
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const skip = action.startRow!;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const take = action.endRow! - action.startRow! + 1;

        if (parameters.length === 0) {
          return of(
            BucketActions.exceptionsPageLoadSuccess({
              rowData: [],
              rowCount: 0,
            })
          );
        }

        return from(
          trpcClient.rateSliceExceptions.get.query({
            bucketId,
            parameters,
            skip,
            take,
          })
        ).pipe(
          map((response) => {
            return BucketActions.exceptionsPageLoadSuccess({
              rowData: response.nodes,
              rowCount: response.totalCount,
            });
          }),
          catchError((error) => {
            return of(BucketActions.exceptionsPageLoadFailure({ error }));
          })
        );
      })
    );
  });

  saveBucketException$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BucketActions.exceptionChanged),
      concatLatestFrom((_) => [
        this.store.pipe(select(selectParameterKeyEntities)),
        this.store.select(selectChosenBucketID),
      ]),
      concatMap(([action, parameterKeysLookup, bucketId]) => {
        if (!bucketId) {
          return EMPTY;
        }
        const upsert$ = concat(
          ...batchRequests(
            action.productParameterKeyCombinationIds,
            (id): UpsertBucketRateException => {
              return {
                bucket_id: bucketId,
                product_parameter_key_combination_id: id,
                value: action.newValue,
              };
            },
            (batch) => this.dataService.upsertBucketRateExceptions(batch)
          )
        ).pipe(toArray());

        const parameterKeys = action.parameterKeyIds.map((pkid) => {
          return parameterKeysLookup[pkid]?.parameterKey ?? `${pkid}`;
        });

        return upsert$.pipe(
          map(() =>
            BucketActions.exceptionSaveSuccess({
              parameterKeys,
              oldExceptionValue: action.oldValue,
              newExceptionValue: action.newValue,
            })
          ),
          catchError((error) =>
            of(BucketActions.exceptionSaveFailure({ error }))
          )
        );
      })
    );
  });

  removeBucketException$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BucketActions.exceptionDeleted),
      concatLatestFrom((_) =>
        this.store.pipe(select(selectParameterKeyEntities))
      ),
      concatMap(([action, parameterKeysLookup]) => {
        const parameterKeys = action.parameterKeyIds.map((pkid) => {
          return parameterKeysLookup[pkid]?.parameterKey ?? `${pkid}`;
        });

        return this.dataService
          .deleteBucketRateExceptions(action.productParameterKeyCombinationIds)
          .pipe(
            map(() =>
              BucketActions.exceptionSaveSuccess({
                parameterKeys,
                oldExceptionValue: action.oldExceptionValue,
                newExceptionValue: null,
              })
            ),
            catchError((error) =>
              of(BucketActions.exceptionSaveFailure({ error }))
            )
          );
      })
    );
  });

  loadReview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BucketActions.reviewPaged),
      concatLatestFrom((_) => [
        this.store.pipe(select(selectChosenBucketID)),
        this.store.pipe(select(selectAppliedBaseParameters)),
        this.store.pipe(select(selectRateFactorsParameters)),
        this.store.pipe(select(selectUnusedParameters)),
      ]),
      switchMap(
        ([action, bucketId, baseParams, rateFactorParams, unusedParams]) => {
          if (!bucketId) {
            return EMPTY;
          }

          const parameters = buildParameterFilters(
            action.filterModel,
            unusedParams.concat(baseParams, rateFactorParams)
          );
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const skip = action.startRow!;
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const take = action.endRow! - action.startRow! + 1;

          if (parameters.length === 0) {
            return of(
              BucketActions.reviewPageLoadSuccess({
                rowData: [],
                rowCount: 0,
              })
            );
          }

          return from(
            trpcClient.rateSliceRates.get.query({
              rateSliceId: bucketId,
              parameters,
              skip,
              take,
            })
          ).pipe(
            map((response) => {
              return BucketActions.reviewPageLoadSuccess({
                rowData: response.nodes,
                rowCount: response.totalCount,
              });
            }),
            catchError((error) => {
              return of(BucketActions.reviewPageLoadFailure({ error }));
            })
          );
        }
      )
    );
  });

  saveRateFactors$ = createEffect(() => {
    return this.actions$.pipe(ofType(BucketActions.rateFactorSave)).pipe(
      concatLatestFrom((_) => [
        this.store.pipe(select(selectChosenBucketID)),
        this.store.pipe(select(selectStateRateFactors)),
        this.store.pipe(select(selectStateRateSliceFactorGroups)),
      ]),
      switchMap(
        ([action, bucketId, oldRateFactors, oldRateSliceFactorGroups]) => {
          if (bucketId === undefined) {
            return EMPTY;
          }

          const groupsWithParameterChanges: number[] = [];
          action.rateFactorGroups
            .filter((rfg) => rfg.id !== undefined && rfg.id > 0)
            .forEach((rfg) => {
              if (rfg.id === undefined) {
                return;
              }

              const oldRfg = oldRateSliceFactorGroups.find(
                (orfg) => orfg.id === rfg.id
              );
              if (oldRfg) {
                const oldParameterIds =
                  oldRfg.rateSliceFactorGroupParameters.map(
                    (params) => params.parameter.id
                  );
                const newParameterIds = rfg.rateFactorGroupParameters.map(
                  (params) => params.parameterId
                );
                const difference =
                  newParameterIds.some((id) => !oldParameterIds.includes(id)) ||
                  newParameterIds.length != oldParameterIds.length;
                if (difference) {
                  groupsWithParameterChanges.push(rfg.id);
                }
              }
            });

          const saveRateFactors = from(
            trpcClient.rateSliceFactors.save.mutate({
              rateSliceId: bucketId,
              rateFactors: action.rateFactors.map((rf) => ({
                id: rf.id > 0 ? rf.id : undefined,
                rateSliceId: bucketId,
                operand: rf.operand,
                operator: rf.operator,
                sortOrder: rf.sortOrder,
                parameterKeys: rf.parameterKeys,
              })),
              rateSliceFactorGroupIdsToDelete:
                action.rateFactorGroupIdsToDelete,
              rateSliceFactorGroups: action.rateFactorGroups.map((group) => ({
                id: group.id,
                rateSliceId: group.rateSliceId,
                parameterIds: group.rateFactorGroupParameters.map(
                  (gp) => gp.parameterId
                ),
                parametersChanged:
                  group.id !== undefined &&
                  groupsWithParameterChanges.length > 0
                    ? groupsWithParameterChanges.includes(group.id)
                    : false,
                rateFactors: group.rateFactors.map((rf) => ({
                  id: rf.id,
                  operand: rf.operand,
                  operator: rf.operator,
                  parameterKeyIds: rf.parameterKeys.map(
                    (pk) => pk.parameterKeyId
                  ),
                  sortOrder: rf.sortOrder,
                })),
              })),
            })
          );

          return saveRateFactors.pipe(
            map(([rateFactorsResponse, rateSliceFactorGroups]) => {
              return BucketActions.rateFactorsSaveSuccess({
                rateFactorsResponse,
                oldRateFactors,
                rateSliceFactorGroups,
              });
            }),
            catchError((_error) => {
              return of(BucketActions.rateFactorsSaveFailure());
            })
          );
        }
      )
    );
  });

  saveBucketReviewRates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BucketActions.reviewSave),
      concatLatestFrom((_) => [
        this.store.pipe(select(selectChosenBucketID)),
        this.store.pipe(select(UserSelectors.selectUserLogInfo)),
      ]),
      switchMap(([_nev, bucketId, userLogInfo]) => {
        return this.dataService
          .saveFinalBucketRates(
            // TODO: remove !
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            bucketId!,
            userLogInfo.user_name ?? '',
            userLogInfo.user_email ?? ''
          )
          .pipe(
            map(() => {
              return BucketActions.reviewSaveSuccess();
            }),
            catchError((_error) => {
              return of(BucketActions.reviewSaveFailure());
            })
          );
      })
    );
  });

  deleteBucket$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BucketActions.deleteBucket),
      concatLatestFrom(() => this.store.pipe(select(selectChosenBucketID))),
      exhaustMap(([_action, bucketId]) => {
        if (!bucketId) {
          return EMPTY;
        }
        return this.bucketsService.delete(bucketId).pipe(
          map((response) =>
            BucketActions.deleteBucketSuccess({
              productId: response.product_id,
            })
          ),
          catchError((error) =>
            of(BucketActions.deleteBucketFailure({ error }))
          )
        );
      })
    );
  });

  redirectAfterBucketDelete$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BucketActions.deleteBucketSuccess),
        tap((action) => {
          const commands: (string | number)[] = [
            'rating',
            'rates',
            action.productId,
          ];
          // TODO: use RateTabNames.RateSlices once the RateTabNames enum is in a shared location
          const queryParams = { tab: 'rate-slices' };
          this.router.navigate(commands, { queryParams });
        })
      );
    },
    { dispatch: false }
  );

  baseRateSlicesSaved$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BucketActions.baseSlicesSaved),
      concatLatestFrom((_) => this.store.select(selectChosenBucketID)),
      switchMap(([action, rateSliceId]) => {
        if (!rateSliceId) {
          return EMPTY;
        }

        return from(
          trpcClient.baseRates.saveBaseRateSlices.mutate({
            baseSliceIds: action.baseSliceIds,
            rateSliceId,
          })
        ).pipe(
          map((_response) =>
            BucketActions.saveBaseSlicesSuccess({
              baseSliceIds: action.baseSliceIds,
            })
          ),
          catchError((error) =>
            of(BucketActions.saveBaseSlicesFailure({ error }))
          )
        );
      })
    );
  });

  switchToBaseSlices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BucketActions.switchToBaseSlicesClicked),
      concatLatestFrom((_) => this.store.select(selectChosenBucketID)),
      switchMap(([_, rateSliceId]) => {
        if (!rateSliceId) {
          return EMPTY;
        }

        return this.switchToBaseSlicesGQL.mutate({ rateSliceId }).pipe(
          map((_response) => BucketActions.switchToBaseSlicesSuccess()),
          catchError((error) =>
            of(BucketActions.switchToBaseSlicesFailure({ error }))
          )
        );
      })
    );
  });

  switchToBaseRates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BucketActions.switchToBaseRatesClicked),
      concatLatestFrom((_) => this.store.select(selectChosenBucketID)),
      switchMap(([_, rateSliceId]) => {
        if (!rateSliceId) {
          return EMPTY;
        }

        return this.switchToBaseRatesGQL.mutate({ rateSliceId }).pipe(
          map((_response) => BucketActions.switchToBaseRatesSuccess()),
          catchError((error) =>
            of(BucketActions.switchToBaseRatesFailure({ error }))
          )
        );
      })
    );
  });
}

// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetBucketByIdQueryVariables = Types.Exact<{
  bucketId: Types.Scalars['bigint'];
}>;


export type GetBucketByIdQuery = { bucket_by_pk: { id: number, name: string, product_id: number, cms_bucket_number: any | null, reserves: boolean, sort_order: number, usesBaseSlices: boolean, base_slices: Array<{ base_slice_id: number }>, bucket_maximum: { id: number, max: number, is_enabled: boolean } | null, bucket_minimum: { id: number, min: number, is_enabled: boolean } | null, payee: { id: number, company: string, code: string }, bucket_rounding: { id: number, round_by: number, round_type: string } | null, bucket_offset: { id: number, offset_bucket_id: number, offset_type: string, offset_bucket: { name: string } } | null, product: { id: number, name: string, productType: { id: number, name: string }, dealerRounding: { offsetBucketId: number, roundingType: string, roundingValue: number } | null } } | null };

export const GetBucketByIdDocument = gql`
    query GetBucketById($bucketId: bigint!) {
  bucket_by_pk(id: $bucketId) {
    id
    name
    product_id
    cms_bucket_number
    reserves
    sort_order
    usesBaseSlices: uses_base_slices
    base_slices {
      base_slice_id
    }
    bucket_maximum {
      id
      max
      is_enabled
    }
    bucket_minimum {
      id
      min
      is_enabled
    }
    payee {
      id
      company
      code: payee_code
    }
    bucket_rounding {
      id
      round_by
      round_type
    }
    bucket_offset {
      id
      offset_bucket_id
      offset_type
      offset_bucket {
        name
      }
    }
    product {
      id
      name
      productType: product_type {
        id
        name: type
      }
      dealerRounding: dealer_rounding {
        offsetBucketId: offset_bucket_id
        roundingType: rounding_type
        roundingValue: rounding_value
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBucketByIdGQL extends Apollo.Query<GetBucketByIdQuery, GetBucketByIdQueryVariables> {
    override document = GetBucketByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RatingUtilityUiAddProgramModule } from '@roadrunner/rating-utility/ui-add-program';
import { SharedUiGridModule } from '@roadrunner/shared/ui-grid';
import { SharedUiLayoutModule } from '@roadrunner/shared/ui-layout';
import { SharedUiSidenavModule } from '@roadrunner/shared/ui-sidenav';
import { SharedMaterialModule } from '../shared-material.module';
import { BackLinkComponent } from './back-link/back-link.component';
import { JumpLinkComponent } from './jump-link/jump-link.component';
import { BreadcrumbDirective } from './layouts/breadcrumb/breadcrumb.directive';
import { BreadcrumbsComponent } from './layouts/breadcrumb/breadcrumbs.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { NotificationsComponent } from './layouts/notifications/notifications.component';
import { PageLayoutComponent } from './layouts/page-layout/page-layout.component';
import { SessionLayoutComponent } from './layouts/session-layout/session-layout.component';
import { ProductsGridComponent } from './product-type-list/products-grid/products-grid.component';
import { SubmitButtonComponent } from './submit-button/submit-button.component';
import { TabIndicatorComponent } from './tab-indicator/tab-indicator.component';

const components = [
  BreadcrumbsComponent,
  BreadcrumbDirective,
  NotificationsComponent,
  SessionLayoutComponent,
  DashboardLayoutComponent,
  SubmitButtonComponent,
  BackLinkComponent,
  JumpLinkComponent,
  PageLayoutComponent,
  ProductsGridComponent,
  TabIndicatorComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedMaterialModule,
    SharedUiGridModule,
    SharedUiSidenavModule,
    SharedUiLayoutModule,
    RatingUtilityUiAddProgramModule,
  ],
  declarations: components,
  exports: components,
})
export class SharedComponentsModule {}

// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpsertNonSellableCombinationsMutationVariables = Types.Exact<{
  combinations: Array<Types.Non_Sellable_Combination_Insert_Input> | Types.Non_Sellable_Combination_Insert_Input;
  productId: Types.Scalars['bigint'];
  combinationIds: Array<Types.Scalars['bigint']> | Types.Scalars['bigint'];
  combinationOptionIds: Array<Types.Scalars['bigint']> | Types.Scalars['bigint'];
}>;


export type UpsertNonSellableCombinationsMutation = { delete_non_sellable_combination: { affected_rows: number } | null, delete_non_sellable_combination_option: { affected_rows: number } | null, insert_non_sellable_combination: { affected_rows: number } | null };

export const UpsertNonSellableCombinationsDocument = gql`
    mutation UpsertNonSellableCombinations($combinations: [non_sellable_combination_insert_input!]!, $productId: bigint!, $combinationIds: [bigint!]!, $combinationOptionIds: [bigint!]!) {
  delete_non_sellable_combination(
    where: {product_id: {_eq: $productId}, _and: {id: {_nin: $combinationIds}}}
  ) {
    affected_rows
  }
  delete_non_sellable_combination_option(
    where: {non_sellable_combination_id: {_in: $combinationIds}, _and: {id: {_nin: $combinationOptionIds}}}
  ) {
    affected_rows
  }
  insert_non_sellable_combination(
    objects: $combinations
    on_conflict: {constraint: non_sellable_combination_pkey, update_columns: product_id}
  ) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpsertNonSellableCombinationsGQL extends Apollo.Mutation<UpsertNonSellableCombinationsMutation, UpsertNonSellableCombinationsMutationVariables> {
    override document = UpsertNonSellableCombinationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
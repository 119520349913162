// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetBucketListQueryVariables = Types.Exact<{
  productId: Types.Scalars['bigint'];
}>;


export type GetBucketListQuery = { bucket: Array<{ id: number, name: string, has_saved_rates: boolean, sort_order: number, payee: { id: number, company: string, code: string } }> };

export const GetBucketListDocument = gql`
    query GetBucketList($productId: bigint!) {
  bucket(
    where: {product_id: {_eq: $productId}}
    order_by: [{sort_order: asc}, {name: asc}]
  ) {
    id
    name
    has_saved_rates
    sort_order
    payee {
      id
      company
      code: payee_code
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBucketListGQL extends Apollo.Query<GetBucketListQuery, GetBucketListQueryVariables> {
    override document = GetBucketListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
import { Injectable, NgZone } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import {
  ActionCreator,
  DefaultProjectorFn,
  MemoizedSelector,
  select,
  Store,
} from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import LogRocket from 'logrocket';
import { EMPTY, Subject } from 'rxjs';
import {
  buffer,
  catchError,
  debounceTime,
  filter,
  map,
  tap,
} from 'rxjs/operators';
import { LoggingDataService } from '../data/logging-data.service';
import { BaseLogMessage } from '../models/base-log-message.interface';
import { LogInfo } from '../models/log-info';

export class LoggingConfig {
  constructor(public appId: string, public enableNgrxMiddleware: boolean) {}
}

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  private logs$ = new Subject<BaseLogMessage>();

  constructor(
    config: LoggingConfig,
    private actions$: Actions,
    private store: Store,
    private dataService: LoggingDataService,
    ngZone: NgZone
  ) {
    ngZone.runOutsideAngular(() => LogRocket.init(config.appId));
    this.logs$
      .pipe(buffer(this.logs$.pipe(debounceTime(250))))
      .subscribe((logs) => {
        this.persistLogs(logs).subscribe();
      });
  }

  identify(accountId: string, name: string, email: string) {
    LogRocket.identify(accountId, { name, email });
  }

  log<T extends BaseLogMessage>(msg: T) {
    this.logs$.next(msg);
  }

  getSimpleValueChangeLog<T>(
    oldValue: T,
    newValue: T
  ): Record<string, unknown> | null {
    return JSON.stringify(newValue) === JSON.stringify(oldValue)
      ? null
      : {
          oldValue,
          newValue,
        };
  }

  getSimpleValueChangeEffectCreator<
    N extends BaseLogMessage,
    M extends LogInfo<N>
  >(
    selector: MemoizedSelector<
      // eslint-disable-next-line @typescript-eslint/ban-types
      object,
      M,
      DefaultProjectorFn<M>
    >
  ) {
    const creator = this.getLogEffectCreator(selector);
    return <T, U>(
      logType: string,
      action: ActionCreator<string, (props: T) => T & TypedAction<string>>,
      getNewValue: (action: T & TypedAction<string>) => U,
      getOldValue: (action: T & TypedAction<string>) => U,
      getMetadata?: (action: T & TypedAction<string>) => Record<string, unknown>
    ) => {
      return creator(logType, action, (a) => {
        const log = this.getSimpleValueChangeLog(
          getOldValue(a) ?? null,
          getNewValue(a) ?? null
        );
        if (log && getMetadata) {
          return {
            ...log,
            ...getMetadata(a),
          };
        }
        return log;
      });
    };
  }

  getLogEffectCreator<N extends BaseLogMessage, M extends LogInfo<N>>(
    selector: MemoizedSelector<
      // eslint-disable-next-line @typescript-eslint/ban-types
      object,
      M,
      DefaultProjectorFn<M>
    >
  ) {
    return <T>(
      logType: string,
      ofTypeAction: ActionCreator<
        string,
        (props: T) => T & TypedAction<string>
      >,
      getLogMessage?: (
        action: T & TypedAction<string>
      ) => Record<string, unknown> | null
    ) =>
      createEffect(
        () =>
          this.actions$.pipe(
            ofType(ofTypeAction),
            concatLatestFrom((_) => this.store.pipe(select(selector))),
            map(([action, partialLogMessage]): N | null => {
              const logMsg = getLogMessage ? getLogMessage(action) : {};
              if (logMsg) {
                //  TODO: Move all relevant fields into logMsg and drop these columns from db
                /*
                  For posterity, the partialLogMessage is supposed to include all the common log data, 
                  like program/product/bucket name & id. However, we want to remove those explicit columns 
                  from the audit_log db table and put those values into the log JSON column instead, because 
                  dealer audit logs don't have a program/product/bucket. So for now what this does is, it leaves 
                  those columns in place (to maintain backwards compatibility with the existing rating utility 
                  logging code) but puts any other fields on the partialLogMessage into the log JSON column, 
                  which allows all new logging effects to still use the same mechanism for common/shared data 
                  without requiring that common/shared data to have explicit columns in the db.
                */
                const fields = partialLogMessage as Record<string, unknown>;
                const legacyAuditLogTableFields: Record<string, unknown> = {
                  program_id: fields['program_id'],
                  product_id: fields['product_id'],
                  bucket_id: fields['bucket_id'],
                  program: fields['program'],
                  product: fields['product'],
                  bucket: fields['bucket'],
                  user_name: fields['user_name'],
                  user_email: fields['user_email'],
                };

                return {
                  ...legacyAuditLogTableFields,
                  type: logType,
                  log: {
                    ...logMsg,
                    ...partialLogMessage,
                  },
                } as unknown as N;
              }
              return null;
            }),
            filter((log): log is N => log != null),
            tap((logs) => {
              this.log(logs);
            }),
            catchError(() => EMPTY) // swallow any errors in log message building code
          ),
        { dispatch: false }
      );
  }

  private persistLogs(logs: BaseLogMessage[]) {
    return this.dataService.logMessages(logs).pipe(
      catchError(() => {
        // swallow logging request errors
        return EMPTY;
      })
    );
  }
}

// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetNonSellableCombinationsQueryVariables = Types.Exact<{
  productId: Types.Scalars['bigint'];
}>;


export type GetNonSellableCombinationsQuery = { non_sellable_combination_option: Array<{ id: number, non_sellable_combination_id: number, parameter_key_id: number }> };

export const GetNonSellableCombinationsDocument = gql`
    query GetNonSellableCombinations($productId: bigint!) {
  non_sellable_combination_option(
    where: {non_sellable_combination: {product_id: {_eq: $productId}}}
  ) {
    id
    non_sellable_combination_id
    parameter_key_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetNonSellableCombinationsGQL extends Apollo.Query<GetNonSellableCombinationsQuery, GetNonSellableCombinationsQueryVariables> {
    override document = GetNonSellableCombinationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
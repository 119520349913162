import { DRYUtility } from './dry.utility';

export function clamp(
  value: number,
  min: number | null | undefined,
  max: number | null | undefined
): number {
  return DRYUtility.isNullOrUndefined(min)
    ? DRYUtility.isNullOrUndefined(max)
      ? value
      : Math.min(max, value)
    : DRYUtility.isNullOrUndefined(max)
    ? Math.max(min, value)
    : Math.max(min, Math.min(max, value));
}

// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetProductParamsByBucketIdQueryVariables = Types.Exact<{
  bucketId: Types.Scalars['bigint'];
}>;


export type GetProductParamsByBucketIdQuery = { parameter: Array<{ description: string, id: number, name: string, parameter_type_id: number, parameter_type: { bundleable: boolean }, parameter_keys: Array<{ sort_order: number, product_id: number, parameter_id: number, key: string, id: number, created_at: any, parameter_key_values: Array<{ id: number, value: string }>, parameter_sub_keys: Array<{ id: number, child_parameter_key_id: number, parameter_sub_key_bucket_discounts: Array<{ discount: number | null }> }> }> }> };

export const GetProductParamsByBucketIdDocument = gql`
    query GetProductParamsByBucketId($bucketId: bigint!) {
  parameter(where: {parameter_keys: {product: {buckets: {id: {_eq: $bucketId}}}}}) {
    description
    id
    name
    parameter_type_id
    parameter_type {
      bundleable
    }
    parameter_keys(
      where: {product: {buckets: {id: {_eq: $bucketId}}}}
      order_by: [{id: asc}]
    ) {
      sort_order
      product_id
      parameter_id
      key
      id
      created_at
      parameter_key_values {
        id
        value
      }
      parameter_sub_keys {
        id
        child_parameter_key_id
        parameter_sub_key_bucket_discounts(where: {bucket_id: {_eq: $bucketId}}) {
          discount
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductParamsByBucketIdGQL extends Apollo.Query<GetProductParamsByBucketIdQuery, GetProductParamsByBucketIdQueryVariables> {
    override document = GetProductParamsByBucketIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { programAdapter, PROGRAM_FEATURE_KEY, State } from './program.reducer';

// Lookup the 'Program' feature state managed by NgRx
export const selectProgramState =
  createFeatureSelector<State>(PROGRAM_FEATURE_KEY);

const {
  selectAll: selectAllPrograms,
  selectEntities: selectAllProgramEntities,
} = programAdapter.getSelectors();

export const selectPrograms = createSelector(
  selectProgramState,
  (state: State) => selectAllPrograms(state)
);

export const selectProgramEntities = createSelector(
  selectProgramState,
  (state: State) => selectAllProgramEntities(state)
);

export const selectAllProgramAgentCodes = createSelector(
  selectProgramState,
  (state: State) => {
    return selectAllPrograms(state).map((program) => program.agentCode);
  }
);

export const selectAllProgramNames = createSelector(
  selectProgramState,
  (state: State) => {
    return selectAllPrograms(state).map((program) => program.name);
  }
);

/**
 * @description This selector retrieves RATING UTILITY APP's user feature > selected program
 *
 * @todo This is a temporary fix to avoid importing selectors from rating-utility app.
 *        All of the rating-utility app state stuff should eventually be split out into data-access libs
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const selectedProgram = (state: any) => state['userState'].selectedProgram;
export const selectSelectedProgram = createSelector(
  selectedProgram,
  (
    state
  ): {
    agent_code: string;
    can_add_remove_buckets: boolean;
    cms: boolean;
    id: number;
    name: string;
  } => {
    return state;
  }
);

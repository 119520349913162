<form
  [formGroup]="optionFormGroup"
  (ngSubmit)="formSubmitted()"
  class="flex items-center py-1 pl-4 pr-2 mb-3 rounded mat-elevation-z4 parameter-key-row"
>
  <app-parameter-option-form-fields
    [optionNameControl]="optionNameControl"
    [optionKeyValuesArrayControls]="optionKeyValuesArrayControls"
    [isFormFieldsInDialog]="false"
    class="contents"
  >
  </app-parameter-option-form-fields>

  <div class="flex items-center justify-end shrink grow basis-0">
    <ng-container>
      <button
        mat-icon-button
        type="submit"
        [disabled]="optionFormGroup.invalid || optionFormGroup.pending"
        class="ml-5"
      >
        <mat-icon>cloud_upload</mat-icon>
      </button>
      <button mat-icon-button type="button" (click)="cancelClicked()">
        <mat-icon>cancel</mat-icon>
      </button>
    </ng-container>
  </div>
</form>

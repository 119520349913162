export const JobStatusPollTime = 3000;

export enum JobStatus {
  InProgress = 'inProgress',
  Complete = 'complete',
  Error = 'error',
}

export interface Job {
  jobId: string;
  status: JobStatus;
  userName: string;
  userEmail: string;
  error?: Error;
  message?: string;
  title?: string;
  errors?: any[];
}

import { roundDownTo, roundToNearest, roundUpTo } from './rounding';

export const ROUND_UP = 'Up';
export const ROUND_DOWN = 'Down';
export const ROUND_NEAREST = 'Nearest';

export function calculateTotalWithRounding(
  startFrom: number,
  roundingType: string,
  roundTo: number
): number {
  switch (roundingType) {
    case ROUND_UP:
      return roundUpTo(roundTo, startFrom);
    case ROUND_DOWN:
      return roundDownTo(roundTo, startFrom);
    case ROUND_NEAREST:
      return roundToNearest(roundTo, startFrom);
    default:
      return startFrom;
  }
}

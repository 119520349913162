<roadrunner-dialog-header [headerText]="headerText"></roadrunner-dialog-header>
<mat-dialog-content>
  <div *ngFor="let error of data.errors" class="error">
    <p class="error-message" [innerHtml]="error.message"></p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [matDialogClose]="null" class="ml-auto" cdkFocusInitial>
    Close
  </button>
</mat-dialog-actions>

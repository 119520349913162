import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss'],
})
export class SubmitButtonComponent {
  @Input() buttonText!: string;
  @Input() formSubmitting!: boolean;
  @Input() disabled!: boolean;
  @Output() buttonClick = new EventEmitter<void>();

  buttonClicked() {
    this.buttonClick.emit();
  }
}

import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  addParameterMutation,
  AddParameterResponse,
  AddParameterVariables,
  addProductMutation,
  AddProductResponse,
  AddProductVariables,
  InsertOneParameterResponse,
  InsertOneProductResponse,
  ProductInput,
} from './add-product.mutation';

@Injectable({ providedIn: 'root' })
export class ProductsService {
  constructor(private apollo: Apollo) {}

  add(product: ProductInput): Observable<InsertOneProductResponse> {
    return (
      this.apollo
        .mutate<AddProductResponse, AddProductVariables>({
          mutation: addProductMutation,
          variables: { product },
        })
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .pipe(map((response) => response.data!.insert_product_one))
    );
  }

  addParameter(
    parameter: AddParameterVariables
  ): Observable<InsertOneParameterResponse> {
    return this.apollo
      .mutate<AddParameterResponse, AddParameterVariables>({
        mutation: addParameterMutation,
        variables: parameter,
      })
      .pipe(
        map(
          (response) =>
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            response.data!.insert_parameter_one
        )
      );
  }
}

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorService } from '@roadrunner/shared/ui-errors';
import { ErrorMessage } from '@roadrunner/shared/util-graphql';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private errorService: ErrorService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // graphql has its own error handler
    if (request.url.includes('/graphql')) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        const defaultTitle = 'Something Went Wrong';

        const errorMessage: ErrorMessage = {
          header: errorResponse.error?.title || defaultTitle,
          message:
            errorResponse.error?.error ||
            errorResponse.error?.message ||
            errorResponse.message ||
            errorResponse.statusText,
        };

        this.errorService.showError(errorMessage);

        return throwError(() => new Error(errorMessage.message));
      })
    );
  }
}

export const httpErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpErrorInterceptor,
  multi: true,
};

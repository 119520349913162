import { ColDef, ValueGetterParams } from '@ag-grid-community/core';
import { numericValueFormatter } from '@roadrunner/shared/ui-grid';
import { calculateTotalWithRounding } from '@roadrunner/util-math';
import {
  ProductMsrpOperation,
  ProductRate,
} from '../../../../../apollo/queries/products/get-product-rates/get-product-rates.interface';
import { IDealerCostRoundingVM } from '../../../../../models/view-models/products/deal-cost-rounding.view-models';
import { MsrpOperator } from '../../../../../models/view-models/products/msrp-operator.enum';
import { calculateMsrp } from '../../../../../shared/utility/calculate-msrp';

export const msrpOperationTuples: {
  operator: MsrpOperator;
  description: string;
}[] = [
  // this also defines the order of operations
  { operator: MsrpOperator.Multiply, description: 'Multiply' },
  { operator: MsrpOperator.AddBeforeMargin, description: 'Add/Subtract' },
  { operator: MsrpOperator.MinMargin, description: 'Min Margin' },
  { operator: MsrpOperator.MaxMargin, description: 'Max Margin' },
  { operator: MsrpOperator.RoundUp, description: 'Round Up' },
  { operator: MsrpOperator.RoundToNearest, description: 'Round to Nearest' },
  { operator: MsrpOperator.RoundDown, description: 'Round Down' },
  { operator: MsrpOperator.AddAfterMargin, description: 'Add/Subtract' },
  { operator: MsrpOperator.Equals, description: 'Equals' },
];

export function getMsrpOperationColumnDefinitions(
  dealerCostRounding: IDealerCostRoundingVM | null
): ColDef[] {
  const columns: ColDef[] = msrpOperationTuples.map((tup) => ({
    headerName: tup.description,
    field: tup.operator,
    valueGetter: (params: ValueGetterParams) => {
      return (params.data as ProductRate).msrpOperations?.find(
        (op) => op.operator === tup.operator
      )?.operand;
    },
  }));
  columns.push({
    colId: 'msrp',
    field: 'msrp',
    headerName: 'Retail Cost',
    cellStyle: { 'font-weight': 'bold' },
    valueFormatter: numericValueFormatter(),
    valueGetter: (params: ValueGetterParams) => {
      const data = params.data as ProductRate;

      const msrpOperations = data.msrpOperations?.reduce(
        (ops: Record<string, ProductMsrpOperation>, op) => {
          ops[op.operator] = op;
          return ops;
        },
        {}
      );

      const roundedDealerCost = dealerCostRounding
        ? calculateTotalWithRounding(
            data.dealerCost,
            dealerCostRounding.roundingType,
            dealerCostRounding.roundTo
          )
        : data.dealerCost;

      return msrpOperations
        ? calculateMsrp(roundedDealerCost, msrpOperations)
        : roundedDealerCost;
    },
  });
  return columns;
}

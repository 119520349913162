// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProgramProductCodesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProgramProductCodesQuery = { program: Array<{ id: number, name: string, agentCode: string, products: Array<{ code: string }> }> };

export const ProgramProductCodesDocument = gql`
    query ProgramProductCodes {
  program {
    id
    name
    agentCode: agent_code
    products {
      code
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProgramProductCodesGQL extends Apollo.Query<ProgramProductCodesQuery, ProgramProductCodesQueryVariables> {
    override document = ProgramProductCodesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
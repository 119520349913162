import { gql } from 'apollo-angular';

export const upsertBucketOffsetMutation = gql`
  mutation UpsertBucketOffset($bucketOffset: bucket_offset_insert_input!) {
    insert_bucket_offset_one(
      object: $bucketOffset
      on_conflict: {
        constraint: bucket_offset_bucket_id_key
        update_columns: [offset_bucket_id, offset_type]
      }
    ) {
      id
      bucket_id
      offset_bucket {
        id
        name
      }
      offset_type
    }
  }
`;

import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
  LoadSuccessParams,
} from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import {
  catchError,
  EMPTY,
  Observable,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { resetGridOverlays } from './reset-grid-overlays';

/**
 * A factory function that returns a ServerSideDatasource object
 */
export function serverSideDatasourceFactory(
  getRows: (params: IServerSideGetRowsParams) => void
): IServerSideDatasource {
  return {
    getRows,
  };
}

/**
 * A base class that implements common mechanisms for server-side grid data sources.
 */
@Injectable()
export abstract class ServerSideDatasource implements IServerSideDatasource {
  protected destroyed$ = new Subject<void>();

  protected load$ = new Subject<IServerSideGetRowsParams>();

  constructor() {
    this.load$.pipe(switchMap((params) => this.get(params))).subscribe();
  }

  abstract fetch(
    params: IServerSideGetRowsParams
  ): Observable<LoadSuccessParams>;

  protected get(
    params: IServerSideGetRowsParams
  ): Observable<LoadSuccessParams> {
    // Hide the "No Rows To Show" overlay when getRows is called to avoid showing "No Rows" and row-level "Loading..." indicators at the same time
    params.api.hideOverlay();

    return this.fetch(params).pipe(
      catchError((_error) => {
        params.fail();
        return EMPTY;
      }),
      tap((successParams) => {
        params.success(successParams);
        resetGridOverlays(params, successParams);
      }),
      take(1),
      takeUntil(this.destroyed$)
    );
  }

  getRows(params: IServerSideGetRowsParams): void {
    this.load$.next(params);
  }

  destroy(): void {
    this.destroyed$.next();
  }
}

<form (ngSubmit)="onSubmit()">
  <roadrunner-dialog-header
    headerText="You are about to request a Publish"
    [matDialogCloseValue]="false"
  ></roadrunner-dialog-header>
  <mat-dialog-content cdkFocusInitial>
    <p class="mb-2">
      You are about to send a request to publish the rates for
      {{ data.productName }}.
    </p>

    <p class="mb-2">
      Once the changes are published, they will take effect on the date you
      select below. This means you will not be able to publish any more changes
      until that effective date.
    </p>

    <mat-form-field class="w-1/2">
      <mat-label>Effective date</mat-label>
      <input
        matInput
        [matDatepicker]="effectiveDatePicker"
        [formControl]="effectiveDateControl"
        data-testid="confirm-publish-effective-date-input"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="effectiveDatePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #effectiveDatePicker></mat-datepicker>
      <mat-error *ngIf="effectiveDateControl.hasError('required')">
        Effective date is required
      </mat-error>
    </mat-form-field>

    <p class="mb-2">
      Once the changes are published, you will not be able to undo your changes.
    </p>

    <p><strong>Are you sure you wish to proceed?</strong></p>
  </mat-dialog-content>
  <div mat-dialog-actions align="end">
    <button type="button" mat-button [matDialogClose]="null">
      Don't Do It
    </button>
    <button type="submit" mat-flat-button color="primary">
      <mat-icon>cloud_upload</mat-icon>
      <span class="ml-2">Yes, Request Publish</span>
    </button>
  </div>
</form>

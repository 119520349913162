import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  CanDeactivate,
} from '@angular/router';
import { Store } from '@ngrx/store';

import {
  programsGuardActivated,
  programsGuardDeactivated,
} from './state/program.actions';

@Injectable({ providedIn: 'root' })
export class ProgramsGuard
  implements CanActivate, CanActivateChild, CanDeactivate<unknown>
{
  constructor(private store: Store) {}

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    return this._canActivate();
  }

  canActivateChild(
    _childRoute: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): boolean {
    return this._canActivate();
  }

  canDeactivate(
    _component: unknown,
    _currentRoute: ActivatedRouteSnapshot,
    _currentState: RouterStateSnapshot,
    _nextState?: RouterStateSnapshot
  ): boolean {
    this.store.dispatch(programsGuardDeactivated());
    return true;
  }

  private _canActivate(): boolean {
    this.store.dispatch(programsGuardActivated());
    return true;
  }
}

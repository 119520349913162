import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { addProgramMutation } from './mutations/add-program.mutation';
import {
  UpdateProgramGQL,
  UpdateProgramMutationVariables,
} from './mutations/update-program.mutation.generated';
import {
  AddProgramResponse,
  AddProgramVariables,
  InsertOneProgramResponse,
} from './state/program.models';

@Injectable({
  providedIn: 'root',
})
export class ProgramService {
  constructor(
    private apollo: Apollo,
    private updateProgramGql: UpdateProgramGQL
  ) {}

  add(program: AddProgramVariables): Observable<InsertOneProgramResponse> {
    return this.apollo
      .mutate<AddProgramResponse, AddProgramVariables>({
        mutation: addProgramMutation,
        variables: program,
      })
      .pipe(
        map((response) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return response.data!.insert_program_one;
        })
      );
  }

  update(program: UpdateProgramMutationVariables) {
    return this.updateProgramGql
      .mutate(program)
      .pipe(map((response) => response.data?.update_program_by_pk));
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IProductParameterKeyVM } from '../../../../../models/view-models/products/product-options.view-model';

@Component({
  selector: 'app-delete-parameter-option',
  templateUrl: './delete-parameter-option.component.html',
})
export class DeleteParameterOptionComponent implements OnInit {
  option!: IProductParameterKeyVM;
  headerText!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { option: IProductParameterKeyVM }
  ) {}

  ngOnInit(): void {
    this.option = this.data.option;
    this.headerText = `Delete ${this.option.parameterKey} Option?`;
  }
}

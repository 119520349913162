import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  BucketMin,
  IGetBucketByIdResponse,
} from '../../apollo/queries/buckets/get-bucket-by-id/get-bucket-by-id.interface';
import { IOffsetBucketListResponse } from '../../apollo/queries/buckets/get-offset-bucket-list/get-offset-bucket-list.interface';
import {
  Parameter,
  ParameterKey,
  ParameterSubKeyDiscount,
} from '../../apollo/queries/buckets/get-product-param-by-bucket-id/get-product-param-by-bucket-id.interface';
import { ConfiguredOffset } from '../../apollo/queries/get-offset-bucket/get-offset-bucket.interface';
import { SiblingRateSlices } from './models/sibling-rate-slices';
import { BaseParameter } from '../../models/view-models/buckets/base-parameter';
import { ProductTypeListItem } from '../../models/view-models/products/product-type.view-model';
import { RateSliceFactorGroup } from '../../models/view-models/rate-factors/rate-factor-group';
import { RateFactorApi } from '../../models/view-models/rate-factors/rate-factor-parameter.view-model';
import { DRYUtility } from '../../shared/utility/dry.utility';
import { Payee } from '../rate/services/get-payees-types';
import * as BucketActions from './bucket.actions';

export const bucketFeatureKey = 'bucketState';

interface SavedChanges {
  baseRateComponent: boolean;
  rateFactorComponent: boolean;
  exceptionsComponent: boolean;
  minMaxComponent: boolean;
  roundingOffsetComponent: boolean;
}

const savedChanges: SavedChanges = {
  baseRateComponent: true,
  rateFactorComponent: true,
  exceptionsComponent: true,
  minMaxComponent: true,
  roundingOffsetComponent: true,
};

export const productParameterAdapter = createEntityAdapter<Parameter>();

export const productParameterKeyAdapter = createEntityAdapter<ParameterKey>({
  sortComparer: (a, b) => a.sort_order - b.sort_order,
});

export interface State {
  // all pages
  savedName: string | null;
  savedCmsBucketNumber: number | null;
  savedReserves: boolean | null;
  savedPayee: Payee | null;
  bucket: IGetBucketByIdResponse | null;
  productParameters: EntityState<Parameter>;
  siblingRateSlices: SiblingRateSlices | null;
  isSavingSettings: boolean;
  savedChanges: SavedChanges;
  productTypes: ProductTypeListItem[];
  payees: Payee[];

  // base-rates page
  baseParameters: BaseParameter[];
  isSavingBaseSlices: boolean;

  // rate-factors page
  rateFactors: RateFactorApi[];
  rateSliceFactorGroups: RateSliceFactorGroup[];
  isSavingRateFactors: boolean;

  // min-max page
  savedMin: number | null;
  savedMax: number | null;
  isSavingMinMax: boolean;

  // rounding-offset page
  offsetBucketList: IOffsetBucketListResponse | null;
  isSavingOffsetRounding: boolean;
  savedOffsetBucketId: number | null;
  savedOffsetType: string | null;
  savedBucketRoundingValue: number | null;
  savedBucketRoundingType: string | null;

  // bundle discounts page
  isSavingBundleDiscounts: boolean;

  // review page
  offsetBucketInfo: ConfiguredOffset | null;
  isLoadingBucket: boolean;
  isSavingReviewRates: boolean;
  isSavingBaseParameters: boolean;
}

export const initialState: State = {
  savedName: null,
  savedCmsBucketNumber: null,
  savedReserves: null,
  savedPayee: null,
  bucket: null,
  baseParameters: [],
  productTypes: [],
  payees: [],
  productParameters: productParameterAdapter.getInitialState(),
  siblingRateSlices: null,
  rateFactors: [],
  rateSliceFactorGroups: [],
  offsetBucketList: null,
  offsetBucketInfo: null,
  isSavingOffsetRounding: false,
  savedMin: null,
  savedMax: null,
  isSavingMinMax: false,
  isSavingRateFactors: false,
  isSavingSettings: false,
  isSavingBundleDiscounts: false,
  isSavingReviewRates: false,
  isLoadingBucket: false,
  isSavingBaseParameters: false,
  isSavingBaseSlices: false,
  savedChanges: savedChanges,
  savedOffsetBucketId: null,
  savedOffsetType: null,
  savedBucketRoundingValue: null,
  savedBucketRoundingType: null,
};

export const bucketReducer = createReducer(
  initialState,
  on(BucketActions.loadBucket, () => {
    return {
      ...initialState,
      isLoadingBucket: true,
    };
  }),
  on(BucketActions.leaveBucket, () => {
    // The bucket data can be quite large, so we don't want to hold onto it any longer than we have to
    return initialState;
  }),
  on(BucketActions.loadBucketSuccess, (state, action) => {
    if (!action.bucket.bucket_by_pk) {
      return state;
    }

    const productParameters = productParameterAdapter.setAll(
      action.productParameters.parameter,
      state.productParameters
    );

    return {
      ...state,
      productParameters,
      bucket: action.bucket,
      offsetBucketInfo: action.offsetBucket,
      siblingRateSlices: action.siblingRateSlices,
      baseParameters: action.baseParameters,
      rateFactors: action.rateSliceFactors,
      offsetBucketList: action.offsetBucketList,
      productTypes: action.productTypes,
      payees: action.payees,
      rateSliceFactorGroups: action.rateSliceFactorGroups,
      isLoadingBucket: false,
      savedMin: action.bucket?.bucket_by_pk?.bucket_minimum?.min ?? null,
      savedMax: action.bucket?.bucket_by_pk?.bucket_maximum?.max ?? null,
      savedName: action.bucket?.bucket_by_pk?.name ?? null,
      savedCmsBucketNumber:
        action.bucket?.bucket_by_pk?.cms_bucket_number ?? null,
      savedReserves: action.bucket?.bucket_by_pk?.reserves ?? null,
      savedPayee: action.bucket?.bucket_by_pk?.payee ?? null,
      savedOffsetBucketId:
        action.bucket?.bucket_by_pk?.bucket_offset?.offset_bucket_id ?? null,
      savedOffsetType:
        action.bucket?.bucket_by_pk?.bucket_offset?.offset_type ?? null,
      savedBucketRoundingValue:
        action.bucket?.bucket_by_pk?.bucket_rounding?.round_by ?? null,
      savedBucketRoundingType:
        action.bucket?.bucket_by_pk?.bucket_rounding?.round_type ?? null,
    };
  }),
  on(BucketActions.updateBucketSettings, (state) => {
    return { ...state, isSavingSettings: true };
  }),
  on(BucketActions.updateBucketSettingsSuccess, (state, action) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const bucket = DRYUtility.deepCopy(state.bucket!);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    bucket.bucket_by_pk!.name = action.newName;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    bucket.bucket_by_pk!.cms_bucket_number = action.newCmsBucketNumber;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    bucket.bucket_by_pk!.reserves = action.newReserves;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    bucket.bucket_by_pk!.payee = action.newPayee;

    return {
      ...state,
      bucket,
      isSavingSettings: false,
      savedName: action.newName,
      savedCmsBucketNumber: action.newCmsBucketNumber,
      savedReserves: action.newReserves,
      savedPayee: action.newPayee,
    };
  }),
  on(BucketActions.saveBaseParameters, (state) => {
    return {
      ...state,
      isSavingBaseParameters: true,
    };
  }),
  on(BucketActions.saveBaseParameterSuccess, (state, action) => {
    return {
      ...state,
      baseParameters: action.parameterIds.map((parameterId, sortOrder) => {
        return {
          parameterId,
          sortOrder,
        };
      }),
      isSavingBaseParameters: false,
    };
  }),
  on(BucketActions.saveBaseParameterFailure, (state) => {
    return {
      ...state,
      isSavingBaseParameters: false,
    };
  }),
  on(BucketActions.minChanged, (state, action): State => {
    // TODO: remove !
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    let stateMin: BucketMin | null = state.bucket!.bucket_by_pk!.bucket_minimum;

    if (action.min == null) {
      stateMin = null;
    } else {
      const minId = stateMin?.id;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      stateMin = { id: minId!, is_enabled: true, min: action.min };
    }

    return {
      ...state,
      bucket: {
        ...state.bucket,
        bucket_by_pk: {
          // TODO: remove !
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...state.bucket!.bucket_by_pk!,
          bucket_minimum: stateMin,
        },
      },
    };
  }),
  on(BucketActions.maxChanged, (state, action): State => {
    // TODO: remove !
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    let stateMax = state.bucket!.bucket_by_pk!.bucket_maximum;

    if (action.max == null) {
      stateMax = null;
    } else {
      const maxId = stateMax?.id;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      stateMax = { id: maxId!, is_enabled: true, max: action.max };
    }

    return {
      ...state,
      bucket: {
        ...state.bucket,
        bucket_by_pk: {
          // TODO: remove !
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...state.bucket!.bucket_by_pk!,
          bucket_maximum: stateMax,
        },
      },
    };
  }),
  on(BucketActions.roundingAdded, (state, action) => {
    const bucket = DRYUtility.deepCopy(state.bucket);
    // TODO: remove !s
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    bucket!.bucket_by_pk!.bucket_rounding = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: undefined!,
      round_by: action.roundingValue,
      round_type: action.roundingType,
    };
    return { ...state, bucket: bucket };
  }),
  on(BucketActions.roundingChanged, (state, action) => {
    const bucket = DRYUtility.deepCopy(state.bucket);
    // TODO: remove !s
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    bucket!.bucket_by_pk!.bucket_rounding!.round_by = action.roundingValue;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    bucket!.bucket_by_pk!.bucket_rounding!.round_type = action.roundingType;
    return { ...state, bucket: bucket };
  }),
  on(BucketActions.roundingRemoved, (state) => {
    const bucket = DRYUtility.deepCopy(state.bucket);
    // TODO: remove !
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    bucket!.bucket_by_pk!.bucket_rounding = null;
    return { ...state, bucket: bucket };
  }),
  on(BucketActions.offsetAdded, (state, action) => {
    const bucket = DRYUtility.deepCopy(state.bucket);
    // TODO: remove !
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    bucket!.bucket_by_pk!.bucket_offset = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: undefined!,
      offset_bucket_id: action.bucketId,
      offset_type: action.offsetType,
      offset_bucket: {
        name: action.offsetBucketName,
      },
    };
    return { ...state, bucket: bucket };
  }),
  on(BucketActions.offsetChanged, (state, action) => {
    const bucket = DRYUtility.deepCopy(state.bucket);
    // TODO: remove !s
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    bucket!.bucket_by_pk!.bucket_offset!.offset_bucket_id = action.bucketId;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    bucket!.bucket_by_pk!.bucket_offset!.offset_type = action.offsetType;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    bucket!.bucket_by_pk!.bucket_offset!.offset_bucket.name =
      action.offsetBucketName;
    return { ...state, bucket: bucket };
  }),
  on(BucketActions.offsetRemoved, (state) => {
    const bucket = DRYUtility.deepCopy(state.bucket);
    // TODO: remove !
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    bucket!.bucket_by_pk!.bucket_offset = null;
    return { ...state, bucket: bucket };
  }),
  on(BucketActions.rateFactorSave, (state) => {
    return {
      ...state,
      isSavingRateFactors: true,
    };
  }),
  on(BucketActions.rateFactorsSaveFailure, (state) => {
    return {
      ...state,
      isSavingRateFactors: false,
    };
  }),
  on(BucketActions.rateFactorsSaveSuccess, (state, action) => {
    return {
      ...state,
      rateFactors: action.rateFactorsResponse,
      rateSliceFactorGroups: action.rateSliceFactorGroups,
      isSavingRateFactors: false,
    };
  }),
  on(BucketActions.bundleDiscountsSave, (state) => {
    return { ...state, isSavingBundleDiscounts: true };
  }),
  on(BucketActions.bundleDiscountsSaveSuccess, (state, action) => {
    const discounts = new Map<number, number | null>(
      action.newDiscounts.map((d) => {
        return [d.parameterSubKeyId, d.discount];
      })
    );

    const productParameters = productParameterAdapter.map((p) => {
      if (!p.parameter_type.bundleable) {
        return p;
      }

      const parameterKeys = p.parameter_keys.map((pk) => {
        return {
          ...pk,
          parameter_sub_keys: pk.parameter_sub_keys.map((sk) => {
            const discount = discounts.get(sk.id) as number | null;
            // We know/assume that there's only one ParameterSubKeyDiscount for this bucket, so we just set it directly.
            const subKeyDiscounts: ParameterSubKeyDiscount[] = [{ discount }];
            return {
              ...sk,
              parameter_sub_key_bucket_discounts: subKeyDiscounts,
            };
          }),
        };
      });

      return {
        ...p,
        parameter_keys: parameterKeys,
      };
    }, state.productParameters);

    return {
      ...state,
      isSavingBundleDiscounts: false,
      productParameters,
    };
  }),
  on(BucketActions.minMaxSave, (state) => {
    return { ...state, isSavingMinMax: true };
  }),
  on(BucketActions.minMaxSaveSuccess, (state, action) => {
    return {
      ...state,
      isSavingMinMax: false,
      savedMin: action.newMin,
      savedMax: action.newMax,
    };
  }),
  on(BucketActions.offsetRoundingSave, (state) => {
    return { ...state, isSavingOffsetRounding: true };
  }),
  on(BucketActions.offsetRoundingSaveSuccess, (state, action) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const bucket = DRYUtility.deepCopy(state.bucket!);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    bucket.bucket_by_pk!.bucket_rounding = action.newRounding
      ?.insert_bucket_rounding_one
      ? {
          id: action.newRounding.insert_bucket_rounding_one.id,
          round_by: action.newRounding.insert_bucket_rounding_one.round_by,
          round_type: action.newRounding.insert_bucket_rounding_one.round_type,
        }
      : null;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    bucket.bucket_by_pk!.bucket_offset = action?.newOffset
      ?.insert_bucket_offset_one
      ? {
          id: action.newOffset.insert_bucket_offset_one.id,
          offset_bucket: {
            name: action.newOffset.insert_bucket_offset_one.offset_bucket.name,
          },
          offset_bucket_id:
            action.newOffset.insert_bucket_offset_one.offset_bucket.id,
          offset_type: action.newOffset.insert_bucket_offset_one.offset_type,
        }
      : null;
    return {
      ...state,
      bucket: bucket,
      isSavingOffsetRounding: false,
      savedOffsetBucketId:
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        bucket.bucket_by_pk!.bucket_offset?.offset_bucket_id ?? null,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      savedOffsetType: bucket.bucket_by_pk!.bucket_offset?.offset_type ?? null,
      savedBucketRoundingValue:
        bucket.bucket_by_pk?.bucket_rounding?.round_by ?? null,
      savedBucketRoundingType:
        bucket.bucket_by_pk?.bucket_rounding?.round_type ?? null,
    };
  }),
  on(BucketActions.reviewSave, (state) => {
    return { ...state, isSavingReviewRates: true };
  }),
  on(BucketActions.reviewSaveSuccess, (state) => {
    return { ...state, isSavingReviewRates: false };
  }),
  on(BucketActions.reviewSaveFailure, (state) => {
    return { ...state, isSavingReviewRates: false };
  }),
  on(BucketActions.deleteBucket, (state) => {
    return {
      ...state,
      isLoadingBucket: true,
    };
  }),
  on(BucketActions.deleteBucketFailure, (state) => {
    return {
      ...state,
      isLoadingBucket: false,
    };
  }),
  on(
    // optimistically switch to base slices
    BucketActions.switchToBaseSlicesClicked,
    BucketActions.switchToBaseSlicesSuccess,
    // fall back to base slices if switching to base rates fails
    BucketActions.switchToBaseRatesFailure,
    (state) => {
      if (!state.bucket?.bucket_by_pk) {
        return state;
      }
      return {
        ...state,
        bucket: {
          ...state.bucket,
          bucket_by_pk: {
            ...state.bucket.bucket_by_pk,
            usesBaseSlices: true,
          },
        },
      };
    }
  ),
  on(
    // optimistically switch to base rates
    BucketActions.switchToBaseRatesClicked,
    BucketActions.switchToBaseRatesSuccess,
    // fall back to base rates if switching to base slices fails
    BucketActions.switchToBaseSlicesFailure,
    (state) => {
      if (!state.bucket?.bucket_by_pk) {
        return state;
      }
      return {
        ...state,
        bucket: {
          ...state.bucket,
          bucket_by_pk: {
            ...state.bucket.bucket_by_pk,
            base_slices: [],
            usesBaseSlices: false,
          },
        },
      };
    }
  ),
  on(BucketActions.saveBaseSlicesSuccess, (state, action) => {
    if (!state.bucket?.bucket_by_pk) {
      return state;
    }
    return {
      ...state,
      bucket: {
        ...state.bucket,
        bucket_by_pk: {
          ...state.bucket?.bucket_by_pk,
          base_slices: action.baseSliceIds.map((id) => {
            return { base_slice_id: id };
          }),
        },
      },
      isSavingBaseSlices: false,
    };
  }),
  on(BucketActions.saveBaseSlicesFailure, (state) => {
    return {
      ...state,
      isSavingBaseSlices: false,
    };
  }),
  on(BucketActions.baseSlicesSaved, (state) => {
    return {
      ...state,
      isSavingBaseSlices: true,
    };
  })
);

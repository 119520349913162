<div class="w-[500px] text-center mx-auto">
  <img src="assets/images/404.svg" alt="" />

  <h1 class="text-5xl font-bold">404 Gravity Lesson</h1>
  <h2 class="mt-2 mb-12 text-2xl font-medium">
    This Roadrunner page is unexpectedly missing.
  </h2>

  <button mat-raised-button color="primary" [routerLink]="['/']">
    Back to solid ground
  </button>
</div>

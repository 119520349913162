import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule, MatDialogModule],
  declarations: [DialogHeaderComponent],
  exports: [DialogHeaderComponent],
})
export class SharedUiDialogModule {}

import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { ConfirmationDialogData } from './confirmation-dialog/confirmation-dialog-data';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { filterUnconfirmed } from './confirmation-dialog/filter-unconfirmed';
import { DialogConfig, DialogSize } from './modal.models';
import { UnsavedChangesDialogComponent } from './unsaved-changes-dialog/unsaved-changes-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private dialog: MatDialog) {}

  open<D, R>(
    component: ComponentType<unknown>,
    options: DialogConfig<D>
  ): Observable<R> {
    return this.dialog.open(component, options).afterClosed();
  }

  confirm(data: ConfirmationDialogData): Observable<boolean> {
    return this.open<ConfirmationDialogData, boolean>(
      ConfirmationDialogComponent,
      {
        width: DialogSize.Small,
        data,
      }
    );
  }

  filterUnconfirmed<T>(data: ConfirmationDialogData) {
    return filterUnconfirmed<T, ConfirmationDialogData>(
      this.confirm.bind(this),
      data
    );
  }

  unsavedChanges(): Observable<boolean> {
    return this.open<null, boolean>(UnsavedChangesDialogComponent, {
      width: DialogSize.Medium,
    });
  }
}

// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetPayeesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPayeesQuery = { payee: Array<{ id: number, company: string, code: string }> };

export const GetPayeesDocument = gql`
    query GetPayees {
  payee(order_by: [{company: asc}, {payee_code: asc}]) {
    id
    company
    code: payee_code
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPayeesGQL extends Apollo.Query<GetPayeesQuery, GetPayeesQueryVariables> {
    override document = GetPayeesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
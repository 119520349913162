import { ColDef } from '@ag-grid-community/core';

export const defaultColDef: ColDef = {
  flex: 1,
  minWidth: 200,
  resizable: true,
  floatingFilter: true,
  filterParams: {
    maxValidYear: 9999,
    trimInput:true,
    debounceMs:800    
  },
  autoHeight: true,
};

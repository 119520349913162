import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import {
  combineLatest,
  map,
  Observable,
  startWith,
  take,
  takeUntil,
} from 'rxjs';
import { Payee } from '../../../models/view-models/products/payee.interface';
import { OnDestroySubs } from '../onDestroySubs';

@Component({
  selector: 'app-payee-selector',
  templateUrl: 'payee-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PayeeSelectorComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: PayeeSelectorComponent,
      multi: true,
    },
  ],
})
export class PayeeSelectorComponent
  extends OnDestroySubs
  implements AfterViewInit, ControlValueAccessor, Validator
{
  // TODO - This component should be used for the entity-commissions add/edit dialogs
  // The entity-commission-payee model is slightly different and would need to be mapped to match the current interface used
  @Input() payees$!: Observable<Payee[]>;

  @ViewChild('payeeInput', { static: true, read: ElementRef })
  payeeInputEl!: ElementRef;

  @ViewChild('payeeAutocomplete') autocomplete: MatAutocomplete | undefined;

  payeeControl = new FormControl<Payee | string | null>(null, [
    this.matchingPayee(),
    Validators.required,
  ]);

  filteredPayees$!: Observable<Payee[]>;

  onTouched!: () => void;

  private onChange!: (payee: Payee | string | null | undefined) => void;

  constructor() {
    super();
  }

  ngAfterViewInit(): void {
    this.filteredPayees$ = combineLatest([
      this.payees$,
      this.payeeControl.valueChanges.pipe(startWith(this.payeeControl.value)),
    ]).pipe(
      map(([payees, value]) => {
        const stringValue = this.isPayee(value) ? value.code : value;

        if (!stringValue) {
          return payees;
        }

        const searchTerm = stringValue.trim().toLocaleLowerCase();

        return payees.filter(
          (p) =>
            p.company.toLocaleLowerCase().includes(searchTerm) ||
            p.code.toLocaleLowerCase().includes(searchTerm)
        );
      }),
      takeUntil(this.componentDestroyed$)
    );

    this.autocomplete?.closed.pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe(() => {
      const value = this.payeeControl.value ?? '';
      if (this.isPayee(value)) {
        this.onChange(value);
      } else {
        this.payees$.pipe(take(1)).subscribe((payees) => {
          const found = payees.find((p) =>
            p.code.toLowerCase().includes(value.toLowerCase() ?? '')
          );
          if (found && value) {
            this.payeeControl.setValue(found);
            this.onChange(found);
          } else {
            this.onChange(null);
          }
        });
      }
    });
  }

  focus(): void {
    this.payeeInputEl.nativeElement.focus();
  }

  writeValue(payee: Payee): void {
    this.payeeControl.setValue(payee);
  }

  registerOnChange(
    fn: (payee: Payee | string | null | undefined) => void
  ): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    return this.payeeControl.errors;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.payeeControl.disable() : this.payeeControl.enable();
  }

  displayFn(payee: Payee | null): string {
    return payee && payee?.code && payee?.company
      ? `${payee.code} - ${payee.company}`
      : `${payee ?? ''}`;
  }

  private isPayee(value: Payee | string | null): value is Payee {
    if (!value) {
      return false;
    }
    if ((value as Payee).id) {
      return true;
    }
    return false;
  }

  private matchingPayee(): ValidatorFn {
    return (control: AbstractControl) => {
      return this.isPayee(control.value) ? null : { noMatch: true };
    };
  }
}

import { AbstractControl, ValidatorFn } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { DateTime } from 'luxon';

export function futureDateValidator<D = DateTime>(
  dateAdapter: DateAdapter<D>,
  ...exceptions: D[]
): ValidatorFn {
  exceptions = exceptions
    .map((exception) => dateAdapter.deserialize(exception))
    .filter((date): date is D => date != null);
  return (control: AbstractControl) => {
    const value = dateAdapter.deserialize(control.value);
    const todayCurrentTime = dateAdapter.today();
    const todayMidnight = dateAdapter.createDate(
      dateAdapter.getYear(todayCurrentTime),
      dateAdapter.getMonth(todayCurrentTime),
      dateAdapter.getDate(todayCurrentTime)
    );
    const tomorrow = dateAdapter.addCalendarDays(todayMidnight, 1);
    if (
      value == null ||
      !dateAdapter.isValid(value) ||
      dateAdapter.compareDate(value, tomorrow) >= 0 ||
      exceptions.some((e) => dateAdapter.compareDate(value, e) === 0)
    ) {
      return null;
    }

    return { future: true };
  };
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParam } from '@roadrunner/dcu/data-access-router';
import { programAdapter, PROGRAM_FEATURE_KEY, State } from './program.reducer';

// Lookup the 'Program' feature state managed by NgRx
export const selectProgramState =
  createFeatureSelector<State>(PROGRAM_FEATURE_KEY);

export const {
  selectAll: selectAllPrograms,
  selectEntities: selectProgramEntities,
} = programAdapter.getSelectors(selectProgramState);

export const selectProgramsLoading = createSelector(
  selectProgramState,
  (state: State) => state.loading
);

export const selectProgramsLoaded = createSelector(
  selectProgramState,
  (state: State) => !state.loading && state.error == null
);

export const selectSelectedProgramId = createSelector(
  selectRouteParam('programId'),
  (programIdParam) => {
    if (!programIdParam) {
      return null;
    }
    const programId = +programIdParam;
    if (isNaN(programId)) {
      return null;
    }
    return programId;
  }
);

export const selectAllProgramAgentCodes = createSelector(
  selectAllPrograms,
  (programs) => {
    return programs.map((program) => program.agentCode);
  }
);

export const selectSelectedProgram = createSelector(
  selectProgramEntities,
  selectSelectedProgramId,
  (entities, selectedId) => {
    return selectedId ? entities[selectedId] : undefined;
  }
);

<ag-grid-angular
  [pagination]="true"
  [paginationPageSize]="100"
  domLayout="normal"
  class="ag-theme-alpine h-[75vh] min-h-[25rem]"
  [columnTypes]="columnTypes"
  [columnDefs]="columnDefs"
  [enableRangeSelection]="true"
  [enableFillHandle]="true"
  [defaultColDef]="defaultColDef"
  [stopEditingWhenCellsLoseFocus]="true"
  [serverSideDatasource]="serverSideDatasource"
  rowModelType="serverSide"
  serverSideStoreType="partial"
  (rowClicked)="onRowClicked($event.data)"
>
</ag-grid-angular>

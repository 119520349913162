import { gql } from 'apollo-angular';

export interface DeleteBucketMutationResponse {
  delete_bucket_by_pk: DeletedBucketResponse;
}

export interface DeletedBucketResponse {
  product_id: number;
}

export interface DeleteBucketMutationVariables {
  bucketId: number;
}

export const deleteBucketMutation = gql`
  mutation DeleteBucket($bucketId: bigint!) {
    delete_bucket_by_pk(id: $bucketId) {
      product_id
    }
  }
`;

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import type { AppRouter } from '@roadrunner/gateway/trpc';
import { TRPCClientError } from '@trpc/client';

@Component({
  selector: 'rr-trpc-error-dialog',
  templateUrl: 'trpc-error-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrpcErrorDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public error: TRPCClientError<AppRouter>
  ) {
    console.log('CLIENT ERROR', JSON.stringify(error, null, 2));
  }
}

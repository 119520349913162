import { gql } from 'apollo-angular';

export const upsertBucketRateExceptionsMutation = gql`
  mutation UpsertBucketRateExceptions(
    $exceptions: [bucket_rate_exception_insert_input!]!
  ) {
    insert_bucket_rate_exception(
      objects: $exceptions
      on_conflict: {
        constraint: bucket_rate_exception_product_parameter_key_combination_id__key
        update_columns: value
      }
    ) {
      returning {
        id
        product_parameter_key_combination_id
        value
      }
    }
  }
`;

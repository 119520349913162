<form [formGroup]="form" (ngSubmit)="formSubmitted()">
  <roadrunner-dialog-header
    [headerText]="!data.isAdd ? 'Edit Coverage' : 'Add a Coverage'"
  ></roadrunner-dialog-header>

  <mat-dialog-content formArrayName="parameterKeyValues" cdkFocusInitial>
    <div class="mb-5">
      <p id="coverage-codes-label">Select Coverage Codes to Bundle</p>
      <mat-button-toggle-group
        [formControl]="coverageCodesControl"
        [multiple]="true"
        aria-labelledby="coverage-codes-label"
      >
        <mat-button-toggle
          [value]="code"
          *ngFor="let code of data.existingStandaloneIdentifiers"
        >
          {{ code }}
        </mat-button-toggle>
      </mat-button-toggle-group>
      <mat-error
        *ngIf="
          coverageCodesControl.hasError('required') ||
          coverageCodesControl.hasError('minlength')
        "
      >
        Select at least two standalone coverages
      </mat-error>
      <mat-error *ngIf="coverageCodesControl.hasError('uniqueArray')">
        This bundle already exists
      </mat-error>
    </div>

    <mat-form-field
      appearance="outline"
      *ngFor="let parameterKeyValue of data.parameterKeyValues; let i = index"
      class="w-full"
    >
      <mat-label>{{ parameterKeyValue.parameterSubtype }}</mat-label>
      <input
        matInput
        [formControlName]="i"
        placeholder="Enter a {{ parameterKeyValue.parameterSubtype }} here..."
        autocomplete="off"
        trimWhitespace
      />
      <mat-error *ngIf="parameterKeyValuesFormArray.at(i).hasError('required')">
        {{ parameterKeyValue.parameterSubtype }} is required
      </mat-error>
      <mat-error *ngIf="parameterKeyValuesFormArray.at(i).hasError('unique')">
        {{ parameterKeyValue.parameterSubtype }} must be unique
      </mat-error>
      <mat-error
        *ngIf="
          parameterKeyValuesFormArray.at(i).getError('maxlength') as maxLength
        "
      >
        {{ parameterKeyValue.parameterSubtype }} must be
        {{ maxLength.requiredLength }} characters or fewer
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <div mat-dialog-actions align="end">
    <button mat-button type="button" [matDialogClose]="null">Cancel</button>
    <button
      mat-raised-button
      type="submit"
      color="primary"
      [class.mat-button-disabled]="!form.valid || form.pristine"
    >
      Save
    </button>
  </div>
</form>

// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { ParameterKeyFieldsFragmentDoc } from '../../fragments/parameter-key-fields.fragment.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ParameterSubtypeFieldsFragment = { id: number, subtype: string, parameter_type_id: number, sort_order: number, control_type: string, visible: boolean, is_identifier: boolean, isUnique: boolean, isGlobalUnique: boolean, options: Array<{ id: number, name: string }> };

export type ParameterTypeFieldsFragment = { id: number, type: string, description: string, bundleable: boolean, parameter_subtypes: Array<{ id: number, subtype: string, parameter_type_id: number, sort_order: number, control_type: string, visible: boolean, is_identifier: boolean, isUnique: boolean, isGlobalUnique: boolean, options: Array<{ id: number, name: string }> }> };

export type ParameterFieldsFragment = { id: number, name: string, description: string, parameter_type: { id: number, type: string, description: string, bundleable: boolean, parameter_subtypes: Array<{ id: number, subtype: string, parameter_type_id: number, sort_order: number, control_type: string, visible: boolean, is_identifier: boolean, isUnique: boolean, isGlobalUnique: boolean, options: Array<{ id: number, name: string }> }> } };

export type ProgramFieldsFragment = { id: number, name: string, canEditParameters: boolean, products: Array<{ id: number, name: string, code: string, description: string | null, major_version: number, product_type_id: number, program_id: number, risk_type: string | null }> };

export type ProductFieldsFragment = { id: number, name: string, code: string, description: string | null, major_version: number, product_type_id: number, program_id: number, risk_type: string | null, program: { id: number, name: string, canEditParameters: boolean, products: Array<{ id: number, name: string, code: string, description: string | null, major_version: number, product_type_id: number, program_id: number, risk_type: string | null }> }, parameter_keys: Array<{ id: number, key: string, product_id: number, parameter_id: number, sort_order: number, created_at: any, parameter_key_values: Array<{ id: number, parameter_key_id: number, parameter_subtype_id: number, value: string, parameter_subtype: { id: number, parameter_type_id: number, sort_order: number, subtype: string, control_type: string, is_identifier: boolean, visible: boolean, isUnique: boolean } }>, parameter_sub_keys: Array<{ id: number, parent_parameter_key_id: number, child_parameter_key: { id: number, key: string, parameter_id: number, product_id: number, sort_order: number } }> }> };

export type GetProductQueryVariables = Types.Exact<{
  productId: Types.Scalars['bigint'];
}>;


export type GetProductQuery = { product_by_pk: { id: number, name: string, code: string, description: string | null, major_version: number, product_type_id: number, program_id: number, risk_type: string | null, program: { id: number, name: string, canEditParameters: boolean, products: Array<{ id: number, name: string, code: string, description: string | null, major_version: number, product_type_id: number, program_id: number, risk_type: string | null }> }, parameter_keys: Array<{ id: number, key: string, product_id: number, parameter_id: number, sort_order: number, created_at: any, parameter_key_values: Array<{ id: number, parameter_key_id: number, parameter_subtype_id: number, value: string, parameter_subtype: { id: number, parameter_type_id: number, sort_order: number, subtype: string, control_type: string, is_identifier: boolean, visible: boolean, isUnique: boolean } }>, parameter_sub_keys: Array<{ id: number, parent_parameter_key_id: number, child_parameter_key: { id: number, key: string, parameter_id: number, product_id: number, sort_order: number } }> }> } | null, parameter: Array<{ id: number, name: string, description: string, parameter_type: { id: number, type: string, description: string, bundleable: boolean, parameter_subtypes: Array<{ id: number, subtype: string, parameter_type_id: number, sort_order: number, control_type: string, visible: boolean, is_identifier: boolean, isUnique: boolean, isGlobalUnique: boolean, options: Array<{ id: number, name: string }> }> } }> };

export const ParameterSubtypeFieldsFragmentDoc = gql`
    fragment ParameterSubtypeFields on parameter_subtype {
  id
  subtype
  parameter_type_id
  sort_order
  control_type
  visible
  is_identifier
  isUnique: is_unique
  isGlobalUnique: is_global_unique
  options: parameter_subtype_options(order_by: [{sort_order: asc}]) {
    id
    name
  }
}
    `;
export const ParameterTypeFieldsFragmentDoc = gql`
    fragment ParameterTypeFields on parameter_type {
  id
  type
  description
  bundleable
  parameter_subtypes {
    ...ParameterSubtypeFields
  }
}
    ${ParameterSubtypeFieldsFragmentDoc}`;
export const ParameterFieldsFragmentDoc = gql`
    fragment ParameterFields on parameter {
  id
  name
  description
  parameter_type {
    ...ParameterTypeFields
  }
}
    ${ParameterTypeFieldsFragmentDoc}`;
export const ProgramFieldsFragmentDoc = gql`
    fragment ProgramFields on program {
  id
  name
  canEditParameters: can_edit_parameters
  products {
    id
    name
    code
    description
    major_version
    product_type_id
    program_id
    risk_type
  }
}
    `;
export const ProductFieldsFragmentDoc = gql`
    fragment ProductFields on product {
  id
  name
  code
  description
  major_version
  product_type_id
  program_id
  risk_type
  program {
    ...ProgramFields
  }
  parameter_keys {
    ...ParameterKeyFields
  }
}
    ${ProgramFieldsFragmentDoc}
${ParameterKeyFieldsFragmentDoc}`;
export const GetProductDocument = gql`
    query GetProduct($productId: bigint!) {
  product_by_pk(id: $productId) {
    ...ProductFields
  }
  parameter {
    ...ParameterFields
  }
}
    ${ProductFieldsFragmentDoc}
${ParameterFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductGQL extends Apollo.Query<GetProductQuery, GetProductQueryVariables> {
    override document = GetProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
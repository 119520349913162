import { gql } from 'apollo-angular';

export const upsertProductSettingsMutation = gql`
  mutation UpsertProductSettings($product: product_insert_input!) {
    insert_product_one(
      object: $product
      on_conflict: {
        constraint: product_pkey
        update_columns: [code, description, name, product_type_id, risk_type]
      }
    ) {
      code
      description
      id
      major_version
      name
      product_type_id
      program_id
      risk_type
    }
  }
`;

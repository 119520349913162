import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  parameterSubtypeAdapter,
  parameterTypeAdapter,
  PARAMETER_TYPE_FEATURE_KEY,
  State,
} from './parameter-type.reducer';

// Lookup the 'ParameterType' feature state managed by NgRx
export const selectParameterTypeState = createFeatureSelector<State>(
  PARAMETER_TYPE_FEATURE_KEY
);

export const selectParameterTypesLoading = createSelector(
  selectParameterTypeState,
  (state: State) => {
    return state.loading;
  }
);

export const selectParameterTypes = createSelector(
  selectParameterTypeState,
  (state: State) => state.parameterTypes
);

export const selectParameterSubtypes = createSelector(
  selectParameterTypeState,
  (state: State) => state.parameterSubtypes
);

export const {
  selectAll: selectAllParameterTypes,
  selectEntities: selectAllParameterTypeEntities,
} = parameterTypeAdapter.getSelectors(selectParameterTypes);

export const {
  selectAll: selectAllParameterSubtypes,
  selectEntities: selectAllParameterSubtypeEntities,
} = parameterSubtypeAdapter.getSelectors(selectParameterSubtypes);

export const selectBundleableParameterType = createSelector(
  selectAllParameterTypes,
  (parameterTypes) => parameterTypes.find((pt) => pt.bundleable)
);
export const selectBundleableParameterSubtypes = createSelector(
  selectBundleableParameterType,
  selectAllParameterSubtypes,
  (bundleableParameterType, parameterSubtypes) => {
    return bundleableParameterType
      ? parameterSubtypes.filter(
          (pst) => pst.parameterTypeId === bundleableParameterType.id
        )
      : [];
  }
);

import { AbstractControl, ValidatorFn } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { DateTime } from 'luxon';

export function dateComparisonValidator<D>(
  dateAdapter: DateAdapter<D>,
  getOtherValue: () => D | DateTime | Date | string | null,
  comparisonResultValid: (comparisonResult: number) => boolean,
  errorKey: string
): ValidatorFn {
  return (control: AbstractControl) => {
    const thisValue = dateAdapter.deserialize(control.value);
    const otherValue = dateAdapter.deserialize(getOtherValue());
    if (
      thisValue == null ||
      otherValue == null ||
      !dateAdapter.isValid(thisValue) ||
      !dateAdapter.isValid(otherValue) ||
      comparisonResultValid(dateAdapter.compareDate(thisValue, otherValue))
    ) {
      return null;
    }

    return { [errorKey]: true };
  };
}

export function dateGreaterThanValidator<D = DateTime>(
  otherControlOrValue: AbstractControl | DateTime | Date | string,
  dateAdapter: DateAdapter<D>,
  errorKey: string = 'dateGreaterThan'
): ValidatorFn {
  return dateComparisonValidator(
    dateAdapter,
    getValue(otherControlOrValue),
    (comparisonResult) => comparisonResult >= 0,
    errorKey
  );
}

export function dateLessThanValidator<D = DateTime>(
  otherControlOrValue: AbstractControl | DateTime | Date | string,
  dateAdapter: DateAdapter<D>,
  errorKey: string = 'dateLessThan'
): ValidatorFn {
  return dateComparisonValidator(
    dateAdapter,
    getValue(otherControlOrValue),
    (comparisonResult) => comparisonResult <= 0,
    errorKey
  );
}

function getValue(
  otherControlOrValue: AbstractControl | DateTime | Date | string
) {
  return otherControlOrValue instanceof AbstractControl
    ? () => otherControlOrValue.value
    : () => otherControlOrValue;
}

import { Directive, HostListener, Optional } from '@angular/core';
import { FormControlDirective, FormControlName } from '@angular/forms';

@Directive({
  selector:
    '[formControlName][toUpperCaseAndTrimWhitespace], [formControl][toUpperCaseAndTrimWhitespace]',
})
export class ToUpperCaseAndTrimDirective {
  constructor(
    @Optional() private formControlDir: FormControlDirective,
    @Optional() private formControlName: FormControlName
  ) {}

  @HostListener('blur')
  @HostListener('keydown.enter', ['$event'])
  upperCaseValue() {
    const control =
      this.formControlDir?.control || this.formControlName?.control;
    if (typeof control.value === 'string') {
      const trimmed = control.value.toUpperCase().trim();
      if (trimmed !== control.value) {
        control.setValue(trimmed);
        if (event) {
          event.stopPropagation();
          event.preventDefault();
        }
      }
    }
  }
}

import { createAction, props } from '@ngrx/store';
import { IAProgram } from '../../apollo/queries/programs/all-program-list/all-program-list.interface';
import { IProgramVM } from '../../models/view-models/programs/program.interface';
import { RiskType } from '../../models/view-models/risk-type';

export const getProgramList = createAction('[Login] User Program list');
export const getProgramListSuccess = createAction(
  '[Login] User Get Program list Success',
  props<{ programs: IAProgram[]; riskTypes: RiskType[] }>()
);
export const getProgramListFail = createAction(
  '[Login] User Get Program list Fail',
  props<{ error: unknown }>()
);

export const chooseProgram = createAction(
  '[Login-choose-program] User Choose Program',
  props<{ program: IProgramVM }>()
);

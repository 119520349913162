import { createAction, props } from '@ngrx/store';
import {
  ParameterSubtype,
  ParameterType,
} from '@roadrunner/rating-utility/data-access-parameter-type';
import { AddParameterDialogResult } from '@roadrunner/rating-utility/ui-add-parameter';
import { AddProductDialogResult } from '@roadrunner/rating-utility/ui-add-product';
import { ParameterKeyFieldsFragment } from '../../apollo/fragments/parameter-key-fields.fragment.generated';
import { InsertParameterKeysReturningFieldsFragment } from '../../apollo/mutations/upsert-product-parameter-keys/upsert-product-parameter-keys.mutation.generated';
import { UpsertProductSettingsResponse } from '../../apollo/mutations/upsert-product-settings/upsert-product-settings.interface';
import {
  ParameterFieldsFragment,
  ProductFieldsFragment,
} from '../../apollo/queries/get-product/get-product.query.generated';
import { IAProductType } from '../../apollo/queries/products/product-types/product-types.interface';
import { IBucket, IProduct } from '../../models/program.interface';
import {
  CoverageKeyValue,
  IBundledCoverageVM,
  ICoverageVM,
  IStandaloneCoverageVM,
} from '../../models/view-models/products/coverage.view-model';
import {
  IProductParameterKeyVM,
  IProductParameterVM,
  ParameterChangeType,
} from '../../models/view-models/products/product-options.view-model';
import { ProductSettings } from './product-settings.interface';

/////////////////////////////////////////// PRODUCT LIST ///////////////////////////////////////////////////////////////
export const GET_PRODUCT_LIST = '[Product List Page] Get Product List';
export const GET_PRODUCT_LIST_SUCCESS =
  '[Product List Page] Get Product List Success';
export const GET_PRODUCT_LIST_FAILURE =
  '[Product List Page] Get Product List Failure';

export const getProductTypeList = createAction(
  '[Product List Page] Get Product Type List'
);
export const getProductTypeListSuccess = createAction(
  '[Product List Page] Get Product Type List Success',
  props<{ productTypes: IAProductType[] }>()
);
export const getProductTypeListFailure = createAction(
  '[Product List Page] Get Product Type List Failure',
  props<{ error: unknown }>()
);
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////// PRODUCT ///////////////////////////////////////////////////////////////
export const getProduct = createAction(
  '[Product Page] Get Product',
  props<{ productId: number }>()
);
export const getProductSuccess = createAction(
  '[Product Page] Get Product Success',
  props<{
    productRes: ProductFieldsFragment;
    parameters: ParameterFieldsFragment[];
  }>()
);
export const getProductFailure = createAction(
  '[Product Page] Get Product Failure'
);
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////// PRODUCT SETTINGS TAB ///////////////////////////////////////////////////////////////
export const SAVE_PRODUCT_SETTINGS =
  '[Product Settings Component] Save Product Settings';
export const SAVE_PRODUCT_SETTINGS_SUCCESS =
  '[Product Settings Component] Save Product Settings Success';
export const SAVE_PRODUCT_SETTINGS_FAILURE =
  '[Product Settings Component] Save Product Settings Failure';

export const saveProductSettings = createAction(
  SAVE_PRODUCT_SETTINGS,
  props<{
    productId: number;
    programId: number;
    productTypeId: number;
    name: string;
    code: string;
    description: string;
    riskType: string;
  }>()
);
export const saveProductSettingsSuccess = createAction(
  SAVE_PRODUCT_SETTINGS_SUCCESS,
  props<{
    settingsResponse: UpsertProductSettingsResponse;
    oldSettings: ProductSettings;
    newSettings: ProductSettings;
  }>()
);
export const saveProductSettingsFailure = createAction(
  SAVE_PRODUCT_SETTINGS_FAILURE
);
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////// PRODUCT COVERAGES TAB ///////////////////////////////////////////////////////////////

export const addCoverageClicked = createAction(
  '[Product Coverages Component] Add Coverage Clicked',
  props<{
    id: number;
    existingParameterKeyValuesBySubtype: Record<number, CoverageKeyValue[]>;
  }>()
);

export const addStandaloneCoverage = createAction(
  '[Product Coverages Component] Add Standalone Coverage',
  props<{
    coverage: IStandaloneCoverageVM;
    coverageParam: IProductParameterVM;
  }>()
);
export const addStandaloneCoverageCancelled = createAction(
  '[Add Coverage Component] Cancel'
);
export const removeStandaloneCoverage = createAction(
  '[Product Coverages Component] Remove Standalone Coverage',
  props<{
    coverage: IStandaloneCoverageVM;
    coverageParam: IProductParameterVM;
  }>()
);
export const updateStandaloneCoverage = createAction(
  '[Product Coverages Component] Update Standalone Coverage',
  props<{
    updatedCoverage: IStandaloneCoverageVM;
    coverageParam: IProductParameterVM;
    originalCoverage: IStandaloneCoverageVM;
  }>()
);

export const addBundledCoverage = createAction(
  '[Product Coverages Component] Add Bundled Coverage',
  props<{
    coverage: IBundledCoverageVM;
    coverageParam: IProductParameterVM;
  }>()
);
export const removeBundledCoverage = createAction(
  '[Product Coverages Component] Remove Bundled Coverage',
  props<{
    coverage: IBundledCoverageVM;
    coverageParam: IProductParameterVM;
  }>()
);
export const updateBundledCoverage = createAction(
  '[Product Coverages Component] Update Bundled Coverage',
  props<{
    updatedCoverage: IBundledCoverageVM;
    coverageParam: IProductParameterVM;
    originalCoverage: IBundledCoverageVM;
  }>()
);

export const saveProductCoverages = createAction(
  '[Product Coverages Component] Save Product Coverages'
);
export const saveProductCoveragesSuccess = createAction(
  '[Product Coverages Component] Save Product Coverages Success'
);
export const saveProductCoverageKeysSuccess = createAction(
  '[Product Coverages Component] Save Product Coverage Keys Success',
  props<{
    newParameterKeyIds: number[];
    upserts: InsertParameterKeysReturningFieldsFragment[];
    newParameterKeys: ParameterKeyFieldsFragment[];
    deletedParameterKeys: ICoverageVM[];
    hasChangedParameterCount: boolean;
  }>()
);
export const saveProductCoveragesFailure = createAction(
  '[Product Coverages Component] Save Product Coverages Failure'
);
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////// PRODUCT PARAMETERS TAB ///////////////////////////////////////////////////////////////
export const addProductOption = createAction(
  '[Product Parameters Component] Add Product Option',
  props<{ option: IProductParameterKeyVM }>()
);
export const updateProductOption = createAction(
  '[Product Parameters Component] Update Product Option',
  props<{
    updatedOption: IProductParameterKeyVM;
    originalOption: IProductParameterKeyVM;
  }>()
);
export const deleteProductOption = createAction(
  '[Product Parameters Component] Delete Product Option',
  props<{ option: IProductParameterKeyVM }>()
);

export const saveProductParameters = createAction(
  '[Product Parameters Component] Save Product Parameters'
);
export const saveProductParameterKeysSuccess = createAction(
  '[Product Parameters Component] Save Product Parameter Keys Success',
  props<{
    newParameterKeyIds: number[];
    upserts: InsertParameterKeysReturningFieldsFragment[];
    newParameterKeys: ParameterKeyFieldsFragment[];
    deletedParameterKeys: IProductParameterKeyVM[];
    parameterChangeType: ParameterChangeType;
  }>()
);
export const saveProductParametersSuccess = createAction(
  '[Product Parameters Component] Save Product Parameters Success'
);
export const saveProductParametersFailure = createAction(
  '[Product Parameters Component] Save Product Parameters Failure'
);
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const SET_PRODUCT = '[Product] Set Product';
export const ADD_BUCKET = '[Product] Add Bucket';
export const ADD_BUCKET_BY_NAME = '[Product] Add Bucket By Name';
export const REMOVE_BUCKET = '[Product] Remove Bucket';
export const ADD_BUCKET_CLICKED = '[Product] Add Bucket Clicked';
export const CLOSE_CREATE_BUCKET_MODAL = '[Product] Close Create Bucket Modal';
export const UPDATE_PRODUCT = '[Product] Update Product';
export const DELETE_PRODUCT = '[Product] Delete Product';
export const UPDATE_BUCKET_MIN_MAX = '[Product] Update Bucket Min Max';

export const setProduct = createAction(
  SET_PRODUCT,
  props<{ product: IProduct }>()
);

export const addBucket = createAction(ADD_BUCKET, props<{ bucket: IBucket }>());
export const addBucketByName = createAction(
  ADD_BUCKET_BY_NAME,
  props<{ productID: number; bucketName: string; payeeID: number }>()
);
export const removeBucket = createAction(
  REMOVE_BUCKET,
  props<{ bucket: IBucket }>()
);
export const addBucketClicked = createAction(ADD_BUCKET_CLICKED);
export const updateProduct = createAction(
  UPDATE_PRODUCT,
  props<{ product: IProduct }>()
);
export const deleteProduct = createAction(
  DELETE_PRODUCT,
  props<{ productID: number }>()
);
export const updateBucketMinMax = createAction(
  UPDATE_BUCKET_MIN_MAX,
  props<{ bucketID: number; min: number; max: number }>()
);
export const addProductClicked = createAction(
  '[Products/Product Search] Add Clicked'
);
export const addProduct = createAction(
  '[Products/Add Product] Submit',
  props<{ product: AddProductDialogResult }>()
);
export const addProductSuccess = createAction(
  '[Products/API] Add Product Success',
  props<{ id: number; code: string; name: string; productTypeId: number }>()
);
export const addProductFailure = createAction(
  '[Products/API] Add Product Failure',
  props<{ error: unknown }>()
);

//#region Parameters
export const addParameterClicked = createAction(
  '[Products/Add Parameter] Add New Parameter Clicked'
);
export const addParameter = createAction(
  '[Products/Add New Parameter] Submit',
  props<{ parameter: AddParameterDialogResult }>()
);
export const addParameterSuccess = createAction(
  '[API] Add Parameter Success',
  props<{
    id: number;
    name: string;
    description: string;
    parameterTypeId: number;
    parameterSubTypes: ParameterSubtype[];
    parameterType: ParameterType;
  }>()
);
export const addParameterFailure = createAction(
  '[API] Add Parameter Failure',
  props<{ error: unknown }>()
);
export const addParameterCancelled = createAction(
  '[Add Program Parameter] Add Parameter Cancelled'
);

export const copyProduct = createAction(
  '[Copy Product Dialog] Submit',
  props<{
    programId: number;
    productId: number;
    code: string;
    name: string;
    description: string;
  }>()
);
export const copyProductSuccess = createAction(
  '[API] Copy Product Success',
  props<{ id: number; code: string; name: string }>()
);
export const copyProductFailure = createAction(
  '[API] Copy Product Failure',
  props<{ error: unknown }>()
);
export const copyProductClicked = createAction(
  '[Product Detail Page] Copy Product clicked'
);
export const addFromExistingProductClicked = createAction(
  '[Add Product Dialog] Add From Existing Product clicked'
);

//#endregion

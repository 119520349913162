<mat-toolbar color="accent">
  <button type="button" mat-icon-button (click)="toggleService.toggle()">
    <mat-icon>menu</mat-icon>
  </button>

  <ng-container *ngIf="shouldShowProgram$ | async">
    <button
      mat-icon-button
      type="button"
      aria-label="switch programs"
      (click)="switchProgramClicked()"
      class="mr-2"
    >
      <mat-icon class="text-success"> swap_horizontal_circle </mat-icon>
    </button>
    <h1>{{ programName$ | async }}</h1>
    <button
      mat-icon-button
      type="button"
      aria-label="edit-program"
      (click)="onEditProgramClick()"
      class="scale-90"
    >
      <mat-icon>edit</mat-icon>
    </button>
  </ng-container>

  <div class="ml-auto flex items-center">
    <div class="mr-2">{{ userName$ | async }}</div>

    <mat-menu #accountMenu="matMenu">
      <!-- <button mat-menu-item [routerLink]="['/profile/overview']">
          <mat-icon>account_box</mat-icon>
          <span>Profile</span>
        </button>
        <button mat-menu-item [routerLink]="['/profile/settings']">
          <mat-icon>settings</mat-icon>
          <span>Account Settings</span>
        </button>
        <button mat-menu-item>
          <mat-icon>notifications_off</mat-icon>
          <span>Disable alerts</span>
        </button> -->
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>SIGNOUT</span>
      </button>
    </mat-menu>

    <button
      type="button"
      mat-icon-button
      [matMenuTriggerFor]="accountMenu"
      matTooltip="Account"
      class="topbar-button-right img-button"
    >
      <img src="assets/images/avatars/001-man.svg" alt="" />
    </button>
  </div>
</mat-toolbar>

<rr-module-loader>
  <router-outlet></router-outlet>
</rr-module-loader>

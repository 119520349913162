import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '../../../environments/environment';

import { LicenseManager } from '@ag-grid-enterprise/core';
import { appConfigProviders } from '@roadrunner/shared/util-app-config';
import { API_URL } from './app/shared/api-url';

LicenseManager.setLicenseKey(
  'CompanyName=Safe Guard,LicensedApplication=Road Runner,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=0,AssetReference=AG-013604,ExpiryDate=12_February_2022_[v2]_MTY0NDYyNDAwMDAwMA==c95aeeda5a8a94a7695cf28cdf9b7eae'
);

if (environment.production) {
  enableProdMode();
}

const apiUrl = environment.apiUrl ?? API_URL;
const trpcUrl = environment.trpcUrl ?? `${apiUrl}/trpc`;
const graphqlUrl = environment.graphQLUrl ?? `${apiUrl}/graphql`;

platformBrowserDynamic(
  appConfigProviders({
    azure: {
      clientId: environment.azureClientId,
      tenantId: environment.azureTenantId,
      interceptUrls: [apiUrl],
    },
    enableReduxDevTools: environment.enableReduxDevTools,
    logRocket: {
      appId: environment.logRocketAppId,
      enableNgrxMiddleware: environment.enableNgRxMiddleware,
    },
    api: {
      apiUrl,
      trpcUrl,
      graphqlUrl,
    },
    rateLookupUrl: environment.rateLookupUrl,
  })
)
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

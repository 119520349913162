import { AbstractControl, ValidatorFn } from '@angular/forms';

export function uniqueValidator(existingValues: string[]): ValidatorFn {
  const lookup = new Set(existingValues.map((code) => code.toLowerCase()));
  return (control: AbstractControl) => {
    return control.value != null &&
      lookup.has(control.value.trim().toLowerCase())
      ? { unique: control.value }
      : null;
  };
}

<roadrunner-dialog-header [headerText]="headerText"></roadrunner-dialog-header>
<form [formGroup]="form" autocomplete="off" (ngSubmit)="onSubmit()">
  <mat-dialog-content cdkFocusInitial>
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input
        #nameInput
        matInput
        formControlName="name"
        required
        trimWhitespace
        data-testid="add-program-name-input"
      />
      <mat-error
        *ngIf="form.hasError('required', 'name')"
        data-testid="add-program-name-required-error"
      >
        Name is required
      </mat-error>
      <mat-error
        *ngIf="form.hasError('unique', 'name')"
        data-testid="add-program-name-unique-error"
      >
        This program name is already in use
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Agent Code</mat-label>
      <input
        matInput
        formControlName="agentCode"
        trimWhitespace
        required
        data-testid="add-program-agent-code-input"
      />
      <mat-error
        *ngIf="form.hasError('required', 'agentCode')"
        data-testid="add-program-agent-code-required-error"
      >
        Agent code is required
      </mat-error>
      <mat-error
        *ngIf="form.getError('maxlength', 'agentCode') as error"
        data-testid="add-program-agent-code-length-error"
      >
        Agent code must be {{ error.requiredLength }} characters or fewer
      </mat-error>
      <mat-error
        *ngIf="form.hasError('unique', 'agentCode')"
        data-testid="add-program-agent-code-unique-error"
      >
        This agent code is already in use
      </mat-error>
    </mat-form-field>

    <mat-checkbox
      formControlName="cms"
      data-testid="add-program-cms-checkbox"
      class="py-2"
    >
      CMS
    </mat-checkbox>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button type="button" mat-button [matDialogClose]="null">Cancel</button>
    <button
      type="submit"
      mat-raised-button
      color="primary"
      [disabled]="form.invalid"
      data-testid="add-program-submit-button"
    >
      {{ submitButtonText }}
    </button>
  </mat-dialog-actions>
</form>

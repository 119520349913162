<span
  *ngIf="optionNameControl && optionKeyValuesArrayControls"
  class="contents"
  [ngClass]="isFormFieldsInDialog ? 'fieldsInDialog' : 'fieldsInGrid'"
>
  <span class="shrink grow basis-0">
    <mat-form-field appearance="outline">
      <mat-label>Option Name</mat-label>
      <input
        matInput
        placeholder="Enter a name here..."
        autocomplete="off"
        trimWhitespace
        [formControl]="optionNameControl"
      />
      <mat-error *ngIf="optionNameControl.errors">
        <span *ngIf="optionNameControl.errors.required"
          >Option Name is required</span
        >
        <span *ngIf="optionNameControl.errors.unique"
          >Option Name must be unique</span
        >
      </mat-error>
    </mat-form-field>
  </span>

  <div [ngClass]="isFormFieldsInDialog ? 'flex space-between' : 'contents'">
    <ng-container
      *ngFor="let control of optionKeyValuesArrayControls"
      [ngSwitch]="
        control.controls.keyValueControl.value.parameterSubType.controlType
      "
    >
      <span class="shrink grow basis-0" *ngSwitchCase="controlType.Checkbox">
        <div
          class="checkbox-parameter-option-value mr-5 last:mr-0"
          [ngClass]="isFormFieldsInDialog ? 'checkbox-center' : ''"
        >
          <mat-checkbox [formControl]="$any(control.controls.valueControl)">
            {{
              control.controls.keyValueControl.value.parameterSubType.subType
            }}
          </mat-checkbox>
        </div>
      </span>

      <span
        class="shrink grow basis-0"
        *ngSwitchCase="controlType.SingleSelect"
      >
        <mat-form-field appearance="outline" class="mr-5 last:mr-0">
          <mat-label>
            {{
              control.controls.keyValueControl.value.parameterSubType.subType
            }}
          </mat-label>
          <mat-select
            [formControl]="$any(control.controls.valueControl)"
            placeholder="Enter a value here..."
            required
          >
            <mat-option
              *ngFor="
                let option of control.controls.keyValueControl.value
                  .parameterSubType.options
              "
              [value]="option.name"
            >
              {{ option.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="control.controls.valueControl.errors">
            <span *ngIf="control.controls.valueControl.errors.required">
              {{
                control.controls.keyValueControl.value.parameterSubType.subType
              }}
              is required
            </span>
          </mat-error>
        </mat-form-field>
      </span>

      <span class="shrink grow basis-0" *ngSwitchDefault>
        <mat-form-field appearance="outline" class="mr-5 last:mr-0">
          <mat-label>
            {{
              control.controls.keyValueControl.value.parameterSubType.subType
            }}
          </mat-label>
          <input
            matInput
            [formControl]="$any(control.controls.valueControl)"
            placeholder="Enter a value here..."
            autocomplete="off"
            required
            trimWhitespace
          />
          <mat-error *ngIf="control.controls.valueControl.errors">
            <span *ngIf="control.controls.valueControl.errors.required">
              {{
                control.controls.keyValueControl.value.parameterSubType.subType
              }}
              is required
            </span>
            <mat-error *ngIf="control.controls.valueControl.hasError('unique')">
              {{
                control.controls.keyValueControl.value.parameterSubType.subType
              }}
              must be globally unique
            </mat-error>
          </mat-error>
        </mat-form-field>
      </span>
    </ng-container>
  </div>
</span>

import { ColDef } from '@ag-grid-community/core';

export const basicFilterColumnType: ColDef = {
  filter: 'agTextColumnFilter',
  suppressMenu: true,
  floatingFilterComponentParams: {
    suppressFilterButton: true,
    suppressFilterMenu: true,
  },
};

// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateProgramMutationVariables = Types.Exact<{
  id: Types.Scalars['bigint'];
  cms: Types.Scalars['Boolean'];
}>;


export type UpdateProgramMutation = { update_program_by_pk: { id: number, cms: boolean } | null };

export const UpdateProgramDocument = gql`
    mutation UpdateProgram($id: bigint!, $cms: Boolean!) {
  update_program_by_pk(pk_columns: {id: $id}, _set: {cms: $cms}) {
    id
    cms
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProgramGQL extends Apollo.Mutation<UpdateProgramMutation, UpdateProgramMutationVariables> {
    override document = UpdateProgramDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SwitchToBaseSlicesMutationVariables = Types.Exact<{
  rateSliceId: Types.Scalars['bigint'];
}>;


export type SwitchToBaseSlicesMutation = { update_bucket_by_pk: { uses_base_slices: boolean } | null };

export const SwitchToBaseSlicesDocument = gql`
    mutation SwitchToBaseSlices($rateSliceId: bigint!) {
  update_bucket_by_pk(
    _set: {uses_base_slices: true}
    pk_columns: {id: $rateSliceId}
  ) {
    uses_base_slices
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SwitchToBaseSlicesGQL extends Apollo.Mutation<SwitchToBaseSlicesMutation, SwitchToBaseSlicesMutationVariables> {
    override document = SwitchToBaseSlicesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FormUtility } from '../../../../../shared/utility/form.utility';
import { StandaloneCoverageData } from './standalone-coverage-data.interface';

@Component({
  selector: 'app-standalone-coverage',
  templateUrl: './standalone-coverage.component.html',
  styleUrls: ['./standalone-coverage.component.scss'],
})
export class StandaloneCoverageComponent implements OnInit {
  form: UntypedFormGroup;

  parameterKeyValuesFormArray = new UntypedFormArray([]);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: StandaloneCoverageData,
    private dialogRef: MatDialogRef<
      StandaloneCoverageComponent,
      Omit<StandaloneCoverageData, 'isAdd'>
    >
  ) {
    this.form = new UntypedFormGroup({
      parameterKeyValues: this.parameterKeyValuesFormArray,
    });
  }

  ngOnInit(): void {
    for (const pkv of this.data.parameterKeyValues) {
      const control = new UntypedFormControl(pkv.value, Validators.required);
      if (pkv.isIdentifier) {
        control.addValidators(Validators.maxLength(8));
      }
      if (pkv.isUnique) {
        control.addValidators(FormUtility.unique(pkv.existingValues));
      }
      this.parameterKeyValuesFormArray.insert(
        this.parameterKeyValuesFormArray.length,
        control
      );
    }
  }

  formSubmitted(): void {
    if (!this.form.valid || this.form.pristine) {
      return;
    }
    this.dialogRef.close({
      id: this.data.id,
      parameterKeyValues: this.data.parameterKeyValues.map((pkv, index) => {
        return {
          ...pkv,
          value: this.parameterKeyValuesFormArray.at(index).value,
        };
      }),
    });
  }
}

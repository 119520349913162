<roadrunner-dialog-header [headerText]="headerText"></roadrunner-dialog-header>

<mat-dialog-content>
  <p>
    Are you sure you want to delete the {{ option.parameterKey }} option? This
    will affect any rates using this option. This action is irreversible.
  </p>
</mat-dialog-content>

<div mat-dialog-actions align="end">
  <button mat-button type="button" [matDialogClose]="null">Cancel</button>
  <button
    mat-raised-button
    type="button"
    color="warn"
    class="ml-5"
    [matDialogClose]="true"
    cdkFocusInitial
  >
    Delete {{ option.parameterKey }}
  </button>
</div>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'roadrunner-dialog-header',
  templateUrl: './dialog-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogHeaderComponent {
  @Input() headerText: string | null = null;
  @Input() matDialogCloseValue: unknown | null = null;
}

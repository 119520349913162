import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  DeleteBucketMutationResponse,
  DeleteBucketMutationVariables,
  deleteBucketMutation,
  DeletedBucketResponse,
} from '../mutations/delete-bucket.mutation';

@Injectable({ providedIn: 'root' })
export class BucketsService {
  constructor(private apollo: Apollo) {}

  delete(bucketId: number): Observable<DeletedBucketResponse> {
    return (
      this.apollo
        .mutate<DeleteBucketMutationResponse, DeleteBucketMutationVariables>({
          mutation: deleteBucketMutation,
          variables: { bucketId },
        })
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .pipe(map((response) => response.data!.delete_bucket_by_pk))
    );
  }
}

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { BaseRate } from 'libs/gateway/trpc/src/lib/router/base-rates-router';
import { RateException } from './models/rate-exception';
import { RateSliceRate, RateSliceBundledRate } from './models/rate-slice-rate';

export function getBucketBaseRate(
  rate:
    | Omit<BaseRate, 'bucketRateIds'>
    | Omit<RateException, 'productParameterKeyCombinationIds'>
    | RateSliceRate
    | RateSliceBundledRate
): number | null {
  if ((rate as RateSliceRate).bundledRates?.length) {
    return null;
  }
  return rate.base ?? 0;
}

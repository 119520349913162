import { AgGridAngular } from '@ag-grid-community/angular';
import { ColDef, IServerSideDatasource } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { defaultColDef } from '@roadrunner/shared/ui-grid';
import { ProductListModel } from '../product-type-list-grid.models';

@Component({
  selector: 'app-products-grid',
  templateUrl: './products-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsGridComponent {
  @Input() columnDefs: ColDef[] = [];

  @Input() columnTypes: Record<string, ColDef> = {};

  @Input() defaultColDef = defaultColDef;

  @Input() serverSideDatasource!: IServerSideDatasource;

  @ViewChild(AgGridAngular) grid?: AgGridAngular;

  @Output() rowClicked = new EventEmitter<ProductListModel>();

  onRowClicked(productListModel: ProductListModel): void {
    this.rowClicked.emit(productListModel);
  }
}

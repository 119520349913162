import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { uniqueValidator } from '@roadrunner/shared/util-forms';
import {
  AddParameterDialogData,
  ParameterTypeListItem,
} from './add-parameter-dialog-data';
import { AddParameterDialogResult } from './add-parameter-dialog-result';

@Component({
  selector: 'rating-add-parameter',
  templateUrl: './add-parameter.component.html',
  styleUrls: ['./add-parameter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddParameterComponent {
  form: UntypedFormGroup;

  parameterTypes: ParameterTypeListItem[];

  parameterTypeTriggerText: string | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: AddParameterDialogData,
    private dialogRef: MatDialogRef<
      AddParameterDialogData,
      AddParameterDialogResult
    >
  ) {
    const parameterTypeControl = new UntypedFormControl(
      null,
      Validators.required
    );

    this.parameterTypes = data.parameterTypes;

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, [
        Validators.required,
        uniqueValidator(data.parameterNames),
      ]),
      description: new UntypedFormControl(null, Validators.required),
      parameterType: parameterTypeControl,
    });
  }

  onParameterTypeChange(change: MatSelectChange): void {
    this.parameterTypeTriggerText = change.value
      ? (change.value as ParameterTypeListItem).type
      : null;
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.dialogRef.close({
        name: this.form.value.name,
        description: this.form.value.description,
        parameterTypeId: this.form.getRawValue().parameterType.id,
      });
    }
  }
}

import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { SaveFinalCostsResponse } from './save-final-costs.interface';
import { saveFinalCostsMutation } from './save-final-costs.mutation';
import {
  SaveFinalCostsVariable,
  UpsertProductParameterKeyCombination,
} from './save-final-costs.variable';

export class SaveFinalCostsData {
  static saveFinalCosts(
    apollo: Apollo,
    combinations: UpsertProductParameterKeyCombination[]
  ): Observable<SaveFinalCostsResponse> {
    return (
      apollo
        .mutate<SaveFinalCostsResponse, SaveFinalCostsVariable>({
          mutation: saveFinalCostsMutation,
          variables: { combinations },
        })
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .pipe(map((res) => res.data!))
    );
  }
}

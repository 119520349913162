import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from 'environments/environment';
import { AuthGuard } from './guards/auth.guard';

export class AuthConfig {
  constructor(
    public clientId: string,
    public tenantId: string,
    public interceptUrls: string[],
    public redirectUri: string = window.origin
  ) {}
}

const httpInterceptors = environment.requireAuth
  ? [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true,
      },
    ]
  : [];

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule, MsalModule],
  bootstrap: [MsalRedirectComponent],
  providers: [
    ...httpInterceptors,
    {
      provide: MSAL_INSTANCE,
      useFactory: (authConfig: AuthConfig) =>
        SharedAuthModule.MSALInstanceFactory(
          authConfig.clientId,
          authConfig.tenantId,
          authConfig.redirectUri
        ),
      deps: [AuthConfig],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: SharedAuthModule.MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: (authConfig: AuthConfig) =>
        SharedAuthModule.MSALInterceptorConfigFactory(authConfig.interceptUrls),
      deps: [AuthConfig],
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AuthGuard,
  ],
})
export class SharedAuthModule {
  private static MSALInstanceFactory(
    clientId: string,
    tenantId: string,
    redirectUri: string
  ): IPublicClientApplication {
    return new PublicClientApplication({
      auth: {
        clientId,
        authority: `https://login.microsoftonline.com/${tenantId}`,
        redirectUri,
        postLogoutRedirectUri: redirectUri,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: false,
      },
      system: {
        allowRedirectInIframe: true,
      },
    });
  }

  private static MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['user.read'],
      },
      loginFailedRoute: '/login-failed',
    };
  }

  private static MSALInterceptorConfigFactory(
    interceptUrls: string[]
  ): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
      'user.read',
    ]);
    for (const interceptUrl of interceptUrls) {
      protectedResourceMap.set(interceptUrl, ['user.read']);
    }
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap,
    };
  }
}

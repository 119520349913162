import { AbstractControl } from '@angular/forms';

export function removeError(control: AbstractControl, errorKey: string): void {
  if (control.errors?.[errorKey]) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [errorKey]: dontCare, ...newErrors } = control.errors;
    if (Object.keys(newErrors).length > 0) {
      control.setErrors(newErrors);
    } else {
      control.setErrors(null);
    }
  }
}

export function addError(control: AbstractControl, errorKey: string): void {
  control.setErrors({ ...control.errors, [errorKey]: true });
  if (!control.touched) {
    control.markAsTouched();
  }
}

/**
 * Adds or removes an error from one or more controls based on the given error state.
 * @param errorState Whether the control(s) should have the error applied
 * @param errorKey The error key
 * @param controls The control(s) whose error state(s) should be modified
 * @example
 * addOrRemoveError(
 *    isDateRangeInvalid(startDateValue, endDateValue),
 *    'dateRange',
 *    startDateControl,
 *    endDateControl
 * )
 */
export function addOrRemoveError(
  errorState: boolean,
  errorKey: string,
  ...controls: AbstractControl[]
): void {
  if (errorState) {
    controls.forEach((c) => addError(c, errorKey));
  } else {
    controls.forEach((c) => removeError(c, errorKey));
  }
}

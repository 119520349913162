import { createAction, props } from '@ngrx/store';
import { Program } from './program.models';

export const programsGuardActivated = createAction(
  '[Programs Guard] Activated'
);
export const programsGuardDeactivated = createAction(
  '[Programs Guard] Deactivated'
);

export const loadProgramsSuccess = createAction(
  '[Programs/API] Load Programs Success',
  props<{ program: Program[] }>()
);

export const loadProgramsFailure = createAction(
  '[Programs/API] Load Programs Failure',
  props<{ error: unknown }>()
);

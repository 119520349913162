import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { tomorrowMidnight } from '@roadrunner/shared/util-dates';

@Component({
  selector: 'app-confirm-publish-rates',
  templateUrl: './confirm-publish-rates.component.html',
  styleUrls: ['./confirm-publish-rates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmPublishRatesComponent {
  effectiveDateControl = new UntypedFormControl(tomorrowMidnight(), [
    Validators.required,
  ]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { productName: string },
    private dialogRef: MatDialogRef<
      ConfirmPublishRatesComponent,
      { effectiveDate: Date } | null
    >
  ) {}

  onSubmit(): void {
    // Re-check validity on submit in case a user leaves the dialog open forever and tries to publish with a date that is no longer valid.
    this.effectiveDateControl.updateValueAndValidity();
    if (this.effectiveDateControl.valid) {
      this.dialogRef.close({
        effectiveDate: this.effectiveDateControl.value as Date,
      });
    }
  }
}

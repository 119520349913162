import { gql } from 'apollo-angular';

export const saveFinalCostsMutation = gql`
  mutation SaveFinalCosts(
    $combinations: [product_parameter_key_combination_insert_input!]!
  ) {
    insert_product_parameter_key_combination(
      objects: $combinations
      on_conflict: {
        constraint: product_parameter_key_combination_pkey
        update_columns: [dealer_cost, msrp]
      }
    ) {
      affected_rows
    }
  }
`;

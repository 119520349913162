import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-delete-coverage',
  templateUrl: './delete-coverage.component.html',
})
export class DeleteCoverageComponent implements OnInit {
  coverageName!: string;
  coverageCode!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: { coverageName: string; coverageCode: string }
  ) {}

  ngOnInit(): void {
    this.coverageName = this.data.coverageName;
    this.coverageCode = this.data.coverageCode;
  }
}

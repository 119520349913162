import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { IDeleteDealerCostRoundingResponse } from './delete-dealer-cost-rounding.interface';
import { deleteDealerCostRoundingMutation } from './delete-dealer-cost-rounding.mutation';

export class DeleteDealerCostRoundingData {
  static deleteDealerCostRounding(
    apollo: Apollo,
    productId: number
  ): Observable<IDeleteDealerCostRoundingResponse> {
    return (
      apollo
        .mutate<IDeleteDealerCostRoundingResponse>({
          mutation: deleteDealerCostRoundingMutation,
          variables: { productId },
        })
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .pipe(map((res) => res.data!))
    );
  }
}

import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { IUpdateBucketResponse } from './update-bucket.interface';
import { updateBucketMutation } from './update-bucket.mutation';

export class UpdateBucketData {
  static updateBucket(
    apollo: Apollo,
    bucketId: number,
    bucketName: string,
    cmsBucketNumber: number | null,
    reserves: boolean,
    payeeId: number
  ): Observable<IUpdateBucketResponse> {
    return (
      apollo
        .mutate<IUpdateBucketResponse>({
          mutation: updateBucketMutation,
          variables: {
            bucketId,
            bucketName,
            cmsBucketNumber,
            reserves,
            payeeId,
          },
        })
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .pipe(map((res) => res.data!))
    );
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  reducer,
  PARAMETER_TYPE_FEATURE_KEY,
} from './state/parameter-type.reducer';
import { ParameterTypeEffects } from './state/parameter-type.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(PARAMETER_TYPE_FEATURE_KEY, reducer),
    EffectsModule.forFeature([ParameterTypeEffects]),
  ],
})
export class RatingUtilityDataAccessParameterTypeModule {}

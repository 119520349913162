import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { updateBucketMinMaxMutation } from './update-bucket-min-max.mutation';

export class UpdateBucketMinMaxData {
  static updateBucketMinMax(
    apollo: Apollo,
    bucketId: number,
    min: number | null,
    max: number | null
  ): Observable<void | null> {
    return apollo
      .mutate<void>({
        mutation: updateBucketMinMaxMutation,
        variables: {
          bucketId,
          min,
          max,
          hasMin: min != null,
          hasMax: max != null,
        },
      })
      .pipe(map((_) => null));
  }
}

import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { IUpsertBucketOffsetResponse } from './upsert-bucket-offset.interface';
import { UpsertBucketOffsetVariable } from './upsert-bucket-offset.variable';
import { upsertBucketOffsetMutation } from './upsert-bucket-offset.mutation';

export class UpsertBucketOffsetData {
  static upsertBucketOffset(
    apollo: Apollo,
    bucketId: number,
    offsetBucketId: number,
    offsetType: string
  ): Observable<IUpsertBucketOffsetResponse> {
    return (
      apollo
        .mutate<IUpsertBucketOffsetResponse, UpsertBucketOffsetVariable>({
          mutation: upsertBucketOffsetMutation,
          variables: {
            bucketOffset: {
              bucket_id: bucketId,
              offset_bucket_id: offsetBucketId,
              offset_type: offsetType,
            },
          },
        })
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .pipe(map((res) => res.data!))
    );
  }
}

import { createAction, props } from '@ngrx/store';
import { ParameterSubtype, ParameterType } from './parameter-type.models';

export const init = createAction('[ParameterType Page] Init');

export const loadParameterTypesSuccess = createAction(
  '[ParameterTypes/API] Load ParameterTypes Success',
  props<{
    parameterTypes: ParameterType[];
    parameterSubtypes: ParameterSubtype[];
  }>()
);

export const loadParameterTypesFailure = createAction(
  '[ParameterTypes/API] Load ParameterTypes Failure',
  props<{ error: unknown }>()
);

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, map, Observable, startWith } from 'rxjs';
import { Store } from '@ngrx/store';
import { ProgramActions } from '@roadrunner/rating-utility/data-access-program';

import { IProgramVM } from '../../../models/view-models/programs/program.interface';
import { OnDestroySubs } from '../../../shared/components/onDestroySubs';
import * as UserActions from '../../../store/user/user.actions';
import { selectProgramList } from '../../../store/user/user.selectors';

@Component({
  selector: 'app-choose-program',
  templateUrl: './choose-program.component.html',
  styleUrls: ['./choose-program.component.scss'],
})
export class ChooseProgramComponent extends OnDestroySubs implements OnInit {
  form = new FormGroup({
    program: new FormControl<IProgramVM | string | null | undefined>(null, [
      Validators.required,
      (control: FormControl<IProgramVM | string | null | undefined>) => {
        if (control.value != null && typeof control.value === 'object') {
          return null;
        }
        return { required: true };
      },
    ]),
  });

  private programs$: Observable<IProgramVM[]> = this.store
    .select(selectProgramList)
    .pipe(map((programs) => programs ?? []));

  filteredPrograms$ = combineLatest([
    this.programs$,
    this.form.controls.program.valueChanges.pipe(
      startWith(this.form.controls.program.value)
    ),
  ]).pipe(
    map(([allPrograms, searchTermOrProgram]) => {
      if (
        searchTermOrProgram == null ||
        typeof searchTermOrProgram === 'object'
      ) {
        return allPrograms;
      }
      const searchTerm = searchTermOrProgram?.trim().toLocaleLowerCase();
      const filteredPrograms = allPrograms.filter(
        (p) =>
          p.agentCode.toLocaleLowerCase().includes(searchTerm) ||
          p.name.toLocaleLowerCase().includes(searchTerm)
      );
      const currentFormValue = this.form.controls.program.value;
      // If the user types an exact match (say, 'gmf'), then immediately set the form value to the matching program object
      if (
        filteredPrograms.length === 1 &&
        filteredPrograms[0].name.toLocaleLowerCase() === searchTerm &&
        currentFormValue !== filteredPrograms[0]
      ) {
        this.form.controls.program.setValue(filteredPrograms[0]);
      }
      return filteredPrograms;
    })
  );

  noPrograms$ = this.filteredPrograms$.pipe(
    map((programs) => programs.length === 0)
  );

  constructor(private store: Store) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(ProgramActions.init());
  }

  chooseProgram() {
    const program = this.form.controls.program.value;
    if (program != null && typeof program === 'object') {
      this.store.dispatch(UserActions.chooseProgram({ program }));
    }
  }

  displayProgram(program: IProgramVM | string | null) {
    if (typeof program === 'string') {
      return program;
    }
    return program?.name ?? '';
  }

  onAddProgramClick(): void {
    this.store.dispatch(ProgramActions.addProgramClicked());
  }
}

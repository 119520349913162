// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
export type ParameterKeyValueFieldsFragment = { id: number, parameter_key_id: number, parameter_subtype_id: number, value: string, parameter_subtype: { id: number, parameter_type_id: number, sort_order: number, subtype: string, control_type: string, is_identifier: boolean, visible: boolean, isUnique: boolean } };

export const ParameterKeyValueFieldsFragmentDoc = gql`
    fragment ParameterKeyValueFields on parameter_key_value {
  id
  parameter_key_id
  parameter_subtype_id
  value
  parameter_subtype {
    id
    parameter_type_id
    sort_order
    subtype
    control_type
    is_identifier
    isUnique: is_unique
    visible
  }
}
    `;
import { gql } from 'apollo-angular';

export const deleteBucketRateExceptionsMutation = gql`
  mutation DeleteBucketRateExceptions(
    $productParameterKeyCombinationIds: [bigint!]!
  ) {
    delete_bucket_rate_exception(
      where: {
        product_parameter_key_combination_id: {
          _in: $productParameterKeyCombinationIds
        }
      }
    ) {
      affected_rows
    }
  }
`;

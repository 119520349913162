import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ControlType } from '@roadrunner/rating-utility/data-access-parameter-type';

@Component({
  selector: 'app-parameter-option-form-fields',
  templateUrl: './parameter-option-form-fields.component.html',
  styleUrls: ['./parameter-option-form-fields.component.scss'],
})
export class ParameterOptionFormFieldsComponent {
  @Input() optionNameControl?: UntypedFormControl;
  @Input() optionKeyValuesArrayControls?: UntypedFormGroup[];
  @Input() isFormFieldsInDialog: boolean = false;

  controlType = ControlType;
}

import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { IUpsertBucketRoundingResponse } from './upsert-bucket-rounding.interface';
import { UpsertBucketRoundingVariable } from './upsert-bucket-rounding.variable';
import { upsertBucketRoundingMutation } from './upsert-bucket-rounding.mutation';

export class UpsertBucketRoundingData {
  static upsertBucketRounding(
    apollo: Apollo,
    bucketId: number,
    roundingValue: number,
    roundingType: string
  ): Observable<IUpsertBucketRoundingResponse> {
    return (
      apollo
        .mutate<IUpsertBucketRoundingResponse, UpsertBucketRoundingVariable>({
          mutation: upsertBucketRoundingMutation,
          variables: {
            bucketRounding: {
              bucket_id: bucketId,
              round_by: roundingValue,
              round_type: roundingType,
            },
          },
        })
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .pipe(map((res) => res.data!))
    );
  }
}

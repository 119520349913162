import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  MatLegacyCheckboxDefaultOptions as MatCheckboxDefaultOptions,
  MAT_LEGACY_CHECKBOX_DEFAULT_OPTIONS as MAT_CHECKBOX_DEFAULT_OPTIONS,
} from '@angular/material/legacy-checkbox';
import {
  MatLegacyFormFieldDefaultOptions as MatFormFieldDefaultOptions,
  MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/legacy-form-field';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedAuthModule } from '@roadrunner/shared/auth';
import { ErrorService } from '@roadrunner/shared/ui-errors';
import { SharedUiLayoutModule } from '@roadrunner/shared/ui-layout';
import { SharedUtilModalModule } from '@roadrunner/shared/util-modal';
import { trpcError$ } from '@roadrunner/shared/util-trpc';
import { UiToggleService } from 'libs/shared/ui-layout/src/lib/service';
import { DataService } from './apollo/data.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SessionsModule } from './pages/sessions/sessions.module';
import { SharedComponentsModule } from './shared/components/shared-components.module';
import { GraphQLModule } from './shared/graphql.module';
import { SelectedProgramGuard } from './shared/guards/selected-program.guard';
import { httpErrorInterceptorProvider } from './shared/http-error-interceptor';
import { NgrxModule } from './store/ngrx.module';

const matCheckboxDefaults: MatCheckboxDefaultOptions = {
  color: 'primary',
};
const matFormFieldDefaults: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

@NgModule({
  declarations: [AppComponent],  
  imports: [
    BrowserModule,
    BrowserAnimationsModule.withConfig({
      disableAnimations: window.matchMedia('(prefers-reduced-motion)').matches,
    }),
    SharedUtilModalModule,
    SharedUiLayoutModule,
    SharedComponentsModule,
    HttpClientModule,
    AppRoutingModule,
    GraphQLModule,
    NgrxModule,
    SharedAuthModule,
    SessionsModule,
  ],
  providers: [
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: matCheckboxDefaults },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: matFormFieldDefaults },
    SelectedProgramGuard,
    httpErrorInterceptorProvider,
    UiToggleService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(public data: DataService, private errorService: ErrorService) {
    trpcError$.subscribe((error) => {
      this.errorService.showTrpcError(error);
    });
  }
}

import {
  QueryMode,
  StringFilter,
} from 'graphql/generated-client/gateway-schema.types';

export interface FilterTypeText {
  filterType: 'text';
  filter: string;
}

export function buildQueryFilterTypeText(searchTerm: string): StringFilter {
  return {
    contains: searchTerm,
    mode: QueryMode.Insensitive,
  };
}

import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { ProductMsrpOperation } from '../../../models/view-models/products/msrp-operations.view-model';
import { deleteMsrpOperationMutation } from './delete-msrp-operation.mutation';
import {
  DeleteMsrpOperationResponse,
  SaveMsrpOperationResponse,
} from './save-msrp-operation.interface';
import { saveMsrpOperationMutation } from './save-msrp-operation.mutation';
import {
  DeleteMsrpOperationVariables,
  SaveMsrpOperationVariables,
} from './save-msrp-operation.variable';

export class SaveMsrpOperationData {
  static saveMsrpOperation(
    apollo: Apollo,
    productMsrpParameterKeyCombinationId: number,
    operation: ProductMsrpOperation
  ) {
    return apollo
      .mutate<SaveMsrpOperationResponse, SaveMsrpOperationVariables>({
        mutation: saveMsrpOperationMutation,
        variables: {
          combinationId: productMsrpParameterKeyCombinationId,
          operand: operation.operand,
          operator: operation.operator,
        },
      })
      .pipe(map((res) => res.data));
  }

  static deleteMsrpOperation(
    apollo: Apollo,
    productMsrpParameterKeyCombinationId: number,
    operator: string
  ) {
    return apollo
      .mutate<DeleteMsrpOperationResponse, DeleteMsrpOperationVariables>({
        mutation: deleteMsrpOperationMutation,
        variables: {
          combinationId: productMsrpParameterKeyCombinationId,
          operator: operator,
        },
      })
      .pipe(map((res) => res.data));
  }
}

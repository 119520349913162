import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { race } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { OnDestroySubs } from '../../../../shared/components/onDestroySubs';
import { PayeeSelectorComponent } from '../../../../shared/components/payee-selector/payee-selector.component';
import { FormUtility } from '../../../../shared/utility/form.utility';
import {
  addPayeeCancelled,
  addPayeeClicked,
  addPayeeSuccess,
} from '../../../../store/rate/rate.actions';
import { selectPayees } from '../../../../store/rate/rate.selectors';
import { CreateRateSliceDialogData } from './create-rate-slice-dialog-data.interface';
import { CreateRateSliceDialogResult } from './create-rate-slice-dialog-result.interface';

@Component({
  selector: 'app-create-rate-slice-dialog',
  templateUrl: './create-rate-slice-dialog.component.html',
})
export class CreateRateSliceDialogComponent
  extends OnDestroySubs
  implements OnInit
{
  @ViewChild(PayeeSelectorComponent, { static: true })
  payeeSelectorComponent!: PayeeSelectorComponent;

  @ViewChild('addPayeeButton', { static: true, read: ElementRef })
  addPayeeButtonEl!: ElementRef;

  private payeeFormControlName = 'payeeFormControl';
  private bucketNameFormControlName = 'bucketNameFormControl';
  private cmsBucketNumberControlName = 'cmsBucketNumber';
  private reserves = 'reserves';

  newBucketFormGroup!: UntypedFormGroup;

  payees$ = this.store.select(selectPayees);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CreateRateSliceDialogData,
    private dialogRef: MatDialogRef<
      CreateRateSliceDialogComponent,
      CreateRateSliceDialogResult
    >,
    private store: Store,
    private actions: Actions,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    const payeeControl = new UntypedFormControl(null);
    this.newBucketFormGroup = new UntypedFormGroup({
      [this.bucketNameFormControlName]: new UntypedFormControl('', [
        Validators.required,
        FormUtility.unique(this.data.forbiddenNames),
      ]),
      [this.payeeFormControlName]: payeeControl,
      [this.cmsBucketNumberControlName]: new UntypedFormControl(null),
      [this.reserves]: new UntypedFormControl(false),
    });
  }

  get bucketNameControl(): UntypedFormControl {
    return this.newBucketFormGroup.get(
      this.bucketNameFormControlName
    ) as UntypedFormControl;
  }

  get cmsBucketNumberControl(): UntypedFormControl {
    return this.newBucketFormGroup.get(
      this.cmsBucketNumberControlName
    ) as UntypedFormControl;
  }

  get payeeControl(): UntypedFormControl {
    return this.newBucketFormGroup.get(
      this.payeeFormControlName
    ) as UntypedFormControl;
  }

  get reservesControl(): UntypedFormControl {
    return this.newBucketFormGroup.get(this.reserves) as UntypedFormControl;
  }

  formSubmitted() {
    if (!this.newBucketFormGroup.valid) {
      return;
    }
    this.dialogRef.close({
      bucketName: this.bucketNameControl.value,
      payeeId: this.payeeControl.value.id,
      cmsBucketNumber: this.cmsBucketNumberControl.value,
      reserves: this.reservesControl.value,
    });
  }

  onAddPayeeClick(): void {
    race([
      this.actions.pipe(
        ofType(addPayeeCancelled),
        map(() => null)
      ),
      this.actions.pipe(
        ofType(addPayeeSuccess),
        map((action) => action.payee)
      ),
    ])
      .pipe(take(1), takeUntil(this.componentDestroyed$))
      .subscribe((payee) => {
        if (payee) {
          this.payeeControl.setValue(payee);
          this.payeeControl.markAsDirty();
          this.payeeControl.markAsTouched();
          this.payeeSelectorComponent.focus();
        } else {
          this.addPayeeButtonEl.nativeElement.focus();
        }
        this.changeDetectorRef.markForCheck();
      });
    this.store.dispatch(addPayeeClicked());
  }
}

import { formatNumber } from '@angular/common';
import { AbstractControl, ValidationErrors } from '@angular/forms';

export function lessThan(value: number) {
  const valueFormatted = formatNumber(value, 'en-US');
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null) {
      return null;
    }
    if (typeof control.value === 'number' && control.value >= value) {
      return { lessThan: { lessThan: valueFormatted, actual: control.value } };
    }
    return null;
  };
}

// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetAllProgramsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllProgramsQuery = { program: Array<{ id: number, name: string, cms: boolean, agentCode: string }> };

export const GetAllProgramsDocument = gql`
    query GetAllPrograms {
  program {
    id
    name
    agentCode: agent_code
    cms
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllProgramsGQL extends Apollo.Query<GetAllProgramsQuery, GetAllProgramsQueryVariables> {
    override document = GetAllProgramsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { uniqueValidator } from '@roadrunner/shared/util-forms';
import {
  AddProgramDialogData,
  DialogDataType,
  EditProgramDialogData,
} from './add-program-dialog-data';
import {
  AddProgramDialogResult,
  EditProgramDialogResult,
} from './add-program-dialog-result';

@Component({
  selector: 'rating-add-program',
  templateUrl: './add-program.component.html',
  styleUrls: ['./add-program.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProgramComponent {
  form: UntypedFormGroup;

  headerText: string;

  submitButtonText: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: AddProgramDialogData | EditProgramDialogData,
    private dialogRef: MatDialogRef<
      null,
      AddProgramDialogResult | EditProgramDialogResult
    >
  ) {
    const isAdd = data.dataType === DialogDataType.Add;

    this.headerText = isAdd ? 'Add a new Program' : 'Edit Program';
    this.submitButtonText = isAdd ? 'Create Program' : 'Save Program';

    const name = new UntypedFormControl(isAdd ? null : data.programName);
    const agentCode = new UntypedFormControl(isAdd ? null : data.agentCode);
    const cms = new UntypedFormControl(isAdd ? false : data.cms);

    this.form = new UntypedFormGroup({
      name,
      agentCode,
      cms,
    });

    if (isAdd) {
      this.setValidatorsForAdd(name, agentCode, data);
    } else {
      name.disable();
      agentCode.disable();
    }
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.dialogRef.close(
        this.data.dataType === DialogDataType.Add
          ? {
              name: this.form.value.name,
              agentCode: this.form.value.agentCode,
              cms: this.form.value.cms,
            }
          : {
              id: this.data.id,
              cms: this.form.value.cms,
            }
      );
    }
  }

  private setValidatorsForAdd(
    name: UntypedFormControl,
    agentCode: UntypedFormControl,
    data: AddProgramDialogData
  ): void {
    name.setValidators([
      Validators.required,
      uniqueValidator(data.programNames),
    ]);

    agentCode.setValidators([
      Validators.required,
      Validators.maxLength(6),
      uniqueValidator(data.agentCodes),
    ]);
  }
}

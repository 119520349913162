// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { BaseRate } from 'libs/gateway/trpc/src/lib/router/base-rates-router';
import { RateException } from './models/rate-exception';
import { RateSliceRate } from '../../store/bucket/models/rate-slice-rate';
import { BundledRate } from '../../models/view-models/buckets/bundled-rate.interface';
import { RateFactorConverters } from '../../models/view-models/rate-factors/rate-factor-converters.interface';

export function getApplicableRateFactorConverters(
  rateFactorConverters: RateFactorConverters,
  data: RateException | BaseRate | RateSliceRate | BundledRate
): RateFactorConverters {
  if ((data as RateSliceRate).bundledRates?.length) {
    return [];
  }

  const result: RateFactorConverters = [];

  rateFactorConverters.forEach((candidate) => {
    if (
      candidate.parameterKeyIds.every((pkid) =>
        data.parameterKeyIds.includes(pkid)
      )
    ) {
      result.push(candidate);
    }
  });

  return result;
}

import { NonSellableCombination as NonSellableCombinationResponse } from '../../queries/get-non-sellable-combinations/get-non-sellable-combinations.interface';
import { NonSellableCombination } from '../../../models/view-models/products/non-sellable-combinations.view-model';
import {
  Non_Sellable_Combination_Insert_Input,
  Non_Sellable_Combination_Option_Constraint,
  Non_Sellable_Combination_Option_Insert_Input,
  Non_Sellable_Combination_Option_Update_Column,
  Non_Sellable_Combination_Option_On_Conflict,
} from 'graphql/generated-client/gateway-schema.types';
import { NonSellableCombinationsViewModel } from '../../queries/get-non-sellable-combinations/get-non-sellable-combinations.interface';
import { UpsertNonSellableCombinationsMutationVariables } from './upsert-non-sellable-combinations.mutation.generated';

const onConflict: Non_Sellable_Combination_Option_On_Conflict = {
  constraint:
    Non_Sellable_Combination_Option_Constraint.NonSellableCombinationOptionPkey,
  update_columns: [
    Non_Sellable_Combination_Option_Update_Column.ParameterKeyId,
  ],
};

export function buildUpsertNonSellableCombinationsVariables(
  productId: number,
  newCombinations: NonSellableCombination[],
  res: NonSellableCombinationsViewModel,
  parameterIdsByKeyId: Map<number, number>
): UpsertNonSellableCombinationsMutationVariables {
  const combinationIds: number[] = [];
  const combinationOptionIds: number[] = [];
  const combinations: Non_Sellable_Combination_Insert_Input[] = [];
  for (const combination of newCombinations) {
    const matchingCombination = getMatchingCombination(
      res.non_sellable_combinations,
      combination
    );

    let id: number | undefined;
    let nonSellableCombinationOptions: Non_Sellable_Combination_Option_Insert_Input[];

    if (matchingCombination) {
      id = matchingCombination.id;
      combinationIds.push(matchingCombination.id);
      nonSellableCombinationOptions =
        matchingCombination.non_sellable_combination_options.map((op) => {
          return {
            id: op.id,
            parameter_key_id: op.parameter_key_id,
          };
        });
    } else {
      nonSellableCombinationOptions = combination.parameterKeyIds.map(
        (pkid) => {
          return {
            parameter_key_id: pkid,
          };
        }
      );
    }

    combinations.push({
      id,
      product_id: productId,
      parameter_key_ids: buildParameterKeyIdsJson(
        combination.parameterKeyIds,
        parameterIdsByKeyId
      ),
      non_sellable_combination_options: {
        data: nonSellableCombinationOptions,
        on_conflict: onConflict,
      },
    });
  }

  return {
    productId,
    combinationIds,
    combinationOptionIds,
    combinations,
  };
}

function getMatchingCombination(
  searchCombinations: NonSellableCombinationResponse[],
  findCombo: NonSellableCombination
) {
  return searchCombinations.find(
    (c) =>
      c.non_sellable_combination_options.length ===
        findCombo.parameterKeyIds.length &&
      c.non_sellable_combination_options.every((op1) =>
        findCombo.parameterKeyIds.some((pkid) => op1.parameter_key_id === pkid)
      )
  );
}

function buildParameterKeyIdsJson(
  parameterKeyIds: number[],
  parameterIdsByKeyId: Map<number, number>
) {
  return parameterKeyIds.reduce((ids: Record<number, number>, pkid) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ids[parameterIdsByKeyId.get(pkid)!] = pkid;
    return ids;
  }, {});
}

import { Injectable } from '@angular/core';
import * as ProductActions from './product.actions';
import { selectProductLogInfo } from './product.selectors';
import { LoggingService } from '@roadrunner/shared/client-logging';

@Injectable()
export class ProductLoggingEffects {
  private getLogEffect =
    this.loggingService.getLogEffectCreator(selectProductLogInfo);

  private getSimpleChangeLogEffect =
    this.loggingService.getSimpleValueChangeEffectCreator(selectProductLogInfo);

  constructor(private loggingService: LoggingService) {}

  LogProductSettingsChanged$ = this.getSimpleChangeLogEffect(
    'productSettingsChanged',
    ProductActions.saveProductSettingsSuccess,
    (a) => a.newSettings,
    (a) => a.oldSettings,
    (a) => ({ productId: a.settingsResponse.insert_product_one.id })
  );

  logProductParameterKeysChanged$ = this.getLogEffect(
    'productParameterKeysChanged',
    ProductActions.saveProductParameterKeysSuccess,
    (a) => ({
      newParameterKeys: a.newParameterKeys.map((pk) => pk.key),
      deletedParameterKeys: a.deletedParameterKeys.map((pk) => ({
        id: pk.parameterKeyId,
        key: pk.parameterKey,
      })),
    })
  );

  logProductCoverageKeysChanged$ = this.getLogEffect(
    'productCoverageKeysChanged',
    ProductActions.saveProductCoverageKeysSuccess,
    (a) => ({
      newParameterKeys: a.newParameterKeys.map((pk) => pk.key),
      deletedParameterKeys: a.deletedParameterKeys.map((pk) => ({
        id: pk.id,
        key: pk.parameterKeyValues.find((pkv) => !pkv.isIdentifier),
      })),
    })
  );
}

import { GetProductParameterKeysResponse } from '../../apollo/queries/get-product-parameter-keys-by-product-id/get-product-parameter-keys-by-product-id.interface';
import { GetParametersResponse } from '../../apollo/queries/get-parameters/get-parameters.interface';
import {
  IProductParameterKeyVM,
  IProductParameterVM,
} from '../../models/view-models/products/product-options.view-model';

export class RateReducersMapping {
  static mapProductParameters(
    parameterKeys: GetProductParameterKeysResponse,
    parameters: GetParametersResponse
  ): IProductParameterVM[] {
    const productParameterKeyParameterIds =
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      parameterKeys.product_by_pk!.parameter_keys.map((pk) => pk.parameter_id);
    return parameters.parameter
      .filter((p) => productParameterKeyParameterIds.includes(p.id))
      .map(
        (param): IProductParameterVM => ({
          parameterId: param.id,
          description: param.description,
          parameterName: param.name,
          parameterType: {
            parameterTypeID: param.parameter_type_id,
            description: param.description,
            parameterSubTypes: [],
            // TODO: remove !
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            type: null!,
            bundleable: false,
          },
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          keys: parameterKeys
            .product_by_pk!.parameter_keys.filter(
              (pk) => pk.parameter_id === param.id
            )
            .map(
              (pk): IProductParameterKeyVM => ({
                parameterKeyId: pk.id,
                parameterId: param.id,
                parameterKey: pk.key,
                productId: pk.product_id,
                sortOrder: pk.sort_order,
                subKeys: [],
                parameterKeyValues: [],
              })
            )
            .sort(
              (a, b) =>
                // TODO: remove !s
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                a.sortOrder! - b.sortOrder! ||
                a.parameterKey.localeCompare(b.parameterKey)
            ),
        })
      );
  }
}

import { FormGroup, NgForm } from '@angular/forms';

export interface FormComponent {
  form?: FormGroup;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isFormComponent(component: any): component is FormComponent {
  return (
    component?.form &&
    (component.form instanceof FormGroup || component.form instanceof NgForm)
  );
}

export class DRYUtility {
  static isNullOrUndefined<T>(
    obj: T | null | undefined
  ): obj is null | undefined {
    // as opposed to generally falsey (like zero)
    return obj === undefined || obj === null;
  }

  static isNullOrUndefinedOrEmpty<T>(arr: T[] | null | undefined): arr is T[] {
    return !arr || !arr.length;
  }

  static deepCopy(obj: null): null;
  static deepCopy<T>(obj: T): T;
  static deepCopy(obj: unknown) {
    return obj ? JSON.parse(JSON.stringify(obj)) : null;
  }
}

import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export class FormUtility {
  public static uniqueArray(existingNameArrays: string[][]) {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return existingNameArrays.some((arr) =>
        this.isUnorderedMatch(arr, control.value)
      )
        ? { uniqueArray: control.value }
        : null;
    };
  }

  // to be valid, the form field value must match (case-insensitive) any of the existingNames
  public static unique(existingNames: string[]) {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return this.isDuplicateName(existingNames, control.value)
        ? { unique: control.value }
        : null;
    };
  }

  public static formChanged(
    initialFormValue: unknown,
    form: AbstractControl
  ): Observable<boolean> {
    return form.valueChanges.pipe(
      map((val) => JSON.stringify(val) !== JSON.stringify(initialFormValue)),
      distinctUntilChanged()
    );
  }

  public static setEnabled(isEnabled: boolean, control: AbstractControl) {
    if (isEnabled) {
      control.enable();
    } else {
      control.disable();
    }
  }

  public static setEnabledAndRemoveValueIfDisabled(
    isEnabled: boolean,
    control: AbstractControl
  ) {
    if (!isEnabled) {
      control.setValue(null);
    }
    this.setEnabled(isEnabled, control);
  }

  private static isDuplicateName(existingNames: string[], newName: string) {
    return (
      newName &&
      existingNames &&
      existingNames.length &&
      existingNames.some((name) => this.isMatchingName(name, newName))
    );
  }

  private static isMatchingName(str1: string, str2: string): boolean {
    return str1.toLowerCase().trim() === str2.toLowerCase().trim();
  }

  private static isUnorderedMatch(a: string[], b: string[]): boolean {
    return (
      a &&
      b &&
      a.length === b.length &&
      a.every((strA) => b.some((strB) => this.isMatchingName(strA, strB)))
    );
  }
}

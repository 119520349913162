<roadrunner-dialog-header
  headerText="Looks like this is taking longer than normal"
></roadrunner-dialog-header>
<mat-dialog-content>
  <p class="mb-2">
    We are still processing this request. Please check back after a short while
    to refresh and verify that your changes have taken effect.
  </p>
  <p class="mb-2">
    If you experience any issues, please reach out to
    <a href="mailto:agadir@sgintl.com">agadir@sgintl.com</a> for immediate
    assistance.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [matDialogClose]="null" class="ml-auto" cdkFocusInitial>
    Close
  </button>
</mat-dialog-actions>

import { ThemePalette } from '@angular/material/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';

@Component({
  selector: 'rr-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmitButtonComponent {
  /**
   * Whether the form is being submitted. Causes the button to appear disabled when true.
   */
  @Input() submitting = false;

  /**
   * Whether the submit button should be disabled. Overrides all other concerns like
   * whether the form is dirty/valid, whether the form is being submitted already, etc.
   */
  @Input() disabled = false;

  /**
   * Whether the form is submittable. Causes the button to appear disabled when true.
   * Can be set manually if needed, but also see `SubmitButtonWithFormDirective`.
   */
  @Input() submittable = true;

  @Input() color: ThemePalette | 'success' = 'primary';

  /**
   * Whether the submit button is full-width.
   * @example <rr-submit-button fullWidth></rr-submit-button>
   * @example <rr-submit-button [fullWidth]="true"></rr-submit-button>
   */
  @Input() set fullWidth(fullWidth: string | boolean) {
    this._fullWidth = coerceBooleanProperty(fullWidth);
  }
  get fullWidth(): boolean {
    return this._fullWidth;
  }
  private _fullWidth = false;

  @Output() buttonClick = new EventEmitter<MouseEvent>();

  onClick(e: MouseEvent) {
    this.buttonClick.emit(e);
  }
}

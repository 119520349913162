import { gql } from 'apollo-angular';

export const deleteMsrpOperationMutation = gql`
  mutation DeleteProductMsrpOperation(
    $combinationId: bigint!
    $operator: String!
  ) {
    delete_product_msrp_operation(
      where: {
        _and: [
          { product_msrp_parameter_key_combination_id: { _eq: $combinationId } }
          { operator: { _eq: $operator } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;

import { NgModule } from '@angular/core';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { ErrorDialogModule, ErrorService } from '@roadrunner/shared/ui-errors';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { GraphQLError } from 'graphql';
import { GraphqlErrorMessage } from '../models/graphql-error-message.interface';
import { captureGraphQLErrors } from './capture-graphql-error';
import { getGatewayUrl } from './get-gateway-url';

const url = getGatewayUrl();

function isMutation(response: ErrorResponse) {
  return response.operation.query.definitions.some(
    (def) => def.kind === 'OperationDefinition' && def.operation === 'mutation'
  );
}

function exceedsTimeout(context: Record<string, unknown>): boolean {
  if (typeof context.startTime !== 'number') {
    return false;
  }

  const durationSeconds = (new Date().getTime() - context.startTime) / 1000;
  // The Heroku router timeout is 30s, but 29 gives us some wiggle room
  return durationSeconds > 29;
}

function hasGraphQLErrors(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any
): error is { error: { errors: ReadonlyArray<GraphQLError> } } {
  const errors = error.error?.errors;
  return errors != null && Array.isArray(errors);
}

export function createApollo(
  errorService: ErrorService,
  httpLink: HttpLink
): ApolloClientOptions<unknown> {
  const errorLink = onError((err) => {
    // operation name taken from log-message.mutation.ts
    if (err.operation.operationName === 'SaveLogMessages') {
      return;
    }
    if (err.networkError) {
      if (hasGraphQLErrors(err.networkError)) {
        captureGraphQLErrors(
          err.operation.operationName,
          err.networkError.error.errors
        );
      }

      const context = err.operation.getContext();
      if (isMutation(err) && exceedsTimeout(context)) {
        errorService.showTimeoutError();
      } else {
        errorService.showError({
          header: 'Network Error',
          message: err.networkError.message,
        });
      }
    } else if (err.graphQLErrors) {
      captureGraphQLErrors(err.operation.operationName, err.graphQLErrors);

      errorService.showGraphqlErrors(
        err.graphQLErrors.map((error): GraphqlErrorMessage => {
          return {
            message: error.message,
            code: error.extensions?.code,
            path: error.path ?? error.extensions?.path,
            title: error.extensions?.['title'],
          };
        })
      );
    }
  });

  const timerMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext((context: Record<string, unknown>) => {
      return {
        ...context,
        startTime: new Date().getTime(),
      };
    });
    return forward(operation);
  });

  return {
    link: ApolloLink.from([
      timerMiddleware,
      errorLink,
      httpLink.create({ uri: url }),
    ]),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
      mutate: {
        fetchPolicy: 'no-cache',
      },
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
    },
    cache: new InMemoryCache({
      // since we're not using the cache at all, the __typename field is useless, so avoid the overhead
      addTypename: false,
    }),
  };
}

@NgModule({
  imports: [ErrorDialogModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [ErrorService, HttpLink],
    },
  ],
})
export class GraphQLModule {}

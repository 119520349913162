import { gql } from 'apollo-angular';

export const addProgramMutation = gql`
  mutation AddProgram($name: String!, $agentCode: String!, $cms: Boolean!) {
    insert_program_one(
      object: {
        name: $name
        agent_code: $agentCode
        cms: $cms
        can_edit_parameters: true
        can_add_remove_buckets: true
      }
    ) {
      id
      name
      agentCode: agent_code
      canEditParameters: can_edit_parameters
      canAddRemoveBuckets: can_add_remove_buckets
      cms
    }
  }
`;

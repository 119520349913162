import { IServerSideGetRowsParams } from '@ag-grid-community/core';

export interface ServerSidePagination {
  skip: number;
  take: number;
}

export function paginate(
  params: IServerSideGetRowsParams
): ServerSidePagination {
  return {
    skip: params.request.startRow!,
    take: params.request.endRow! - params.request.startRow!,
  };
}

import { Component } from '@angular/core';

import { NavItem } from '@roadrunner/shared/ui-sidenav';
import { AppConfig } from '@roadrunner/shared/util-app-config';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
})
export class DashboardLayoutComponent {
  navItems: NavItem[] = [
    {
      icon: 'home',
      label: 'Home',
      route: '/home',
    },
    {
      icon: 'gpp_good',
      label: 'Products',
      route: '/rating/products',
    },
    {
      icon: 'paid',
      label: 'Rates',
      route: '/rating/rates',
    },
    {
      icon: 'store',
      label: 'Dealers',
      route: '/dealers',
    },
    {
      icon: 'add_moderator',
      label: 'Auto-Enrollments',
      route: '/enrollments',
    },
    {
      icon: 'payments',
      label: 'Override Payees',
      route: '/override-payees',
    },
    {
      icon: 'percent',
      label: 'NCB Percentages',
      route: '/ncb-percentages',
    },
  ];

  constructor(appConfig: AppConfig) {
    if (environment.enableLicensing) {
      this.navItems.push({
        icon: 'account_balance',
        label: 'Licensing Rules',
        route: '/licensing-rules',
      });
    }
    if (appConfig.rateLookupUrl) {
      this.navItems.push({
        icon: 'search',
        label: 'Rate Lookup',
        href: appConfig.rateLookupUrl,
      });
    }
    if (environment.enableFileImportExportMenuItem) {
      this.navItems.push({
        icon: 'upload_file',
        label: 'File Import/Export',
        route: '/file-import-export',
      });
    }
  }
}

import {
  Parameter_Key_Value_Constraint,
  Parameter_Key_Value_Update_Column,
  Parameter_Sub_Key_Constraint,
  Parameter_Sub_Key_Update_Column,
} from 'graphql/generated-client/gateway-schema.types';
import { ParameterKeyFieldsFragment } from '../../fragments/parameter-key-fields.fragment.generated';
import { UpsertProductParameterKeysMutationVariables } from './upsert-product-parameter-keys.mutation.generated';

export function buildUpsertProductParameterKeysVariables(
  productId: number,
  parameterKeysToUpsert: ParameterKeyFieldsFragment[],
  parameterKeyIdsToDelete: number[]
): UpsertProductParameterKeysMutationVariables {
  return {
    productId,
    insertParameterKeys: parameterKeysToUpsert.length > 0,
    deleteParameterKeys: parameterKeyIdsToDelete.length > 0,
    parameterKeyIdsToDelete,
    parameterKeys: parameterKeysToUpsert.map((key) => {
      return {
        id: key.id,
        key: key.key,
        parameter_id: key.parameter_id,
        product_id: productId,
        sort_order: key.sort_order,
        parameter_key_values: {
          data: key.parameter_key_values.map((pkv) => {
            return {
              id: undefined,
              parameter_subtype_id: pkv.parameter_subtype_id,
              value: pkv.value.toString(),
            };
          }),
          on_conflict: {
            constraint:
              Parameter_Key_Value_Constraint.ParameterKeyValueParameterSubtypeIdParameterKeyIdKey,
            update_columns: [Parameter_Key_Value_Update_Column.Value],
          },
        },
        parameter_sub_keys: key.parameter_sub_keys
          ? {
              data: key.parameter_sub_keys.map((psk) => ({
                id: psk.id,
                child_parameter_key_id: psk.child_parameter_key.id,
              })),
              on_conflict: {
                constraint:
                  Parameter_Sub_Key_Constraint.ParameterSubKeyParentChild,
                update_columns: [
                  Parameter_Sub_Key_Update_Column.ChildParameterKeyId,
                ],
              },
            }
          : undefined,
      };
    }),
  };
}

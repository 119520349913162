import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as ParameterTypeActions from './parameter-type.actions';
import { ParameterSubtype, ParameterType } from './parameter-type.models';

export const PARAMETER_TYPE_FEATURE_KEY = 'parameterTypes';

export interface State {
  parameterTypes: EntityState<ParameterType>;
  parameterSubtypes: EntityState<ParameterSubtype>;
  loading: boolean;
}

export const parameterTypeAdapter: EntityAdapter<ParameterType> =
  createEntityAdapter<ParameterType>();

export const parameterSubtypeAdapter: EntityAdapter<ParameterSubtype> =
  createEntityAdapter<ParameterSubtype>();

export const initialState: State = {
  parameterTypes: parameterTypeAdapter.getInitialState(),
  parameterSubtypes: parameterSubtypeAdapter.getInitialState(),
  loading: true,
};

export const reducer = createReducer(
  initialState,
  on(
    ParameterTypeActions.init,
    (state): State => ({
      ...state,
    })
  ),
  on(
    ParameterTypeActions.loadParameterTypesSuccess,
    (state, { parameterTypes, parameterSubtypes }): State => {
      return {
        ...state,
        parameterTypes: parameterTypeAdapter.setAll(
          parameterTypes,
          state.parameterTypes
        ),
        parameterSubtypes: parameterSubtypeAdapter.setAll(
          parameterSubtypes,
          state.parameterSubtypes
        ),
        loading: false,
      };
    }
  ),
  on(
    ParameterTypeActions.loadParameterTypesFailure,
    (state): State => ({
      ...state,
      loading: false,
    })
  )
);

import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { UpdateProductMsrpParameterKeyCombinationsVariable } from './update-product-msrp-parameter-key-combinations.variable';
import { UpdateProductMsrpParameterKeyCombinationsResponse } from './update-product-msrp-parameter-key-combinations.interface';
import { updateProductMsrpParameterKeyCombinationsMutation } from './update-product-msrp-parameter-key-combinations.mutation';

export class UpdateProductMsrpParameterKeyCombinationsData {
  static updateProductMsrpParameterKeyCombinations(
    apollo: Apollo,
    productId: number,
    newParameterKeyIds: number[],
    parameterKeyIdsToDelete: number[],
    parameterCountChanged: boolean
  ): Observable<UpdateProductMsrpParameterKeyCombinationsResponse> {
    const resetCombinations = parameterCountChanged;
    const createCombinations =
      !parameterCountChanged && newParameterKeyIds.length > 0;
    const deleteCombinations =
      !parameterCountChanged && parameterKeyIdsToDelete.length > 0;

    return (
      apollo
        .mutate<
          UpdateProductMsrpParameterKeyCombinationsResponse,
          UpdateProductMsrpParameterKeyCombinationsVariable
        >({
          mutation: updateProductMsrpParameterKeyCombinationsMutation,
          variables: {
            productId,
            createCombinations,
            deleteCombinations,
            resetCombinations,
            // These should be number arrays, but Hasura doesn't treat array arguments correctly.
            // See https://github.com/hasura/graphql-engine/issues/2160
            newParameterKeyIds: `{${newParameterKeyIds.join(',')}}`,
            parameterKeyIdsToDelete: `{${parameterKeyIdsToDelete.join(',')}}`,
          },
        })
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .pipe(map((res) => res.data!))
    );
  }
}

import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import {
  CoverageKeyValue,
  IBundledCoverageVM,
  IStandaloneCoverageVM,
} from '../models/view-models/products/coverage.view-model';
import {
  IProductParameterKeyVM,
  IProductParameterVM,
} from '../models/view-models/products/product-options.view-model';
import { BundledCoverageData } from '../pages/products/product/modals/bundled-coverage/bundled-coverage-data.interface';
import { BundledCoverageComponent } from '../pages/products/product/modals/bundled-coverage/bundled-coverage.component';
import { DeleteCoverageComponent } from '../pages/products/product/modals/delete-coverage/delete-coverage.component';
import { DeleteParameterOptionComponent } from '../pages/products/product/modals/delete-parameter-option/delete-parameter-option.component';
import { ParameterOptionComponent } from '../pages/products/product/modals/parameter-option/parameter-option.component';
import { ParameterComponent } from '../pages/products/product/modals/parameter/parameter.component';
import { StandaloneCoverageData } from '../pages/products/product/modals/standalone-coverage/standalone-coverage-data.interface';
import { StandaloneCoverageComponent } from '../pages/products/product/modals/standalone-coverage/standalone-coverage.component';
import { ConfirmPublishRatesComponent } from '../pages/rates/modals/confirm-publish-rates/confirm-publish-rates.component';
import { ChangeProgramModalComponent } from '../pages/sessions/choose-program/change-program-modal/change-program-modal.component';

export enum DialogSize {
  Tiny = '200px',
  Small = '300px',
  Medium = '600px',
  Large = '1000px',
}

export interface DialogConfig<D>
  extends Omit<MatDialogConfig<D>, 'width' | 'height'> {
  width?: DialogSize;
  height?: DialogSize;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private dialog: MatDialog) {}

  open<D, R>(
    component: ComponentType<unknown>,
    options: DialogConfig<D>
  ): Observable<R> {
    return this.dialog.open(component, options).afterClosed();
  }

  openChangeProgramModal(): Observable<boolean> {
    return this.dialog.open(ChangeProgramModalComponent).afterClosed();
  }

  openStandaloneCoverageModal(
    id: number,
    parameterKeyValues: CoverageKeyValue[],
    existingParameterKeyValues: Record<number, CoverageKeyValue[]>,
    isAdd: boolean
  ): Observable<IStandaloneCoverageVM | undefined | null> {
    return this.dialog
      .open<
        StandaloneCoverageComponent,
        StandaloneCoverageData,
        IStandaloneCoverageVM | null
      >(StandaloneCoverageComponent, {
        width: DialogSize.Medium,
        data: {
          id,
          isAdd,
          parameterKeyValues: parameterKeyValues.map((pkv) => {
            return {
              existingValues:
                existingParameterKeyValues[pkv.parameterSubtypeId]
                  ?.filter(
                    (epkv) => epkv.value != null && epkv.value !== pkv.value
                  )
                  .map((epkv) => epkv.value) ?? [],
              isIdentifier: pkv.isIdentifier,
              isUnique: pkv.isUnique,
              parameterSubtypeId: pkv.parameterSubtypeId,
              parameterSubType: pkv.parameterSubtype,
              value: pkv.value,
            };
          }),
        },
      })
      .afterClosed();
  }

  openBundledCoverageModal(
    id: number,
    parameterKeyValues: CoverageKeyValue[],
    existingParameterKeyValues: Record<number, CoverageKeyValue[]>,
    coverageIds: string[],
    otherExistingBundleCoverageIds: string[][],
    existingStandaloneIdentifiers: string[],
    isAdd: boolean
  ): Observable<IBundledCoverageVM | null | undefined> {
    return this.dialog
      .open<
        BundledCoverageComponent,
        BundledCoverageData,
        IBundledCoverageVM | null
      >(BundledCoverageComponent, {
        width: DialogSize.Medium,
        data: {
          id,
          isAdd,
          parameterKeyValues: parameterKeyValues.map((pkv) => {
            return {
              existingValues:
                existingParameterKeyValues[pkv.parameterSubtypeId]
                  ?.filter(
                    (epkv) => epkv.value != null && epkv.value !== pkv.value
                  )
                  .map((epkv) => epkv.value) ?? [],
              isIdentifier: pkv.isIdentifier,
              isUnique: pkv.isUnique,
              parameterSubtypeId: pkv.parameterSubtypeId,
              parameterSubtype: pkv.parameterSubtype,
              value: pkv.value,
            };
          }),
          initialIdentifiers: coverageIds,
          existingIdentifiers: otherExistingBundleCoverageIds,
          existingStandaloneIdentifiers: existingStandaloneIdentifiers,
        },
      })
      .afterClosed();
  }

  openDeleteCoverageModal(
    coverageName: string,
    coverageCode: string
  ): Observable<boolean> {
    return this.dialog
      .open(DeleteCoverageComponent, {
        width: DialogSize.Medium,
        data: {
          coverageName,
          coverageCode,
        },
      })
      .afterClosed();
  }

  openParameterModal(
    unusedParams: IProductParameterVM[],
    siblingOptionsProductLevel: IProductParameterKeyVM[],
    disableProgramParameterIds: number[],
  ): Observable<{
    option: IProductParameterKeyVM;
  }> {
    return this.dialog
      .open(ParameterComponent, {
        width: DialogSize.Medium,
        data: {
          unusedParams,
          siblingOptionsProductLevel,
          disableProgramParameterIds,
        },
      })
      .afterClosed();
  }

  openParameterOptionModal(
    option: IProductParameterKeyVM,
    siblingOptions: IProductParameterKeyVM[],
    isNewOption: boolean,
    siblingOptionsProductLevel: IProductParameterKeyVM[]
  ): Observable<IProductParameterKeyVM> {
    return this.dialog
      .open(ParameterOptionComponent, {
        width: DialogSize.Medium,
        data: {
          option,
          siblingOptions,
          isNewOption,
          siblingOptionsProductLevel,
        },
      })
      .afterClosed();
  }

  openDeleteParameterOptionModal(
    option: IProductParameterKeyVM
  ): Observable<boolean> {
    return this.dialog
      .open(DeleteParameterOptionComponent, {
        width: DialogSize.Medium,
        data: {
          option,
        },
      })
      .afterClosed();
  }

  openConfirmPublishRatesModal(
    productName: string
  ): Observable<{ effectiveDate: Date } | null> {
    return this.dialog
      .open(ConfirmPublishRatesComponent, {
        width: DialogSize.Medium,
        data: { productName },
      })
      .afterClosed();
  }
}

import { Action, createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';

export const USER_FEATURE_KEY = 'user';

export interface State {
  userName: string | null;
  userEmail: string | null;
}

export const initialState: State = {
  userName: null,
  userEmail: null,
};

const userReducer = createReducer<State>(
  initialState,
  on(UserActions.setUserInfo, (state, action): State => {
    return {
      ...state,
      userName: action.userName,
      userEmail: action.userEmail,
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

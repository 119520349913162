// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AddPayeeMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  code: Types.Scalars['String'];
  programId: Types.Scalars['bigint'];
}>;


export type AddPayeeMutation = { insert_payee_one: { id: number } | null };

export const AddPayeeDocument = gql`
    mutation AddPayee($name: String!, $code: String!, $programId: bigint!) {
  insert_payee_one(
    object: {company: $name, payee_code: $code, program_id: $programId}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddPayeeGQL extends Apollo.Mutation<AddPayeeMutation, AddPayeeMutationVariables> {
    override document = AddPayeeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
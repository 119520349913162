import { gql } from 'apollo-angular';

export const saveMsrpOperationMutation = gql`
  mutation SaveProductMsrpOperation(
    $combinationId: bigint!
    $operand: numeric!
    $operator: String!
  ) {
    insert_product_msrp_operation_one(
      object: {
        operand: $operand
        operator: $operator
        product_msrp_parameter_key_combination_id: $combinationId
      }
      on_conflict: {
        constraint: product_msrp_operation_operator_product_msrp_parameter_key_comb
        update_columns: [operand]
      }
    ) {
      id
    }
  }
`;

import { AgGridModule } from '@ag-grid-community/angular';
import { ModuleRegistry } from '@ag-grid-community/core';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { FullscreenGridComponent } from './fullscreen-grid/fullscreen-grid.component';
import { FullscreenGridHeaderDirective } from './fullscreen-grid/fullscreen-grid-header.directive';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';

import {
  AutoGridRowCountDirective,
  GridRowCountComponent,
} from './grid-row-count/grid-row-count.component';

LicenseManager.setLicenseKey(
  'CompanyName=Safe Guard,LicensedApplication=Road Runner,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=0,AssetReference=AG-013604,ExpiryDate=12_February_2022_[v2]_MTY0NDYyNDAwMDAwMA==c95aeeda5a8a94a7695cf28cdf9b7eae'
);

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ServerSideRowModelModule,
  RangeSelectionModule,
  SetFilterModule,
  ExcelExportModule
]);

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule, MatTooltipModule],
  exports: [
    AgGridModule,
    FullscreenGridComponent,
    FullscreenGridHeaderDirective,
    GridRowCountComponent,
    AutoGridRowCountDirective,
  ],
  declarations: [
    FullscreenGridComponent,
    FullscreenGridHeaderDirective,
    GridRowCountComponent,
    AutoGridRowCountDirective,
  ],
})
export class SharedUiGridModule {}

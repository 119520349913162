import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ProgramEffects } from './state/program.effects';
import * as fromProgram from './state/program.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromProgram.PROGRAM_FEATURE_KEY,
      fromProgram.reducer
    ),
    EffectsModule.forFeature([ProgramEffects]),
  ],
})
export class DcuDataAccessProgramModule {}

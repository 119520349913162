import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { SharedUiDialogModule } from '@roadrunner/shared/ui-dialog';

import { ErrorDialogComponent } from './dialogs/error-dialog.component';
import { GraphqlErrorDialogComponent } from './dialogs/graphql-error-dialog.component';
import { TimeoutErrorDialogComponent } from './dialogs/timeout-error-dialog.component';
import { TrpcErrorDialogComponent } from './dialogs/trpc-error-dialog.component';
import { ErrorService } from './error.service';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    SharedUiDialogModule,
  ],
  declarations: [
    ErrorDialogComponent,
    GraphqlErrorDialogComponent,
    TimeoutErrorDialogComponent,
    TrpcErrorDialogComponent,
  ],
  providers: [ErrorService],
})
export class ErrorDialogModule {}

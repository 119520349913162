import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

export enum DialogSize {
  Tiny = '200px',
  Small = '300px',
  Medium = '600px',
  Large = '1000px',
}

export interface DialogConfig<D>
  extends Omit<MatDialogConfig<D>, 'width' | 'height'> {
  width?: DialogSize;
  height?: DialogSize;
}

export enum DialogType {
  Add,
  Edit,
}

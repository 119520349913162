import { gql } from 'apollo-angular';

export const upsertBucketBaseRatesMutation = gql`
  mutation UpsertBucketBaseRates(
    $bucketBaseRates: [bucket_base_rate_insert_input!]!
  ) {
    insert_bucket_base_rate(
      objects: $bucketBaseRates
      on_conflict: {
        constraint: bucket_base_rate_bucket_rate_id_key
        update_columns: base
      }
    ) {
      returning {
        id
        bucket_rate_id
        base
      }
    }
  }
`;

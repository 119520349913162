import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ProgramActions } from '@roadrunner/rating-utility/data-access-program';
import { UserActions } from '@roadrunner/shared/data-access-user';
import { map, switchMap, tap } from 'rxjs/operators';

import { GetProgramListGQL } from '../../apollo/queries/programs/all-program-list/all-program-list.query.generated';
import { selectQueryParam } from '../router-param/router-param.selectors';
import {
  chooseProgram,
  getProgramList,
  getProgramListSuccess,
} from './user.actions';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private getProgramListGQL: GetProgramListGQL,
    private router: Router,
    private store: Store
  ) {}

  getProgramList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.setUserInfo, getProgramList),
      switchMap(() => {
        return this.getProgramListGQL.fetch().pipe(
          map((response) => {
            return getProgramListSuccess({
              programs: response.data.program,
              riskTypes: response.data.risk_type,
            });
          })
        );
      })
    )
  );

  chooseProgram$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(chooseProgram, ProgramActions.addProgramSuccess),
        concatLatestFrom((_) =>
          this.store.pipe(select(selectQueryParam('redirectUrl')))
        ),
        tap(([_action, redirectUri]) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.router.navigateByUrl(redirectUri || '/');
        })
      ),
    { dispatch: false }
  );
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RatingUtilityDataAccessProgramModule } from '@roadrunner/rating-utility/data-access-program';
import { SharedUiDialogModule } from '@roadrunner/shared/ui-dialog';
import { SharedMaterialModule } from '../../shared/shared-material.module';
import { ChangeProgramModalComponent } from './choose-program/change-program-modal/change-program-modal.component';
import { ChooseProgramComponent } from './choose-program/choose-program.component';
import { NotFoundComponent } from './not-found/not-found.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RatingUtilityDataAccessProgramModule,
    ReactiveFormsModule,
    RouterModule,
    SharedMaterialModule,
    SharedUiDialogModule,
  ],
  declarations: [
    NotFoundComponent,
    ChooseProgramComponent,
    ChangeProgramModalComponent,
  ],
})
export class SessionsModule {}

import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { productRatesLoaded } from '@roadrunner/rating-utility/data-access-program';
import {
  AddPayeeComponent,
  AddPayeeDialogData,
  AddPayeeDialogResult,
} from '@roadrunner/rating-utility/ui-add-payee';
import {
  AddProductComponent,
  AddProductDialogData,
  AddProductDialogResult,
} from '@roadrunner/rating-utility/ui-add-product';
import { UserSelectors } from '@roadrunner/shared/data-access-user';
import { PublishStatus } from '@roadrunner/shared/models';
import { JobStatus, JobStatusPollTime } from '@roadrunner/shared/util-api';
import { ApiConfig } from '@roadrunner/shared/util-app-config';
import { trpcClient } from '@roadrunner/shared/util-trpc';
import { EMPTY, forkJoin, from, merge, of, throwError, timer } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { DataService } from '../../apollo/data.service';
import { SetIsNonSellableGQL } from '../../apollo/mutations/set-is-non-sellable/set-is-non-sellable.mutation.generated';
import { buildUpsertNonSellableCombinationsVariables } from '../../apollo/mutations/upsert-non-sellable-combinations/build-upsert-non-sellable-combinations-variables';
import { UpsertNonSellableCombinationsGQL } from '../../apollo/mutations/upsert-non-sellable-combinations/upsert-non-sellable-combinations.mutation.generated';
import { GetDealerCostRoundingBucketsGQL } from '../../apollo/queries/get-dealer-cost-rounding-bucket-options/get-dealer-cost-rounding-bucket-options.query.generated';
import { GetDealerCostRoundingGQL } from '../../apollo/queries/get-dealer-cost-rounding/get-dealer-cost-rounding.query.generated';
import { GetNonSellableCombinationsGQL } from '../../apollo/queries/get-non-sellable-combinations/get-non-sellable-combinations.query.generated';
import { GetParametersGQL } from '../../apollo/queries/get-parameters/get-parameters.query.generated';
import { GetProductParameterKeysGQL } from '../../apollo/queries/get-product-parameter-keys-by-product-id/get-product-parameter-keys-by-product-id.query.generated';
import { GetBucketListGQL } from '../../apollo/queries/products/bucket-list-by-product/bucket-list-by-product.query.generated';
import { GetProductProgramIdGQL } from '../../apollo/queries/products/get-product-program-id/get-product-program-id.query.generated';
import { GetProductRatesGQL } from '../../apollo/queries/products/get-product-rates/get-product-rates.query.generated';
import { ProductTypesGQL } from '../../apollo/queries/products/product-types/product-types.query.generated';
import { reduceNonSellableCombinationOptions } from '../../apollo/reduce-non-sellable-combination-options';
import { IMsrpParametersVM } from '../../models/view-models/products/msrp-parameters.view-model';
import { CreateRateSliceDialogData } from '../../pages/rates/modals/create-rate-slice/create-rate-slice-dialog-data.interface';
import { CreateRateSliceDialogResult } from '../../pages/rates/modals/create-rate-slice/create-rate-slice-dialog-result.interface';
import { CreateRateSliceDialogComponent } from '../../pages/rates/modals/create-rate-slice/create-rate-slice-dialog.component';
import { DialogSize, ModalService } from '../../services/modal.service';
import { ofRoute } from '../../shared/utility/of-route.operator';
import { buildParameterFilters } from '../../shared/utility/parameter-filters';
import { ProductsService } from '../product/services/products.service';
import {
  selectChosenProgram,
  selectChosenProgramIsCms,
  selectRiskTypes,
} from '../user/user.selectors';
import {
  addBucketClicked,
  addPayee,
  addPayeeCancelled,
  addPayeeClicked,
  addPayeeFailure,
  addPayeeSuccess,
  addProduct,
  addProductClicked,
  addProductFailure,
  addProductSuccess,
  createBucket,
  createBucketFailure,
  createBucketSuccess,
  exportProductReviewFailure,
  exportProductReviewSuccess,
  getProductTypeList,
  getProductTypeListSuccess,
  loadProductDetail,
  loadProductDetailSuccess,
  msrpOperationChanged,
  msrpOperationDeleted,
  msrpOperationSaveFailure,
  msrpOperationSaveSuccess,
  msrpOperationsPaged,
  msrpOperationsPageLoadFailure,
  msrpOperationsPageLoadSuccess,
  msrpParameterAdded,
  msrpParameterAddFailure,
  msrpParameterAddSuccess,
  msrpParameterDeleteFailure,
  msrpParameterDeleteSuccess,
  msrpParameterRemoved,
  ratesPublishProduct,
  ratesPublishProductCancelled,
  ratesPublishProductFailure,
  ratesPublishProductSuccess,
  reviewExportClicked,
  reviewPaged,
  reviewPageLoadFailure,
  reviewPageLoadSuccess,
  saveDealerCostRounding,
  saveDealerCostRoundingSuccess,
  saveNonSellableCombinations,
  saveNonSellableCombinationsFailure,
  saveNonSellableCombinationsSuccess,
} from './rate.actions';
import {
  selectAllProductTypes,
  selectBucketNames,
  selectChosenProduct,
  selectDealerCostRoundingResponse,
  selectExistingPayeeCodes,
  selectExistingPayeeNames,
  selectMsrpParameters,
  selectNextBucketSortOrder,
  selectParameterIdsByKeyId,
  selectPayees,
  selectProductCodes,
  selectProductIdParam,
  selectProductParameters,
  selectProductTypeList,
  selectRoundingId,
  selectSavedMsrpParameters,
  selectStateNonSellableCombinationsResponse,
} from './rate.selectors';
import { AddPayeeGQL } from './services/add-payee.mutation.generated';
import { GetPayeesGQL } from './services/get-payees.query.generated';

function getParameterKeys(
  parameterKeyIds: number[],
  msrpParameters: IMsrpParametersVM
) {
  return parameterKeyIds.map((pkid) => {
    for (const p of msrpParameters.usedParams) {
      const matchingKey = p.keys.find((key) => key.parameterKeyId === pkid);
      if (matchingKey) {
        return matchingKey.parameterKey;
      }
    }
    // This should never happen, but fall back to logging the parameter key ids if key isn't found.
    return `${pkid}`;
  });
}

@Injectable()
export class RateEffects {
  constructor(
    private actions$: Actions,
    private dataService: DataService,
    private store: Store,
    private modalService: ModalService,
    private addPayeeGQL: AddPayeeGQL,
    private router: Router,
    private productsService: ProductsService,
    private getNonSellableCombinationsGQL: GetNonSellableCombinationsGQL,
    private setIsNonSellableGQL: SetIsNonSellableGQL,
    private upsertNonSellableCombinationsGQL: UpsertNonSellableCombinationsGQL,
    private getProductTypesGQL: ProductTypesGQL,
    private getProductRatesGQL: GetProductRatesGQL,
    private getBucketListGQL: GetBucketListGQL,
    private getParameters: GetParametersGQL,
    private getProductProgramId: GetProductProgramIdGQL,
    private getProductParameterKeysGQL: GetProductParameterKeysGQL,
    private getDealerCostRoundingBucketsGQL: GetDealerCostRoundingBucketsGQL,
    private getDealerCostRoundingGQL: GetDealerCostRoundingGQL,
    private getPayeesGQL: GetPayeesGQL,
    private apiConfig: ApiConfig,
    private snackbar: MatSnackBar
  ) {}

  getProductTypeList$ = createEffect(() => {
    return merge(
      this.actions$.pipe(ofType(getProductTypeList)),
      // Reload product types when the program id changes.
      // This currently only happens when copying a product to a different program.
      this.actions$.pipe(ofType(productRatesLoaded)),
      this.actions$.pipe(
        // Load the product type list when the user routes directly to the product detail page
        // or product list page without first loading the product types.
        ofRoute('/rating/rates'),
        concatLatestFrom((_) => this.store.pipe(select(selectProductTypeList))),
        filter(([_action, productTypes]) => {
          return !productTypes || productTypes.length === 0;
        })
      )
    ).pipe(
      concatLatestFrom((_) => this.store.pipe(select(selectChosenProgram))),
      exhaustMap(([_, program]) => {
        if (!program) {
          return EMPTY;
        }
        return this.getProductTypesGQL.fetch({ programId: program.id }).pipe(
          map((res) => {
            return getProductTypeListSuccess({
              productTypes: res.data.product_type,
            });
          }),
          catchError((error) => {
            return throwError(error);
          })
        );
      })
    );
  });

  getProductDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadProductDetail),
      switchMap((action) => {
        const productId = action.productId;

        // TODO: We could put all of this into a single query, or leave them separate and load them lazily

        // TODO: remove !s
        const buckets$ = this.getBucketListGQL.fetch({ productId });
        const dealerCostRounding$ = this.getDealerCostRoundingGQL.fetch({
          productId,
        });
        const dcrBucketOptions$ =
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.getDealerCostRoundingBucketsGQL.fetch({ productId });
        const nonSellableCombinations$ = this.getNonSellableCombinationsGQL
          .fetch({ productId })
          .pipe(
            map((response) => {
              return reduceNonSellableCombinationOptions(
                productId,
                response.data.non_sellable_combination_option
              );
            })
          );
        const parameterKeys$ = this.getProductParameterKeysGQL.fetch({
          productId,
        });
        const parameters$ = this.getParameters.fetch();
        const programId$ = this.getProductProgramId.fetch({ productId });
        const payees$ = this.getPayeesGQL.fetch();

        return forkJoin({
          buckets: buckets$,
          dealerCostRounding: dealerCostRounding$,
          dcrBucketOptions: dcrBucketOptions$,
          nonSellableCombinations: nonSellableCombinations$,
          parameterKeys: parameterKeys$,
          parameters: parameters$,
          payees: payees$,
          productProgramId: programId$,
        }).pipe(
          map((response) => {
            return loadProductDetailSuccess({
              programId:
                response.productProgramId.data.product_by_pk?.programId ?? 0,
              buckets: response.buckets.data,
              dealerCostRounding:
                response.dealerCostRounding.data.product_by_pk
                  ?.dealer_rounding ?? null,
              dcrBucketOptions: response.dcrBucketOptions.data,
              nonSellableCombinations: response.nonSellableCombinations,
              parameterKeys: response.parameterKeys.data,
              parameters: response.parameters.data,
              payees: response.payees.data.payee,
            });
          }),
          catchError((error) => {
            return throwError(() => error);
          })
        );
      })
    );
  });

  // This is a temporary hack to select the correct program when a product is loaded
  // that does not belong to the currently selected program. This fixes an issue where
  // the selected program did not match the selected product's program when navigating
  // directly to a product via URL or by copying a product to a new program & being
  // redirected programmatically.
  // TODO: figure out how to reconcile the need for a global "selected program" (is there a need?)
  // with the need for the selected program to match the local state's product's program.
  selectProgramForCurrentProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadProductDetailSuccess),
      map((action) => {
        return productRatesLoaded({
          programId: action.programId,
        });
      })
    );
  });

  loadProductMsrpOperations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(msrpOperationsPaged),
      concatLatestFrom((_) => [
        this.store.pipe(select(selectProductIdParam)),
        this.store.pipe(select(selectMsrpParameters)),
      ]),
      switchMap(([action, productId, params]) => {
        const parameters = buildParameterFilters(
          action.filterModel,
          params.usedParams
        );
        const skip = action.startRow ?? 0;
        const take = action.endRow ? action.endRow - skip + 1 : null;

        if (parameters.length === 0) {
          return of(
            msrpOperationsPageLoadSuccess({
              rowData: [],
              rowCount: 0,
            })
          );
        }

        return from(
          trpcClient.productMsrp.get.query({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            productId: productId!,
            parameters,
            skip,
            take,
          })
        ).pipe(
          map((response) => {
            return msrpOperationsPageLoadSuccess({
              rowData: response.nodes,
              rowCount: response.totalCount,
            });
          }),
          catchError((error) => {
            return of(msrpOperationsPageLoadFailure({ error }));
          })
        );
      })
    );
  });

  saveMsrpOperation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(msrpOperationChanged),
      concatLatestFrom((_) => [this.store.pipe(select(selectMsrpParameters))]),
      concatMap(([action, msrpParameters]) => {
        const parameterKeys = getParameterKeys(
          action.parameterKeyIds,
          msrpParameters
        );

        return this.dataService
          .saveMsrpOperation(
            action.productMsrpParameterKeyCombinationId,
            action.operation
          )
          .pipe(
            map(() => {
              return msrpOperationSaveSuccess({
                parameterKeys,
                operator: action.operation.operator,
                oldOperand: action.oldOperand,
                newOperand: action.operation.operand,
              });
            }),
            catchError((error) => of(msrpOperationSaveFailure({ error })))
          );
      })
    );
  });

  deleteMsrpOperation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(msrpOperationDeleted),
      concatLatestFrom((_) => [this.store.pipe(select(selectMsrpParameters))]),
      concatMap(([action, msrpParameters]) => {
        const parameterKeys = getParameterKeys(
          action.parameterKeyIds,
          msrpParameters
        );

        return this.dataService
          .deleteMsrpOperation(
            action.productMsrpParameterKeyCombinationId,
            action.operator
          )
          .pipe(
            map(() =>
              msrpOperationSaveSuccess({
                parameterKeys,
                operator: action.operator,
                oldOperand: action.oldOperand,
                newOperand: null,
              })
            ),
            catchError((error) => of(msrpOperationSaveFailure({ error })))
          );
      })
    );
  });

  addMsrpParameter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(msrpParameterAdded),
      concatLatestFrom((_) => [
        this.store.pipe(select(selectProductIdParam)),
        this.store.pipe(select(selectMsrpParameters)),
      ]),
      concatMap(([action, productId, msrpParameters]) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const parameterName = msrpParameters.usedParams.find(
          (p) => p.parameterId === action.parameterId
        )!.parameterName;
        return from(
          trpcClient.productMsrp.addMsrpParameter.mutate({
            parameterId: action.parameterId,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            productId: productId!,
          })
        ).pipe(
          map(() =>
            msrpParameterAddSuccess({
              parameterId: action.parameterId,
              parameterName,
            })
          ),
          catchError((error) => of(msrpParameterAddFailure({ error })))
        );
      })
    );
  });

  removeMsrpParameter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(msrpParameterRemoved),
      concatLatestFrom((_) => [
        this.store.pipe(select(selectProductIdParam)),
        this.store.pipe(select(selectSavedMsrpParameters)),
      ]),
      concatMap(([action, productId, msrpParameters]) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const parameterName = msrpParameters.usedParams.find(
          (p) => p.parameterId === action.parameterId
        )!.parameterName;
        return from(
          trpcClient.productMsrp.deleteMsrpParameter.mutate({
            parameterId: action.parameterId,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            productId: productId!,
          })
        ).pipe(
          map(() =>
            msrpParameterDeleteSuccess({
              parameterId: action.parameterId,
              parameterName,
            })
          ),
          catchError((error) => of(msrpParameterDeleteFailure({ error })))
        );
      })
    );
  });

  loadProductReview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reviewPaged),
      concatLatestFrom((_) => [
        this.store.pipe(select(selectProductIdParam)),
        this.store.pipe(select(selectProductParameters)),
      ]),
      switchMap(([action, productId, params]) => {
        // TODO: remove !
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const parameters = buildParameterFilters(action.filterModel, params!);
        const skip = action.startRow ?? 0;
        const take = action.endRow ? action.endRow - skip + 1 : null;

        if (parameters.length === 0) {
          return of(
            reviewPageLoadSuccess({
              rowData: [],
              rowCount: 0,
            })
          );
        }

        return (
          this.getProductRatesGQL
            // TODO: remove !
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .fetch({ productId: productId!, parameters, skip, take })
            .pipe(
              map((response) => {
                return reviewPageLoadSuccess({
                  rowData: response.data.productRates.nodes,
                  rowCount: response.data.productRates.totalCount,
                });
              }),
              catchError((error) => {
                return of(reviewPageLoadFailure({ error }));
              })
            )
        );
      })
    );
  });

  exportProductReview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reviewExportClicked),
      concatLatestFrom((_) => [
        this.store.pipe(select(selectProductIdParam)),
        this.store.pipe(select(selectProductParameters)),
        this.store.select(UserSelectors.selectUserLogInfo),
      ]),
      exhaustMap(([action, productId, params, userLogInfo]) => {
        // TODO: remove !
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const parameters = buildParameterFilters(action.filterModel, params!);

        if (parameters.length === 0 || !productId) {
          return EMPTY;
        }

        return from(
          trpcClient.productRates.export.query({
            productId,
            parameters,
            currentUserEmail: userLogInfo.user_email ?? '',
            currentUserName: userLogInfo.user_name ?? '',
          })
        ).pipe(
          switchMap((response) => {
            return timer(0, JobStatusPollTime).pipe(
              exhaustMap(() => {
                return from(
                  trpcClient.productRates.getProductRatesExportStatus.query({
                    jobId: response.jobId,
                  })
                );
              }),
              filter((job) => {
                return (
                  job.status === JobStatus.Complete ||
                  job.status === JobStatus.Error
                );
              }),
              map((job) => {
                if (job.status === JobStatus.Complete) {
                  return exportProductReviewSuccess({
                    fileUrl: response.url,
                  });
                }
                return exportProductReviewFailure({ error: job.error });
              }),
              take(1)
            );
          }),
          catchError((error) => {
            return of(exportProductReviewFailure({ error }));
          })
        );
      })
    );
  });

  downloadProductReview$ = createEffect(
    () => {
      const baseUrl = this.apiConfig.apiUrl;
      return this.actions$.pipe(
        ofType(exportProductReviewSuccess),
        tap(({ fileUrl }) => {
          const a = document.createElement('a');
          a.href = baseUrl + fileUrl;
          a.click();
        })
      );
    },
    { dispatch: false }
  );

  saveDealerCostRounding$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveDealerCostRounding),
      concatLatestFrom((_) => [
        this.store.pipe(select(selectRoundingId)),
        this.store.pipe(select(selectChosenProduct)),
        this.store.pipe(select(selectDealerCostRoundingResponse)),
      ]),
      switchMap(
        ([
          { isEnabled, roundingValue, roundingType, offsetBucketId },
          roundingId,
          product,
          oldDealerCostRounding,
        ]) => {
          return this.dataService
            .saveDealerCostRounding(
              isEnabled,
              // TODO: remove !s
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              product!.id,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              roundingId!,
              roundingValue,
              roundingType,
              offsetBucketId
            )
            .pipe(
              switchMap((newDealerCostRounding) => {
                return (
                  this.getProductParameterKeysGQL
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    .fetch({ productId: product!.id }) // TODO: why is this here? it looks unused, can we just remove it?
                    .pipe(
                      map((response) => {
                        return saveDealerCostRoundingSuccess({
                          newDealerCostRounding,
                          oldDealerCostRounding,
                          parameterKeys: response.data,
                        });
                      }),
                      catchError((error) => {
                        return throwError(error);
                      })
                    )
                );
              })
            );
        }
      )
    );
  });

  upsertNonSellableCombinations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveNonSellableCombinations),
      concatLatestFrom((_) => [
        this.store.select(selectProductIdParam),
        this.store.select(selectStateNonSellableCombinationsResponse),
        this.store.select(selectParameterIdsByKeyId),
      ]),
      switchMap(
        ([
          { nonSellableCombinations },
          productId,
          combinations,
          parameterIdsByKeyId,
        ]) => {
          if (!productId || !combinations) {
            return EMPTY;
          }
          return this.upsertNonSellableCombinationsGQL
            .mutate(
              buildUpsertNonSellableCombinationsVariables(
                productId,
                nonSellableCombinations,
                combinations,
                parameterIdsByKeyId
              )
            )
            .pipe(
              switchMap(() => {
                return this.setIsNonSellableGQL
                  .mutate({ productId, limit: null })
                  .pipe(
                    map((response) => {
                      return saveNonSellableCombinationsSuccess({
                        newNonSellableCombinations:
                          reduceNonSellableCombinationOptions(
                            productId,
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            response.data!.non_sellable_combination_options
                          ),
                        oldNonSellableCombinations: combinations,
                      });
                    })
                  );
              }),
              catchError((response) => {
                return of(
                  saveNonSellableCombinationsFailure({
                    error: response.errors,
                  })
                );
              })
            );
        }
      )
    )
  );

  ratesPublishProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ratesPublishProduct),
      concatLatestFrom((_) => this.store.pipe(select(selectChosenProduct))),
      exhaustMap(([_action, product]) =>
        // TODO: remove !
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.modalService.openConfirmPublishRatesModal(product!.name)
      ),
      concatLatestFrom((_) => [
        this.store.pipe(select(selectChosenProduct)),
        this.store.select(UserSelectors.selectUserLogInfo),
      ]),
      switchMap(([dialogResult, product, userLogInfo]) => {
        if (!dialogResult) {
          return of(ratesPublishProductCancelled());
        }

        return this.dataService
          .submitPublishProductEvent(
            // TODO: remove !
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            product!.id,
            userLogInfo.user_name ?? '',
            userLogInfo.user_email ?? '',
            dialogResult.effectiveDate
          )
          .pipe(
            switchMap(({ publishId }) => {
              return timer(0, 3000).pipe(
                exhaustMap(() => {
                  return from(
                    trpcClient.productRates.getPublishStatus.query({
                      publishId,
                    })
                  );
                }),
                filter((publishProduct) => {
                  return (
                    publishProduct.status === PublishStatus.Completed ||
                    publishProduct.status === PublishStatus.Errored
                  );
                }),
                map((publishProduct) => {
                  if (publishProduct.status === PublishStatus.Completed) {
                    return ratesPublishProductSuccess({ publishProduct });
                  }
                  return ratesPublishProductFailure({
                    error: publishProduct.error,
                  });
                }),
                take(1)
              );
            }),
            catchError((error) => {
              return of(ratesPublishProductFailure({ error: error.message }));
            })
          );
      })
    );
  });

  ratesPublishSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ratesPublishProductSuccess),
        tap(({ publishProduct }) => {
          const message = `Rates for product ${publishProduct.productCode} have been published.`;
          this.snackbar.open(message, 'Ok', { duration: undefined });
        })
      );
    },
    { dispatch: false }
  );

  addBucket$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addBucketClicked),
      concatLatestFrom(() => [
        this.store.pipe(select(selectBucketNames)),
        this.store.pipe(select(selectPayees)),
        this.store.pipe(select(selectChosenProgramIsCms)),
      ]),
      exhaustMap(([_action, forbiddenNames, payees, cms]) => {
        return this.modalService.open<
          CreateRateSliceDialogData,
          CreateRateSliceDialogResult | undefined
        >(CreateRateSliceDialogComponent, {
          width: DialogSize.Medium,
          data: {
            forbiddenNames,
            payees,
            cms,
          },
        });
      }),
      filter((res): res is CreateRateSliceDialogResult => res != null),
      map((res) => {
        return createBucket({
          bucketName: res.bucketName,
          payeeId: res.payeeId,
          cmsBucketNumber: res.cmsBucketNumber,
          reserves: res.reserves,
        });
      })
    );
  });

  createBucket$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createBucket),
      concatLatestFrom(() => [
        this.store.pipe(select(selectProductIdParam)),
        this.store.pipe(select(selectNextBucketSortOrder)),
      ]),
      switchMap(([action, productId, sortOrder]) => {
        if (!productId) {
          return EMPTY;
        }
        return from(
          trpcClient.rateSlice.create.mutate({
            name: action.bucketName,
            payeeId: action.payeeId,
            cmsBucketNumber: action.cmsBucketNumber,
            productId,
            sortOrder,
            reserves: action.reserves,
          })
        ).pipe(
          map((bucket) => {
            return createBucketSuccess({
              bucket: {
                id: bucket.id,
                has_saved_rates: bucket.hasSavedRates,
                name: bucket.name,
                payee: {
                  id: bucket.payee.id,
                  code: bucket.payee.payeeCode,
                  company: bucket.payee.company,
                },
                sort_order: bucket.sortOrder,
              },
            });
          }),
          catchError((error) => {
            return of(createBucketFailure({ error }));
          })
        );
      })
    );
  });

  addPayeeDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addPayeeClicked),
      concatLatestFrom(() => [
        this.store.pipe(select(selectChosenProgram)),
        this.store.pipe(select(selectExistingPayeeCodes)),
        this.store.pipe(select(selectExistingPayeeNames)),
      ]),
      exhaustMap(
        ([_action, program, existingPayeeCodes, existingPayeeNames]) => {
          if (!program) {
            return EMPTY;
          }

          return this.modalService
            .open<AddPayeeDialogData, AddPayeeDialogResult | null>(
              AddPayeeComponent,
              {
                width: DialogSize.Medium,
                data: { existingPayeeCodes, existingPayeeNames },
                // The create-bucket dialog handles focus restoration
                restoreFocus: false,
              }
            )
            .pipe(
              map((payee) => {
                return payee ? addPayee({ payee }) : addPayeeCancelled();
              })
            );
        }
      )
    );
  });

  addPayee$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addPayee),
      concatLatestFrom(() => this.store.pipe(select(selectChosenProgram))),
      exhaustMap(([action, program]) => {
        if (!program) {
          return EMPTY;
        }

        return this.addPayeeGQL
          .mutate({
            ...action.payee,
            programId: program.id,
          })
          .pipe(
            map((response) => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const payee = response.data!.insert_payee_one!;
              return addPayeeSuccess({
                payee: {
                  id: payee.id,
                  company: action.payee.name,
                  code: action.payee.code,
                },
              });
            }),
            catchError((error) => {
              return of(addPayeeFailure({ error }));
            })
          );
      })
    );
  });

  addProductDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addProductClicked),
      concatLatestFrom(() => [
        this.store.pipe(select(selectProductCodes)),
        this.store.pipe(select(selectAllProductTypes)),
        this.store.pipe(select(selectRiskTypes)),
      ]),
      exhaustMap(([_action, productCodes, productTypes, riskTypes]) => {
        return this.modalService
          .open<AddProductDialogData, AddProductDialogResult>(
            AddProductComponent,
            {
              width: DialogSize.Medium,
              data: {
                productCodes,
                productTypes,
                riskTypes,
              },
            }
          )
          .pipe(
            filter((product) => product != null),
            map((product) => addProduct({ product }))
          );
      })
    );
  });

  addProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addProduct),
      concatLatestFrom(() => this.store.pipe(select(selectChosenProgram))),
      exhaustMap(([action, program]) => {
        if (!program) {
          return EMPTY;
        }
        return this.productsService
          .add({
            program_id: program.id,
            product_type_id: action.product.productTypeId,
            name: action.product.name,
            description: action.product.description,
            code: action.product.code,
            risk_type: action.product.riskType,
          })
          .pipe(
            map((product) =>
              addProductSuccess({
                id: product.id,
                code: action.product.code,
                name: action.product.name,
                productTypeId: action.product.productTypeId,
              })
            ),
            catchError((error) => of(addProductFailure({ error })))
          );
      })
    );
  });

  navigateToNewProduct$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(addProductSuccess),
        tap((action) => {
          this.router.navigate(['rating', 'rates', action.id]);
        })
      );
    },
    { dispatch: false }
  );
}

import { ActivatedRouteSnapshot } from '@angular/router';
import {
  RouterNavigatedAction,
  routerNavigatedAction,
  SerializedRouterStateSnapshot,
} from '@ngrx/router-store';
import { Action } from '@ngrx/store';
import { OperatorFunction } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * Filters an action stream and only emits actions that are RouterNavigatedActions that match the given path.
 * @param pathToMatch The router path to match against.
 */
export function ofRoute(
  pathToMatch: string
): OperatorFunction<
  Action,
  RouterNavigatedAction<SerializedRouterStateSnapshot>
> {
  if (!pathToMatch.startsWith('/')) {
    pathToMatch = '/' + pathToMatch;
  }
  return filter((action: Action): action is RouterNavigatedAction => {
    if (action.type !== routerNavigatedAction.type) {
      return false;
    }
    const routeAction = action as RouterNavigatedAction;
    let route: ActivatedRouteSnapshot = routeAction.payload.routerState.root;
    let path = '';

    while (route.firstChild) {
      if (route.routeConfig?.path) {
        path += '/' + route.routeConfig.path;
        if (path === pathToMatch) {
          return true;
        }
      }
      route = route.firstChild;
    }

    if (!route.firstChild && route.routeConfig?.path) {
      path += '/' + route.routeConfig.path;
    }

    return path.startsWith(pathToMatch);
  });
}

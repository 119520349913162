import { ControlType } from '@roadrunner/rating-utility/data-access-parameter-type';

export interface IProductParameterVM {
  parameterId: number;
  parameterName: string;
  description: string;
  keys: IProductParameterKeyVM[];
  parameterType: IParameterTypeVM;
}

export interface IProductParameterKeyVM {
  parameterKeyId: number;
  parameterId: number;
  parameterKey: string;
  sortOrder: number | undefined;
  productId: number;
  parameterKeyValues: IParameterKeyValueVM[];
  subKeys: IProductParameterKeyVM[] | null;
}

export enum ParameterChangeType {
  /**
   * The number of parameters was not changed.
   */
  None = 'NONE',
  /**
   * A single parameter key was added for a single new parameter.
   *
   * This option allows a faster method to be used for updating rates
   * where rates are updated in place to include the new parameter key
   * instead of dropping and recreating all rates.
   * Importantly, this path does not require destroying & recreating
   * all rates, because the total # of rates is unchanged.
   */
  AddSingleParameterKey = 'ADD_SINGLE_PARAMETER_KEY',
  /**
   * Multiple parameter keys were added or removed and one or more
   * parameters have been added to or removed from this product.
   *
   * This option opts into a slower and more destructive path for updating rates where
   * all existing rates are destroyed and an entirely new set of rates is generated.
   */
  AddOrRemoveMultiple = 'ADD_OR_REMOVE_MULTIPLE',
}

export interface IParameterTypeVM {
  parameterTypeID: number;
  type: string;
  description: string;
  bundleable: boolean;
  parameterSubTypes: IParameterSubTypeVM[];
}

export interface IParameterSubTypeVM {
  parameterSubTypeID: number;
  parameterTypeID: number;
  subType: string;
  // GraphQL treats this type as a string instead of an enum, so we allow both here even though in practice it'll always be a ControlType
  // TODO: can we fix graphql to properly return the enum type?
  controlType: ControlType | string;
  visible: boolean;
  sortOrder: number;
  isIdentifier: boolean;
  isUnique: boolean;
  isGlobalUnique: boolean;
  options: IParameterSubTypeOptionVM[];
}

export interface IParameterSubTypeOptionVM {
  id: number;
  name: string;
}

export interface IParameterKeyValueVM {
  parameterKeyValueID: number;
  parameterKeyID: number;
  parameterSubType: IParameterSubTypeVM;
  value: string;
}

export interface IMsrpParameterKeyCombinationOperationVM {
  id: number;
  parameterKeys: Record<number, IProductParameterKeyVM>;
  // The operations for this parameter key combination, keyed by operator.
  operations: Record<string, IMsrpOperationVM>;
}

export interface IMsrpOperationVM {
  id: number;
  operator: string;
  operand: number;
}

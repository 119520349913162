import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { setTab } from './router-param.actions';
import { Router } from '@angular/router';

@Injectable()
export class RouteParamsEffects {
  constructor(private actions$: Actions, private router: Router) {}

  setTabIndex$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setTab),
        tap((action) => {
          this.router.navigate([], {
            replaceUrl: true,
            queryParamsHandling: 'merge',
            queryParams: { tab: action.newTabName },
          });
        })
      ),
    { dispatch: false }
  );
}

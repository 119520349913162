import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { SharedUiSidenavModule } from '@roadrunner/shared/ui-sidenav';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { RouterModule } from '@angular/router';
import { ModuleLoaderComponent } from './module-loader/module-loader.component';
import { MenuToggleComponent } from './menu-toggle/menu-toggle.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatToolbarModule,
    RouterModule,
    SharedUiSidenavModule,
  ],
  declarations: [LayoutComponent, ModuleLoaderComponent, MenuToggleComponent],
  exports: [LayoutComponent, ModuleLoaderComponent, MenuToggleComponent],
})
export class SharedUiLayoutModule {}

import { Injectable, NgZone } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { ComponentType } from '@angular/cdk/portal';
import {
  ErrorMessage,
  GraphqlErrorMessage,
} from '@roadrunner/shared/util-graphql';

import { TimeoutErrorDialogComponent } from './dialogs/timeout-error-dialog.component';
import { ErrorDialogComponent } from './dialogs/error-dialog.component';
import { GraphqlErrorDialogComponent } from './dialogs/graphql-error-dialog.component';
import { TrpcErrorDialogComponent } from './dialogs/trpc-error-dialog.component';
import { TRPCClientError } from '@trpc/client';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import type { AppRouter } from '@roadrunner/gateway/trpc';

@Injectable()
export class ErrorService {
  private errorDialogId = 'error-dialog';

  constructor(private dialog: MatDialog, private ngZone: NgZone) {}

  showTimeoutError(): Observable<void> {
    return this.show(TimeoutErrorDialogComponent, {});
  }

  showError(error: ErrorMessage): Observable<void> {
    return this.show(ErrorDialogComponent, { data: error });
  }

  showGraphqlErrors(errors: GraphqlErrorMessage[]): Observable<void> {
    return this.show(GraphqlErrorDialogComponent, { data: { errors } });
  }

  showTrpcError(error: TRPCClientError<AppRouter>): Observable<void> {
    let result: Observable<void> | null = null;
    this.ngZone.run(() => {
      result = this.show(TrpcErrorDialogComponent, { data: error });
    });
    return result as unknown as Observable<void>;
  }

  private show<T, D>(
    component: ComponentType<T>,
    config: MatDialogConfig<D>
  ): Observable<void> {
    let dialog = this.dialog.getDialogById(this.errorDialogId);
    if (!dialog) {
      dialog = this.dialog.open(component, {
        ...config,
        width: '600px',
      });
    }
    return dialog.afterClosed();
  }
}

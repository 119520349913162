// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetProductProgramIdQueryVariables = Types.Exact<{
  productId: Types.Scalars['bigint'];
}>;


export type GetProductProgramIdQuery = { product_by_pk: { programId: number } | null };

export const GetProductProgramIdDocument = gql`
    query GetProductProgramId($productId: bigint!) {
  product_by_pk(id: $productId) {
    programId: program_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductProgramIdGQL extends Apollo.Query<GetProductProgramIdQuery, GetProductProgramIdQueryVariables> {
    override document = GetProductProgramIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
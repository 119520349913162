// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetDealerCostRoundingQueryVariables = Types.Exact<{
  productId: Types.Scalars['bigint'];
}>;


export type GetDealerCostRoundingQuery = { product_by_pk: { dealer_rounding: { id: number, rounding_type: string, rounding_value: number, offset_bucket: { id: number, name: string } } | null } | null };

export const GetDealerCostRoundingDocument = gql`
    query GetDealerCostRounding($productId: bigint!) {
  product_by_pk(id: $productId) {
    dealer_rounding {
      id
      offset_bucket {
        id
        name
      }
      rounding_type
      rounding_value
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDealerCostRoundingGQL extends Apollo.Query<GetDealerCostRoundingQuery, GetDealerCostRoundingQueryVariables> {
    override document = GetDealerCostRoundingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
import { Observable } from 'rxjs';

export function batchRequests<T, R>(
  items: T[],
  mapItem: null,
  requestFactory: (items: T[]) => Observable<R>,
  batchSize?: number
): Observable<R>[];
export function batchRequests<T, R, U>(
  items: T[],
  mapItem: (item: T) => U,
  requestFactory: (items: U[]) => Observable<R>,
  batchSize?: number
): Observable<R>[];
export function batchRequests<T, R, U>(
  items: T[],
  mapItem: ((item: T) => U) | null,
  requestFactory: (items: U[]) => Observable<R>,
  batchSize: number = 30000
): Observable<R>[] {
  const batches: Observable<R>[] = [];
  let currentBatch: U[] = [];
  for (const item of items) {
    if (currentBatch.length >= batchSize) {
      batches.push(requestFactory(currentBatch));
      currentBatch = [];
    }
    currentBatch.push(mapItem ? mapItem(item) : ((item as unknown) as U));
  }
  if (currentBatch.length > 0) {
    batches.push(requestFactory(currentBatch));
  }
  return batches;
}

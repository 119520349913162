import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, USER_FEATURE_KEY } from './user.reducer';

// Lookup the 'User' feature state managed by NgRx
export const selectUserState = createFeatureSelector<State>(USER_FEATURE_KEY);

export const selectUserName = createSelector(
  selectUserState,
  (state) => state.userName
);

export const selectUserEmail = createSelector(
  selectUserState,
  (state) => state.userEmail
);

export const selectUserLogInfo = createSelector(selectUserState, (state) => {
  return {
    user_name: state.userName,
    user_email: state.userEmail,
  };
});

import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { takeUntil } from 'rxjs/operators';
import { IBundledCoverageVM } from '../../../../../models/view-models/products/coverage.view-model';
import { OnDestroySubs } from '../../../../../shared/components/onDestroySubs';
import { FormUtility } from '../../../../../shared/utility/form.utility';
import { BundledCoverageData } from './bundled-coverage-data.interface';

@Component({
  selector: 'app-bundled-coverage',
  templateUrl: './bundled-coverage.component.html',
  styleUrls: ['./bundled-coverage.component.scss'],
})
export class BundledCoverageComponent extends OnDestroySubs implements OnInit {
  form!: UntypedFormGroup;

  parameterKeyValuesFormArray = new UntypedFormArray([]);

  coverageCodesControl!: UntypedFormControl;

  coverageCodeControl!: UntypedFormControl;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: BundledCoverageData,
    private dialogRef: MatDialogRef<
      BundledCoverageComponent,
      IBundledCoverageVM
    >
  ) {
    super();
    this.coverageCodesControl = new UntypedFormControl(
      this.data.initialIdentifiers,
      [
        Validators.required,
        Validators.minLength(2),
        FormUtility.uniqueArray(this.data.existingIdentifiers),
      ]
    );
    this.form = new UntypedFormGroup({
      coverageCodes: this.coverageCodesControl,
      parameterKeyValues: this.parameterKeyValuesFormArray,
    });
    for (const pkv of this.data.parameterKeyValues) {
      const control = new UntypedFormControl(pkv.value, Validators.required);
      if (pkv.isIdentifier) {
        control.addValidators(Validators.maxLength(8));
        this.coverageCodeControl = control;
      }
      if (pkv.isUnique) {
        control.addValidators(FormUtility.unique(pkv.existingValues));
      }
      this.parameterKeyValuesFormArray.insert(
        this.parameterKeyValuesFormArray.length,
        control
      );
    }
  }

  ngOnInit(): void {
    this.coverageCodesControl.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((value: string[] | null) => {
        this.coverageCodeControl.setValue(value?.join(''));
        this.coverageCodeControl.markAsTouched();
        this.coverageCodeControl.markAsDirty();
      });
  }

  formSubmitted(): void {
    if (!this.form.valid || this.form.pristine) {
      return;
    }
    this.dialogRef.close({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: this.data.id,
      canDelete: true,
      coverageIds: this.coverageCodesControl.value,
      parameterKeyValues: this.data.parameterKeyValues.map((pkv, index) => {
        return {
          ...pkv,
          value: this.parameterKeyValuesFormArray.at(index).value,
        };
      }),
    });
  }
}

<div class="flex flex-col">
  <div class="w-full">
    <div class="pt-7 px-7">
      <div class="w-full">
        <img src="assets/images/logo-light.svg" />
        <div class="w-full text-center text-accent-default text-2xl">
          <span>Roadrunner</span>
        </div>
      </div>
    </div>

    <div class="text-center text-sm text-accent-default">
      {{ version }}
    </div>

    <mat-nav-list class="ml-3 mt-4">
      <ng-container *ngFor="let navItem of navItems">
        <ng-container *ngIf="$any(navItem).route as route">
          <a
            mat-list-item
            disableRipple
            matRipple
            [routerLink]="route"
            routerLinkActive="bg-primary-darker"
            class="rounded-tl-full rounded-bl-full"
          >
            <mat-icon mat-list-icon class="text-accent-default">
              {{ navItem.icon }}
            </mat-icon>
            <div mat-line class="text-accent-default">{{ navItem.label }}</div>
          </a>
        </ng-container>
        <ng-container *ngIf="$any(navItem).href as href">
          <a
            mat-list-item
            disableRipple
            matRipple
            [attr.href]="href"
            class="rounded-tl-full rounded-bl-full"
          >
            <mat-icon mat-list-icon class="text-accent-default">
              {{ navItem.icon }}
            </mat-icon>
            <div mat-line class="text-accent-default">{{ navItem.label }}</div>
          </a>
        </ng-container>
      </ng-container>
    </mat-nav-list>
  </div>
</div>

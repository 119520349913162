// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetProductParameterKeysQueryVariables = Types.Exact<{
  productId: Types.Scalars['bigint'];
}>;


export type GetProductParameterKeysQuery = { product_by_pk: { parameter_keys: Array<{ id: number, key: string, product_id: number, parameter_id: number, sort_order: number, created_at: any, parameter_key_values: Array<{ id: number, parameter_key_id: number, parameter_subtype_id: number, value: string, parameter_subtype: { id: number, parameter_type_id: number, subtype: string } }> }>, msrp_parameters: Array<{ parameter_id: number }> } | null };

export const GetProductParameterKeysDocument = gql`
    query GetProductParameterKeys($productId: bigint!) {
  product_by_pk(id: $productId) {
    parameter_keys {
      id
      key
      product_id
      parameter_id
      sort_order
      created_at
      parameter_key_values {
        id
        parameter_key_id
        parameter_subtype_id
        value
        parameter_subtype {
          id
          parameter_type_id
          subtype
        }
      }
    }
    msrp_parameters {
      parameter_id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductParameterKeysGQL extends Apollo.Query<GetProductParameterKeysQuery, GetProductParameterKeysQueryVariables> {
    override document = GetProductParameterKeysDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
import { gql } from 'apollo-angular';

export const updateProductParameterKeyCombinationsMutation = gql`
  mutation UpdateProductParameterKeyCombinations(
    $productId: bigint!
    # These should be of type '[bigint!]!', but Hasura doesn't treat array arguments correctly.
    # See https://github.com/hasura/graphql-engine/issues/2160
    $parameterKeyIdsToDelete: _int8!
    $newParameterKeyIds: _int8!
    $deleteCombinations: Boolean!
    $createCombinations: Boolean!
    $resetCombinations: Boolean!
  ) {
    delete_product_parameter_key_combinations(
      args: {
        deletedparameterkeyids: $parameterKeyIdsToDelete
        productid: $productId
      }
      limit: 1
    ) @include(if: $deleteCombinations) {
      id
    }
    create_product_parameter_key_combinations(
      args: { newparameterkeyids: $newParameterKeyIds, productid: $productId }
      limit: 1
    ) @include(if: $createCombinations) {
      id
    }
    reset_product_parameter_key_combinations(
      args: { productid: $productId }
      limit: 1
    ) @include(if: $resetCombinations) {
      id
    }
  }
`;

import {
  Component,
  Directive,
  Input,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Events } from '@ag-grid-community/core';
import { AgGridAngular } from '@ag-grid-community/angular';

/**
 * Simple pure component to render a grid's row count.
 * Can be used alone:
 * @example
 * ```
 * <rr-grid-row-count [rowCount]="rowCount$ | async"></rr-grid-row-count>
 * ```
 * or with the AutoGridRowCountDirective:
 * @example
 * ```
 * <ag-grid-angular #fooGrid></ag-grid-angular>
 * <rr-grid-row-count [grid]="fooGrid"></rr-grid-row-count>
 * ```
 */
@Component({
  selector: 'rr-grid-row-count',
  template: `Rows: <strong>{{ rowCount | number: '0.0-0' }}</strong>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'ag-theme-alpine bg-[#fafafa] px-4 pt-3 pb-4 text-end' },
})
export class GridRowCountComponent {
  @Input() rowCount: number | null = null;
}

/**
 * Directive that automatically wires up a grid using the ServerSideRowModel to the GridRowCountComponent.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'rr-grid-row-count[grid]',
})
export class AutoGridRowCountDirective implements OnDestroy, AfterViewInit {
  @Input() grid!: AgGridAngular;

  private handler = this.onModelUpdated.bind(this);

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private rowCountComponent: GridRowCountComponent
  ) {}

  ngAfterViewInit(): void {
    // the grid api is not created yet during ngOnInit, so we have to do this in ngAfterViewInit
    this.grid.api.addEventListener(Events.EVENT_MODEL_UPDATED, this.handler);
  }

  ngOnDestroy(): void {
    // safe navigation operator because the grid may already be destroyed at this point
    this.grid?.api.removeEventListener(
      Events.EVENT_MODEL_UPDATED,
      this.handler
    );
  }

  private onModelUpdated() {
    const rowCount = this.grid.api.getDisplayedRowCount();
    // this method can get called a lot, so we skip change detection unless the row count has actually changed
    if (rowCount !== this.rowCountComponent.rowCount) {
      this.rowCountComponent.rowCount = rowCount;
      // We have to trigger change detection manually because
      // we're manually setting the GridRowCountComponent's input
      this.changeDetectorRef.detectChanges();
    }
  }
}

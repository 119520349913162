import { Injectable } from '@angular/core';
import packageInfo from '../../../../../../package.json';

/**
 * Jest doesn't like the weird packageInfo import above,
 * so the version is encapsulated by a service so that it can be stubbed for testing.
 */
@Injectable({ providedIn: 'root' })
export class VersionService {
  version = packageInfo.version;
}

// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetProgramListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProgramListQuery = { program: Array<{ id: number, name: string, agent_code: string, can_add_remove_buckets: boolean, cms: boolean }>, risk_type: Array<{ code: string, name: string }> };

export const GetProgramListDocument = gql`
    query GetProgramList {
  program {
    id
    name
    agent_code
    can_add_remove_buckets
    cms
  }
  risk_type(order_by: {risk_type_description: asc}) {
    code: risk_type_code
    name: risk_type_description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProgramListGQL extends Apollo.Query<GetProgramListQuery, GetProgramListQueryVariables> {
    override document = GetProgramListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetOffsetBucketListQueryVariables = Types.Exact<{
  currentBucketId: Types.Scalars['bigint'];
}>;


export type GetOffsetBucketListQuery = { bucket_by_pk: { product: { buckets: Array<{ id: number, name: string, sort_order: number, bucket_offset: { id: number } | null, configured_offset: { id: number, bucket_id: number } | null }>, dealer_rounding: { offset_bucket_id: number } | null } } | null };

export const GetOffsetBucketListDocument = gql`
    query GetOffsetBucketList($currentBucketId: bigint!) {
  bucket_by_pk(id: $currentBucketId) {
    product {
      buckets(where: {id: {_neq: $currentBucketId}}) {
        id
        name
        sort_order
        bucket_offset {
          id
        }
        configured_offset {
          id
          bucket_id
        }
      }
      dealer_rounding {
        offset_bucket_id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOffsetBucketListGQL extends Apollo.Query<GetOffsetBucketListQuery, GetOffsetBucketListQueryVariables> {
    override document = GetOffsetBucketListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';

import { BaseLogMessage } from '../models/base-log-message.interface';
import { logMessagesMutation } from './log-messages.mutation';

@Injectable({
  providedIn: 'root',
})
export class LoggingDataService {
  constructor(private apollo: Apollo) {}

  logMessages<T extends BaseLogMessage>(logs: T[]): Observable<unknown> {
    return this.apollo.mutate<{ affected_rows: number }>({
      mutation: logMessagesMutation,
      variables: { logs },
    });
  }
}

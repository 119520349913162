import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Params } from '@angular/router';

@Component({
  selector: 'app-back-link',
  templateUrl: 'back-link.component.html',
  styleUrls: ['back-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'text-primary-default' },
})
export class BackLinkComponent {
  @Input() link!: (string | number)[];

  @Input() queryParams?: Params | null;
}

import { FormControl } from '@angular/forms';
import {
  combineLatest,
  isObservable,
  map,
  Observable,
  of,
  startWith,
} from 'rxjs';

export interface FilteredProgramProducts<
  TProgram extends { agentCode: string; name: string },
  TProduct extends {
    code: string;
    name: string;
    program: { agentCode: string };
  }
> {
  filteredPrograms$: Observable<TProgram[]>;
  filteredProducts$: Observable<TProduct[]>;
}

// TODO: ideally this would go into a shared lib, or, even better, in a consolidated `feature-dealer` lib that includes all the dealer stuff.
/**
 * Builds observables of filtered program & product options for a cascading set of program agent code & product code dropdowns.
 * @param programs An array of all programs, or an observable thereof
 * @param products  An array of all products, or an observable thereof
 * @param programAgentCodeControl The program agent code form control
 * @param productCodeControl The product code form control
 */
export function filteredProgramProducts<
  TProgram extends { agentCode: string; name: string },
  TProduct extends {
    code: string;
    name: string;
    program: { agentCode: string };
  },
  TProgramAgentCodeControl extends FormControl<string>,
  TProductCodeControl extends FormControl<string>
>(
  programs: TProgram[] | Observable<TProgram[]>,
  products: TProduct[] | Observable<TProduct[]>,
  programAgentCodeControl: TProgramAgentCodeControl,
  productCodeControl: TProductCodeControl
): FilteredProgramProducts<TProgram, TProduct> {
  function productInProgram(product: TProduct, program: TProgram): boolean {
    return product.program.agentCode === program.agentCode;
  }

  function productMatches(product: TProduct, value: string): boolean {
    return (
      product.code.toLowerCase().includes(value) ||
      product.name.toLowerCase().includes(value)
    );
  }

  const programs$ = isObservable(programs) ? programs : of(programs);
  const products$ = isObservable(products) ? products : of(products);

  const programAgentCodeValue$ = programAgentCodeControl.valueChanges.pipe(
    startWith(programAgentCodeControl.value)
  );
  const productCodeValue$ = productCodeControl.valueChanges.pipe(
    startWith(productCodeControl.value)
  );

  const filteredPrograms$ = combineLatest([
    programs$,
    programAgentCodeValue$,
  ]).pipe(
    map(([programs, programAgentCodeValue]) => {
      const programInput = programAgentCodeValue?.trim().toLowerCase();
      if (!programInput) {
        return programs;
      }

      return programs.filter(
        (p) =>
          p.agentCode.toLocaleLowerCase().includes(programInput) ||
          p.name.toLocaleLowerCase().includes(programInput)
      );
    })
  );

  const filteredProducts$ = combineLatest([
    programs$,
    products$,
    programAgentCodeValue$,
    productCodeValue$,
  ]).pipe(
    map(
      ([
        programs,
        products,
        programAgentCodeValue,
        productCodeControlValue,
      ]) => {
        const programAgentCode = programAgentCodeValue
          ?.trim()
          .toLocaleLowerCase();
        const productCode = productCodeControlValue?.trim().toLocaleLowerCase();

        // We only want to filter products by program if there's a single exact match on program agent code.
        // Program agent codes are unique, so we don't need to assert uniqueness here, we can just find the first match
        const program =
          programs.find(
            (p) => p.agentCode.toLocaleLowerCase() === programAgentCode
          ) ?? null;

        if (program && !productCode) {
          return products.filter((p) => productInProgram(p, program));
        }
        if (!program && productCode) {
          return products.filter((p) => productMatches(p, productCode));
        }
        if (program && productCode) {
          return products.filter(
            (p) =>
              productInProgram(p, program) && productMatches(p, productCode)
          );
        }
        return products;
      }
    )
  );

  return { filteredPrograms$, filteredProducts$ };
}

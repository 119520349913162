import { Component, ChangeDetectionStrategy } from '@angular/core';
import { LayoutComponent } from '../layout/layout.component';

@Component({
  selector: 'rr-menu-toggle',
  templateUrl: './menu-toggle.component.html',
  styleUrls: ['./menu-toggle.component.scss'],
  // This component references properties on its parent component, and must use the default
  // change detection strategy in order to pick those up, since they're not @Inputs.
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MenuToggleComponent {
  constructor(public layout: LayoutComponent) {}
}

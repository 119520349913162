import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenavContent } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { editProgramClicked } from '@roadrunner/rating-utility/data-access-program';
import { AuthService } from '@roadrunner/shared/auth';
import { UserSelectors } from '@roadrunner/shared/data-access-user';
import { UiToggleService } from 'libs/shared/ui-layout/src/lib/service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModalService } from '../../../../services/modal.service';
import { selectRouteData } from '../../../../store/router-param/router-param.selectors';
import {
  selectChosenProgramName,
  selectHasChosenProgram,
} from '../../../../store/user/user.selectors';


@Component({
  selector: 'app-page-layout',
  templateUrl: 'page-layout.component.html',
})
export class PageLayoutComponent implements OnInit {
  @ViewChild(MatSidenavContent) sidenavContent!: MatSidenavContent;

  programName$: Observable<string | undefined>;

  shouldShowProgram$!: Observable<boolean>;

  userName$!: Observable<string | null>;

  constructor(
    private router: Router,
    private store: Store,
    public authService: AuthService,
    private modalService: ModalService,
    public toggleService: UiToggleService
  ) {
    this.programName$ = store.pipe(select(selectChosenProgramName));
  }

  ngOnInit() {
    this.userName$ = this.store.pipe(select(UserSelectors.selectUserName));
    this.shouldShowProgram$ = combineLatest([
      this.store.pipe(select(selectHasChosenProgram)),
      this.store.pipe(select(selectRouteData)),
    ]).pipe(map(([hasProgram, data]) => data.requiresProgram && hasProgram));
  }
  sideNavclose(){
    console.log("pagelayout")  
      
  }

  logout() {
    this.authService.logOut();
  }

  switchProgramClicked(): void {
    // TODO: dispatch an action and move this to an effect
    this.modalService.openChangeProgramModal().subscribe((b) => {
      if (b) {
        this.router.navigate(['rating', 'choose-program']);
      }
    });
  }

  onEditProgramClick(): void {
    this.store.dispatch(editProgramClicked());
  }
}

export enum DialogDataType {
  Add = 'Add',
  Edit = 'Edit',
}

export interface AddProgramDialogData {
  dataType: DialogDataType.Add;
  programNames: string[];
  agentCodes: string[];
}

export interface EditProgramDialogData {
  dataType: DialogDataType.Edit;
  id: number;
  programName: string;
  agentCode: string;
  cms: boolean;
}

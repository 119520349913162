import { gql } from 'apollo-angular';

export const updateBucketMutation = gql`
  mutation UpdateBucket(
    $bucketId: bigint!
    $bucketName: String!
    $cmsBucketNumber: smallint
    $reserves: Boolean!
    $payeeId: bigint!
  ) {
    update_bucket_by_pk(
      pk_columns: { id: $bucketId }
      _set: {
        name: $bucketName
        cms_bucket_number: $cmsBucketNumber
        reserves: $reserves
        payee_id: $payeeId
      }
    ) {
      name
      cms_bucket_number
    }
  }
`;

<form [formGroup]="form" (ngSubmit)="formSubmitted()">
  <roadrunner-dialog-header
    [headerText]="!data.isAdd ? 'Edit Coverage' : 'Add a Coverage'"
  ></roadrunner-dialog-header>

  <mat-dialog-content formArrayName="parameterKeyValues" cdkFocusInitial>
    <mat-form-field
      *ngFor="let parameterKeyValue of data.parameterKeyValues; let i = index"
      class="w-full"
    >
      <mat-label>{{ parameterKeyValue.parameterSubType }}</mat-label>
      <input
        matInput
        [formControlName]="i"
        placeholder="Enter a {{ parameterKeyValue.parameterSubType }} here..."
        autocomplete="off"
        trimWhitespace
      />
      <mat-error *ngIf="parameterKeyValuesFormArray.at(i).hasError('required')">
        {{ parameterKeyValue.parameterSubType }} is required
      </mat-error>
      <mat-error *ngIf="parameterKeyValuesFormArray.at(i).hasError('unique')">
        {{ parameterKeyValue.parameterSubType }} must be unique
      </mat-error>
      <mat-error
        *ngIf="
          parameterKeyValuesFormArray.at(i).getError('maxlength') as maxLength
        "
      >
        {{ parameterKeyValue.parameterSubType }} must be
        {{ maxLength.requiredLength }} characters or less
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <div mat-dialog-actions align="end">
    <button mat-button type="button" [matDialogClose]="null" class="mr-2">
      Cancel
    </button>
    <button
      mat-raised-button
      type="submit"
      color="primary"
      [class.mat-button-disabled]="form.invalid"
    >
      Save
    </button>
  </div>
</form>

import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { upsertDealerCostRoundingMutation } from './upsert-dealer-cost-rounding.mutation';
import { IUpsertDealerCostRoundingResponse } from './upsert-dealer-cost-rounding.interface';
import { UpsertDealerCostRoundingVariable } from './upsert-dealer-cost-rounding.variable';
import { DealerRoundingResponse } from '../../queries/get-dealer-cost-rounding/get-dealer-cost-rounding.interface';

export class UpsertDealerCostRoundingData {
  static upsertDealerCostRounding(
    apollo: Apollo,
    productId: number,
    roundingId: number,
    roundingValue: number,
    roundingType: string,
    offsetBucketId: number
  ): Observable<DealerRoundingResponse> {
    const upsertVariable: UpsertDealerCostRoundingVariable = {
      dealerCostRounding: {
        id: roundingId,
        offset_bucket_id: offsetBucketId,
        product_id: productId,
        rounding_type: roundingType,
        rounding_value: roundingValue,
      },
    };
    return (
      apollo
        .mutate<IUpsertDealerCostRoundingResponse>({
          mutation: upsertDealerCostRoundingMutation,
          variables: upsertVariable,
        })
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .pipe(map((res) => res.data!.insert_product_dealer_offset_one))
    );
  }
}

import {
  RateSliceRate,
  RateSliceBundledRate,
} from '../../../../store/bucket/models/rate-slice-rate';
import { RateException } from '../../../../store/bucket/models/rate-exception';

export function getBundledRateTotal(
  bundleParameterKeyIds: number[],
  bundleParameterKeyIdIndex: number,
  rate: RateSliceBundledRate,
  getExceptionTotal: (
    data: Omit<RateException, 'bucketRateIds'> | RateSliceRate
  ) => number | null
) {
  const parameterKeyIds = bundleParameterKeyIds.slice();
  parameterKeyIds[bundleParameterKeyIdIndex] = rate.parameterKeyId;

  return getExceptionTotal({
    parameterKeyIds,
    base: rate.base,
    exception: rate.exception,
  } as RateException) as number;
}

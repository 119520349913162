import { gql } from 'apollo-angular';

export const updateBucketMinMaxMutation = gql`
  mutation UpdateBucketMinMax(
    $bucketId: bigint!
    $min: numeric
    $max: numeric
    $hasMin: Boolean!
    $hasMax: Boolean!
  ) {
    insert_bucket_minimum_one(
      object: { bucket_id: $bucketId, min: $min, is_enabled: true }
      on_conflict: {
        constraint: bucket_minimum_bucket_id_key
        update_columns: min
      }
    ) @include(if: $hasMin) {
      min
    }
    insert_bucket_maximum_one(
      object: { bucket_id: $bucketId, max: $max, is_enabled: true }
      on_conflict: {
        constraint: bucket_maximum_bucket_id_key
        update_columns: max
      }
    ) @include(if: $hasMax) {
      max
    }
    delete_bucket_minimum(where: { bucket_id: { _eq: $bucketId } })
      @skip(if: $hasMin) {
      affected_rows
    }
    delete_bucket_maximum(where: { bucket_id: { _eq: $bucketId } })
      @skip(if: $hasMax) {
      affected_rows
    }
  }
`;

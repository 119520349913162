import { AbstractControl, ValidationErrors } from '@angular/forms';
import { first, map, Observable, of } from 'rxjs';

/**
 * Callback function to be utilized by uniqueValidatorAsync
 *
 * @param value The value for which we are checking uniqueness
 * @param entityId An optional entityId for checking uniqueness per entity
 */
// `T` defaults to [number] for backwards compatibility with existing FetchIsUniqueFns that use an `entityId`
export interface FetchIsUniqueFn<T extends unknown[] = [number]> {
  (value: string, ...args: T): Observable<boolean>;
}

export function uniqueValidatorAsync<T extends unknown[]>(
  fetchIsUnique: FetchIsUniqueFn<T>,
  ...args: T
): (control: AbstractControl) => Observable<ValidationErrors | null> {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (control.value == null) {
      return of(null);
    }

    return fetchIsUnique(control.value, ...args).pipe(
      map((unique) => (unique ? null : { unique: control.value })),
      first()
    );
  };
}

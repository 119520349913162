import { Component, ChangeDetectionStrategy } from '@angular/core';
import {
  ResolveEnd,
  ResolveStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { distinctUntilChanged, filter, map, Observable } from 'rxjs';

@Component({
  selector: 'rr-module-loader',
  templateUrl: './module-loader.component.html',
  styleUrls: ['./module-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'flex flex-col grow py-4 px-20' },
})
export class ModuleLoaderComponent {
  moduleLoading$: Observable<boolean>;

  constructor(router: Router) {
    this.moduleLoading$ = router.events.pipe(
      map((event) => {
        if (
          event instanceof RouteConfigLoadStart ||
          event instanceof ResolveStart
        ) {
          return true;
        }
        if (
          event instanceof RouteConfigLoadEnd ||
          event instanceof ResolveEnd
        ) {
          return false;
        }
        return null;
      }),
      filter((loading): loading is boolean => typeof loading === 'boolean'),
      distinctUntilChanged()
    );
  }
}

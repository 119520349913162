import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  ParameterTypeSelectors,
  selectBundleableParameterSubtypes,
  selectBundleableParameterType,
} from '@roadrunner/rating-utility/data-access-parameter-type';
import { productLoaded } from '@roadrunner/rating-utility/data-access-program';
import {
  AddParameterComponent,
  AddParameterDialogData,
  AddParameterDialogResult,
} from '@roadrunner/rating-utility/ui-add-parameter';
import {
  AddProductComponent,
  AddProductDialogData,
  AddProductDialogResult,
  CopyingProductComponent,
  CopyProductComponent,
  CopyProductDialogData,
  CopyProductDialogResult,
} from '@roadrunner/rating-utility/ui-add-product';
import { UserSelectors } from '@roadrunner/shared/data-access-user';
import { JobStatus } from '@roadrunner/shared/util-api';
import { trpcClient } from '@roadrunner/shared/util-trpc';
import {
  EMPTY,
  forkJoin,
  from,
  merge,
  ObservableInput,
  of,
  take,
  throwError,
  timer,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  retry,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { DataService } from '../../apollo/data.service';
import { ParameterKeyFieldsFragment } from '../../apollo/fragments/parameter-key-fields.fragment.generated';
import { SetIsNonSellableGQL } from '../../apollo/mutations/set-is-non-sellable/set-is-non-sellable.mutation.generated';
import { buildUpsertProductParameterKeysVariables } from '../../apollo/mutations/upsert-product-parameter-keys/build-variables';
import { UpsertProductParameterKeysGQL } from '../../apollo/mutations/upsert-product-parameter-keys/upsert-product-parameter-keys.mutation.generated';
import { GetProductGQL } from '../../apollo/queries/get-product/get-product.query.generated';
import { ProductTypesGQL } from '../../apollo/queries/products/product-types/product-types.query.generated';
import { CoverageKeyValue } from '../../models/view-models/products/coverage.view-model';
import {
  IParameterSubTypeVM,
  IProductParameterVM,
  ParameterChangeType,
} from '../../models/view-models/products/product-options.view-model';
import { DialogSize, ModalService } from '../../services/modal.service';
import { ofRoute } from '../../shared/utility/of-route.operator';
import {
  selectChosenProgram,
  selectProgramList,
  selectRiskTypes,
} from '../user/user.selectors';
import * as ProductActions from './product.actions';
import {
  selectAllParameterNames,
  selectAllProductTypes,
  selectChosenProductId,
  selectCoverageParameter,
  selectDeletedCoverageParameterKeys,
  selectDeletedNonCoverageParameterKeys,
  selectProduct,
  selectProductCodes,
  selectProductStateCoverageParameterKeys,
  selectProductStateNonCoverageParameterKeys,
  selectProductTypeList,
  selectSavedProductSettings,
} from './product.selectors';
import { ProductsService } from './services/products.service';
import { ProgramProductCodesGQL } from './services/program-product-codes.query.generated';

@Injectable()
export class ProductEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private dataService: DataService,
    private modalService: ModalService,
    private dialog: MatDialog,
    private productsService: ProductsService,
    private router: Router,
    private upsertProductParameterKeysGQL: UpsertProductParameterKeysGQL,
    private getProductGQL: GetProductGQL,
    private setIsNonSellableGQL: SetIsNonSellableGQL,
    private getProductTypesGQL: ProductTypesGQL,
    private programProductCodesGQL: ProgramProductCodesGQL
  ) {}

  getProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.getProduct),
      switchMap(({ productId }) => {
        return this.getProductGQL.fetch({ productId }).pipe(
          map((response) => {
            return ProductActions.getProductSuccess({
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              productRes: response.data.product_by_pk!,
              parameters: response.data.parameter,
            });
          }),
          catchError((error) => {
            return throwError(error);
          })
        );
      })
    );
  });

  // This is a temporary hack to select the correct program when a product is loaded
  // that does not belong to the currently selected program. This fixes an issue where
  // the selected program did not match the selected product's program when navigating
  // directly to a product via URL or by copying a product to a new program & being
  // redirected programmatically.
  // TODO: figure out how to reconcile the need for a global "selected program" (is there a need?)
  // with the need for the selected program to match the local state's product's program.
  selectProgramForCurrentProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.getProductSuccess),
      map((action) => {
        return productLoaded({
          programId: action.productRes.program.id,
        });
      })
    );
  });

  getProductTypeList$ = createEffect(() => {
    return merge(
      this.actions$.pipe(ofType(ProductActions.getProductTypeList)),
      // Reload product types when the program id changes.
      // This currently only happens when copying a product to a different program.
      this.actions$.pipe(
        ofType(productLoaded),
        startWith({ programId: -1 }),
        distinctUntilChanged((a, b) => a.programId === b.programId)
      ),
      this.actions$.pipe(
        ofRoute('/rating/products'),
        concatLatestFrom((_) => this.store.pipe(select(selectProductTypeList))),
        // This filter prevents the product types from reloading every time the user switches tabs on
        // the product detail page. The tabs are wired to a queryParam, so every tab change is picked
        // up as a route change by the ofRoute operator.
        // TODO: make the ofRoute operator include/exclude specific params or query params to remove
        // the need for this filter, and/or consolidate this logic with the programId logic above.
        filter(([_, productTypes]) => {
          return !productTypes || productTypes.length === 0;
        })
      )
    ).pipe(
      concatLatestFrom((_) => this.store.pipe(select(selectChosenProgram))),
      exhaustMap(([_, program]) => {
        if (!program) {
          return EMPTY;
        }
        return this.getProductTypesGQL.fetch({ programId: program.id }).pipe(
          map((res) => {
            return ProductActions.getProductTypeListSuccess({
              productTypes: res.data.product_type,
            });
          }),
          catchError((error) => {
            return throwError(error);
          })
        );
      })
    );
  });

  saveProductSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.saveProductSettings),
      concatLatestFrom((_) =>
        this.store.pipe(select(selectSavedProductSettings))
      ),
      switchMap(
        ([
          {
            productId,
            programId,
            productTypeId,
            name,
            code,
            description,
            riskType,
          },
          oldSettings,
        ]) => {
          return this.dataService
            .upsertProductSettings(
              productId,
              programId,
              productTypeId,
              name,
              description,
              code,
              riskType
            )
            .pipe(
              map((settingsResponse) => {
                return ProductActions.saveProductSettingsSuccess({
                  settingsResponse,
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  oldSettings: oldSettings!,
                  newSettings: {
                    name,
                    description,
                    code,
                    riskType,
                  },
                });
              }),
              catchError((error) => {
                return throwError(error);
              })
            );
        }
      )
    )
  );

  saveProductParameterKeys$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.saveProductParameters),
      concatLatestFrom((_) => {
        return [
          this.store.pipe(select(selectProductStateNonCoverageParameterKeys)),
          this.store.pipe(select(selectChosenProductId)),
          this.store.pipe(select(selectDeletedNonCoverageParameterKeys)),
        ];
      }),
      switchMap(([_, nonCoverageKeys, pid, parameterKeysToDelete]) => {
        const productId = pid as number;
        const currentParameterIdsInUse = new Set<number>();
        const allParameterIdsInUse = new Set<number>();
        const existingParameterKeyIds: number[] = [];
        const parameterKeysToUpsert: ParameterKeyFieldsFragment[] = [];
        let newParameterKeyCount = 0;

        nonCoverageKeys.forEach((key) => {
          allParameterIdsInUse.add(key.parameter_id);
          if (key.id && key.id > 0) {
            parameterKeysToUpsert.push(key);
            existingParameterKeyIds.push(key.id);
            currentParameterIdsInUse.add(key.parameter_id);
          } else {
            parameterKeysToUpsert.push({
              ...key,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              id: undefined!,
            });
            newParameterKeyCount++;
          }
        });

        const allKeysDeletedFromParameter = parameterKeysToDelete.some(
          (key) => !allParameterIdsInUse.has(key.parameterId)
        );

        const newParameterIdsInUse = new Set<number>();
        let hasNewKeysForNewParam = false;
        parameterKeysToUpsert.forEach((key) => {
          if (!currentParameterIdsInUse.has(key.parameter_id)) {
            hasNewKeysForNewParam = true;
            newParameterIdsInUse.add(key.parameter_id);
          }
        });

        let parameterChangeType = ParameterChangeType.None;

        if (allKeysDeletedFromParameter || hasNewKeysForNewParam) {
          parameterChangeType = ParameterChangeType.AddOrRemoveMultiple;
          // In the narrow case where only a single parameter key for a new parameter is added (e.g., adding a dealer tier to a product with no dealer tiers, and no other change),
          // we can opt into a fast path where we simply update all the rates in place by appending the new parameter key to each product_parameter_key_combination's parameter key ids array.
          if (
            parameterKeysToDelete.length === 0 &&
            newParameterKeyCount === 1 &&
            newParameterIdsInUse.size === 1
          ) {
            parameterChangeType = ParameterChangeType.AddSingleParameterKey;
          }
        }
        const parameterKeyIdsToDelete = parameterKeysToDelete.map(
          (key) => key.parameterKeyId
        );

        const upsertProductParameterKeys =
          this.upsertProductParameterKeysGQL.mutate(
            buildUpsertProductParameterKeysVariables(
              productId,
              parameterKeysToUpsert,
              parameterKeyIdsToDelete
            )
          );
        const deleteRateFactorAndRateFactorGroups =
          parameterKeyIdsToDelete.length > 0
            ? trpcClient.rateSliceFactors.delete.mutate({
                parameterKeyIds: parameterKeyIdsToDelete,
              })
            : of(null);
        return forkJoin([
          upsertProductParameterKeys,
          deleteRateFactorAndRateFactorGroups,
        ]).pipe(
          map(([response]) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const data = response.data!;
            const newParameterKeyIds =
              data.insert_parameter_key?.returning
                .map((key) => key.id)
                .filter((id) => !existingParameterKeyIds.includes(id)) ?? [];

            return ProductActions.saveProductParameterKeysSuccess({
              newParameterKeyIds,
              upserts: data.insert_parameter_key?.returning ?? [],
              newParameterKeys: parameterKeysToUpsert.filter((pk) => !pk.id),
              deletedParameterKeys: parameterKeysToDelete,
              parameterChangeType,
            });
          }),
          catchError((_error) => {
            return of(ProductActions.saveProductParametersFailure());
          })
        );
      })
    );
  });

  saveProductCoverageKeys$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.saveProductCoverages),
      concatLatestFrom((_) => {
        return [
          this.store.pipe(select(selectProductStateCoverageParameterKeys)),
          this.store.pipe(select(selectChosenProductId)),
          this.store.pipe(select(selectDeletedCoverageParameterKeys)),
        ];
      }),
      switchMap(([_, coverageKeys, pid, coveragesToDelete]) => {
        const productId = pid as number;
        coverageKeys = coverageKeys.map((key) => {
          if (key.id > 0) {
            return key;
          }
          return {
            ...key,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            id: undefined!,
          };
        });
        const bundles = coverageKeys.filter(
          (ck) => ck.parameter_sub_keys?.length > 0
        );
        const bundlesWithNewSubKeys = new Map<
          string,
          ParameterKeyFieldsFragment
        >();
        const bundlesWithoutNewChildParameterKeys: ParameterKeyFieldsFragment[] =
          [];
        for (const bundle of bundles) {
          let hasNewChildParameterKeys = false;
          for (const subKey of bundle.parameter_sub_keys) {
            if (!subKey.child_parameter_key.id) {
              hasNewChildParameterKeys = true;
              bundlesWithNewSubKeys.set(bundle.key, bundle);
            }
          }
          if (!hasNewChildParameterKeys) {
            bundlesWithoutNewChildParameterKeys.push(bundle);
          }
        }

        const existingParameterKeyIds = coverageKeys
          .map((k) => k.id)
          .filter((id) => !!id);

        const coverageIdsToDelete = coveragesToDelete.map((key) => key.id);

        const hasChangedParameterCount =
          (coverageKeys.length === 0 && coveragesToDelete.length > 0) ||
          (coverageKeys.length > 0 && !coverageKeys.some((key) => key.id));

        if (bundlesWithNewSubKeys.size > 0) {
          const coverageKeysToUpsert = coverageKeys.filter(
            (ck) => !bundlesWithNewSubKeys.has(ck.key)
          );
          return this.upsertProductParameterKeysGQL
            .mutate(
              buildUpsertProductParameterKeysVariables(
                productId,
                coverageKeysToUpsert,
                coverageIdsToDelete
              )
            )
            .pipe(
              switchMap((res) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const data = res.data!;
                let newParameterKeyIds =
                  data.insert_parameter_key?.returning
                    ?.map((key) => key.id)
                    .filter((id) => !existingParameterKeyIds.includes(id)) ??
                  [];

                const parameterKeysToUpsert: ParameterKeyFieldsFragment[] = [];
                for (const bundle of bundlesWithNewSubKeys.values()) {
                  parameterKeysToUpsert.push({
                    ...bundle,
                    parameter_sub_keys: bundle.parameter_sub_keys.map((psk) => {
                      if (psk.child_parameter_key.id) {
                        return psk;
                      }
                      return {
                        ...psk,
                        child_parameter_key: {
                          ...psk.child_parameter_key,
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          id: res.data!.insert_parameter_key!.returning.find(
                            (ipk) => ipk.key === psk.child_parameter_key.key
                          )!.id,
                        },
                      };
                    }),
                  });
                }

                return this.upsertProductParameterKeysGQL
                  .mutate(
                    buildUpsertProductParameterKeysVariables(
                      productId,
                      parameterKeysToUpsert,
                      coverageIdsToDelete
                    )
                  )
                  .pipe(
                    map((response) => {
                      newParameterKeyIds = newParameterKeyIds.concat(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        response
                          .data!.insert_parameter_key!.returning.map(
                            (key) => key.id
                          )
                          .filter((id) => !existingParameterKeyIds.includes(id))
                      );

                      return ProductActions.saveProductCoverageKeysSuccess({
                        newParameterKeyIds,
                        upserts:
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          response.data!.insert_parameter_key!.returning.concat(
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            res.data!.insert_parameter_key!.returning
                          ),
                        newParameterKeys: coverageKeysToUpsert
                          .concat(parameterKeysToUpsert)
                          .filter((pk) => !pk.id),
                        deletedParameterKeys: coveragesToDelete,
                        hasChangedParameterCount,
                      });
                    }),
                    catchError((_error) => {
                      return of(ProductActions.saveProductCoveragesFailure());
                    })
                  );
              })
            );
        } else {
          return this.upsertProductParameterKeysGQL
            .mutate(
              buildUpsertProductParameterKeysVariables(
                productId,
                coverageKeys,
                coverageIdsToDelete
              )
            )
            .pipe(
              map((response) => {
                const newParameterKeyIds =
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  response
                    .data!.insert_parameter_key!.returning.map((key) => key.id)
                    .filter((id) => !existingParameterKeyIds.includes(id));
                return ProductActions.saveProductCoverageKeysSuccess({
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  upserts: response.data!.insert_parameter_key!.returning,
                  newParameterKeys: coverageKeys.filter((pk) => !pk.id),
                  deletedParameterKeys: coveragesToDelete,
                  hasChangedParameterCount,
                  newParameterKeyIds,
                });
              }),
              catchError((_error) => {
                return of(ProductActions.saveProductCoveragesFailure());
              })
            );
        }
      })
    );
  });

  updateParameterKeyCombinationsAfterParameterChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.saveProductParameterKeysSuccess),
      concatLatestFrom((_) => [this.store.pipe(select(selectChosenProductId))]),
      switchMap(([action, productId]) => {
        if (!productId) {
          return EMPTY;
        }
        const deletedParameterKeyIds = action.deletedParameterKeys.map(
          (key) => key.parameterKeyId
        );

        let requests: ObservableInput<unknown>[];

        if (
          action.parameterChangeType ===
          ParameterChangeType.AddSingleParameterKey
        ) {
          requests = [
            trpcClient.parameterKeyCombinations.expand.mutate({
              productId,
              parameterKeyId: action.newParameterKeyIds[0],
            }),
          ];
        } else {
          requests = [
            this.dataService.updateProductParameterKeyCombinations(
              productId,
              action.newParameterKeyIds,
              deletedParameterKeyIds,
              action.parameterChangeType ===
                ParameterChangeType.AddOrRemoveMultiple
            ),
            this.dataService.updateProductMsrpParameterKeyCombinations(
              productId,
              action.newParameterKeyIds,
              deletedParameterKeyIds,
              action.parameterChangeType ===
                ParameterChangeType.AddOrRemoveMultiple
            ),
          ];
        }

        return forkJoin(requests).pipe(
          switchMap((_) => {
            if (
              action.newParameterKeys.length === 0 &&
              action.deletedParameterKeys.length === 0
            ) {
              // setIsNonSellable has no effect when the user doesn't actually add or remove any parameter keys,
              // since all existing rates should already have their non-sellable flag set correctly.
              // We can't return EMPTY here, because we still want to emit a value so the following `map` still runs.
              return of(null);
            }
            return this.setIsNonSellableGQL.mutate({
              productId,
              limit: null,
            });
          }),
          map(() => {
            return ProductActions.saveProductParametersSuccess();
          }),
          catchError((_error) => {
            return of(ProductActions.saveProductParametersFailure());
          })
        );
      })
    );
  });

  updateParameterKeyCombinationsAfterCoverageChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.saveProductCoverageKeysSuccess),
      concatLatestFrom((_) => [this.store.pipe(select(selectChosenProductId))]),
      switchMap(([action, productId]) => {
        if (!productId) {
          return EMPTY;
        }
        const deletedParameterKeyIds = action.deletedParameterKeys.map(
          (key) => key.id
        );
        return forkJoin([
          this.dataService.updateProductParameterKeyCombinations(
            productId,
            action.newParameterKeyIds,
            deletedParameterKeyIds,
            action.hasChangedParameterCount
          ),
          this.dataService.updateProductMsrpParameterKeyCombinations(
            productId,
            action.newParameterKeyIds,
            deletedParameterKeyIds,
            action.hasChangedParameterCount
          ),
        ]).pipe(
          switchMap((_) => {
            if (
              action.newParameterKeys.length === 0 &&
              action.deletedParameterKeys.length === 0
            ) {
              // setIsNonSellable has no effect when the user doesn't actually add or remove any parameter keys,
              // since all existing rates should already have their non-sellable flag set correctly.
              // We can't return EMPTY here, because we still want to emit a value so the following `map` still runs.
              return of(null);
            }
            return this.setIsNonSellableGQL.mutate({
              productId,
              limit: null,
            });
          }),
          map(() => {
            return ProductActions.saveProductCoveragesSuccess();
          }),
          catchError((_error) => {
            return of(ProductActions.saveProductCoveragesFailure());
          })
        );
      })
    );
  });

  private readonly addProductDialogId = 'add-product';

  addProductDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.addProductClicked),
      concatLatestFrom(() => [
        this.store.pipe(select(selectProductCodes)),
        this.store.pipe(select(selectAllProductTypes)),
        this.store.pipe(select(selectRiskTypes)),
      ]),
      exhaustMap(([_action, productCodes, productTypes, riskTypes]) => {
        return this.modalService
          .open<AddProductDialogData, AddProductDialogResult>(
            AddProductComponent,
            {
              id: this.addProductDialogId,
              width: DialogSize.Medium,
              data: {
                productCodes,
                productTypes,
                riskTypes,
              },
            }
          )
          .pipe(
            filter((product) => product != null),
            map((product) => ProductActions.addProduct({ product }))
          );
      })
    );
  });

  copyProductDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.copyProductClicked),
      exhaustMap(() => {
        // Program product codes are loaded each time this dialog is opened.
        // This is a bit heavy, but it avoids us having to keep this in state & update it
        // whenever new products/programs are added or updated.
        return this.programProductCodesGQL.fetch().pipe(
          concatLatestFrom(() => [
            this.store.pipe(select(selectProduct)),
            this.store.pipe(select(selectChosenProgram)),
            this.store.pipe(select(selectProgramList)),
          ]),
          switchMap(
            ([programProductCodesResponse, product, program, allPrograms]) => {
              if (!program || !product || !allPrograms) {
                return EMPTY;
              }

              const programProductCodes =
                programProductCodesResponse.data.program;

              return this.dialog
                .open<
                  CopyProductComponent,
                  CopyProductDialogData,
                  CopyProductDialogResult
                >(CopyProductComponent, {
                  id: this.addProductDialogId,
                  width: DialogSize.Medium,
                  data: {
                    programs: programProductCodes.map((p) => {
                      return {
                        id: p.id,
                        agentCode: p.agentCode,
                        name: p.name,
                        productCodes: p.products.flatMap((pr) => pr.code),
                      };
                    }),
                    product: {
                      id: product.id,
                      code: product.code,
                      name: product.name,
                      description: product.description,
                    },
                    programId: program.id,
                  },
                })
                .afterClosed()
                .pipe(
                  filter(
                    (result): result is CopyProductDialogResult =>
                      result != null
                  ),
                  tap(() => {
                    this.dialog.getDialogById(this.addProductDialogId)?.close();
                  }),
                  map((result) => ProductActions.copyProduct(result))
                );
            }
          )
        );
      })
    );
  });

  copyProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.copyProduct),
      concatLatestFrom(() => [
        this.store.select(UserSelectors.selectUserLogInfo),
      ]),
      exhaustMap(([action, userLogInfo]) => {
        const dialogRef = this.dialog.open(CopyingProductComponent, {
          disableClose: true,
        });
        return from(
          trpcClient.product.copy.mutate({
            programId: action.programId,
            productId: action.productId,
            product: {
              code: action.code,
              name: action.name,
              description: action.description,
            },
            currentUserEmail: userLogInfo.user_email ?? '',
            currentUserName: userLogInfo.user_name ?? '',
          })
        ).pipe(
          switchMap((jobId) => {
            return timer(0, 3000).pipe(
              exhaustMap(() => {
                return from(
                  trpcClient.product.getCopyProductStatus.query({ jobId })
                ).pipe(
                  retry({ count: 3, delay: 500 }),
                  catchError((_) =>
                    of({
                      jobId,
                      status: JobStatus.Error,
                      error: 'Failed to query job status',
                      productId: -1,
                    })
                  )
                );
              })
            );
          }),
          filter(
            (job) =>
              job.status === JobStatus.Complete ||
              job.status === JobStatus.Error
          ),
          take(1),
          map((job) => {
            dialogRef.close();
            return job.status === JobStatus.Complete
              ? ProductActions.copyProductSuccess({
                  id: job.productId,
                  code: action.code,
                  name: action.name,
                })
              : ProductActions.copyProductFailure({ error: job.error });
          }),
          catchError((error) => {
            dialogRef.close();
            return of(ProductActions.copyProductFailure({ error }));
          })
        );
      })
    );
  });

  addProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.addProduct),
      concatLatestFrom(() => this.store.pipe(select(selectChosenProgram))),
      exhaustMap(([action, program]) => {
        if (!program) {
          return EMPTY;
        }
        return this.productsService
          .add({
            program_id: program.id,
            product_type_id: action.product.productTypeId,
            name: action.product.name,
            description: action.product.description,
            code: action.product.code,
            risk_type: action.product.riskType,
          })
          .pipe(
            map((product) =>
              ProductActions.addProductSuccess({
                id: product.id,
                code: action.product.code,
                name: action.product.name,
                productTypeId: action.product.productTypeId,
              })
            ),
            catchError((error) =>
              of(ProductActions.addProductFailure({ error }))
            )
          );
      })
    );
  });

  navigateToNewProduct$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          ProductActions.addProductSuccess,
          ProductActions.copyProductSuccess
        ),
        tap((action) => {
          this.router.navigate(['rating', 'products', action.id]);
        })
      );
    },
    { dispatch: false }
  );

  addParameterDialog$ = createEffect(() => {
    return this.actions$.pipe(ofType(ProductActions.addParameterClicked)).pipe(
      concatLatestFrom((_) => [
        this.store.pipe(select(ParameterTypeSelectors.selectAllParameterTypes)),
        this.store.pipe(select(selectAllParameterNames)),
      ]),
      exhaustMap(([_, parameterTypes, parameterNames]) => {
        const data: AddParameterDialogData = {
          parameterTypes: parameterTypes.filter((pt) => !pt.bundleable),
          parameterNames,
        };

        return this.modalService
          .open<AddParameterDialogData, AddParameterDialogResult | null>(
            AddParameterComponent,
            {
              width: DialogSize.Medium,
              data,
              // The add parameter dialog handles focus restoration
              restoreFocus: false,
            }
          )
          .pipe(
            map((parameter) => {
              return parameter
                ? ProductActions.addParameter({ parameter })
                : ProductActions.addParameterCancelled();
            })
          );
      })
    );
  });

  addParameter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.addParameter),
      concatLatestFrom(() => [
        this.store.pipe(select(selectChosenProgram)),
        this.store.pipe(
          select(ParameterTypeSelectors.selectAllParameterSubtypes)
        ),
        this.store.pipe(select(ParameterTypeSelectors.selectAllParameterTypes)),
      ]),
      exhaustMap(([action, program, parameterSubtypes, parameterTypes]) => {
        if (!program) {
          return EMPTY;
        }

        return this.productsService
          .addParameter({
            name: action.parameter.name,
            description: action.parameter.description,
            parameterTypeId: action.parameter.parameterTypeId,
          })
          .pipe(
            map((parameter) =>
              ProductActions.addParameterSuccess({
                id: parameter.id,
                name: parameter.name,
                description: parameter.description,
                parameterTypeId: parameter.parameterTypeId,
                parameterSubTypes: parameterSubtypes.filter(
                  (subtype) =>
                    subtype.parameterTypeId === parameter.parameterTypeId
                ),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                parameterType: parameterTypes.find(
                  (pt) => pt.id === parameter.parameterTypeId
                )!,
              })
            ),
            catchError((error) =>
              of(ProductActions.addParameterFailure({ error }))
            )
          );
      })
    );
  });

  addCoverageDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductActions.addCoverageClicked),
      concatLatestFrom(() => [
        this.store.pipe(select(selectCoverageParameter)),
        this.store.pipe(select(selectBundleableParameterType)),
        this.store.pipe(select(selectBundleableParameterSubtypes)),
      ]),
      switchMap(
        ([
          action,
          coverageParam,
          coverageParameterType,
          coverageParameterSubtypes,
        ]) => {
          if (coverageParam) {
            return of([
              action.id,
              action.existingParameterKeyValuesBySubtype,
              coverageParam,
            ] as const);
          }
          if (!coverageParameterType) {
            return EMPTY;
          }
          return this.productsService
            .addParameter({
              name: 'Coverage',
              description: 'Coverage',
              parameterTypeId: coverageParameterType.id,
            })
            .pipe(
              map((response) => {
                const coverageParameter: IProductParameterVM = {
                  parameterId: response.id,
                  parameterName: response.name,
                  description: response.description,
                  keys: [],
                  parameterType: {
                    bundleable: coverageParameterType.bundleable,
                    description: coverageParameterType.description,
                    parameterTypeID: coverageParameterType.id,
                    parameterSubTypes: coverageParameterSubtypes.map(
                      (pst): IParameterSubTypeVM => {
                        return {
                          isIdentifier: pst.isIdentifier,
                          isUnique: pst.isUnique,
                          isGlobalUnique: pst.isGlobalUnique,
                          controlType: pst.controlType,
                          options: pst.parameterSubtypeOptions,
                          parameterSubTypeID: pst.id,
                          parameterTypeID: pst.parameterTypeId,
                          sortOrder: pst.sortOrder,
                          subType: pst.subtype,
                          visible: pst.visible,
                        };
                      }
                    ),
                    type: coverageParameterType.type,
                  },
                };
                return [
                  action.id,
                  action.existingParameterKeyValuesBySubtype,
                  coverageParameter,
                ] as const;
              })
            );
        }
      ),
      switchMap(([id, existingParameterKeyValuesBySubtype, coverageParam]) => {
        return this.modalService
          .openStandaloneCoverageModal(
            id,
            this.getCoverageParameterSubtypes(coverageParam),
            existingParameterKeyValuesBySubtype,
            true
          )
          .pipe(
            map((coverage) => {
              return coverage
                ? ProductActions.addStandaloneCoverage({
                    coverage,
                    coverageParam,
                  })
                : ProductActions.addStandaloneCoverageCancelled();
            })
          );
      })
    );
  });

  private getCoverageParameterSubtypes(
    coverageParam: IProductParameterVM
  ): CoverageKeyValue[] {
    return coverageParam.parameterType.parameterSubTypes
      .filter((pst) => pst.visible)
      .map((pst): CoverageKeyValue => {
        return {
          isIdentifier: pst.isIdentifier,
          isUnique: pst.isUnique,
          parameterSubtypeId: pst.parameterSubTypeID,
          parameterSubtype: pst.subType,
          value: '',
        };
      });
  }
}

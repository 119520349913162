import {
  IServerSideGetRowsRequest,
  LoadSuccessParams,
} from '@ag-grid-community/core';
import { createAction, props } from '@ngrx/store';
import {
  RateFactorGroup,
  RateSliceFactorGroup,
} from '../../models/view-models/rate-factors/rate-factor-group';

import { IUpsertBucketOffsetResponse } from '../../apollo/mutations/upsert-bucket-offset/upsert-bucket-offset.interface';
import { IUpsertBucketRoundingResponse } from '../../apollo/mutations/upsert-bucket-rounding/upsert-bucket-rounding.interface';
import { IGetBucketByIdResponse } from '../../apollo/queries/buckets/get-bucket-by-id/get-bucket-by-id.interface';
import { IOffsetBucketListResponse } from '../../apollo/queries/buckets/get-offset-bucket-list/get-offset-bucket-list.interface';
import { IGetProductParamByBucketIdResponse } from '../../apollo/queries/buckets/get-product-param-by-bucket-id/get-product-param-by-bucket-id.interface';
import { ConfiguredOffset } from '../../apollo/queries/get-offset-bucket/get-offset-bucket.interface';
import { IBundledCoverageParameterDiscountVM } from '../../models/view-models/buckets/bucket.view-model';
import { ProductTypeListItem } from '../../models/view-models/products/product-type.view-model';
import { BaseParameter } from '../../models/view-models/buckets/base-parameter';
import { IParameterVM } from '../../models/view-models/parameters/parameter.view-model';
import {
  RateFactor,
  RateFactorApi,
} from '../../models/view-models/rate-factors/rate-factor-parameter.view-model';
import { Payee } from '../rate/services/get-payees-types';
import { SiblingRateSlices } from './models/sibling-rate-slices';

export const loadBucket = createAction(
  '[Bucket Guard] Load',
  props<{ bucketId: number }>()
);

export const loadBucketSuccess = createAction(
  '[API] Load Bucket Success',
  props<{
    bucket: IGetBucketByIdResponse;
    productParameters: IGetProductParamByBucketIdResponse;
    siblingRateSlices: SiblingRateSlices;
    baseParameters: BaseParameter[];
    rateSliceFactors: RateFactorApi[];
    offsetBucket: ConfiguredOffset | null;
    offsetBucketList: IOffsetBucketListResponse;
    productTypes: ProductTypeListItem[];
    payees: Payee[];
    rateSliceFactorGroups: RateSliceFactorGroup[];
  }>()
);

export const leaveBucket = createAction('[Bucket Settings Component] Leave');

/// COMPONENT BASED ACTIONS
export const updateBucketSettings = createAction(
  '[Bucket Settings Component] Update Settings',
  props<{
    bucketId: number;
    newName: string;
    payee: Payee;
    cmsBucketNumber: number | null;
    reserves: boolean;
  }>()
);
export const updateBucketSettingsSuccess = createAction(
  '[Bucket Settings Component] Update Settings Success',
  props<{
    oldName: string;
    newName: string;
    newPayee: Payee;
    oldPayee: Payee;
    oldCmsBucketNumber: number | null;
    newCmsBucketNumber: number | null;
    oldReserves: boolean;
    newReserves: boolean;
  }>()
);
export const updateBucketSettingsFailure = createAction(
  '[Bucket Settings Component] Update Settings Failure'
);

export const deleteBucket = createAction(
  '[Bucket Settings Component] Delete Bucket'
);
export const deleteBucketSuccess = createAction(
  '[Bucket Settings Component] Delete Bucket Success',
  props<{ productId: number }>()
);
export const deleteBucketFailure = createAction(
  '[Bucket Settings Component] Delete Bucket Failure',
  props<{ error: unknown }>()
);

export const saveBaseParameters = createAction(
  '[Base Rates Component] Save Base Parameters',
  props<{ baseParameters: IParameterVM[] }>()
);
export const saveBaseParameterSuccess = createAction(
  '[API] Save Base Parameters Success',
  props<{
    parameterIds: number[];
    parametersAdded: { parameterId: number; parameterName: string }[];
    parametersDeleted: { parameterId: number; parameterName: string }[];
  }>()
);
export const saveBaseParameterFailure = createAction(
  '[API] Save Base Parameters Failure',
  props<{ error: unknown }>()
);

export const baseRateCellValueChanged = createAction(
  '[Base Rates Component] Cell Value Changed',
  props<{
    bucketRateIds: number[];
    parameterKeyIds: number[];
    oldBase: number;
    newBase: number;
  }>()
);
export const baseRateSaveSuccess = createAction(
  '[API] Save Base Rate Success',
  props<{
    parameterKeys: string[];
    oldBase: number;
    newBase: number;
  }>()
);
export const baseRateSaveFailure = createAction(
  '[API] Save Base Rate Failure',
  props<{ error: unknown }>()
);
export const baseRatesPageLoadSuccess = createAction(
  '[API] Base Rates Page Load Success',
  props<LoadSuccessParams>()
);
export const baseRatesPageLoadFailure = createAction(
  '[API] Base Rates Page Load Failure',
  props<{ error: unknown }>()
);
export const baseRatesPaged = createAction(
  '[Base Rates Component] Paged',
  props<IServerSideGetRowsRequest>()
);

export const baseSlicesSaved = createAction(
  '[Base Rates] Slice Selection Saved',
  props<{ baseSliceIds: number[] }>()
);
export const switchToBaseSlicesClicked = createAction(
  '[Base Rates] Switch to Base Slices Clicked'
);
export const switchToBaseRatesClicked = createAction(
  '[Base Rates] Switch to Base Rates Clicked'
);
export const saveBaseSlicesSuccess = createAction(
  '[API] Save Base Slices Success',
  props<{ baseSliceIds: number[] }>()
);
export const saveBaseSlicesFailure = createAction(
  '[API] Save Base Slices Failure',
  props<{ error: unknown }>()
);
export const switchToBaseRatesSuccess = createAction(
  '[API] Switch to Base Rates Success'
);
export const switchToBaseRatesFailure = createAction(
  '[API] Switch to Base Rates Failure',
  props<{ error: unknown }>()
);
export const switchToBaseSlicesSuccess = createAction(
  '[API] Switch to Base Slices Success'
);
export const switchToBaseSlicesFailure = createAction(
  '[API] Switch to Base Slices Failure',
  props<{ error: unknown }>()
);

export const exceptionsPaged = createAction(
  '[Exceptions Component] Paged',
  props<IServerSideGetRowsRequest>()
);
export const exceptionsPageLoadSuccess = createAction(
  '[API] Exceptions Page Load Success',
  props<LoadSuccessParams>()
);
export const exceptionsPageLoadFailure = createAction(
  '[API] Exceptions Page Load Failure',
  props<{ error: unknown }>()
);
export const exceptionChanged = createAction(
  '[Exceptions Component] Cell Value Changed',
  props<{
    productParameterKeyCombinationIds: number[];
    parameterKeyIds: number[];
    oldValue: number | null;
    newValue: number;
  }>()
);
export const exceptionSaveSuccess = createAction(
  '[API] Save Exception Success',
  props<{
    parameterKeys: string[];
    oldExceptionValue: number | null;
    newExceptionValue: number | null;
  }>()
);
export const exceptionSaveFailure = createAction(
  '[API] Save Exception Failure',
  props<{ error: unknown }>()
);
export const exceptionDeleted = createAction(
  '[Exceptions Component] Cell Value Removed',
  props<{
    productParameterKeyCombinationIds: number[];
    parameterKeyIds: number[];
    oldExceptionValue: number;
  }>()
);
export const reviewPaged = createAction(
  '[Bucket Review Component] Paged',
  props<IServerSideGetRowsRequest>()
);
export const reviewPageLoadSuccess = createAction(
  '[API] Bucket Review Page Load Success',
  props<LoadSuccessParams>()
);
export const reviewPageLoadFailure = createAction(
  '[API] Bucket Review Page Load Failure',
  props<{ error: unknown }>()
);

export const rateFactorsSaveSuccess = createAction(
  '[API] Rate Factors Save Success',
  props<{
    rateFactorsResponse: RateFactorApi[];
    oldRateFactors: RateFactorApi[];
    rateSliceFactorGroups: RateSliceFactorGroup[];
  }>()
);

export const rateFactorsSaveFailure = createAction(
  '[API] Rate Factors Save Failure'
);

export const rateFactorSave = createAction(
  '[Rate Factors Component] Rate Factor Save',
  props<{
    rateFactors: RateFactor[];
    rateFactorGroups: RateFactorGroup[];
    rateFactorGroupIdsToDelete: number[];
  }>()
);

export const bundleDiscountsSave = createAction(
  '[Bundle Discounts] Save',
  props<{ discounts: IBundledCoverageParameterDiscountVM[] }>()
);
export const bundleDiscountsSaveSuccess = createAction(
  '[Bundle Discounts] Save Success',
  props<{
    oldDiscounts: IBundledCoverageParameterDiscountVM[];
    newDiscounts: IBundledCoverageParameterDiscountVM[];
  }>()
);
export const bundleDiscountsSaveFailure = createAction(
  '[Bundle Discounts] Save Failure'
);

export const minChanged = createAction(
  '[Min-Max Component] Bucket Min Changed',
  props<{ min: number }>()
);
export const maxChanged = createAction(
  '[Min-Max Component] Bucket Max Changed',
  props<{ max: number }>()
);

export const minMaxSave = createAction(
  '[Min-Max Component] Bucket Min Max Save',
  props<{ min: number | null; max: number | null }>()
);
export const minMaxSaveSuccess = createAction(
  '[Min-Max Component] Bucket Min Max Save Success',
  props<{
    oldMin: number | null;
    oldMax: number | null;
    newMin: number | null;
    newMax: number | null;
  }>()
);
export const minMaxSaveFailure = createAction(
  '[Min-Max Component] Bucket Min Max Save Failure'
);

export const roundingAdded = createAction(
  '[Offset/Rounding Component] Bucket Rounding Added',
  props<{ roundingValue: number; roundingType: string }>()
);
export const roundingChanged = createAction(
  '[Offset/Rounding Component] Bucket Rounding Changed',
  props<{ roundingValue: number; roundingType: string }>()
);
export const roundingRemoved = createAction(
  '[Offset/Rounding Component] Bucket Rounding Removed'
);

export const offsetAdded = createAction(
  '[Offset/Rounding Component] Bucket Offset Added',
  props<{ bucketId: number; offsetType: string; offsetBucketName: string }>()
);
export const offsetChanged = createAction(
  '[Offset/Rounding Component] Bucket Offset Changed',
  props<{ bucketId: number; offsetType: string; offsetBucketName: string }>()
);
export const offsetRemoved = createAction(
  '[Offset/Rounding Component] Bucket Offset Removed'
);

export const offsetTypeChanged = createAction(
  '[Offset/Rounding Component] Cell Value Changed',
  props<{ offsetType: string }>()
);

export const offsetRoundingSave = createAction(
  '[Offset/Rounding Component] Bucket Offset Rounding Save'
);
export const offsetRoundingSaveSuccess = createAction(
  '[Offset/Rounding Component] Bucket Offset Rounding Save Success',
  props<{
    oldOffset: {
      offsetType: string | null;
      offsetBucketId: number | null;
    };
    oldRounding: {
      roundingType: string | null;
      roundingValue: number | null;
    };
    newOffset: IUpsertBucketOffsetResponse | null;
    newRounding: IUpsertBucketRoundingResponse | null;
  }>()
);
export const offsetRoundingSaveFailure = createAction(
  '[Offset/Rounding Component] Bucket Offset Rounding Save Failure'
);

export const reviewSave = createAction('[Review Component] Bucket Review Save');
export const reviewSaveSuccess = createAction(
  '[Review Component] Bucket Review Save Success'
);
export const reviewSaveFailure = createAction(
  '[Review Component] Bucket Review Save Failure'
);

import { Directive, HostListener, Optional } from '@angular/core';
import { FormControlDirective, FormControlName } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formControlName][trimWhitespace], [formControl][trimWhitespace]',
})
export class TrimWhitespaceDirective {
  constructor(
    @Optional() private formControlDir: FormControlDirective,
    @Optional() private formControlName: FormControlName
  ) {}

  @HostListener('blur')
  @HostListener('keydown.enter', ['$event'])
  trimValue(event?: Event) {
    const control =
      this.formControlDir?.control || this.formControlName?.control;
    if (typeof control.value === 'string') {
      const trimmed = control.value.trim();
      if (trimmed !== control.value) {
        control.setValue(trimmed);
        // If a control is invalid but trimming it makes it valid, the form might submit immediately,
        // which creates a weird UX where it seems like the user is submitting an invalid form.
        // Stop propagation on the keydown event to prevent the form submission when the value has changed,
        // to give the user a chance to see that the value was changed.
        if (event) {
          event.stopPropagation();
          event.preventDefault();
        }
      }
    }
  }
}

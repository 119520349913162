import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Account } from '../models/account.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private signedInAccountSubject = new BehaviorSubject<Account | null>(null);
  signedInAccount$ = this.signedInAccountSubject.asObservable();

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    let account = this.authService.instance.getActiveAccount();
    if (account) {
      this.emitAccount(account);
    } else if (!environment.requireAuth) {
      account = {
        localAccountId: '-1',
        name: 'e2e test guy',
        username: 'e2e-test-guy@cool-dude.com',
      } as AccountInfo;
      this.emitAccount(account);
    } else {
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter(
            (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
          ),
          take(1)
        )
        .subscribe((result: EventMessage) => {
          const payload = result.payload as AuthenticationResult;
          this.authService.instance.setActiveAccount(payload.account);
          this.emitAccount(payload.account as AccountInfo);
        });
    }
  }

  logOut() {
    this.authService
      .logoutRedirect()
      .pipe(take(1))
      .subscribe(() => {
        // noop
      });
  }

  private emitAccount(account: AccountInfo) {
    this.signedInAccountSubject.next({
      accountId: account.localAccountId,
      email: account.username,
      name: account.name as string,
    });
  }
}

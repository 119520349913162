import { Injectable } from '@angular/core';
import { LoggingService } from '@roadrunner/shared/client-logging';
import * as BucketActions from './bucket.actions';
import { selectBucketLogInfo } from './bucket.selectors';

@Injectable()
export class BucketLoggingEffects {
  private getLogEffect =
    this.loggingService.getLogEffectCreator(selectBucketLogInfo);

  private getSimpleChangeLogEffect =
    this.loggingService.getSimpleValueChangeEffectCreator(selectBucketLogInfo);

  constructor(private loggingService: LoggingService) {}

  logMinChange$ = this.getSimpleChangeLogEffect(
    'minChanged',
    BucketActions.minMaxSaveSuccess,
    (a) => a.newMin,
    (a) => a.oldMin
  );

  logMaxChange$ = this.getSimpleChangeLogEffect(
    'maxChanged',
    BucketActions.minMaxSaveSuccess,
    (a) => a.newMax,
    (a) => a.oldMax
  );

  logRoundingChange$ = this.getSimpleChangeLogEffect(
    'bucketRoundingChanged',
    BucketActions.offsetRoundingSaveSuccess,
    (a) => {
      if (a.newRounding?.insert_bucket_rounding_one.round_by !== 0) {
        return {
          roundingType:
            a.newRounding?.insert_bucket_rounding_one.round_type ?? null,
          roundingValue:
            a.newRounding?.insert_bucket_rounding_one.round_by ?? null,
        };
      }
    },
    (a) => {
      if (a.newRounding?.insert_bucket_rounding_one.round_by !== 0) {
        return {
          roundingType: a.oldRounding?.roundingType ?? null,
          roundingValue: a.oldRounding?.roundingValue ?? null,
        };
      }
    }
  );

  logOffsetChange$ = this.getSimpleChangeLogEffect(
    'bucketOffsetChanged',
    BucketActions.offsetRoundingSaveSuccess,
    (a) => ({
      offsetBucketId:
        a.newOffset?.insert_bucket_offset_one.offset_bucket.id ?? null,
      offsetType: a.newOffset?.insert_bucket_offset_one.offset_type ?? null,
    }),
    (a) => ({
      offsetBucketId: a.oldOffset?.offsetBucketId ?? null,
      offsetType: a.oldOffset?.offsetType ?? null,
    })
  );

  logUpdateBucketSettings$ = this.getSimpleChangeLogEffect(
    'bucketSettingsChanged',
    BucketActions.updateBucketSettingsSuccess,
    (a) => ({
      name: a.newName,
      payeeCode: a.newPayee.code,
      cmsBucketNumber: a.newCmsBucketNumber,
      reserves: a.newReserves,
    }),
    (a) => ({
      name: a.oldName,
      payeeCode: a.oldPayee.code,
      cmsBucketNumber: a.oldCmsBucketNumber,
      reserves: a.oldReserves,
    })
  );

  logBucketBundleDiscountsSave$ = this.getLogEffect(
    'bucketBundleDiscountsSave',
    BucketActions.bundleDiscountsSaveSuccess,
    (action) => {
      // TODO: it would be nice to move this into the actual save effect so that we only save discounts that have actually changed
      const changedDiscounts = action.newDiscounts.reduce(
        (
          changes: {
            parameterSubKeyId: number;
            oldDiscount: number | null;
            newDiscount: number | null;
          }[],
          nd
        ) => {
          const oldDiscount =
            action.oldDiscounts.find(
              (od) => od.parameterSubKeyId === nd.parameterSubKeyId
            )?.discount ?? null;
          if (oldDiscount != nd.discount) {
            changes.push({
              parameterSubKeyId: nd.parameterSubKeyId,
              oldDiscount,
              newDiscount: nd.discount,
            });
          }
          return changes;
        },
        []
      );
      return { changedDiscounts };
    }
  );

  logBucketBaseParameterSave$ = this.getLogEffect(
    'bucketBaseParameterSave',
    BucketActions.saveBaseParameterSuccess,
    (a) => ({
      addedBaseParameters: a.parametersAdded,
      deletedBaseParameters: a.parametersDeleted,
    })
  );

  logBucketBaseRatesSave$ = this.getSimpleChangeLogEffect(
    'bucketBaseRatesSave',
    BucketActions.baseRateSaveSuccess,
    (a) => a.newBase,
    (a) => a.oldBase,
    (a) => ({ parameterKeys: a.parameterKeys })
  );

  logBucketExceptionSave$ = this.getSimpleChangeLogEffect(
    'bucketExceptionSave',
    BucketActions.exceptionSaveSuccess,
    (a) => a.newExceptionValue,
    (a) => a.oldExceptionValue,
    (a) => ({ parameterKeys: a.parameterKeys })
  );

  logRateFactorsSave$ = this.getSimpleChangeLogEffect(
    'rateFactorsSave',
    BucketActions.rateFactorsSaveSuccess,
    (a) => a.rateFactorsResponse,
    (a) => a.oldRateFactors
  );

  logBucketReviewRatesSave$ = this.getLogEffect(
    'bucketReviewRatesSave',
    BucketActions.reviewSaveSuccess
  );
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { SharedUtilFormsModule } from '@roadrunner/shared/util-forms';

import { SharedUiDialogModule } from '@roadrunner/shared/ui-dialog';
import { AddPayeeComponent } from './add-payee/add-payee.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    SharedUiDialogModule,
    SharedUtilFormsModule,
  ],
  declarations: [AddPayeeComponent],
})
export class RatingUtilityUiAddPayeeModule {}

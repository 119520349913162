import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  // Temporarily use ViewEncapsulation.None until styles can be ported to tailwind
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .breadcrumb {
        list-style: none;
        border-radius: 4px;
      }
      .breadcrumb .mat-icon {
        height: 1rem;
        width: 1rem;
        line-height: 1rem;
      }
      .breadcrumb .mat-icon:first-child {
        margin-right: 0.25rem;
        color: #42c780;
        font-size: 1rem;
      }
      .breadcrumb a,
      .breadcrumb button {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 0.75rem;
      }
    `,
  ],
})
export class BreadcrumbsComponent {}

import {
  NonSellableCombination,
  NonSellableCombinationOption,
} from './queries/get-non-sellable-combinations/get-non-sellable-combinations.interface';

export function reduceNonSellableCombinationOptions(
  productId: number,
  options: NonSellableCombinationOption[]
) {
  const indices = new Map<number, number>();
  return {
    non_sellable_combinations: options.reduce(
      (combinations: NonSellableCombination[], option) => {
        if (indices.has(option.non_sellable_combination_id)) {
          combinations[
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            indices.get(option.non_sellable_combination_id)!
          ].non_sellable_combination_options.push(option);
        } else {
          combinations.push({
            id: option.non_sellable_combination_id,
            non_sellable_combination_options: [option],
            product_id: productId,
          });
          indices.set(
            option.non_sellable_combination_id,
            combinations.length - 1
          );
        }

        return combinations;
      },
      []
    ),
  };
}

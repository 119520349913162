// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
export type ParameterSubKeyFieldsFragment = { id: number, parent_parameter_key_id: number, child_parameter_key: { id: number, key: string, parameter_id: number, product_id: number, sort_order: number } };

export const ParameterSubKeyFieldsFragmentDoc = gql`
    fragment ParameterSubKeyFields on parameter_sub_key {
  id
  parent_parameter_key_id
  child_parameter_key {
    id
    key
    parameter_id
    product_id
    sort_order
  }
}
    `;
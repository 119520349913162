import { CommonModule } from '@angular/common';
import { FactoryProvider, NgModule } from '@angular/core';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { EffectsModule } from '@ngrx/effects';
import {
  NavigationActionTiming,
  routerReducer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import {
  ActionReducer,
  MetaReducer,
  StoreModule,
  USER_PROVIDED_META_REDUCERS,
} from '@ngrx/store';
import {
  INITIAL_OPTIONS as STORE_DEV_TOOLS_INITIAL_OPTIONS,
  StoreDevtoolsModule,
  StoreDevtoolsOptions,
} from '@ngrx/store-devtools';
import { LoggingConfig } from '@roadrunner/shared/client-logging';
import { SharedDataAccessUserModule } from '@roadrunner/shared/data-access-user';
import { ReduxDevToolsConfig } from '@roadrunner/shared/util-ngrx';
import LogRocket from 'logrocket';
import { localStorageSync } from 'ngrx-store-localstorage';
import { BucketLoggingEffects } from './bucket/bucket-logging.effects';
import { BucketEffects } from './bucket/bucket.effects';
import { bucketReducer } from './bucket/bucket.reducers';
import { ProductLoggingEffects } from './product/product-logging.effects';
import { ProductEffects } from './product/product.effects';
import { productReducer } from './product/product.reducers';
import { RatingLoggingEffects } from './rate/rate-logging.effects';
import { RateEffects } from './rate/rate.effects';
import { rateReducer } from './rate/rate.reducers';
import { RouteParamsEffects } from './router-param/router-param.effects';
import { UserEffects } from './user/user.effects';
import { userReducer } from './user/user.reducers';

const reduxMiddleware = LogRocket.reduxMiddleware();

export function logrocketMiddleware(
  reducer: ActionReducer<unknown>
): ActionReducer<unknown> {
  let currentState: unknown;
  const fakeDispatch = reduxMiddleware({
    getState: () => currentState,
  })(() => {
    // noop
  });

  return function (state, action) {
    const newState = reducer(state, action);
    currentState = state;
    fakeDispatch(action);
    return newState;
  };
}

export function localStorageSyncReducer(
  reducer: ActionReducer<unknown>
): ActionReducer<unknown> {
  return localStorageSync({
    keys: [
      {
        userState: ['selectedProgram', 'riskTypes'],
      },
      'routeParams',
    ],
    rehydrate: true,
  })(reducer);
}

export const metaReducersProvider: FactoryProvider = {
  provide: USER_PROVIDED_META_REDUCERS,
  useFactory: (loggingConfig: LoggingConfig): MetaReducer[] => {
    return loggingConfig?.enableNgrxMiddleware
      ? [localStorageSyncReducer, logrocketMiddleware]
      : [localStorageSyncReducer];
  },
  deps: [LoggingConfig],
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot({
      router: routerReducer,
      userState: userReducer,
      rateState: rateReducer,
      bucketState: bucketReducer,
      productState: productReducer,
    }),
    StoreRouterConnectingModule.forRoot({
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    EffectsModule.forRoot([
      UserEffects,
      RateEffects,
      BucketEffects,
      ProductEffects,
      RouteParamsEffects,
      BucketLoggingEffects,
      ProductLoggingEffects,
      RatingLoggingEffects,
    ]),
    StoreDevtoolsModule.instrument(),
    SharedDataAccessUserModule,
    MatSnackBarModule,
  ],
  providers: [
    {
      provide: STORE_DEV_TOOLS_INITIAL_OPTIONS,
      useFactory: (
        reduxDevToolsConfig: ReduxDevToolsConfig
      ): StoreDevtoolsOptions => {
        return {
          logOnly: reduxDevToolsConfig.enabled,

          maxAge: 25,
          serialize: {
            // Limit serialization of arrays to avoid performance problems.
            // We could target individual actions and parts of the state instead, but this is a lot easier than
            // maintaining a list of all actions that might have tens of thousands of entries in an array.
            replacer: (_key: string, value: Record<string, unknown>) => {
              if (Array.isArray(value) && value.length > 100) {
                return value
                  .slice(0, 100)
                  .concat('Truncated for Redux DevTools');
              }
              // @ngrx/entity state
              if (
                typeof value === 'object' &&
                value != null &&
                'ids' in value &&
                'entities' in value
              ) {
                let ids: (number | string)[] = value['ids'] as (
                  | number
                  | string
                )[];
                let entities: Record<string | number, unknown> = value[
                  'entities'
                ] as Record<string | number, unknown>;
                if (ids.length > 100) {
                  ids = ids
                    .slice(0, 100)
                    .concat('Truncated for Redux DevTools');
                  entities = ids.reduce(
                    (map: Record<string | number, unknown>, id) => {
                      map[id] = entities[id];
                      return map;
                    },
                    {}
                  );
                }
                return { ids, entities };
              }
              return value as Record<string, unknown>;
            },
          },
        };
      },
      deps: [LoggingConfig],
    },
    metaReducersProvider,
  ],
})
export class NgrxModule {}

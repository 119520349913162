export function applyMinMargin(
  baseValue: number,
  currentValue: number,
  minMargin: number
) {
  if (currentValue - baseValue < minMargin) {
    return baseValue + minMargin;
  }
  return currentValue;
}

export function applyMaxMargin(
  baseValue: number,
  currentValue: number,
  maxMargin: number
) {
  if (currentValue - baseValue > maxMargin) {
    return baseValue + maxMargin;
  }
  return currentValue;
}

import {
  IServerSideGetRowsParams,
  LoadSuccessParams,
} from '@ag-grid-community/core';

export function resetGridOverlays(
  params: IServerSideGetRowsParams,
  successParams: LoadSuccessParams
): void {
  if (successParams.rowCount === 0 && successParams.rowData.length === 0) {
    params.api.showNoRowsOverlay();
  } else {
    params.api.hideOverlay();
  }
}

// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetAllProgramsQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllProgramsQueryQuery = { program: Array<{ id: number, name: string, cms: boolean, agentCode: string, canEditParameters: boolean, canAddRemoveBuckets: boolean }> };

export const GetAllProgramsQueryDocument = gql`
    query GetAllProgramsQuery {
  program {
    id
    name
    agentCode: agent_code
    canEditParameters: can_edit_parameters
    canAddRemoveBuckets: can_add_remove_buckets
    cms
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllProgramsQueryGQL extends Apollo.Query<GetAllProgramsQueryQuery, GetAllProgramsQueryQueryVariables> {
    override document = GetAllProgramsQueryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
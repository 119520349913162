// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SaveLogMessagesMutationVariables = Types.Exact<{
  logs: Array<Types.Audit_Log_Insert_Input> | Types.Audit_Log_Insert_Input;
}>;


export type SaveLogMessagesMutation = { insert_audit_log: { affected_rows: number } | null };

export const SaveLogMessagesDocument = gql`
    mutation SaveLogMessages($logs: [audit_log_insert_input!]!) {
  insert_audit_log(objects: $logs) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveLogMessagesGQL extends Apollo.Mutation<SaveLogMessagesMutation, SaveLogMessagesMutationVariables> {
    override document = SaveLogMessagesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
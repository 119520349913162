export enum MsrpOperator {
  Multiply = 'Multiply',
  AddBeforeMargin = 'AddBeforeMargin',
  MinMargin = 'MinMargin',
  MaxMargin = 'MaxMargin',
  RoundUp = 'RoundUp',
  RoundToNearest = 'RoundToNearest',
  RoundDown = 'RoundDown',
  AddAfterMargin = 'AddAfterMargin',
  Equals = 'Equals',
}

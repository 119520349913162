// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { ParameterKeyValueFieldsFragmentDoc } from './parameter-key-value-fields.fragment.generated';
import { ParameterSubKeyFieldsFragmentDoc } from './parameter-sub-key-fields.fragment.generated';
export type ParameterKeyFieldsFragment = { id: number, key: string, product_id: number, parameter_id: number, sort_order: number, created_at: any, parameter_key_values: Array<{ id: number, parameter_key_id: number, parameter_subtype_id: number, value: string, parameter_subtype: { id: number, parameter_type_id: number, sort_order: number, subtype: string, control_type: string, is_identifier: boolean, visible: boolean, isUnique: boolean } }>, parameter_sub_keys: Array<{ id: number, parent_parameter_key_id: number, child_parameter_key: { id: number, key: string, parameter_id: number, product_id: number, sort_order: number } }> };

export const ParameterKeyFieldsFragmentDoc = gql`
    fragment ParameterKeyFields on parameter_key {
  id
  key
  product_id
  parameter_id
  sort_order
  created_at
  parameter_key_values {
    ...ParameterKeyValueFields
  }
  parameter_sub_keys {
    ...ParameterSubKeyFields
  }
}
    ${ParameterKeyValueFieldsFragmentDoc}
${ParameterSubKeyFieldsFragmentDoc}`;
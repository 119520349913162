// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetOffsetBucketQueryVariables = Types.Exact<{
  bucketId: Types.Scalars['bigint'];
}>;


export type GetOffsetBucketQuery = { bucket_by_pk: { configured_offset: { offset_type: string, bucket: { id: number, name: string } } | null } | null };

export const GetOffsetBucketDocument = gql`
    query GetOffsetBucket($bucketId: bigint!) {
  bucket_by_pk(id: $bucketId) {
    configured_offset {
      bucket {
        id
        name
      }
      offset_type
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOffsetBucketGQL extends Apollo.Query<GetOffsetBucketQuery, GetOffsetBucketQueryVariables> {
    override document = GetOffsetBucketDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
import { StaticProvider } from '@angular/core';
import { LoggingConfig } from '@roadrunner/shared/client-logging';
import { AuthConfig } from '@roadrunner/shared/auth';
import { ReduxDevToolsConfig } from '@roadrunner/shared/util-ngrx';

import { ApiConfig, AppConfig } from './app-config.models';

/**
 * Builds a set of providers needed configure various app-wide services.
 * Must be built as a providers array (as opposed to a static AppConfigModule.forRoot(...))
 * in order for these providers to be visible to the root AppModule.
 */
export function appConfigProviders(appConfig: AppConfig): StaticProvider[] {
  return [
    { provide: AppConfig, useValue: appConfig },
    {
      provide: ApiConfig,
      useValue: appConfig.api,
    },
    { provide: LoggingConfig, useValue: appConfig.logRocket },
    {
      provide: AuthConfig,
      useValue: new AuthConfig(
        appConfig.azure.clientId,
        appConfig.azure.tenantId,
        appConfig.azure.interceptUrls
      ),
    },
    {
      provide: ReduxDevToolsConfig,
      useValue: new ReduxDevToolsConfig(appConfig.enableReduxDevTools),
    },
  ];
}

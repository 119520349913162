<roadrunner-dialog-header [headerText]="headerText"></roadrunner-dialog-header>
<form [formGroup]="form" autocomplete="off" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <mat-form-field class="w-full">
      <mat-label>Program</mat-label>
      <mat-select
        formControlName="programId"
        data-testid="product-program-select"
      >
        <mat-select-trigger>
          {{ programTriggerText }}
        </mat-select-trigger>
        <mat-option
          *ngFor="let program of data.programs"
          [value]="program.id"
          class="multi-line-option"
        >
          <div>{{ program.name }}</div>
          <div>{{ program.agentCode }}</div>
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.hasError('required', 'programId')">
        Program is required
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>Code</mat-label>
      <input
        matInput
        formControlName="code"
        trimWhitespace
        cdkFocusInitial
        autocomplete="off"
      />
      <mat-error *ngIf="form.hasError('required', 'code')">
        Code is required
      </mat-error>
      <mat-error
        *ngIf="
          form.getError('maxlength', 'code') ||
          form.getError('minlength', 'code') as error
        "
      >
        Code must be {{ error.requiredLength }} characters
      </mat-error>
      <mat-error *ngIf="form.hasError('unique', 'code')">
        This code is already in use
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" trimWhitespace />
      <mat-error *ngIf="form.hasError('required', 'name')">
        Name is required
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description"></textarea>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button [matDialogClose]="null" type="button">Cancel</button>
    <rr-submit-button [submittable]="form.valid && form.dirty" color="success">
      Create Product
    </rr-submit-button>
  </mat-dialog-actions>
</form>

<roadrunner-dialog-header
  headerText="Add a new Parameter"
></roadrunner-dialog-header>

<!-- TODO: alert component with encapsulated styles -->
<div class="bg-red-400 font-bold text-white p-3 mb-6 rounded-lg text-xs">
  <p>
    WARNING: Please contact RoadRunner Support before adding new parameters.
  </p>

  <p class="mt-2">
    Parameter additions must be coordinated with RR, dealer portals, menus, &
    integrators.
  </p>
</div>

<form [formGroup]="form" autocomplete="off" (ngSubmit)="onSubmit()">
  <mat-dialog-content cdkFocusInitial>
    <mat-form-field class="w-full">
      <mat-label>Parameter Type</mat-label>
      <mat-select
        formControlName="parameterType"
        required
        (selectionChange)="onParameterTypeChange($event)"
      >
        <mat-select-trigger>
          {{ parameterTypeTriggerText }}
        </mat-select-trigger>
        <mat-option
          *ngFor="let parameterType of parameterTypes"
          [value]="parameterType"
          class="multi-line-option"
        >
          <div>{{ parameterType.type }}</div>
          <div>{{ parameterType.description }}</div>
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.hasError('required', 'parameterType')">
        Parameter type is required
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>Name</mat-label>
      <input formControlName="name" matInput required trimWhitespace />
      <mat-error *ngIf="form.hasError('required', 'name')">
        Name is required
      </mat-error>
      <mat-error *ngIf="form.hasError('unique', 'name')">
        This name is already in use
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>Description</mat-label>
      <input formControlName="description" matInput required trimWhitespace />
      <mat-error *ngIf="form.hasError('required', 'description')">
        Description is required
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button type="button" mat-button [matDialogClose]="null">Cancel</button>
    <button
      type="submit"
      mat-raised-button
      color="primary"
      [disabled]="form.invalid"
    >
      Create Parameter
    </button>
  </mat-dialog-actions>
</form>

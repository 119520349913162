import { Injectable } from '@angular/core';
import { LoggingService } from '@roadrunner/shared/client-logging';
import {
  msrpOperationSaveSuccess,
  msrpParameterAddSuccess,
  msrpParameterDeleteSuccess,
  ratesPublishProductSuccess,
  saveDealerCostRoundingSuccess,
  saveNonSellableCombinationsSuccess,
} from './rate.actions';
import { selectProductLogInfo } from './rate.selectors';

@Injectable()
export class RatingLoggingEffects {
  private createSimpleChangeLogEffect =
    this.loggingService.getSimpleValueChangeEffectCreator(selectProductLogInfo);

  private createLogEffect =
    this.loggingService.getLogEffectCreator(selectProductLogInfo);

  constructor(private loggingService: LoggingService) {}

  logAddMsrpParameter$ = this.createLogEffect(
    'msrpParameterAdded',
    msrpParameterAddSuccess,
    (action) => ({
      parameterId: action.parameterId,
      parameterName: action.parameterName,
    })
  );

  logDeleteMsrpParameter$ = this.createLogEffect(
    'msrpParameterDeleted',
    msrpParameterDeleteSuccess,
    (action) => ({
      parameterId: action.parameterId,
      parameterName: action.parameterName,
    })
  );

  logMsrpOperationChanged$ = this.createSimpleChangeLogEffect(
    'msrpOperationChanged',
    msrpOperationSaveSuccess,
    (a) => a.newOperand,
    (a) => a.oldOperand,
    (a) => ({
      operator: a.operator,
      parameterKeys: a.parameterKeys,
    })
  );

  logDealerCostRounding$ = this.createSimpleChangeLogEffect(
    'dealerCostRoundingChanged',
    saveDealerCostRoundingSuccess,
    (a) => a.newDealerCostRounding,
    (a) => a.oldDealerCostRounding
  );

  logNonSellableCombinations$ = this.createSimpleChangeLogEffect(
    'nonSellableCombinationsChanged',
    saveNonSellableCombinationsSuccess,
    (a) => a.newNonSellableCombinations?.non_sellable_combinations,
    (a) => a.oldNonSellableCombinations?.non_sellable_combinations
  );

  logPublish$ = this.createLogEffect(
    'productRatesPublished',
    ratesPublishProductSuccess
  );
}

import { gql } from "apollo-angular";

export const upsertDealerCostRoundingMutation = gql`
mutation UpsertDealerCostRounding($dealerCostRounding: product_dealer_offset_insert_input!) {
  insert_product_dealer_offset_one(object: $dealerCostRounding, on_conflict: {constraint: product_dealer_rounding_pkey, update_columns: [offset_bucket_id, rounding_type, rounding_value]}) {
    id
    offset_bucket {
      id
      name
    }
    rounding_type
    rounding_value
  }
}
`;
import { formatNumber } from '@angular/common';
import { AbstractControl, ValidationErrors } from '@angular/forms';

export function greaterThanZero(
  control: AbstractControl
): ValidationErrors | null {
  if (control.value == null) {
    return null;
  }
  if (typeof control.value === 'number' && control.value <= 0) {
    return { greaterThanZero: { greaterThanZero: 0, actual: control.value } };
  }
  return null;
}

export function greaterThan(value: number) {
  const valueFormatted = formatNumber(value, 'en-US');
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null) {
      return null;
    }
    if (typeof control.value === 'number' && control.value <= value) {
      return {
        greaterThan: { greaterThan: valueFormatted, actual: control.value },
      };
    }
    return null;
  };
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { OnDestroySubs } from '@rating-utility/shared/components/onDestroySubs';
import { takeUntil } from 'rxjs';
import {
  IParameterKeyValueVM,
  IProductParameterKeyVM,
} from '../../../../../models/view-models/products/product-options.view-model';
import { FormUtility } from '../../../../../shared/utility/form.utility';
import { selectNonCoverageParameters } from '../../../../../store/product/product.selectors';
import { booleanToString } from '../../../../../store/product/to-product-parameter';
import { ProductTypeService } from '../product-type.service';

@Component({
  selector: 'app-parameter-option',
  templateUrl: './parameter-option.component.html',
  styleUrls: ['./parameter-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParameterOptionComponent extends OnDestroySubs implements OnInit {
  @Input() option?: IProductParameterKeyVM;

  @Output() saveClick = new EventEmitter<IProductParameterKeyVM>();
  @Output() cancelClick = new EventEmitter<IProductParameterKeyVM>();

  productParams$ = this.store.select(selectNonCoverageParameters);
  siblingOptions: IProductParameterKeyVM[] = [];

  optionFormGroup: FormGroup<{
    optionName: FormControl<any>;
    optionKeyValues: FormArray<UntypedFormGroup>;
  }> = this.formBuilder(true);

  constructor(
    private productTypeService: ProductTypeService,
    private store: Store
  ) {
    super();
  }

  ngOnInit(): void {
    this.setFormGroup(true);
    this.getSiblingOptions(this.option);
  }

  getSiblingOptions(param: IProductParameterKeyVM | undefined) {
    this.productParams$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((params) => {
        this.siblingOptions =
          params
            ?.filter(
              (parameter) => parameter.parameterId === param?.parameterId
            )
            .map((paramter) => paramter.keys)
            .flat(1)
            .filter(
              (parameter) => parameter.parameterKeyId !== param?.parameterKeyId
            ) ?? [];
        this.setFormGroup(false);
      });
  }

  setFormGroup(setNewFromGroup: boolean) {
    this.optionFormGroup = this.formBuilder(setNewFromGroup);
  }

  formBuilder(setNewFromGroup: boolean): FormGroup {
    const form = new FormGroup({
      optionName: new FormControl(
        setNewFromGroup
          ? this.option?.parameterKey
          : this.optionNameControl.value,
        {
          nonNullable: true,
          validators: [
            Validators.required,
            FormUtility.unique(
              this.siblingOptions.map((op) => op.parameterKey)
            ),
          ],
        }
      ),
      optionKeyValues: new FormArray(
        setNewFromGroup
          ? this.productTypeService.getFormArrayControls(
              this.option,
              this.siblingOptions
            )
          : this.productTypeService.updateValidators(
              this.optionKeyValuesArrayControls,
              this.siblingOptions
            ),
        [Validators.required]
      ),
    });
    return form;
  }

  get optionNameControl() {
    return this.optionFormGroup?.controls.optionName;
  }

  get optionKeyValuesArrayControls() {
    return this.optionFormGroup?.controls.optionKeyValues?.controls;
  }

  formSubmitted() {
    if (this.option && this.optionFormGroup.valid)
      this.saveClick.emit({
        ...this.option,
        // Need to remove ? ''
        parameterKey: this.optionNameControl.value ?? '',
        parameterKeyValues: this.optionKeyValuesArrayControls.map(
          (kvControl): IParameterKeyValueVM => {
            const kv = kvControl.value.keyValueControl as IParameterKeyValueVM;
            let value = kvControl.value.valueControl;
            if (typeof value === 'boolean') {
              value = booleanToString(value);
            }
            return {
              ...kv,
              value,
            };
          }
        ),
      });
  }

  cancelClicked() {
    this.cancelClick.emit(this.option);
  }
}

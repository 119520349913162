import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAProgram } from '../../apollo/queries/programs/all-program-list/all-program-list.interface';
import { IProgramVM } from '../../models/view-models/programs/program.interface';
import { State, userStateFeatureKey } from './user.reducers';

const selectUserState = createFeatureSelector<State>(userStateFeatureKey);

export const selectProgramList = createSelector(
  selectUserState,
  (state: State) =>
    state.programs
      ?.map((program: IAProgram): IProgramVM => {
        return {
          id: program.id,
          name: program.name,
          agentCode: program.agent_code,
          canAddRemoveBuckets: program.can_add_remove_buckets,
          cms: program.cms,
        };
      })
      .sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { numeric: true })
      ) ?? null
);
export const selectChosenProgram = createSelector(
  selectUserState,
  (state: State): IProgramVM | null =>
    state.selectedProgram
      ? {
          id: state.selectedProgram.id,
          name: state.selectedProgram.name,
          agentCode: state.selectedProgram.agent_code,
          canAddRemoveBuckets: state.selectedProgram.can_add_remove_buckets,
          cms: state.selectedProgram.cms,
        }
      : null
);
export const selectChosenProgramId = createSelector(
  selectUserState,
  (state: State): number | null => state.selectedProgram?.id ?? null
);

export const selectChosenProgramName = createSelector(
  selectChosenProgram,
  (program) => program?.name
);
export const selectCanAddRemoveBuckets = createSelector(
  selectChosenProgram,
  (program) => {
    return program != null && program.canAddRemoveBuckets;
  }
);
export const selectChosenProgramIsCms = createSelector(
  selectChosenProgram,
  (program) => program?.cms ?? false
);

export const selectRiskTypes = createSelector(
  selectUserState,
  (state) => state.riskTypes
);

export const selectHasChosenProgram = createSelector(
  selectChosenProgram,
  (p) => p != null
);

<mat-form-field class="w-full">
  <mat-label>Payee/Agent</mat-label>
  <input
    matInput
    [formControl]="payeeControl"
    trimWhitespace
    [matAutocomplete]="payeeAutocomplete"
    #payeeInput
  />
  <mat-autocomplete
    #payeeAutocomplete
    [displayWith]="displayFn"
    [autoActiveFirstOption]="payeeControl.value ? true : false"
  >
    <mat-option
      *ngFor="let payee of filteredPayees$ | async"
      [value]="payee"
      class="multi-line-option"
    >
      <div>{{ payee.company }}</div>
      <div>{{ payee.code }}</div>
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="payeeControl.hasError('required')">
    Payee/Agent is required
  </mat-error>
  <mat-error
    *ngIf="
      !payeeControl.hasError('required') && payeeControl.hasError('noMatch')
    "
  >
    Invalid Payee/Agent
  </mat-error>
</mat-form-field>

import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, map, take, tap } from 'rxjs/operators';
import { IProgramVM } from '../../models/view-models/programs/program.interface';
import * as UserActions from '../../store/user/user.actions';
import { selectProgramList } from '../../store/user/user.selectors';

@Injectable({ providedIn: 'root' })
export class ChooseProgramGuard implements CanActivate {
  constructor(private store: Store) {}

  getFromStoreOrApi() {
    return this.store.pipe(
      select(selectProgramList),
      tap((data: IProgramVM[] | null) => {
        if (!data) {
          this.store.dispatch(UserActions.getProgramList());
        }
      }),
      filter((data: IProgramVM[] | null) => data != null),
      take(1)
    );
  }

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    return this.getFromStoreOrApi().pipe(map(() => true));
  }
}

<roadrunner-dialog-header
  headerText="Add a new Product"
></roadrunner-dialog-header>
<form [formGroup]="form" autocomplete="off" (ngSubmit)="onSubmit()">
  <mat-dialog-content cdkFocusInitial>
    <mat-form-field appearance="outline">
      <mat-label>Product Type</mat-label>
      <mat-select formControlName="productTypeId" required>
        <mat-select-trigger>
          {{ productTypeTriggerText$ | async }}
        </mat-select-trigger>
        <mat-option
          *ngFor="let productType of data.productTypes"
          [value]="productType.id"
          class="multi-line-option"
        >
          <div>{{ productType.description }}</div>
          <div>{{ productType.name }}</div>
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.hasError('required', 'productTypeId')">
        Product Type is required
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input
        matInput
        formControlName="name"
        trimWhitespace
        required
        data-testid="add-product-name-input"
      />
      <mat-error *ngIf="form.hasError('required', 'name')">
        Name is required
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Code</mat-label>
      <input
        matInput
        formControlName="code"
        trimWhitespace
        required
        autocomplete="off"
      />
      <mat-error *ngIf="form.hasError('required', 'code')">
        Code is required
      </mat-error>
      <mat-error
        *ngIf="
          form.getError('maxlength', 'code') ||
          form.getError('minlength', 'code') as error
        "
      >
        Code must be {{ error.requiredLength }} characters
      </mat-error>
      <mat-error *ngIf="form.hasError('unique', 'code')">
        This code is already in use
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description"></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Risk Type</mat-label>
      <mat-select formControlName="riskType" required>
        <mat-select-trigger>
          {{ riskTypeTriggerText$ | async }}
        </mat-select-trigger>
        <mat-option
          *ngFor="let riskType of data.riskTypes"
          [value]="riskType.code"
          class="multi-line-option"
        >
          <div>{{ riskType.name }}</div>
          <div>{{ riskType.code }}</div>
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.hasError('required', 'riskType')">
        Risk Type is required
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button [matDialogClose]="null" type="button">Cancel</button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="form.invalid"
      type="submit"
    >
      Create Product
    </button>
  </mat-dialog-actions>
</form>

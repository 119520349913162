import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { NavItem } from './nav-item';
import { VersionService } from './version.service';

@Component({
  selector: 'rr-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'block min-h-screen z-50 w-64 bg-primary-default',
  },
})
export class SidenavComponent {
  @Input() navItems!: NavItem[];

  version: string;

  constructor(versionService: VersionService) {
    this.version = versionService.version;
  }
}

import { GraphQLError } from 'graphql';
import LogRocket from 'logrocket';

export function captureGraphQLErrors(
  operationName: string,
  errors: ReadonlyArray<GraphQLError>
) {
  errors.forEach((error) => captureGraphQLError(operationName, error));
}

export function captureGraphQLError(
  operationName: string,
  error: GraphQLError
) {
  LogRocket.captureException(error, {
    extra: {
      operationName: operationName,
      message: error.message,
      code: error.extensions?.code,
      path: error.extensions?.path,
    },
  });
}

import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
})
export class NotificationsComponent implements OnInit {
  @Input() notificationPanel!: MatSidenav;

  // Dummy notifications
  notifications = [
    {
      message: 'Updated Product HYVSC',
      icon: 'assignment_ind',
      time: '1 min ago',
      route: '/inbox',
      color: 'primary',
    },
    {
      message: 'Added new bucket: Admin Fee',
      icon: 'chat',
      time: '4 min ago',
      route: '/chat',
      color: 'accent',
    },
    {
      message: 'Deleted bucket: Reserve',
      icon: 'settings_backup_restore',
      time: '12 min ago',
      route: '/charts',
      color: 'warn',
    },
  ];

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((routeChange) => {
      if (routeChange instanceof NavigationEnd) {
        this.notificationPanel.close();
      }
    });
  }

  clearAll(e: MouseEvent) {
    e.preventDefault();
    this.notifications = [];
  }
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ErrorMessage } from '@roadrunner/shared/util-graphql';

@Component({
  selector: 'rr-error-dialog',
  templateUrl: 'error-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ErrorMessage) {}
}

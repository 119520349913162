import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { SaveBucketBundleDiscountsResponse } from './save-bucket-bundle-discounts.interface';
import { IBundledCoverageParameterDiscountVM } from '../../../models/view-models/buckets/bucket.view-model';
import { saveBucketBundleDiscountsMutation } from './save-bucket-bundle-discounts.mutation';

export class SaveBucketBundleDiscountsData {
  static saveBucketBundleDiscounts(
    apollo: Apollo,
    bucketId: number,
    bundleDiscounts: IBundledCoverageParameterDiscountVM[]
  ): Observable<SaveBucketBundleDiscountsResponse> {
    const discounts = bundleDiscounts.map((d) => {
      return {
        bucket_id: bucketId,
        parameter_sub_key_id: d.parameterSubKeyId,
        discount: d.discount,
      };
    });

    return (
      apollo
        .mutate<SaveBucketBundleDiscountsResponse>({
          mutation: saveBucketBundleDiscountsMutation,
          variables: { discounts },
        })
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .pipe(map((res) => res.data!))
    );
  }
}

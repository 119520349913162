import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as ProgramActions from './program.actions';
import { Program } from './program.models';

export const PROGRAM_FEATURE_KEY = 'program';

export interface State extends EntityState<Program> {
  loading: boolean;
}

export interface ProgramPartialState {
  readonly [PROGRAM_FEATURE_KEY]: State;
}

export const programAdapter: EntityAdapter<Program> =
  createEntityAdapter<Program>();

export const initialState: State = programAdapter.getInitialState({
  // set initial required properties
  loading: true,
});

const programReducer = createReducer(
  initialState,
  on(ProgramActions.loadProgramSuccess, (state, { program }) =>
    programAdapter.setAll<State>(program, { ...state, loading: false })
  ),
  on(
    ProgramActions.loadProgramFailure,
    (state): State => ({
      ...state,
      loading: false,
    })
  ),
  on(
    ProgramActions.addProgramSuccess,
    (state, { program }): State =>
      programAdapter.addOne<State>(program, { ...state, loading: false })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return programReducer(state, action);
}

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';

@Component({
  selector: 'app-tab-indicator',
  templateUrl: './tab-indicator.component.html',
  styleUrls: ['./tab-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabIndicatorComponent<T> implements OnChanges {
  @Input() active = false;
  @Input() dirty = false;
  @Input() valid = false;
  @Input() value: T | null = null;

  cssClass = 'bg-gray';

  ngOnChanges(): void {
    this.setCssClass();
  }

  private setCssClass(): void {
    if (this.active) {
      this.cssClass = 'bg-primary-default';
    } else if (this.valid) {
      if (this.dirty) {
        this.cssClass = 'bg-yellow pulse';
      } else {
        this.cssClass = 'bg-success';
      }
    } else {
      this.cssClass = this.value ? 'bg-warn pulse' : 'bg-gray';
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Account, AuthService } from '@roadrunner/shared/auth';
import { LoggingService } from '@roadrunner/shared/client-logging';
import { UserActions } from '@roadrunner/shared/data-access-user';
import { filter, take } from 'rxjs/operators';

export interface IWindowCypress {
  store?: Store;
  Cypress?: unknown;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private loggingService: LoggingService,
    private store: Store
  ) {
    const windowWithStore: IWindowCypress = window as unknown as IWindowCypress;
    if (windowWithStore.Cypress) {
      windowWithStore.store = this.store;
    }
  }

  ngOnInit(): void {
    this.authService.signedInAccount$
      .pipe(
        filter((a): a is Account => !!a),
        take(1)
      )
      .subscribe((account) => {
        this.store.dispatch(
          UserActions.setUserInfo({
            userName: account.name,
            userEmail: account.email,
          })
        );
        this.loggingService.identify(
          account.accountId,
          account.name,
          account.email
        );
      });
  }
}

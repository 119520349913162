import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ParameterListItem } from '@roadrunner/rating-utility/ui-parameter-key-combination-selector';
import { UserSelectors } from '@roadrunner/shared/data-access-user';
import { BucketLogMessage } from '@roadrunner/shared/client-logging';
import { calculateTotalWithRounding } from '@roadrunner/util-math';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { BaseRate } from 'libs/gateway/trpc/src/lib/router/base-rates-router';
import { RateException } from './models/rate-exception';
import { RateSliceRate, RateSliceBundledRate } from './models/rate-slice-rate';
import {
  IBucketVM,
  IBundledCoverageParameterDiscountVM,
  IBundledCoverageParameterVM,
  IBundledCoverageVM,
  IOffsetBucketVM,
  IOffsetConfigurationVM,
  IRoundingConfigurationVM,
} from '../../models/view-models/buckets/bucket.view-model';
import { BundledRate } from '../../models/view-models/buckets/bundled-rate.interface';
import {
  IParameterKeyVM,
  IParameterSubKeyVM,
  IParameterVM,
} from '../../models/view-models/parameters/parameter.view-model';
import { IDealerCostRoundingConfigVM } from '../../models/view-models/products/deal-cost-rounding.view-models';
import {
  RateFactorConverter,
  RateFactorConverters,
} from '../../models/view-models/rate-factors/rate-factor-converters.interface';
import { RateFactorGroup } from '../../models/view-models/rate-factors/rate-factor-group';
import {
  ADD,
  MULTIPLY,
  SUBTRACT,
} from '../../models/view-models/rate-factors/rate-factor-operators';
import { RateFactor } from '../../models/view-models/rate-factors/rate-factor-parameter.view-model';
import { getBundledRateTotal } from '../../pages/bucket/steps/bundle-discounts/get-bundled-rate-total';
import { clamp } from '../../shared/utility/clamp';
import { DRYUtility } from '../../shared/utility/dry.utility';
import { selectChosenProgram } from '../user/user.selectors';
import {
  bucketFeatureKey,
  productParameterAdapter,
  State,
} from './bucket.reducers';
import { getApplicableRateFactorConverters } from './get-applicable-rate-factor-converters';
import { getBucketBaseRate } from './get-bucket-base-rate';

const selectBucketState = createFeatureSelector<State>(bucketFeatureKey);

const selectStateBaseParameters = createSelector(
  selectBucketState,
  (state: State) => {
    return state.baseParameters;
  }
);

export const selectBaseParametersInSortOrder = createSelector(
  selectStateBaseParameters,
  (baseParameters) => {
    return [...baseParameters].sort((a, b) => a.sortOrder - b.sortOrder);
  }
);

const selectBaseParameterIdsInSortOrder = createSelector(
  selectBaseParametersInSortOrder,
  (baseParameters) => {
    return baseParameters.map((bp) => bp.parameterId);
  }
);

export const selectIsSavingBaseParameters = createSelector(
  selectBucketState,
  (state) => state.isSavingBaseParameters
);

export const selectStateRateFactors = createSelector(
  selectBucketState,
  (state: State) => {
    return state.rateFactors;
  }
);

export const selectStateRateSliceFactorGroups = createSelector(
  selectBucketState,
  (state: State) => {
    return state.rateSliceFactorGroups;
  }
);

const selectStateOffsetBucketList = createSelector(
  selectBucketState,
  (state: State) => {
    return state.offsetBucketList;
  }
);

const selectStateProductParameters = createSelector(
  selectBucketState,
  (state: State) => {
    return state.productParameters;
  }
);

const selectStateSiblingRateSlices = createSelector(
  selectBucketState,
  (state: State) => {
    return state.siblingRateSlices;
  }
);

////////////////////////////////////////////////////////// CHOSEN BUCKET /////////////////////////////////////////////////////////////////////////////////
export const selectChosenBucket = createSelector(
  selectBucketState,
  (state: State) => {
    return state.bucket?.bucket_by_pk;
  }
);

const selectStateBucketMax = createSelector(selectChosenBucket, (bucket) => {
  // max currently shown on the UI
  return bucket?.bucket_maximum;
});

const selectStateBucketMin = createSelector(selectChosenBucket, (bucket) => {
  // min currently shown on the UI
  return bucket?.bucket_minimum;
});

export const selectSavedBucketMax = createSelector(
  selectBucketState,
  (state) => {
    // max saved in the DB
    return state.savedMax;
  }
);

export const selectSavedBucketMin = createSelector(
  selectBucketState,
  (state) => {
    // min saved in the DB
    return state.savedMin;
  }
);

export const selectSavedBucketName = createSelector(
  selectBucketState,
  (state) => {
    // name saved in the DB
    return state.savedName;
  }
);

export const selectSavedCmsBucketNumber = createSelector(
  selectBucketState,
  (state) => {
    // CMS bucket # saved in the DB
    return state.savedCmsBucketNumber;
  }
);

export const selectSavedReserves = createSelector(
  selectBucketState,
  (state) => {
    // 'reserves' flag saved in the DB
    return state.savedReserves;
  }
);

export const selectSavedPayeeCode = createSelector(
  selectBucketState,
  (state) => {
    // rate slice payee saved in the DB
    return state.savedPayee;
  }
);

export const selectSavedBucketOffset = createSelector(
  selectBucketState,
  (state) => {
    // saved in the DB
    return {
      offsetType: state.savedOffsetType,
      offsetBucketId: state.savedOffsetBucketId,
    };
  }
);

export const selectSavedBucketRounding = createSelector(
  selectBucketState,
  (state) => {
    // saved in the DB
    return {
      roundingType: state.savedBucketRoundingType,
      roundingValue: state.savedBucketRoundingValue,
    };
  }
);

const selectStateBucketOffset = createSelector(selectChosenBucket, (bucket) => {
  return bucket?.bucket_offset;
});

const selectStateBucketRounding = createSelector(
  selectChosenBucket,
  (bucket) => {
    return bucket?.bucket_rounding;
  }
);

export const selectStateBucketId = createSelector(
  selectChosenBucket,
  (bucket) => {
    return bucket?.id;
  }
);

export const selectChosenBucketName = createSelector(
  selectChosenBucket,
  (bucket) => {
    return bucket?.name;
  }
);

export const selectChosenBucketsProductId = createSelector(
  selectChosenBucket,
  (bucket) => {
    return bucket?.product_id;
  }
);

export const selectChosenBucketsProductName = createSelector(
  selectChosenBucket,
  (bucket) => {
    return bucket?.product?.name;
  }
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////// OFFSET BUCKET /////////////////////////////////////////////////////////////////////////////////
export const selectOffsetBucket = createSelector(
  selectBucketState,
  (state: State): IOffsetBucketVM | null => {
    return state.offsetBucketInfo
      ? {
          ...state.offsetBucketInfo.bucket,
          offsetType: state.offsetBucketInfo.offset_type,
        }
      : null;
  }
);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const {
  selectAll: selectAllProductParameters,
  selectEntities: selectProductParameterEntities,
} = productParameterAdapter.getSelectors(selectStateProductParameters);

export const selectProductParameters = createSelector(
  selectAllProductParameters,
  (parameters) => {
    return parameters.map(
      (param): IParameterVM => ({
        parameterId: param.id,
        description: param.description,
        parameterName: param.name,
        parameterTypeId: param.parameter_type_id,
        bundleable: param.parameter_type.bundleable,
        keys: param.parameter_keys.map(
          (pk): IParameterKeyVM => ({
            parameterId: param.id,
            parameterKey: pk.key,
            parameterKeyId: pk.id,
            productId: pk.product_id,
            sortOrder: pk.sort_order,
            parameterSubKeys: pk.parameter_sub_keys.map(
              (sk): IParameterSubKeyVM => {
                const discounts = sk.parameter_sub_key_bucket_discounts;
                return {
                  id: sk.id,
                  childParameterKeyId: sk.child_parameter_key_id,
                  discount:
                    discounts?.length > 0
                      ? discounts[0].discount // Take the first discount since there should only ever be one
                      : undefined,
                };
              }
            ),
            parameterKeyValues: pk.parameter_key_values,
          })
        ),
      })
    );
  }
);

export const selectNonBundleParameterListItems = createSelector(
  selectAllProductParameters,
  (parameters) => {
    return parameters.map((parameter): ParameterListItem => {
      return {
        id: parameter.id,
        name: parameter.name,
        parameterKeys: parameter.parameter_keys
          .filter(
            (pk) =>
              pk.parameter_sub_keys == null ||
              pk.parameter_sub_keys.length === 0
          )
          .map((pk) => {
            return {
              id: pk.id,
              name: pk.key,
            };
          }),
      };
    });
  }
);

export const selectParameterKeyEntities = createSelector(
  selectProductParameters,
  (parameters): Dictionary<IParameterKeyVM> => {
    return parameters.reduce(
      (parameterKeys: Record<number, IParameterKeyVM>, parameter) => {
        for (const key of parameter.keys) {
          parameterKeys[key.parameterKeyId] = key;
        }
        return parameterKeys;
      },
      {}
    );
  }
);

export const selectCoverageProductParameter = createSelector(
  selectProductParameters,
  (productParams) => productParams?.find((p) => p.bundleable)
);

export const selectBundledCoverageParameterKeyIds = createSelector(
  selectCoverageProductParameter,
  (coverageParameter): Set<number> => {
    if (!coverageParameter) {
      return new Set<number>();
    }
    return new Set<number>(
      coverageParameter.keys.reduce((bundleIds: number[], key) => {
        if (key.parameterSubKeys.length > 0) {
          bundleIds.push(key.parameterKeyId);
        }
        return bundleIds;
      }, [])
    );
  }
);

export const selectBaseParameters = createSelector(
  selectBaseParameterIdsInSortOrder,
  selectProductParameters,
  (baseParameterIds, productParameters) => {
    const parameters: IParameterVM[] = [];
    // Maintain the order of the base parameter ids
    for (const parameterId of baseParameterIds) {
      const parameter = productParameters.find(
        (p) => p.parameterId === parameterId
      );
      if (parameter) {
        parameters.push(parameter);
      }
    }
    return parameters;
  }
);

export const selectBucketRateFactors = createSelector(
  selectStateRateFactors,
  selectParameterKeyEntities,
  selectProductParameterEntities,
  (rateFactors, parameterKeys, parameters) => {
    if (!rateFactors) {
      return [];
    }
    return rateFactors.map((rateFactor): RateFactor => {
      return {
        id: rateFactor.id,
        operand: +rateFactor.operand,
        operator: rateFactor.operator,
        sortOrder: rateFactor.sortOrder,
        parameterKeys: rateFactor.parameterKeyIds.map((pkid) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const parameterKey = parameterKeys[pkid]!;
          return {
            parameterId: parameterKey.parameterId,
            parameterKeyId: parameterKey.parameterKeyId,
            parameterKeyName: parameterKey.parameterKey,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            parameterName: parameters[parameterKey.parameterId]!.name,
          };
        }),
      };
    });
  }
);

export const selectBucketRateSliceFactorGroups = createSelector(
  selectStateRateSliceFactorGroups,
  selectStateBucketId,
  selectParameterKeyEntities,
  selectProductParameterEntities,
  (rateSliceFactorGroups, rateSliceId, parameterKeys, parameters) => {
    if (!rateSliceFactorGroups || !rateSliceId) {
      return [];
    }
    return rateSliceFactorGroups.map(
      (rateSliceFactorGroup): RateFactorGroup => {
        return {
          id: rateSliceFactorGroup.id,
          rateSliceId,
          rateFactorGroupParameters:
            rateSliceFactorGroup.rateSliceFactorGroupParameters.map((gp) => ({
              id: gp.id,
              groupId: rateSliceFactorGroup.id,
              parameterId: gp.parameter.id,
              parameterName: gp.parameter.name,
            })),
          rateFactors: rateSliceFactorGroup.bucketRateFactors.map((brf) => ({
            id: brf.id,
            operand: +brf.operand,
            operator: brf.operator,
            sortOrder: brf.sortOrder,
            parameterKeys: brf.parameterKeyIds.map((pkid) => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const parameterKey = parameterKeys[pkid]!;
              return {
                id: pkid,
                parameterId: parameterKey.parameterId,
                parameterKeyId: parameterKey.parameterKeyId,
                parameterKeyName: parameterKey.parameterKey,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                parameterName: parameters[parameterKey.parameterId]!.name,
              };
            }),
          })),
        };
      }
    );
  }
);

export const selectBucketRounding = createSelector(
  selectStateBucketRounding,
  (rounding): IRoundingConfigurationVM | null => {
    return !DRYUtility.isNullOrUndefined(rounding?.round_by) &&
      rounding?.round_type
      ? {
          roundTo: rounding.round_by,
          roundingType: rounding.round_type,
        }
      : null;
  }
);

export const selectBucketOffset = createSelector(
  selectStateBucketOffset,
  (offset): IOffsetConfigurationVM | null => {
    return offset?.offset_bucket_id &&
      offset.offset_type &&
      offset.offset_bucket?.name
      ? {
          offsetBucketId: offset.offset_bucket_id,
          offsetType: offset.offset_type,
          offsetBucketName: offset.offset_bucket.name,
        }
      : null;
  }
);

export const selectSavedOffsetBucketId = createSelector(
  selectBucketState,
  (state) => state.savedOffsetBucketId
);

export const selectUsesBaseSlices = createSelector(
  selectChosenBucket,
  (bucket) => bucket?.usesBaseSlices ?? false
);
export const selectSavingBaseSlices = createSelector(
  selectBucketState,
  (state) => state.isSavingBaseSlices
);

export const selectBaseSliceIds = createSelector(
  selectChosenBucket,
  (bucket) => {
    const baseSliceIds = bucket?.base_slices.map(
      (baseSlice) => baseSlice.base_slice_id
    );
    if (!baseSliceIds?.length) {
      return null;
    }
    return baseSliceIds;
  }
);

export const selectBaseSliceOptions = createSelector(
  selectStateSiblingRateSlices,
  (rateSlices) => {
    return (
      rateSlices?.map((rs) => {
        return {
          id: rs.id,
          name: rs.name,
          payee: rs.payee.company,
        };
      }) ?? []
    );
  }
);

export const selectAppliedBaseParameters = createSelector(
  selectUsesBaseSlices,
  selectProductParameters,
  selectBaseParameters,
  (usesBaseSlices, allParams, baseParams) => {
    return usesBaseSlices ? allParams : baseParams;
  }
);

export const selectSiblingBuckets = createSelector(
  selectStateSiblingRateSlices,
  (buckets) =>
    buckets?.map(
      (b) =>
        ({
          id: b.id,
          name: b.name,
          payee: b.payee,
          sortOrder: b.sortOrder,
          productId: b.productId,
        } as unknown as IBucketVM)
    ) ?? []
);

export const selectStateFactorParametersForEffects = createSelector(
  selectStateRateFactors,
  (factors) => {
    return factors;
  }
);

export const selectBucket = createSelector(
  selectChosenBucket,
  (bucket): IBucketVM | null => {
    if (!bucket) {
      return null;
    }
    return {
      id: bucket.id,
      name: bucket.name,
      payee: bucket.payee,
      sortOrder: bucket.sort_order,
      productId: bucket.product_id,
      has_saved_rates: false,
      bucket_rates: [],
      product: bucket.product,
    };
  }
);

export const selectAllBucketRateFactors = createSelector(
  selectBucketRateFactors,
  selectBucketRateSliceFactorGroups,
  (rateFactors, rateSliceFactorGroups) => {
    return rateFactors
      .concat(rateSliceFactorGroups.flatMap((rsfg) => rsfg.rateFactors))
      .sort((a, b) => a.sortOrder - b.sortOrder);
  }
);

export const selectRateFactorsParameters = createSelector(
  selectAppliedBaseParameters,
  selectAllBucketRateFactors,
  selectProductParameters,
  (appliedBaseParameters, rateFactors, parameters) => {
    const baseParamIdsLookup = new Set<number>(
      appliedBaseParameters.map((p) => p.parameterId)
    );
    return parameters.filter((p) => {
      return (
        !baseParamIdsLookup.has(p.parameterId) &&
        rateFactors.some((rf) =>
          rf.parameterKeys.some((pk) => pk.parameterId === p.parameterId)
        )
      );
    });
  }
);

export const selectAvailableBaseParameters = createSelector(
  selectProductParameters,
  selectBaseParameters,
  (productParams, baseParams) => {
    return productParams.filter((pp) =>
      baseParams.every((baseParam) => baseParam.parameterId !== pp.parameterId)
    );
  }
);

export const selectUnusedParameters = createSelector(
  selectProductParameters,
  selectAppliedBaseParameters,
  selectRateFactorsParameters,
  (productParams, baseParams, rateFactorsParams) => {
    const usedParams = baseParams.concat(rateFactorsParams);
    return productParams.filter((pp) =>
      usedParams.every((up) => up.parameterId !== pp.parameterId)
    );
  }
);

export const selectUnusedSavedParametersCount = createSelector(
  selectUnusedParameters,
  (unusedParams) => {
    return unusedParams.length;
  }
);

export const selectIsSavingBucketSettings = createSelector(
  selectBucketState,
  (state: State) => {
    return state.isSavingSettings;
  }
);

export const selectIsSavingRateFactors = createSelector(
  selectBucketState,
  (state: State) => {
    return state.isSavingRateFactors;
  }
);

export const selectIsSavingMinMax = createSelector(
  selectBucketState,
  (state: State) => {
    return state.isSavingMinMax;
  }
);

export const selectIsSavingOffsetRounding = createSelector(
  selectBucketState,
  (state: State) => {
    return state.isSavingOffsetRounding;
  }
);

export const selectIsSavingReviewRates = createSelector(
  selectBucketState,
  (state: State) => {
    return state.isSavingReviewRates;
  }
);

export const selectChosenBucketID = createSelector(
  selectStateBucketId,
  (id) => {
    return id;
  }
);

export const selectChosenBucketMinValue = createSelector(
  selectStateBucketMin,
  (min) => {
    return min?.min;
  }
);

export const selectChosenBucketMaxValue = createSelector(
  selectStateBucketMax,
  (max) => {
    return max?.max;
  }
);

export const selectOffsetBucketList = createSelector(
  selectStateOffsetBucketList,
  (res) => {
    return res
      ? res.buckets.map(
          (b) =>
            ({
              id: b.id,
              name: b.name,
              sortOrder: b.sort_order,
            } as IBucketVM)
        )
      : [];
  }
);

export const selectDealerCostRounding = createSelector(
  selectBucket,
  (bucket): IDealerCostRoundingConfigVM | null => {
    if (
      bucket &&
      bucket.product.dealerRounding &&
      bucket.id === bucket.product.dealerRounding.offsetBucketId
    ) {
      return bucket.product.dealerRounding;
    }
    return null;
  }
);

export const selectIsLoadingBucket = createSelector(
  selectBucketState,
  (state) => state.isLoadingBucket
);

export const selectIsSavingBundleDiscounts = createSelector(
  selectBucketState,
  (state) => state.isSavingBundleDiscounts
);

export const selectBundledCoverages = createSelector(
  selectCoverageProductParameter,
  (coverageParam) => {
    if (!coverageParam?.keys) {
      return [];
    }
    const keys = coverageParam.keys.filter(
      (op) => !DRYUtility.isNullOrUndefinedOrEmpty(op.parameterSubKeys)
    );
    return keys.map((op): IBundledCoverageVM => {
      return {
        id: op.parameterKeyId,
        coverageParams: op.parameterSubKeys.map(
          (sk): IBundledCoverageParameterVM => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const coverage = coverageParam.keys.find(
              (key) => key.parameterKeyId === sk.childParameterKeyId
            )!;

            return {
              ...coverage,
              discount: sk.discount,
              parameterSubKeyId: sk.id,
            };
          }
        ),
        name: op.parameterKey,
      };
    });
  }
);

export const selectSavedBucketDiscounts = createSelector(
  selectCoverageProductParameter,
  (coverageParam) => {
    if (!coverageParam?.keys) {
      return [];
    }
    return coverageParam.keys.flatMap((k) => {
      return k.parameterSubKeys.flatMap((sk) => {
        return {
          parameterSubKeyId: sk.id,
          discount: sk.discount,
        } as IBundledCoverageParameterDiscountVM;
      });
    });
  }
);

export const selectProductBuckets = createSelector(
  selectBucket,
  selectSiblingBuckets,
  (bucket, siblingBuckets): IBucketVM[] => {
    if (bucket && siblingBuckets) {
      return siblingBuckets
        .concat(bucket)
        .sort(
          (a, b) => a.sortOrder - b.sortOrder || a.name.localeCompare(b.name)
        );
    }
    return [];
  }
);

export const selectProductTypes = createSelector(selectBucketState, (state) => {
  return state.productTypes;
});

export const selectProducts = createSelector(
  selectProductTypes,
  selectBucket,
  (productTypes, bucket) => {
    return (
      productTypes.find((pt) => pt.id === bucket?.product.productType.id)
        ?.products ?? []
    );
  }
);

export const selectRateFactorConverters = createSelector(
  selectAllBucketRateFactors,
  (rateFactors): RateFactorConverters => {
    const converters = rateFactors.map((rf): RateFactorConverter => {
      let converter: (value: number) => number;
      const operand = rf.operand;
      switch (rf.operator) {
        case MULTIPLY:
          converter = (value) => value * operand;
          break;
        case SUBTRACT:
          converter = (value) => value - operand;
          break;
        case ADD:
          converter = (value) => value + operand;
          break;
        default:
          converter = (value) => value;
          break;
      }

      return {
        sortOrder: rf.sortOrder,
        parameterKeyIds: rf.parameterKeys.map((pk) => pk.parameterKeyId),
        converter,
        rateFactor: rf,
      };
    });
    converters.sort((a, b) => a.sortOrder - b.sortOrder);
    return converters;
  }
);

export const selectGetApplicableRateFactorConverters = createSelector(
  selectRateFactorConverters,
  (rateFactorConverters) => {
    return (data: RateException | BaseRate | RateSliceRate | BundledRate) => {
      return getApplicableRateFactorConverters(rateFactorConverters, data);
    };
  }
);

export const selectGetRateFactorsTotal = createSelector(
  selectGetApplicableRateFactorConverters,
  (getApplicableConverters) => {
    return (data: RateException | BaseRate | RateSliceRate | BundledRate) => {
      const applicableConverters = getApplicableConverters(data);

      if (applicableConverters.length === 0) {
        return getBucketBaseRate(data);
      }

      const base = data.base ?? 0;

      // This assumes that the rateFactorConverters are returned in order by selectRateFactorConverters
      let runningTotal = base;
      applicableConverters.forEach((converter) => {
        runningTotal = converter.converter(runningTotal);
      });
      return runningTotal;
    };
  }
);

export const selectGetExceptionTotal = createSelector(
  selectGetRateFactorsTotal,
  (getRateFactorsTotal) => {
    return (data: RateException | RateSliceRate | BundledRate) => {
      if ((data as RateSliceRate).bundledRates?.length) {
        return null;
      }
      return data.exception ?? getRateFactorsTotal(data);
    };
  }
);

export const selectGetDiscountedTotal = createSelector(
  selectGetExceptionTotal,
  selectBundledCoverages,
  (getExceptionTotal, bundledCoverages) => {
    if (!bundledCoverages || bundledCoverages.length === 0) {
      return getExceptionTotal;
    }

    const allBundles = new Map<
      number,
      Map<number, number | null | undefined>
    >();
    bundledCoverages.forEach((bc) =>
      allBundles.set(
        bc.id,
        new Map<number, number | null | undefined>(
          bc.coverageParams.map((p) => [p.parameterKeyId, p.discount])
        )
      )
    );

    return (data: RateSliceRate): number => {
      if (!data.bundledRates || data.bundledRates.length === 0) {
        return getExceptionTotal(data) as number;
      }

      const bundleParameterKeyIdIndex = data.parameterKeyIds.findIndex((id) =>
        allBundles.has(id)
      );

      //  This should never happen, but we want to handle it if bad data gets imported
      if (bundleParameterKeyIdIndex === -1) {
        return getExceptionTotal(data) as number;
      }

      const bundleParameterKeyId =
        data.parameterKeyIds[bundleParameterKeyIdIndex];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const thisBundleDiscounts = allBundles.get(bundleParameterKeyId)!;

      return data.bundledRates.reduce<number>(
        (sum: number, rate: RateSliceBundledRate) => {
          const discount = thisBundleDiscounts.get(rate.parameterKeyId) ?? 0;
          const discountMultiplier = (100 - discount) / 100;
          const bundledRateValue = getBundledRateTotal(
            data.parameterKeyIds,
            bundleParameterKeyIdIndex,
            rate,
            getExceptionTotal
          );
          return sum + bundledRateValue * discountMultiplier;
        },
        0
      );
    };
  }
);

export const selectGetUndiscountedTotal = createSelector(
  selectGetExceptionTotal,
  selectBundledCoverages,
  (getExceptionTotal, bundledCoverages) => {
    return (data: RateSliceRate): number => {
      if (!data.bundledRates || data.bundledRates.length === 0) {
        return getExceptionTotal(data) as number;
      }

      const allBundleParameterKeyIds = new Set<number>();
      bundledCoverages.forEach((bc) => allBundleParameterKeyIds.add(bc.id));
      const bundleParameterKeyIdIndex = data.parameterKeyIds.findIndex((id) =>
        allBundleParameterKeyIds.has(id)
      );

      //  This should never happen, but we want to handle it if bad data gets imported
      if (bundleParameterKeyIdIndex === -1) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return getExceptionTotal(data)!;
      }

      return data.bundledRates.reduce((sum, rate) => {
        const bundledRateValue = getBundledRateTotal(
          data.parameterKeyIds,
          bundleParameterKeyIdIndex,
          rate,
          getExceptionTotal as (rate: {
            parameterKeyIds: number[];
            base: number | null;
            exception?: number | null;
          }) => number
        );
        return sum + bundledRateValue;
      }, 0);
    };
  }
);

export const selectGetMinMaxTotal = createSelector(
  selectChosenBucketMinValue,
  selectChosenBucketMaxValue,
  selectGetDiscountedTotal,
  (min, max, getDiscountedTotal) => {
    if (min == null && max == null) {
      return getDiscountedTotal;
    }

    return (data: RateSliceRate) => {
      // TODO: remove !
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return clamp(getDiscountedTotal(data)!, min, max);
    };
  }
);

export const selectGetRoundingTotal = createSelector(
  selectBucketRounding,
  selectGetMinMaxTotal,
  (roundingConfig, getMinMaxTotal) => {
    if (!roundingConfig) {
      return getMinMaxTotal;
    }

    return (data: RateSliceRate) =>
      calculateTotalWithRounding(
        // TODO: remove !
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        getMinMaxTotal(data)!,
        roundingConfig.roundingType,
        roundingConfig.roundTo
      );
  }
);

export const selectGetRoundingAmount = createSelector(
  selectBucketRounding,
  selectGetMinMaxTotal,
  selectGetRoundingTotal,
  (roundingConfig, getMinMaxTotal, getRoundingTotal) => {
    if (!roundingConfig) {
      return (_: RateSliceRate) => null;
    }

    return (data: RateSliceRate) =>
      // TODO: remove !s
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      getRoundingTotal(data)! - getMinMaxTotal(data)!;
  }
);

export const selectGetOffsetTotal = createSelector(
  selectGetRoundingTotal,
  (getRoundingTotal) => {
    return (data: RateSliceRate) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return getRoundingTotal(data)! + (data.offset ?? 0);
    };
  }
);

export const selectGetDealerCostBeforeRounding = createSelector(
  selectGetOffsetTotal,
  (getOffsetTotal) => {
    return (data: RateSliceRate) => {
      // TODO: remove this ! and coalesce otherBucketDealerCost to zero on the server
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return data.otherBucketDealerCost! + getOffsetTotal(data);
    };
  }
);

export const selectGetDealerCostAfterRounding = createSelector(
  selectDealerCostRounding,
  selectGetDealerCostBeforeRounding,
  (dealerCostRoundingConfig, getDealerCostBeforeOffset) => {
    return dealerCostRoundingConfig
      ? (data: RateSliceRate) => {
          const originalDealerCost = getDealerCostBeforeOffset(data);
          return calculateTotalWithRounding(
            originalDealerCost,
            dealerCostRoundingConfig.roundingType,
            dealerCostRoundingConfig.roundingValue
          );
        }
      : getDealerCostBeforeOffset;
  }
);

export const selectGetDealerCostOffsetAmount = createSelector(
  selectDealerCostRounding,
  selectGetDealerCostBeforeRounding,
  selectGetDealerCostAfterRounding,
  (
    dealerCostRoundingConfig,
    getDealerCostBeforeRounding,
    getDealerCostAfterRounding
  ) => {
    if (!dealerCostRoundingConfig) {
      return (_data: RateSliceRate) => 0;
    }
    return (data: RateSliceRate) =>
      getDealerCostAfterRounding(data) - getDealerCostBeforeRounding(data);
  }
);

export const selectGetDealerCostOffsetTotal = createSelector(
  selectGetDealerCostOffsetAmount,
  selectGetOffsetTotal,
  (getDealerCostOffsetAmount, getOffsetTotal) => {
    return (data: RateSliceRate) =>
      getOffsetTotal(data) + getDealerCostOffsetAmount(data);
  }
);

export const selectBucketLogInfo = createSelector(
  selectChosenBucketID,
  selectChosenBucketsProductId,
  selectChosenProgram,
  selectChosenBucketName,
  selectChosenBucketsProductName,
  UserSelectors.selectUserName,
  UserSelectors.selectUserEmail,
  (
    bucketId,
    productId,
    program,
    bucketName,
    productName,
    userName,
    userEmail
  ) => {
    return {
      bucket_id: bucketId,
      product_id: productId,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      program_id: program!.id,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      program: program!.name,
      bucket: bucketName,
      product: productName,
      user_name: userName,
      user_email: userEmail,
    } as BucketLogMessage;
  }
);

export class BucketTabNames {
  static SliceSettings = 'slice-settings';
  static BaseRates = 'base-rates';
  static RateFactors = 'rate-factors';
  static Exceptions = 'exceptions';
  static Bundles = 'bundles';
  static MinMax = 'min-max';
  static RoundingOffset = 'rounding-offset';
  static SliceReview = 'slice-review';
}

export const orderedBucketTabNames = [
  BucketTabNames.SliceSettings,
  BucketTabNames.BaseRates,
  BucketTabNames.RateFactors,
  BucketTabNames.Exceptions,
  BucketTabNames.Bundles,
  BucketTabNames.MinMax,
  BucketTabNames.RoundingOffset,
  BucketTabNames.SliceReview,
];

export const selectPayees = createSelector(
  selectBucketState,
  (state) => state.payees
);

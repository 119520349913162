import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { race } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import {
  IParameterKeyValueVM,
  IProductParameterKeyVM,
  IProductParameterVM,
} from '../../../../../models/view-models/products/product-options.view-model';
import { OnDestroySubs } from '../../../../../shared/components/onDestroySubs';
import {
  addParameterCancelled,
  addParameterClicked,
  addParameterSuccess,
} from '../../../../../store/product/product.actions';
import { booleanToString } from '../../../../../store/product/to-product-parameter';
import { ProductTypeService } from '../product-type.service';

@Component({
  selector: 'app-parameter',
  templateUrl: './parameter.component.html',
  styleUrls: ['./parameter.component.scss'],
})
export class ParameterComponent extends OnDestroySubs implements OnInit {
  @ViewChild('parameterSelect', { static: true, read: ElementRef })
  parameterSelectEl!: ElementRef;

  @ViewChild('addParameterButton', { static: true, read: ElementRef })
  addParameterButton!: ElementRef<HTMLButtonElement>;

  private parameterSelectControlName = 'parameterSelect';
  private optionNameControlName = 'optionName';
  private optionKeyValuesControlName = 'optionKeyValues';

  parameterFormGroup!: UntypedFormGroup;
  formSubmitting = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      unusedParams: IProductParameterVM[];
      siblingOptionsProductLevel: IProductParameterKeyVM[];
      disableProgramParameterIds: number[];
    },
    private dialogRef: MatDialogRef<
      ParameterComponent,
      {
        option: Partial<IProductParameterKeyVM>;
      }
    >,
    private store: Store,
    private actions: Actions,
    private productTypeService: ProductTypeService
  ) {
    super();
  }

  ngOnInit(): void {
    this.parameterFormGroup = new UntypedFormGroup({
      [this.parameterSelectControlName]: new UntypedFormControl(null, [
        Validators.required,
      ]),
      [this.optionNameControlName]: new UntypedFormControl(null, [
        Validators.required,
      ]),
      [this.optionKeyValuesControlName]: new UntypedFormArray(
        [],
        [Validators.required]
      ),
    });

    if (this.data.unusedParams.length === 0) {
      this.parameterSelectControl.disable();
    }

    this.parameterSelectControl.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((param) => {
        if (param) {
          this.addOptionControls(param);
        }
      });
  }

  get parameterSelectControl(): UntypedFormControl {
    return this.parameterFormGroup.get(
      this.parameterSelectControlName
    ) as UntypedFormControl;
  }

  get optionNameControl(): UntypedFormControl {
    return this.parameterFormGroup.get(
      this.optionNameControlName
    ) as UntypedFormControl;
  }

  get optionKeyValuesArrayControls(): UntypedFormGroup[] {
    return (
      this.parameterFormGroup.get(
        this.optionKeyValuesControlName
      ) as UntypedFormArray
    ).controls as UntypedFormGroup[];
  }

  formSubmitted() {
    const selectedParam = this.parameterSelectControl
      .value as IProductParameterVM;

    this.dialogRef.close({
      option: {
        parameterId: selectedParam.parameterId,
        parameterKey: this.optionNameControl.value,
        parameterKeyValues: this.optionKeyValuesArrayControls.map(
          (kvControl): IParameterKeyValueVM => {
            let value = kvControl.value.valueControl;
            if (typeof value === 'boolean') {
              value = booleanToString(value);
            }
            return {
              ...kvControl.value.keyValueControl,
              value,
            };
          }
        ),
      },
    });
  }

  addOptionControls(param: IProductParameterVM) {
    const blankOption = {
      parameterKeyValues: param.parameterType.parameterSubTypes.map(
        (sub) =>
          ({
            value: null as unknown as string,
            parameterSubType: sub,
          } as IParameterKeyValueVM)
      ),
    } as IProductParameterKeyVM;

    this.parameterFormGroup.setControl(
      this.optionNameControlName,
      new UntypedFormControl(null, [Validators.required])
    );
    this.parameterFormGroup.setControl(
      this.optionKeyValuesControlName,
      new UntypedFormArray(
        this.productTypeService.getFormArrayControls(
          blankOption,
          this.data.siblingOptionsProductLevel
        ),
        [Validators.required]
      )
    );
  }

  onAddParameterClick(): void {
    race([
      this.actions.pipe(
        ofType(addParameterCancelled),
        map(() => null)
      ),
      this.actions.pipe(
        ofType(addParameterSuccess),
        map((action): IProductParameterVM => {
          return {
            description: null as unknown as string,
            keys: null as unknown as IProductParameterKeyVM[],
            parameterId: action.id,
            parameterName: action.name,
            parameterType: {
              parameterTypeID: action.parameterTypeId,
              parameterSubTypes: action.parameterSubTypes.map((subtype) => {
                return {
                  parameterSubTypeID: subtype.id,
                  parameterTypeID: action.parameterTypeId,
                  subType: subtype.subtype,
                  controlType: subtype.controlType,
                  visible: subtype.visible,
                  sortOrder: subtype.sortOrder,
                  isIdentifier: subtype.isIdentifier,
                  isUnique: subtype.isUnique,
                  isGlobalUnique: subtype.isGlobalUnique,
                  options: subtype.parameterSubtypeOptions,
                };
              }),
              type: null as unknown as string,
              description: null as unknown as string,
              bundleable: null as unknown as boolean,
            },
          };
        })
      ),
    ])
      .pipe(take(1), takeUntil(this.componentDestroyed$))
      .subscribe((parameter) => {
        if (parameter) {
          this.data = {
            ...this.data,
            unusedParams: this.data.unusedParams.concat(parameter),
          };
          this.parameterSelectControl.enable();
          this.parameterSelectControl.setValue(parameter);
          this.parameterSelectControl.markAsTouched();
          this.parameterSelectControl.markAsDirty();
        }

        // If we have at least one parameter to select, focus the parameter control.
        if (parameter || this.data.unusedParams.length > 0) {
          this.parameterSelectEl.nativeElement.focus();
        } else {
          // Otherwise the parameter control is disabled so we can't focus it.
          this.addParameterButton.nativeElement.focus();
        }
      });

    this.store.dispatch(addParameterClicked());
  }
}

// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetParametersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetParametersQuery = { parameter: Array<{ id: number, description: string, name: string, parameter_type_id: number }> };

export const GetParametersDocument = gql`
    query GetParameters {
  parameter {
    id
    description
    name
    parameter_type_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetParametersGQL extends Apollo.Query<GetParametersQuery, GetParametersQueryVariables> {
    override document = GetParametersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
import { ActionReducer } from '@ngrx/store';
// import LogRocket from 'logrocket';           Fix Me -- Why does this import fail for tests in dcu libs?
import * as LogRocket from 'logrocket';

const reduxMiddleware = LogRocket.reduxMiddleware();
reduxMiddleware;

export function logrocketMiddleware(
  reducer: ActionReducer<unknown>
): ActionReducer<unknown> {
  let currentState: unknown;

  const fakeDispatch = reduxMiddleware({
    getState: () => currentState,
  })(() => {
    // noop
  });

  return function (state, action) {
    const newState = reducer(state, action);
    currentState = state;
    fakeDispatch(action);
    return newState;
  };
}

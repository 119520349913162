import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { SharedUiDialogModule } from '@roadrunner/shared/ui-dialog';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { UnsavedChangesDialogComponent } from './unsaved-changes-dialog/unsaved-changes-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    SharedUiDialogModule,
  ],
  declarations: [ConfirmationDialogComponent, UnsavedChangesDialogComponent],
  exports: [ConfirmationDialogComponent, UnsavedChangesDialogComponent],
})
export class SharedUtilModalModule {}

// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProductTypesQueryVariables = Types.Exact<{
  programId: Types.Scalars['bigint'];
}>;


export type ProductTypesQuery = { product_type: Array<{ id: number, type: string, description: string | null, products: Array<{ id: number, code: string, name: string }> }> };

export const ProductTypesDocument = gql`
    query ProductTypes($programId: bigint!) {
  product_type(order_by: {type: asc}) {
    id
    type
    description
    products(order_by: {code: asc}, where: {program_id: {_eq: $programId}}) {
      id
      code
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductTypesGQL extends Apollo.Query<ProductTypesQuery, ProductTypesQueryVariables> {
    override document = ProductTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import {
  BaseRateResponse,
  UpsertBucketBaseRatesResponse,
} from './upsert-bucket-base-rates.interface';
import {
  UpsertBucketBaseRate,
  UpsertBucketBaseRatesVariable,
} from './upsert-bucket-base-rates.variable';
import { upsertBucketBaseRatesMutation } from './upsert-bucket-base-rates.mutation';

export class UpsertBucketBaseRatesData {
  static upsertBucketBaseRates(
    apollo: Apollo,
    bucketBaseRates: UpsertBucketBaseRate[]
  ): Observable<BaseRateResponse[]> {
    return (
      apollo
        .mutate<UpsertBucketBaseRatesResponse, UpsertBucketBaseRatesVariable>({
          mutation: upsertBucketBaseRatesMutation,
          variables: {
            bucketBaseRates,
          },
        })
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .pipe(map((res) => res.data!.insert_bucket_base_rate.returning))
    );
  }
}

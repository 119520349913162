import { RatingUtilityEnvironment } from './environment.interface';

export const environment: RatingUtilityEnvironment = {
  production: true,
  requireAuth: true,
  azureClientId: '89da5b5f-4d42-4823-8ae0-cb119a90eef8',
  azureTenantId: 'b45b2e14-b22d-4196-b48c-b1b5506c874a',
  enableNgRxMiddleware: true,
  enableReduxDevTools: true,
  rateLookupUrl: 'https://roadrunner-rate-lookup-uat.herokuapp.com/',
  logRocketAppId: 'npobc9/roadrunner-ui',
  enableLicensing: true,
  enableFileImportExportMenuItem: true,
  enableFileImportTab: true,
};

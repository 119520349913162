<roadrunner-dialog-header
  headerText="Switch to a different Program"
  [matDialogCloseValue]="false"
></roadrunner-dialog-header>
<div mat-dialog-content>
  <p>
    Are you sure you want to change to a different program? Any unsaved data
    will be lost.
  </p>
</div>
<div mat-dialog-actions align="end">
  <button mat-button [matDialogClose]="false">Cancel</button>
  <button
    mat-flat-button
    color="primary"
    [matDialogClose]="true"
    class="ml-2"
    cdkFocusInitial
  >
    Change Program
  </button>
</div>

import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { IGetDealerCostRoundingBucketOptionsResponse } from '../../apollo/queries/get-dealer-cost-rounding-bucket-options/get-dealer-cost-rounding-bucket-options.interface';
import { DealerRoundingResponse } from '../../apollo/queries/get-dealer-cost-rounding/get-dealer-cost-rounding.interface';
import { NonSellableCombinationsViewModel } from '../../apollo/queries/get-non-sellable-combinations/get-non-sellable-combinations.interface';
import { GetParametersResponse } from '../../apollo/queries/get-parameters/get-parameters.interface';
import { ParameterKey } from '../../apollo/queries/get-product-parameter-keys-by-product-id/get-product-parameter-keys-by-product-id.interface';
import { IABucket } from '../../apollo/queries/products/bucket-list-by-product/bucket-list-by-product.interface';
import { IAProductType } from '../../apollo/queries/products/product-types/product-types.interface';
import { Payee } from '../../models/view-models/products/payee.interface';
import { IProductParameterVM } from '../../models/view-models/products/product-options.view-model';
import { chooseProgram } from '../user/user.actions';
import * as RateActions from './rate.actions';
import { RateReducersMapping } from './rate.reducers.mapping';

export const rateFeatureKey = 'rateState';

export const parameterKeyAdapter = createEntityAdapter<ParameterKey>({
  sortComparer: (a, b) => {
    return a.sort_order - b.sort_order;
  },
});

export interface State {
  productTypeList: IAProductType[] | null;
  bucketList: IABucket[];
  nonSellableCombinations: NonSellableCombinationsViewModel | null;
  isPublishingProductRates: boolean;
  isSavingDealerCostRounding: boolean;
  isSavingNonSellableCombinations: boolean;
  isLoadingProductDetail: boolean;
  isAddingBucket: boolean;
  showPreviewProductRatesModal: boolean;
  showCreateBucketModal: boolean;
  dealerCostRounding: DealerRoundingResponse | null;
  dealerCostOffsetBucketOptions: IGetDealerCostRoundingBucketOptionsResponse | null;
  productParameterKeys: EntityState<ParameterKey>;
  programParameters: GetParametersResponse | null;
  productParameters: IProductParameterVM[] | null;
  msrpParameterIds: number[];
  savedMsrpParameterIds: number[];
  isSavingMsrpParameters: boolean;
  isExportingProductReview: boolean;
  payees: Payee[];
}

export const initialState: State = {
  productTypeList: null,
  bucketList: [],
  nonSellableCombinations: null,
  isPublishingProductRates: false,
  isSavingDealerCostRounding: false,
  isSavingNonSellableCombinations: false,
  isLoadingProductDetail: false,
  isAddingBucket: false,
  showPreviewProductRatesModal: false,
  showCreateBucketModal: false,
  dealerCostRounding: null,
  dealerCostOffsetBucketOptions: null,
  productParameterKeys: parameterKeyAdapter.getInitialState(),
  programParameters: null,
  productParameters: null,
  msrpParameterIds: [],
  savedMsrpParameterIds: [],
  isSavingMsrpParameters: false,
  isExportingProductReview: false,
  payees: [],
};

export const rateReducer = createReducer(
  initialState,
  on(RateActions.getProductTypeList, chooseProgram, (state) => {
    return { ...state, productTypeList: initialState.productTypeList };
  }),
  on(RateActions.getProductTypeListSuccess, (state, action) => {
    return { ...state, productTypeList: action.productTypes };
  }),
  on(RateActions.saveDealerCostRounding, (state) => {
    return { ...state, isSavingDealerCostRounding: true };
  }),
  on(RateActions.saveDealerCostRoundingSuccess, (state, action) => {
    return {
      ...state,
      dealerCostRounding: action.newDealerCostRounding,
      isSavingDealerCostRounding: false,
    };
  }),
  on(RateActions.ratesPublishProduct, (state) => {
    return {
      ...state,
      isPublishingProductRates: true,
    };
  }),
  on(
    RateActions.ratesPublishProductSuccess,
    RateActions.ratesPublishProductCancelled,
    RateActions.ratesPublishProductFailure,
    (state) => {
      return {
        ...state,
        isPublishingProductRates: false,
      };
    }
  ),
  on(RateActions.saveNonSellableCombinations, (state) => {
    return {
      ...state,
      isSavingNonSellableCombinations: true,
    };
  }),
  on(RateActions.saveNonSellableCombinationsSuccess, (state, action): State => {
    return {
      ...state,
      nonSellableCombinations: action.newNonSellableCombinations,
      isSavingNonSellableCombinations: false,
    };
  }),
  on(RateActions.saveNonSellableCombinationsFailure, (state) => {
    return {
      ...state,
      isSavingNonSellableCombinations: false,
    };
  }),
  on(RateActions.loadProductDetail, (state) => {
    return {
      ...state,
      isLoadingProductDetail: true,
      isPublishingProductRates: false,
      dealerCostOffsetBucketOptions: initialState.dealerCostOffsetBucketOptions,
      dealerCostRounding: initialState.dealerCostRounding,
      nonSellableCombinations: initialState.nonSellableCombinations,
      bucketList: initialState.bucketList,
      programParameters: initialState.programParameters,
      productParameterKeys: initialState.productParameterKeys,
      productParameters: initialState.productParameters,
    };
  }),
  on(RateActions.loadProductDetailSuccess, (state, action) => {
    if (!action.parameterKeys.product_by_pk) {
      return state;
    }

    const productParameterKeys = parameterKeyAdapter.setAll(
      action.parameterKeys.product_by_pk.parameter_keys,
      state.productParameterKeys
    );

    const productParameters = RateReducersMapping.mapProductParameters(
      action.parameterKeys,
      action.parameters
    );

    const msrpParameterIds =
      action.parameterKeys.product_by_pk.msrp_parameters.map(
        (p) => p.parameter_id
      );

    return {
      ...state,
      isLoadingProductDetail: false,
      dealerCostOffsetBucketOptions: action.dcrBucketOptions,
      dealerCostRounding: action.dealerCostRounding,
      nonSellableCombinations: action.nonSellableCombinations,
      savedNonSellableCombinations: action.nonSellableCombinations,
      bucketList: action.buckets.bucket,
      programParameters: action.parameters,
      productParameterKeys,
      productParameters,
      msrpParameterIds,
      savedMsrpParameterIds: msrpParameterIds,
      payees: action.payees,
    };
  }),
  on(RateActions.createBucket, (state) => {
    return {
      ...state,
      isAddingBucket: true,
    };
  }),
  on(RateActions.createBucketFailure, (state) => {
    return {
      ...state,
      isAddingBucket: false,
    };
  }),
  on(RateActions.createBucketSuccess, (state, action) => {
    return {
      ...state,
      bucketList: state.bucketList.concat(action.bucket),
      isAddingBucket: false,
    };
  }),
  on(RateActions.reviewExportClicked, (state) => {
    return {
      ...state,
      isExportingProductReview: true,
    };
  }),
  on(
    RateActions.exportProductReviewSuccess,
    RateActions.exportProductReviewFailure,
    (state) => {
      return {
        ...state,
        isExportingProductReview: false,
      };
    }
  ),
  on(RateActions.msrpParameterAdded, (state, action) => {
    return {
      ...state,
      msrpParameterIds: state.msrpParameterIds.concat(action.parameterId),
      isSavingMsrpParameters: true,
    };
  }),
  on(RateActions.msrpParameterRemoved, (state, action) => {
    return {
      ...state,
      msrpParameterIds: state.msrpParameterIds.filter(
        (id) => id !== action.parameterId
      ),
      isSavingMsrpParameters: true,
    };
  }),
  on(
    RateActions.msrpParameterDeleteFailure,
    RateActions.msrpParameterAddFailure,
    (state) => {
      return {
        ...state,
        isSavingMsrpParameters: false,
      };
    }
  ),
  on(RateActions.msrpParameterDeleteSuccess, (state, action) => {
    return {
      ...state,
      isSavingMsrpParameters: false,
      savedMsrpParameterIds: state.savedMsrpParameterIds.filter(
        (id) => id !== action.parameterId
      ),
    };
  }),
  on(RateActions.msrpParameterAddSuccess, (state, action) => {
    return {
      ...state,
      isSavingMsrpParameters: false,
      savedMsrpParameterIds: state.savedMsrpParameterIds.concat([
        action.parameterId,
      ]),
    };
  }),
  on(RateActions.addPayeeSuccess, (state, action) => {
    const payees = state.payees.concat(action.payee);
    payees.sort((a, b) => {
      return a.company.localeCompare(b.company) || a.code.localeCompare(b.code);
    });
    return {
      ...state,
      payees,
    };
  }),
  on(RateActions.addProductSuccess, (state, action) => {
    const productTypeList = state.productTypeList?.slice() ?? [];
    const index = productTypeList.findIndex(
      (pt) => pt.id === action.productTypeId
    );
    if (index >= 0) {
      productTypeList[index] = {
        ...productTypeList[index],
        products: productTypeList[index].products.concat({
          id: action.id,
          code: action.code,
          name: action.name,
        }),
      };
    }

    return {
      ...state,
      productTypeList,
    };
  })
);

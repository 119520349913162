import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  UrlTree,
  Router,
  CanActivate,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectChosenProgram } from '../../store/user/user.selectors';

@Injectable({ providedIn: 'root' })
export class SelectedProgramGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean | UrlTree> {
    if (!route.data.requiresProgram) {
      return true;
    }
    return this.hasSelectedProgram().pipe(
      map((b) => (b ? b : this.getChooseProgramURLTree(state)))
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean | UrlTree> {
    if (!childRoute.data.requiresProgram) {
      return true;
    }
    return this.hasSelectedProgram().pipe(
      map((b) => (b ? b : this.getChooseProgramURLTree(state)))
    );
  }

  private hasSelectedProgram(): Observable<boolean> {
    return this.store.pipe(
      select(selectChosenProgram),
      map((p) => !!p)
    );
  }

  private getChooseProgramURLTree(snapshot: RouterStateSnapshot): UrlTree {
    const redirectUrl = snapshot.url.includes('rates')
      ? '/rating/rates'
      : '/rating/products';
    return this.router.createUrlTree(['rating', 'choose-program'], {
      queryParams: { redirectUrl },
    });
  }
}

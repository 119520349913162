import { Component, OnDestroy } from "@angular/core";
import { Subject, Subscription } from "rxjs";

@Component({
    selector: 'app-on-destroy-subs-do-not-use-this',
    template: '<div></div>'
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class OnDestroySubs implements OnDestroy {

    private componentDestroyedSubject = new Subject<boolean>();
    protected componentDestroyed$ = this.componentDestroyedSubject.asObservable();
    protected destroySubs: Subscription[] = [];

    ngOnDestroy() {
        this.destroySubs.forEach(sub => sub.unsubscribe());
        this.componentDestroyedSubject.next(true);
        this.componentDestroyedSubject.complete();
    }
}
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ModalService } from '@roadrunner/shared/util-modal';
import { map, Observable, take } from 'rxjs';
import { FormComponent, isFormComponent } from './form-component';

@Injectable({
  providedIn: 'root',
})
export class FormUnsavedChangesGuard implements CanDeactivate<FormComponent> {
  constructor(private modalService: ModalService) {}

  canDeactivate(component: FormComponent): Observable<boolean> | boolean {
    if (!isFormComponent(component)) {
      console.error(
        'Component supplied either does not implement the FormComponent interface or the form is undefined.',
        component
      );
    }

    if (component?.form?.dirty) {
      return this.modalService.unsavedChanges().pipe(
        take(1),
        map((value) => {
          return value;
        })
      );
    }

    return true;
  }
}

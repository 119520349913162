import { pipe, exhaustMap, map, filter, Observable, UnaryFunction } from 'rxjs';

export function filterUnconfirmed<T, D>(
  confirm: (data: D) => Observable<boolean>,
  data: D
): UnaryFunction<Observable<T>, Observable<T>> {
  return pipe(
    exhaustMap((source: T) => {
      return confirm(data).pipe(
        map((confirmed) => [source, confirmed] as const)
      );
    }),
    filter(([_source, confirmed]) => confirmed),
    map(([source, _confirmed]) => source)
  );
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GraphqlErrorMessage } from '@roadrunner/shared/util-graphql';

@Component({
  selector: 'rr-graphql-error-dialog',
  templateUrl: 'graphql-error-dialog.component.html',
  styleUrls: ['graphql-error-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphqlErrorDialogComponent {
  headerText = 'Something Went Wrong';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { errors: GraphqlErrorMessage[] }
  ) {
    if (data.errors.length === 1 && data.errors[0].title) {
      this.headerText = data.errors[0].title;
    }
  }
}

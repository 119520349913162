// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SwitchToBaseRatesMutationVariables = Types.Exact<{
  rateSliceId: Types.Scalars['bigint'];
}>;


export type SwitchToBaseRatesMutation = { update_bucket_by_pk: { uses_base_slices: boolean } | null, delete_rate_slice_base_slice: { affected_rows: number } | null };

export const SwitchToBaseRatesDocument = gql`
    mutation SwitchToBaseRates($rateSliceId: bigint!) {
  update_bucket_by_pk(
    _set: {uses_base_slices: false}
    pk_columns: {id: $rateSliceId}
  ) {
    uses_base_slices
  }
  delete_rate_slice_base_slice(where: {rate_slice_id: {_eq: $rateSliceId}}) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SwitchToBaseRatesGQL extends Apollo.Mutation<SwitchToBaseRatesMutation, SwitchToBaseRatesMutationVariables> {
    override document = SwitchToBaseRatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
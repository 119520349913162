<roadrunner-dialog-header
  [headerText]="error.shape?.title ?? 'API Error'"
></roadrunner-dialog-header>
<mat-dialog-content>
  <div class="error">
    <p class="whitespace-pre mb-8" [innerHtml]="error.shape?.userMessage"></p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [matDialogClose]="null" class="ml-auto" cdkFocusInitial>
    Close
  </button>
</mat-dialog-actions>

<roadrunner-dialog-header
  headerText="Are you sure you want to leave this page?"
></roadrunner-dialog-header>
<mat-dialog-content>
  <p>You have unsaved changes that will be lost if you leave this page.</p>
  <p>Are you sure you want to leave this page?</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button cdkFocusInitial type="button" mat-button [matDialogClose]="false">
    Stay
  </button>
  <button type="button" mat-raised-button color="warn" [matDialogClose]="true">
    Leave
  </button>
</mat-dialog-actions>

import {
  CoverageKeyValue,
  IBundledCoverageVM,
  IStandaloneCoverageVM,
} from '../../models/view-models/products/coverage.view-model';
import {
  IProductParameterKeyVM,
  IProductParameterVM,
} from '../../models/view-models/products/product-options.view-model';
import {
  ParameterFieldsFragment,
  ParameterSubtypeFieldsFragment,
  ProductFieldsFragment,
} from '../../apollo/queries/get-product/get-product.query.generated';
import { ParameterKeyFieldsFragment } from '../../apollo/fragments/parameter-key-fields.fragment.generated';
import { ParameterSubKeyFieldsFragment } from '../../apollo/fragments/parameter-sub-key-fields.fragment.generated';
import { ParameterKeyValueFieldsFragment } from '../../apollo/fragments/parameter-key-value-fields.fragment.generated';
import { InsertParameterKeysReturningFieldsFragment } from '../../apollo/mutations/upsert-product-parameter-keys/upsert-product-parameter-keys.mutation.generated';

export class ProductReducersMapping {
  static updateParameterKeyIds(
    product: ProductFieldsFragment,
    upserts: InsertParameterKeysReturningFieldsFragment[]
  ): ProductFieldsFragment {
    return {
      ...product,
      parameter_keys: product.parameter_keys.map((pk) => {
        const upsertedKey = upserts.find(
          (i) => i.key === pk.key && i.parameterId === pk.parameter_id
        );
        if (upsertedKey) {
          return {
            ...pk,
            id: upsertedKey.id,
          };
        }
        return pk;
      }),
    };
  }
  static mapNewOptionToProductState(
    product: ProductFieldsFragment,
    option: IProductParameterKeyVM
  ): ProductFieldsFragment {
    const paramKey = this.toParameterKey(option);
    return this.addParameterKey(product, paramKey);
  }

  static mapUpdatedOptionToProductState(
    product: ProductFieldsFragment,
    updatedOption: IProductParameterKeyVM,
    originalOption: IProductParameterKeyVM
  ): ProductFieldsFragment {
    const updatedParamKey = this.toParameterKey(updatedOption);
    const originalParamKey = this.toParameterKey(originalOption);
    return this.updateParameterKey(product, updatedParamKey, originalParamKey);
  }

  static mapDeletedOptionToProductState(
    product: ProductFieldsFragment,
    option: IProductParameterKeyVM
  ): ProductFieldsFragment {
    const paramKey = this.toParameterKey(option);
    return this.removeParameterKey(product, paramKey);
  }

  static mapNewStandaloneCoverageToProductState(
    product: ProductFieldsFragment,
    coverage: IStandaloneCoverageVM,
    coverageParam: IProductParameterVM
  ): ProductFieldsFragment {
    const paramKey = this.toCoverageParameterKey(
      product,
      coverage.id,
      coverage.parameterKeyValues,
      coverageParam
    );
    return this.addParameterKey(product, paramKey);
  }

  static mapRemoveStandaloneCoverageToProductState(
    product: ProductFieldsFragment,
    coverage: IStandaloneCoverageVM,
    coverageParam: IProductParameterVM
  ): ProductFieldsFragment {
    const paramKey = this.toCoverageParameterKey(
      product,
      coverage.id,
      coverage.parameterKeyValues,
      coverageParam
    );
    return this.removeParameterKey(product, paramKey);
  }

  static mapUpdateStandaloneCoverageToProductState(
    product: ProductFieldsFragment,
    updatedCoverage: IStandaloneCoverageVM,
    coverageParam: IProductParameterVM,
    originalCoverage: IStandaloneCoverageVM
  ): ProductFieldsFragment {
    const updatedParamKey = this.toCoverageParameterKey(
      product,
      updatedCoverage.id,
      updatedCoverage.parameterKeyValues,
      coverageParam
    );
    const originalParamKey = this.toCoverageParameterKey(
      product,
      originalCoverage.id,
      originalCoverage.parameterKeyValues,
      coverageParam
    );
    return this.updateParameterKey(product, updatedParamKey, originalParamKey);
  }

  static mapNewBundledCoverageToProductState(
    product: ProductFieldsFragment,
    coverage: IBundledCoverageVM,
    coverageParam: IProductParameterVM
  ): ProductFieldsFragment {
    const paramKey = this.toCoverageParameterKey(
      product,
      coverage.id,
      coverage.parameterKeyValues,
      coverageParam
    );
    paramKey.parameter_sub_keys = this.getParameterSubKeys(
      coverage.coverageIds,
      coverageParam.keys
    );
    return this.addParameterKey(product, paramKey);
  }

  static mapRemoveBundledCoverageToProductState(
    product: ProductFieldsFragment,
    coverage: IBundledCoverageVM,
    coverageParam: IProductParameterVM
  ): ProductFieldsFragment {
    const paramKey = this.toCoverageParameterKey(
      product,
      coverage.id,
      coverage.parameterKeyValues,
      coverageParam
    );
    return this.removeParameterKey(product, paramKey);
  }

  static mapUpdateBundledCoverageToProductState(
    product: ProductFieldsFragment,
    updatedCoverage: IBundledCoverageVM,
    coverageParam: IProductParameterVM,
    originalCoverage: IBundledCoverageVM
  ): ProductFieldsFragment {
    const updatedParamKey = this.toCoverageParameterKey(
      product,
      updatedCoverage.id,
      updatedCoverage.parameterKeyValues,
      coverageParam
    );
    updatedParamKey.parameter_sub_keys = this.getParameterSubKeys(
      updatedCoverage.coverageIds,
      coverageParam.keys
    );
    const originalParamKey = this.toCoverageParameterKey(
      product,
      originalCoverage.id,
      originalCoverage.parameterKeyValues,
      coverageParam
    );
    originalParamKey.parameter_sub_keys = this.getParameterSubKeys(
      originalCoverage.coverageIds,
      coverageParam.keys
    );
    return this.updateParameterKey(product, updatedParamKey, originalParamKey);
  }

  static mapParameterViewModelToResponseModel(
    coverageParam: IProductParameterVM
  ): ParameterFieldsFragment {
    return {
      id: coverageParam.parameterId,
      description: coverageParam.description,
      name: coverageParam.parameterName,
      parameter_type: {
        id: coverageParam.parameterType.parameterTypeID,
        bundleable: coverageParam.parameterType.bundleable,
        description: coverageParam.parameterType.description,
        type: coverageParam.parameterType.type,
        parameter_subtypes: coverageParam.parameterType.parameterSubTypes.map(
          (pst): ParameterSubtypeFieldsFragment => {
            return {
              id: pst.parameterSubTypeID,
              control_type: pst.controlType,
              is_identifier: pst.isIdentifier,
              isUnique: pst.isUnique,
              isGlobalUnique: pst.isGlobalUnique,
              options: pst.options,
              parameter_type_id: pst.parameterTypeID,
              sort_order: pst.sortOrder,
              subtype: pst.subType,
              visible: pst.visible,
            };
          }
        ),
      },
    };
  }

  private static toParameterKey(option: undefined): undefined;
  private static toParameterKey(
    option: IProductParameterKeyVM
  ): ParameterKeyFieldsFragment;
  private static toParameterKey(
    option: IProductParameterKeyVM | undefined
  ): ParameterKeyFieldsFragment | undefined {
    if (!option) {
      return undefined;
    }
    return {
      id: option.parameterKeyId,
      sort_order: option.sortOrder ?? 0,
      created_at: undefined,
      key: option.parameterKey,
      parameter_id: option.parameterId,
      product_id: option.productId,
      parameter_key_values: option.parameterKeyValues.map(
        (pkv): ParameterKeyValueFieldsFragment => ({
          id: pkv.parameterKeyValueID,
          parameter_key_id: pkv.parameterKeyID,
          value: pkv.value,
          parameter_subtype_id: pkv.parameterSubType.parameterSubTypeID,
          parameter_subtype: {
            id: pkv.parameterSubType.parameterSubTypeID,
            parameter_type_id: pkv.parameterSubType.parameterTypeID,
            subtype: pkv.parameterSubType.subType,
            control_type: pkv.parameterSubType.controlType,
            // TODO: need to add "options" field to this query? I think that's recursive???
            // options: pkv.parameterSubType.options,
            is_identifier: pkv.parameterSubType.isIdentifier,
            isUnique: pkv.parameterSubType.isUnique,
            visible: pkv.parameterSubType.visible,
            sort_order: pkv.parameterSubType.sortOrder,
          },
        })
      ),
      parameter_sub_keys: [],
    };
  }

  private static toCoverageParameterKey(
    product: ProductFieldsFragment,
    id: number,
    parameterKeyValues: CoverageKeyValue[],
    coverageParam: IProductParameterVM
  ): ParameterKeyFieldsFragment {
    const key = parameterKeyValues.find((pkv) => pkv.isIdentifier);

    return {
      id,
      sort_order: 0,
      created_at: undefined,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      key: key!.value,
      parameter_id: coverageParam.parameterId,
      product_id: product.id,
      parameter_key_values: coverageParam.parameterType.parameterSubTypes.map(
        (pst): ParameterKeyValueFieldsFragment => {
          return {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            id: undefined!,
            parameter_key_id: id,
            value:
              parameterKeyValues.find(
                (pkv) => pkv.parameterSubtypeId === pst.parameterSubTypeID
              )?.value ?? '',
            parameter_subtype_id: pst.parameterSubTypeID,
            parameter_subtype: {
              id: pst.parameterSubTypeID,
              control_type: pst.controlType,
              is_identifier: pst.isIdentifier,
              isUnique: pst.isUnique,
              // TODO: need to add "options" field to this query? I think that's recursive???
              // options: pst.options,
              parameter_type_id: pst.parameterTypeID,
              subtype: pst.subType,
              visible: pst.visible,
              sort_order: pst.sortOrder,
            },
          };
        }
      ),
      parameter_sub_keys: [],
    };
  }

  private static addParameterKey(
    product: ProductFieldsFragment,
    paramKey: ParameterKeyFieldsFragment
  ): ProductFieldsFragment {
    if (!product.parameter_keys) {
      product.parameter_keys = [];
    }
    product.parameter_keys.push(paramKey);
    return product;
  }

  private static updateParameterKey(
    product: ProductFieldsFragment,
    updatedParamKey: ParameterKeyFieldsFragment,
    originalParamKey: ParameterKeyFieldsFragment
  ): ProductFieldsFragment {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const matchKey = this.getMatchingParameterKey(
      product.parameter_keys,
      originalParamKey
    )!;
    updatedParamKey.id = originalParamKey.id;
    const matchIndex = product.parameter_keys.indexOf(matchKey); // should be the same as above
    product.parameter_keys[matchIndex] = updatedParamKey;
    return product;
  }

  private static removeParameterKey(
    product: ProductFieldsFragment,
    paramKey: ParameterKeyFieldsFragment
  ): ProductFieldsFragment {
    const matchKey = this.getMatchingParameterKey(
      product.parameter_keys,
      paramKey
    );
    product.parameter_keys = product.parameter_keys.filter(
      (pk) => pk !== matchKey
    );
    return product;
  }

  private static getMatchingParameterKey(
    existingKeys: ParameterKeyFieldsFragment[],
    findMatchFor: ParameterKeyFieldsFragment
  ): ParameterKeyFieldsFragment | null | undefined {
    if (!existingKeys || !findMatchFor) {
      return null;
    }
    return findMatchFor.id
      ? existingKeys.find((k) => k.id === findMatchFor.id)
      : existingKeys.find(
          (k) =>
            k.parameter_id === findMatchFor.parameter_id &&
            findMatchFor.parameter_key_values?.length ===
              k.parameter_key_values?.length &&
            findMatchFor.parameter_key_values.every((pkv1) =>
              k.parameter_key_values.some((pkv2) => pkv1.value === pkv2.value)
            )
        );
  }

  private static getParameterSubKeys(
    bundleCoverageCodes: string[],
    options: IProductParameterKeyVM[]
  ): ParameterSubKeyFieldsFragment[] {
    return options
      .filter(
        (op) =>
          this.parameterKeyIsStandalone(op) &&
          this.parameterKeyHasValues(op) &&
          this.parameterKeyHasOneOfTheseCodes(op, bundleCoverageCodes)
      )
      .map((op): ParameterSubKeyFieldsFragment => {
        return {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: undefined!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          parent_parameter_key_id: undefined!,
          child_parameter_key: this.toParameterKey(op),
        };
      });
  }

  private static parameterKeyIsStandalone(
    key: IProductParameterKeyVM
  ): boolean {
    return !key.subKeys || key.subKeys.length === 0;
  }

  private static parameterKeyHasValues(key: IProductParameterKeyVM): boolean {
    return key.parameterKeyValues?.length > 0;
  }

  private static parameterKeyHasOneOfTheseCodes(
    key: IProductParameterKeyVM,
    bundledCoverageCodes: string[]
  ): boolean {
    return key.parameterKeyValues.some((pkv) =>
      bundledCoverageCodes.includes(pkv.value)
    );
  }
}

// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetProductRatesQueryVariables = Types.Exact<{
  productId: Types.Scalars['Int'];
  parameters: Array<Types.FilteredParameter> | Types.FilteredParameter;
  skip: Types.InputMaybe<Types.Scalars['Int']>;
  take: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetProductRatesQuery = { productRates: { totalCount: number, nodes: Array<{ parameterKeyIds: Array<number>, dealerCost: number, msrp: number, bucketRateTotals: Array<{ bucketId: number, total: number }>, msrpOperations: Array<{ operator: string, operand: number }> | null }> } };

export const GetProductRatesDocument = gql`
    query GetProductRates($productId: Int!, $parameters: [FilteredParameter!]!, $skip: Int, $take: Int) {
  productRates(
    productId: $productId
    parameters: $parameters
    skip: $skip
    take: $take
  ) {
    totalCount
    nodes {
      parameterKeyIds
      dealerCost
      msrp
      bucketRateTotals {
        bucketId
        total
      }
      msrpOperations {
        operator
        operand
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductRatesGQL extends Apollo.Query<GetProductRatesQuery, GetProductRatesQueryVariables> {
    override document = GetProductRatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetDealerCostRoundingBucketsQueryVariables = Types.Exact<{
  productId: Types.Scalars['bigint'];
}>;


export type GetDealerCostRoundingBucketsQuery = { product_by_pk: { buckets: Array<{ id: number, name: string }> } | null };

export const GetDealerCostRoundingBucketsDocument = gql`
    query GetDealerCostRoundingBuckets($productId: bigint!) {
  product_by_pk(id: $productId) {
    buckets(
      where: {_not: {bucket_offset: {}, configured_offset: {}}}
      order_by: {sort_order: asc}
    ) {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDealerCostRoundingBucketsGQL extends Apollo.Query<GetDealerCostRoundingBucketsQuery, GetDealerCostRoundingBucketsQueryVariables> {
    override document = GetDealerCostRoundingBucketsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
// export function roundUpTo(roundTo: number, value: number): number {
//   return roundTo && value
//     ? Math.ceil(value / roundTo) * roundTo
//     : roundTo
//     ? roundTo // in this case, value is falsey. So we just return whatever the roundTo value is.
//     : 0;
// }

// export function roundDownTo(roundTo: number, value: number): number {
//   return roundTo && value
//     ? Math.floor(value / roundTo) * roundTo
//     : roundTo
//     ? roundTo
//     : 0;
// }

// export function roundToNearest(roundTo: number, value: number): number {
//   return roundTo && value
//     ? Math.round(value / roundTo) * roundTo
//     : roundTo
//     ? roundTo
//     : 0;
// }

export function roundUpTo(roundTo: number, value: number): number {
  return roundTo && value ? Math.ceil(value / roundTo) * roundTo : 0;
}

export function roundDownTo(roundTo: number, value: number): number {
  return roundTo && value ? Math.floor(value / roundTo) * roundTo : 0;
}

export function roundToNearest(roundTo: number, value: number): number {
  return roundTo && value ? Math.round(value / roundTo) * roundTo : 0;
}

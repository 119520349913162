import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';
import * as ParameterTypeActions from '../state/parameter-type.actions';
import { GetAllParameterTypesQueryGQL } from '../queries/get-all-parameter-types.query.generated';

@Injectable()
export class ParameterTypeEffects {
  init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ParameterTypeActions.init),
      exhaustMap((_) => {
        return this.getAllParameterTypesQueryGQL.fetch().pipe(
          map((response) => {
            return ParameterTypeActions.loadParameterTypesSuccess({
              parameterTypes: response.data.parameterTypes,
              parameterSubtypes: response.data.parameterSubtypes,
            });
          })
        );
      }),
      catchError((error) => {
        return of(ParameterTypeActions.loadParameterTypesFailure({ error }));
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly getAllParameterTypesQueryGQL: GetAllParameterTypesQueryGQL
  ) {}
}

import { FilteredParameter } from '../../models/filtered-parameter.interface';
import { IParameterVM } from '../../models/view-models/parameters/parameter.view-model';
import { IProductParameterVM } from '../../models/view-models/products/product-options.view-model';

/**
 * Converts the ag-grid filter model into an array of filter models for sending to the backend
 * with a base rates, exceptions, or bucket review request. Assumes that the keys of the ag-grid
 * filter model are stringified parameter ids, and that each filter is a 'set' filter.
 * @param filterModel The ag-grid filter model
 * @param params The parameters used in the grid
 */
export function buildParameterFilters(
  filterModel: { [key: string]: { values: string[] } },
  params: IParameterVM[] | IProductParameterVM[]
): FilteredParameter[] {
  return params.map(
    (p: IParameterVM | IProductParameterVM): FilteredParameter => {
      const filteredParam: FilteredParameter = {
        parameterId: p.parameterId,
      };
      if (filterModel[p.parameterId]) {
        filteredParam.filteredParameterKeyIds = getUnselectedParameterKeys(
          p.keys,
          filterModel[p.parameterId].values
        );
      }
      return filteredParam;
    }
  );
}

/**
 * Gets the parameter keys that are present in the given set of all keys but not present in the given set of selected keys.
 * @param allKeys The parameter whose keys are being filtered.
 * @param selectedKeys The selected keys.
 * @returns An array of parameter key ids.
 */
export function getUnselectedParameterKeys(
  allKeys: { parameterKey: string; parameterKeyId: number }[],
  selectedKeys: string[]
): number[] {
  return allKeys.reduce(
    (
      pkIds: number[],
      parameterKey: { parameterKey: string; parameterKeyId: number }
    ) => {
      if (!selectedKeys.includes(parameterKey.parameterKey)) {
        pkIds.push(parameterKey.parameterKeyId);
      }
      return pkIds;
    },
    []
  );
}

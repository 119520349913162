  <mat-sidenav-container class="h-screen">
    <mat-sidenav 
      [mode]="sidenavMode"
      *ngIf="toggleService.isOpened | async"
      [(opened)]="menuOpened"
      #menuSidenav
    >
      <rr-sidenav [navItems]="navItems"></rr-sidenav>
    </mat-sidenav>
    <mat-sidenav-content class="!bg-white">
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>


import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-jump-link',
  templateUrl: 'jump-link.component.html',
  styleUrls: ['jump-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'text-primary-default' },
})
export class JumpLinkComponent {
  @Input() link!: (string | number)[];
}

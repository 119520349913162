import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { MatDrawerMode, MatSidenavContent } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable, Subject } from 'rxjs';
import { NavItem } from '@roadrunner/shared/ui-sidenav';
import { UiToggleService } from '../service';


@Component({
  selector: 'rr-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnDestroy, OnInit {
  @Input() navItems: NavItem[] = [];

  @ViewChild(MatSidenavContent) sidenavContent!: MatSidenavContent;

  @HostBinding('class.is-mobile') isMobile?: boolean;

  menuOpened = true;

  moduleLoading$!: Observable<boolean>;

  sidenavMode!: MatDrawerMode;

  private destroyed$ = new Subject<void>();

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    public toggleService:UiToggleService
  ) {   
  }

  ngOnInit() {
    this.setUpResponsiveness();
    this.setUpSidenavScrollReset();
  }

  private setUpResponsiveness() {
    this.breakpointObserver
      .observe('(max-width: 959px)')
      .pipe(
        map((breakpointState) => breakpointState.matches),
        takeUntil(this.destroyed$)
      )
      .subscribe((isMobile) => {
        if (isMobile && this.menuOpened) {
          this.menuOpened = false;
        } else if (!isMobile && !this.menuOpened) {
          this.menuOpened = true;
        }
        this.isMobile = isMobile;
        this.sidenavMode = isMobile ? 'over' : 'side';
        this.cdr.markForCheck();
      });
  }

  private setUpSidenavScrollReset() {
    this.router.events
      .pipe(
        filter(
          (e) =>
            // When on a mobile device the document is the scrolling element, and scroll position is handled
            // by the router via its `scrollPositionRestoration` feature, so we only need to scroll to top on
            // larger devices where the scrolling element is the mat-sidenav-content.
            !this.isMobile && e instanceof NavigationEnd
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.scrollSidenavToTop();
      });
  }

  private scrollSidenavToTop() {
    this.sidenavContent.scrollTo({ top: 0, left: 0 });
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}

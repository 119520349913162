import { ValueFormatterParams } from '@ag-grid-community/core';
import { formatNumber } from '@angular/common';

export function numericValueFormatter(defaultValue: string = '') {
  return (params: ValueFormatterParams) => {
    return params.value == null || params.value === ''
      ? defaultValue
      : numericFormatter(params.value);
  };
}

export function numericFormatter(value: number) {
  return formatNumber(value, 'en-US', '0.0-4');
}

<button
  mat-raised-button
  type="submit"
  [color]="color"
  [disabled]="disabled"
  class="py-2"
  [class.w-full]="fullWidth"
  [class.mat-button-disabled]="!submittable"
  [class.rr-submit-button-success]="color === 'success' && submittable"
  (click)="onClick($event)"
>
  <span class="flex items-center">
    <mat-icon *ngIf="!submitting; else savingSpinner" class="mr-2">
      cloud_upload
    </mat-icon>
    <ng-content></ng-content>
  </span>
</button>
<ng-template #savingSpinner>
  <mat-spinner [diameter]="20" color="accent" class="mr-2"></mat-spinner>
</ng-template>

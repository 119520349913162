import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as ProgramActions from './program.actions';
import { Program } from './program.models';

export const PROGRAM_FEATURE_KEY = 'program';

export interface State extends EntityState<Program> {
  loading: boolean;
  error: unknown | null;
}

export interface ProgramPartialState {
  readonly [PROGRAM_FEATURE_KEY]: State;
}

export const programAdapter: EntityAdapter<Program> =
  createEntityAdapter<Program>();

export const initialState: State = programAdapter.getInitialState({
  loading: true,
  error: null,
});

export const reducer = createReducer(
  initialState,
  on(ProgramActions.programsGuardActivated, (state): State => {
    return {
      ...state,
      error: null,
    };
  }),
  on(ProgramActions.loadProgramsSuccess, (state, { program }): State => {
    return programAdapter.setAll<State>(program, {
      ...state,
      loading: false,
    });
  }),
  on(
    ProgramActions.loadProgramsFailure,
    (state, action): State => ({
      ...state,
      loading: false,
      error: action.error,
    })
  ),
  on(ProgramActions.programsGuardDeactivated, (_state, _action): State => {
    // Purge the programs cache when the user switches away from the EMU
    // TODO: make program state truly global
    return initialState;
  })
);

import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { SaveBucketRateExceptionsResponse } from './delete-bucket-rate-exceptions.interface';
import { deleteBucketRateExceptionsMutation } from './delete-bucket-rate-exceptions.mutation';

export class DeleteBucketRateExceptionsData {
  static deleteBucketRateExceptions(
    apollo: Apollo,
    productParameterKeyCombinationIds: number[]
  ): Observable<SaveBucketRateExceptionsResponse> {
    return (
      apollo
        .mutate<SaveBucketRateExceptionsResponse>({
          mutation: deleteBucketRateExceptionsMutation,
          variables: { productParameterKeyCombinationIds },
        })
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .pipe(map((res) => res.data!))
    );
  }
}

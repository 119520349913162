// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SetIsNonSellableMutationVariables = Types.Exact<{
  limit: Types.InputMaybe<Types.Scalars['Int']>;
  productId: Types.Scalars['bigint'];
}>;


export type SetIsNonSellableMutation = { non_sellable_combination_options: Array<{ id: number, non_sellable_combination_id: number, parameter_key_id: number }> };

export const SetIsNonSellableDocument = gql`
    mutation SetIsNonSellable($limit: Int, $productId: bigint!) {
  non_sellable_combination_options: set_is_non_sellable(
    limit: $limit
    args: {productid: $productId}
  ) {
    id
    non_sellable_combination_id
    parameter_key_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetIsNonSellableGQL extends Apollo.Mutation<SetIsNonSellableMutation, SetIsNonSellableMutationVariables> {
    override document = SetIsNonSellableDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
<div class="py-12 px-4 bg-primary-default h-screen">
  <div class="my-0 mx-auto w-[600px]">
    <div>
      <div class="text-center py-6">
        <img
          src="assets/images/logo-light.svg"
          alt=""
          class="inline w-[250px]"
        />
        <h1 class="text-3xl text-accent-default">Roadrunner</h1>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>

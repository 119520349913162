<roadrunner-dialog-header
  headerText="Delete Coverage"
  [matDialogCloseValue]="false"
></roadrunner-dialog-header>

<mat-dialog-content>
  <p>
    Are you sure you want to delete the {{ coverageName }} ({{ coverageCode }})
    coverage? This action is irreversible.
  </p>
</mat-dialog-content>

<div mat-dialog-actions align="end">
  <button mat-button type="button" [matDialogClose]="false">Cancel</button>
  <button
    mat-raised-button
    type="button"
    color="warn"
    class="ml-5"
    [matDialogClose]="true"
    cdkFocusInitial
  >
    Delete {{ coverageName }}
  </button>
</div>

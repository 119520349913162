import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { SharedUiButtonsModule } from '@roadrunner/shared/ui-buttons';
import { SharedUtilFormsModule } from '@roadrunner/shared/util-forms';

import { SharedUiDialogModule } from '@roadrunner/shared/ui-dialog';
import { AddProductComponent } from './add-product/add-product.component';
import { CopyProductComponent } from './copy-product/copy-product.component';
import { CopyingProductComponent } from './copying-product/copying-product.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    ReactiveFormsModule,
    SharedUiButtonsModule,
    SharedUiDialogModule,
    SharedUtilFormsModule,
  ],
  declarations: [
    AddProductComponent,
    CopyProductComponent,
    CopyingProductComponent,
  ],
})
export class RatingUtilityUiAddProductModule {}

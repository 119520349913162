import { Injectable } from '@angular/core';
import {
  AsyncValidatorFn,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  IParameterKeyValueVM,
  IProductParameterKeyVM,
} from '@rating-utility/models/view-models/products/product-options.view-model';
import { FormUtility } from '@rating-utility/shared/utility/form.utility';
import { ControlType } from '@roadrunner/rating-utility/data-access-parameter-type';
import { uniqueValidatorAsync } from '@roadrunner/shared/util-forms';
import { trpcClient } from '@roadrunner/shared/util-trpc';
import { filter, map, Observable, switchMap } from 'rxjs';
import { selectChosenProductId } from '../../../../store/product/product.selectors';

@Injectable({
  providedIn: 'root',
})
export class ProductTypeService {
  constructor(private store: Store) {}

  getFormArrayControls(
    option: IProductParameterKeyVM | undefined,
    siblingOptions: IProductParameterKeyVM[]
  ): UntypedFormGroup[] {
    return (
      option?.parameterKeyValues.map((kv) => {
        let value: string | boolean = kv.value;
        if (kv.parameterSubType.controlType === ControlType.Checkbox) {
          // parameter key values are stored in the db as strings, so working values in the UI may be strings or booleans.
          value = this.coerceBoolean(value);
        }
        let validators = [Validators.required];
        let asyncValidators: AsyncValidatorFn[] = [];
        if (kv.parameterSubType.isGlobalUnique) {
          validators.push(
            FormUtility.unique(
              siblingOptions
                .map(
                  (keys) =>
                    keys.parameterKeyValues.find(
                      (keyValue) =>
                        keyValue.parameterSubType.parameterSubTypeID ===
                        kv.parameterSubType.parameterSubTypeID
                    )?.value ?? ''
                )
                .filter((value) => value != '')
            )
          );

          asyncValidators.push(
            uniqueValidatorAsync<IParameterKeyValueVM[]>(
              this.checkParametersValueUnique.bind(this),
              kv
            )
          );
        }

        return new UntypedFormGroup({
          valueControl: new UntypedFormControl(
            value,
            validators,
            asyncValidators
          ),
          keyValueControl: new UntypedFormControl(kv, Validators.required),
        });
      }) ?? []
    );
  }

  updateValidators(
    form: UntypedFormGroup[],
    siblingOptions: IProductParameterKeyVM[]
  ): UntypedFormGroup[] {
    return form.map((untypedFormGroup) => {
      let value: string | boolean =
        untypedFormGroup.controls.valueControl.value;
      if (
        untypedFormGroup.controls.keyValueControl.value.parameterSubType
          .controlType === ControlType.Checkbox
      ) {
        // parameter key values are stored in the db as strings, so working values in the UI may be strings or booleans.
        value = this.coerceBoolean(value);
      }

      let validators = [Validators.required];
      let asyncValidators: AsyncValidatorFn[] = [];
      if (
        untypedFormGroup.controls.keyValueControl.value.parameterSubType
          .isGlobalUnique
      ) {
        validators.push(
          FormUtility.unique(
            siblingOptions
              .map(
                (keys) =>
                  keys.parameterKeyValues.find(
                    (keyValue) =>
                      keyValue.parameterSubType.parameterSubTypeID ===
                      untypedFormGroup.controls.keyValueControl.value
                        .parameterSubType.parameterSubTypeID
                  )?.value ?? ''
              )
              .filter((value) => value != '')
          )
        );

        asyncValidators.push(
          uniqueValidatorAsync<IParameterKeyValueVM[]>(
            this.checkParametersValueUnique.bind(this),
            untypedFormGroup.controls.keyValueControl.value
          )
        );
      }

      return new UntypedFormGroup({
        valueControl: new UntypedFormControl(
          value,
          validators,
          asyncValidators
        ),
        keyValueControl: new UntypedFormControl(
          untypedFormGroup.controls.keyValueControl.value,
          Validators.required
        ),
      });
    });
  }

  private coerceBoolean(value: string | boolean): boolean {
    return (
      value === true ||
      value === true.toString() ||
      value?.toString().toLowerCase() === true.toString()
    );
  }

  checkParametersValueUnique(
    value: string,
    parameterKeyValues?: IParameterKeyValueVM
  ): Observable<boolean> {
    const parameterValue = value;
    const parameterSubTypeID =
      parameterKeyValues?.parameterSubType.parameterSubTypeID ?? 0;
    return this.store.select(selectChosenProductId).pipe(
      filter((productId) => productId !== null && parameterValue !== null),
      switchMap((productId) => {
        return trpcClient.product.checkParameterValueGlobalUnique.query({
          parameterSubTypeID: parameterSubTypeID,
          parameterValue: parameterValue,
          productId: productId ?? 0,
        });
      }),
      map(({ count }) => {
        return count === 0;
      })
    );
  }
}

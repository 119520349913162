<form [formGroup]="parameterFormGroup" (ngSubmit)="formSubmitted()">
  <roadrunner-dialog-header
    headerText="Add a Parameter"
  ></roadrunner-dialog-header>

  <mat-dialog-content class="p-8" cdkFocusInitial>
    <!-- TODO: alert component with encapsulated styles -->
    <div
      class="p-3 mb-6 text-xs font-bold rounded-lg bg-accent-default text-primary-default"
      *ngIf="data.disableProgramParameterIds.length > 0"
    >
      NOTE: Term Mileage and Term Months cannot co-exist
    </div>

    <div class="add-parameter">
      <mat-form-field>
        <mat-label>Select a Parameter</mat-label>
        <mat-select [formControl]="parameterSelectControl" #parameterSelect>
          <mat-option
            *ngFor="let param of data.unusedParams"
            [value]="param"
            [disabled]="
              data.disableProgramParameterIds.indexOf(param.parameterId) > -1
            "
          >
            {{ param.parameterName }}
          </mat-option>
        </mat-select>
        <mat-hint *ngIf="data.unusedParams.length === 0">
          All parameters in use. Click 'Add Parameter' to add one.
        </mat-hint>
        <mat-error
          *ngIf="
            parameterSelectControl.errors?.required &&
            data.unusedParams.length > 0
          "
        >
          Parameter is required
        </mat-error>
      </mat-form-field>
      <button
        #addParameterButton
        type="button"
        mat-flat-button
        (click)="onAddParameterClick()"
      >
        Add Parameter
      </button>
    </div>

    <!-- Create First Option -->
    <div *ngIf="parameterSelectControl.value">
      <p><b>Create the first Option</b></p>
      <app-parameter-option-form-fields
        [optionNameControl]="optionNameControl"
        [optionKeyValuesArrayControls]="optionKeyValuesArrayControls"
        [isFormFieldsInDialog]="true"
      >
      </app-parameter-option-form-fields>
    </div>
  </mat-dialog-content>

  <div mat-dialog-actions align="end">
    <button mat-button type="button" [matDialogClose]="null">Cancel</button>
    <button
      mat-raised-button
      type="submit"
      color="primary"
      class="ml-5"
      [disabled]="
        parameterFormGroup.invalid ||
        parameterFormGroup.pristine ||
        parameterFormGroup.pending
      "
    >
      <mat-icon>cloud_upload</mat-icon> Save
    </button>
  </div>
</form>

import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  AddProgramComponent,
  AddProgramDialogData,
  AddProgramDialogResult,
  DialogDataType,
  EditProgramDialogData,
  EditProgramDialogResult,
} from '@roadrunner/rating-utility/ui-add-program';
import { DialogSize, ModalService } from '@roadrunner/shared/util-modal';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { ProgramService } from '../program.service';
import { GetAllProgramsQueryGQL } from '../queries/get-all-programs.query.generated';
import {
  addProgram,
  addProgramCancelled,
  addProgramClicked,
  addProgramFailure,
  addProgramSuccess,
  editProgram,
  editProgramCancelled,
  editProgramClicked,
  editProgramFailure,
  editProgramSuccess,
  init,
  loadProgramFailure,
  loadProgramSuccess,
} from './program.actions';
import {
  selectAllProgramAgentCodes,
  selectAllProgramNames,
  selectSelectedProgram,
} from './program.selectors';

@Injectable()
export class ProgramEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private modalService: ModalService,
    private programService: ProgramService,
    private getAllProgramsQueryGQL: GetAllProgramsQueryGQL
  ) {}

  init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(init),
      exhaustMap((_) => {
        return this.getAllProgramsQueryGQL.fetch().pipe(
          map((response) => {
            return loadProgramSuccess({ program: response.data.program });
          })
        );
      }),
      catchError((_error) => {
        return of(loadProgramFailure());
      })
    );
  });

  addProgramDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addProgramClicked),
      concatLatestFrom(() => [
        this.store.pipe(select(selectAllProgramAgentCodes)),
        this.store.pipe(select(selectAllProgramNames)),
      ]),
      exhaustMap(([_action, agentCodes, programNames]) => {
        return this.modalService
          .open<AddProgramDialogData, AddProgramDialogResult>(
            AddProgramComponent,
            {
              width: DialogSize.Medium,
              data: {
                dataType: DialogDataType.Add,
                agentCodes,
                programNames,
              },
            }
          )
          .pipe(
            map((program) => {
              return program ? addProgram({ program }) : addProgramCancelled();
            })
          );
      })
    );
  });

  addProgram$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addProgram),
      exhaustMap((action) =>
        this.programService.add(action.program).pipe(
          map((program) => {
            return addProgramSuccess({ program });
          })
        )
      ),
      catchError((error) => {
        return of(addProgramFailure({ error }));
      })
    );
  });

  editProgramDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(editProgramClicked),
      concatLatestFrom(() => this.store.select(selectSelectedProgram)),
      exhaustMap(([_action, program]) => {
        return this.modalService
          .open<EditProgramDialogData, EditProgramDialogResult>(
            AddProgramComponent,
            {
              width: DialogSize.Medium,
              data: {
                dataType: DialogDataType.Edit,
                id: program.id,
                programName: program.name,
                agentCode: program.agent_code,
                cms: program.cms,
              },
            }
          )
          .pipe(
            map((program) => {
              return program
                ? editProgram({ program })
                : editProgramCancelled();
            })
          );
      })
    );
  });

  editProgram$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(editProgram),
      exhaustMap((action) =>
        this.programService.update(action.program).pipe(
          map((program) => {
            if (!program) {
              return editProgramFailure({ error: 'Program not found' });
            }

            return editProgramSuccess({ program });
          })
        )
      ),
      catchError((error) => {
        return of(editProgramFailure({ error }));
      })
    );
  });
}

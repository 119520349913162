import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserSelectors } from '@roadrunner/shared/data-access-user';
import { ProductLogMessage } from '@roadrunner/shared/client-logging';
import {
  CoverageKeyValue,
  IBundledCoverageVM,
  IStandaloneCoverageVM,
} from '../../models/view-models/products/coverage.view-model';
import { IProductParameterVM } from '../../models/view-models/products/product-options.view-model';
import { ProductTypeListItem } from '../../models/view-models/products/product-type.view-model';
import { DRYUtility } from '../../shared/utility/dry.utility';
import { selectChosenProgram, selectRiskTypes } from '../user/user.selectors';
import { productFeatureKey, State } from './product.reducers';
import { toProductParameter } from './to-product-parameter';

const selectProductState = createFeatureSelector<State>(productFeatureKey);
export const selectProduct = createSelector(
  selectProductState,
  (state: State) => state?.product
);
const selectProductsParentProgram = createSelector(
  selectProduct,
  (product) => product?.program
);
export const selectCanEditParameters = createSelector(
  selectProductsParentProgram,
  (program) => program?.canEditParameters ?? false
);
const selectAllParameters = createSelector(
  selectProductState,
  (state) => state.parameters
);
const selectProductParameterKeys = createSelector(
  selectProduct,
  (product) => product?.parameter_keys ?? []
);
/**
 * Selects the parameters that are "in use" by this product
 * i.e., parameters that have one or more parameter keys for this product
 */
const selectProductParameters = createSelector(
  selectAllParameters,
  selectProductParameterKeys,
  (params, paramKeys) => {
    return params?.filter((param) =>
      paramKeys?.some((pk) => pk.parameter_id === param.id)
    );
  }
);
const selectProductParametersAsViewModels = createSelector(
  selectProductParameters,
  selectProductParameterKeys,
  (productParams, paramKeys) =>
    productParams?.map((param) => toProductParameter(param, paramKeys))
);

export const selectAllParameterNames = createSelector(
  selectAllParameters,
  (params) => params?.map((p) => p.name) ?? []
);

const selectStateProductTypeList = createSelector(
  selectProductState,
  (state: State) => state.productTypeList ?? []
);

export const selectAllProductTypes = createSelector(
  selectStateProductTypeList,
  (productTypes) => {
    return productTypes.map((productType): ProductTypeListItem => {
      return {
        id: productType.id,
        name: productType.type,
        description: productType.description,
        products: productType.products,
      };
    });
  }
);
export const selectProductTypeList = createSelector(
  selectAllProductTypes,
  (productTypes) => {
    return productTypes.filter(
      (productType) => productType.products.length > 0
    );
  }
);

export const selectSavedProductSettings = createSelector(
  selectProductState,
  (state) => state.savedProductSettings
);

export const selectChosenProductId = createSelector(
  selectProduct,
  (product) => product?.id
);

export const selectChosenProductRiskTypeCode = createSelector(
  selectProduct,
  (product) => product?.risk_type
);

export const selectChosenProductRiskType = createSelector(
  selectProduct,
  selectRiskTypes,
  (product, riskTypes) => {
    return riskTypes.find((riskType) => riskType.code === product?.risk_type);
  }
);

export const selectChosenProductType = createSelector(
  selectAllProductTypes,
  selectProduct,
  (productTypes, product) => {
    return product
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        productTypes.find((pt) => pt.id === product.product_type_id)!
      : null;
  }
);
export const selectChosenProductName = createSelector(
  selectProduct,
  (product) => product?.name
);
export const selectChosenProductDescription = createSelector(
  selectProduct,
  (product) => product?.description
);
export const selectChosenProductCode = createSelector(
  selectProduct,
  (product) => product?.code
);
export const selectChosenProductTypeId = createSelector(
  selectProduct,
  (product) => product?.product_type_id
);
export const selectSiblingProducts = createSelector(
  selectProductsParentProgram,
  selectProduct,
  // TODO: remove !
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  (program, product) => program?.products.filter((p) => p.id !== product!.id)
);
export const selectNonCoverageParameters = createSelector(
  selectProductParametersAsViewModels,
  (productParams) =>
    productParams?.filter((p) => !p.parameterType.bundleable) ?? []
);

export const selectCoverageParameter = createSelector(
  selectAllParameters,
  selectProductParameterKeys,
  (productParams, allParamKeys): IProductParameterVM | null => {
    const coverageParam = productParams?.find(
      (p) => p.parameter_type.bundleable
    );
    return coverageParam
      ? toProductParameter(coverageParam, allParamKeys)
      : null;
  }
);

export const selectUnusedProgramParameters = createSelector(
  selectAllParameters,
  selectProductParameterKeys,
  (params, paramKeys) =>
    params
      ?.filter((param) =>
        paramKeys?.every((pk) => pk.parameter_id !== param.id)
      )
      .map((param) => toProductParameter(param, paramKeys))
);

export const selectRateChosenProductLink = createSelector(
  selectChosenProductId,
  (productId): (string | number)[] | null => {
    if (!productId) {
      return null;
    }
    return ['/rating', 'rates', productId];
  }
);

export const selectChosenProductLink = createSelector(
  selectChosenProductId,
  (productId) => {
    if (productId) {
      return ['/rating', 'products', productId];
    }
    return null;
  }
);

export const selectIsSavingProductSettings = createSelector(
  selectProductState,
  (state) => state.isSavingProductSettings
);
export const selectIsSavingProductParameters = createSelector(
  selectProductState,
  (state) => state.isSavingProductParameters
);
export const selectIsSavingProductCoverages = createSelector(
  selectProductState,
  (state) => state.isSavingCoverages
);

export const selectBundledCoverages = createSelector(
  selectCoverageParameter,
  (coverageParam) =>
    coverageParam?.keys
      .filter((op) => !DRYUtility.isNullOrUndefinedOrEmpty(op.subKeys))
      .map((parameterKey): IBundledCoverageVM => {
        const parameterKeyValues = parameterKey.parameterKeyValues
          .filter((pkv) => pkv.parameterSubType.visible)
          .map((pkv): CoverageKeyValue => {
            return {
              value: pkv.value,
              isIdentifier: pkv.parameterSubType.isIdentifier,
              isUnique: pkv.parameterSubType.isUnique,
              parameterSubtypeId: pkv.parameterSubType.parameterSubTypeID,
              parameterSubtype: pkv.parameterSubType.subType,
            };
          });

        return {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: parameterKey.parameterKeyId!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          coverageIds: parameterKey.subKeys!.map((sk) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return sk.parameterKey!;
          }),
          parameterKeyValues,
          canDelete: true,
        };
      }) ?? []
);

export const selectStandaloneCoverages = createSelector(
  selectCoverageParameter,
  selectBundledCoverages,
  (coverageParam, bundles) =>
    coverageParam?.keys
      .filter((op) => DRYUtility.isNullOrUndefinedOrEmpty(op.subKeys))
      .map((op): IStandaloneCoverageVM => {
        const parameterKeyValues = op.parameterKeyValues
          .filter((pkv) => pkv.parameterSubType.visible)
          .map((pkv): CoverageKeyValue => {
            return {
              value: pkv.value,
              isIdentifier: pkv.parameterSubType.isIdentifier,
              isUnique: pkv.parameterSubType.isUnique,
              parameterSubtypeId: pkv.parameterSubType.parameterSubTypeID,
              parameterSubtype: pkv.parameterSubType.subType,
            };
          });

        const identifier = parameterKeyValues.find((pkv) => pkv.isIdentifier);

        return {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: op.parameterKeyId!,
          parameterKeyValues,
          canDelete:
            !identifier ||
            bundles.every((b) => !b.coverageIds.includes(identifier.value)),
        };
      }) ?? []
);

export const selectProductStateNonCoverageParameterKeys = createSelector(
  selectProductParameterKeys,
  selectNonCoverageParameters,
  (keys, nonCoverageParams) => {
    const nonCoverageParameterIds = nonCoverageParams
      .map((p) => p.parameterId)
      .filter((pId) => !!pId);
    return keys.filter((k) => nonCoverageParameterIds.includes(k.parameter_id));
  }
);

export const selectProductStateCoverageParameterKeys = createSelector(
  selectProductParameterKeys,
  selectCoverageParameter,
  (keys, coverageParam) => {
    return keys?.filter(
      (k) =>
        coverageParam?.parameterId &&
        k.parameter_id === coverageParam.parameterId
    );
  }
);

export const selectDeletedNonCoverageParameterKeys = createSelector(
  selectProductState,
  (state) => state.deletedNonCoverageParameterKeys
);

export const selectDeletedCoverageParameterKeys = createSelector(
  selectProductState,
  (state) => state.deletedCoverageParameterKeys
);

export const selectProductLogInfo = createSelector(
  selectChosenProductId,
  selectChosenProgram,
  selectChosenProductName,
  UserSelectors.selectUserName,
  UserSelectors.selectUserEmail,
  (productId, program, productName, userName, userEmail) => {
    return {
      product_id: productId,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      program_id: program!.id,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      program: program!.name,
      product: productName,
      user_name: userName,
      user_email: userEmail,
    } as ProductLogMessage;
  }
);

export const selectProductCodes = createSelector(
  selectProductTypeList,
  (productTypes) => {
    return productTypes.reduce((productCodes: string[], productType) => {
      return productCodes.concat(productType.products.map((p) => p.code));
    }, []);
  }
);

export class ProductTabNames {
  static ProductSettings = 'product-settings';
  static Coverages = 'coverages';
  static Parameters = 'parameters';
  static ProductReview = 'product-review';
}

export const orderedProductTabNames = [
  ProductTabNames.ProductSettings,
  ProductTabNames.Coverages,
  ProductTabNames.Parameters,
  ProductTabNames.ProductReview,
];

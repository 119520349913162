import { DateTimeNullableFilter } from 'graphql/generated-client/gateway-schema.types';
import { DateTime } from 'luxon';

export interface FilterTypeDate {
  filterType: 'date';
  dateFrom: string;
  dateTo: string | null;
}

export function buildQueryFilterTypeDate(date: string): DateTimeNullableFilter {
  return {
    equals: DateTime.fromFormat(date, 'yyyy-MM-dd hh:mm:ss', { zone: 'UTC' }),
  };
}

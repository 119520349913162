<roadrunner-dialog-header
  headerText="Add a new Rate Slice"
></roadrunner-dialog-header>
<form
  #addForm
  [formGroup]="newBucketFormGroup"
  autocomplete="off"
  (ngSubmit)="formSubmitted()"
>
  <mat-dialog-content cdkFocusInitial>
    <mat-form-field class="w-full">
      <mat-label>Rate Slice Name</mat-label>
      <input
        matInput
        [formControl]="bucketNameControl"
        trimWhitespace
        required
        data-testid="rate-slice-name-input"
      />
      <mat-error *ngIf="bucketNameControl.errors?.required">
        Name is required
      </mat-error>
      <mat-error *ngIf="bucketNameControl.errors?.unique">
        Name must be unique
      </mat-error>
    </mat-form-field>
    <div class="flex items-center">
      <app-payee-selector
        class="mr-1 grow"
        [formControl]="payeeControl"
        [payees$]="payees$"
      ></app-payee-selector>
      <button
        class="!mb-4"
        #addPayeeButton
        mat-flat-button
        type="button"
        (click)="onAddPayeeClick()"
      >
        Add a Payee
      </button>
    </div>
    <mat-form-field class="w-full" *ngIf="data.cms">
      <mat-label>CMS Bucket Number</mat-label>
      <input type="number" matInput [formControl]="cmsBucketNumberControl" />
    </mat-form-field>
    <mat-checkbox class="!m-4" [formControl]="reservesControl">
      Reserves
    </mat-checkbox>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button [matDialogClose]="null">Cancel</button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="newBucketFormGroup.invalid"
    >
      Create Rate Slice
    </button>
  </mat-dialog-actions>
</form>

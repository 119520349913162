<roadrunner-dialog-header
  headerText="Add a new Payee"
></roadrunner-dialog-header>
<form [formGroup]="form" autocomplete="off" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" required trimWhitespace />
      <mat-error *ngIf="form.hasError('required', 'name')">
        Name is required
      </mat-error>
      <mat-error *ngIf="form.hasError('unique', 'name')">
        This name is already in use
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Code</mat-label>
      <input matInput formControlName="code" required trimWhitespace />
      <mat-error *ngIf="form.hasError('required', 'code')">
        Code is required
      </mat-error>
      <mat-error *ngIf="form.getError('maxlength', 'code') as error">
        Code must be {{ error.requiredLength }} characters or fewer
      </mat-error>
      <mat-error *ngIf="form.hasError('unique', 'code')">
        This code is already in use
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button [matDialogClose]="null" type="button">Cancel</button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="form.invalid"
      type="submit"
    >
      Create Payee
    </button>
  </mat-dialog-actions>
</form>

import { Injectable } from '@angular/core';
import { JobStatus } from '@roadrunner/shared/util-api';
import { trpcClient } from '@roadrunner/shared/util-trpc';
import { Apollo } from 'apollo-angular';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  forkJoin,
  from,
  map,
  Observable,
  of,
  retry,
  switchMap,
  take,
  tap,
  timer,
} from 'rxjs';
import { ProductMsrpOperation } from '../models/view-models/products/msrp-operations.view-model';

import { IBundledCoverageParameterDiscountVM } from '../models/view-models/buckets/bucket.view-model';
import { DeleteBucketRateExceptionsData } from './mutations/delete-bucket-rate-exceptions/delete-bucket-rate-exceptions.data';
import { DeleteDealerCostRoundingData } from './mutations/delete-dealer-cost-rounding/delete-dealer-cost-rounding.data';
import { SaveBucketBundleDiscountsData } from './mutations/save-bucket-bundle-discounts/save-bucket-bundle-discounts.data';
import { SaveBucketBundleDiscountsResponse } from './mutations/save-bucket-bundle-discounts/save-bucket-bundle-discounts.interface';
import { SaveFinalCostsData } from './mutations/save-final-costs/save-final-costs.data';
import { UpsertProductParameterKeyCombination } from './mutations/save-final-costs/save-final-costs.variable';
import { SaveMsrpOperationData } from './mutations/save-msrp-operation/save-msrp-operation.data';
import { UpdateBucketMinMaxData } from './mutations/update-bucket-min-max/update-bucket-min-max.data';
import { UpdateBucketData } from './mutations/update-bucket/update-bucket.data';
import { UpdateProductMsrpParameterKeyCombinationsData } from './mutations/update-product-msrp-parameter-key-combinations/update-product-msrp-parameter-key-combinations.data';
import { UpdateProductMsrpParameterKeyCombinationsResponse } from './mutations/update-product-msrp-parameter-key-combinations/update-product-msrp-parameter-key-combinations.interface';
import { UpdateProductParameterKeyCombinationsData } from './mutations/update-product-parameter-key-combinations/update-product-parameter-key-combinations.data';
import { UpdateProductParameterKeyCombinationsResponse } from './mutations/update-product-parameter-key-combinations/update-product-parameter-key-combinations.interface';
import { UpsertBucketBaseRatesData } from './mutations/upsert-bucket-base-rates/upsert-bucket-base-rates.data';
import { BaseRateResponse } from './mutations/upsert-bucket-base-rates/upsert-bucket-base-rates.interface';
import { UpsertBucketBaseRate } from './mutations/upsert-bucket-base-rates/upsert-bucket-base-rates.variable';
import { UpsertBucketOffsetData } from './mutations/upsert-bucket-offset/upsert-bucket-offset.data';
import { IUpsertBucketOffsetResponse } from './mutations/upsert-bucket-offset/upsert-bucket-offset.interface';
import { UpsertBucketRateExceptionsData } from './mutations/upsert-bucket-rate-exceptions/upsert-bucket-rate-exceptions.data';
import { BucketRateExceptionResponse } from './mutations/upsert-bucket-rate-exceptions/upsert-bucket-rate-exceptions.interface';
import { UpsertBucketRateException } from './mutations/upsert-bucket-rate-exceptions/upsert-bucket-rate-exceptions.variable';
import { UpsertBucketRoundingData } from './mutations/upsert-bucket-rounding/upsert-bucket-rounding.data';
import { IUpsertBucketRoundingResponse } from './mutations/upsert-bucket-rounding/upsert-bucket-rounding.interface';
import { UpsertDealerCostRoundingData } from './mutations/upsert-dealer-cost-rounding/upsert-dealer-cost-rounding.data';
import { UpsertProductSettingsData } from './mutations/upsert-product-settings/upsert-product-settings.data';
import { UpsertProductSettingsResponse } from './mutations/upsert-product-settings/upsert-product-settings.interface';
import { DealerRoundingResponse } from './queries/get-dealer-cost-rounding/get-dealer-cost-rounding.interface';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private apollo: Apollo) {}

  submitPublishProductEvent(
    productId: number,
    currentUserName: string,
    currentUserEmail: string,
    effectiveDate: Date
  ) {
    return from(
      trpcClient.productRates.publish.mutate({
        productId,
        currentUserName,
        currentUserEmail,
        effectiveDate,
      })
    );
  }

  saveFinalCosts(combinations: UpsertProductParameterKeyCombination[]) {
    return SaveFinalCostsData.saveFinalCosts(this.apollo, combinations);
  }

  saveMsrpOperation(
    productMsrpParameterKeyCombinationId: number,
    operation: ProductMsrpOperation
  ) {
    return SaveMsrpOperationData.saveMsrpOperation(
      this.apollo,
      productMsrpParameterKeyCombinationId,
      operation
    );
  }

  deleteMsrpOperation(
    productMsrpParameterKeyCombinationId: number,
    operator: string
  ) {
    return SaveMsrpOperationData.deleteMsrpOperation(
      this.apollo,
      productMsrpParameterKeyCombinationId,
      operator
    );
  }

  saveBucketMinMax(
    bucketId: number,
    min: number | null,
    max: number | null
  ): Observable<unknown> {
    return UpdateBucketMinMaxData.updateBucketMinMax(
      this.apollo,
      bucketId,
      min,
      max
    );
  }

  saveRounding(
    isEnabled: boolean,
    rateSliceId: number,
    roundingValue: number | null,
    roundingType: string | null
  ): Observable<IUpsertBucketRoundingResponse | null> {
    return isEnabled
      ? UpsertBucketRoundingData.upsertBucketRounding(
          this.apollo,
          rateSliceId,
          // TODO: remove !s
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          roundingValue!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          roundingType!
        )
      : from(trpcClient.rateSlice.deleteRounding.mutate({ rateSliceId })).pipe(
          map(() => null)
        );
  }

  saveOffset(
    bucketId: number,
    savedOffsetBucketId: number | null,
    offsetBucketId: number | null,
    offsetType: string | null,
    currentUserName: string,
    currentUserEmail: string
  ): Observable<IUpsertBucketOffsetResponse | null> {
    const hasOffset = offsetBucketId != null;
    const hadOffset = savedOffsetBucketId != null;

    if (hasOffset) {
      // TODO: we could skip this if the offset hasn't actually changed
      return UpsertBucketOffsetData.upsertBucketOffset(
        this.apollo,
        bucketId,
        offsetBucketId,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        offsetType!
      ).pipe(
        concatMap((response) => {
          // The bucket rate totals must be saved in the correct order for the offset to work correctly (bucket then offset bucket),
          // so we set them whenever an offset is changed.
          return this.setRateSliceTotals(
            bucketId,
            currentUserName,
            currentUserEmail
          ).pipe(
            concatMap(() => {
              return this.setRateSliceTotals(
                offsetBucketId,
                currentUserName,
                currentUserEmail
              );
            }),
            map(() => response)
          );
        })
      );
    }

    if (!hasOffset && hadOffset) {
      return from(
        trpcClient.rateSlice.deleteOffsetSlice.mutate({ rateSliceId: bucketId })
      ).pipe(
        concatMap(() =>
          this.setRateSliceTotals(
            savedOffsetBucketId,
            currentUserName,
            currentUserEmail
          )
        ),
        map(() => null)
      );
    }

    return of(null);
  }

  updateBucket(
    bucketId: number,
    newName: string,
    cmsBucketNumber: number | null,
    reserves: boolean,
    payeeId: number
  ): Observable<string> {
    return UpdateBucketData.updateBucket(
      this.apollo,
      bucketId,
      newName,
      cmsBucketNumber,
      reserves,
      payeeId
    ).pipe(map((res) => res.update_bucket_by_pk.name));
  }

  saveBucketBundleDiscounts(
    bucketId: number,
    bundleDiscounts: IBundledCoverageParameterDiscountVM[]
  ): Observable<SaveBucketBundleDiscountsResponse> {
    return SaveBucketBundleDiscountsData.saveBucketBundleDiscounts(
      this.apollo,
      bucketId,
      bundleDiscounts
    );
  }

  upsertBucketRateExceptions(
    exceptions: UpsertBucketRateException[]
  ): Observable<BucketRateExceptionResponse[]> {
    return UpsertBucketRateExceptionsData.upsertBucketRateExceptions(
      this.apollo,
      exceptions
    ).pipe(
      map((res): BucketRateExceptionResponse[] => {
        return res.insert_bucket_rate_exception.returning;
      })
    );
  }

  deleteBucketRateExceptions(
    productParameterKeyCombinationIds: number[]
  ): Observable<number> {
    return DeleteBucketRateExceptionsData.deleteBucketRateExceptions(
      this.apollo,
      productParameterKeyCombinationIds
    ).pipe(
      map((res) => {
        return res.affected_rows;
      })
    );
  }

  upsertBucketBaseRates(
    baseRates: UpsertBucketBaseRate[]
  ): Observable<BaseRateResponse[]> {
    return UpsertBucketBaseRatesData.upsertBucketBaseRates(
      this.apollo,
      baseRates
    );
  }

  deleteExceptionRates(bucketRateIds: number[]) {
    return DeleteBucketRateExceptionsData.deleteBucketRateExceptions(
      this.apollo,
      bucketRateIds
    ).pipe(map((_) => bucketRateIds));
  }

  saveFinalBucketRates(
    rateSliceId: number,
    currentUserName: string,
    currentUserEmail: string
  ) {
    return forkJoin([
      this.setRateSliceTotals(rateSliceId, currentUserName, currentUserEmail),
      trpcClient.rateSlice.setHasSavedRates.mutate({ rateSliceId }),
    ]);
  }

  // DEALER COST
  saveDealerCostRounding(
    isEnabled: boolean,
    productId: number,
    roundingId: number,
    roundingValue: number,
    roundingType: string,
    offsetBucketId: number
  ): Observable<DealerRoundingResponse | null> {
    return isEnabled
      ? UpsertDealerCostRoundingData.upsertDealerCostRounding(
          this.apollo,
          productId,
          roundingId,
          roundingValue,
          roundingType,
          offsetBucketId
        )
      : DeleteDealerCostRoundingData.deleteDealerCostRounding(
          this.apollo,
          productId
        ).pipe(map(() => null));
  }

  updateProductParameterKeyCombinations(
    productId: number,
    newParameterKeyIds: number[],
    deletedParameterKeyIds: number[],
    parameterCountChanged: boolean
  ): Observable<UpdateProductParameterKeyCombinationsResponse> {
    return UpdateProductParameterKeyCombinationsData.updateProductParameterKeyCombinations(
      this.apollo,
      productId,
      newParameterKeyIds,
      deletedParameterKeyIds,
      parameterCountChanged
    );
  }

  updateProductMsrpParameterKeyCombinations(
    productId: number,
    newParameterKeyIds: number[],
    deletedParameterKeyIds: number[],
    parameterCountChanged: boolean
  ): Observable<UpdateProductMsrpParameterKeyCombinationsResponse> {
    return UpdateProductMsrpParameterKeyCombinationsData.updateProductMsrpParameterKeyCombinations(
      this.apollo,
      productId,
      newParameterKeyIds,
      deletedParameterKeyIds,
      parameterCountChanged
    );
  }

  upsertProductSettings(
    productId: number,
    programId: number,
    productTypeId: number,
    name: string,
    description: string,
    code: string,
    riskType: string
  ): Observable<UpsertProductSettingsResponse> {
    return UpsertProductSettingsData.upsertProductSettings(
      this.apollo,
      productId,
      productTypeId,
      programId,
      name,
      description,
      code,
      riskType
    );
  }

  private setRateSliceTotals(
    rateSliceId: number,
    currentUserName: string,
    currentUserEmail: string
  ) {
    return from(
      trpcClient.rateSliceRates.setTotals.mutate({
        rateSliceId,
        currentUserName,
        currentUserEmail,
      })
    ).pipe(
      switchMap((job) => {
        return timer(0, 3000).pipe(
          exhaustMap(() => {
            return from(
              trpcClient.rateSliceRates.getSetTotalsStatus.query(job)
            ).pipe(
              map((job) => job.status),
              retry({ count: 3, delay: 500 }),
              catchError((_) => {
                // If we fail 3 times in a row, treat that as an error to avoid polling forever
                return of(JobStatus.Error);
              })
            );
          }),
          filter(
            (status) =>
              status === JobStatus.Complete || status === JobStatus.Error
          ),
          take(1)
        );
      }),
      tap((_) => console.log('after switchMap', _))
    );
  }
}

import { gql } from 'apollo-angular';

export const saveBucketBundleDiscountsMutation = gql`
  mutation SaveBucketBundleDiscounts(
    $discounts: [parameter_sub_key_bucket_discount_insert_input!]!
  ) {
    insert_parameter_sub_key_bucket_discount(
      objects: $discounts
      on_conflict: {
        constraint: parameter_sub_key_bucket_discount_pkey
        update_columns: [discount]
      }
    ) {
      affected_rows
    }
  }
`;

import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { uniqueValidator } from '@roadrunner/shared/util-forms';
import { AddPayeeDialogData } from './add-payee-dialog-data';
import { AddPayeeDialogResult } from './add-payee-dialog-result';

@Component({
  selector: 'rating-add-payee',
  templateUrl: './add-payee.component.html',
  styleUrls: ['./add-payee.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPayeeComponent {
  form: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: AddPayeeDialogData,
    private dialogRef: MatDialogRef<AddPayeeDialogData, AddPayeeDialogResult>
  ) {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, [
        Validators.required,
        uniqueValidator(data.existingPayeeNames),
      ]),
      code: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(6),
        uniqueValidator(data.existingPayeeCodes),
      ]),
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.dialogRef.close({
        name: this.form.value.name,
        code: this.form.value.code,
      });
    }
  }
}

// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetAllParameterTypesQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllParameterTypesQueryQuery = { parameterTypes: Array<{ id: number, description: string, type: string, bundleable: boolean }>, parameterSubtypes: Array<{ id: number, subtype: string, visible: boolean, parameterTypeId: number, controlType: string, isIdentifier: boolean, isUnique: boolean, isGlobalUnique: boolean, sortOrder: number, parameterSubtypeOptions: Array<{ id: number, name: string, parameterSubtypeId: number, sortOrder: number }> }> };

export const GetAllParameterTypesQueryDocument = gql`
    query GetAllParameterTypesQuery {
  parameterTypes: parameter_type {
    id
    description
    type
    bundleable
  }
  parameterSubtypes: parameter_subtype {
    id
    parameterTypeId: parameter_type_id
    subtype
    controlType: control_type
    visible
    isIdentifier: is_identifier
    isUnique: is_unique
    isGlobalUnique: is_global_unique
    sortOrder: sort_order
    parameterSubtypeOptions: parameter_subtype_options {
      id
      parameterSubtypeId: parameter_subtype_id
      name
      sortOrder: sort_order
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllParameterTypesQueryGQL extends Apollo.Query<GetAllParameterTypesQueryQuery, GetAllParameterTypesQueryQueryVariables> {
    override document = GetAllParameterTypesQueryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import * as Types from 'graphql/generated-client/gateway-schema.types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpsertProductParameterKeysMutationVariables = Types.Exact<{
  parameterKeyIdsToDelete: Array<Types.Scalars['bigint']> | Types.Scalars['bigint'];
  parameterKeys: Array<Types.Parameter_Key_Insert_Input> | Types.Parameter_Key_Insert_Input;
  insertParameterKeys: Types.Scalars['Boolean'];
  deleteParameterKeys: Types.Scalars['Boolean'];
  productId: Types.Scalars['bigint'];
}>;


export type UpsertProductParameterKeysMutation = { delete_non_sellable_combination?: { affected_rows: number } | null, delete_parameter_key?: { returning: Array<{ id: number }> } | null, delete_bucket_base_parameter: { affected_rows: number } | null, insert_parameter_key?: { returning: Array<{ id: number, key: string, parameterId: number }> } | null };

export type InsertParameterKeysReturningFieldsFragment = { id: number, key: string, parameterId: number };

export const InsertParameterKeysReturningFieldsFragmentDoc = gql`
    fragment InsertParameterKeysReturningFields on parameter_key {
  id
  key
  parameterId: parameter_id
}
    `;
export const UpsertProductParameterKeysDocument = gql`
    mutation UpsertProductParameterKeys($parameterKeyIdsToDelete: [bigint!]!, $parameterKeys: [parameter_key_insert_input!]!, $insertParameterKeys: Boolean!, $deleteParameterKeys: Boolean!, $productId: bigint!) {
  delete_non_sellable_combination(
    where: {_and: [{product_id: {_eq: $productId}}, {non_sellable_combination_options: {parameter_key_id: {_in: $parameterKeyIdsToDelete}}}]}
  ) @include(if: $deleteParameterKeys) {
    affected_rows
  }
  delete_parameter_key(where: {id: {_in: $parameterKeyIdsToDelete}}) @include(if: $deleteParameterKeys) {
    returning {
      id
    }
  }
  delete_bucket_base_parameter(
    where: {bucket: {product_id: {_eq: $productId}}, parameter: {_not: {parameter_keys: {product_id: {_eq: $productId}}}}}
  ) {
    affected_rows
  }
  insert_parameter_key(
    objects: $parameterKeys
    on_conflict: {constraint: parameter_key_pkey, update_columns: key}
  ) @include(if: $insertParameterKeys) {
    returning {
      ...InsertParameterKeysReturningFields
    }
  }
}
    ${InsertParameterKeysReturningFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpsertProductParameterKeysGQL extends Apollo.Mutation<UpsertProductParameterKeysMutation, UpsertProductParameterKeysMutationVariables> {
    override document = UpsertProductParameterKeysDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
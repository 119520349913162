// THIS FILE IS GENERATED, DO NOT EDIT!
/* eslint-disable */
import { DateTime } from 'luxon'
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: DateTime | string;
  /** GraphQL Scalar representing the Prisma.Decimal type, based on Decimal.js library. */
  Decimal: any;
  _int8: any;
  bigint: number;
  date: any;
  json: any;
  jsonb: any;
  numeric: number;
  smallint: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

export type AggregateCommissionPayee = {
  __typename?: 'AggregateCommissionPayee';
  _avg?: Maybe<CommissionPayeeAvgAggregate>;
  _count?: Maybe<CommissionPayeeCountAggregate>;
  _max?: Maybe<CommissionPayeeMaxAggregate>;
  _min?: Maybe<CommissionPayeeMinAggregate>;
  _sum?: Maybe<CommissionPayeeSumAggregate>;
};

export type AggregateCommissionType = {
  __typename?: 'AggregateCommissionType';
  _avg?: Maybe<CommissionTypeAvgAggregate>;
  _count?: Maybe<CommissionTypeCountAggregate>;
  _max?: Maybe<CommissionTypeMaxAggregate>;
  _min?: Maybe<CommissionTypeMinAggregate>;
  _sum?: Maybe<CommissionTypeSumAggregate>;
};

export type AggregateCommissionTypeParameter = {
  __typename?: 'AggregateCommissionTypeParameter';
  _avg?: Maybe<CommissionTypeParameterAvgAggregate>;
  _count?: Maybe<CommissionTypeParameterCountAggregate>;
  _max?: Maybe<CommissionTypeParameterMaxAggregate>;
  _min?: Maybe<CommissionTypeParameterMinAggregate>;
  _sum?: Maybe<CommissionTypeParameterSumAggregate>;
};

export type AggregateDealer = {
  __typename?: 'AggregateDealer';
  _avg?: Maybe<DealerAvgAggregate>;
  _count?: Maybe<DealerCountAggregate>;
  _max?: Maybe<DealerMaxAggregate>;
  _min?: Maybe<DealerMinAggregate>;
  _sum?: Maybe<DealerSumAggregate>;
};

export type AggregateDealerChargeback = {
  __typename?: 'AggregateDealerChargeback';
  _avg?: Maybe<DealerChargebackAvgAggregate>;
  _count?: Maybe<DealerChargebackCountAggregate>;
  _max?: Maybe<DealerChargebackMaxAggregate>;
  _min?: Maybe<DealerChargebackMinAggregate>;
  _sum?: Maybe<DealerChargebackSumAggregate>;
};

export type AggregateDealerProduct = {
  __typename?: 'AggregateDealerProduct';
  _avg?: Maybe<DealerProductAvgAggregate>;
  _count?: Maybe<DealerProductCountAggregate>;
  _max?: Maybe<DealerProductMaxAggregate>;
  _min?: Maybe<DealerProductMinAggregate>;
  _sum?: Maybe<DealerProductSumAggregate>;
};

export type AggregateDealerTier = {
  __typename?: 'AggregateDealerTier';
  _avg?: Maybe<DealerTierAvgAggregate>;
  _count?: Maybe<DealerTierCountAggregate>;
  _max?: Maybe<DealerTierMaxAggregate>;
  _min?: Maybe<DealerTierMinAggregate>;
  _sum?: Maybe<DealerTierSumAggregate>;
};

export type AggregateEntityCommission = {
  __typename?: 'AggregateEntityCommission';
  _avg?: Maybe<EntityCommissionAvgAggregate>;
  _count?: Maybe<EntityCommissionCountAggregate>;
  _max?: Maybe<EntityCommissionMaxAggregate>;
  _min?: Maybe<EntityCommissionMinAggregate>;
  _sum?: Maybe<EntityCommissionSumAggregate>;
};

export type AggregateEntityCommissionParameterKey = {
  __typename?: 'AggregateEntityCommissionParameterKey';
  _avg?: Maybe<EntityCommissionParameterKeyAvgAggregate>;
  _count?: Maybe<EntityCommissionParameterKeyCountAggregate>;
  _max?: Maybe<EntityCommissionParameterKeyMaxAggregate>;
  _min?: Maybe<EntityCommissionParameterKeyMinAggregate>;
  _sum?: Maybe<EntityCommissionParameterKeySumAggregate>;
};

export type AggregateEntityProgram = {
  __typename?: 'AggregateEntityProgram';
  _avg?: Maybe<EntityProgramAvgAggregate>;
  _count?: Maybe<EntityProgramCountAggregate>;
  _max?: Maybe<EntityProgramMaxAggregate>;
  _min?: Maybe<EntityProgramMinAggregate>;
  _sum?: Maybe<EntityProgramSumAggregate>;
};

export type AggregateNcbPercentage = {
  __typename?: 'AggregateNcbPercentage';
  _avg?: Maybe<NcbPercentageAvgAggregate>;
  _count?: Maybe<NcbPercentageCountAggregate>;
  _max?: Maybe<NcbPercentageMaxAggregate>;
  _min?: Maybe<NcbPercentageMinAggregate>;
  _sum?: Maybe<NcbPercentageSumAggregate>;
};

export type AggregatePaymentType = {
  __typename?: 'AggregatePaymentType';
  _avg?: Maybe<PaymentTypeAvgAggregate>;
  _count?: Maybe<PaymentTypeCountAggregate>;
  _max?: Maybe<PaymentTypeMaxAggregate>;
  _min?: Maybe<PaymentTypeMinAggregate>;
  _sum?: Maybe<PaymentTypeSumAggregate>;
};

export type AggregateProgramProductEnrollment = {
  __typename?: 'AggregateProgramProductEnrollment';
  _avg?: Maybe<ProgramProductEnrollmentAvgAggregate>;
  _count?: Maybe<ProgramProductEnrollmentCountAggregate>;
  _max?: Maybe<ProgramProductEnrollmentMaxAggregate>;
  _min?: Maybe<ProgramProductEnrollmentMinAggregate>;
  _sum?: Maybe<ProgramProductEnrollmentSumAggregate>;
};

export type AggregateProgramProductEnrollmentDealerTier = {
  __typename?: 'AggregateProgramProductEnrollmentDealerTier';
  _avg?: Maybe<ProgramProductEnrollmentDealerTierAvgAggregate>;
  _count?: Maybe<ProgramProductEnrollmentDealerTierCountAggregate>;
  _max?: Maybe<ProgramProductEnrollmentDealerTierMaxAggregate>;
  _min?: Maybe<ProgramProductEnrollmentDealerTierMinAggregate>;
  _sum?: Maybe<ProgramProductEnrollmentDealerTierSumAggregate>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CommissionPayee = {
  __typename?: 'CommissionPayee';
  _count?: Maybe<CommissionPayeeCount>;
  createdAt: Scalars['DateTime'];
  entityCommissions: Array<EntityCommission>;
  id: Scalars['Int'];
  payeeCode: Scalars['String'];
  payeeName: Scalars['String'];
  paymentType: PaymentType;
  paymentTypeId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CommissionPayeeEntityCommissionsArgs = {
  cursor?: InputMaybe<EntityCommissionWhereUniqueInput>;
  distinct?: InputMaybe<Array<EntityCommissionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EntityCommissionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntityCommissionWhereInput>;
};

export type CommissionPayeeAvgAggregate = {
  __typename?: 'CommissionPayeeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  paymentTypeId?: Maybe<Scalars['Float']>;
};

export type CommissionPayeeAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  paymentTypeId?: InputMaybe<SortOrder>;
};

export type CommissionPayeeCount = {
  __typename?: 'CommissionPayeeCount';
  entityCommissions: Scalars['Int'];
};

export type CommissionPayeeCountAggregate = {
  __typename?: 'CommissionPayeeCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  payeeCode: Scalars['Int'];
  payeeName: Scalars['Int'];
  paymentTypeId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CommissionPayeeCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  payeeCode?: InputMaybe<SortOrder>;
  payeeName?: InputMaybe<SortOrder>;
  paymentTypeId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CommissionPayeeGroupBy = {
  __typename?: 'CommissionPayeeGroupBy';
  _avg?: Maybe<CommissionPayeeAvgAggregate>;
  _count?: Maybe<CommissionPayeeCountAggregate>;
  _max?: Maybe<CommissionPayeeMaxAggregate>;
  _min?: Maybe<CommissionPayeeMinAggregate>;
  _sum?: Maybe<CommissionPayeeSumAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  payeeCode: Scalars['String'];
  payeeName: Scalars['String'];
  paymentTypeId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommissionPayeeListRelationFilter = {
  every?: InputMaybe<CommissionPayeeWhereInput>;
  none?: InputMaybe<CommissionPayeeWhereInput>;
  some?: InputMaybe<CommissionPayeeWhereInput>;
};

export type CommissionPayeeMaxAggregate = {
  __typename?: 'CommissionPayeeMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  payeeCode?: Maybe<Scalars['String']>;
  payeeName?: Maybe<Scalars['String']>;
  paymentTypeId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommissionPayeeMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  payeeCode?: InputMaybe<SortOrder>;
  payeeName?: InputMaybe<SortOrder>;
  paymentTypeId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CommissionPayeeMinAggregate = {
  __typename?: 'CommissionPayeeMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  payeeCode?: Maybe<Scalars['String']>;
  payeeName?: Maybe<Scalars['String']>;
  paymentTypeId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommissionPayeeMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  payeeCode?: InputMaybe<SortOrder>;
  payeeName?: InputMaybe<SortOrder>;
  paymentTypeId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CommissionPayeeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CommissionPayeeOrderByWithAggregationInput = {
  _avg?: InputMaybe<CommissionPayeeAvgOrderByAggregateInput>;
  _count?: InputMaybe<CommissionPayeeCountOrderByAggregateInput>;
  _max?: InputMaybe<CommissionPayeeMaxOrderByAggregateInput>;
  _min?: InputMaybe<CommissionPayeeMinOrderByAggregateInput>;
  _sum?: InputMaybe<CommissionPayeeSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  payeeCode?: InputMaybe<SortOrder>;
  payeeName?: InputMaybe<SortOrder>;
  paymentTypeId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CommissionPayeeOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  entityCommissions?: InputMaybe<EntityCommissionOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  payeeCode?: InputMaybe<SortOrder>;
  payeeName?: InputMaybe<SortOrder>;
  paymentType?: InputMaybe<PaymentTypeOrderByWithRelationInput>;
  paymentTypeId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CommissionPayeeRelationFilter = {
  is?: InputMaybe<CommissionPayeeWhereInput>;
  isNot?: InputMaybe<CommissionPayeeWhereInput>;
};

export enum CommissionPayeeScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  PayeeCode = 'payeeCode',
  PayeeName = 'payeeName',
  PaymentTypeId = 'paymentTypeId',
  UpdatedAt = 'updatedAt'
}

export type CommissionPayeeScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CommissionPayeeScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CommissionPayeeScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CommissionPayeeScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  payeeCode?: InputMaybe<StringWithAggregatesFilter>;
  payeeName?: InputMaybe<StringWithAggregatesFilter>;
  paymentTypeId?: InputMaybe<IntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type CommissionPayeeSumAggregate = {
  __typename?: 'CommissionPayeeSumAggregate';
  id?: Maybe<Scalars['Int']>;
  paymentTypeId?: Maybe<Scalars['Int']>;
};

export type CommissionPayeeSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  paymentTypeId?: InputMaybe<SortOrder>;
};

export type CommissionPayeeWhereInput = {
  AND?: InputMaybe<Array<CommissionPayeeWhereInput>>;
  NOT?: InputMaybe<Array<CommissionPayeeWhereInput>>;
  OR?: InputMaybe<Array<CommissionPayeeWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  entityCommissions?: InputMaybe<EntityCommissionListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  payeeCode?: InputMaybe<StringFilter>;
  payeeName?: InputMaybe<StringFilter>;
  paymentType?: InputMaybe<PaymentTypeRelationFilter>;
  paymentTypeId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type CommissionPayeeWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  payeeCode?: InputMaybe<Scalars['String']>;
};

export type CommissionType = {
  __typename?: 'CommissionType';
  _count?: Maybe<CommissionTypeCount>;
  commissionTypeParameters: Array<CommissionTypeParameter>;
  createdAt: Scalars['DateTime'];
  dealers: Array<Dealer>;
  description: Scalars['String'];
  entityCommissions: Array<EntityCommission>;
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CommissionTypeCommissionTypeParametersArgs = {
  cursor?: InputMaybe<CommissionTypeParameterWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommissionTypeParameterScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommissionTypeParameterOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommissionTypeParameterWhereInput>;
};


export type CommissionTypeDealersArgs = {
  cursor?: InputMaybe<DealerWhereUniqueInput>;
  distinct?: InputMaybe<Array<DealerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DealerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DealerWhereInput>;
};


export type CommissionTypeEntityCommissionsArgs = {
  cursor?: InputMaybe<EntityCommissionWhereUniqueInput>;
  distinct?: InputMaybe<Array<EntityCommissionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EntityCommissionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntityCommissionWhereInput>;
};

export type CommissionTypeAvgAggregate = {
  __typename?: 'CommissionTypeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type CommissionTypeAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
};

export type CommissionTypeCount = {
  __typename?: 'CommissionTypeCount';
  commissionTypeParameters: Scalars['Int'];
  dealers: Scalars['Int'];
  entityCommissions: Scalars['Int'];
};

export type CommissionTypeCountAggregate = {
  __typename?: 'CommissionTypeCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CommissionTypeCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CommissionTypeGroupBy = {
  __typename?: 'CommissionTypeGroupBy';
  _avg?: Maybe<CommissionTypeAvgAggregate>;
  _count?: Maybe<CommissionTypeCountAggregate>;
  _max?: Maybe<CommissionTypeMaxAggregate>;
  _min?: Maybe<CommissionTypeMinAggregate>;
  _sum?: Maybe<CommissionTypeSumAggregate>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommissionTypeMaxAggregate = {
  __typename?: 'CommissionTypeMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommissionTypeMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CommissionTypeMinAggregate = {
  __typename?: 'CommissionTypeMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommissionTypeMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CommissionTypeOrderByWithAggregationInput = {
  _avg?: InputMaybe<CommissionTypeAvgOrderByAggregateInput>;
  _count?: InputMaybe<CommissionTypeCountOrderByAggregateInput>;
  _max?: InputMaybe<CommissionTypeMaxOrderByAggregateInput>;
  _min?: InputMaybe<CommissionTypeMinOrderByAggregateInput>;
  _sum?: InputMaybe<CommissionTypeSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CommissionTypeOrderByWithRelationInput = {
  commissionTypeParameters?: InputMaybe<CommissionTypeParameterOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  dealers?: InputMaybe<DealerOrderByRelationAggregateInput>;
  description?: InputMaybe<SortOrder>;
  entityCommissions?: InputMaybe<EntityCommissionOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CommissionTypeParameter = {
  __typename?: 'CommissionTypeParameter';
  _count?: Maybe<CommissionTypeParameterCount>;
  commissionType: CommissionType;
  commissionTypeId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  entityCommissionParameterKeys: Array<EntityCommissionParameterKey>;
  id: Scalars['Int'];
  parameterName: Scalars['String'];
};


export type CommissionTypeParameterEntityCommissionParameterKeysArgs = {
  cursor?: InputMaybe<EntityCommissionParameterKeyWhereUniqueInput>;
  distinct?: InputMaybe<Array<EntityCommissionParameterKeyScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EntityCommissionParameterKeyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntityCommissionParameterKeyWhereInput>;
};

export type CommissionTypeParameterAvgAggregate = {
  __typename?: 'CommissionTypeParameterAvgAggregate';
  commissionTypeId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type CommissionTypeParameterAvgOrderByAggregateInput = {
  commissionTypeId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type CommissionTypeParameterCount = {
  __typename?: 'CommissionTypeParameterCount';
  entityCommissionParameterKeys: Scalars['Int'];
};

export type CommissionTypeParameterCountAggregate = {
  __typename?: 'CommissionTypeParameterCountAggregate';
  _all: Scalars['Int'];
  commissionTypeId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  parameterName: Scalars['Int'];
};

export type CommissionTypeParameterCountOrderByAggregateInput = {
  commissionTypeId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  parameterName?: InputMaybe<SortOrder>;
};

export type CommissionTypeParameterGroupBy = {
  __typename?: 'CommissionTypeParameterGroupBy';
  _avg?: Maybe<CommissionTypeParameterAvgAggregate>;
  _count?: Maybe<CommissionTypeParameterCountAggregate>;
  _max?: Maybe<CommissionTypeParameterMaxAggregate>;
  _min?: Maybe<CommissionTypeParameterMinAggregate>;
  _sum?: Maybe<CommissionTypeParameterSumAggregate>;
  commissionTypeId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  parameterName: Scalars['String'];
};

export type CommissionTypeParameterListRelationFilter = {
  every?: InputMaybe<CommissionTypeParameterWhereInput>;
  none?: InputMaybe<CommissionTypeParameterWhereInput>;
  some?: InputMaybe<CommissionTypeParameterWhereInput>;
};

export type CommissionTypeParameterMaxAggregate = {
  __typename?: 'CommissionTypeParameterMaxAggregate';
  commissionTypeId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  parameterName?: Maybe<Scalars['String']>;
};

export type CommissionTypeParameterMaxOrderByAggregateInput = {
  commissionTypeId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  parameterName?: InputMaybe<SortOrder>;
};

export type CommissionTypeParameterMinAggregate = {
  __typename?: 'CommissionTypeParameterMinAggregate';
  commissionTypeId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  parameterName?: Maybe<Scalars['String']>;
};

export type CommissionTypeParameterMinOrderByAggregateInput = {
  commissionTypeId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  parameterName?: InputMaybe<SortOrder>;
};

export type CommissionTypeParameterOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CommissionTypeParameterOrderByWithAggregationInput = {
  _avg?: InputMaybe<CommissionTypeParameterAvgOrderByAggregateInput>;
  _count?: InputMaybe<CommissionTypeParameterCountOrderByAggregateInput>;
  _max?: InputMaybe<CommissionTypeParameterMaxOrderByAggregateInput>;
  _min?: InputMaybe<CommissionTypeParameterMinOrderByAggregateInput>;
  _sum?: InputMaybe<CommissionTypeParameterSumOrderByAggregateInput>;
  commissionTypeId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  parameterName?: InputMaybe<SortOrder>;
};

export type CommissionTypeParameterOrderByWithRelationInput = {
  commissionType?: InputMaybe<CommissionTypeOrderByWithRelationInput>;
  commissionTypeId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  entityCommissionParameterKeys?: InputMaybe<EntityCommissionParameterKeyOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  parameterName?: InputMaybe<SortOrder>;
};

export type CommissionTypeParameterRelationFilter = {
  is?: InputMaybe<CommissionTypeParameterWhereInput>;
  isNot?: InputMaybe<CommissionTypeParameterWhereInput>;
};

export enum CommissionTypeParameterScalarFieldEnum {
  CommissionTypeId = 'commissionTypeId',
  CreatedAt = 'createdAt',
  Id = 'id',
  ParameterName = 'parameterName'
}

export type CommissionTypeParameterScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CommissionTypeParameterScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CommissionTypeParameterScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CommissionTypeParameterScalarWhereWithAggregatesInput>>;
  commissionTypeId?: InputMaybe<IntWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  parameterName?: InputMaybe<StringWithAggregatesFilter>;
};

export type CommissionTypeParameterSumAggregate = {
  __typename?: 'CommissionTypeParameterSumAggregate';
  commissionTypeId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type CommissionTypeParameterSumOrderByAggregateInput = {
  commissionTypeId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type CommissionTypeParameterWhereInput = {
  AND?: InputMaybe<Array<CommissionTypeParameterWhereInput>>;
  NOT?: InputMaybe<Array<CommissionTypeParameterWhereInput>>;
  OR?: InputMaybe<Array<CommissionTypeParameterWhereInput>>;
  commissionType?: InputMaybe<CommissionTypeRelationFilter>;
  commissionTypeId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  entityCommissionParameterKeys?: InputMaybe<EntityCommissionParameterKeyListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  parameterName?: InputMaybe<StringFilter>;
};

export type CommissionTypeParameterWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type CommissionTypeRelationFilter = {
  is?: InputMaybe<CommissionTypeWhereInput>;
  isNot?: InputMaybe<CommissionTypeWhereInput>;
};

export enum CommissionTypeScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type CommissionTypeScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CommissionTypeScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CommissionTypeScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CommissionTypeScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type CommissionTypeSumAggregate = {
  __typename?: 'CommissionTypeSumAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type CommissionTypeSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
};

export type CommissionTypeWhereInput = {
  AND?: InputMaybe<Array<CommissionTypeWhereInput>>;
  NOT?: InputMaybe<Array<CommissionTypeWhereInput>>;
  OR?: InputMaybe<Array<CommissionTypeWhereInput>>;
  commissionTypeParameters?: InputMaybe<CommissionTypeParameterListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dealers?: InputMaybe<DealerListRelationFilter>;
  description?: InputMaybe<StringFilter>;
  entityCommissions?: InputMaybe<EntityCommissionListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type CommissionTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreateDealerChargebackInput = {
  chargeback: Scalars['Boolean'];
  dealerId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type Dealer = {
  __typename?: 'Dealer';
  _count?: Maybe<DealerCount>;
  addressOne?: Maybe<Scalars['String']>;
  addressTwo?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  commissionType?: Maybe<CommissionType>;
  commissionTypeId?: Maybe<Scalars['Int']>;
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  dealerChargebacks: Array<DealerChargeback>;
  dealerProducts: Array<DealerProduct>;
  dirty: Scalars['Boolean'];
  endDate?: Maybe<Scalars['DateTime']>;
  entityCommissions: Array<EntityCommission>;
  entityPrograms: Array<EntityProgram>;
  highRisk: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  outOfBusinessDate?: Maybe<Scalars['DateTime']>;
  postalCode?: Maybe<Scalars['String']>;
  publishEntitys: Array<PublishEntity>;
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  publishedOutOfBusinessDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  state: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type DealerDealerChargebacksArgs = {
  cursor?: InputMaybe<DealerChargebackWhereUniqueInput>;
  distinct?: InputMaybe<Array<DealerChargebackScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DealerChargebackOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DealerChargebackWhereInput>;
};


export type DealerDealerProductsArgs = {
  cursor?: InputMaybe<DealerProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<DealerProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DealerProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DealerProductWhereInput>;
};


export type DealerEntityCommissionsArgs = {
  cursor?: InputMaybe<EntityCommissionWhereUniqueInput>;
  distinct?: InputMaybe<Array<EntityCommissionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EntityCommissionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntityCommissionWhereInput>;
};


export type DealerEntityProgramsArgs = {
  cursor?: InputMaybe<EntityProgramWhereUniqueInput>;
  distinct?: InputMaybe<Array<EntityProgramScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EntityProgramOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntityProgramWhereInput>;
};


export type DealerPublishEntitysArgs = {
  cursor?: InputMaybe<PublishEntityWhereUniqueInput>;
  distinct?: InputMaybe<Array<PublishEntityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PublishEntityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PublishEntityWhereInput>;
};

export type DealerAvgAggregate = {
  __typename?: 'DealerAvgAggregate';
  commissionTypeId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type DealerAvgOrderByAggregateInput = {
  commissionTypeId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type DealerChargeback = {
  __typename?: 'DealerChargeback';
  chargeback: Scalars['Boolean'];
  dealer: Dealer;
  dealerId: Scalars['Int'];
  deleted: Scalars['Boolean'];
  dirty: Scalars['Boolean'];
  endDate?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id: Scalars['Int'];
  lastPublishDate?: Maybe<Scalars['DateTime']>;
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  publishedStartDate?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
};

export type DealerChargebackAvgAggregate = {
  __typename?: 'DealerChargebackAvgAggregate';
  dealerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type DealerChargebackAvgOrderByAggregateInput = {
  dealerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type DealerChargebackCountAggregate = {
  __typename?: 'DealerChargebackCountAggregate';
  _all: Scalars['Int'];
  chargeback: Scalars['Int'];
  dealerId: Scalars['Int'];
  deleted: Scalars['Int'];
  dirty: Scalars['Int'];
  endDate: Scalars['Int'];
  externalId: Scalars['Int'];
  id: Scalars['Int'];
  lastPublishDate: Scalars['Int'];
  publishedEndDate: Scalars['Int'];
  publishedStartDate: Scalars['Int'];
  startDate: Scalars['Int'];
};

export type DealerChargebackCountOrderByAggregateInput = {
  chargeback?: InputMaybe<SortOrder>;
  dealerId?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  dirty?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  publishedStartDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type DealerChargebackGroupBy = {
  __typename?: 'DealerChargebackGroupBy';
  _avg?: Maybe<DealerChargebackAvgAggregate>;
  _count?: Maybe<DealerChargebackCountAggregate>;
  _max?: Maybe<DealerChargebackMaxAggregate>;
  _min?: Maybe<DealerChargebackMinAggregate>;
  _sum?: Maybe<DealerChargebackSumAggregate>;
  chargeback: Scalars['Boolean'];
  dealerId: Scalars['Int'];
  deleted: Scalars['Boolean'];
  dirty: Scalars['Boolean'];
  endDate?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id: Scalars['Int'];
  lastPublishDate?: Maybe<Scalars['DateTime']>;
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  publishedStartDate?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
};

export type DealerChargebackListRelationFilter = {
  every?: InputMaybe<DealerChargebackWhereInput>;
  none?: InputMaybe<DealerChargebackWhereInput>;
  some?: InputMaybe<DealerChargebackWhereInput>;
};

export type DealerChargebackMaxAggregate = {
  __typename?: 'DealerChargebackMaxAggregate';
  chargeback?: Maybe<Scalars['Boolean']>;
  dealerId?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Boolean']>;
  dirty?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastPublishDate?: Maybe<Scalars['DateTime']>;
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  publishedStartDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type DealerChargebackMaxOrderByAggregateInput = {
  chargeback?: InputMaybe<SortOrder>;
  dealerId?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  dirty?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  publishedStartDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type DealerChargebackMinAggregate = {
  __typename?: 'DealerChargebackMinAggregate';
  chargeback?: Maybe<Scalars['Boolean']>;
  dealerId?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Boolean']>;
  dirty?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastPublishDate?: Maybe<Scalars['DateTime']>;
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  publishedStartDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type DealerChargebackMinOrderByAggregateInput = {
  chargeback?: InputMaybe<SortOrder>;
  dealerId?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  dirty?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  publishedStartDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type DealerChargebackOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DealerChargebackOrderByWithAggregationInput = {
  _avg?: InputMaybe<DealerChargebackAvgOrderByAggregateInput>;
  _count?: InputMaybe<DealerChargebackCountOrderByAggregateInput>;
  _max?: InputMaybe<DealerChargebackMaxOrderByAggregateInput>;
  _min?: InputMaybe<DealerChargebackMinOrderByAggregateInput>;
  _sum?: InputMaybe<DealerChargebackSumOrderByAggregateInput>;
  chargeback?: InputMaybe<SortOrder>;
  dealerId?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  dirty?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  publishedStartDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type DealerChargebackOrderByWithRelationInput = {
  chargeback?: InputMaybe<SortOrder>;
  dealer?: InputMaybe<DealerOrderByWithRelationInput>;
  dealerId?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  dirty?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  publishedStartDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export enum DealerChargebackScalarFieldEnum {
  Chargeback = 'chargeback',
  DealerId = 'dealerId',
  Deleted = 'deleted',
  Dirty = 'dirty',
  EndDate = 'endDate',
  ExternalId = 'externalId',
  Id = 'id',
  LastPublishDate = 'lastPublishDate',
  PublishedEndDate = 'publishedEndDate',
  PublishedStartDate = 'publishedStartDate',
  StartDate = 'startDate'
}

export type DealerChargebackScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<DealerChargebackScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<DealerChargebackScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<DealerChargebackScalarWhereWithAggregatesInput>>;
  chargeback?: InputMaybe<BoolWithAggregatesFilter>;
  dealerId?: InputMaybe<IntWithAggregatesFilter>;
  deleted?: InputMaybe<BoolWithAggregatesFilter>;
  dirty?: InputMaybe<BoolWithAggregatesFilter>;
  endDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  externalId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  lastPublishDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  publishedEndDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  publishedStartDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  startDate?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type DealerChargebackSumAggregate = {
  __typename?: 'DealerChargebackSumAggregate';
  dealerId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type DealerChargebackSumOrderByAggregateInput = {
  dealerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type DealerChargebackWhereInput = {
  AND?: InputMaybe<Array<DealerChargebackWhereInput>>;
  NOT?: InputMaybe<Array<DealerChargebackWhereInput>>;
  OR?: InputMaybe<Array<DealerChargebackWhereInput>>;
  chargeback?: InputMaybe<BoolFilter>;
  dealer?: InputMaybe<DealerRelationFilter>;
  dealerId?: InputMaybe<IntFilter>;
  deleted?: InputMaybe<BoolFilter>;
  dirty?: InputMaybe<BoolFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  externalId?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  lastPublishDate?: InputMaybe<DateTimeNullableFilter>;
  publishedEndDate?: InputMaybe<DateTimeNullableFilter>;
  publishedStartDate?: InputMaybe<DateTimeNullableFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
};

export type DealerChargebackWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type DealerCount = {
  __typename?: 'DealerCount';
  dealerChargebacks: Scalars['Int'];
  dealerProducts: Scalars['Int'];
  entityCommissions: Scalars['Int'];
  entityPrograms: Scalars['Int'];
  publishEntitys: Scalars['Int'];
};

export type DealerCountAggregate = {
  __typename?: 'DealerCountAggregate';
  _all: Scalars['Int'];
  addressOne: Scalars['Int'];
  addressTwo: Scalars['Int'];
  city: Scalars['Int'];
  code: Scalars['Int'];
  commissionTypeId: Scalars['Int'];
  country: Scalars['Int'];
  createdAt: Scalars['Int'];
  dirty: Scalars['Int'];
  endDate: Scalars['Int'];
  highRisk: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  outOfBusinessDate: Scalars['Int'];
  postalCode: Scalars['Int'];
  publishedEndDate: Scalars['Int'];
  publishedOutOfBusinessDate: Scalars['Int'];
  startDate: Scalars['Int'];
  state: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type DealerCountOrderByAggregateInput = {
  addressOne?: InputMaybe<SortOrder>;
  addressTwo?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  commissionTypeId?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dirty?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  highRisk?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  outOfBusinessDate?: InputMaybe<SortOrder>;
  postalCode?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  publishedOutOfBusinessDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DealerGroupBy = {
  __typename?: 'DealerGroupBy';
  _avg?: Maybe<DealerAvgAggregate>;
  _count?: Maybe<DealerCountAggregate>;
  _max?: Maybe<DealerMaxAggregate>;
  _min?: Maybe<DealerMinAggregate>;
  _sum?: Maybe<DealerSumAggregate>;
  addressOne?: Maybe<Scalars['String']>;
  addressTwo?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  commissionTypeId?: Maybe<Scalars['Int']>;
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  dirty: Scalars['Boolean'];
  endDate?: Maybe<Scalars['DateTime']>;
  highRisk: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  outOfBusinessDate?: Maybe<Scalars['DateTime']>;
  postalCode?: Maybe<Scalars['String']>;
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  publishedOutOfBusinessDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  state: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DealerListRelationFilter = {
  every?: InputMaybe<DealerWhereInput>;
  none?: InputMaybe<DealerWhereInput>;
  some?: InputMaybe<DealerWhereInput>;
};

export type DealerMaxAggregate = {
  __typename?: 'DealerMaxAggregate';
  addressOne?: Maybe<Scalars['String']>;
  addressTwo?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  commissionTypeId?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dirty?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTime']>;
  highRisk?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  outOfBusinessDate?: Maybe<Scalars['DateTime']>;
  postalCode?: Maybe<Scalars['String']>;
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  publishedOutOfBusinessDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DealerMaxOrderByAggregateInput = {
  addressOne?: InputMaybe<SortOrder>;
  addressTwo?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  commissionTypeId?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dirty?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  highRisk?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  outOfBusinessDate?: InputMaybe<SortOrder>;
  postalCode?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  publishedOutOfBusinessDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DealerMinAggregate = {
  __typename?: 'DealerMinAggregate';
  addressOne?: Maybe<Scalars['String']>;
  addressTwo?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  commissionTypeId?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dirty?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTime']>;
  highRisk?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  outOfBusinessDate?: Maybe<Scalars['DateTime']>;
  postalCode?: Maybe<Scalars['String']>;
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  publishedOutOfBusinessDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DealerMinOrderByAggregateInput = {
  addressOne?: InputMaybe<SortOrder>;
  addressTwo?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  commissionTypeId?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dirty?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  highRisk?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  outOfBusinessDate?: InputMaybe<SortOrder>;
  postalCode?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  publishedOutOfBusinessDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DealerOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DealerOrderByWithAggregationInput = {
  _avg?: InputMaybe<DealerAvgOrderByAggregateInput>;
  _count?: InputMaybe<DealerCountOrderByAggregateInput>;
  _max?: InputMaybe<DealerMaxOrderByAggregateInput>;
  _min?: InputMaybe<DealerMinOrderByAggregateInput>;
  _sum?: InputMaybe<DealerSumOrderByAggregateInput>;
  addressOne?: InputMaybe<SortOrder>;
  addressTwo?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  commissionTypeId?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dirty?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  highRisk?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  outOfBusinessDate?: InputMaybe<SortOrder>;
  postalCode?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  publishedOutOfBusinessDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DealerOrderByWithRelationInput = {
  addressOne?: InputMaybe<SortOrder>;
  addressTwo?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  commissionType?: InputMaybe<CommissionTypeOrderByWithRelationInput>;
  commissionTypeId?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dealerChargebacks?: InputMaybe<DealerChargebackOrderByRelationAggregateInput>;
  dealerProducts?: InputMaybe<DealerProductOrderByRelationAggregateInput>;
  dirty?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  entityCommissions?: InputMaybe<EntityCommissionOrderByRelationAggregateInput>;
  entityPrograms?: InputMaybe<EntityProgramOrderByRelationAggregateInput>;
  highRisk?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  outOfBusinessDate?: InputMaybe<SortOrder>;
  postalCode?: InputMaybe<SortOrder>;
  publishEntitys?: InputMaybe<PublishEntityOrderByRelationAggregateInput>;
  publishedEndDate?: InputMaybe<SortOrder>;
  publishedOutOfBusinessDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DealerProduct = {
  __typename?: 'DealerProduct';
  _count?: Maybe<DealerProductCount>;
  dealer: Dealer;
  dealerId: Scalars['Int'];
  dealerProductCompliances: Array<DealerProductCompliance>;
  dealerTiers: Array<DealerTier>;
  endDate?: Maybe<Scalars['DateTime']>;
  enrollmentReceiptDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  lastPublishDate?: Maybe<Scalars['DateTime']>;
  productCode: Scalars['String'];
  programAgentCode: Scalars['String'];
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
};


export type DealerProductDealerProductCompliancesArgs = {
  cursor?: InputMaybe<DealerProductComplianceWhereUniqueInput>;
  distinct?: InputMaybe<Array<DealerProductComplianceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DealerProductComplianceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DealerProductComplianceWhereInput>;
};


export type DealerProductDealerTiersArgs = {
  cursor?: InputMaybe<DealerTierWhereUniqueInput>;
  distinct?: InputMaybe<Array<DealerTierScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DealerTierOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DealerTierWhereInput>;
};

export type DealerProductAvgAggregate = {
  __typename?: 'DealerProductAvgAggregate';
  dealerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type DealerProductAvgOrderByAggregateInput = {
  dealerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type DealerProductCompliance = {
  __typename?: 'DealerProductCompliance';
  dealerProductId: Scalars['Int'];
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  restrictByDefault: Scalars['Boolean'];
  startDate?: Maybe<Scalars['DateTime']>;
};

export type DealerProductComplianceListRelationFilter = {
  every?: InputMaybe<DealerProductComplianceWhereInput>;
  none?: InputMaybe<DealerProductComplianceWhereInput>;
  some?: InputMaybe<DealerProductComplianceWhereInput>;
};

export type DealerProductComplianceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DealerProductComplianceOrderByWithRelationInput = {
  dealerProduct?: InputMaybe<DealerProductOrderByWithRelationInput>;
  dealerProductId?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  restrictByDefault?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export enum DealerProductComplianceScalarFieldEnum {
  DealerProductId = 'dealerProductId',
  EndDate = 'endDate',
  Id = 'id',
  RestrictByDefault = 'restrictByDefault',
  StartDate = 'startDate'
}

export type DealerProductComplianceUpsert = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  restrictByDefault: Scalars['Boolean'];
  startDate: Scalars['DateTime'];
};

export type DealerProductComplianceWhereInput = {
  AND?: InputMaybe<Array<DealerProductComplianceWhereInput>>;
  NOT?: InputMaybe<Array<DealerProductComplianceWhereInput>>;
  OR?: InputMaybe<Array<DealerProductComplianceWhereInput>>;
  dealerProduct?: InputMaybe<DealerProductRelationFilter>;
  dealerProductId?: InputMaybe<IntFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  restrictByDefault?: InputMaybe<BoolFilter>;
  startDate?: InputMaybe<DateTimeNullableFilter>;
};

export type DealerProductComplianceWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type DealerProductCount = {
  __typename?: 'DealerProductCount';
  dealerProductCompliances: Scalars['Int'];
  dealerTiers: Scalars['Int'];
};

export type DealerProductCountAggregate = {
  __typename?: 'DealerProductCountAggregate';
  _all: Scalars['Int'];
  dealerId: Scalars['Int'];
  endDate: Scalars['Int'];
  enrollmentReceiptDate: Scalars['Int'];
  id: Scalars['Int'];
  lastPublishDate: Scalars['Int'];
  productCode: Scalars['Int'];
  programAgentCode: Scalars['Int'];
  publishedEndDate: Scalars['Int'];
  startDate: Scalars['Int'];
};

export type DealerProductCountOrderByAggregateInput = {
  dealerId?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  enrollmentReceiptDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  productCode?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type DealerProductGroupBy = {
  __typename?: 'DealerProductGroupBy';
  _avg?: Maybe<DealerProductAvgAggregate>;
  _count?: Maybe<DealerProductCountAggregate>;
  _max?: Maybe<DealerProductMaxAggregate>;
  _min?: Maybe<DealerProductMinAggregate>;
  _sum?: Maybe<DealerProductSumAggregate>;
  dealerId: Scalars['Int'];
  endDate?: Maybe<Scalars['DateTime']>;
  enrollmentReceiptDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  lastPublishDate?: Maybe<Scalars['DateTime']>;
  productCode: Scalars['String'];
  programAgentCode: Scalars['String'];
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
};

export type DealerProductInsert = {
  dealerId: Scalars['Int'];
  dealerProductCompliance: Array<DealerProductComplianceUpsert>;
  dealerTiers: Array<DealerTierUpsert>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  enrollmentReceiptDate?: InputMaybe<Scalars['DateTime']>;
  productCode: Scalars['String'];
  programAgentCode: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type DealerProductListRelationFilter = {
  every?: InputMaybe<DealerProductWhereInput>;
  none?: InputMaybe<DealerProductWhereInput>;
  some?: InputMaybe<DealerProductWhereInput>;
};

export type DealerProductMaxAggregate = {
  __typename?: 'DealerProductMaxAggregate';
  dealerId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enrollmentReceiptDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  lastPublishDate?: Maybe<Scalars['DateTime']>;
  productCode?: Maybe<Scalars['String']>;
  programAgentCode?: Maybe<Scalars['String']>;
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type DealerProductMaxOrderByAggregateInput = {
  dealerId?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  enrollmentReceiptDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  productCode?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type DealerProductMinAggregate = {
  __typename?: 'DealerProductMinAggregate';
  dealerId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enrollmentReceiptDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  lastPublishDate?: Maybe<Scalars['DateTime']>;
  productCode?: Maybe<Scalars['String']>;
  programAgentCode?: Maybe<Scalars['String']>;
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type DealerProductMinOrderByAggregateInput = {
  dealerId?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  enrollmentReceiptDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  productCode?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type DealerProductOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DealerProductOrderByWithAggregationInput = {
  _avg?: InputMaybe<DealerProductAvgOrderByAggregateInput>;
  _count?: InputMaybe<DealerProductCountOrderByAggregateInput>;
  _max?: InputMaybe<DealerProductMaxOrderByAggregateInput>;
  _min?: InputMaybe<DealerProductMinOrderByAggregateInput>;
  _sum?: InputMaybe<DealerProductSumOrderByAggregateInput>;
  dealerId?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  enrollmentReceiptDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  productCode?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type DealerProductOrderByWithRelationInput = {
  dealer?: InputMaybe<DealerOrderByWithRelationInput>;
  dealerId?: InputMaybe<SortOrder>;
  dealerProductCompliances?: InputMaybe<DealerProductComplianceOrderByRelationAggregateInput>;
  dealerTiers?: InputMaybe<DealerTierOrderByRelationAggregateInput>;
  endDate?: InputMaybe<SortOrder>;
  enrollmentReceiptDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  productCode?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type DealerProductRelationFilter = {
  is?: InputMaybe<DealerProductWhereInput>;
  isNot?: InputMaybe<DealerProductWhereInput>;
};

export enum DealerProductScalarFieldEnum {
  DealerId = 'dealerId',
  EndDate = 'endDate',
  EnrollmentReceiptDate = 'enrollmentReceiptDate',
  Id = 'id',
  LastPublishDate = 'lastPublishDate',
  ProductCode = 'productCode',
  ProgramAgentCode = 'programAgentCode',
  PublishedEndDate = 'publishedEndDate',
  StartDate = 'startDate'
}

export type DealerProductScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<DealerProductScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<DealerProductScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<DealerProductScalarWhereWithAggregatesInput>>;
  dealerId?: InputMaybe<IntWithAggregatesFilter>;
  endDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  enrollmentReceiptDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  lastPublishDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  productCode?: InputMaybe<StringWithAggregatesFilter>;
  programAgentCode?: InputMaybe<StringWithAggregatesFilter>;
  publishedEndDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  startDate?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type DealerProductSumAggregate = {
  __typename?: 'DealerProductSumAggregate';
  dealerId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type DealerProductSumOrderByAggregateInput = {
  dealerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type DealerProductUpdate = {
  dealerId: Scalars['Int'];
  dealerProductCompliance: Array<DealerProductComplianceUpsert>;
  dealerTiers: Array<DealerTierUpsert>;
  deletedDealerTierIds?: InputMaybe<Array<Scalars['Int']>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  enrollmentReceiptDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  productCode: Scalars['String'];
  programAgentCode: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type DealerProductWhereInput = {
  AND?: InputMaybe<Array<DealerProductWhereInput>>;
  NOT?: InputMaybe<Array<DealerProductWhereInput>>;
  OR?: InputMaybe<Array<DealerProductWhereInput>>;
  dealer?: InputMaybe<DealerRelationFilter>;
  dealerId?: InputMaybe<IntFilter>;
  dealerProductCompliances?: InputMaybe<DealerProductComplianceListRelationFilter>;
  dealerTiers?: InputMaybe<DealerTierListRelationFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  enrollmentReceiptDate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  lastPublishDate?: InputMaybe<DateTimeNullableFilter>;
  productCode?: InputMaybe<StringFilter>;
  programAgentCode?: InputMaybe<StringFilter>;
  publishedEndDate?: InputMaybe<DateTimeNullableFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
};

export type DealerProductWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type DealerRelationFilter = {
  is?: InputMaybe<DealerWhereInput>;
  isNot?: InputMaybe<DealerWhereInput>;
};

export enum DealerScalarFieldEnum {
  AddressOne = 'addressOne',
  AddressTwo = 'addressTwo',
  City = 'city',
  Code = 'code',
  CommissionTypeId = 'commissionTypeId',
  Country = 'country',
  CreatedAt = 'createdAt',
  Dirty = 'dirty',
  EndDate = 'endDate',
  HighRisk = 'highRisk',
  Id = 'id',
  Name = 'name',
  OutOfBusinessDate = 'outOfBusinessDate',
  PostalCode = 'postalCode',
  PublishedEndDate = 'publishedEndDate',
  PublishedOutOfBusinessDate = 'publishedOutOfBusinessDate',
  StartDate = 'startDate',
  State = 'state',
  UpdatedAt = 'updatedAt'
}

export type DealerScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<DealerScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<DealerScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<DealerScalarWhereWithAggregatesInput>>;
  addressOne?: InputMaybe<StringNullableWithAggregatesFilter>;
  addressTwo?: InputMaybe<StringNullableWithAggregatesFilter>;
  city?: InputMaybe<StringNullableWithAggregatesFilter>;
  code?: InputMaybe<StringWithAggregatesFilter>;
  commissionTypeId?: InputMaybe<IntNullableWithAggregatesFilter>;
  country?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  dirty?: InputMaybe<BoolWithAggregatesFilter>;
  endDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  highRisk?: InputMaybe<BoolWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  outOfBusinessDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  postalCode?: InputMaybe<StringNullableWithAggregatesFilter>;
  publishedEndDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  publishedOutOfBusinessDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  startDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  state?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type DealerSumAggregate = {
  __typename?: 'DealerSumAggregate';
  commissionTypeId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type DealerSumOrderByAggregateInput = {
  commissionTypeId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type DealerTier = {
  __typename?: 'DealerTier';
  dealerProduct: DealerProduct;
  dealerProductId: Scalars['Int'];
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  lastPublishDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
};

export type DealerTierAvgAggregate = {
  __typename?: 'DealerTierAvgAggregate';
  dealerProductId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type DealerTierAvgOrderByAggregateInput = {
  dealerProductId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type DealerTierCountAggregate = {
  __typename?: 'DealerTierCountAggregate';
  _all: Scalars['Int'];
  dealerProductId: Scalars['Int'];
  endDate: Scalars['Int'];
  id: Scalars['Int'];
  lastPublishDate: Scalars['Int'];
  name: Scalars['Int'];
  publishedEndDate: Scalars['Int'];
  startDate: Scalars['Int'];
};

export type DealerTierCountOrderByAggregateInput = {
  dealerProductId?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type DealerTierGroupBy = {
  __typename?: 'DealerTierGroupBy';
  _avg?: Maybe<DealerTierAvgAggregate>;
  _count?: Maybe<DealerTierCountAggregate>;
  _max?: Maybe<DealerTierMaxAggregate>;
  _min?: Maybe<DealerTierMinAggregate>;
  _sum?: Maybe<DealerTierSumAggregate>;
  dealerProductId: Scalars['Int'];
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  lastPublishDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
};

export type DealerTierListRelationFilter = {
  every?: InputMaybe<DealerTierWhereInput>;
  none?: InputMaybe<DealerTierWhereInput>;
  some?: InputMaybe<DealerTierWhereInput>;
};

export type DealerTierMaxAggregate = {
  __typename?: 'DealerTierMaxAggregate';
  dealerProductId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  lastPublishDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type DealerTierMaxOrderByAggregateInput = {
  dealerProductId?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type DealerTierMinAggregate = {
  __typename?: 'DealerTierMinAggregate';
  dealerProductId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  lastPublishDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type DealerTierMinOrderByAggregateInput = {
  dealerProductId?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type DealerTierOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DealerTierOrderByWithAggregationInput = {
  _avg?: InputMaybe<DealerTierAvgOrderByAggregateInput>;
  _count?: InputMaybe<DealerTierCountOrderByAggregateInput>;
  _max?: InputMaybe<DealerTierMaxOrderByAggregateInput>;
  _min?: InputMaybe<DealerTierMinOrderByAggregateInput>;
  _sum?: InputMaybe<DealerTierSumOrderByAggregateInput>;
  dealerProductId?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type DealerTierOrderByWithRelationInput = {
  dealerProduct?: InputMaybe<DealerProductOrderByWithRelationInput>;
  dealerProductId?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export enum DealerTierScalarFieldEnum {
  DealerProductId = 'dealerProductId',
  EndDate = 'endDate',
  Id = 'id',
  LastPublishDate = 'lastPublishDate',
  Name = 'name',
  PublishedEndDate = 'publishedEndDate',
  StartDate = 'startDate'
}

export type DealerTierScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<DealerTierScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<DealerTierScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<DealerTierScalarWhereWithAggregatesInput>>;
  dealerProductId?: InputMaybe<IntWithAggregatesFilter>;
  endDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  lastPublishDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  publishedEndDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  startDate?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type DealerTierSumAggregate = {
  __typename?: 'DealerTierSumAggregate';
  dealerProductId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type DealerTierSumOrderByAggregateInput = {
  dealerProductId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type DealerTierUpsert = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type DealerTierWhereInput = {
  AND?: InputMaybe<Array<DealerTierWhereInput>>;
  NOT?: InputMaybe<Array<DealerTierWhereInput>>;
  OR?: InputMaybe<Array<DealerTierWhereInput>>;
  dealerProduct?: InputMaybe<DealerProductRelationFilter>;
  dealerProductId?: InputMaybe<IntFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  lastPublishDate?: InputMaybe<DateTimeNullableFilter>;
  name?: InputMaybe<StringFilter>;
  publishedEndDate?: InputMaybe<DateTimeNullableFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
};

export type DealerTierWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type DealerWhereInput = {
  AND?: InputMaybe<Array<DealerWhereInput>>;
  NOT?: InputMaybe<Array<DealerWhereInput>>;
  OR?: InputMaybe<Array<DealerWhereInput>>;
  addressOne?: InputMaybe<StringNullableFilter>;
  addressTwo?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  code?: InputMaybe<StringFilter>;
  commissionType?: InputMaybe<CommissionTypeRelationFilter>;
  commissionTypeId?: InputMaybe<IntNullableFilter>;
  country?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dealerChargebacks?: InputMaybe<DealerChargebackListRelationFilter>;
  dealerProducts?: InputMaybe<DealerProductListRelationFilter>;
  dirty?: InputMaybe<BoolFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  entityCommissions?: InputMaybe<EntityCommissionListRelationFilter>;
  entityPrograms?: InputMaybe<EntityProgramListRelationFilter>;
  highRisk?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  outOfBusinessDate?: InputMaybe<DateTimeNullableFilter>;
  postalCode?: InputMaybe<StringNullableFilter>;
  publishEntitys?: InputMaybe<PublishEntityListRelationFilter>;
  publishedEndDate?: InputMaybe<DateTimeNullableFilter>;
  publishedOutOfBusinessDate?: InputMaybe<DateTimeNullableFilter>;
  startDate?: InputMaybe<DateTimeNullableFilter>;
  state?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type DealerWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};

export type DecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type DecimalWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDecimalFilter>;
  _min?: InputMaybe<NestedDecimalFilter>;
  _sum?: InputMaybe<NestedDecimalFilter>;
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type EntityCommission = {
  __typename?: 'EntityCommission';
  _count?: Maybe<EntityCommissionCount>;
  amount: Scalars['Decimal'];
  cmsBucketNumber?: Maybe<Scalars['Int']>;
  commissionPayee: CommissionPayee;
  commissionPayeeId: Scalars['Int'];
  commissionType: CommissionType;
  commissionTypeId: Scalars['Int'];
  dealer: Dealer;
  dealerId: Scalars['Int'];
  dealerTier?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  dirty: Scalars['Boolean'];
  endDate?: Maybe<Scalars['DateTime']>;
  entityCommissionParameterKeys: Array<EntityCommissionParameterKey>;
  externalId: Scalars['String'];
  id: Scalars['Int'];
  isDiscounted?: Maybe<Scalars['Int']>;
  isParent?: Maybe<Scalars['Int']>;
  lastPublishDate?: Maybe<Scalars['DateTime']>;
  productCode: Scalars['String'];
  programAgentCode: Scalars['String'];
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  publishedStartDate?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
};


export type EntityCommissionEntityCommissionParameterKeysArgs = {
  cursor?: InputMaybe<EntityCommissionParameterKeyWhereUniqueInput>;
  distinct?: InputMaybe<Array<EntityCommissionParameterKeyScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EntityCommissionParameterKeyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntityCommissionParameterKeyWhereInput>;
};

export type EntityCommissionAvgAggregate = {
  __typename?: 'EntityCommissionAvgAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  cmsBucketNumber?: Maybe<Scalars['Float']>;
  commissionPayeeId?: Maybe<Scalars['Float']>;
  commissionTypeId?: Maybe<Scalars['Float']>;
  dealerId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  isDiscounted?: Maybe<Scalars['Float']>;
  isParent?: Maybe<Scalars['Float']>;
};

export type EntityCommissionAvgOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  cmsBucketNumber?: InputMaybe<SortOrder>;
  commissionPayeeId?: InputMaybe<SortOrder>;
  commissionTypeId?: InputMaybe<SortOrder>;
  dealerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDiscounted?: InputMaybe<SortOrder>;
  isParent?: InputMaybe<SortOrder>;
};

export type EntityCommissionCount = {
  __typename?: 'EntityCommissionCount';
  entityCommissionParameterKeys: Scalars['Int'];
};

export type EntityCommissionCountAggregate = {
  __typename?: 'EntityCommissionCountAggregate';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  cmsBucketNumber: Scalars['Int'];
  commissionPayeeId: Scalars['Int'];
  commissionTypeId: Scalars['Int'];
  dealerId: Scalars['Int'];
  dealerTier: Scalars['Int'];
  deleted: Scalars['Int'];
  dirty: Scalars['Int'];
  endDate: Scalars['Int'];
  externalId: Scalars['Int'];
  id: Scalars['Int'];
  isDiscounted: Scalars['Int'];
  isParent: Scalars['Int'];
  lastPublishDate: Scalars['Int'];
  productCode: Scalars['Int'];
  programAgentCode: Scalars['Int'];
  publishedEndDate: Scalars['Int'];
  publishedStartDate: Scalars['Int'];
  startDate: Scalars['Int'];
};

export type EntityCommissionCountOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  cmsBucketNumber?: InputMaybe<SortOrder>;
  commissionPayeeId?: InputMaybe<SortOrder>;
  commissionTypeId?: InputMaybe<SortOrder>;
  dealerId?: InputMaybe<SortOrder>;
  dealerTier?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  dirty?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDiscounted?: InputMaybe<SortOrder>;
  isParent?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  productCode?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  publishedStartDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type EntityCommissionGroupBy = {
  __typename?: 'EntityCommissionGroupBy';
  _avg?: Maybe<EntityCommissionAvgAggregate>;
  _count?: Maybe<EntityCommissionCountAggregate>;
  _max?: Maybe<EntityCommissionMaxAggregate>;
  _min?: Maybe<EntityCommissionMinAggregate>;
  _sum?: Maybe<EntityCommissionSumAggregate>;
  amount: Scalars['Decimal'];
  cmsBucketNumber?: Maybe<Scalars['Int']>;
  commissionPayeeId: Scalars['Int'];
  commissionTypeId: Scalars['Int'];
  dealerId: Scalars['Int'];
  dealerTier?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  dirty: Scalars['Boolean'];
  endDate?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id: Scalars['Int'];
  isDiscounted?: Maybe<Scalars['Int']>;
  isParent?: Maybe<Scalars['Int']>;
  lastPublishDate?: Maybe<Scalars['DateTime']>;
  productCode: Scalars['String'];
  programAgentCode: Scalars['String'];
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  publishedStartDate?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
};

export type EntityCommissionListRelationFilter = {
  every?: InputMaybe<EntityCommissionWhereInput>;
  none?: InputMaybe<EntityCommissionWhereInput>;
  some?: InputMaybe<EntityCommissionWhereInput>;
};

export type EntityCommissionMaxAggregate = {
  __typename?: 'EntityCommissionMaxAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  cmsBucketNumber?: Maybe<Scalars['Int']>;
  commissionPayeeId?: Maybe<Scalars['Int']>;
  commissionTypeId?: Maybe<Scalars['Int']>;
  dealerId?: Maybe<Scalars['Int']>;
  dealerTier?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  dirty?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isDiscounted?: Maybe<Scalars['Int']>;
  isParent?: Maybe<Scalars['Int']>;
  lastPublishDate?: Maybe<Scalars['DateTime']>;
  productCode?: Maybe<Scalars['String']>;
  programAgentCode?: Maybe<Scalars['String']>;
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  publishedStartDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type EntityCommissionMaxOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  cmsBucketNumber?: InputMaybe<SortOrder>;
  commissionPayeeId?: InputMaybe<SortOrder>;
  commissionTypeId?: InputMaybe<SortOrder>;
  dealerId?: InputMaybe<SortOrder>;
  dealerTier?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  dirty?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDiscounted?: InputMaybe<SortOrder>;
  isParent?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  productCode?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  publishedStartDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type EntityCommissionMinAggregate = {
  __typename?: 'EntityCommissionMinAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  cmsBucketNumber?: Maybe<Scalars['Int']>;
  commissionPayeeId?: Maybe<Scalars['Int']>;
  commissionTypeId?: Maybe<Scalars['Int']>;
  dealerId?: Maybe<Scalars['Int']>;
  dealerTier?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  dirty?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isDiscounted?: Maybe<Scalars['Int']>;
  isParent?: Maybe<Scalars['Int']>;
  lastPublishDate?: Maybe<Scalars['DateTime']>;
  productCode?: Maybe<Scalars['String']>;
  programAgentCode?: Maybe<Scalars['String']>;
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  publishedStartDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type EntityCommissionMinOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  cmsBucketNumber?: InputMaybe<SortOrder>;
  commissionPayeeId?: InputMaybe<SortOrder>;
  commissionTypeId?: InputMaybe<SortOrder>;
  dealerId?: InputMaybe<SortOrder>;
  dealerTier?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  dirty?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDiscounted?: InputMaybe<SortOrder>;
  isParent?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  productCode?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  publishedStartDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type EntityCommissionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EntityCommissionOrderByWithAggregationInput = {
  _avg?: InputMaybe<EntityCommissionAvgOrderByAggregateInput>;
  _count?: InputMaybe<EntityCommissionCountOrderByAggregateInput>;
  _max?: InputMaybe<EntityCommissionMaxOrderByAggregateInput>;
  _min?: InputMaybe<EntityCommissionMinOrderByAggregateInput>;
  _sum?: InputMaybe<EntityCommissionSumOrderByAggregateInput>;
  amount?: InputMaybe<SortOrder>;
  cmsBucketNumber?: InputMaybe<SortOrder>;
  commissionPayeeId?: InputMaybe<SortOrder>;
  commissionTypeId?: InputMaybe<SortOrder>;
  dealerId?: InputMaybe<SortOrder>;
  dealerTier?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  dirty?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDiscounted?: InputMaybe<SortOrder>;
  isParent?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  productCode?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  publishedStartDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type EntityCommissionOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  cmsBucketNumber?: InputMaybe<SortOrder>;
  commissionPayee?: InputMaybe<CommissionPayeeOrderByWithRelationInput>;
  commissionPayeeId?: InputMaybe<SortOrder>;
  commissionType?: InputMaybe<CommissionTypeOrderByWithRelationInput>;
  commissionTypeId?: InputMaybe<SortOrder>;
  dealer?: InputMaybe<DealerOrderByWithRelationInput>;
  dealerId?: InputMaybe<SortOrder>;
  dealerTier?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  dirty?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  entityCommissionParameterKeys?: InputMaybe<EntityCommissionParameterKeyOrderByRelationAggregateInput>;
  externalId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDiscounted?: InputMaybe<SortOrder>;
  isParent?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  productCode?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  publishedStartDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type EntityCommissionParameterKey = {
  __typename?: 'EntityCommissionParameterKey';
  commissionTypeParameter: CommissionTypeParameter;
  commissionTypeParameterId: Scalars['Int'];
  entityCommission: EntityCommission;
  entityCommissionId: Scalars['Int'];
  id: Scalars['Int'];
  parameterKeyName: Scalars['String'];
};

export type EntityCommissionParameterKeyAvgAggregate = {
  __typename?: 'EntityCommissionParameterKeyAvgAggregate';
  commissionTypeParameterId?: Maybe<Scalars['Float']>;
  entityCommissionId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type EntityCommissionParameterKeyAvgOrderByAggregateInput = {
  commissionTypeParameterId?: InputMaybe<SortOrder>;
  entityCommissionId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type EntityCommissionParameterKeyCountAggregate = {
  __typename?: 'EntityCommissionParameterKeyCountAggregate';
  _all: Scalars['Int'];
  commissionTypeParameterId: Scalars['Int'];
  entityCommissionId: Scalars['Int'];
  id: Scalars['Int'];
  parameterKeyName: Scalars['Int'];
};

export type EntityCommissionParameterKeyCountOrderByAggregateInput = {
  commissionTypeParameterId?: InputMaybe<SortOrder>;
  entityCommissionId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  parameterKeyName?: InputMaybe<SortOrder>;
};

export type EntityCommissionParameterKeyGroupBy = {
  __typename?: 'EntityCommissionParameterKeyGroupBy';
  _avg?: Maybe<EntityCommissionParameterKeyAvgAggregate>;
  _count?: Maybe<EntityCommissionParameterKeyCountAggregate>;
  _max?: Maybe<EntityCommissionParameterKeyMaxAggregate>;
  _min?: Maybe<EntityCommissionParameterKeyMinAggregate>;
  _sum?: Maybe<EntityCommissionParameterKeySumAggregate>;
  commissionTypeParameterId: Scalars['Int'];
  entityCommissionId: Scalars['Int'];
  id: Scalars['Int'];
  parameterKeyName: Scalars['String'];
};

export type EntityCommissionParameterKeyListRelationFilter = {
  every?: InputMaybe<EntityCommissionParameterKeyWhereInput>;
  none?: InputMaybe<EntityCommissionParameterKeyWhereInput>;
  some?: InputMaybe<EntityCommissionParameterKeyWhereInput>;
};

export type EntityCommissionParameterKeyMaxAggregate = {
  __typename?: 'EntityCommissionParameterKeyMaxAggregate';
  commissionTypeParameterId?: Maybe<Scalars['Int']>;
  entityCommissionId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  parameterKeyName?: Maybe<Scalars['String']>;
};

export type EntityCommissionParameterKeyMaxOrderByAggregateInput = {
  commissionTypeParameterId?: InputMaybe<SortOrder>;
  entityCommissionId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  parameterKeyName?: InputMaybe<SortOrder>;
};

export type EntityCommissionParameterKeyMinAggregate = {
  __typename?: 'EntityCommissionParameterKeyMinAggregate';
  commissionTypeParameterId?: Maybe<Scalars['Int']>;
  entityCommissionId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  parameterKeyName?: Maybe<Scalars['String']>;
};

export type EntityCommissionParameterKeyMinOrderByAggregateInput = {
  commissionTypeParameterId?: InputMaybe<SortOrder>;
  entityCommissionId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  parameterKeyName?: InputMaybe<SortOrder>;
};

export type EntityCommissionParameterKeyOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EntityCommissionParameterKeyOrderByWithAggregationInput = {
  _avg?: InputMaybe<EntityCommissionParameterKeyAvgOrderByAggregateInput>;
  _count?: InputMaybe<EntityCommissionParameterKeyCountOrderByAggregateInput>;
  _max?: InputMaybe<EntityCommissionParameterKeyMaxOrderByAggregateInput>;
  _min?: InputMaybe<EntityCommissionParameterKeyMinOrderByAggregateInput>;
  _sum?: InputMaybe<EntityCommissionParameterKeySumOrderByAggregateInput>;
  commissionTypeParameterId?: InputMaybe<SortOrder>;
  entityCommissionId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  parameterKeyName?: InputMaybe<SortOrder>;
};

export type EntityCommissionParameterKeyOrderByWithRelationInput = {
  commissionTypeParameter?: InputMaybe<CommissionTypeParameterOrderByWithRelationInput>;
  commissionTypeParameterId?: InputMaybe<SortOrder>;
  entityCommission?: InputMaybe<EntityCommissionOrderByWithRelationInput>;
  entityCommissionId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  parameterKeyName?: InputMaybe<SortOrder>;
};

export enum EntityCommissionParameterKeyScalarFieldEnum {
  CommissionTypeParameterId = 'commissionTypeParameterId',
  EntityCommissionId = 'entityCommissionId',
  Id = 'id',
  ParameterKeyName = 'parameterKeyName'
}

export type EntityCommissionParameterKeyScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<EntityCommissionParameterKeyScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<EntityCommissionParameterKeyScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<EntityCommissionParameterKeyScalarWhereWithAggregatesInput>>;
  commissionTypeParameterId?: InputMaybe<IntWithAggregatesFilter>;
  entityCommissionId?: InputMaybe<IntWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  parameterKeyName?: InputMaybe<StringWithAggregatesFilter>;
};

export type EntityCommissionParameterKeySumAggregate = {
  __typename?: 'EntityCommissionParameterKeySumAggregate';
  commissionTypeParameterId?: Maybe<Scalars['Int']>;
  entityCommissionId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type EntityCommissionParameterKeySumOrderByAggregateInput = {
  commissionTypeParameterId?: InputMaybe<SortOrder>;
  entityCommissionId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type EntityCommissionParameterKeyWhereInput = {
  AND?: InputMaybe<Array<EntityCommissionParameterKeyWhereInput>>;
  NOT?: InputMaybe<Array<EntityCommissionParameterKeyWhereInput>>;
  OR?: InputMaybe<Array<EntityCommissionParameterKeyWhereInput>>;
  commissionTypeParameter?: InputMaybe<CommissionTypeParameterRelationFilter>;
  commissionTypeParameterId?: InputMaybe<IntFilter>;
  entityCommission?: InputMaybe<EntityCommissionRelationFilter>;
  entityCommissionId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  parameterKeyName?: InputMaybe<StringFilter>;
};

export type EntityCommissionParameterKeyWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type EntityCommissionRelationFilter = {
  is?: InputMaybe<EntityCommissionWhereInput>;
  isNot?: InputMaybe<EntityCommissionWhereInput>;
};

export enum EntityCommissionScalarFieldEnum {
  Amount = 'amount',
  CmsBucketNumber = 'cmsBucketNumber',
  CommissionPayeeId = 'commissionPayeeId',
  CommissionTypeId = 'commissionTypeId',
  DealerId = 'dealerId',
  DealerTier = 'dealerTier',
  Deleted = 'deleted',
  Dirty = 'dirty',
  EndDate = 'endDate',
  ExternalId = 'externalId',
  Id = 'id',
  IsDiscounted = 'isDiscounted',
  IsParent = 'isParent',
  LastPublishDate = 'lastPublishDate',
  ProductCode = 'productCode',
  ProgramAgentCode = 'programAgentCode',
  PublishedEndDate = 'publishedEndDate',
  PublishedStartDate = 'publishedStartDate',
  StartDate = 'startDate'
}

export type EntityCommissionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<EntityCommissionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<EntityCommissionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<EntityCommissionScalarWhereWithAggregatesInput>>;
  amount?: InputMaybe<DecimalWithAggregatesFilter>;
  cmsBucketNumber?: InputMaybe<IntNullableWithAggregatesFilter>;
  commissionPayeeId?: InputMaybe<IntWithAggregatesFilter>;
  commissionTypeId?: InputMaybe<IntWithAggregatesFilter>;
  dealerId?: InputMaybe<IntWithAggregatesFilter>;
  dealerTier?: InputMaybe<StringNullableWithAggregatesFilter>;
  deleted?: InputMaybe<BoolWithAggregatesFilter>;
  dirty?: InputMaybe<BoolWithAggregatesFilter>;
  endDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  externalId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  isDiscounted?: InputMaybe<IntNullableWithAggregatesFilter>;
  isParent?: InputMaybe<IntNullableWithAggregatesFilter>;
  lastPublishDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  productCode?: InputMaybe<StringWithAggregatesFilter>;
  programAgentCode?: InputMaybe<StringWithAggregatesFilter>;
  publishedEndDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  publishedStartDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  startDate?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type EntityCommissionSumAggregate = {
  __typename?: 'EntityCommissionSumAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  cmsBucketNumber?: Maybe<Scalars['Int']>;
  commissionPayeeId?: Maybe<Scalars['Int']>;
  commissionTypeId?: Maybe<Scalars['Int']>;
  dealerId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isDiscounted?: Maybe<Scalars['Int']>;
  isParent?: Maybe<Scalars['Int']>;
};

export type EntityCommissionSumOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  cmsBucketNumber?: InputMaybe<SortOrder>;
  commissionPayeeId?: InputMaybe<SortOrder>;
  commissionTypeId?: InputMaybe<SortOrder>;
  dealerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDiscounted?: InputMaybe<SortOrder>;
  isParent?: InputMaybe<SortOrder>;
};

export type EntityCommissionTypeUpdate = {
  commissionTypeId?: InputMaybe<Scalars['Int']>;
  dealerId: Scalars['Int'];
  endDateOnChange: Scalars['Boolean'];
};

export type EntityCommissionWhereInput = {
  AND?: InputMaybe<Array<EntityCommissionWhereInput>>;
  NOT?: InputMaybe<Array<EntityCommissionWhereInput>>;
  OR?: InputMaybe<Array<EntityCommissionWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  cmsBucketNumber?: InputMaybe<IntNullableFilter>;
  commissionPayee?: InputMaybe<CommissionPayeeRelationFilter>;
  commissionPayeeId?: InputMaybe<IntFilter>;
  commissionType?: InputMaybe<CommissionTypeRelationFilter>;
  commissionTypeId?: InputMaybe<IntFilter>;
  dealer?: InputMaybe<DealerRelationFilter>;
  dealerId?: InputMaybe<IntFilter>;
  dealerTier?: InputMaybe<StringNullableFilter>;
  deleted?: InputMaybe<BoolFilter>;
  dirty?: InputMaybe<BoolFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  entityCommissionParameterKeys?: InputMaybe<EntityCommissionParameterKeyListRelationFilter>;
  externalId?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  isDiscounted?: InputMaybe<IntNullableFilter>;
  isParent?: InputMaybe<IntNullableFilter>;
  lastPublishDate?: InputMaybe<DateTimeNullableFilter>;
  productCode?: InputMaybe<StringFilter>;
  programAgentCode?: InputMaybe<StringFilter>;
  publishedEndDate?: InputMaybe<DateTimeNullableFilter>;
  publishedStartDate?: InputMaybe<DateTimeNullableFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
};

export type EntityCommissionWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type EntityProgram = {
  __typename?: 'EntityProgram';
  dealer: Dealer;
  deleted: Scalars['Boolean'];
  dirty: Scalars['Boolean'];
  endDate?: Maybe<Scalars['DateTime']>;
  enrollmentReceiptDate?: Maybe<Scalars['DateTime']>;
  enrollmentSignatureDate?: Maybe<Scalars['DateTime']>;
  entityId: Scalars['Int'];
  id: Scalars['Int'];
  lastPublishDate?: Maybe<Scalars['DateTime']>;
  programAgentCode: Scalars['String'];
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  publishedStartDate?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
};

export type EntityProgramAvgAggregate = {
  __typename?: 'EntityProgramAvgAggregate';
  entityId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type EntityProgramAvgOrderByAggregateInput = {
  entityId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type EntityProgramCountAggregate = {
  __typename?: 'EntityProgramCountAggregate';
  _all: Scalars['Int'];
  deleted: Scalars['Int'];
  dirty: Scalars['Int'];
  endDate: Scalars['Int'];
  enrollmentReceiptDate: Scalars['Int'];
  enrollmentSignatureDate: Scalars['Int'];
  entityId: Scalars['Int'];
  id: Scalars['Int'];
  lastPublishDate: Scalars['Int'];
  programAgentCode: Scalars['Int'];
  publishedEndDate: Scalars['Int'];
  publishedStartDate: Scalars['Int'];
  startDate: Scalars['Int'];
};

export type EntityProgramCountOrderByAggregateInput = {
  deleted?: InputMaybe<SortOrder>;
  dirty?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  enrollmentReceiptDate?: InputMaybe<SortOrder>;
  enrollmentSignatureDate?: InputMaybe<SortOrder>;
  entityId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  publishedStartDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type EntityProgramEntityIdProgramAgentCodeCompoundUniqueInput = {
  entityId: Scalars['Int'];
  programAgentCode: Scalars['String'];
};

export type EntityProgramGroupBy = {
  __typename?: 'EntityProgramGroupBy';
  _avg?: Maybe<EntityProgramAvgAggregate>;
  _count?: Maybe<EntityProgramCountAggregate>;
  _max?: Maybe<EntityProgramMaxAggregate>;
  _min?: Maybe<EntityProgramMinAggregate>;
  _sum?: Maybe<EntityProgramSumAggregate>;
  deleted: Scalars['Boolean'];
  dirty: Scalars['Boolean'];
  endDate?: Maybe<Scalars['DateTime']>;
  enrollmentReceiptDate?: Maybe<Scalars['DateTime']>;
  enrollmentSignatureDate?: Maybe<Scalars['DateTime']>;
  entityId: Scalars['Int'];
  id: Scalars['Int'];
  lastPublishDate?: Maybe<Scalars['DateTime']>;
  programAgentCode: Scalars['String'];
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  publishedStartDate?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
};

export type EntityProgramInsert = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  enrollmentReceiptDate?: InputMaybe<Scalars['DateTime']>;
  enrollmentSignatureDate?: InputMaybe<Scalars['DateTime']>;
  entityId: Scalars['Int'];
  programAgentCode: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type EntityProgramListRelationFilter = {
  every?: InputMaybe<EntityProgramWhereInput>;
  none?: InputMaybe<EntityProgramWhereInput>;
  some?: InputMaybe<EntityProgramWhereInput>;
};

export type EntityProgramMaxAggregate = {
  __typename?: 'EntityProgramMaxAggregate';
  deleted?: Maybe<Scalars['Boolean']>;
  dirty?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enrollmentReceiptDate?: Maybe<Scalars['DateTime']>;
  enrollmentSignatureDate?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lastPublishDate?: Maybe<Scalars['DateTime']>;
  programAgentCode?: Maybe<Scalars['String']>;
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  publishedStartDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type EntityProgramMaxOrderByAggregateInput = {
  deleted?: InputMaybe<SortOrder>;
  dirty?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  enrollmentReceiptDate?: InputMaybe<SortOrder>;
  enrollmentSignatureDate?: InputMaybe<SortOrder>;
  entityId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  publishedStartDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type EntityProgramMinAggregate = {
  __typename?: 'EntityProgramMinAggregate';
  deleted?: Maybe<Scalars['Boolean']>;
  dirty?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTime']>;
  enrollmentReceiptDate?: Maybe<Scalars['DateTime']>;
  enrollmentSignatureDate?: Maybe<Scalars['DateTime']>;
  entityId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lastPublishDate?: Maybe<Scalars['DateTime']>;
  programAgentCode?: Maybe<Scalars['String']>;
  publishedEndDate?: Maybe<Scalars['DateTime']>;
  publishedStartDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type EntityProgramMinOrderByAggregateInput = {
  deleted?: InputMaybe<SortOrder>;
  dirty?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  enrollmentReceiptDate?: InputMaybe<SortOrder>;
  enrollmentSignatureDate?: InputMaybe<SortOrder>;
  entityId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  publishedStartDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type EntityProgramOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EntityProgramOrderByWithAggregationInput = {
  _avg?: InputMaybe<EntityProgramAvgOrderByAggregateInput>;
  _count?: InputMaybe<EntityProgramCountOrderByAggregateInput>;
  _max?: InputMaybe<EntityProgramMaxOrderByAggregateInput>;
  _min?: InputMaybe<EntityProgramMinOrderByAggregateInput>;
  _sum?: InputMaybe<EntityProgramSumOrderByAggregateInput>;
  deleted?: InputMaybe<SortOrder>;
  dirty?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  enrollmentReceiptDate?: InputMaybe<SortOrder>;
  enrollmentSignatureDate?: InputMaybe<SortOrder>;
  entityId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  publishedStartDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type EntityProgramOrderByWithRelationInput = {
  dealer?: InputMaybe<DealerOrderByWithRelationInput>;
  deleted?: InputMaybe<SortOrder>;
  dirty?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  enrollmentReceiptDate?: InputMaybe<SortOrder>;
  enrollmentSignatureDate?: InputMaybe<SortOrder>;
  entityId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastPublishDate?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
  publishedEndDate?: InputMaybe<SortOrder>;
  publishedStartDate?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export enum EntityProgramScalarFieldEnum {
  Deleted = 'deleted',
  Dirty = 'dirty',
  EndDate = 'endDate',
  EnrollmentReceiptDate = 'enrollmentReceiptDate',
  EnrollmentSignatureDate = 'enrollmentSignatureDate',
  EntityId = 'entityId',
  Id = 'id',
  LastPublishDate = 'lastPublishDate',
  ProgramAgentCode = 'programAgentCode',
  PublishedEndDate = 'publishedEndDate',
  PublishedStartDate = 'publishedStartDate',
  StartDate = 'startDate'
}

export type EntityProgramScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<EntityProgramScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<EntityProgramScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<EntityProgramScalarWhereWithAggregatesInput>>;
  deleted?: InputMaybe<BoolWithAggregatesFilter>;
  dirty?: InputMaybe<BoolWithAggregatesFilter>;
  endDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  enrollmentReceiptDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  enrollmentSignatureDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  entityId?: InputMaybe<IntWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  lastPublishDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  programAgentCode?: InputMaybe<StringWithAggregatesFilter>;
  publishedEndDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  publishedStartDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  startDate?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type EntityProgramSumAggregate = {
  __typename?: 'EntityProgramSumAggregate';
  entityId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type EntityProgramSumOrderByAggregateInput = {
  entityId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type EntityProgramUpdate = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  enrollmentReceiptDate?: InputMaybe<Scalars['DateTime']>;
  enrollmentSignatureDate?: InputMaybe<Scalars['DateTime']>;
  entityId: Scalars['Int'];
  id: Scalars['Int'];
  programAgentCode: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type EntityProgramWhereInput = {
  AND?: InputMaybe<Array<EntityProgramWhereInput>>;
  NOT?: InputMaybe<Array<EntityProgramWhereInput>>;
  OR?: InputMaybe<Array<EntityProgramWhereInput>>;
  dealer?: InputMaybe<DealerRelationFilter>;
  deleted?: InputMaybe<BoolFilter>;
  dirty?: InputMaybe<BoolFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  enrollmentReceiptDate?: InputMaybe<DateTimeNullableFilter>;
  enrollmentSignatureDate?: InputMaybe<DateTimeNullableFilter>;
  entityId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  lastPublishDate?: InputMaybe<DateTimeNullableFilter>;
  programAgentCode?: InputMaybe<StringFilter>;
  publishedEndDate?: InputMaybe<DateTimeNullableFilter>;
  publishedStartDate?: InputMaybe<DateTimeNullableFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
};

export type EntityProgramWhereUniqueInput = {
  entityId_programAgentCode?: InputMaybe<EntityProgramEntityIdProgramAgentCodeCompoundUniqueInput>;
  id?: InputMaybe<Scalars['Int']>;
};

export type FilteredParameter = {
  filteredParameterKeyIds?: InputMaybe<Array<Scalars['Int']>>;
  parameterId: Scalars['Int'];
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createDealerChargeback: DealerChargeback;
  /** execute VOLATILE function "create_product_msrp_parameter_key_combinations" which returns "product_msrp_parameter_key_combination" */
  create_product_msrp_parameter_key_combinations: Array<Product_Msrp_Parameter_Key_Combination>;
  /** execute VOLATILE function "create_product_parameter_key_combinations" which returns "product_parameter_key_combination" */
  create_product_parameter_key_combinations: Array<Product_Parameter_Key_Combination>;
  deleteEntityProgram: Scalars['Boolean'];
  /** delete data from the table: "audit_log" */
  delete_audit_log?: Maybe<Audit_Log_Mutation_Response>;
  /** delete single row from the table: "audit_log" */
  delete_audit_log_by_pk?: Maybe<Audit_Log>;
  /** delete data from the table: "bucket" */
  delete_bucket?: Maybe<Bucket_Mutation_Response>;
  /** delete data from the table: "bucket_base_parameter" */
  delete_bucket_base_parameter?: Maybe<Bucket_Base_Parameter_Mutation_Response>;
  /** delete single row from the table: "bucket_base_parameter" */
  delete_bucket_base_parameter_by_pk?: Maybe<Bucket_Base_Parameter>;
  /** delete data from the table: "bucket_base_rate" */
  delete_bucket_base_rate?: Maybe<Bucket_Base_Rate_Mutation_Response>;
  /** delete single row from the table: "bucket_base_rate" */
  delete_bucket_base_rate_by_pk?: Maybe<Bucket_Base_Rate>;
  /** delete single row from the table: "bucket" */
  delete_bucket_by_pk?: Maybe<Bucket>;
  /** delete data from the table: "bucket_maximum" */
  delete_bucket_maximum?: Maybe<Bucket_Maximum_Mutation_Response>;
  /** delete single row from the table: "bucket_maximum" */
  delete_bucket_maximum_by_pk?: Maybe<Bucket_Maximum>;
  /** delete data from the table: "bucket_minimum" */
  delete_bucket_minimum?: Maybe<Bucket_Minimum_Mutation_Response>;
  /** delete single row from the table: "bucket_minimum" */
  delete_bucket_minimum_by_pk?: Maybe<Bucket_Minimum>;
  /** delete data from the table: "bucket_offset" */
  delete_bucket_offset?: Maybe<Bucket_Offset_Mutation_Response>;
  /** delete single row from the table: "bucket_offset" */
  delete_bucket_offset_by_pk?: Maybe<Bucket_Offset>;
  /** delete data from the table: "bucket_rate" */
  delete_bucket_rate?: Maybe<Bucket_Rate_Mutation_Response>;
  /** delete single row from the table: "bucket_rate" */
  delete_bucket_rate_by_pk?: Maybe<Bucket_Rate>;
  /** delete data from the table: "bucket_rate_exception" */
  delete_bucket_rate_exception?: Maybe<Bucket_Rate_Exception_Mutation_Response>;
  /** delete single row from the table: "bucket_rate_exception" */
  delete_bucket_rate_exception_by_pk?: Maybe<Bucket_Rate_Exception>;
  /** delete data from the table: "bucket_rate_factor" */
  delete_bucket_rate_factor?: Maybe<Bucket_Rate_Factor_Mutation_Response>;
  /** delete single row from the table: "bucket_rate_factor" */
  delete_bucket_rate_factor_by_pk?: Maybe<Bucket_Rate_Factor>;
  /** delete data from the table: "bucket_rate_offset" */
  delete_bucket_rate_offset?: Maybe<Bucket_Rate_Offset_Mutation_Response>;
  /** delete single row from the table: "bucket_rate_offset" */
  delete_bucket_rate_offset_by_pk?: Maybe<Bucket_Rate_Offset>;
  /** delete data from the table: "bucket_rounding" */
  delete_bucket_rounding?: Maybe<Bucket_Rounding_Mutation_Response>;
  /** delete single row from the table: "bucket_rounding" */
  delete_bucket_rounding_by_pk?: Maybe<Bucket_Rounding>;
  /** delete data from the table: "commission_type" */
  delete_commission_type?: Maybe<Commission_Type_Mutation_Response>;
  /** delete single row from the table: "commission_type" */
  delete_commission_type_by_pk?: Maybe<Commission_Type>;
  /** delete data from the table: "control_type" */
  delete_control_type?: Maybe<Control_Type_Mutation_Response>;
  /** delete single row from the table: "control_type" */
  delete_control_type_by_pk?: Maybe<Control_Type>;
  /** delete data from the table: "dealer_product" */
  delete_dealer_product?: Maybe<Dealer_Product_Mutation_Response>;
  /** delete single row from the table: "dealer_product" */
  delete_dealer_product_by_pk?: Maybe<Dealer_Product>;
  /** delete data from the table: "ledger_event" */
  delete_ledger_event?: Maybe<Ledger_Event_Mutation_Response>;
  /** delete single row from the table: "ledger_event" */
  delete_ledger_event_by_pk?: Maybe<Ledger_Event>;
  /** delete data from the table: "non_sellable_combination" */
  delete_non_sellable_combination?: Maybe<Non_Sellable_Combination_Mutation_Response>;
  /** delete single row from the table: "non_sellable_combination" */
  delete_non_sellable_combination_by_pk?: Maybe<Non_Sellable_Combination>;
  /** delete data from the table: "non_sellable_combination_option" */
  delete_non_sellable_combination_option?: Maybe<Non_Sellable_Combination_Option_Mutation_Response>;
  /** delete single row from the table: "non_sellable_combination_option" */
  delete_non_sellable_combination_option_by_pk?: Maybe<Non_Sellable_Combination_Option>;
  /** delete data from the table: "offset_type" */
  delete_offset_type?: Maybe<Offset_Type_Mutation_Response>;
  /** delete single row from the table: "offset_type" */
  delete_offset_type_by_pk?: Maybe<Offset_Type>;
  /** delete data from the table: "operator" */
  delete_operator?: Maybe<Operator_Mutation_Response>;
  /** delete single row from the table: "operator" */
  delete_operator_by_pk?: Maybe<Operator>;
  /** delete data from the table: "parameter" */
  delete_parameter?: Maybe<Parameter_Mutation_Response>;
  /** delete single row from the table: "parameter" */
  delete_parameter_by_pk?: Maybe<Parameter>;
  /** delete data from the table: "parameter_key" */
  delete_parameter_key?: Maybe<Parameter_Key_Mutation_Response>;
  /** delete single row from the table: "parameter_key" */
  delete_parameter_key_by_pk?: Maybe<Parameter_Key>;
  /** delete data from the table: "parameter_key_value" */
  delete_parameter_key_value?: Maybe<Parameter_Key_Value_Mutation_Response>;
  /** delete single row from the table: "parameter_key_value" */
  delete_parameter_key_value_by_pk?: Maybe<Parameter_Key_Value>;
  /** delete data from the table: "parameter_sub_key" */
  delete_parameter_sub_key?: Maybe<Parameter_Sub_Key_Mutation_Response>;
  /** delete data from the table: "parameter_sub_key_bucket_discount" */
  delete_parameter_sub_key_bucket_discount?: Maybe<Parameter_Sub_Key_Bucket_Discount_Mutation_Response>;
  /** delete single row from the table: "parameter_sub_key_bucket_discount" */
  delete_parameter_sub_key_bucket_discount_by_pk?: Maybe<Parameter_Sub_Key_Bucket_Discount>;
  /** delete single row from the table: "parameter_sub_key" */
  delete_parameter_sub_key_by_pk?: Maybe<Parameter_Sub_Key>;
  /** delete data from the table: "parameter_subtype" */
  delete_parameter_subtype?: Maybe<Parameter_Subtype_Mutation_Response>;
  /** delete single row from the table: "parameter_subtype" */
  delete_parameter_subtype_by_pk?: Maybe<Parameter_Subtype>;
  /** delete data from the table: "parameter_subtype_options" */
  delete_parameter_subtype_options?: Maybe<Parameter_Subtype_Options_Mutation_Response>;
  /** delete single row from the table: "parameter_subtype_options" */
  delete_parameter_subtype_options_by_pk?: Maybe<Parameter_Subtype_Options>;
  /** delete data from the table: "parameter_type" */
  delete_parameter_type?: Maybe<Parameter_Type_Mutation_Response>;
  /** delete single row from the table: "parameter_type" */
  delete_parameter_type_by_pk?: Maybe<Parameter_Type>;
  /** delete data from the table: "payee" */
  delete_payee?: Maybe<Payee_Mutation_Response>;
  /** delete single row from the table: "payee" */
  delete_payee_by_pk?: Maybe<Payee>;
  /** delete data from the table: "product" */
  delete_product?: Maybe<Product_Mutation_Response>;
  /** delete single row from the table: "product" */
  delete_product_by_pk?: Maybe<Product>;
  /** delete data from the table: "product_dealer_offset" */
  delete_product_dealer_offset?: Maybe<Product_Dealer_Offset_Mutation_Response>;
  /** delete single row from the table: "product_dealer_offset" */
  delete_product_dealer_offset_by_pk?: Maybe<Product_Dealer_Offset>;
  /** delete data from the table: "product_msrp_operation" */
  delete_product_msrp_operation?: Maybe<Product_Msrp_Operation_Mutation_Response>;
  /** delete single row from the table: "product_msrp_operation" */
  delete_product_msrp_operation_by_pk?: Maybe<Product_Msrp_Operation>;
  /** delete data from the table: "product_msrp_operator" */
  delete_product_msrp_operator?: Maybe<Product_Msrp_Operator_Mutation_Response>;
  /** delete single row from the table: "product_msrp_operator" */
  delete_product_msrp_operator_by_pk?: Maybe<Product_Msrp_Operator>;
  /** delete data from the table: "product_msrp_parameter" */
  delete_product_msrp_parameter?: Maybe<Product_Msrp_Parameter_Mutation_Response>;
  /** delete single row from the table: "product_msrp_parameter" */
  delete_product_msrp_parameter_by_pk?: Maybe<Product_Msrp_Parameter>;
  /** delete data from the table: "product_msrp_parameter_key" */
  delete_product_msrp_parameter_key?: Maybe<Product_Msrp_Parameter_Key_Mutation_Response>;
  /** delete single row from the table: "product_msrp_parameter_key" */
  delete_product_msrp_parameter_key_by_pk?: Maybe<Product_Msrp_Parameter_Key>;
  /** delete data from the table: "product_msrp_parameter_key_combination" */
  delete_product_msrp_parameter_key_combination?: Maybe<Product_Msrp_Parameter_Key_Combination_Mutation_Response>;
  /** delete single row from the table: "product_msrp_parameter_key_combination" */
  delete_product_msrp_parameter_key_combination_by_pk?: Maybe<Product_Msrp_Parameter_Key_Combination>;
  /** execute VOLATILE function "delete_product_msrp_parameter_key_combinations" which returns "product_msrp_parameter_key_combination" */
  delete_product_msrp_parameter_key_combinations: Array<Product_Msrp_Parameter_Key_Combination>;
  /** delete data from the table: "product_parameter_key" */
  delete_product_parameter_key?: Maybe<Product_Parameter_Key_Mutation_Response>;
  /** delete single row from the table: "product_parameter_key" */
  delete_product_parameter_key_by_pk?: Maybe<Product_Parameter_Key>;
  /** delete data from the table: "product_parameter_key_combination" */
  delete_product_parameter_key_combination?: Maybe<Product_Parameter_Key_Combination_Mutation_Response>;
  /** delete single row from the table: "product_parameter_key_combination" */
  delete_product_parameter_key_combination_by_pk?: Maybe<Product_Parameter_Key_Combination>;
  /** execute VOLATILE function "delete_product_parameter_key_combinations" which returns "product_parameter_key_combination" */
  delete_product_parameter_key_combinations: Array<Product_Parameter_Key_Combination>;
  /** delete data from the table: "product_parameter_key_sub_combination" */
  delete_product_parameter_key_sub_combination?: Maybe<Product_Parameter_Key_Sub_Combination_Mutation_Response>;
  /** delete single row from the table: "product_parameter_key_sub_combination" */
  delete_product_parameter_key_sub_combination_by_pk?: Maybe<Product_Parameter_Key_Sub_Combination>;
  /** delete data from the table: "product_type" */
  delete_product_type?: Maybe<Product_Type_Mutation_Response>;
  /** delete single row from the table: "product_type" */
  delete_product_type_by_pk?: Maybe<Product_Type>;
  /** delete data from the table: "program" */
  delete_program?: Maybe<Program_Mutation_Response>;
  /** delete single row from the table: "program" */
  delete_program_by_pk?: Maybe<Program>;
  /** delete data from the table: "publish_complete" */
  delete_publish_complete?: Maybe<Publish_Complete_Mutation_Response>;
  /** delete single row from the table: "publish_complete" */
  delete_publish_complete_by_pk?: Maybe<Publish_Complete>;
  /** delete data from the table: "publish_product" */
  delete_publish_product?: Maybe<Publish_Product_Mutation_Response>;
  /** delete single row from the table: "publish_product" */
  delete_publish_product_by_pk?: Maybe<Publish_Product>;
  /** delete data from the table: "rate_slice_base_slice" */
  delete_rate_slice_base_slice?: Maybe<Rate_Slice_Base_Slice_Mutation_Response>;
  /** delete single row from the table: "rate_slice_base_slice" */
  delete_rate_slice_base_slice_by_pk?: Maybe<Rate_Slice_Base_Slice>;
  /** delete data from the table: "risk_type" */
  delete_risk_type?: Maybe<Risk_Type_Mutation_Response>;
  /** delete single row from the table: "risk_type" */
  delete_risk_type_by_pk?: Maybe<Risk_Type>;
  /** delete data from the table: "round_type" */
  delete_round_type?: Maybe<Round_Type_Mutation_Response>;
  /** delete single row from the table: "round_type" */
  delete_round_type_by_pk?: Maybe<Round_Type>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  insertDealerProduct: DealerProduct;
  insertEntityProgram: EntityProgram;
  /** insert data into the table: "audit_log" */
  insert_audit_log?: Maybe<Audit_Log_Mutation_Response>;
  /** insert a single row into the table: "audit_log" */
  insert_audit_log_one?: Maybe<Audit_Log>;
  /** insert data into the table: "bucket" */
  insert_bucket?: Maybe<Bucket_Mutation_Response>;
  /** insert data into the table: "bucket_base_parameter" */
  insert_bucket_base_parameter?: Maybe<Bucket_Base_Parameter_Mutation_Response>;
  /** insert a single row into the table: "bucket_base_parameter" */
  insert_bucket_base_parameter_one?: Maybe<Bucket_Base_Parameter>;
  /** insert data into the table: "bucket_base_rate" */
  insert_bucket_base_rate?: Maybe<Bucket_Base_Rate_Mutation_Response>;
  /** insert a single row into the table: "bucket_base_rate" */
  insert_bucket_base_rate_one?: Maybe<Bucket_Base_Rate>;
  /** insert data into the table: "bucket_maximum" */
  insert_bucket_maximum?: Maybe<Bucket_Maximum_Mutation_Response>;
  /** insert a single row into the table: "bucket_maximum" */
  insert_bucket_maximum_one?: Maybe<Bucket_Maximum>;
  /** insert data into the table: "bucket_minimum" */
  insert_bucket_minimum?: Maybe<Bucket_Minimum_Mutation_Response>;
  /** insert a single row into the table: "bucket_minimum" */
  insert_bucket_minimum_one?: Maybe<Bucket_Minimum>;
  /** insert data into the table: "bucket_offset" */
  insert_bucket_offset?: Maybe<Bucket_Offset_Mutation_Response>;
  /** insert a single row into the table: "bucket_offset" */
  insert_bucket_offset_one?: Maybe<Bucket_Offset>;
  /** insert a single row into the table: "bucket" */
  insert_bucket_one?: Maybe<Bucket>;
  /** insert data into the table: "bucket_rate" */
  insert_bucket_rate?: Maybe<Bucket_Rate_Mutation_Response>;
  /** insert data into the table: "bucket_rate_exception" */
  insert_bucket_rate_exception?: Maybe<Bucket_Rate_Exception_Mutation_Response>;
  /** insert a single row into the table: "bucket_rate_exception" */
  insert_bucket_rate_exception_one?: Maybe<Bucket_Rate_Exception>;
  /** insert data into the table: "bucket_rate_factor" */
  insert_bucket_rate_factor?: Maybe<Bucket_Rate_Factor_Mutation_Response>;
  /** insert a single row into the table: "bucket_rate_factor" */
  insert_bucket_rate_factor_one?: Maybe<Bucket_Rate_Factor>;
  /** insert data into the table: "bucket_rate_offset" */
  insert_bucket_rate_offset?: Maybe<Bucket_Rate_Offset_Mutation_Response>;
  /** insert a single row into the table: "bucket_rate_offset" */
  insert_bucket_rate_offset_one?: Maybe<Bucket_Rate_Offset>;
  /** insert a single row into the table: "bucket_rate" */
  insert_bucket_rate_one?: Maybe<Bucket_Rate>;
  /** insert data into the table: "bucket_rounding" */
  insert_bucket_rounding?: Maybe<Bucket_Rounding_Mutation_Response>;
  /** insert a single row into the table: "bucket_rounding" */
  insert_bucket_rounding_one?: Maybe<Bucket_Rounding>;
  /** insert data into the table: "commission_type" */
  insert_commission_type?: Maybe<Commission_Type_Mutation_Response>;
  /** insert a single row into the table: "commission_type" */
  insert_commission_type_one?: Maybe<Commission_Type>;
  /** insert data into the table: "control_type" */
  insert_control_type?: Maybe<Control_Type_Mutation_Response>;
  /** insert a single row into the table: "control_type" */
  insert_control_type_one?: Maybe<Control_Type>;
  /** insert data into the table: "dealer_product" */
  insert_dealer_product?: Maybe<Dealer_Product_Mutation_Response>;
  /** insert a single row into the table: "dealer_product" */
  insert_dealer_product_one?: Maybe<Dealer_Product>;
  /** insert data into the table: "ledger_event" */
  insert_ledger_event?: Maybe<Ledger_Event_Mutation_Response>;
  /** insert a single row into the table: "ledger_event" */
  insert_ledger_event_one?: Maybe<Ledger_Event>;
  /** insert data into the table: "non_sellable_combination" */
  insert_non_sellable_combination?: Maybe<Non_Sellable_Combination_Mutation_Response>;
  /** insert a single row into the table: "non_sellable_combination" */
  insert_non_sellable_combination_one?: Maybe<Non_Sellable_Combination>;
  /** insert data into the table: "non_sellable_combination_option" */
  insert_non_sellable_combination_option?: Maybe<Non_Sellable_Combination_Option_Mutation_Response>;
  /** insert a single row into the table: "non_sellable_combination_option" */
  insert_non_sellable_combination_option_one?: Maybe<Non_Sellable_Combination_Option>;
  /** insert data into the table: "offset_type" */
  insert_offset_type?: Maybe<Offset_Type_Mutation_Response>;
  /** insert a single row into the table: "offset_type" */
  insert_offset_type_one?: Maybe<Offset_Type>;
  /** insert data into the table: "operator" */
  insert_operator?: Maybe<Operator_Mutation_Response>;
  /** insert a single row into the table: "operator" */
  insert_operator_one?: Maybe<Operator>;
  /** insert data into the table: "parameter" */
  insert_parameter?: Maybe<Parameter_Mutation_Response>;
  /** insert data into the table: "parameter_key" */
  insert_parameter_key?: Maybe<Parameter_Key_Mutation_Response>;
  /** insert a single row into the table: "parameter_key" */
  insert_parameter_key_one?: Maybe<Parameter_Key>;
  /** insert data into the table: "parameter_key_value" */
  insert_parameter_key_value?: Maybe<Parameter_Key_Value_Mutation_Response>;
  /** insert a single row into the table: "parameter_key_value" */
  insert_parameter_key_value_one?: Maybe<Parameter_Key_Value>;
  /** insert a single row into the table: "parameter" */
  insert_parameter_one?: Maybe<Parameter>;
  /** insert data into the table: "parameter_sub_key" */
  insert_parameter_sub_key?: Maybe<Parameter_Sub_Key_Mutation_Response>;
  /** insert data into the table: "parameter_sub_key_bucket_discount" */
  insert_parameter_sub_key_bucket_discount?: Maybe<Parameter_Sub_Key_Bucket_Discount_Mutation_Response>;
  /** insert a single row into the table: "parameter_sub_key_bucket_discount" */
  insert_parameter_sub_key_bucket_discount_one?: Maybe<Parameter_Sub_Key_Bucket_Discount>;
  /** insert a single row into the table: "parameter_sub_key" */
  insert_parameter_sub_key_one?: Maybe<Parameter_Sub_Key>;
  /** insert data into the table: "parameter_subtype" */
  insert_parameter_subtype?: Maybe<Parameter_Subtype_Mutation_Response>;
  /** insert a single row into the table: "parameter_subtype" */
  insert_parameter_subtype_one?: Maybe<Parameter_Subtype>;
  /** insert data into the table: "parameter_subtype_options" */
  insert_parameter_subtype_options?: Maybe<Parameter_Subtype_Options_Mutation_Response>;
  /** insert a single row into the table: "parameter_subtype_options" */
  insert_parameter_subtype_options_one?: Maybe<Parameter_Subtype_Options>;
  /** insert data into the table: "parameter_type" */
  insert_parameter_type?: Maybe<Parameter_Type_Mutation_Response>;
  /** insert a single row into the table: "parameter_type" */
  insert_parameter_type_one?: Maybe<Parameter_Type>;
  /** insert data into the table: "payee" */
  insert_payee?: Maybe<Payee_Mutation_Response>;
  /** insert a single row into the table: "payee" */
  insert_payee_one?: Maybe<Payee>;
  /** insert data into the table: "product" */
  insert_product?: Maybe<Product_Mutation_Response>;
  /** insert data into the table: "product_dealer_offset" */
  insert_product_dealer_offset?: Maybe<Product_Dealer_Offset_Mutation_Response>;
  /** insert a single row into the table: "product_dealer_offset" */
  insert_product_dealer_offset_one?: Maybe<Product_Dealer_Offset>;
  /** insert data into the table: "product_msrp_operation" */
  insert_product_msrp_operation?: Maybe<Product_Msrp_Operation_Mutation_Response>;
  /** insert a single row into the table: "product_msrp_operation" */
  insert_product_msrp_operation_one?: Maybe<Product_Msrp_Operation>;
  /** insert data into the table: "product_msrp_operator" */
  insert_product_msrp_operator?: Maybe<Product_Msrp_Operator_Mutation_Response>;
  /** insert a single row into the table: "product_msrp_operator" */
  insert_product_msrp_operator_one?: Maybe<Product_Msrp_Operator>;
  /** insert data into the table: "product_msrp_parameter" */
  insert_product_msrp_parameter?: Maybe<Product_Msrp_Parameter_Mutation_Response>;
  /** insert data into the table: "product_msrp_parameter_key" */
  insert_product_msrp_parameter_key?: Maybe<Product_Msrp_Parameter_Key_Mutation_Response>;
  /** insert data into the table: "product_msrp_parameter_key_combination" */
  insert_product_msrp_parameter_key_combination?: Maybe<Product_Msrp_Parameter_Key_Combination_Mutation_Response>;
  /** insert a single row into the table: "product_msrp_parameter_key_combination" */
  insert_product_msrp_parameter_key_combination_one?: Maybe<Product_Msrp_Parameter_Key_Combination>;
  /** insert a single row into the table: "product_msrp_parameter_key" */
  insert_product_msrp_parameter_key_one?: Maybe<Product_Msrp_Parameter_Key>;
  /** insert a single row into the table: "product_msrp_parameter" */
  insert_product_msrp_parameter_one?: Maybe<Product_Msrp_Parameter>;
  /** insert a single row into the table: "product" */
  insert_product_one?: Maybe<Product>;
  /** insert data into the table: "product_parameter_key" */
  insert_product_parameter_key?: Maybe<Product_Parameter_Key_Mutation_Response>;
  /** insert data into the table: "product_parameter_key_combination" */
  insert_product_parameter_key_combination?: Maybe<Product_Parameter_Key_Combination_Mutation_Response>;
  /** insert a single row into the table: "product_parameter_key_combination" */
  insert_product_parameter_key_combination_one?: Maybe<Product_Parameter_Key_Combination>;
  /** insert a single row into the table: "product_parameter_key" */
  insert_product_parameter_key_one?: Maybe<Product_Parameter_Key>;
  /** insert data into the table: "product_parameter_key_sub_combination" */
  insert_product_parameter_key_sub_combination?: Maybe<Product_Parameter_Key_Sub_Combination_Mutation_Response>;
  /** insert a single row into the table: "product_parameter_key_sub_combination" */
  insert_product_parameter_key_sub_combination_one?: Maybe<Product_Parameter_Key_Sub_Combination>;
  /** insert data into the table: "product_type" */
  insert_product_type?: Maybe<Product_Type_Mutation_Response>;
  /** insert a single row into the table: "product_type" */
  insert_product_type_one?: Maybe<Product_Type>;
  /** insert data into the table: "program" */
  insert_program?: Maybe<Program_Mutation_Response>;
  /** insert a single row into the table: "program" */
  insert_program_one?: Maybe<Program>;
  /** insert data into the table: "publish_complete" */
  insert_publish_complete?: Maybe<Publish_Complete_Mutation_Response>;
  /** insert a single row into the table: "publish_complete" */
  insert_publish_complete_one?: Maybe<Publish_Complete>;
  /** insert data into the table: "publish_product" */
  insert_publish_product?: Maybe<Publish_Product_Mutation_Response>;
  /** insert a single row into the table: "publish_product" */
  insert_publish_product_one?: Maybe<Publish_Product>;
  /** insert data into the table: "rate_slice_base_slice" */
  insert_rate_slice_base_slice?: Maybe<Rate_Slice_Base_Slice_Mutation_Response>;
  /** insert a single row into the table: "rate_slice_base_slice" */
  insert_rate_slice_base_slice_one?: Maybe<Rate_Slice_Base_Slice>;
  /** insert data into the table: "risk_type" */
  insert_risk_type?: Maybe<Risk_Type_Mutation_Response>;
  /** insert a single row into the table: "risk_type" */
  insert_risk_type_one?: Maybe<Risk_Type>;
  /** insert data into the table: "round_type" */
  insert_round_type?: Maybe<Round_Type_Mutation_Response>;
  /** insert a single row into the table: "round_type" */
  insert_round_type_one?: Maybe<Round_Type>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  removeDealerChargeback: Scalars['Boolean'];
  /** execute VOLATILE function "reset_product_msrp_parameter_key_combinations" which returns "product_msrp_parameter_key_combination" */
  reset_product_msrp_parameter_key_combinations: Array<Product_Msrp_Parameter_Key_Combination>;
  /** execute VOLATILE function "reset_product_parameter_key_combinations" which returns "product_parameter_key_combination" */
  reset_product_parameter_key_combinations: Array<Product_Parameter_Key_Combination>;
  /** execute VOLATILE function "set_bucket_rate_totals" which returns "bucket_rate" */
  set_bucket_rate_totals: Array<Bucket_Rate>;
  /** execute VOLATILE function "set_is_non_sellable" which returns "non_sellable_combination_option" */
  set_is_non_sellable: Array<Non_Sellable_Combination_Option>;
  updateDealerChargeback: DealerChargeback;
  updateDealerProduct: DealerProduct;
  updateEntityCommissionType: Dealer;
  updateEntityProgram: EntityProgram;
  /** update data of the table: "audit_log" */
  update_audit_log?: Maybe<Audit_Log_Mutation_Response>;
  /** update single row of the table: "audit_log" */
  update_audit_log_by_pk?: Maybe<Audit_Log>;
  /** update data of the table: "bucket" */
  update_bucket?: Maybe<Bucket_Mutation_Response>;
  /** update data of the table: "bucket_base_parameter" */
  update_bucket_base_parameter?: Maybe<Bucket_Base_Parameter_Mutation_Response>;
  /** update single row of the table: "bucket_base_parameter" */
  update_bucket_base_parameter_by_pk?: Maybe<Bucket_Base_Parameter>;
  /** update data of the table: "bucket_base_rate" */
  update_bucket_base_rate?: Maybe<Bucket_Base_Rate_Mutation_Response>;
  /** update single row of the table: "bucket_base_rate" */
  update_bucket_base_rate_by_pk?: Maybe<Bucket_Base_Rate>;
  /** update single row of the table: "bucket" */
  update_bucket_by_pk?: Maybe<Bucket>;
  /** update data of the table: "bucket_maximum" */
  update_bucket_maximum?: Maybe<Bucket_Maximum_Mutation_Response>;
  /** update single row of the table: "bucket_maximum" */
  update_bucket_maximum_by_pk?: Maybe<Bucket_Maximum>;
  /** update data of the table: "bucket_minimum" */
  update_bucket_minimum?: Maybe<Bucket_Minimum_Mutation_Response>;
  /** update single row of the table: "bucket_minimum" */
  update_bucket_minimum_by_pk?: Maybe<Bucket_Minimum>;
  /** update data of the table: "bucket_offset" */
  update_bucket_offset?: Maybe<Bucket_Offset_Mutation_Response>;
  /** update single row of the table: "bucket_offset" */
  update_bucket_offset_by_pk?: Maybe<Bucket_Offset>;
  /** update data of the table: "bucket_rate" */
  update_bucket_rate?: Maybe<Bucket_Rate_Mutation_Response>;
  /** update single row of the table: "bucket_rate" */
  update_bucket_rate_by_pk?: Maybe<Bucket_Rate>;
  /** update data of the table: "bucket_rate_exception" */
  update_bucket_rate_exception?: Maybe<Bucket_Rate_Exception_Mutation_Response>;
  /** update single row of the table: "bucket_rate_exception" */
  update_bucket_rate_exception_by_pk?: Maybe<Bucket_Rate_Exception>;
  /** update data of the table: "bucket_rate_factor" */
  update_bucket_rate_factor?: Maybe<Bucket_Rate_Factor_Mutation_Response>;
  /** update single row of the table: "bucket_rate_factor" */
  update_bucket_rate_factor_by_pk?: Maybe<Bucket_Rate_Factor>;
  /** update data of the table: "bucket_rate_offset" */
  update_bucket_rate_offset?: Maybe<Bucket_Rate_Offset_Mutation_Response>;
  /** update single row of the table: "bucket_rate_offset" */
  update_bucket_rate_offset_by_pk?: Maybe<Bucket_Rate_Offset>;
  /** update data of the table: "bucket_rounding" */
  update_bucket_rounding?: Maybe<Bucket_Rounding_Mutation_Response>;
  /** update single row of the table: "bucket_rounding" */
  update_bucket_rounding_by_pk?: Maybe<Bucket_Rounding>;
  /** update data of the table: "commission_type" */
  update_commission_type?: Maybe<Commission_Type_Mutation_Response>;
  /** update single row of the table: "commission_type" */
  update_commission_type_by_pk?: Maybe<Commission_Type>;
  /** update data of the table: "control_type" */
  update_control_type?: Maybe<Control_Type_Mutation_Response>;
  /** update single row of the table: "control_type" */
  update_control_type_by_pk?: Maybe<Control_Type>;
  /** update data of the table: "dealer_product" */
  update_dealer_product?: Maybe<Dealer_Product_Mutation_Response>;
  /** update single row of the table: "dealer_product" */
  update_dealer_product_by_pk?: Maybe<Dealer_Product>;
  /** update data of the table: "ledger_event" */
  update_ledger_event?: Maybe<Ledger_Event_Mutation_Response>;
  /** update single row of the table: "ledger_event" */
  update_ledger_event_by_pk?: Maybe<Ledger_Event>;
  /** update data of the table: "non_sellable_combination" */
  update_non_sellable_combination?: Maybe<Non_Sellable_Combination_Mutation_Response>;
  /** update single row of the table: "non_sellable_combination" */
  update_non_sellable_combination_by_pk?: Maybe<Non_Sellable_Combination>;
  /** update data of the table: "non_sellable_combination_option" */
  update_non_sellable_combination_option?: Maybe<Non_Sellable_Combination_Option_Mutation_Response>;
  /** update single row of the table: "non_sellable_combination_option" */
  update_non_sellable_combination_option_by_pk?: Maybe<Non_Sellable_Combination_Option>;
  /** update data of the table: "offset_type" */
  update_offset_type?: Maybe<Offset_Type_Mutation_Response>;
  /** update single row of the table: "offset_type" */
  update_offset_type_by_pk?: Maybe<Offset_Type>;
  /** update data of the table: "operator" */
  update_operator?: Maybe<Operator_Mutation_Response>;
  /** update single row of the table: "operator" */
  update_operator_by_pk?: Maybe<Operator>;
  /** update data of the table: "parameter" */
  update_parameter?: Maybe<Parameter_Mutation_Response>;
  /** update single row of the table: "parameter" */
  update_parameter_by_pk?: Maybe<Parameter>;
  /** update data of the table: "parameter_key" */
  update_parameter_key?: Maybe<Parameter_Key_Mutation_Response>;
  /** update single row of the table: "parameter_key" */
  update_parameter_key_by_pk?: Maybe<Parameter_Key>;
  /** update data of the table: "parameter_key_value" */
  update_parameter_key_value?: Maybe<Parameter_Key_Value_Mutation_Response>;
  /** update single row of the table: "parameter_key_value" */
  update_parameter_key_value_by_pk?: Maybe<Parameter_Key_Value>;
  /** update data of the table: "parameter_sub_key" */
  update_parameter_sub_key?: Maybe<Parameter_Sub_Key_Mutation_Response>;
  /** update data of the table: "parameter_sub_key_bucket_discount" */
  update_parameter_sub_key_bucket_discount?: Maybe<Parameter_Sub_Key_Bucket_Discount_Mutation_Response>;
  /** update single row of the table: "parameter_sub_key_bucket_discount" */
  update_parameter_sub_key_bucket_discount_by_pk?: Maybe<Parameter_Sub_Key_Bucket_Discount>;
  /** update single row of the table: "parameter_sub_key" */
  update_parameter_sub_key_by_pk?: Maybe<Parameter_Sub_Key>;
  /** update data of the table: "parameter_subtype" */
  update_parameter_subtype?: Maybe<Parameter_Subtype_Mutation_Response>;
  /** update single row of the table: "parameter_subtype" */
  update_parameter_subtype_by_pk?: Maybe<Parameter_Subtype>;
  /** update data of the table: "parameter_subtype_options" */
  update_parameter_subtype_options?: Maybe<Parameter_Subtype_Options_Mutation_Response>;
  /** update single row of the table: "parameter_subtype_options" */
  update_parameter_subtype_options_by_pk?: Maybe<Parameter_Subtype_Options>;
  /** update data of the table: "parameter_type" */
  update_parameter_type?: Maybe<Parameter_Type_Mutation_Response>;
  /** update single row of the table: "parameter_type" */
  update_parameter_type_by_pk?: Maybe<Parameter_Type>;
  /** update data of the table: "payee" */
  update_payee?: Maybe<Payee_Mutation_Response>;
  /** update single row of the table: "payee" */
  update_payee_by_pk?: Maybe<Payee>;
  /** update data of the table: "product" */
  update_product?: Maybe<Product_Mutation_Response>;
  /** update single row of the table: "product" */
  update_product_by_pk?: Maybe<Product>;
  /** update data of the table: "product_dealer_offset" */
  update_product_dealer_offset?: Maybe<Product_Dealer_Offset_Mutation_Response>;
  /** update single row of the table: "product_dealer_offset" */
  update_product_dealer_offset_by_pk?: Maybe<Product_Dealer_Offset>;
  /** update data of the table: "product_msrp_operation" */
  update_product_msrp_operation?: Maybe<Product_Msrp_Operation_Mutation_Response>;
  /** update single row of the table: "product_msrp_operation" */
  update_product_msrp_operation_by_pk?: Maybe<Product_Msrp_Operation>;
  /** update data of the table: "product_msrp_operator" */
  update_product_msrp_operator?: Maybe<Product_Msrp_Operator_Mutation_Response>;
  /** update single row of the table: "product_msrp_operator" */
  update_product_msrp_operator_by_pk?: Maybe<Product_Msrp_Operator>;
  /** update data of the table: "product_msrp_parameter" */
  update_product_msrp_parameter?: Maybe<Product_Msrp_Parameter_Mutation_Response>;
  /** update single row of the table: "product_msrp_parameter" */
  update_product_msrp_parameter_by_pk?: Maybe<Product_Msrp_Parameter>;
  /** update data of the table: "product_msrp_parameter_key" */
  update_product_msrp_parameter_key?: Maybe<Product_Msrp_Parameter_Key_Mutation_Response>;
  /** update single row of the table: "product_msrp_parameter_key" */
  update_product_msrp_parameter_key_by_pk?: Maybe<Product_Msrp_Parameter_Key>;
  /** update data of the table: "product_msrp_parameter_key_combination" */
  update_product_msrp_parameter_key_combination?: Maybe<Product_Msrp_Parameter_Key_Combination_Mutation_Response>;
  /** update single row of the table: "product_msrp_parameter_key_combination" */
  update_product_msrp_parameter_key_combination_by_pk?: Maybe<Product_Msrp_Parameter_Key_Combination>;
  /** update data of the table: "product_parameter_key" */
  update_product_parameter_key?: Maybe<Product_Parameter_Key_Mutation_Response>;
  /** update single row of the table: "product_parameter_key" */
  update_product_parameter_key_by_pk?: Maybe<Product_Parameter_Key>;
  /** update data of the table: "product_parameter_key_combination" */
  update_product_parameter_key_combination?: Maybe<Product_Parameter_Key_Combination_Mutation_Response>;
  /** update single row of the table: "product_parameter_key_combination" */
  update_product_parameter_key_combination_by_pk?: Maybe<Product_Parameter_Key_Combination>;
  /** execute VOLATILE function "update_product_parameter_key_combinations" which returns "product_parameter_key_combination" */
  update_product_parameter_key_combinations: Array<Product_Parameter_Key_Combination>;
  /** update data of the table: "product_parameter_key_sub_combination" */
  update_product_parameter_key_sub_combination?: Maybe<Product_Parameter_Key_Sub_Combination_Mutation_Response>;
  /** update single row of the table: "product_parameter_key_sub_combination" */
  update_product_parameter_key_sub_combination_by_pk?: Maybe<Product_Parameter_Key_Sub_Combination>;
  /** update data of the table: "product_type" */
  update_product_type?: Maybe<Product_Type_Mutation_Response>;
  /** update single row of the table: "product_type" */
  update_product_type_by_pk?: Maybe<Product_Type>;
  /** update data of the table: "program" */
  update_program?: Maybe<Program_Mutation_Response>;
  /** update single row of the table: "program" */
  update_program_by_pk?: Maybe<Program>;
  /** update data of the table: "publish_complete" */
  update_publish_complete?: Maybe<Publish_Complete_Mutation_Response>;
  /** update single row of the table: "publish_complete" */
  update_publish_complete_by_pk?: Maybe<Publish_Complete>;
  /** update data of the table: "publish_product" */
  update_publish_product?: Maybe<Publish_Product_Mutation_Response>;
  /** update single row of the table: "publish_product" */
  update_publish_product_by_pk?: Maybe<Publish_Product>;
  /** update data of the table: "rate_slice_base_slice" */
  update_rate_slice_base_slice?: Maybe<Rate_Slice_Base_Slice_Mutation_Response>;
  /** update single row of the table: "rate_slice_base_slice" */
  update_rate_slice_base_slice_by_pk?: Maybe<Rate_Slice_Base_Slice>;
  /** update data of the table: "risk_type" */
  update_risk_type?: Maybe<Risk_Type_Mutation_Response>;
  /** update single row of the table: "risk_type" */
  update_risk_type_by_pk?: Maybe<Risk_Type>;
  /** update data of the table: "round_type" */
  update_round_type?: Maybe<Round_Type_Mutation_Response>;
  /** update single row of the table: "round_type" */
  update_round_type_by_pk?: Maybe<Round_Type>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
};


export type MutationCreateDealerChargebackArgs = {
  createDealerChargebackInput: CreateDealerChargebackInput;
};


export type MutationCreate_Product_Msrp_Parameter_Key_CombinationsArgs = {
  args: Create_Product_Msrp_Parameter_Key_Combinations_Args;
  distinct_on?: InputMaybe<Array<Product_Msrp_Parameter_Key_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Parameter_Key_Combination_Order_By>>;
  where?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Bool_Exp>;
};


export type MutationCreate_Product_Parameter_Key_CombinationsArgs = {
  args: Create_Product_Parameter_Key_Combinations_Args;
  distinct_on?: InputMaybe<Array<Product_Parameter_Key_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Parameter_Key_Combination_Order_By>>;
  where?: InputMaybe<Product_Parameter_Key_Combination_Bool_Exp>;
};


export type MutationDeleteEntityProgramArgs = {
  entityProgramId: Scalars['Int'];
};


export type MutationDelete_Audit_LogArgs = {
  where: Audit_Log_Bool_Exp;
};


export type MutationDelete_Audit_Log_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_BucketArgs = {
  where: Bucket_Bool_Exp;
};


export type MutationDelete_Bucket_Base_ParameterArgs = {
  where: Bucket_Base_Parameter_Bool_Exp;
};


export type MutationDelete_Bucket_Base_Parameter_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Bucket_Base_RateArgs = {
  where: Bucket_Base_Rate_Bool_Exp;
};


export type MutationDelete_Bucket_Base_Rate_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Bucket_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Bucket_MaximumArgs = {
  where: Bucket_Maximum_Bool_Exp;
};


export type MutationDelete_Bucket_Maximum_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Bucket_MinimumArgs = {
  where: Bucket_Minimum_Bool_Exp;
};


export type MutationDelete_Bucket_Minimum_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Bucket_OffsetArgs = {
  where: Bucket_Offset_Bool_Exp;
};


export type MutationDelete_Bucket_Offset_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Bucket_RateArgs = {
  where: Bucket_Rate_Bool_Exp;
};


export type MutationDelete_Bucket_Rate_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Bucket_Rate_ExceptionArgs = {
  where: Bucket_Rate_Exception_Bool_Exp;
};


export type MutationDelete_Bucket_Rate_Exception_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Bucket_Rate_FactorArgs = {
  where: Bucket_Rate_Factor_Bool_Exp;
};


export type MutationDelete_Bucket_Rate_Factor_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Bucket_Rate_OffsetArgs = {
  where: Bucket_Rate_Offset_Bool_Exp;
};


export type MutationDelete_Bucket_Rate_Offset_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Bucket_RoundingArgs = {
  where: Bucket_Rounding_Bool_Exp;
};


export type MutationDelete_Bucket_Rounding_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Commission_TypeArgs = {
  where: Commission_Type_Bool_Exp;
};


export type MutationDelete_Commission_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type MutationDelete_Control_TypeArgs = {
  where: Control_Type_Bool_Exp;
};


export type MutationDelete_Control_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type MutationDelete_Dealer_ProductArgs = {
  where: Dealer_Product_Bool_Exp;
};


export type MutationDelete_Dealer_Product_By_PkArgs = {
  id: Scalars['Int'];
};


export type MutationDelete_Ledger_EventArgs = {
  where: Ledger_Event_Bool_Exp;
};


export type MutationDelete_Ledger_Event_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Non_Sellable_CombinationArgs = {
  where: Non_Sellable_Combination_Bool_Exp;
};


export type MutationDelete_Non_Sellable_Combination_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Non_Sellable_Combination_OptionArgs = {
  where: Non_Sellable_Combination_Option_Bool_Exp;
};


export type MutationDelete_Non_Sellable_Combination_Option_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Offset_TypeArgs = {
  where: Offset_Type_Bool_Exp;
};


export type MutationDelete_Offset_Type_By_PkArgs = {
  offset_type: Scalars['String'];
};


export type MutationDelete_OperatorArgs = {
  where: Operator_Bool_Exp;
};


export type MutationDelete_Operator_By_PkArgs = {
  operator: Scalars['String'];
};


export type MutationDelete_ParameterArgs = {
  where: Parameter_Bool_Exp;
};


export type MutationDelete_Parameter_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Parameter_KeyArgs = {
  where: Parameter_Key_Bool_Exp;
};


export type MutationDelete_Parameter_Key_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Parameter_Key_ValueArgs = {
  where: Parameter_Key_Value_Bool_Exp;
};


export type MutationDelete_Parameter_Key_Value_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Parameter_Sub_KeyArgs = {
  where: Parameter_Sub_Key_Bool_Exp;
};


export type MutationDelete_Parameter_Sub_Key_Bucket_DiscountArgs = {
  where: Parameter_Sub_Key_Bucket_Discount_Bool_Exp;
};


export type MutationDelete_Parameter_Sub_Key_Bucket_Discount_By_PkArgs = {
  bucket_id: Scalars['bigint'];
  parameter_sub_key_id: Scalars['bigint'];
};


export type MutationDelete_Parameter_Sub_Key_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Parameter_SubtypeArgs = {
  where: Parameter_Subtype_Bool_Exp;
};


export type MutationDelete_Parameter_Subtype_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Parameter_Subtype_OptionsArgs = {
  where: Parameter_Subtype_Options_Bool_Exp;
};


export type MutationDelete_Parameter_Subtype_Options_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Parameter_TypeArgs = {
  where: Parameter_Type_Bool_Exp;
};


export type MutationDelete_Parameter_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_PayeeArgs = {
  where: Payee_Bool_Exp;
};


export type MutationDelete_Payee_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_ProductArgs = {
  where: Product_Bool_Exp;
};


export type MutationDelete_Product_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Product_Dealer_OffsetArgs = {
  where: Product_Dealer_Offset_Bool_Exp;
};


export type MutationDelete_Product_Dealer_Offset_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Product_Msrp_OperationArgs = {
  where: Product_Msrp_Operation_Bool_Exp;
};


export type MutationDelete_Product_Msrp_Operation_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Product_Msrp_OperatorArgs = {
  where: Product_Msrp_Operator_Bool_Exp;
};


export type MutationDelete_Product_Msrp_Operator_By_PkArgs = {
  name: Scalars['String'];
};


export type MutationDelete_Product_Msrp_ParameterArgs = {
  where: Product_Msrp_Parameter_Bool_Exp;
};


export type MutationDelete_Product_Msrp_Parameter_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Product_Msrp_Parameter_KeyArgs = {
  where: Product_Msrp_Parameter_Key_Bool_Exp;
};


export type MutationDelete_Product_Msrp_Parameter_Key_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Product_Msrp_Parameter_Key_CombinationArgs = {
  where: Product_Msrp_Parameter_Key_Combination_Bool_Exp;
};


export type MutationDelete_Product_Msrp_Parameter_Key_Combination_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Product_Msrp_Parameter_Key_CombinationsArgs = {
  args: Delete_Product_Msrp_Parameter_Key_Combinations_Args;
  distinct_on?: InputMaybe<Array<Product_Msrp_Parameter_Key_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Parameter_Key_Combination_Order_By>>;
  where?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Bool_Exp>;
};


export type MutationDelete_Product_Parameter_KeyArgs = {
  where: Product_Parameter_Key_Bool_Exp;
};


export type MutationDelete_Product_Parameter_Key_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Product_Parameter_Key_CombinationArgs = {
  where: Product_Parameter_Key_Combination_Bool_Exp;
};


export type MutationDelete_Product_Parameter_Key_Combination_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Product_Parameter_Key_CombinationsArgs = {
  args: Delete_Product_Parameter_Key_Combinations_Args;
  distinct_on?: InputMaybe<Array<Product_Parameter_Key_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Parameter_Key_Combination_Order_By>>;
  where?: InputMaybe<Product_Parameter_Key_Combination_Bool_Exp>;
};


export type MutationDelete_Product_Parameter_Key_Sub_CombinationArgs = {
  where: Product_Parameter_Key_Sub_Combination_Bool_Exp;
};


export type MutationDelete_Product_Parameter_Key_Sub_Combination_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Product_TypeArgs = {
  where: Product_Type_Bool_Exp;
};


export type MutationDelete_Product_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_ProgramArgs = {
  where: Program_Bool_Exp;
};


export type MutationDelete_Program_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Publish_CompleteArgs = {
  where: Publish_Complete_Bool_Exp;
};


export type MutationDelete_Publish_Complete_By_PkArgs = {
  publish_complete_id: Scalars['Int'];
};


export type MutationDelete_Publish_ProductArgs = {
  where: Publish_Product_Bool_Exp;
};


export type MutationDelete_Publish_Product_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Rate_Slice_Base_SliceArgs = {
  where: Rate_Slice_Base_Slice_Bool_Exp;
};


export type MutationDelete_Rate_Slice_Base_Slice_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationDelete_Risk_TypeArgs = {
  where: Risk_Type_Bool_Exp;
};


export type MutationDelete_Risk_Type_By_PkArgs = {
  risk_type_code: Scalars['String'];
};


export type MutationDelete_Round_TypeArgs = {
  where: Round_Type_Bool_Exp;
};


export type MutationDelete_Round_Type_By_PkArgs = {
  round_type: Scalars['String'];
};


export type MutationDelete_UserArgs = {
  where: User_Bool_Exp;
};


export type MutationDelete_User_By_PkArgs = {
  id: Scalars['bigint'];
};


export type MutationInsertDealerProductArgs = {
  dealerProduct: DealerProductInsert;
};


export type MutationInsertEntityProgramArgs = {
  entityProgram: EntityProgramInsert;
};


export type MutationInsert_Audit_LogArgs = {
  objects: Array<Audit_Log_Insert_Input>;
  on_conflict?: InputMaybe<Audit_Log_On_Conflict>;
};


export type MutationInsert_Audit_Log_OneArgs = {
  object: Audit_Log_Insert_Input;
  on_conflict?: InputMaybe<Audit_Log_On_Conflict>;
};


export type MutationInsert_BucketArgs = {
  objects: Array<Bucket_Insert_Input>;
  on_conflict?: InputMaybe<Bucket_On_Conflict>;
};


export type MutationInsert_Bucket_Base_ParameterArgs = {
  objects: Array<Bucket_Base_Parameter_Insert_Input>;
  on_conflict?: InputMaybe<Bucket_Base_Parameter_On_Conflict>;
};


export type MutationInsert_Bucket_Base_Parameter_OneArgs = {
  object: Bucket_Base_Parameter_Insert_Input;
  on_conflict?: InputMaybe<Bucket_Base_Parameter_On_Conflict>;
};


export type MutationInsert_Bucket_Base_RateArgs = {
  objects: Array<Bucket_Base_Rate_Insert_Input>;
  on_conflict?: InputMaybe<Bucket_Base_Rate_On_Conflict>;
};


export type MutationInsert_Bucket_Base_Rate_OneArgs = {
  object: Bucket_Base_Rate_Insert_Input;
  on_conflict?: InputMaybe<Bucket_Base_Rate_On_Conflict>;
};


export type MutationInsert_Bucket_MaximumArgs = {
  objects: Array<Bucket_Maximum_Insert_Input>;
  on_conflict?: InputMaybe<Bucket_Maximum_On_Conflict>;
};


export type MutationInsert_Bucket_Maximum_OneArgs = {
  object: Bucket_Maximum_Insert_Input;
  on_conflict?: InputMaybe<Bucket_Maximum_On_Conflict>;
};


export type MutationInsert_Bucket_MinimumArgs = {
  objects: Array<Bucket_Minimum_Insert_Input>;
  on_conflict?: InputMaybe<Bucket_Minimum_On_Conflict>;
};


export type MutationInsert_Bucket_Minimum_OneArgs = {
  object: Bucket_Minimum_Insert_Input;
  on_conflict?: InputMaybe<Bucket_Minimum_On_Conflict>;
};


export type MutationInsert_Bucket_OffsetArgs = {
  objects: Array<Bucket_Offset_Insert_Input>;
  on_conflict?: InputMaybe<Bucket_Offset_On_Conflict>;
};


export type MutationInsert_Bucket_Offset_OneArgs = {
  object: Bucket_Offset_Insert_Input;
  on_conflict?: InputMaybe<Bucket_Offset_On_Conflict>;
};


export type MutationInsert_Bucket_OneArgs = {
  object: Bucket_Insert_Input;
  on_conflict?: InputMaybe<Bucket_On_Conflict>;
};


export type MutationInsert_Bucket_RateArgs = {
  objects: Array<Bucket_Rate_Insert_Input>;
  on_conflict?: InputMaybe<Bucket_Rate_On_Conflict>;
};


export type MutationInsert_Bucket_Rate_ExceptionArgs = {
  objects: Array<Bucket_Rate_Exception_Insert_Input>;
  on_conflict?: InputMaybe<Bucket_Rate_Exception_On_Conflict>;
};


export type MutationInsert_Bucket_Rate_Exception_OneArgs = {
  object: Bucket_Rate_Exception_Insert_Input;
  on_conflict?: InputMaybe<Bucket_Rate_Exception_On_Conflict>;
};


export type MutationInsert_Bucket_Rate_FactorArgs = {
  objects: Array<Bucket_Rate_Factor_Insert_Input>;
  on_conflict?: InputMaybe<Bucket_Rate_Factor_On_Conflict>;
};


export type MutationInsert_Bucket_Rate_Factor_OneArgs = {
  object: Bucket_Rate_Factor_Insert_Input;
  on_conflict?: InputMaybe<Bucket_Rate_Factor_On_Conflict>;
};


export type MutationInsert_Bucket_Rate_OffsetArgs = {
  objects: Array<Bucket_Rate_Offset_Insert_Input>;
  on_conflict?: InputMaybe<Bucket_Rate_Offset_On_Conflict>;
};


export type MutationInsert_Bucket_Rate_Offset_OneArgs = {
  object: Bucket_Rate_Offset_Insert_Input;
  on_conflict?: InputMaybe<Bucket_Rate_Offset_On_Conflict>;
};


export type MutationInsert_Bucket_Rate_OneArgs = {
  object: Bucket_Rate_Insert_Input;
  on_conflict?: InputMaybe<Bucket_Rate_On_Conflict>;
};


export type MutationInsert_Bucket_RoundingArgs = {
  objects: Array<Bucket_Rounding_Insert_Input>;
  on_conflict?: InputMaybe<Bucket_Rounding_On_Conflict>;
};


export type MutationInsert_Bucket_Rounding_OneArgs = {
  object: Bucket_Rounding_Insert_Input;
  on_conflict?: InputMaybe<Bucket_Rounding_On_Conflict>;
};


export type MutationInsert_Commission_TypeArgs = {
  objects: Array<Commission_Type_Insert_Input>;
  on_conflict?: InputMaybe<Commission_Type_On_Conflict>;
};


export type MutationInsert_Commission_Type_OneArgs = {
  object: Commission_Type_Insert_Input;
  on_conflict?: InputMaybe<Commission_Type_On_Conflict>;
};


export type MutationInsert_Control_TypeArgs = {
  objects: Array<Control_Type_Insert_Input>;
  on_conflict?: InputMaybe<Control_Type_On_Conflict>;
};


export type MutationInsert_Control_Type_OneArgs = {
  object: Control_Type_Insert_Input;
  on_conflict?: InputMaybe<Control_Type_On_Conflict>;
};


export type MutationInsert_Dealer_ProductArgs = {
  objects: Array<Dealer_Product_Insert_Input>;
  on_conflict?: InputMaybe<Dealer_Product_On_Conflict>;
};


export type MutationInsert_Dealer_Product_OneArgs = {
  object: Dealer_Product_Insert_Input;
  on_conflict?: InputMaybe<Dealer_Product_On_Conflict>;
};


export type MutationInsert_Ledger_EventArgs = {
  objects: Array<Ledger_Event_Insert_Input>;
  on_conflict?: InputMaybe<Ledger_Event_On_Conflict>;
};


export type MutationInsert_Ledger_Event_OneArgs = {
  object: Ledger_Event_Insert_Input;
  on_conflict?: InputMaybe<Ledger_Event_On_Conflict>;
};


export type MutationInsert_Non_Sellable_CombinationArgs = {
  objects: Array<Non_Sellable_Combination_Insert_Input>;
  on_conflict?: InputMaybe<Non_Sellable_Combination_On_Conflict>;
};


export type MutationInsert_Non_Sellable_Combination_OneArgs = {
  object: Non_Sellable_Combination_Insert_Input;
  on_conflict?: InputMaybe<Non_Sellable_Combination_On_Conflict>;
};


export type MutationInsert_Non_Sellable_Combination_OptionArgs = {
  objects: Array<Non_Sellable_Combination_Option_Insert_Input>;
  on_conflict?: InputMaybe<Non_Sellable_Combination_Option_On_Conflict>;
};


export type MutationInsert_Non_Sellable_Combination_Option_OneArgs = {
  object: Non_Sellable_Combination_Option_Insert_Input;
  on_conflict?: InputMaybe<Non_Sellable_Combination_Option_On_Conflict>;
};


export type MutationInsert_Offset_TypeArgs = {
  objects: Array<Offset_Type_Insert_Input>;
  on_conflict?: InputMaybe<Offset_Type_On_Conflict>;
};


export type MutationInsert_Offset_Type_OneArgs = {
  object: Offset_Type_Insert_Input;
  on_conflict?: InputMaybe<Offset_Type_On_Conflict>;
};


export type MutationInsert_OperatorArgs = {
  objects: Array<Operator_Insert_Input>;
  on_conflict?: InputMaybe<Operator_On_Conflict>;
};


export type MutationInsert_Operator_OneArgs = {
  object: Operator_Insert_Input;
  on_conflict?: InputMaybe<Operator_On_Conflict>;
};


export type MutationInsert_ParameterArgs = {
  objects: Array<Parameter_Insert_Input>;
  on_conflict?: InputMaybe<Parameter_On_Conflict>;
};


export type MutationInsert_Parameter_KeyArgs = {
  objects: Array<Parameter_Key_Insert_Input>;
  on_conflict?: InputMaybe<Parameter_Key_On_Conflict>;
};


export type MutationInsert_Parameter_Key_OneArgs = {
  object: Parameter_Key_Insert_Input;
  on_conflict?: InputMaybe<Parameter_Key_On_Conflict>;
};


export type MutationInsert_Parameter_Key_ValueArgs = {
  objects: Array<Parameter_Key_Value_Insert_Input>;
  on_conflict?: InputMaybe<Parameter_Key_Value_On_Conflict>;
};


export type MutationInsert_Parameter_Key_Value_OneArgs = {
  object: Parameter_Key_Value_Insert_Input;
  on_conflict?: InputMaybe<Parameter_Key_Value_On_Conflict>;
};


export type MutationInsert_Parameter_OneArgs = {
  object: Parameter_Insert_Input;
  on_conflict?: InputMaybe<Parameter_On_Conflict>;
};


export type MutationInsert_Parameter_Sub_KeyArgs = {
  objects: Array<Parameter_Sub_Key_Insert_Input>;
  on_conflict?: InputMaybe<Parameter_Sub_Key_On_Conflict>;
};


export type MutationInsert_Parameter_Sub_Key_Bucket_DiscountArgs = {
  objects: Array<Parameter_Sub_Key_Bucket_Discount_Insert_Input>;
  on_conflict?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_On_Conflict>;
};


export type MutationInsert_Parameter_Sub_Key_Bucket_Discount_OneArgs = {
  object: Parameter_Sub_Key_Bucket_Discount_Insert_Input;
  on_conflict?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_On_Conflict>;
};


export type MutationInsert_Parameter_Sub_Key_OneArgs = {
  object: Parameter_Sub_Key_Insert_Input;
  on_conflict?: InputMaybe<Parameter_Sub_Key_On_Conflict>;
};


export type MutationInsert_Parameter_SubtypeArgs = {
  objects: Array<Parameter_Subtype_Insert_Input>;
  on_conflict?: InputMaybe<Parameter_Subtype_On_Conflict>;
};


export type MutationInsert_Parameter_Subtype_OneArgs = {
  object: Parameter_Subtype_Insert_Input;
  on_conflict?: InputMaybe<Parameter_Subtype_On_Conflict>;
};


export type MutationInsert_Parameter_Subtype_OptionsArgs = {
  objects: Array<Parameter_Subtype_Options_Insert_Input>;
  on_conflict?: InputMaybe<Parameter_Subtype_Options_On_Conflict>;
};


export type MutationInsert_Parameter_Subtype_Options_OneArgs = {
  object: Parameter_Subtype_Options_Insert_Input;
  on_conflict?: InputMaybe<Parameter_Subtype_Options_On_Conflict>;
};


export type MutationInsert_Parameter_TypeArgs = {
  objects: Array<Parameter_Type_Insert_Input>;
  on_conflict?: InputMaybe<Parameter_Type_On_Conflict>;
};


export type MutationInsert_Parameter_Type_OneArgs = {
  object: Parameter_Type_Insert_Input;
  on_conflict?: InputMaybe<Parameter_Type_On_Conflict>;
};


export type MutationInsert_PayeeArgs = {
  objects: Array<Payee_Insert_Input>;
  on_conflict?: InputMaybe<Payee_On_Conflict>;
};


export type MutationInsert_Payee_OneArgs = {
  object: Payee_Insert_Input;
  on_conflict?: InputMaybe<Payee_On_Conflict>;
};


export type MutationInsert_ProductArgs = {
  objects: Array<Product_Insert_Input>;
  on_conflict?: InputMaybe<Product_On_Conflict>;
};


export type MutationInsert_Product_Dealer_OffsetArgs = {
  objects: Array<Product_Dealer_Offset_Insert_Input>;
  on_conflict?: InputMaybe<Product_Dealer_Offset_On_Conflict>;
};


export type MutationInsert_Product_Dealer_Offset_OneArgs = {
  object: Product_Dealer_Offset_Insert_Input;
  on_conflict?: InputMaybe<Product_Dealer_Offset_On_Conflict>;
};


export type MutationInsert_Product_Msrp_OperationArgs = {
  objects: Array<Product_Msrp_Operation_Insert_Input>;
  on_conflict?: InputMaybe<Product_Msrp_Operation_On_Conflict>;
};


export type MutationInsert_Product_Msrp_Operation_OneArgs = {
  object: Product_Msrp_Operation_Insert_Input;
  on_conflict?: InputMaybe<Product_Msrp_Operation_On_Conflict>;
};


export type MutationInsert_Product_Msrp_OperatorArgs = {
  objects: Array<Product_Msrp_Operator_Insert_Input>;
  on_conflict?: InputMaybe<Product_Msrp_Operator_On_Conflict>;
};


export type MutationInsert_Product_Msrp_Operator_OneArgs = {
  object: Product_Msrp_Operator_Insert_Input;
  on_conflict?: InputMaybe<Product_Msrp_Operator_On_Conflict>;
};


export type MutationInsert_Product_Msrp_ParameterArgs = {
  objects: Array<Product_Msrp_Parameter_Insert_Input>;
  on_conflict?: InputMaybe<Product_Msrp_Parameter_On_Conflict>;
};


export type MutationInsert_Product_Msrp_Parameter_KeyArgs = {
  objects: Array<Product_Msrp_Parameter_Key_Insert_Input>;
  on_conflict?: InputMaybe<Product_Msrp_Parameter_Key_On_Conflict>;
};


export type MutationInsert_Product_Msrp_Parameter_Key_CombinationArgs = {
  objects: Array<Product_Msrp_Parameter_Key_Combination_Insert_Input>;
  on_conflict?: InputMaybe<Product_Msrp_Parameter_Key_Combination_On_Conflict>;
};


export type MutationInsert_Product_Msrp_Parameter_Key_Combination_OneArgs = {
  object: Product_Msrp_Parameter_Key_Combination_Insert_Input;
  on_conflict?: InputMaybe<Product_Msrp_Parameter_Key_Combination_On_Conflict>;
};


export type MutationInsert_Product_Msrp_Parameter_Key_OneArgs = {
  object: Product_Msrp_Parameter_Key_Insert_Input;
  on_conflict?: InputMaybe<Product_Msrp_Parameter_Key_On_Conflict>;
};


export type MutationInsert_Product_Msrp_Parameter_OneArgs = {
  object: Product_Msrp_Parameter_Insert_Input;
  on_conflict?: InputMaybe<Product_Msrp_Parameter_On_Conflict>;
};


export type MutationInsert_Product_OneArgs = {
  object: Product_Insert_Input;
  on_conflict?: InputMaybe<Product_On_Conflict>;
};


export type MutationInsert_Product_Parameter_KeyArgs = {
  objects: Array<Product_Parameter_Key_Insert_Input>;
  on_conflict?: InputMaybe<Product_Parameter_Key_On_Conflict>;
};


export type MutationInsert_Product_Parameter_Key_CombinationArgs = {
  objects: Array<Product_Parameter_Key_Combination_Insert_Input>;
  on_conflict?: InputMaybe<Product_Parameter_Key_Combination_On_Conflict>;
};


export type MutationInsert_Product_Parameter_Key_Combination_OneArgs = {
  object: Product_Parameter_Key_Combination_Insert_Input;
  on_conflict?: InputMaybe<Product_Parameter_Key_Combination_On_Conflict>;
};


export type MutationInsert_Product_Parameter_Key_OneArgs = {
  object: Product_Parameter_Key_Insert_Input;
  on_conflict?: InputMaybe<Product_Parameter_Key_On_Conflict>;
};


export type MutationInsert_Product_Parameter_Key_Sub_CombinationArgs = {
  objects: Array<Product_Parameter_Key_Sub_Combination_Insert_Input>;
  on_conflict?: InputMaybe<Product_Parameter_Key_Sub_Combination_On_Conflict>;
};


export type MutationInsert_Product_Parameter_Key_Sub_Combination_OneArgs = {
  object: Product_Parameter_Key_Sub_Combination_Insert_Input;
  on_conflict?: InputMaybe<Product_Parameter_Key_Sub_Combination_On_Conflict>;
};


export type MutationInsert_Product_TypeArgs = {
  objects: Array<Product_Type_Insert_Input>;
  on_conflict?: InputMaybe<Product_Type_On_Conflict>;
};


export type MutationInsert_Product_Type_OneArgs = {
  object: Product_Type_Insert_Input;
  on_conflict?: InputMaybe<Product_Type_On_Conflict>;
};


export type MutationInsert_ProgramArgs = {
  objects: Array<Program_Insert_Input>;
  on_conflict?: InputMaybe<Program_On_Conflict>;
};


export type MutationInsert_Program_OneArgs = {
  object: Program_Insert_Input;
  on_conflict?: InputMaybe<Program_On_Conflict>;
};


export type MutationInsert_Publish_CompleteArgs = {
  objects: Array<Publish_Complete_Insert_Input>;
  on_conflict?: InputMaybe<Publish_Complete_On_Conflict>;
};


export type MutationInsert_Publish_Complete_OneArgs = {
  object: Publish_Complete_Insert_Input;
  on_conflict?: InputMaybe<Publish_Complete_On_Conflict>;
};


export type MutationInsert_Publish_ProductArgs = {
  objects: Array<Publish_Product_Insert_Input>;
  on_conflict?: InputMaybe<Publish_Product_On_Conflict>;
};


export type MutationInsert_Publish_Product_OneArgs = {
  object: Publish_Product_Insert_Input;
  on_conflict?: InputMaybe<Publish_Product_On_Conflict>;
};


export type MutationInsert_Rate_Slice_Base_SliceArgs = {
  objects: Array<Rate_Slice_Base_Slice_Insert_Input>;
  on_conflict?: InputMaybe<Rate_Slice_Base_Slice_On_Conflict>;
};


export type MutationInsert_Rate_Slice_Base_Slice_OneArgs = {
  object: Rate_Slice_Base_Slice_Insert_Input;
  on_conflict?: InputMaybe<Rate_Slice_Base_Slice_On_Conflict>;
};


export type MutationInsert_Risk_TypeArgs = {
  objects: Array<Risk_Type_Insert_Input>;
  on_conflict?: InputMaybe<Risk_Type_On_Conflict>;
};


export type MutationInsert_Risk_Type_OneArgs = {
  object: Risk_Type_Insert_Input;
  on_conflict?: InputMaybe<Risk_Type_On_Conflict>;
};


export type MutationInsert_Round_TypeArgs = {
  objects: Array<Round_Type_Insert_Input>;
  on_conflict?: InputMaybe<Round_Type_On_Conflict>;
};


export type MutationInsert_Round_Type_OneArgs = {
  object: Round_Type_Insert_Input;
  on_conflict?: InputMaybe<Round_Type_On_Conflict>;
};


export type MutationInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


export type MutationInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


export type MutationRemoveDealerChargebackArgs = {
  id: Scalars['Int'];
};


export type MutationReset_Product_Msrp_Parameter_Key_CombinationsArgs = {
  args: Reset_Product_Msrp_Parameter_Key_Combinations_Args;
  distinct_on?: InputMaybe<Array<Product_Msrp_Parameter_Key_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Parameter_Key_Combination_Order_By>>;
  where?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Bool_Exp>;
};


export type MutationReset_Product_Parameter_Key_CombinationsArgs = {
  args: Reset_Product_Parameter_Key_Combinations_Args;
  distinct_on?: InputMaybe<Array<Product_Parameter_Key_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Parameter_Key_Combination_Order_By>>;
  where?: InputMaybe<Product_Parameter_Key_Combination_Bool_Exp>;
};


export type MutationSet_Bucket_Rate_TotalsArgs = {
  args: Set_Bucket_Rate_Totals_Args;
  distinct_on?: InputMaybe<Array<Bucket_Rate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Bool_Exp>;
};


export type MutationSet_Is_Non_SellableArgs = {
  args: Set_Is_Non_Sellable_Args;
  distinct_on?: InputMaybe<Array<Non_Sellable_Combination_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Non_Sellable_Combination_Option_Order_By>>;
  where?: InputMaybe<Non_Sellable_Combination_Option_Bool_Exp>;
};


export type MutationUpdateDealerChargebackArgs = {
  updateDealerChargebackInput: UpdateDealerChargebackInput;
};


export type MutationUpdateDealerProductArgs = {
  dealerProduct: DealerProductUpdate;
};


export type MutationUpdateEntityCommissionTypeArgs = {
  entityCommission: EntityCommissionTypeUpdate;
};


export type MutationUpdateEntityProgramArgs = {
  entityProgram: EntityProgramUpdate;
};


export type MutationUpdate_Audit_LogArgs = {
  _inc?: InputMaybe<Audit_Log_Inc_Input>;
  _set?: InputMaybe<Audit_Log_Set_Input>;
  where: Audit_Log_Bool_Exp;
};


export type MutationUpdate_Audit_Log_By_PkArgs = {
  _inc?: InputMaybe<Audit_Log_Inc_Input>;
  _set?: InputMaybe<Audit_Log_Set_Input>;
  pk_columns: Audit_Log_Pk_Columns_Input;
};


export type MutationUpdate_BucketArgs = {
  _inc?: InputMaybe<Bucket_Inc_Input>;
  _set?: InputMaybe<Bucket_Set_Input>;
  where: Bucket_Bool_Exp;
};


export type MutationUpdate_Bucket_Base_ParameterArgs = {
  _inc?: InputMaybe<Bucket_Base_Parameter_Inc_Input>;
  _set?: InputMaybe<Bucket_Base_Parameter_Set_Input>;
  where: Bucket_Base_Parameter_Bool_Exp;
};


export type MutationUpdate_Bucket_Base_Parameter_By_PkArgs = {
  _inc?: InputMaybe<Bucket_Base_Parameter_Inc_Input>;
  _set?: InputMaybe<Bucket_Base_Parameter_Set_Input>;
  pk_columns: Bucket_Base_Parameter_Pk_Columns_Input;
};


export type MutationUpdate_Bucket_Base_RateArgs = {
  _inc?: InputMaybe<Bucket_Base_Rate_Inc_Input>;
  _set?: InputMaybe<Bucket_Base_Rate_Set_Input>;
  where: Bucket_Base_Rate_Bool_Exp;
};


export type MutationUpdate_Bucket_Base_Rate_By_PkArgs = {
  _inc?: InputMaybe<Bucket_Base_Rate_Inc_Input>;
  _set?: InputMaybe<Bucket_Base_Rate_Set_Input>;
  pk_columns: Bucket_Base_Rate_Pk_Columns_Input;
};


export type MutationUpdate_Bucket_By_PkArgs = {
  _inc?: InputMaybe<Bucket_Inc_Input>;
  _set?: InputMaybe<Bucket_Set_Input>;
  pk_columns: Bucket_Pk_Columns_Input;
};


export type MutationUpdate_Bucket_MaximumArgs = {
  _inc?: InputMaybe<Bucket_Maximum_Inc_Input>;
  _set?: InputMaybe<Bucket_Maximum_Set_Input>;
  where: Bucket_Maximum_Bool_Exp;
};


export type MutationUpdate_Bucket_Maximum_By_PkArgs = {
  _inc?: InputMaybe<Bucket_Maximum_Inc_Input>;
  _set?: InputMaybe<Bucket_Maximum_Set_Input>;
  pk_columns: Bucket_Maximum_Pk_Columns_Input;
};


export type MutationUpdate_Bucket_MinimumArgs = {
  _inc?: InputMaybe<Bucket_Minimum_Inc_Input>;
  _set?: InputMaybe<Bucket_Minimum_Set_Input>;
  where: Bucket_Minimum_Bool_Exp;
};


export type MutationUpdate_Bucket_Minimum_By_PkArgs = {
  _inc?: InputMaybe<Bucket_Minimum_Inc_Input>;
  _set?: InputMaybe<Bucket_Minimum_Set_Input>;
  pk_columns: Bucket_Minimum_Pk_Columns_Input;
};


export type MutationUpdate_Bucket_OffsetArgs = {
  _inc?: InputMaybe<Bucket_Offset_Inc_Input>;
  _set?: InputMaybe<Bucket_Offset_Set_Input>;
  where: Bucket_Offset_Bool_Exp;
};


export type MutationUpdate_Bucket_Offset_By_PkArgs = {
  _inc?: InputMaybe<Bucket_Offset_Inc_Input>;
  _set?: InputMaybe<Bucket_Offset_Set_Input>;
  pk_columns: Bucket_Offset_Pk_Columns_Input;
};


export type MutationUpdate_Bucket_RateArgs = {
  _inc?: InputMaybe<Bucket_Rate_Inc_Input>;
  _set?: InputMaybe<Bucket_Rate_Set_Input>;
  where: Bucket_Rate_Bool_Exp;
};


export type MutationUpdate_Bucket_Rate_By_PkArgs = {
  _inc?: InputMaybe<Bucket_Rate_Inc_Input>;
  _set?: InputMaybe<Bucket_Rate_Set_Input>;
  pk_columns: Bucket_Rate_Pk_Columns_Input;
};


export type MutationUpdate_Bucket_Rate_ExceptionArgs = {
  _inc?: InputMaybe<Bucket_Rate_Exception_Inc_Input>;
  _set?: InputMaybe<Bucket_Rate_Exception_Set_Input>;
  where: Bucket_Rate_Exception_Bool_Exp;
};


export type MutationUpdate_Bucket_Rate_Exception_By_PkArgs = {
  _inc?: InputMaybe<Bucket_Rate_Exception_Inc_Input>;
  _set?: InputMaybe<Bucket_Rate_Exception_Set_Input>;
  pk_columns: Bucket_Rate_Exception_Pk_Columns_Input;
};


export type MutationUpdate_Bucket_Rate_FactorArgs = {
  _inc?: InputMaybe<Bucket_Rate_Factor_Inc_Input>;
  _set?: InputMaybe<Bucket_Rate_Factor_Set_Input>;
  where: Bucket_Rate_Factor_Bool_Exp;
};


export type MutationUpdate_Bucket_Rate_Factor_By_PkArgs = {
  _inc?: InputMaybe<Bucket_Rate_Factor_Inc_Input>;
  _set?: InputMaybe<Bucket_Rate_Factor_Set_Input>;
  pk_columns: Bucket_Rate_Factor_Pk_Columns_Input;
};


export type MutationUpdate_Bucket_Rate_OffsetArgs = {
  _inc?: InputMaybe<Bucket_Rate_Offset_Inc_Input>;
  _set?: InputMaybe<Bucket_Rate_Offset_Set_Input>;
  where: Bucket_Rate_Offset_Bool_Exp;
};


export type MutationUpdate_Bucket_Rate_Offset_By_PkArgs = {
  _inc?: InputMaybe<Bucket_Rate_Offset_Inc_Input>;
  _set?: InputMaybe<Bucket_Rate_Offset_Set_Input>;
  pk_columns: Bucket_Rate_Offset_Pk_Columns_Input;
};


export type MutationUpdate_Bucket_RoundingArgs = {
  _inc?: InputMaybe<Bucket_Rounding_Inc_Input>;
  _set?: InputMaybe<Bucket_Rounding_Set_Input>;
  where: Bucket_Rounding_Bool_Exp;
};


export type MutationUpdate_Bucket_Rounding_By_PkArgs = {
  _inc?: InputMaybe<Bucket_Rounding_Inc_Input>;
  _set?: InputMaybe<Bucket_Rounding_Set_Input>;
  pk_columns: Bucket_Rounding_Pk_Columns_Input;
};


export type MutationUpdate_Commission_TypeArgs = {
  _inc?: InputMaybe<Commission_Type_Inc_Input>;
  _set?: InputMaybe<Commission_Type_Set_Input>;
  where: Commission_Type_Bool_Exp;
};


export type MutationUpdate_Commission_Type_By_PkArgs = {
  _inc?: InputMaybe<Commission_Type_Inc_Input>;
  _set?: InputMaybe<Commission_Type_Set_Input>;
  pk_columns: Commission_Type_Pk_Columns_Input;
};


export type MutationUpdate_Control_TypeArgs = {
  _set?: InputMaybe<Control_Type_Set_Input>;
  where: Control_Type_Bool_Exp;
};


export type MutationUpdate_Control_Type_By_PkArgs = {
  _set?: InputMaybe<Control_Type_Set_Input>;
  pk_columns: Control_Type_Pk_Columns_Input;
};


export type MutationUpdate_Dealer_ProductArgs = {
  _inc?: InputMaybe<Dealer_Product_Inc_Input>;
  _set?: InputMaybe<Dealer_Product_Set_Input>;
  where: Dealer_Product_Bool_Exp;
};


export type MutationUpdate_Dealer_Product_By_PkArgs = {
  _inc?: InputMaybe<Dealer_Product_Inc_Input>;
  _set?: InputMaybe<Dealer_Product_Set_Input>;
  pk_columns: Dealer_Product_Pk_Columns_Input;
};


export type MutationUpdate_Ledger_EventArgs = {
  _inc?: InputMaybe<Ledger_Event_Inc_Input>;
  _set?: InputMaybe<Ledger_Event_Set_Input>;
  where: Ledger_Event_Bool_Exp;
};


export type MutationUpdate_Ledger_Event_By_PkArgs = {
  _inc?: InputMaybe<Ledger_Event_Inc_Input>;
  _set?: InputMaybe<Ledger_Event_Set_Input>;
  pk_columns: Ledger_Event_Pk_Columns_Input;
};


export type MutationUpdate_Non_Sellable_CombinationArgs = {
  _append?: InputMaybe<Non_Sellable_Combination_Append_Input>;
  _delete_at_path?: InputMaybe<Non_Sellable_Combination_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Non_Sellable_Combination_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Non_Sellable_Combination_Delete_Key_Input>;
  _inc?: InputMaybe<Non_Sellable_Combination_Inc_Input>;
  _prepend?: InputMaybe<Non_Sellable_Combination_Prepend_Input>;
  _set?: InputMaybe<Non_Sellable_Combination_Set_Input>;
  where: Non_Sellable_Combination_Bool_Exp;
};


export type MutationUpdate_Non_Sellable_Combination_By_PkArgs = {
  _append?: InputMaybe<Non_Sellable_Combination_Append_Input>;
  _delete_at_path?: InputMaybe<Non_Sellable_Combination_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Non_Sellable_Combination_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Non_Sellable_Combination_Delete_Key_Input>;
  _inc?: InputMaybe<Non_Sellable_Combination_Inc_Input>;
  _prepend?: InputMaybe<Non_Sellable_Combination_Prepend_Input>;
  _set?: InputMaybe<Non_Sellable_Combination_Set_Input>;
  pk_columns: Non_Sellable_Combination_Pk_Columns_Input;
};


export type MutationUpdate_Non_Sellable_Combination_OptionArgs = {
  _inc?: InputMaybe<Non_Sellable_Combination_Option_Inc_Input>;
  _set?: InputMaybe<Non_Sellable_Combination_Option_Set_Input>;
  where: Non_Sellable_Combination_Option_Bool_Exp;
};


export type MutationUpdate_Non_Sellable_Combination_Option_By_PkArgs = {
  _inc?: InputMaybe<Non_Sellable_Combination_Option_Inc_Input>;
  _set?: InputMaybe<Non_Sellable_Combination_Option_Set_Input>;
  pk_columns: Non_Sellable_Combination_Option_Pk_Columns_Input;
};


export type MutationUpdate_Offset_TypeArgs = {
  _set?: InputMaybe<Offset_Type_Set_Input>;
  where: Offset_Type_Bool_Exp;
};


export type MutationUpdate_Offset_Type_By_PkArgs = {
  _set?: InputMaybe<Offset_Type_Set_Input>;
  pk_columns: Offset_Type_Pk_Columns_Input;
};


export type MutationUpdate_OperatorArgs = {
  _set?: InputMaybe<Operator_Set_Input>;
  where: Operator_Bool_Exp;
};


export type MutationUpdate_Operator_By_PkArgs = {
  _set?: InputMaybe<Operator_Set_Input>;
  pk_columns: Operator_Pk_Columns_Input;
};


export type MutationUpdate_ParameterArgs = {
  _inc?: InputMaybe<Parameter_Inc_Input>;
  _set?: InputMaybe<Parameter_Set_Input>;
  where: Parameter_Bool_Exp;
};


export type MutationUpdate_Parameter_By_PkArgs = {
  _inc?: InputMaybe<Parameter_Inc_Input>;
  _set?: InputMaybe<Parameter_Set_Input>;
  pk_columns: Parameter_Pk_Columns_Input;
};


export type MutationUpdate_Parameter_KeyArgs = {
  _inc?: InputMaybe<Parameter_Key_Inc_Input>;
  _set?: InputMaybe<Parameter_Key_Set_Input>;
  where: Parameter_Key_Bool_Exp;
};


export type MutationUpdate_Parameter_Key_By_PkArgs = {
  _inc?: InputMaybe<Parameter_Key_Inc_Input>;
  _set?: InputMaybe<Parameter_Key_Set_Input>;
  pk_columns: Parameter_Key_Pk_Columns_Input;
};


export type MutationUpdate_Parameter_Key_ValueArgs = {
  _inc?: InputMaybe<Parameter_Key_Value_Inc_Input>;
  _set?: InputMaybe<Parameter_Key_Value_Set_Input>;
  where: Parameter_Key_Value_Bool_Exp;
};


export type MutationUpdate_Parameter_Key_Value_By_PkArgs = {
  _inc?: InputMaybe<Parameter_Key_Value_Inc_Input>;
  _set?: InputMaybe<Parameter_Key_Value_Set_Input>;
  pk_columns: Parameter_Key_Value_Pk_Columns_Input;
};


export type MutationUpdate_Parameter_Sub_KeyArgs = {
  _inc?: InputMaybe<Parameter_Sub_Key_Inc_Input>;
  _set?: InputMaybe<Parameter_Sub_Key_Set_Input>;
  where: Parameter_Sub_Key_Bool_Exp;
};


export type MutationUpdate_Parameter_Sub_Key_Bucket_DiscountArgs = {
  _inc?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Inc_Input>;
  _set?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Set_Input>;
  where: Parameter_Sub_Key_Bucket_Discount_Bool_Exp;
};


export type MutationUpdate_Parameter_Sub_Key_Bucket_Discount_By_PkArgs = {
  _inc?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Inc_Input>;
  _set?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Set_Input>;
  pk_columns: Parameter_Sub_Key_Bucket_Discount_Pk_Columns_Input;
};


export type MutationUpdate_Parameter_Sub_Key_By_PkArgs = {
  _inc?: InputMaybe<Parameter_Sub_Key_Inc_Input>;
  _set?: InputMaybe<Parameter_Sub_Key_Set_Input>;
  pk_columns: Parameter_Sub_Key_Pk_Columns_Input;
};


export type MutationUpdate_Parameter_SubtypeArgs = {
  _inc?: InputMaybe<Parameter_Subtype_Inc_Input>;
  _set?: InputMaybe<Parameter_Subtype_Set_Input>;
  where: Parameter_Subtype_Bool_Exp;
};


export type MutationUpdate_Parameter_Subtype_By_PkArgs = {
  _inc?: InputMaybe<Parameter_Subtype_Inc_Input>;
  _set?: InputMaybe<Parameter_Subtype_Set_Input>;
  pk_columns: Parameter_Subtype_Pk_Columns_Input;
};


export type MutationUpdate_Parameter_Subtype_OptionsArgs = {
  _inc?: InputMaybe<Parameter_Subtype_Options_Inc_Input>;
  _set?: InputMaybe<Parameter_Subtype_Options_Set_Input>;
  where: Parameter_Subtype_Options_Bool_Exp;
};


export type MutationUpdate_Parameter_Subtype_Options_By_PkArgs = {
  _inc?: InputMaybe<Parameter_Subtype_Options_Inc_Input>;
  _set?: InputMaybe<Parameter_Subtype_Options_Set_Input>;
  pk_columns: Parameter_Subtype_Options_Pk_Columns_Input;
};


export type MutationUpdate_Parameter_TypeArgs = {
  _inc?: InputMaybe<Parameter_Type_Inc_Input>;
  _set?: InputMaybe<Parameter_Type_Set_Input>;
  where: Parameter_Type_Bool_Exp;
};


export type MutationUpdate_Parameter_Type_By_PkArgs = {
  _inc?: InputMaybe<Parameter_Type_Inc_Input>;
  _set?: InputMaybe<Parameter_Type_Set_Input>;
  pk_columns: Parameter_Type_Pk_Columns_Input;
};


export type MutationUpdate_PayeeArgs = {
  _inc?: InputMaybe<Payee_Inc_Input>;
  _set?: InputMaybe<Payee_Set_Input>;
  where: Payee_Bool_Exp;
};


export type MutationUpdate_Payee_By_PkArgs = {
  _inc?: InputMaybe<Payee_Inc_Input>;
  _set?: InputMaybe<Payee_Set_Input>;
  pk_columns: Payee_Pk_Columns_Input;
};


export type MutationUpdate_ProductArgs = {
  _inc?: InputMaybe<Product_Inc_Input>;
  _set?: InputMaybe<Product_Set_Input>;
  where: Product_Bool_Exp;
};


export type MutationUpdate_Product_By_PkArgs = {
  _inc?: InputMaybe<Product_Inc_Input>;
  _set?: InputMaybe<Product_Set_Input>;
  pk_columns: Product_Pk_Columns_Input;
};


export type MutationUpdate_Product_Dealer_OffsetArgs = {
  _inc?: InputMaybe<Product_Dealer_Offset_Inc_Input>;
  _set?: InputMaybe<Product_Dealer_Offset_Set_Input>;
  where: Product_Dealer_Offset_Bool_Exp;
};


export type MutationUpdate_Product_Dealer_Offset_By_PkArgs = {
  _inc?: InputMaybe<Product_Dealer_Offset_Inc_Input>;
  _set?: InputMaybe<Product_Dealer_Offset_Set_Input>;
  pk_columns: Product_Dealer_Offset_Pk_Columns_Input;
};


export type MutationUpdate_Product_Msrp_OperationArgs = {
  _inc?: InputMaybe<Product_Msrp_Operation_Inc_Input>;
  _set?: InputMaybe<Product_Msrp_Operation_Set_Input>;
  where: Product_Msrp_Operation_Bool_Exp;
};


export type MutationUpdate_Product_Msrp_Operation_By_PkArgs = {
  _inc?: InputMaybe<Product_Msrp_Operation_Inc_Input>;
  _set?: InputMaybe<Product_Msrp_Operation_Set_Input>;
  pk_columns: Product_Msrp_Operation_Pk_Columns_Input;
};


export type MutationUpdate_Product_Msrp_OperatorArgs = {
  _inc?: InputMaybe<Product_Msrp_Operator_Inc_Input>;
  _set?: InputMaybe<Product_Msrp_Operator_Set_Input>;
  where: Product_Msrp_Operator_Bool_Exp;
};


export type MutationUpdate_Product_Msrp_Operator_By_PkArgs = {
  _inc?: InputMaybe<Product_Msrp_Operator_Inc_Input>;
  _set?: InputMaybe<Product_Msrp_Operator_Set_Input>;
  pk_columns: Product_Msrp_Operator_Pk_Columns_Input;
};


export type MutationUpdate_Product_Msrp_ParameterArgs = {
  _inc?: InputMaybe<Product_Msrp_Parameter_Inc_Input>;
  _set?: InputMaybe<Product_Msrp_Parameter_Set_Input>;
  where: Product_Msrp_Parameter_Bool_Exp;
};


export type MutationUpdate_Product_Msrp_Parameter_By_PkArgs = {
  _inc?: InputMaybe<Product_Msrp_Parameter_Inc_Input>;
  _set?: InputMaybe<Product_Msrp_Parameter_Set_Input>;
  pk_columns: Product_Msrp_Parameter_Pk_Columns_Input;
};


export type MutationUpdate_Product_Msrp_Parameter_KeyArgs = {
  _inc?: InputMaybe<Product_Msrp_Parameter_Key_Inc_Input>;
  _set?: InputMaybe<Product_Msrp_Parameter_Key_Set_Input>;
  where: Product_Msrp_Parameter_Key_Bool_Exp;
};


export type MutationUpdate_Product_Msrp_Parameter_Key_By_PkArgs = {
  _inc?: InputMaybe<Product_Msrp_Parameter_Key_Inc_Input>;
  _set?: InputMaybe<Product_Msrp_Parameter_Key_Set_Input>;
  pk_columns: Product_Msrp_Parameter_Key_Pk_Columns_Input;
};


export type MutationUpdate_Product_Msrp_Parameter_Key_CombinationArgs = {
  _inc?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Inc_Input>;
  _set?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Set_Input>;
  where: Product_Msrp_Parameter_Key_Combination_Bool_Exp;
};


export type MutationUpdate_Product_Msrp_Parameter_Key_Combination_By_PkArgs = {
  _inc?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Inc_Input>;
  _set?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Set_Input>;
  pk_columns: Product_Msrp_Parameter_Key_Combination_Pk_Columns_Input;
};


export type MutationUpdate_Product_Parameter_KeyArgs = {
  _inc?: InputMaybe<Product_Parameter_Key_Inc_Input>;
  _set?: InputMaybe<Product_Parameter_Key_Set_Input>;
  where: Product_Parameter_Key_Bool_Exp;
};


export type MutationUpdate_Product_Parameter_Key_By_PkArgs = {
  _inc?: InputMaybe<Product_Parameter_Key_Inc_Input>;
  _set?: InputMaybe<Product_Parameter_Key_Set_Input>;
  pk_columns: Product_Parameter_Key_Pk_Columns_Input;
};


export type MutationUpdate_Product_Parameter_Key_CombinationArgs = {
  _append?: InputMaybe<Product_Parameter_Key_Combination_Append_Input>;
  _delete_at_path?: InputMaybe<Product_Parameter_Key_Combination_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Product_Parameter_Key_Combination_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Product_Parameter_Key_Combination_Delete_Key_Input>;
  _inc?: InputMaybe<Product_Parameter_Key_Combination_Inc_Input>;
  _prepend?: InputMaybe<Product_Parameter_Key_Combination_Prepend_Input>;
  _set?: InputMaybe<Product_Parameter_Key_Combination_Set_Input>;
  where: Product_Parameter_Key_Combination_Bool_Exp;
};


export type MutationUpdate_Product_Parameter_Key_Combination_By_PkArgs = {
  _append?: InputMaybe<Product_Parameter_Key_Combination_Append_Input>;
  _delete_at_path?: InputMaybe<Product_Parameter_Key_Combination_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Product_Parameter_Key_Combination_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Product_Parameter_Key_Combination_Delete_Key_Input>;
  _inc?: InputMaybe<Product_Parameter_Key_Combination_Inc_Input>;
  _prepend?: InputMaybe<Product_Parameter_Key_Combination_Prepend_Input>;
  _set?: InputMaybe<Product_Parameter_Key_Combination_Set_Input>;
  pk_columns: Product_Parameter_Key_Combination_Pk_Columns_Input;
};


export type MutationUpdate_Product_Parameter_Key_CombinationsArgs = {
  args: Update_Product_Parameter_Key_Combinations_Args;
  distinct_on?: InputMaybe<Array<Product_Parameter_Key_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Parameter_Key_Combination_Order_By>>;
  where?: InputMaybe<Product_Parameter_Key_Combination_Bool_Exp>;
};


export type MutationUpdate_Product_Parameter_Key_Sub_CombinationArgs = {
  _inc?: InputMaybe<Product_Parameter_Key_Sub_Combination_Inc_Input>;
  _set?: InputMaybe<Product_Parameter_Key_Sub_Combination_Set_Input>;
  where: Product_Parameter_Key_Sub_Combination_Bool_Exp;
};


export type MutationUpdate_Product_Parameter_Key_Sub_Combination_By_PkArgs = {
  _inc?: InputMaybe<Product_Parameter_Key_Sub_Combination_Inc_Input>;
  _set?: InputMaybe<Product_Parameter_Key_Sub_Combination_Set_Input>;
  pk_columns: Product_Parameter_Key_Sub_Combination_Pk_Columns_Input;
};


export type MutationUpdate_Product_TypeArgs = {
  _inc?: InputMaybe<Product_Type_Inc_Input>;
  _set?: InputMaybe<Product_Type_Set_Input>;
  where: Product_Type_Bool_Exp;
};


export type MutationUpdate_Product_Type_By_PkArgs = {
  _inc?: InputMaybe<Product_Type_Inc_Input>;
  _set?: InputMaybe<Product_Type_Set_Input>;
  pk_columns: Product_Type_Pk_Columns_Input;
};


export type MutationUpdate_ProgramArgs = {
  _inc?: InputMaybe<Program_Inc_Input>;
  _set?: InputMaybe<Program_Set_Input>;
  where: Program_Bool_Exp;
};


export type MutationUpdate_Program_By_PkArgs = {
  _inc?: InputMaybe<Program_Inc_Input>;
  _set?: InputMaybe<Program_Set_Input>;
  pk_columns: Program_Pk_Columns_Input;
};


export type MutationUpdate_Publish_CompleteArgs = {
  _inc?: InputMaybe<Publish_Complete_Inc_Input>;
  _set?: InputMaybe<Publish_Complete_Set_Input>;
  where: Publish_Complete_Bool_Exp;
};


export type MutationUpdate_Publish_Complete_By_PkArgs = {
  _inc?: InputMaybe<Publish_Complete_Inc_Input>;
  _set?: InputMaybe<Publish_Complete_Set_Input>;
  pk_columns: Publish_Complete_Pk_Columns_Input;
};


export type MutationUpdate_Publish_ProductArgs = {
  _inc?: InputMaybe<Publish_Product_Inc_Input>;
  _set?: InputMaybe<Publish_Product_Set_Input>;
  where: Publish_Product_Bool_Exp;
};


export type MutationUpdate_Publish_Product_By_PkArgs = {
  _inc?: InputMaybe<Publish_Product_Inc_Input>;
  _set?: InputMaybe<Publish_Product_Set_Input>;
  pk_columns: Publish_Product_Pk_Columns_Input;
};


export type MutationUpdate_Rate_Slice_Base_SliceArgs = {
  _inc?: InputMaybe<Rate_Slice_Base_Slice_Inc_Input>;
  _set?: InputMaybe<Rate_Slice_Base_Slice_Set_Input>;
  where: Rate_Slice_Base_Slice_Bool_Exp;
};


export type MutationUpdate_Rate_Slice_Base_Slice_By_PkArgs = {
  _inc?: InputMaybe<Rate_Slice_Base_Slice_Inc_Input>;
  _set?: InputMaybe<Rate_Slice_Base_Slice_Set_Input>;
  pk_columns: Rate_Slice_Base_Slice_Pk_Columns_Input;
};


export type MutationUpdate_Risk_TypeArgs = {
  _set?: InputMaybe<Risk_Type_Set_Input>;
  where: Risk_Type_Bool_Exp;
};


export type MutationUpdate_Risk_Type_By_PkArgs = {
  _set?: InputMaybe<Risk_Type_Set_Input>;
  pk_columns: Risk_Type_Pk_Columns_Input;
};


export type MutationUpdate_Round_TypeArgs = {
  _set?: InputMaybe<Round_Type_Set_Input>;
  where: Round_Type_Bool_Exp;
};


export type MutationUpdate_Round_Type_By_PkArgs = {
  _set?: InputMaybe<Round_Type_Set_Input>;
  pk_columns: Round_Type_Pk_Columns_Input;
};


export type MutationUpdate_UserArgs = {
  _inc?: InputMaybe<User_Inc_Input>;
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


export type MutationUpdate_User_By_PkArgs = {
  _inc?: InputMaybe<User_Inc_Input>;
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};

export type NcbPercentage = {
  __typename?: 'NcbPercentage';
  endDate?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id: Scalars['Int'];
  percentage: Scalars['Decimal'];
  productType: Scalars['String'];
  programAgentCode: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type NcbPercentageAvgAggregate = {
  __typename?: 'NcbPercentageAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Decimal']>;
};

export type NcbPercentageAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  percentage?: InputMaybe<SortOrder>;
};

export type NcbPercentageCountAggregate = {
  __typename?: 'NcbPercentageCountAggregate';
  _all: Scalars['Int'];
  endDate: Scalars['Int'];
  externalId: Scalars['Int'];
  id: Scalars['Int'];
  percentage: Scalars['Int'];
  productType: Scalars['Int'];
  programAgentCode: Scalars['Int'];
  startDate: Scalars['Int'];
};

export type NcbPercentageCountOrderByAggregateInput = {
  endDate?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  percentage?: InputMaybe<SortOrder>;
  productType?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type NcbPercentageGroupBy = {
  __typename?: 'NcbPercentageGroupBy';
  _avg?: Maybe<NcbPercentageAvgAggregate>;
  _count?: Maybe<NcbPercentageCountAggregate>;
  _max?: Maybe<NcbPercentageMaxAggregate>;
  _min?: Maybe<NcbPercentageMinAggregate>;
  _sum?: Maybe<NcbPercentageSumAggregate>;
  endDate?: Maybe<Scalars['DateTime']>;
  externalId: Scalars['String'];
  id: Scalars['Int'];
  percentage: Scalars['Decimal'];
  productType: Scalars['String'];
  programAgentCode: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type NcbPercentageMaxAggregate = {
  __typename?: 'NcbPercentageMaxAggregate';
  endDate?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  percentage?: Maybe<Scalars['Decimal']>;
  productType?: Maybe<Scalars['String']>;
  programAgentCode?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type NcbPercentageMaxOrderByAggregateInput = {
  endDate?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  percentage?: InputMaybe<SortOrder>;
  productType?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type NcbPercentageMinAggregate = {
  __typename?: 'NcbPercentageMinAggregate';
  endDate?: Maybe<Scalars['DateTime']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  percentage?: Maybe<Scalars['Decimal']>;
  productType?: Maybe<Scalars['String']>;
  programAgentCode?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type NcbPercentageMinOrderByAggregateInput = {
  endDate?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  percentage?: InputMaybe<SortOrder>;
  productType?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type NcbPercentageOrderByWithAggregationInput = {
  _avg?: InputMaybe<NcbPercentageAvgOrderByAggregateInput>;
  _count?: InputMaybe<NcbPercentageCountOrderByAggregateInput>;
  _max?: InputMaybe<NcbPercentageMaxOrderByAggregateInput>;
  _min?: InputMaybe<NcbPercentageMinOrderByAggregateInput>;
  _sum?: InputMaybe<NcbPercentageSumOrderByAggregateInput>;
  endDate?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  percentage?: InputMaybe<SortOrder>;
  productType?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export type NcbPercentageOrderByWithRelationInput = {
  endDate?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  percentage?: InputMaybe<SortOrder>;
  productType?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
};

export enum NcbPercentageScalarFieldEnum {
  EndDate = 'endDate',
  ExternalId = 'externalId',
  Id = 'id',
  Percentage = 'percentage',
  ProductType = 'productType',
  ProgramAgentCode = 'programAgentCode',
  StartDate = 'startDate'
}

export type NcbPercentageScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<NcbPercentageScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<NcbPercentageScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<NcbPercentageScalarWhereWithAggregatesInput>>;
  endDate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  externalId?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  percentage?: InputMaybe<DecimalWithAggregatesFilter>;
  productType?: InputMaybe<StringWithAggregatesFilter>;
  programAgentCode?: InputMaybe<StringWithAggregatesFilter>;
  startDate?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type NcbPercentageSumAggregate = {
  __typename?: 'NcbPercentageSumAggregate';
  id?: Maybe<Scalars['Int']>;
  percentage?: Maybe<Scalars['Decimal']>;
};

export type NcbPercentageSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  percentage?: InputMaybe<SortOrder>;
};

export type NcbPercentageWhereInput = {
  AND?: InputMaybe<Array<NcbPercentageWhereInput>>;
  NOT?: InputMaybe<Array<NcbPercentageWhereInput>>;
  OR?: InputMaybe<Array<NcbPercentageWhereInput>>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  externalId?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  percentage?: InputMaybe<DecimalFilter>;
  productType?: InputMaybe<StringFilter>;
  programAgentCode?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
};

export type NcbPercentageWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedDecimalWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDecimalFilter>;
  _min?: InputMaybe<NestedDecimalFilter>;
  _sum?: InputMaybe<NestedDecimalFilter>;
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type PaymentType = {
  __typename?: 'PaymentType';
  _count?: Maybe<PaymentTypeCount>;
  commissionPayees: Array<CommissionPayee>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type PaymentTypeCommissionPayeesArgs = {
  cursor?: InputMaybe<CommissionPayeeWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommissionPayeeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommissionPayeeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommissionPayeeWhereInput>;
};

export type PaymentTypeAvgAggregate = {
  __typename?: 'PaymentTypeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PaymentTypeAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
};

export type PaymentTypeCount = {
  __typename?: 'PaymentTypeCount';
  commissionPayees: Scalars['Int'];
};

export type PaymentTypeCountAggregate = {
  __typename?: 'PaymentTypeCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PaymentTypeCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PaymentTypeGroupBy = {
  __typename?: 'PaymentTypeGroupBy';
  _avg?: Maybe<PaymentTypeAvgAggregate>;
  _count?: Maybe<PaymentTypeCountAggregate>;
  _max?: Maybe<PaymentTypeMaxAggregate>;
  _min?: Maybe<PaymentTypeMinAggregate>;
  _sum?: Maybe<PaymentTypeSumAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentTypeMaxAggregate = {
  __typename?: 'PaymentTypeMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentTypeMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PaymentTypeMinAggregate = {
  __typename?: 'PaymentTypeMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentTypeMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PaymentTypeOrderByWithAggregationInput = {
  _avg?: InputMaybe<PaymentTypeAvgOrderByAggregateInput>;
  _count?: InputMaybe<PaymentTypeCountOrderByAggregateInput>;
  _max?: InputMaybe<PaymentTypeMaxOrderByAggregateInput>;
  _min?: InputMaybe<PaymentTypeMinOrderByAggregateInput>;
  _sum?: InputMaybe<PaymentTypeSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PaymentTypeOrderByWithRelationInput = {
  commissionPayees?: InputMaybe<CommissionPayeeOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PaymentTypeRelationFilter = {
  is?: InputMaybe<PaymentTypeWhereInput>;
  isNot?: InputMaybe<PaymentTypeWhereInput>;
};

export enum PaymentTypeScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type PaymentTypeScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PaymentTypeScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PaymentTypeScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PaymentTypeScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type PaymentTypeSumAggregate = {
  __typename?: 'PaymentTypeSumAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type PaymentTypeSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
};

export type PaymentTypeWhereInput = {
  AND?: InputMaybe<Array<PaymentTypeWhereInput>>;
  NOT?: InputMaybe<Array<PaymentTypeWhereInput>>;
  OR?: InputMaybe<Array<PaymentTypeWhereInput>>;
  commissionPayees?: InputMaybe<CommissionPayeeListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type PaymentTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ProductBucketRateTotal = {
  __typename?: 'ProductBucketRateTotal';
  bucketId: Scalars['Int'];
  total: Scalars['Float'];
};

export type ProductMsrpOperation = {
  __typename?: 'ProductMsrpOperation';
  operand: Scalars['Float'];
  operator: Scalars['String'];
};

export type ProductRate = {
  __typename?: 'ProductRate';
  bucketRateTotals: Array<ProductBucketRateTotal>;
  dealerCost: Scalars['Float'];
  msrp: Scalars['Float'];
  msrpOperations?: Maybe<Array<ProductMsrpOperation>>;
  parameterKeyIds: Array<Scalars['Int']>;
};

export type ProductRatesConnection = {
  __typename?: 'ProductRatesConnection';
  nodes: Array<ProductRate>;
  totalCount: Scalars['Int'];
};

export type ProgramProductEnrollment = {
  __typename?: 'ProgramProductEnrollment';
  _count?: Maybe<ProgramProductEnrollmentCount>;
  id: Scalars['Int'];
  productCode: Scalars['String'];
  programAgentCode: Scalars['String'];
  programProductEnrollmentDealerTiers: Array<ProgramProductEnrollmentDealerTier>;
};


export type ProgramProductEnrollmentProgramProductEnrollmentDealerTiersArgs = {
  cursor?: InputMaybe<ProgramProductEnrollmentDealerTierWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProgramProductEnrollmentDealerTierScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProgramProductEnrollmentDealerTierOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProgramProductEnrollmentDealerTierWhereInput>;
};

export type ProgramProductEnrollmentAvgAggregate = {
  __typename?: 'ProgramProductEnrollmentAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ProgramProductEnrollmentAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
};

export type ProgramProductEnrollmentCount = {
  __typename?: 'ProgramProductEnrollmentCount';
  programProductEnrollmentDealerTiers: Scalars['Int'];
};

export type ProgramProductEnrollmentCountAggregate = {
  __typename?: 'ProgramProductEnrollmentCountAggregate';
  _all: Scalars['Int'];
  id: Scalars['Int'];
  productCode: Scalars['Int'];
  programAgentCode: Scalars['Int'];
};

export type ProgramProductEnrollmentCountOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  productCode?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
};

export type ProgramProductEnrollmentDealerTier = {
  __typename?: 'ProgramProductEnrollmentDealerTier';
  id: Scalars['Int'];
  name: Scalars['String'];
  programProductEnrollment: ProgramProductEnrollment;
  programProductEnrollmentId: Scalars['Int'];
};

export type ProgramProductEnrollmentDealerTierAvgAggregate = {
  __typename?: 'ProgramProductEnrollmentDealerTierAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  programProductEnrollmentId?: Maybe<Scalars['Float']>;
};

export type ProgramProductEnrollmentDealerTierAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  programProductEnrollmentId?: InputMaybe<SortOrder>;
};

export type ProgramProductEnrollmentDealerTierCountAggregate = {
  __typename?: 'ProgramProductEnrollmentDealerTierCountAggregate';
  _all: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  programProductEnrollmentId: Scalars['Int'];
};

export type ProgramProductEnrollmentDealerTierCountOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  programProductEnrollmentId?: InputMaybe<SortOrder>;
};

export type ProgramProductEnrollmentDealerTierGroupBy = {
  __typename?: 'ProgramProductEnrollmentDealerTierGroupBy';
  _avg?: Maybe<ProgramProductEnrollmentDealerTierAvgAggregate>;
  _count?: Maybe<ProgramProductEnrollmentDealerTierCountAggregate>;
  _max?: Maybe<ProgramProductEnrollmentDealerTierMaxAggregate>;
  _min?: Maybe<ProgramProductEnrollmentDealerTierMinAggregate>;
  _sum?: Maybe<ProgramProductEnrollmentDealerTierSumAggregate>;
  id: Scalars['Int'];
  name: Scalars['String'];
  programProductEnrollmentId: Scalars['Int'];
};

export type ProgramProductEnrollmentDealerTierListRelationFilter = {
  every?: InputMaybe<ProgramProductEnrollmentDealerTierWhereInput>;
  none?: InputMaybe<ProgramProductEnrollmentDealerTierWhereInput>;
  some?: InputMaybe<ProgramProductEnrollmentDealerTierWhereInput>;
};

export type ProgramProductEnrollmentDealerTierMaxAggregate = {
  __typename?: 'ProgramProductEnrollmentDealerTierMaxAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  programProductEnrollmentId?: Maybe<Scalars['Int']>;
};

export type ProgramProductEnrollmentDealerTierMaxOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  programProductEnrollmentId?: InputMaybe<SortOrder>;
};

export type ProgramProductEnrollmentDealerTierMinAggregate = {
  __typename?: 'ProgramProductEnrollmentDealerTierMinAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  programProductEnrollmentId?: Maybe<Scalars['Int']>;
};

export type ProgramProductEnrollmentDealerTierMinOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  programProductEnrollmentId?: InputMaybe<SortOrder>;
};

export type ProgramProductEnrollmentDealerTierNameProgramProductEnrollmentIdCompoundUniqueInput = {
  name: Scalars['String'];
  programProductEnrollmentId: Scalars['Int'];
};

export type ProgramProductEnrollmentDealerTierOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProgramProductEnrollmentDealerTierOrderByWithAggregationInput = {
  _avg?: InputMaybe<ProgramProductEnrollmentDealerTierAvgOrderByAggregateInput>;
  _count?: InputMaybe<ProgramProductEnrollmentDealerTierCountOrderByAggregateInput>;
  _max?: InputMaybe<ProgramProductEnrollmentDealerTierMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProgramProductEnrollmentDealerTierMinOrderByAggregateInput>;
  _sum?: InputMaybe<ProgramProductEnrollmentDealerTierSumOrderByAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  programProductEnrollmentId?: InputMaybe<SortOrder>;
};

export type ProgramProductEnrollmentDealerTierOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  programProductEnrollment?: InputMaybe<ProgramProductEnrollmentOrderByWithRelationInput>;
  programProductEnrollmentId?: InputMaybe<SortOrder>;
};

export enum ProgramProductEnrollmentDealerTierScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  ProgramProductEnrollmentId = 'programProductEnrollmentId'
}

export type ProgramProductEnrollmentDealerTierScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ProgramProductEnrollmentDealerTierScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ProgramProductEnrollmentDealerTierScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ProgramProductEnrollmentDealerTierScalarWhereWithAggregatesInput>>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  programProductEnrollmentId?: InputMaybe<IntWithAggregatesFilter>;
};

export type ProgramProductEnrollmentDealerTierSumAggregate = {
  __typename?: 'ProgramProductEnrollmentDealerTierSumAggregate';
  id?: Maybe<Scalars['Int']>;
  programProductEnrollmentId?: Maybe<Scalars['Int']>;
};

export type ProgramProductEnrollmentDealerTierSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  programProductEnrollmentId?: InputMaybe<SortOrder>;
};

export type ProgramProductEnrollmentDealerTierWhereInput = {
  AND?: InputMaybe<Array<ProgramProductEnrollmentDealerTierWhereInput>>;
  NOT?: InputMaybe<Array<ProgramProductEnrollmentDealerTierWhereInput>>;
  OR?: InputMaybe<Array<ProgramProductEnrollmentDealerTierWhereInput>>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  programProductEnrollment?: InputMaybe<ProgramProductEnrollmentRelationFilter>;
  programProductEnrollmentId?: InputMaybe<IntFilter>;
};

export type ProgramProductEnrollmentDealerTierWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  name_programProductEnrollmentId?: InputMaybe<ProgramProductEnrollmentDealerTierNameProgramProductEnrollmentIdCompoundUniqueInput>;
};

export type ProgramProductEnrollmentGroupBy = {
  __typename?: 'ProgramProductEnrollmentGroupBy';
  _avg?: Maybe<ProgramProductEnrollmentAvgAggregate>;
  _count?: Maybe<ProgramProductEnrollmentCountAggregate>;
  _max?: Maybe<ProgramProductEnrollmentMaxAggregate>;
  _min?: Maybe<ProgramProductEnrollmentMinAggregate>;
  _sum?: Maybe<ProgramProductEnrollmentSumAggregate>;
  id: Scalars['Int'];
  productCode: Scalars['String'];
  programAgentCode: Scalars['String'];
};

export type ProgramProductEnrollmentMaxAggregate = {
  __typename?: 'ProgramProductEnrollmentMaxAggregate';
  id?: Maybe<Scalars['Int']>;
  productCode?: Maybe<Scalars['String']>;
  programAgentCode?: Maybe<Scalars['String']>;
};

export type ProgramProductEnrollmentMaxOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  productCode?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
};

export type ProgramProductEnrollmentMinAggregate = {
  __typename?: 'ProgramProductEnrollmentMinAggregate';
  id?: Maybe<Scalars['Int']>;
  productCode?: Maybe<Scalars['String']>;
  programAgentCode?: Maybe<Scalars['String']>;
};

export type ProgramProductEnrollmentMinOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  productCode?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
};

export type ProgramProductEnrollmentOrderByWithAggregationInput = {
  _avg?: InputMaybe<ProgramProductEnrollmentAvgOrderByAggregateInput>;
  _count?: InputMaybe<ProgramProductEnrollmentCountOrderByAggregateInput>;
  _max?: InputMaybe<ProgramProductEnrollmentMaxOrderByAggregateInput>;
  _min?: InputMaybe<ProgramProductEnrollmentMinOrderByAggregateInput>;
  _sum?: InputMaybe<ProgramProductEnrollmentSumOrderByAggregateInput>;
  id?: InputMaybe<SortOrder>;
  productCode?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
};

export type ProgramProductEnrollmentOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  productCode?: InputMaybe<SortOrder>;
  programAgentCode?: InputMaybe<SortOrder>;
  programProductEnrollmentDealerTiers?: InputMaybe<ProgramProductEnrollmentDealerTierOrderByRelationAggregateInput>;
};

export type ProgramProductEnrollmentProgramAgentCodeProductCodeCompoundUniqueInput = {
  productCode: Scalars['String'];
  programAgentCode: Scalars['String'];
};

export type ProgramProductEnrollmentRelationFilter = {
  is?: InputMaybe<ProgramProductEnrollmentWhereInput>;
  isNot?: InputMaybe<ProgramProductEnrollmentWhereInput>;
};

export enum ProgramProductEnrollmentScalarFieldEnum {
  Id = 'id',
  ProductCode = 'productCode',
  ProgramAgentCode = 'programAgentCode'
}

export type ProgramProductEnrollmentScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ProgramProductEnrollmentScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ProgramProductEnrollmentScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ProgramProductEnrollmentScalarWhereWithAggregatesInput>>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  productCode?: InputMaybe<StringWithAggregatesFilter>;
  programAgentCode?: InputMaybe<StringWithAggregatesFilter>;
};

export type ProgramProductEnrollmentSumAggregate = {
  __typename?: 'ProgramProductEnrollmentSumAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type ProgramProductEnrollmentSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
};

export type ProgramProductEnrollmentWhereInput = {
  AND?: InputMaybe<Array<ProgramProductEnrollmentWhereInput>>;
  NOT?: InputMaybe<Array<ProgramProductEnrollmentWhereInput>>;
  OR?: InputMaybe<Array<ProgramProductEnrollmentWhereInput>>;
  id?: InputMaybe<IntFilter>;
  productCode?: InputMaybe<StringFilter>;
  programAgentCode?: InputMaybe<StringFilter>;
  programProductEnrollmentDealerTiers?: InputMaybe<ProgramProductEnrollmentDealerTierListRelationFilter>;
};

export type ProgramProductEnrollmentWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  programAgentCode_productCode?: InputMaybe<ProgramProductEnrollmentProgramAgentCodeProductCodeCompoundUniqueInput>;
};

export type PublishEntity = {
  __typename?: 'PublishEntity';
  createdAt: Scalars['DateTime'];
  entityId: Scalars['Int'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  status: Scalars['String'];
  userEmail: Scalars['String'];
  userName: Scalars['String'];
};

export type PublishEntityListRelationFilter = {
  every?: InputMaybe<PublishEntityWhereInput>;
  none?: InputMaybe<PublishEntityWhereInput>;
  some?: InputMaybe<PublishEntityWhereInput>;
};

export type PublishEntityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PublishEntityOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  dealer?: InputMaybe<DealerOrderByWithRelationInput>;
  entityId?: InputMaybe<SortOrder>;
  error?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  userEmail?: InputMaybe<SortOrder>;
  userName?: InputMaybe<SortOrder>;
};

export enum PublishEntityScalarFieldEnum {
  CreatedAt = 'createdAt',
  EntityId = 'entityId',
  Error = 'error',
  Id = 'id',
  Status = 'status',
  UserEmail = 'userEmail',
  UserName = 'userName'
}

export type PublishEntityWhereInput = {
  AND?: InputMaybe<Array<PublishEntityWhereInput>>;
  NOT?: InputMaybe<Array<PublishEntityWhereInput>>;
  OR?: InputMaybe<Array<PublishEntityWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dealer?: InputMaybe<DealerRelationFilter>;
  entityId?: InputMaybe<IntFilter>;
  error?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<StringFilter>;
  userEmail?: InputMaybe<StringFilter>;
  userName?: InputMaybe<StringFilter>;
};

export type PublishEntityWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  aggregateCommissionPayee: AggregateCommissionPayee;
  aggregateCommissionType: AggregateCommissionType;
  aggregateCommissionTypeParameter: AggregateCommissionTypeParameter;
  aggregateDealer: AggregateDealer;
  aggregateDealerChargeback: AggregateDealerChargeback;
  aggregateDealerProduct: AggregateDealerProduct;
  aggregateDealerTier: AggregateDealerTier;
  aggregateEntityCommission: AggregateEntityCommission;
  aggregateEntityCommissionParameterKey: AggregateEntityCommissionParameterKey;
  aggregateEntityProgram: AggregateEntityProgram;
  aggregateNcbPercentage: AggregateNcbPercentage;
  aggregatePaymentType: AggregatePaymentType;
  aggregateProgramProductEnrollment: AggregateProgramProductEnrollment;
  aggregateProgramProductEnrollmentDealerTier: AggregateProgramProductEnrollmentDealerTier;
  /** fetch data from the table: "audit_log" */
  audit_log: Array<Audit_Log>;
  /** fetch aggregated fields from the table: "audit_log" */
  audit_log_aggregate: Audit_Log_Aggregate;
  /** fetch data from the table: "audit_log" using primary key columns */
  audit_log_by_pk?: Maybe<Audit_Log>;
  /** fetch data from the table: "bucket" */
  bucket: Array<Bucket>;
  /** fetch aggregated fields from the table: "bucket" */
  bucket_aggregate: Bucket_Aggregate;
  /** fetch data from the table: "bucket_base_parameter" */
  bucket_base_parameter: Array<Bucket_Base_Parameter>;
  /** fetch aggregated fields from the table: "bucket_base_parameter" */
  bucket_base_parameter_aggregate: Bucket_Base_Parameter_Aggregate;
  /** fetch data from the table: "bucket_base_parameter" using primary key columns */
  bucket_base_parameter_by_pk?: Maybe<Bucket_Base_Parameter>;
  /** fetch data from the table: "bucket_base_rate" */
  bucket_base_rate: Array<Bucket_Base_Rate>;
  /** fetch aggregated fields from the table: "bucket_base_rate" */
  bucket_base_rate_aggregate: Bucket_Base_Rate_Aggregate;
  /** fetch data from the table: "bucket_base_rate" using primary key columns */
  bucket_base_rate_by_pk?: Maybe<Bucket_Base_Rate>;
  /** fetch data from the table: "bucket" using primary key columns */
  bucket_by_pk?: Maybe<Bucket>;
  /** fetch data from the table: "bucket_maximum" */
  bucket_maximum: Array<Bucket_Maximum>;
  /** fetch aggregated fields from the table: "bucket_maximum" */
  bucket_maximum_aggregate: Bucket_Maximum_Aggregate;
  /** fetch data from the table: "bucket_maximum" using primary key columns */
  bucket_maximum_by_pk?: Maybe<Bucket_Maximum>;
  /** fetch data from the table: "bucket_minimum" */
  bucket_minimum: Array<Bucket_Minimum>;
  /** fetch aggregated fields from the table: "bucket_minimum" */
  bucket_minimum_aggregate: Bucket_Minimum_Aggregate;
  /** fetch data from the table: "bucket_minimum" using primary key columns */
  bucket_minimum_by_pk?: Maybe<Bucket_Minimum>;
  /** fetch data from the table: "bucket_offset" */
  bucket_offset: Array<Bucket_Offset>;
  /** fetch aggregated fields from the table: "bucket_offset" */
  bucket_offset_aggregate: Bucket_Offset_Aggregate;
  /** fetch data from the table: "bucket_offset" using primary key columns */
  bucket_offset_by_pk?: Maybe<Bucket_Offset>;
  /** fetch data from the table: "bucket_rate" */
  bucket_rate: Array<Bucket_Rate>;
  /** fetch aggregated fields from the table: "bucket_rate" */
  bucket_rate_aggregate: Bucket_Rate_Aggregate;
  /** fetch data from the table: "bucket_rate" using primary key columns */
  bucket_rate_by_pk?: Maybe<Bucket_Rate>;
  /** fetch data from the table: "bucket_rate_exception" */
  bucket_rate_exception: Array<Bucket_Rate_Exception>;
  /** fetch aggregated fields from the table: "bucket_rate_exception" */
  bucket_rate_exception_aggregate: Bucket_Rate_Exception_Aggregate;
  /** fetch data from the table: "bucket_rate_exception" using primary key columns */
  bucket_rate_exception_by_pk?: Maybe<Bucket_Rate_Exception>;
  /** fetch data from the table: "bucket_rate_factor" */
  bucket_rate_factor: Array<Bucket_Rate_Factor>;
  /** fetch aggregated fields from the table: "bucket_rate_factor" */
  bucket_rate_factor_aggregate: Bucket_Rate_Factor_Aggregate;
  /** fetch data from the table: "bucket_rate_factor" using primary key columns */
  bucket_rate_factor_by_pk?: Maybe<Bucket_Rate_Factor>;
  /** fetch data from the table: "bucket_rate_offset" */
  bucket_rate_offset: Array<Bucket_Rate_Offset>;
  /** fetch aggregated fields from the table: "bucket_rate_offset" */
  bucket_rate_offset_aggregate: Bucket_Rate_Offset_Aggregate;
  /** fetch data from the table: "bucket_rate_offset" using primary key columns */
  bucket_rate_offset_by_pk?: Maybe<Bucket_Rate_Offset>;
  /** fetch data from the table: "bucket_rounding" */
  bucket_rounding: Array<Bucket_Rounding>;
  /** fetch aggregated fields from the table: "bucket_rounding" */
  bucket_rounding_aggregate: Bucket_Rounding_Aggregate;
  /** fetch data from the table: "bucket_rounding" using primary key columns */
  bucket_rounding_by_pk?: Maybe<Bucket_Rounding>;
  commissionPayee?: Maybe<CommissionPayee>;
  commissionPayees: Array<CommissionPayee>;
  commissionType?: Maybe<CommissionType>;
  commissionTypeParameter?: Maybe<CommissionTypeParameter>;
  commissionTypeParameters: Array<CommissionTypeParameter>;
  commissionTypes: Array<CommissionType>;
  /** fetch data from the table: "commission_type" */
  commission_type: Array<Commission_Type>;
  /** fetch aggregated fields from the table: "commission_type" */
  commission_type_aggregate: Commission_Type_Aggregate;
  /** fetch data from the table: "commission_type" using primary key columns */
  commission_type_by_pk?: Maybe<Commission_Type>;
  /** fetch data from the table: "control_type" */
  control_type: Array<Control_Type>;
  /** fetch aggregated fields from the table: "control_type" */
  control_type_aggregate: Control_Type_Aggregate;
  /** fetch data from the table: "control_type" using primary key columns */
  control_type_by_pk?: Maybe<Control_Type>;
  dealer?: Maybe<Dealer>;
  dealerChargeback?: Maybe<DealerChargeback>;
  dealerChargebacks: Array<DealerChargeback>;
  dealerProduct?: Maybe<DealerProduct>;
  dealerProducts: Array<DealerProduct>;
  dealerTier?: Maybe<DealerTier>;
  dealerTiers: Array<DealerTier>;
  /** fetch data from the table: "dealer_product" */
  dealer_product: Array<Dealer_Product>;
  /** fetch aggregated fields from the table: "dealer_product" */
  dealer_product_aggregate: Dealer_Product_Aggregate;
  /** fetch data from the table: "dealer_product" using primary key columns */
  dealer_product_by_pk?: Maybe<Dealer_Product>;
  dealers: Array<Dealer>;
  entityCommission?: Maybe<EntityCommission>;
  entityCommissionParameterKey?: Maybe<EntityCommissionParameterKey>;
  entityCommissionParameterKeys: Array<EntityCommissionParameterKey>;
  entityCommissions: Array<EntityCommission>;
  entityProgram?: Maybe<EntityProgram>;
  entityPrograms: Array<EntityProgram>;
  findFirstCommissionPayee?: Maybe<CommissionPayee>;
  findFirstCommissionType?: Maybe<CommissionType>;
  findFirstCommissionTypeParameter?: Maybe<CommissionTypeParameter>;
  findFirstDealer?: Maybe<Dealer>;
  findFirstDealerChargeback?: Maybe<DealerChargeback>;
  findFirstDealerProduct?: Maybe<DealerProduct>;
  findFirstDealerTier?: Maybe<DealerTier>;
  findFirstEntityCommission?: Maybe<EntityCommission>;
  findFirstEntityCommissionParameterKey?: Maybe<EntityCommissionParameterKey>;
  findFirstEntityProgram?: Maybe<EntityProgram>;
  findFirstNcbPercentage?: Maybe<NcbPercentage>;
  findFirstPaymentType?: Maybe<PaymentType>;
  findFirstProgramProductEnrollment?: Maybe<ProgramProductEnrollment>;
  findFirstProgramProductEnrollmentDealerTier?: Maybe<ProgramProductEnrollmentDealerTier>;
  groupByCommissionPayee: Array<CommissionPayeeGroupBy>;
  groupByCommissionType: Array<CommissionTypeGroupBy>;
  groupByCommissionTypeParameter: Array<CommissionTypeParameterGroupBy>;
  groupByDealer: Array<DealerGroupBy>;
  groupByDealerChargeback: Array<DealerChargebackGroupBy>;
  groupByDealerProduct: Array<DealerProductGroupBy>;
  groupByDealerTier: Array<DealerTierGroupBy>;
  groupByEntityCommission: Array<EntityCommissionGroupBy>;
  groupByEntityCommissionParameterKey: Array<EntityCommissionParameterKeyGroupBy>;
  groupByEntityProgram: Array<EntityProgramGroupBy>;
  groupByNcbPercentage: Array<NcbPercentageGroupBy>;
  groupByPaymentType: Array<PaymentTypeGroupBy>;
  groupByProgramProductEnrollment: Array<ProgramProductEnrollmentGroupBy>;
  groupByProgramProductEnrollmentDealerTier: Array<ProgramProductEnrollmentDealerTierGroupBy>;
  /** fetch data from the table: "ledger_event" */
  ledger_event: Array<Ledger_Event>;
  /** fetch aggregated fields from the table: "ledger_event" */
  ledger_event_aggregate: Ledger_Event_Aggregate;
  /** fetch data from the table: "ledger_event" using primary key columns */
  ledger_event_by_pk?: Maybe<Ledger_Event>;
  ncbPercentage?: Maybe<NcbPercentage>;
  ncbPercentages: Array<NcbPercentage>;
  /** fetch data from the table: "non_sellable_combination" */
  non_sellable_combination: Array<Non_Sellable_Combination>;
  /** fetch aggregated fields from the table: "non_sellable_combination" */
  non_sellable_combination_aggregate: Non_Sellable_Combination_Aggregate;
  /** fetch data from the table: "non_sellable_combination" using primary key columns */
  non_sellable_combination_by_pk?: Maybe<Non_Sellable_Combination>;
  /** fetch data from the table: "non_sellable_combination_option" */
  non_sellable_combination_option: Array<Non_Sellable_Combination_Option>;
  /** fetch aggregated fields from the table: "non_sellable_combination_option" */
  non_sellable_combination_option_aggregate: Non_Sellable_Combination_Option_Aggregate;
  /** fetch data from the table: "non_sellable_combination_option" using primary key columns */
  non_sellable_combination_option_by_pk?: Maybe<Non_Sellable_Combination_Option>;
  /** fetch data from the table: "offset_type" */
  offset_type: Array<Offset_Type>;
  /** fetch aggregated fields from the table: "offset_type" */
  offset_type_aggregate: Offset_Type_Aggregate;
  /** fetch data from the table: "offset_type" using primary key columns */
  offset_type_by_pk?: Maybe<Offset_Type>;
  /** fetch data from the table: "operator" */
  operator: Array<Operator>;
  /** fetch aggregated fields from the table: "operator" */
  operator_aggregate: Operator_Aggregate;
  /** fetch data from the table: "operator" using primary key columns */
  operator_by_pk?: Maybe<Operator>;
  /** fetch data from the table: "parameter" */
  parameter: Array<Parameter>;
  /** fetch aggregated fields from the table: "parameter" */
  parameter_aggregate: Parameter_Aggregate;
  /** fetch data from the table: "parameter" using primary key columns */
  parameter_by_pk?: Maybe<Parameter>;
  /** fetch data from the table: "parameter_key" */
  parameter_key: Array<Parameter_Key>;
  /** fetch aggregated fields from the table: "parameter_key" */
  parameter_key_aggregate: Parameter_Key_Aggregate;
  /** fetch data from the table: "parameter_key" using primary key columns */
  parameter_key_by_pk?: Maybe<Parameter_Key>;
  /** fetch data from the table: "parameter_key_value" */
  parameter_key_value: Array<Parameter_Key_Value>;
  /** fetch aggregated fields from the table: "parameter_key_value" */
  parameter_key_value_aggregate: Parameter_Key_Value_Aggregate;
  /** fetch data from the table: "parameter_key_value" using primary key columns */
  parameter_key_value_by_pk?: Maybe<Parameter_Key_Value>;
  /** fetch data from the table: "parameter_sub_key" */
  parameter_sub_key: Array<Parameter_Sub_Key>;
  /** fetch aggregated fields from the table: "parameter_sub_key" */
  parameter_sub_key_aggregate: Parameter_Sub_Key_Aggregate;
  /** fetch data from the table: "parameter_sub_key_bucket_discount" */
  parameter_sub_key_bucket_discount: Array<Parameter_Sub_Key_Bucket_Discount>;
  /** fetch aggregated fields from the table: "parameter_sub_key_bucket_discount" */
  parameter_sub_key_bucket_discount_aggregate: Parameter_Sub_Key_Bucket_Discount_Aggregate;
  /** fetch data from the table: "parameter_sub_key_bucket_discount" using primary key columns */
  parameter_sub_key_bucket_discount_by_pk?: Maybe<Parameter_Sub_Key_Bucket_Discount>;
  /** fetch data from the table: "parameter_sub_key" using primary key columns */
  parameter_sub_key_by_pk?: Maybe<Parameter_Sub_Key>;
  /** fetch data from the table: "parameter_subtype" */
  parameter_subtype: Array<Parameter_Subtype>;
  /** fetch aggregated fields from the table: "parameter_subtype" */
  parameter_subtype_aggregate: Parameter_Subtype_Aggregate;
  /** fetch data from the table: "parameter_subtype" using primary key columns */
  parameter_subtype_by_pk?: Maybe<Parameter_Subtype>;
  /** An array relationship */
  parameter_subtype_options: Array<Parameter_Subtype_Options>;
  /** An aggregate relationship */
  parameter_subtype_options_aggregate: Parameter_Subtype_Options_Aggregate;
  /** fetch data from the table: "parameter_subtype_options" using primary key columns */
  parameter_subtype_options_by_pk?: Maybe<Parameter_Subtype_Options>;
  /** fetch data from the table: "parameter_type" */
  parameter_type: Array<Parameter_Type>;
  /** fetch aggregated fields from the table: "parameter_type" */
  parameter_type_aggregate: Parameter_Type_Aggregate;
  /** fetch data from the table: "parameter_type" using primary key columns */
  parameter_type_by_pk?: Maybe<Parameter_Type>;
  /** fetch data from the table: "payee" */
  payee: Array<Payee>;
  /** fetch aggregated fields from the table: "payee" */
  payee_aggregate: Payee_Aggregate;
  /** fetch data from the table: "payee" using primary key columns */
  payee_by_pk?: Maybe<Payee>;
  paymentType?: Maybe<PaymentType>;
  paymentTypes: Array<PaymentType>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  productRates: ProductRatesConnection;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "vw_dealer_cost" */
  product_dealer_cost: Array<Product_Dealer_Cost>;
  /** fetch aggregated fields from the table: "vw_dealer_cost" */
  product_dealer_cost_aggregate: Product_Dealer_Cost_Aggregate;
  /** fetch data from the table: "product_dealer_offset" */
  product_dealer_offset: Array<Product_Dealer_Offset>;
  /** fetch aggregated fields from the table: "product_dealer_offset" */
  product_dealer_offset_aggregate: Product_Dealer_Offset_Aggregate;
  /** fetch data from the table: "product_dealer_offset" using primary key columns */
  product_dealer_offset_by_pk?: Maybe<Product_Dealer_Offset>;
  /** fetch data from the table: "product_msrp_operation" */
  product_msrp_operation: Array<Product_Msrp_Operation>;
  /** fetch aggregated fields from the table: "product_msrp_operation" */
  product_msrp_operation_aggregate: Product_Msrp_Operation_Aggregate;
  /** fetch data from the table: "product_msrp_operation" using primary key columns */
  product_msrp_operation_by_pk?: Maybe<Product_Msrp_Operation>;
  /** fetch data from the table: "product_msrp_operator" */
  product_msrp_operator: Array<Product_Msrp_Operator>;
  /** fetch aggregated fields from the table: "product_msrp_operator" */
  product_msrp_operator_aggregate: Product_Msrp_Operator_Aggregate;
  /** fetch data from the table: "product_msrp_operator" using primary key columns */
  product_msrp_operator_by_pk?: Maybe<Product_Msrp_Operator>;
  /** fetch data from the table: "product_msrp_parameter" */
  product_msrp_parameter: Array<Product_Msrp_Parameter>;
  /** fetch aggregated fields from the table: "product_msrp_parameter" */
  product_msrp_parameter_aggregate: Product_Msrp_Parameter_Aggregate;
  /** fetch data from the table: "product_msrp_parameter" using primary key columns */
  product_msrp_parameter_by_pk?: Maybe<Product_Msrp_Parameter>;
  /** fetch data from the table: "product_msrp_parameter_key" */
  product_msrp_parameter_key: Array<Product_Msrp_Parameter_Key>;
  /** fetch aggregated fields from the table: "product_msrp_parameter_key" */
  product_msrp_parameter_key_aggregate: Product_Msrp_Parameter_Key_Aggregate;
  /** fetch data from the table: "product_msrp_parameter_key" using primary key columns */
  product_msrp_parameter_key_by_pk?: Maybe<Product_Msrp_Parameter_Key>;
  /** fetch data from the table: "product_msrp_parameter_key_combination" */
  product_msrp_parameter_key_combination: Array<Product_Msrp_Parameter_Key_Combination>;
  /** fetch aggregated fields from the table: "product_msrp_parameter_key_combination" */
  product_msrp_parameter_key_combination_aggregate: Product_Msrp_Parameter_Key_Combination_Aggregate;
  /** fetch data from the table: "product_msrp_parameter_key_combination" using primary key columns */
  product_msrp_parameter_key_combination_by_pk?: Maybe<Product_Msrp_Parameter_Key_Combination>;
  /** fetch data from the table: "product_parameter_key" */
  product_parameter_key: Array<Product_Parameter_Key>;
  /** fetch aggregated fields from the table: "product_parameter_key" */
  product_parameter_key_aggregate: Product_Parameter_Key_Aggregate;
  /** fetch data from the table: "product_parameter_key" using primary key columns */
  product_parameter_key_by_pk?: Maybe<Product_Parameter_Key>;
  /** fetch data from the table: "product_parameter_key_combination" */
  product_parameter_key_combination: Array<Product_Parameter_Key_Combination>;
  /** fetch aggregated fields from the table: "product_parameter_key_combination" */
  product_parameter_key_combination_aggregate: Product_Parameter_Key_Combination_Aggregate;
  /** fetch data from the table: "product_parameter_key_combination" using primary key columns */
  product_parameter_key_combination_by_pk?: Maybe<Product_Parameter_Key_Combination>;
  /** fetch data from the table: "product_parameter_key_sub_combination" */
  product_parameter_key_sub_combination: Array<Product_Parameter_Key_Sub_Combination>;
  /** fetch aggregated fields from the table: "product_parameter_key_sub_combination" */
  product_parameter_key_sub_combination_aggregate: Product_Parameter_Key_Sub_Combination_Aggregate;
  /** fetch data from the table: "product_parameter_key_sub_combination" using primary key columns */
  product_parameter_key_sub_combination_by_pk?: Maybe<Product_Parameter_Key_Sub_Combination>;
  /** fetch data from the table: "product_type" */
  product_type: Array<Product_Type>;
  /** fetch aggregated fields from the table: "product_type" */
  product_type_aggregate: Product_Type_Aggregate;
  /** fetch data from the table: "product_type" using primary key columns */
  product_type_by_pk?: Maybe<Product_Type>;
  /** fetch data from the table: "program" */
  program: Array<Program>;
  programProductEnrollment?: Maybe<ProgramProductEnrollment>;
  programProductEnrollmentDealerTier?: Maybe<ProgramProductEnrollmentDealerTier>;
  programProductEnrollmentDealerTiers: Array<ProgramProductEnrollmentDealerTier>;
  programProductEnrollments: Array<ProgramProductEnrollment>;
  /** fetch aggregated fields from the table: "program" */
  program_aggregate: Program_Aggregate;
  /** fetch data from the table: "program" using primary key columns */
  program_by_pk?: Maybe<Program>;
  /** fetch data from the table: "publish_complete" */
  publish_complete: Array<Publish_Complete>;
  /** fetch aggregated fields from the table: "publish_complete" */
  publish_complete_aggregate: Publish_Complete_Aggregate;
  /** fetch data from the table: "publish_complete" using primary key columns */
  publish_complete_by_pk?: Maybe<Publish_Complete>;
  /** fetch data from the table: "publish_product" */
  publish_product: Array<Publish_Product>;
  /** fetch aggregated fields from the table: "publish_product" */
  publish_product_aggregate: Publish_Product_Aggregate;
  /** fetch data from the table: "publish_product" using primary key columns */
  publish_product_by_pk?: Maybe<Publish_Product>;
  /** fetch data from the table: "rate_slice_base_slice" */
  rate_slice_base_slice: Array<Rate_Slice_Base_Slice>;
  /** fetch aggregated fields from the table: "rate_slice_base_slice" */
  rate_slice_base_slice_aggregate: Rate_Slice_Base_Slice_Aggregate;
  /** fetch data from the table: "rate_slice_base_slice" using primary key columns */
  rate_slice_base_slice_by_pk?: Maybe<Rate_Slice_Base_Slice>;
  /** fetch data from the table: "risk_type" */
  risk_type: Array<Risk_Type>;
  /** fetch aggregated fields from the table: "risk_type" */
  risk_type_aggregate: Risk_Type_Aggregate;
  /** fetch data from the table: "risk_type" using primary key columns */
  risk_type_by_pk?: Maybe<Risk_Type>;
  /** fetch data from the table: "round_type" */
  round_type: Array<Round_Type>;
  /** fetch aggregated fields from the table: "round_type" */
  round_type_aggregate: Round_Type_Aggregate;
  /** fetch data from the table: "round_type" using primary key columns */
  round_type_by_pk?: Maybe<Round_Type>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "vw_final_costs" */
  vw_final_costs: Array<Vw_Final_Costs>;
  /** fetch aggregated fields from the table: "vw_final_costs" */
  vw_final_costs_aggregate: Vw_Final_Costs_Aggregate;
  /** fetch data from the table: "vw_parameter_values" */
  vw_parameter_values: Array<Vw_Parameter_Values>;
  /** fetch aggregated fields from the table: "vw_parameter_values" */
  vw_parameter_values_aggregate: Vw_Parameter_Values_Aggregate;
  /** fetch data from the table: "vw_product_parameter_key_combination_dealer_cost_rounding" */
  vw_product_parameter_key_combination_dealer_cost_rounding: Array<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding>;
  /** fetch aggregated fields from the table: "vw_product_parameter_key_combination_dealer_cost_rounding" */
  vw_product_parameter_key_combination_dealer_cost_rounding_aggregate: Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Aggregate;
  /** fetch data from the table: "vw_rate_bucket" */
  vw_rate_bucket: Array<Vw_Rate_Bucket>;
  /** fetch aggregated fields from the table: "vw_rate_bucket" */
  vw_rate_bucket_aggregate: Vw_Rate_Bucket_Aggregate;
};


export type QueryAggregateCommissionPayeeArgs = {
  cursor?: InputMaybe<CommissionPayeeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CommissionPayeeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommissionPayeeWhereInput>;
};


export type QueryAggregateCommissionTypeArgs = {
  cursor?: InputMaybe<CommissionTypeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CommissionTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommissionTypeWhereInput>;
};


export type QueryAggregateCommissionTypeParameterArgs = {
  cursor?: InputMaybe<CommissionTypeParameterWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CommissionTypeParameterOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommissionTypeParameterWhereInput>;
};


export type QueryAggregateDealerArgs = {
  cursor?: InputMaybe<DealerWhereUniqueInput>;
  orderBy?: InputMaybe<Array<DealerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DealerWhereInput>;
};


export type QueryAggregateDealerChargebackArgs = {
  cursor?: InputMaybe<DealerChargebackWhereUniqueInput>;
  orderBy?: InputMaybe<Array<DealerChargebackOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DealerChargebackWhereInput>;
};


export type QueryAggregateDealerProductArgs = {
  cursor?: InputMaybe<DealerProductWhereUniqueInput>;
  orderBy?: InputMaybe<Array<DealerProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DealerProductWhereInput>;
};


export type QueryAggregateDealerTierArgs = {
  cursor?: InputMaybe<DealerTierWhereUniqueInput>;
  orderBy?: InputMaybe<Array<DealerTierOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DealerTierWhereInput>;
};


export type QueryAggregateEntityCommissionArgs = {
  cursor?: InputMaybe<EntityCommissionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EntityCommissionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntityCommissionWhereInput>;
};


export type QueryAggregateEntityCommissionParameterKeyArgs = {
  cursor?: InputMaybe<EntityCommissionParameterKeyWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EntityCommissionParameterKeyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntityCommissionParameterKeyWhereInput>;
};


export type QueryAggregateEntityProgramArgs = {
  cursor?: InputMaybe<EntityProgramWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EntityProgramOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntityProgramWhereInput>;
};


export type QueryAggregateNcbPercentageArgs = {
  cursor?: InputMaybe<NcbPercentageWhereUniqueInput>;
  orderBy?: InputMaybe<Array<NcbPercentageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NcbPercentageWhereInput>;
};


export type QueryAggregatePaymentTypeArgs = {
  cursor?: InputMaybe<PaymentTypeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PaymentTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentTypeWhereInput>;
};


export type QueryAggregateProgramProductEnrollmentArgs = {
  cursor?: InputMaybe<ProgramProductEnrollmentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProgramProductEnrollmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProgramProductEnrollmentWhereInput>;
};


export type QueryAggregateProgramProductEnrollmentDealerTierArgs = {
  cursor?: InputMaybe<ProgramProductEnrollmentDealerTierWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProgramProductEnrollmentDealerTierOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProgramProductEnrollmentDealerTierWhereInput>;
};


export type QueryAudit_LogArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_Log_Order_By>>;
  where?: InputMaybe<Audit_Log_Bool_Exp>;
};


export type QueryAudit_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_Log_Order_By>>;
  where?: InputMaybe<Audit_Log_Bool_Exp>;
};


export type QueryAudit_Log_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryBucketArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Order_By>>;
  where?: InputMaybe<Bucket_Bool_Exp>;
};


export type QueryBucket_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Order_By>>;
  where?: InputMaybe<Bucket_Bool_Exp>;
};


export type QueryBucket_Base_ParameterArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Base_Parameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Base_Parameter_Order_By>>;
  where?: InputMaybe<Bucket_Base_Parameter_Bool_Exp>;
};


export type QueryBucket_Base_Parameter_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Base_Parameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Base_Parameter_Order_By>>;
  where?: InputMaybe<Bucket_Base_Parameter_Bool_Exp>;
};


export type QueryBucket_Base_Parameter_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryBucket_Base_RateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Base_Rate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Base_Rate_Order_By>>;
  where?: InputMaybe<Bucket_Base_Rate_Bool_Exp>;
};


export type QueryBucket_Base_Rate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Base_Rate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Base_Rate_Order_By>>;
  where?: InputMaybe<Bucket_Base_Rate_Bool_Exp>;
};


export type QueryBucket_Base_Rate_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryBucket_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryBucket_MaximumArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Maximum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Maximum_Order_By>>;
  where?: InputMaybe<Bucket_Maximum_Bool_Exp>;
};


export type QueryBucket_Maximum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Maximum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Maximum_Order_By>>;
  where?: InputMaybe<Bucket_Maximum_Bool_Exp>;
};


export type QueryBucket_Maximum_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryBucket_MinimumArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Minimum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Minimum_Order_By>>;
  where?: InputMaybe<Bucket_Minimum_Bool_Exp>;
};


export type QueryBucket_Minimum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Minimum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Minimum_Order_By>>;
  where?: InputMaybe<Bucket_Minimum_Bool_Exp>;
};


export type QueryBucket_Minimum_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryBucket_OffsetArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Offset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Offset_Order_By>>;
  where?: InputMaybe<Bucket_Offset_Bool_Exp>;
};


export type QueryBucket_Offset_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Offset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Offset_Order_By>>;
  where?: InputMaybe<Bucket_Offset_Bool_Exp>;
};


export type QueryBucket_Offset_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryBucket_RateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Bool_Exp>;
};


export type QueryBucket_Rate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Bool_Exp>;
};


export type QueryBucket_Rate_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryBucket_Rate_ExceptionArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rate_Exception_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Exception_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Exception_Bool_Exp>;
};


export type QueryBucket_Rate_Exception_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rate_Exception_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Exception_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Exception_Bool_Exp>;
};


export type QueryBucket_Rate_Exception_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryBucket_Rate_FactorArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rate_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Factor_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Factor_Bool_Exp>;
};


export type QueryBucket_Rate_Factor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rate_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Factor_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Factor_Bool_Exp>;
};


export type QueryBucket_Rate_Factor_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryBucket_Rate_OffsetArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rate_Offset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Offset_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Offset_Bool_Exp>;
};


export type QueryBucket_Rate_Offset_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rate_Offset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Offset_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Offset_Bool_Exp>;
};


export type QueryBucket_Rate_Offset_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryBucket_RoundingArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rounding_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rounding_Order_By>>;
  where?: InputMaybe<Bucket_Rounding_Bool_Exp>;
};


export type QueryBucket_Rounding_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rounding_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rounding_Order_By>>;
  where?: InputMaybe<Bucket_Rounding_Bool_Exp>;
};


export type QueryBucket_Rounding_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryCommissionPayeeArgs = {
  where: CommissionPayeeWhereUniqueInput;
};


export type QueryCommissionPayeesArgs = {
  cursor?: InputMaybe<CommissionPayeeWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommissionPayeeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommissionPayeeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommissionPayeeWhereInput>;
};


export type QueryCommissionTypeArgs = {
  where: CommissionTypeWhereUniqueInput;
};


export type QueryCommissionTypeParameterArgs = {
  where: CommissionTypeParameterWhereUniqueInput;
};


export type QueryCommissionTypeParametersArgs = {
  cursor?: InputMaybe<CommissionTypeParameterWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommissionTypeParameterScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommissionTypeParameterOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommissionTypeParameterWhereInput>;
};


export type QueryCommissionTypesArgs = {
  cursor?: InputMaybe<CommissionTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommissionTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommissionTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommissionTypeWhereInput>;
};


export type QueryCommission_TypeArgs = {
  distinct_on?: InputMaybe<Array<Commission_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Type_Order_By>>;
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};


export type QueryCommission_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Type_Order_By>>;
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};


export type QueryCommission_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type QueryControl_TypeArgs = {
  distinct_on?: InputMaybe<Array<Control_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Control_Type_Order_By>>;
  where?: InputMaybe<Control_Type_Bool_Exp>;
};


export type QueryControl_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Control_Type_Order_By>>;
  where?: InputMaybe<Control_Type_Bool_Exp>;
};


export type QueryControl_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type QueryDealerArgs = {
  where: DealerWhereUniqueInput;
};


export type QueryDealerChargebackArgs = {
  where: DealerChargebackWhereUniqueInput;
};


export type QueryDealerChargebacksArgs = {
  cursor?: InputMaybe<DealerChargebackWhereUniqueInput>;
  distinct?: InputMaybe<Array<DealerChargebackScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DealerChargebackOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DealerChargebackWhereInput>;
};


export type QueryDealerProductArgs = {
  where: DealerProductWhereUniqueInput;
};


export type QueryDealerProductsArgs = {
  cursor?: InputMaybe<DealerProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<DealerProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DealerProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DealerProductWhereInput>;
};


export type QueryDealerTierArgs = {
  where: DealerTierWhereUniqueInput;
};


export type QueryDealerTiersArgs = {
  cursor?: InputMaybe<DealerTierWhereUniqueInput>;
  distinct?: InputMaybe<Array<DealerTierScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DealerTierOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DealerTierWhereInput>;
};


export type QueryDealer_ProductArgs = {
  distinct_on?: InputMaybe<Array<Dealer_Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dealer_Product_Order_By>>;
  where?: InputMaybe<Dealer_Product_Bool_Exp>;
};


export type QueryDealer_Product_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dealer_Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dealer_Product_Order_By>>;
  where?: InputMaybe<Dealer_Product_Bool_Exp>;
};


export type QueryDealer_Product_By_PkArgs = {
  id: Scalars['Int'];
};


export type QueryDealersArgs = {
  cursor?: InputMaybe<DealerWhereUniqueInput>;
  distinct?: InputMaybe<Array<DealerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DealerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DealerWhereInput>;
};


export type QueryEntityCommissionArgs = {
  where: EntityCommissionWhereUniqueInput;
};


export type QueryEntityCommissionParameterKeyArgs = {
  where: EntityCommissionParameterKeyWhereUniqueInput;
};


export type QueryEntityCommissionParameterKeysArgs = {
  cursor?: InputMaybe<EntityCommissionParameterKeyWhereUniqueInput>;
  distinct?: InputMaybe<Array<EntityCommissionParameterKeyScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EntityCommissionParameterKeyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntityCommissionParameterKeyWhereInput>;
};


export type QueryEntityCommissionsArgs = {
  cursor?: InputMaybe<EntityCommissionWhereUniqueInput>;
  distinct?: InputMaybe<Array<EntityCommissionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EntityCommissionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntityCommissionWhereInput>;
};


export type QueryEntityProgramArgs = {
  where: EntityProgramWhereUniqueInput;
};


export type QueryEntityProgramsArgs = {
  cursor?: InputMaybe<EntityProgramWhereUniqueInput>;
  distinct?: InputMaybe<Array<EntityProgramScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EntityProgramOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntityProgramWhereInput>;
};


export type QueryFindFirstCommissionPayeeArgs = {
  cursor?: InputMaybe<CommissionPayeeWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommissionPayeeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommissionPayeeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommissionPayeeWhereInput>;
};


export type QueryFindFirstCommissionTypeArgs = {
  cursor?: InputMaybe<CommissionTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommissionTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommissionTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommissionTypeWhereInput>;
};


export type QueryFindFirstCommissionTypeParameterArgs = {
  cursor?: InputMaybe<CommissionTypeParameterWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommissionTypeParameterScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommissionTypeParameterOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommissionTypeParameterWhereInput>;
};


export type QueryFindFirstDealerArgs = {
  cursor?: InputMaybe<DealerWhereUniqueInput>;
  distinct?: InputMaybe<Array<DealerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DealerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DealerWhereInput>;
};


export type QueryFindFirstDealerChargebackArgs = {
  cursor?: InputMaybe<DealerChargebackWhereUniqueInput>;
  distinct?: InputMaybe<Array<DealerChargebackScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DealerChargebackOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DealerChargebackWhereInput>;
};


export type QueryFindFirstDealerProductArgs = {
  cursor?: InputMaybe<DealerProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<DealerProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DealerProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DealerProductWhereInput>;
};


export type QueryFindFirstDealerTierArgs = {
  cursor?: InputMaybe<DealerTierWhereUniqueInput>;
  distinct?: InputMaybe<Array<DealerTierScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DealerTierOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DealerTierWhereInput>;
};


export type QueryFindFirstEntityCommissionArgs = {
  cursor?: InputMaybe<EntityCommissionWhereUniqueInput>;
  distinct?: InputMaybe<Array<EntityCommissionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EntityCommissionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntityCommissionWhereInput>;
};


export type QueryFindFirstEntityCommissionParameterKeyArgs = {
  cursor?: InputMaybe<EntityCommissionParameterKeyWhereUniqueInput>;
  distinct?: InputMaybe<Array<EntityCommissionParameterKeyScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EntityCommissionParameterKeyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntityCommissionParameterKeyWhereInput>;
};


export type QueryFindFirstEntityProgramArgs = {
  cursor?: InputMaybe<EntityProgramWhereUniqueInput>;
  distinct?: InputMaybe<Array<EntityProgramScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EntityProgramOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntityProgramWhereInput>;
};


export type QueryFindFirstNcbPercentageArgs = {
  cursor?: InputMaybe<NcbPercentageWhereUniqueInput>;
  distinct?: InputMaybe<Array<NcbPercentageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NcbPercentageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NcbPercentageWhereInput>;
};


export type QueryFindFirstPaymentTypeArgs = {
  cursor?: InputMaybe<PaymentTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentTypeWhereInput>;
};


export type QueryFindFirstProgramProductEnrollmentArgs = {
  cursor?: InputMaybe<ProgramProductEnrollmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProgramProductEnrollmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProgramProductEnrollmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProgramProductEnrollmentWhereInput>;
};


export type QueryFindFirstProgramProductEnrollmentDealerTierArgs = {
  cursor?: InputMaybe<ProgramProductEnrollmentDealerTierWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProgramProductEnrollmentDealerTierScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProgramProductEnrollmentDealerTierOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProgramProductEnrollmentDealerTierWhereInput>;
};


export type QueryGroupByCommissionPayeeArgs = {
  by: Array<CommissionPayeeScalarFieldEnum>;
  having?: InputMaybe<CommissionPayeeScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CommissionPayeeOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommissionPayeeWhereInput>;
};


export type QueryGroupByCommissionTypeArgs = {
  by: Array<CommissionTypeScalarFieldEnum>;
  having?: InputMaybe<CommissionTypeScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CommissionTypeOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommissionTypeWhereInput>;
};


export type QueryGroupByCommissionTypeParameterArgs = {
  by: Array<CommissionTypeParameterScalarFieldEnum>;
  having?: InputMaybe<CommissionTypeParameterScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CommissionTypeParameterOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommissionTypeParameterWhereInput>;
};


export type QueryGroupByDealerArgs = {
  by: Array<DealerScalarFieldEnum>;
  having?: InputMaybe<DealerScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<DealerOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DealerWhereInput>;
};


export type QueryGroupByDealerChargebackArgs = {
  by: Array<DealerChargebackScalarFieldEnum>;
  having?: InputMaybe<DealerChargebackScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<DealerChargebackOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DealerChargebackWhereInput>;
};


export type QueryGroupByDealerProductArgs = {
  by: Array<DealerProductScalarFieldEnum>;
  having?: InputMaybe<DealerProductScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<DealerProductOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DealerProductWhereInput>;
};


export type QueryGroupByDealerTierArgs = {
  by: Array<DealerTierScalarFieldEnum>;
  having?: InputMaybe<DealerTierScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<DealerTierOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DealerTierWhereInput>;
};


export type QueryGroupByEntityCommissionArgs = {
  by: Array<EntityCommissionScalarFieldEnum>;
  having?: InputMaybe<EntityCommissionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<EntityCommissionOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntityCommissionWhereInput>;
};


export type QueryGroupByEntityCommissionParameterKeyArgs = {
  by: Array<EntityCommissionParameterKeyScalarFieldEnum>;
  having?: InputMaybe<EntityCommissionParameterKeyScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<EntityCommissionParameterKeyOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntityCommissionParameterKeyWhereInput>;
};


export type QueryGroupByEntityProgramArgs = {
  by: Array<EntityProgramScalarFieldEnum>;
  having?: InputMaybe<EntityProgramScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<EntityProgramOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EntityProgramWhereInput>;
};


export type QueryGroupByNcbPercentageArgs = {
  by: Array<NcbPercentageScalarFieldEnum>;
  having?: InputMaybe<NcbPercentageScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<NcbPercentageOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NcbPercentageWhereInput>;
};


export type QueryGroupByPaymentTypeArgs = {
  by: Array<PaymentTypeScalarFieldEnum>;
  having?: InputMaybe<PaymentTypeScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PaymentTypeOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentTypeWhereInput>;
};


export type QueryGroupByProgramProductEnrollmentArgs = {
  by: Array<ProgramProductEnrollmentScalarFieldEnum>;
  having?: InputMaybe<ProgramProductEnrollmentScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ProgramProductEnrollmentOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProgramProductEnrollmentWhereInput>;
};


export type QueryGroupByProgramProductEnrollmentDealerTierArgs = {
  by: Array<ProgramProductEnrollmentDealerTierScalarFieldEnum>;
  having?: InputMaybe<ProgramProductEnrollmentDealerTierScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ProgramProductEnrollmentDealerTierOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProgramProductEnrollmentDealerTierWhereInput>;
};


export type QueryLedger_EventArgs = {
  distinct_on?: InputMaybe<Array<Ledger_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ledger_Event_Order_By>>;
  where?: InputMaybe<Ledger_Event_Bool_Exp>;
};


export type QueryLedger_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ledger_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ledger_Event_Order_By>>;
  where?: InputMaybe<Ledger_Event_Bool_Exp>;
};


export type QueryLedger_Event_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryNcbPercentageArgs = {
  where: NcbPercentageWhereUniqueInput;
};


export type QueryNcbPercentagesArgs = {
  cursor?: InputMaybe<NcbPercentageWhereUniqueInput>;
  distinct?: InputMaybe<Array<NcbPercentageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NcbPercentageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NcbPercentageWhereInput>;
};


export type QueryNon_Sellable_CombinationArgs = {
  distinct_on?: InputMaybe<Array<Non_Sellable_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Non_Sellable_Combination_Order_By>>;
  where?: InputMaybe<Non_Sellable_Combination_Bool_Exp>;
};


export type QueryNon_Sellable_Combination_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Non_Sellable_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Non_Sellable_Combination_Order_By>>;
  where?: InputMaybe<Non_Sellable_Combination_Bool_Exp>;
};


export type QueryNon_Sellable_Combination_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryNon_Sellable_Combination_OptionArgs = {
  distinct_on?: InputMaybe<Array<Non_Sellable_Combination_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Non_Sellable_Combination_Option_Order_By>>;
  where?: InputMaybe<Non_Sellable_Combination_Option_Bool_Exp>;
};


export type QueryNon_Sellable_Combination_Option_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Non_Sellable_Combination_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Non_Sellable_Combination_Option_Order_By>>;
  where?: InputMaybe<Non_Sellable_Combination_Option_Bool_Exp>;
};


export type QueryNon_Sellable_Combination_Option_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryOffset_TypeArgs = {
  distinct_on?: InputMaybe<Array<Offset_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Offset_Type_Order_By>>;
  where?: InputMaybe<Offset_Type_Bool_Exp>;
};


export type QueryOffset_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Offset_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Offset_Type_Order_By>>;
  where?: InputMaybe<Offset_Type_Bool_Exp>;
};


export type QueryOffset_Type_By_PkArgs = {
  offset_type: Scalars['String'];
};


export type QueryOperatorArgs = {
  distinct_on?: InputMaybe<Array<Operator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operator_Order_By>>;
  where?: InputMaybe<Operator_Bool_Exp>;
};


export type QueryOperator_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operator_Order_By>>;
  where?: InputMaybe<Operator_Bool_Exp>;
};


export type QueryOperator_By_PkArgs = {
  operator: Scalars['String'];
};


export type QueryParameterArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Order_By>>;
  where?: InputMaybe<Parameter_Bool_Exp>;
};


export type QueryParameter_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Order_By>>;
  where?: InputMaybe<Parameter_Bool_Exp>;
};


export type QueryParameter_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryParameter_KeyArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Key_Order_By>>;
  where?: InputMaybe<Parameter_Key_Bool_Exp>;
};


export type QueryParameter_Key_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Key_Order_By>>;
  where?: InputMaybe<Parameter_Key_Bool_Exp>;
};


export type QueryParameter_Key_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryParameter_Key_ValueArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Key_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Key_Value_Order_By>>;
  where?: InputMaybe<Parameter_Key_Value_Bool_Exp>;
};


export type QueryParameter_Key_Value_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Key_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Key_Value_Order_By>>;
  where?: InputMaybe<Parameter_Key_Value_Bool_Exp>;
};


export type QueryParameter_Key_Value_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryParameter_Sub_KeyArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Sub_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Sub_Key_Order_By>>;
  where?: InputMaybe<Parameter_Sub_Key_Bool_Exp>;
};


export type QueryParameter_Sub_Key_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Sub_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Sub_Key_Order_By>>;
  where?: InputMaybe<Parameter_Sub_Key_Bool_Exp>;
};


export type QueryParameter_Sub_Key_Bucket_DiscountArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Sub_Key_Bucket_Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Sub_Key_Bucket_Discount_Order_By>>;
  where?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Bool_Exp>;
};


export type QueryParameter_Sub_Key_Bucket_Discount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Sub_Key_Bucket_Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Sub_Key_Bucket_Discount_Order_By>>;
  where?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Bool_Exp>;
};


export type QueryParameter_Sub_Key_Bucket_Discount_By_PkArgs = {
  bucket_id: Scalars['bigint'];
  parameter_sub_key_id: Scalars['bigint'];
};


export type QueryParameter_Sub_Key_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryParameter_SubtypeArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Subtype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Subtype_Order_By>>;
  where?: InputMaybe<Parameter_Subtype_Bool_Exp>;
};


export type QueryParameter_Subtype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Subtype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Subtype_Order_By>>;
  where?: InputMaybe<Parameter_Subtype_Bool_Exp>;
};


export type QueryParameter_Subtype_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryParameter_Subtype_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Subtype_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Subtype_Options_Order_By>>;
  where?: InputMaybe<Parameter_Subtype_Options_Bool_Exp>;
};


export type QueryParameter_Subtype_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Subtype_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Subtype_Options_Order_By>>;
  where?: InputMaybe<Parameter_Subtype_Options_Bool_Exp>;
};


export type QueryParameter_Subtype_Options_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryParameter_TypeArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Type_Order_By>>;
  where?: InputMaybe<Parameter_Type_Bool_Exp>;
};


export type QueryParameter_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Type_Order_By>>;
  where?: InputMaybe<Parameter_Type_Bool_Exp>;
};


export type QueryParameter_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryPayeeArgs = {
  distinct_on?: InputMaybe<Array<Payee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payee_Order_By>>;
  where?: InputMaybe<Payee_Bool_Exp>;
};


export type QueryPayee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payee_Order_By>>;
  where?: InputMaybe<Payee_Bool_Exp>;
};


export type QueryPayee_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryPaymentTypeArgs = {
  where: PaymentTypeWhereUniqueInput;
};


export type QueryPaymentTypesArgs = {
  cursor?: InputMaybe<PaymentTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentTypeWhereInput>;
};


export type QueryProductArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type QueryProductRatesArgs = {
  parameters: Array<FilteredParameter>;
  productId: Scalars['Int'];
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type QueryProduct_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryProduct_Dealer_CostArgs = {
  distinct_on?: InputMaybe<Array<Product_Dealer_Cost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Dealer_Cost_Order_By>>;
  where?: InputMaybe<Product_Dealer_Cost_Bool_Exp>;
};


export type QueryProduct_Dealer_Cost_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Dealer_Cost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Dealer_Cost_Order_By>>;
  where?: InputMaybe<Product_Dealer_Cost_Bool_Exp>;
};


export type QueryProduct_Dealer_OffsetArgs = {
  distinct_on?: InputMaybe<Array<Product_Dealer_Offset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Dealer_Offset_Order_By>>;
  where?: InputMaybe<Product_Dealer_Offset_Bool_Exp>;
};


export type QueryProduct_Dealer_Offset_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Dealer_Offset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Dealer_Offset_Order_By>>;
  where?: InputMaybe<Product_Dealer_Offset_Bool_Exp>;
};


export type QueryProduct_Dealer_Offset_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryProduct_Msrp_OperationArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Operation_Order_By>>;
  where?: InputMaybe<Product_Msrp_Operation_Bool_Exp>;
};


export type QueryProduct_Msrp_Operation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Operation_Order_By>>;
  where?: InputMaybe<Product_Msrp_Operation_Bool_Exp>;
};


export type QueryProduct_Msrp_Operation_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryProduct_Msrp_OperatorArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Operator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Operator_Order_By>>;
  where?: InputMaybe<Product_Msrp_Operator_Bool_Exp>;
};


export type QueryProduct_Msrp_Operator_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Operator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Operator_Order_By>>;
  where?: InputMaybe<Product_Msrp_Operator_Bool_Exp>;
};


export type QueryProduct_Msrp_Operator_By_PkArgs = {
  name: Scalars['String'];
};


export type QueryProduct_Msrp_ParameterArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Parameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Parameter_Order_By>>;
  where?: InputMaybe<Product_Msrp_Parameter_Bool_Exp>;
};


export type QueryProduct_Msrp_Parameter_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Parameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Parameter_Order_By>>;
  where?: InputMaybe<Product_Msrp_Parameter_Bool_Exp>;
};


export type QueryProduct_Msrp_Parameter_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryProduct_Msrp_Parameter_KeyArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Parameter_Key_Order_By>>;
  where?: InputMaybe<Product_Msrp_Parameter_Key_Bool_Exp>;
};


export type QueryProduct_Msrp_Parameter_Key_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Parameter_Key_Order_By>>;
  where?: InputMaybe<Product_Msrp_Parameter_Key_Bool_Exp>;
};


export type QueryProduct_Msrp_Parameter_Key_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryProduct_Msrp_Parameter_Key_CombinationArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Parameter_Key_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Parameter_Key_Combination_Order_By>>;
  where?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Bool_Exp>;
};


export type QueryProduct_Msrp_Parameter_Key_Combination_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Parameter_Key_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Parameter_Key_Combination_Order_By>>;
  where?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Bool_Exp>;
};


export type QueryProduct_Msrp_Parameter_Key_Combination_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryProduct_Parameter_KeyArgs = {
  distinct_on?: InputMaybe<Array<Product_Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Parameter_Key_Order_By>>;
  where?: InputMaybe<Product_Parameter_Key_Bool_Exp>;
};


export type QueryProduct_Parameter_Key_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Parameter_Key_Order_By>>;
  where?: InputMaybe<Product_Parameter_Key_Bool_Exp>;
};


export type QueryProduct_Parameter_Key_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryProduct_Parameter_Key_CombinationArgs = {
  distinct_on?: InputMaybe<Array<Product_Parameter_Key_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Parameter_Key_Combination_Order_By>>;
  where?: InputMaybe<Product_Parameter_Key_Combination_Bool_Exp>;
};


export type QueryProduct_Parameter_Key_Combination_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Parameter_Key_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Parameter_Key_Combination_Order_By>>;
  where?: InputMaybe<Product_Parameter_Key_Combination_Bool_Exp>;
};


export type QueryProduct_Parameter_Key_Combination_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryProduct_Parameter_Key_Sub_CombinationArgs = {
  distinct_on?: InputMaybe<Array<Product_Parameter_Key_Sub_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Parameter_Key_Sub_Combination_Order_By>>;
  where?: InputMaybe<Product_Parameter_Key_Sub_Combination_Bool_Exp>;
};


export type QueryProduct_Parameter_Key_Sub_Combination_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Parameter_Key_Sub_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Parameter_Key_Sub_Combination_Order_By>>;
  where?: InputMaybe<Product_Parameter_Key_Sub_Combination_Bool_Exp>;
};


export type QueryProduct_Parameter_Key_Sub_Combination_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryProduct_TypeArgs = {
  distinct_on?: InputMaybe<Array<Product_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Type_Order_By>>;
  where?: InputMaybe<Product_Type_Bool_Exp>;
};


export type QueryProduct_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Type_Order_By>>;
  where?: InputMaybe<Product_Type_Bool_Exp>;
};


export type QueryProduct_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryProgramArgs = {
  distinct_on?: InputMaybe<Array<Program_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Program_Order_By>>;
  where?: InputMaybe<Program_Bool_Exp>;
};


export type QueryProgramProductEnrollmentArgs = {
  where: ProgramProductEnrollmentWhereUniqueInput;
};


export type QueryProgramProductEnrollmentDealerTierArgs = {
  where: ProgramProductEnrollmentDealerTierWhereUniqueInput;
};


export type QueryProgramProductEnrollmentDealerTiersArgs = {
  cursor?: InputMaybe<ProgramProductEnrollmentDealerTierWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProgramProductEnrollmentDealerTierScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProgramProductEnrollmentDealerTierOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProgramProductEnrollmentDealerTierWhereInput>;
};


export type QueryProgramProductEnrollmentsArgs = {
  cursor?: InputMaybe<ProgramProductEnrollmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProgramProductEnrollmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProgramProductEnrollmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProgramProductEnrollmentWhereInput>;
};


export type QueryProgram_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Program_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Program_Order_By>>;
  where?: InputMaybe<Program_Bool_Exp>;
};


export type QueryProgram_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryPublish_CompleteArgs = {
  distinct_on?: InputMaybe<Array<Publish_Complete_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Publish_Complete_Order_By>>;
  where?: InputMaybe<Publish_Complete_Bool_Exp>;
};


export type QueryPublish_Complete_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Publish_Complete_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Publish_Complete_Order_By>>;
  where?: InputMaybe<Publish_Complete_Bool_Exp>;
};


export type QueryPublish_Complete_By_PkArgs = {
  publish_complete_id: Scalars['Int'];
};


export type QueryPublish_ProductArgs = {
  distinct_on?: InputMaybe<Array<Publish_Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Publish_Product_Order_By>>;
  where?: InputMaybe<Publish_Product_Bool_Exp>;
};


export type QueryPublish_Product_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Publish_Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Publish_Product_Order_By>>;
  where?: InputMaybe<Publish_Product_Bool_Exp>;
};


export type QueryPublish_Product_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryRate_Slice_Base_SliceArgs = {
  distinct_on?: InputMaybe<Array<Rate_Slice_Base_Slice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rate_Slice_Base_Slice_Order_By>>;
  where?: InputMaybe<Rate_Slice_Base_Slice_Bool_Exp>;
};


export type QueryRate_Slice_Base_Slice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rate_Slice_Base_Slice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rate_Slice_Base_Slice_Order_By>>;
  where?: InputMaybe<Rate_Slice_Base_Slice_Bool_Exp>;
};


export type QueryRate_Slice_Base_Slice_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryRisk_TypeArgs = {
  distinct_on?: InputMaybe<Array<Risk_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Risk_Type_Order_By>>;
  where?: InputMaybe<Risk_Type_Bool_Exp>;
};


export type QueryRisk_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Risk_Type_Order_By>>;
  where?: InputMaybe<Risk_Type_Bool_Exp>;
};


export type QueryRisk_Type_By_PkArgs = {
  risk_type_code: Scalars['String'];
};


export type QueryRound_TypeArgs = {
  distinct_on?: InputMaybe<Array<Round_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Round_Type_Order_By>>;
  where?: InputMaybe<Round_Type_Bool_Exp>;
};


export type QueryRound_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Round_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Round_Type_Order_By>>;
  where?: InputMaybe<Round_Type_Bool_Exp>;
};


export type QueryRound_Type_By_PkArgs = {
  round_type: Scalars['String'];
};


export type QueryUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type QueryUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type QueryUser_By_PkArgs = {
  id: Scalars['bigint'];
};


export type QueryVw_Final_CostsArgs = {
  distinct_on?: InputMaybe<Array<Vw_Final_Costs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vw_Final_Costs_Order_By>>;
  where?: InputMaybe<Vw_Final_Costs_Bool_Exp>;
};


export type QueryVw_Final_Costs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vw_Final_Costs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vw_Final_Costs_Order_By>>;
  where?: InputMaybe<Vw_Final_Costs_Bool_Exp>;
};


export type QueryVw_Parameter_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Vw_Parameter_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vw_Parameter_Values_Order_By>>;
  where?: InputMaybe<Vw_Parameter_Values_Bool_Exp>;
};


export type QueryVw_Parameter_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vw_Parameter_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vw_Parameter_Values_Order_By>>;
  where?: InputMaybe<Vw_Parameter_Values_Bool_Exp>;
};


export type QueryVw_Product_Parameter_Key_Combination_Dealer_Cost_RoundingArgs = {
  distinct_on?: InputMaybe<Array<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Order_By>>;
  where?: InputMaybe<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Bool_Exp>;
};


export type QueryVw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Order_By>>;
  where?: InputMaybe<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Bool_Exp>;
};


export type QueryVw_Rate_BucketArgs = {
  distinct_on?: InputMaybe<Array<Vw_Rate_Bucket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vw_Rate_Bucket_Order_By>>;
  where?: InputMaybe<Vw_Rate_Bucket_Bool_Exp>;
};


export type QueryVw_Rate_Bucket_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vw_Rate_Bucket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vw_Rate_Bucket_Order_By>>;
  where?: InputMaybe<Vw_Rate_Bucket_Bool_Exp>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** fetch data from the table: "audit_log" */
  audit_log: Array<Audit_Log>;
  /** fetch aggregated fields from the table: "audit_log" */
  audit_log_aggregate: Audit_Log_Aggregate;
  /** fetch data from the table: "audit_log" using primary key columns */
  audit_log_by_pk?: Maybe<Audit_Log>;
  /** fetch data from the table: "bucket" */
  bucket: Array<Bucket>;
  /** fetch aggregated fields from the table: "bucket" */
  bucket_aggregate: Bucket_Aggregate;
  /** fetch data from the table: "bucket_base_parameter" */
  bucket_base_parameter: Array<Bucket_Base_Parameter>;
  /** fetch aggregated fields from the table: "bucket_base_parameter" */
  bucket_base_parameter_aggregate: Bucket_Base_Parameter_Aggregate;
  /** fetch data from the table: "bucket_base_parameter" using primary key columns */
  bucket_base_parameter_by_pk?: Maybe<Bucket_Base_Parameter>;
  /** fetch data from the table: "bucket_base_rate" */
  bucket_base_rate: Array<Bucket_Base_Rate>;
  /** fetch aggregated fields from the table: "bucket_base_rate" */
  bucket_base_rate_aggregate: Bucket_Base_Rate_Aggregate;
  /** fetch data from the table: "bucket_base_rate" using primary key columns */
  bucket_base_rate_by_pk?: Maybe<Bucket_Base_Rate>;
  /** fetch data from the table: "bucket" using primary key columns */
  bucket_by_pk?: Maybe<Bucket>;
  /** fetch data from the table: "bucket_maximum" */
  bucket_maximum: Array<Bucket_Maximum>;
  /** fetch aggregated fields from the table: "bucket_maximum" */
  bucket_maximum_aggregate: Bucket_Maximum_Aggregate;
  /** fetch data from the table: "bucket_maximum" using primary key columns */
  bucket_maximum_by_pk?: Maybe<Bucket_Maximum>;
  /** fetch data from the table: "bucket_minimum" */
  bucket_minimum: Array<Bucket_Minimum>;
  /** fetch aggregated fields from the table: "bucket_minimum" */
  bucket_minimum_aggregate: Bucket_Minimum_Aggregate;
  /** fetch data from the table: "bucket_minimum" using primary key columns */
  bucket_minimum_by_pk?: Maybe<Bucket_Minimum>;
  /** fetch data from the table: "bucket_offset" */
  bucket_offset: Array<Bucket_Offset>;
  /** fetch aggregated fields from the table: "bucket_offset" */
  bucket_offset_aggregate: Bucket_Offset_Aggregate;
  /** fetch data from the table: "bucket_offset" using primary key columns */
  bucket_offset_by_pk?: Maybe<Bucket_Offset>;
  /** fetch data from the table: "bucket_rate" */
  bucket_rate: Array<Bucket_Rate>;
  /** fetch aggregated fields from the table: "bucket_rate" */
  bucket_rate_aggregate: Bucket_Rate_Aggregate;
  /** fetch data from the table: "bucket_rate" using primary key columns */
  bucket_rate_by_pk?: Maybe<Bucket_Rate>;
  /** fetch data from the table: "bucket_rate_exception" */
  bucket_rate_exception: Array<Bucket_Rate_Exception>;
  /** fetch aggregated fields from the table: "bucket_rate_exception" */
  bucket_rate_exception_aggregate: Bucket_Rate_Exception_Aggregate;
  /** fetch data from the table: "bucket_rate_exception" using primary key columns */
  bucket_rate_exception_by_pk?: Maybe<Bucket_Rate_Exception>;
  /** fetch data from the table: "bucket_rate_factor" */
  bucket_rate_factor: Array<Bucket_Rate_Factor>;
  /** fetch aggregated fields from the table: "bucket_rate_factor" */
  bucket_rate_factor_aggregate: Bucket_Rate_Factor_Aggregate;
  /** fetch data from the table: "bucket_rate_factor" using primary key columns */
  bucket_rate_factor_by_pk?: Maybe<Bucket_Rate_Factor>;
  /** fetch data from the table: "bucket_rate_offset" */
  bucket_rate_offset: Array<Bucket_Rate_Offset>;
  /** fetch aggregated fields from the table: "bucket_rate_offset" */
  bucket_rate_offset_aggregate: Bucket_Rate_Offset_Aggregate;
  /** fetch data from the table: "bucket_rate_offset" using primary key columns */
  bucket_rate_offset_by_pk?: Maybe<Bucket_Rate_Offset>;
  /** fetch data from the table: "bucket_rounding" */
  bucket_rounding: Array<Bucket_Rounding>;
  /** fetch aggregated fields from the table: "bucket_rounding" */
  bucket_rounding_aggregate: Bucket_Rounding_Aggregate;
  /** fetch data from the table: "bucket_rounding" using primary key columns */
  bucket_rounding_by_pk?: Maybe<Bucket_Rounding>;
  /** fetch data from the table: "commission_type" */
  commission_type: Array<Commission_Type>;
  /** fetch aggregated fields from the table: "commission_type" */
  commission_type_aggregate: Commission_Type_Aggregate;
  /** fetch data from the table: "commission_type" using primary key columns */
  commission_type_by_pk?: Maybe<Commission_Type>;
  /** fetch data from the table: "control_type" */
  control_type: Array<Control_Type>;
  /** fetch aggregated fields from the table: "control_type" */
  control_type_aggregate: Control_Type_Aggregate;
  /** fetch data from the table: "control_type" using primary key columns */
  control_type_by_pk?: Maybe<Control_Type>;
  /** fetch data from the table: "dealer_product" */
  dealer_product: Array<Dealer_Product>;
  /** fetch aggregated fields from the table: "dealer_product" */
  dealer_product_aggregate: Dealer_Product_Aggregate;
  /** fetch data from the table: "dealer_product" using primary key columns */
  dealer_product_by_pk?: Maybe<Dealer_Product>;
  /** fetch data from the table: "ledger_event" */
  ledger_event: Array<Ledger_Event>;
  /** fetch aggregated fields from the table: "ledger_event" */
  ledger_event_aggregate: Ledger_Event_Aggregate;
  /** fetch data from the table: "ledger_event" using primary key columns */
  ledger_event_by_pk?: Maybe<Ledger_Event>;
  /** fetch data from the table: "non_sellable_combination" */
  non_sellable_combination: Array<Non_Sellable_Combination>;
  /** fetch aggregated fields from the table: "non_sellable_combination" */
  non_sellable_combination_aggregate: Non_Sellable_Combination_Aggregate;
  /** fetch data from the table: "non_sellable_combination" using primary key columns */
  non_sellable_combination_by_pk?: Maybe<Non_Sellable_Combination>;
  /** fetch data from the table: "non_sellable_combination_option" */
  non_sellable_combination_option: Array<Non_Sellable_Combination_Option>;
  /** fetch aggregated fields from the table: "non_sellable_combination_option" */
  non_sellable_combination_option_aggregate: Non_Sellable_Combination_Option_Aggregate;
  /** fetch data from the table: "non_sellable_combination_option" using primary key columns */
  non_sellable_combination_option_by_pk?: Maybe<Non_Sellable_Combination_Option>;
  /** fetch data from the table: "offset_type" */
  offset_type: Array<Offset_Type>;
  /** fetch aggregated fields from the table: "offset_type" */
  offset_type_aggregate: Offset_Type_Aggregate;
  /** fetch data from the table: "offset_type" using primary key columns */
  offset_type_by_pk?: Maybe<Offset_Type>;
  /** fetch data from the table: "operator" */
  operator: Array<Operator>;
  /** fetch aggregated fields from the table: "operator" */
  operator_aggregate: Operator_Aggregate;
  /** fetch data from the table: "operator" using primary key columns */
  operator_by_pk?: Maybe<Operator>;
  /** fetch data from the table: "parameter" */
  parameter: Array<Parameter>;
  /** fetch aggregated fields from the table: "parameter" */
  parameter_aggregate: Parameter_Aggregate;
  /** fetch data from the table: "parameter" using primary key columns */
  parameter_by_pk?: Maybe<Parameter>;
  /** fetch data from the table: "parameter_key" */
  parameter_key: Array<Parameter_Key>;
  /** fetch aggregated fields from the table: "parameter_key" */
  parameter_key_aggregate: Parameter_Key_Aggregate;
  /** fetch data from the table: "parameter_key" using primary key columns */
  parameter_key_by_pk?: Maybe<Parameter_Key>;
  /** fetch data from the table: "parameter_key_value" */
  parameter_key_value: Array<Parameter_Key_Value>;
  /** fetch aggregated fields from the table: "parameter_key_value" */
  parameter_key_value_aggregate: Parameter_Key_Value_Aggregate;
  /** fetch data from the table: "parameter_key_value" using primary key columns */
  parameter_key_value_by_pk?: Maybe<Parameter_Key_Value>;
  /** fetch data from the table: "parameter_sub_key" */
  parameter_sub_key: Array<Parameter_Sub_Key>;
  /** fetch aggregated fields from the table: "parameter_sub_key" */
  parameter_sub_key_aggregate: Parameter_Sub_Key_Aggregate;
  /** fetch data from the table: "parameter_sub_key_bucket_discount" */
  parameter_sub_key_bucket_discount: Array<Parameter_Sub_Key_Bucket_Discount>;
  /** fetch aggregated fields from the table: "parameter_sub_key_bucket_discount" */
  parameter_sub_key_bucket_discount_aggregate: Parameter_Sub_Key_Bucket_Discount_Aggregate;
  /** fetch data from the table: "parameter_sub_key_bucket_discount" using primary key columns */
  parameter_sub_key_bucket_discount_by_pk?: Maybe<Parameter_Sub_Key_Bucket_Discount>;
  /** fetch data from the table: "parameter_sub_key" using primary key columns */
  parameter_sub_key_by_pk?: Maybe<Parameter_Sub_Key>;
  /** fetch data from the table: "parameter_subtype" */
  parameter_subtype: Array<Parameter_Subtype>;
  /** fetch aggregated fields from the table: "parameter_subtype" */
  parameter_subtype_aggregate: Parameter_Subtype_Aggregate;
  /** fetch data from the table: "parameter_subtype" using primary key columns */
  parameter_subtype_by_pk?: Maybe<Parameter_Subtype>;
  /** An array relationship */
  parameter_subtype_options: Array<Parameter_Subtype_Options>;
  /** An aggregate relationship */
  parameter_subtype_options_aggregate: Parameter_Subtype_Options_Aggregate;
  /** fetch data from the table: "parameter_subtype_options" using primary key columns */
  parameter_subtype_options_by_pk?: Maybe<Parameter_Subtype_Options>;
  /** fetch data from the table: "parameter_type" */
  parameter_type: Array<Parameter_Type>;
  /** fetch aggregated fields from the table: "parameter_type" */
  parameter_type_aggregate: Parameter_Type_Aggregate;
  /** fetch data from the table: "parameter_type" using primary key columns */
  parameter_type_by_pk?: Maybe<Parameter_Type>;
  /** fetch data from the table: "payee" */
  payee: Array<Payee>;
  /** fetch aggregated fields from the table: "payee" */
  payee_aggregate: Payee_Aggregate;
  /** fetch data from the table: "payee" using primary key columns */
  payee_by_pk?: Maybe<Payee>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "vw_dealer_cost" */
  product_dealer_cost: Array<Product_Dealer_Cost>;
  /** fetch aggregated fields from the table: "vw_dealer_cost" */
  product_dealer_cost_aggregate: Product_Dealer_Cost_Aggregate;
  /** fetch data from the table: "product_dealer_offset" */
  product_dealer_offset: Array<Product_Dealer_Offset>;
  /** fetch aggregated fields from the table: "product_dealer_offset" */
  product_dealer_offset_aggregate: Product_Dealer_Offset_Aggregate;
  /** fetch data from the table: "product_dealer_offset" using primary key columns */
  product_dealer_offset_by_pk?: Maybe<Product_Dealer_Offset>;
  /** fetch data from the table: "product_msrp_operation" */
  product_msrp_operation: Array<Product_Msrp_Operation>;
  /** fetch aggregated fields from the table: "product_msrp_operation" */
  product_msrp_operation_aggregate: Product_Msrp_Operation_Aggregate;
  /** fetch data from the table: "product_msrp_operation" using primary key columns */
  product_msrp_operation_by_pk?: Maybe<Product_Msrp_Operation>;
  /** fetch data from the table: "product_msrp_operator" */
  product_msrp_operator: Array<Product_Msrp_Operator>;
  /** fetch aggregated fields from the table: "product_msrp_operator" */
  product_msrp_operator_aggregate: Product_Msrp_Operator_Aggregate;
  /** fetch data from the table: "product_msrp_operator" using primary key columns */
  product_msrp_operator_by_pk?: Maybe<Product_Msrp_Operator>;
  /** fetch data from the table: "product_msrp_parameter" */
  product_msrp_parameter: Array<Product_Msrp_Parameter>;
  /** fetch aggregated fields from the table: "product_msrp_parameter" */
  product_msrp_parameter_aggregate: Product_Msrp_Parameter_Aggregate;
  /** fetch data from the table: "product_msrp_parameter" using primary key columns */
  product_msrp_parameter_by_pk?: Maybe<Product_Msrp_Parameter>;
  /** fetch data from the table: "product_msrp_parameter_key" */
  product_msrp_parameter_key: Array<Product_Msrp_Parameter_Key>;
  /** fetch aggregated fields from the table: "product_msrp_parameter_key" */
  product_msrp_parameter_key_aggregate: Product_Msrp_Parameter_Key_Aggregate;
  /** fetch data from the table: "product_msrp_parameter_key" using primary key columns */
  product_msrp_parameter_key_by_pk?: Maybe<Product_Msrp_Parameter_Key>;
  /** fetch data from the table: "product_msrp_parameter_key_combination" */
  product_msrp_parameter_key_combination: Array<Product_Msrp_Parameter_Key_Combination>;
  /** fetch aggregated fields from the table: "product_msrp_parameter_key_combination" */
  product_msrp_parameter_key_combination_aggregate: Product_Msrp_Parameter_Key_Combination_Aggregate;
  /** fetch data from the table: "product_msrp_parameter_key_combination" using primary key columns */
  product_msrp_parameter_key_combination_by_pk?: Maybe<Product_Msrp_Parameter_Key_Combination>;
  /** fetch data from the table: "product_parameter_key" */
  product_parameter_key: Array<Product_Parameter_Key>;
  /** fetch aggregated fields from the table: "product_parameter_key" */
  product_parameter_key_aggregate: Product_Parameter_Key_Aggregate;
  /** fetch data from the table: "product_parameter_key" using primary key columns */
  product_parameter_key_by_pk?: Maybe<Product_Parameter_Key>;
  /** fetch data from the table: "product_parameter_key_combination" */
  product_parameter_key_combination: Array<Product_Parameter_Key_Combination>;
  /** fetch aggregated fields from the table: "product_parameter_key_combination" */
  product_parameter_key_combination_aggregate: Product_Parameter_Key_Combination_Aggregate;
  /** fetch data from the table: "product_parameter_key_combination" using primary key columns */
  product_parameter_key_combination_by_pk?: Maybe<Product_Parameter_Key_Combination>;
  /** fetch data from the table: "product_parameter_key_sub_combination" */
  product_parameter_key_sub_combination: Array<Product_Parameter_Key_Sub_Combination>;
  /** fetch aggregated fields from the table: "product_parameter_key_sub_combination" */
  product_parameter_key_sub_combination_aggregate: Product_Parameter_Key_Sub_Combination_Aggregate;
  /** fetch data from the table: "product_parameter_key_sub_combination" using primary key columns */
  product_parameter_key_sub_combination_by_pk?: Maybe<Product_Parameter_Key_Sub_Combination>;
  /** fetch data from the table: "product_type" */
  product_type: Array<Product_Type>;
  /** fetch aggregated fields from the table: "product_type" */
  product_type_aggregate: Product_Type_Aggregate;
  /** fetch data from the table: "product_type" using primary key columns */
  product_type_by_pk?: Maybe<Product_Type>;
  /** fetch data from the table: "program" */
  program: Array<Program>;
  /** fetch aggregated fields from the table: "program" */
  program_aggregate: Program_Aggregate;
  /** fetch data from the table: "program" using primary key columns */
  program_by_pk?: Maybe<Program>;
  /** fetch data from the table: "publish_complete" */
  publish_complete: Array<Publish_Complete>;
  /** fetch aggregated fields from the table: "publish_complete" */
  publish_complete_aggregate: Publish_Complete_Aggregate;
  /** fetch data from the table: "publish_complete" using primary key columns */
  publish_complete_by_pk?: Maybe<Publish_Complete>;
  /** fetch data from the table: "publish_product" */
  publish_product: Array<Publish_Product>;
  /** fetch aggregated fields from the table: "publish_product" */
  publish_product_aggregate: Publish_Product_Aggregate;
  /** fetch data from the table: "publish_product" using primary key columns */
  publish_product_by_pk?: Maybe<Publish_Product>;
  /** fetch data from the table: "rate_slice_base_slice" */
  rate_slice_base_slice: Array<Rate_Slice_Base_Slice>;
  /** fetch aggregated fields from the table: "rate_slice_base_slice" */
  rate_slice_base_slice_aggregate: Rate_Slice_Base_Slice_Aggregate;
  /** fetch data from the table: "rate_slice_base_slice" using primary key columns */
  rate_slice_base_slice_by_pk?: Maybe<Rate_Slice_Base_Slice>;
  /** fetch data from the table: "risk_type" */
  risk_type: Array<Risk_Type>;
  /** fetch aggregated fields from the table: "risk_type" */
  risk_type_aggregate: Risk_Type_Aggregate;
  /** fetch data from the table: "risk_type" using primary key columns */
  risk_type_by_pk?: Maybe<Risk_Type>;
  /** fetch data from the table: "round_type" */
  round_type: Array<Round_Type>;
  /** fetch aggregated fields from the table: "round_type" */
  round_type_aggregate: Round_Type_Aggregate;
  /** fetch data from the table: "round_type" using primary key columns */
  round_type_by_pk?: Maybe<Round_Type>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "vw_final_costs" */
  vw_final_costs: Array<Vw_Final_Costs>;
  /** fetch aggregated fields from the table: "vw_final_costs" */
  vw_final_costs_aggregate: Vw_Final_Costs_Aggregate;
  /** fetch data from the table: "vw_parameter_values" */
  vw_parameter_values: Array<Vw_Parameter_Values>;
  /** fetch aggregated fields from the table: "vw_parameter_values" */
  vw_parameter_values_aggregate: Vw_Parameter_Values_Aggregate;
  /** fetch data from the table: "vw_product_parameter_key_combination_dealer_cost_rounding" */
  vw_product_parameter_key_combination_dealer_cost_rounding: Array<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding>;
  /** fetch aggregated fields from the table: "vw_product_parameter_key_combination_dealer_cost_rounding" */
  vw_product_parameter_key_combination_dealer_cost_rounding_aggregate: Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Aggregate;
  /** fetch data from the table: "vw_rate_bucket" */
  vw_rate_bucket: Array<Vw_Rate_Bucket>;
  /** fetch aggregated fields from the table: "vw_rate_bucket" */
  vw_rate_bucket_aggregate: Vw_Rate_Bucket_Aggregate;
};


export type SubscriptionAudit_LogArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_Log_Order_By>>;
  where?: InputMaybe<Audit_Log_Bool_Exp>;
};


export type SubscriptionAudit_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_Log_Order_By>>;
  where?: InputMaybe<Audit_Log_Bool_Exp>;
};


export type SubscriptionAudit_Log_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionBucketArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Order_By>>;
  where?: InputMaybe<Bucket_Bool_Exp>;
};


export type SubscriptionBucket_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Order_By>>;
  where?: InputMaybe<Bucket_Bool_Exp>;
};


export type SubscriptionBucket_Base_ParameterArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Base_Parameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Base_Parameter_Order_By>>;
  where?: InputMaybe<Bucket_Base_Parameter_Bool_Exp>;
};


export type SubscriptionBucket_Base_Parameter_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Base_Parameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Base_Parameter_Order_By>>;
  where?: InputMaybe<Bucket_Base_Parameter_Bool_Exp>;
};


export type SubscriptionBucket_Base_Parameter_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionBucket_Base_RateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Base_Rate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Base_Rate_Order_By>>;
  where?: InputMaybe<Bucket_Base_Rate_Bool_Exp>;
};


export type SubscriptionBucket_Base_Rate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Base_Rate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Base_Rate_Order_By>>;
  where?: InputMaybe<Bucket_Base_Rate_Bool_Exp>;
};


export type SubscriptionBucket_Base_Rate_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionBucket_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionBucket_MaximumArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Maximum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Maximum_Order_By>>;
  where?: InputMaybe<Bucket_Maximum_Bool_Exp>;
};


export type SubscriptionBucket_Maximum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Maximum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Maximum_Order_By>>;
  where?: InputMaybe<Bucket_Maximum_Bool_Exp>;
};


export type SubscriptionBucket_Maximum_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionBucket_MinimumArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Minimum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Minimum_Order_By>>;
  where?: InputMaybe<Bucket_Minimum_Bool_Exp>;
};


export type SubscriptionBucket_Minimum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Minimum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Minimum_Order_By>>;
  where?: InputMaybe<Bucket_Minimum_Bool_Exp>;
};


export type SubscriptionBucket_Minimum_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionBucket_OffsetArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Offset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Offset_Order_By>>;
  where?: InputMaybe<Bucket_Offset_Bool_Exp>;
};


export type SubscriptionBucket_Offset_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Offset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Offset_Order_By>>;
  where?: InputMaybe<Bucket_Offset_Bool_Exp>;
};


export type SubscriptionBucket_Offset_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionBucket_RateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Bool_Exp>;
};


export type SubscriptionBucket_Rate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Bool_Exp>;
};


export type SubscriptionBucket_Rate_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionBucket_Rate_ExceptionArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rate_Exception_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Exception_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Exception_Bool_Exp>;
};


export type SubscriptionBucket_Rate_Exception_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rate_Exception_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Exception_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Exception_Bool_Exp>;
};


export type SubscriptionBucket_Rate_Exception_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionBucket_Rate_FactorArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rate_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Factor_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Factor_Bool_Exp>;
};


export type SubscriptionBucket_Rate_Factor_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rate_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Factor_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Factor_Bool_Exp>;
};


export type SubscriptionBucket_Rate_Factor_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionBucket_Rate_OffsetArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rate_Offset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Offset_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Offset_Bool_Exp>;
};


export type SubscriptionBucket_Rate_Offset_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rate_Offset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Offset_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Offset_Bool_Exp>;
};


export type SubscriptionBucket_Rate_Offset_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionBucket_RoundingArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rounding_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rounding_Order_By>>;
  where?: InputMaybe<Bucket_Rounding_Bool_Exp>;
};


export type SubscriptionBucket_Rounding_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rounding_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rounding_Order_By>>;
  where?: InputMaybe<Bucket_Rounding_Bool_Exp>;
};


export type SubscriptionBucket_Rounding_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionCommission_TypeArgs = {
  distinct_on?: InputMaybe<Array<Commission_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Type_Order_By>>;
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};


export type SubscriptionCommission_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Commission_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Commission_Type_Order_By>>;
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};


export type SubscriptionCommission_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionControl_TypeArgs = {
  distinct_on?: InputMaybe<Array<Control_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Control_Type_Order_By>>;
  where?: InputMaybe<Control_Type_Bool_Exp>;
};


export type SubscriptionControl_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Control_Type_Order_By>>;
  where?: InputMaybe<Control_Type_Bool_Exp>;
};


export type SubscriptionControl_Type_By_PkArgs = {
  name: Scalars['String'];
};


export type SubscriptionDealer_ProductArgs = {
  distinct_on?: InputMaybe<Array<Dealer_Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dealer_Product_Order_By>>;
  where?: InputMaybe<Dealer_Product_Bool_Exp>;
};


export type SubscriptionDealer_Product_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dealer_Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dealer_Product_Order_By>>;
  where?: InputMaybe<Dealer_Product_Bool_Exp>;
};


export type SubscriptionDealer_Product_By_PkArgs = {
  id: Scalars['Int'];
};


export type SubscriptionLedger_EventArgs = {
  distinct_on?: InputMaybe<Array<Ledger_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ledger_Event_Order_By>>;
  where?: InputMaybe<Ledger_Event_Bool_Exp>;
};


export type SubscriptionLedger_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ledger_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ledger_Event_Order_By>>;
  where?: InputMaybe<Ledger_Event_Bool_Exp>;
};


export type SubscriptionLedger_Event_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionNon_Sellable_CombinationArgs = {
  distinct_on?: InputMaybe<Array<Non_Sellable_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Non_Sellable_Combination_Order_By>>;
  where?: InputMaybe<Non_Sellable_Combination_Bool_Exp>;
};


export type SubscriptionNon_Sellable_Combination_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Non_Sellable_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Non_Sellable_Combination_Order_By>>;
  where?: InputMaybe<Non_Sellable_Combination_Bool_Exp>;
};


export type SubscriptionNon_Sellable_Combination_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionNon_Sellable_Combination_OptionArgs = {
  distinct_on?: InputMaybe<Array<Non_Sellable_Combination_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Non_Sellable_Combination_Option_Order_By>>;
  where?: InputMaybe<Non_Sellable_Combination_Option_Bool_Exp>;
};


export type SubscriptionNon_Sellable_Combination_Option_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Non_Sellable_Combination_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Non_Sellable_Combination_Option_Order_By>>;
  where?: InputMaybe<Non_Sellable_Combination_Option_Bool_Exp>;
};


export type SubscriptionNon_Sellable_Combination_Option_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionOffset_TypeArgs = {
  distinct_on?: InputMaybe<Array<Offset_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Offset_Type_Order_By>>;
  where?: InputMaybe<Offset_Type_Bool_Exp>;
};


export type SubscriptionOffset_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Offset_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Offset_Type_Order_By>>;
  where?: InputMaybe<Offset_Type_Bool_Exp>;
};


export type SubscriptionOffset_Type_By_PkArgs = {
  offset_type: Scalars['String'];
};


export type SubscriptionOperatorArgs = {
  distinct_on?: InputMaybe<Array<Operator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operator_Order_By>>;
  where?: InputMaybe<Operator_Bool_Exp>;
};


export type SubscriptionOperator_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operator_Order_By>>;
  where?: InputMaybe<Operator_Bool_Exp>;
};


export type SubscriptionOperator_By_PkArgs = {
  operator: Scalars['String'];
};


export type SubscriptionParameterArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Order_By>>;
  where?: InputMaybe<Parameter_Bool_Exp>;
};


export type SubscriptionParameter_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Order_By>>;
  where?: InputMaybe<Parameter_Bool_Exp>;
};


export type SubscriptionParameter_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionParameter_KeyArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Key_Order_By>>;
  where?: InputMaybe<Parameter_Key_Bool_Exp>;
};


export type SubscriptionParameter_Key_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Key_Order_By>>;
  where?: InputMaybe<Parameter_Key_Bool_Exp>;
};


export type SubscriptionParameter_Key_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionParameter_Key_ValueArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Key_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Key_Value_Order_By>>;
  where?: InputMaybe<Parameter_Key_Value_Bool_Exp>;
};


export type SubscriptionParameter_Key_Value_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Key_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Key_Value_Order_By>>;
  where?: InputMaybe<Parameter_Key_Value_Bool_Exp>;
};


export type SubscriptionParameter_Key_Value_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionParameter_Sub_KeyArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Sub_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Sub_Key_Order_By>>;
  where?: InputMaybe<Parameter_Sub_Key_Bool_Exp>;
};


export type SubscriptionParameter_Sub_Key_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Sub_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Sub_Key_Order_By>>;
  where?: InputMaybe<Parameter_Sub_Key_Bool_Exp>;
};


export type SubscriptionParameter_Sub_Key_Bucket_DiscountArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Sub_Key_Bucket_Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Sub_Key_Bucket_Discount_Order_By>>;
  where?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Bool_Exp>;
};


export type SubscriptionParameter_Sub_Key_Bucket_Discount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Sub_Key_Bucket_Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Sub_Key_Bucket_Discount_Order_By>>;
  where?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Bool_Exp>;
};


export type SubscriptionParameter_Sub_Key_Bucket_Discount_By_PkArgs = {
  bucket_id: Scalars['bigint'];
  parameter_sub_key_id: Scalars['bigint'];
};


export type SubscriptionParameter_Sub_Key_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionParameter_SubtypeArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Subtype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Subtype_Order_By>>;
  where?: InputMaybe<Parameter_Subtype_Bool_Exp>;
};


export type SubscriptionParameter_Subtype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Subtype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Subtype_Order_By>>;
  where?: InputMaybe<Parameter_Subtype_Bool_Exp>;
};


export type SubscriptionParameter_Subtype_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionParameter_Subtype_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Subtype_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Subtype_Options_Order_By>>;
  where?: InputMaybe<Parameter_Subtype_Options_Bool_Exp>;
};


export type SubscriptionParameter_Subtype_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Subtype_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Subtype_Options_Order_By>>;
  where?: InputMaybe<Parameter_Subtype_Options_Bool_Exp>;
};


export type SubscriptionParameter_Subtype_Options_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionParameter_TypeArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Type_Order_By>>;
  where?: InputMaybe<Parameter_Type_Bool_Exp>;
};


export type SubscriptionParameter_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Type_Order_By>>;
  where?: InputMaybe<Parameter_Type_Bool_Exp>;
};


export type SubscriptionParameter_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionPayeeArgs = {
  distinct_on?: InputMaybe<Array<Payee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payee_Order_By>>;
  where?: InputMaybe<Payee_Bool_Exp>;
};


export type SubscriptionPayee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payee_Order_By>>;
  where?: InputMaybe<Payee_Bool_Exp>;
};


export type SubscriptionPayee_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionProductArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type SubscriptionProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


export type SubscriptionProduct_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionProduct_Dealer_CostArgs = {
  distinct_on?: InputMaybe<Array<Product_Dealer_Cost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Dealer_Cost_Order_By>>;
  where?: InputMaybe<Product_Dealer_Cost_Bool_Exp>;
};


export type SubscriptionProduct_Dealer_Cost_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Dealer_Cost_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Dealer_Cost_Order_By>>;
  where?: InputMaybe<Product_Dealer_Cost_Bool_Exp>;
};


export type SubscriptionProduct_Dealer_OffsetArgs = {
  distinct_on?: InputMaybe<Array<Product_Dealer_Offset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Dealer_Offset_Order_By>>;
  where?: InputMaybe<Product_Dealer_Offset_Bool_Exp>;
};


export type SubscriptionProduct_Dealer_Offset_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Dealer_Offset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Dealer_Offset_Order_By>>;
  where?: InputMaybe<Product_Dealer_Offset_Bool_Exp>;
};


export type SubscriptionProduct_Dealer_Offset_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionProduct_Msrp_OperationArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Operation_Order_By>>;
  where?: InputMaybe<Product_Msrp_Operation_Bool_Exp>;
};


export type SubscriptionProduct_Msrp_Operation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Operation_Order_By>>;
  where?: InputMaybe<Product_Msrp_Operation_Bool_Exp>;
};


export type SubscriptionProduct_Msrp_Operation_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionProduct_Msrp_OperatorArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Operator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Operator_Order_By>>;
  where?: InputMaybe<Product_Msrp_Operator_Bool_Exp>;
};


export type SubscriptionProduct_Msrp_Operator_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Operator_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Operator_Order_By>>;
  where?: InputMaybe<Product_Msrp_Operator_Bool_Exp>;
};


export type SubscriptionProduct_Msrp_Operator_By_PkArgs = {
  name: Scalars['String'];
};


export type SubscriptionProduct_Msrp_ParameterArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Parameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Parameter_Order_By>>;
  where?: InputMaybe<Product_Msrp_Parameter_Bool_Exp>;
};


export type SubscriptionProduct_Msrp_Parameter_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Parameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Parameter_Order_By>>;
  where?: InputMaybe<Product_Msrp_Parameter_Bool_Exp>;
};


export type SubscriptionProduct_Msrp_Parameter_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionProduct_Msrp_Parameter_KeyArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Parameter_Key_Order_By>>;
  where?: InputMaybe<Product_Msrp_Parameter_Key_Bool_Exp>;
};


export type SubscriptionProduct_Msrp_Parameter_Key_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Parameter_Key_Order_By>>;
  where?: InputMaybe<Product_Msrp_Parameter_Key_Bool_Exp>;
};


export type SubscriptionProduct_Msrp_Parameter_Key_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionProduct_Msrp_Parameter_Key_CombinationArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Parameter_Key_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Parameter_Key_Combination_Order_By>>;
  where?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Bool_Exp>;
};


export type SubscriptionProduct_Msrp_Parameter_Key_Combination_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Parameter_Key_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Parameter_Key_Combination_Order_By>>;
  where?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Bool_Exp>;
};


export type SubscriptionProduct_Msrp_Parameter_Key_Combination_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionProduct_Parameter_KeyArgs = {
  distinct_on?: InputMaybe<Array<Product_Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Parameter_Key_Order_By>>;
  where?: InputMaybe<Product_Parameter_Key_Bool_Exp>;
};


export type SubscriptionProduct_Parameter_Key_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Parameter_Key_Order_By>>;
  where?: InputMaybe<Product_Parameter_Key_Bool_Exp>;
};


export type SubscriptionProduct_Parameter_Key_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionProduct_Parameter_Key_CombinationArgs = {
  distinct_on?: InputMaybe<Array<Product_Parameter_Key_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Parameter_Key_Combination_Order_By>>;
  where?: InputMaybe<Product_Parameter_Key_Combination_Bool_Exp>;
};


export type SubscriptionProduct_Parameter_Key_Combination_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Parameter_Key_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Parameter_Key_Combination_Order_By>>;
  where?: InputMaybe<Product_Parameter_Key_Combination_Bool_Exp>;
};


export type SubscriptionProduct_Parameter_Key_Combination_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionProduct_Parameter_Key_Sub_CombinationArgs = {
  distinct_on?: InputMaybe<Array<Product_Parameter_Key_Sub_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Parameter_Key_Sub_Combination_Order_By>>;
  where?: InputMaybe<Product_Parameter_Key_Sub_Combination_Bool_Exp>;
};


export type SubscriptionProduct_Parameter_Key_Sub_Combination_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Parameter_Key_Sub_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Parameter_Key_Sub_Combination_Order_By>>;
  where?: InputMaybe<Product_Parameter_Key_Sub_Combination_Bool_Exp>;
};


export type SubscriptionProduct_Parameter_Key_Sub_Combination_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionProduct_TypeArgs = {
  distinct_on?: InputMaybe<Array<Product_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Type_Order_By>>;
  where?: InputMaybe<Product_Type_Bool_Exp>;
};


export type SubscriptionProduct_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Type_Order_By>>;
  where?: InputMaybe<Product_Type_Bool_Exp>;
};


export type SubscriptionProduct_Type_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionProgramArgs = {
  distinct_on?: InputMaybe<Array<Program_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Program_Order_By>>;
  where?: InputMaybe<Program_Bool_Exp>;
};


export type SubscriptionProgram_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Program_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Program_Order_By>>;
  where?: InputMaybe<Program_Bool_Exp>;
};


export type SubscriptionProgram_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionPublish_CompleteArgs = {
  distinct_on?: InputMaybe<Array<Publish_Complete_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Publish_Complete_Order_By>>;
  where?: InputMaybe<Publish_Complete_Bool_Exp>;
};


export type SubscriptionPublish_Complete_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Publish_Complete_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Publish_Complete_Order_By>>;
  where?: InputMaybe<Publish_Complete_Bool_Exp>;
};


export type SubscriptionPublish_Complete_By_PkArgs = {
  publish_complete_id: Scalars['Int'];
};


export type SubscriptionPublish_ProductArgs = {
  distinct_on?: InputMaybe<Array<Publish_Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Publish_Product_Order_By>>;
  where?: InputMaybe<Publish_Product_Bool_Exp>;
};


export type SubscriptionPublish_Product_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Publish_Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Publish_Product_Order_By>>;
  where?: InputMaybe<Publish_Product_Bool_Exp>;
};


export type SubscriptionPublish_Product_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionRate_Slice_Base_SliceArgs = {
  distinct_on?: InputMaybe<Array<Rate_Slice_Base_Slice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rate_Slice_Base_Slice_Order_By>>;
  where?: InputMaybe<Rate_Slice_Base_Slice_Bool_Exp>;
};


export type SubscriptionRate_Slice_Base_Slice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rate_Slice_Base_Slice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rate_Slice_Base_Slice_Order_By>>;
  where?: InputMaybe<Rate_Slice_Base_Slice_Bool_Exp>;
};


export type SubscriptionRate_Slice_Base_Slice_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionRisk_TypeArgs = {
  distinct_on?: InputMaybe<Array<Risk_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Risk_Type_Order_By>>;
  where?: InputMaybe<Risk_Type_Bool_Exp>;
};


export type SubscriptionRisk_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Risk_Type_Order_By>>;
  where?: InputMaybe<Risk_Type_Bool_Exp>;
};


export type SubscriptionRisk_Type_By_PkArgs = {
  risk_type_code: Scalars['String'];
};


export type SubscriptionRound_TypeArgs = {
  distinct_on?: InputMaybe<Array<Round_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Round_Type_Order_By>>;
  where?: InputMaybe<Round_Type_Bool_Exp>;
};


export type SubscriptionRound_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Round_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Round_Type_Order_By>>;
  where?: InputMaybe<Round_Type_Bool_Exp>;
};


export type SubscriptionRound_Type_By_PkArgs = {
  round_type: Scalars['String'];
};


export type SubscriptionUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type SubscriptionUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type SubscriptionUser_By_PkArgs = {
  id: Scalars['bigint'];
};


export type SubscriptionVw_Final_CostsArgs = {
  distinct_on?: InputMaybe<Array<Vw_Final_Costs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vw_Final_Costs_Order_By>>;
  where?: InputMaybe<Vw_Final_Costs_Bool_Exp>;
};


export type SubscriptionVw_Final_Costs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vw_Final_Costs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vw_Final_Costs_Order_By>>;
  where?: InputMaybe<Vw_Final_Costs_Bool_Exp>;
};


export type SubscriptionVw_Parameter_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Vw_Parameter_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vw_Parameter_Values_Order_By>>;
  where?: InputMaybe<Vw_Parameter_Values_Bool_Exp>;
};


export type SubscriptionVw_Parameter_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vw_Parameter_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vw_Parameter_Values_Order_By>>;
  where?: InputMaybe<Vw_Parameter_Values_Bool_Exp>;
};


export type SubscriptionVw_Product_Parameter_Key_Combination_Dealer_Cost_RoundingArgs = {
  distinct_on?: InputMaybe<Array<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Order_By>>;
  where?: InputMaybe<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Bool_Exp>;
};


export type SubscriptionVw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Order_By>>;
  where?: InputMaybe<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Bool_Exp>;
};


export type SubscriptionVw_Rate_BucketArgs = {
  distinct_on?: InputMaybe<Array<Vw_Rate_Bucket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vw_Rate_Bucket_Order_By>>;
  where?: InputMaybe<Vw_Rate_Bucket_Bool_Exp>;
};


export type SubscriptionVw_Rate_Bucket_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vw_Rate_Bucket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vw_Rate_Bucket_Order_By>>;
  where?: InputMaybe<Vw_Rate_Bucket_Bool_Exp>;
};

export type UpdateDealerChargebackInput = {
  chargeback: Scalars['Boolean'];
  dealerId: Scalars['Int'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  startDate: Scalars['DateTime'];
};

/** Boolean expression to compare columns of type "_int8". All fields are combined with logical 'AND'. */
export type _Int8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['_int8']>;
  _gt?: InputMaybe<Scalars['_int8']>;
  _gte?: InputMaybe<Scalars['_int8']>;
  _in?: InputMaybe<Array<Scalars['_int8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['_int8']>;
  _lte?: InputMaybe<Scalars['_int8']>;
  _neq?: InputMaybe<Scalars['_int8']>;
  _nin?: InputMaybe<Array<Scalars['_int8']>>;
};

/** columns and relationships of "audit_log" */
export type Audit_Log = {
  __typename?: 'audit_log';
  bucket?: Maybe<Scalars['String']>;
  bucket_id?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  log: Scalars['json'];
  product?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['bigint']>;
  program?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['bigint']>;
  type: Scalars['String'];
  user_email: Scalars['String'];
  user_name: Scalars['String'];
};


/** columns and relationships of "audit_log" */
export type Audit_LogLogArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "audit_log" */
export type Audit_Log_Aggregate = {
  __typename?: 'audit_log_aggregate';
  aggregate?: Maybe<Audit_Log_Aggregate_Fields>;
  nodes: Array<Audit_Log>;
};

/** aggregate fields of "audit_log" */
export type Audit_Log_Aggregate_Fields = {
  __typename?: 'audit_log_aggregate_fields';
  avg?: Maybe<Audit_Log_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Audit_Log_Max_Fields>;
  min?: Maybe<Audit_Log_Min_Fields>;
  stddev?: Maybe<Audit_Log_Stddev_Fields>;
  stddev_pop?: Maybe<Audit_Log_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Audit_Log_Stddev_Samp_Fields>;
  sum?: Maybe<Audit_Log_Sum_Fields>;
  var_pop?: Maybe<Audit_Log_Var_Pop_Fields>;
  var_samp?: Maybe<Audit_Log_Var_Samp_Fields>;
  variance?: Maybe<Audit_Log_Variance_Fields>;
};


/** aggregate fields of "audit_log" */
export type Audit_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Audit_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Audit_Log_Avg_Fields = {
  __typename?: 'audit_log_avg_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "audit_log". All fields are combined with a logical 'AND'. */
export type Audit_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Audit_Log_Bool_Exp>>;
  _not?: InputMaybe<Audit_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Audit_Log_Bool_Exp>>;
  bucket?: InputMaybe<String_Comparison_Exp>;
  bucket_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  log?: InputMaybe<Json_Comparison_Exp>;
  product?: InputMaybe<String_Comparison_Exp>;
  product_id?: InputMaybe<Bigint_Comparison_Exp>;
  program?: InputMaybe<String_Comparison_Exp>;
  program_id?: InputMaybe<Bigint_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user_email?: InputMaybe<String_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "audit_log" */
export enum Audit_Log_Constraint {
  /** unique or primary key constraint */
  AuditLogPkey = 'audit_log_pkey'
}

/** input type for incrementing numeric columns in table "audit_log" */
export type Audit_Log_Inc_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['bigint']>;
  program_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "audit_log" */
export type Audit_Log_Insert_Input = {
  bucket?: InputMaybe<Scalars['String']>;
  bucket_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  log?: InputMaybe<Scalars['json']>;
  product?: InputMaybe<Scalars['String']>;
  product_id?: InputMaybe<Scalars['bigint']>;
  program?: InputMaybe<Scalars['String']>;
  program_id?: InputMaybe<Scalars['bigint']>;
  type?: InputMaybe<Scalars['String']>;
  user_email?: InputMaybe<Scalars['String']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Audit_Log_Max_Fields = {
  __typename?: 'audit_log_max_fields';
  bucket?: Maybe<Scalars['String']>;
  bucket_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  product?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['bigint']>;
  program?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
  user_email?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Audit_Log_Min_Fields = {
  __typename?: 'audit_log_min_fields';
  bucket?: Maybe<Scalars['String']>;
  bucket_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  product?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['bigint']>;
  program?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
  user_email?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "audit_log" */
export type Audit_Log_Mutation_Response = {
  __typename?: 'audit_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Audit_Log>;
};

/** on conflict condition type for table "audit_log" */
export type Audit_Log_On_Conflict = {
  constraint: Audit_Log_Constraint;
  update_columns?: Array<Audit_Log_Update_Column>;
  where?: InputMaybe<Audit_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "audit_log". */
export type Audit_Log_Order_By = {
  bucket?: InputMaybe<Order_By>;
  bucket_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  log?: InputMaybe<Order_By>;
  product?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  program?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_email?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: audit_log */
export type Audit_Log_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "audit_log" */
export enum Audit_Log_Select_Column {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Log = 'log',
  /** column name */
  Product = 'product',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Program = 'program',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  Type = 'type',
  /** column name */
  UserEmail = 'user_email',
  /** column name */
  UserName = 'user_name'
}

/** input type for updating data in table "audit_log" */
export type Audit_Log_Set_Input = {
  bucket?: InputMaybe<Scalars['String']>;
  bucket_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  log?: InputMaybe<Scalars['json']>;
  product?: InputMaybe<Scalars['String']>;
  product_id?: InputMaybe<Scalars['bigint']>;
  program?: InputMaybe<Scalars['String']>;
  program_id?: InputMaybe<Scalars['bigint']>;
  type?: InputMaybe<Scalars['String']>;
  user_email?: InputMaybe<Scalars['String']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Audit_Log_Stddev_Fields = {
  __typename?: 'audit_log_stddev_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Audit_Log_Stddev_Pop_Fields = {
  __typename?: 'audit_log_stddev_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Audit_Log_Stddev_Samp_Fields = {
  __typename?: 'audit_log_stddev_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Audit_Log_Sum_Fields = {
  __typename?: 'audit_log_sum_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  program_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "audit_log" */
export enum Audit_Log_Update_Column {
  /** column name */
  Bucket = 'bucket',
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Log = 'log',
  /** column name */
  Product = 'product',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Program = 'program',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  Type = 'type',
  /** column name */
  UserEmail = 'user_email',
  /** column name */
  UserName = 'user_name'
}

/** aggregate var_pop on columns */
export type Audit_Log_Var_Pop_Fields = {
  __typename?: 'audit_log_var_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Audit_Log_Var_Samp_Fields = {
  __typename?: 'audit_log_var_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Audit_Log_Variance_Fields = {
  __typename?: 'audit_log_variance_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "bucket" */
export type Bucket = {
  __typename?: 'bucket';
  /** An array relationship */
  base_slices: Array<Rate_Slice_Base_Slice>;
  /** An aggregate relationship */
  base_slices_aggregate: Rate_Slice_Base_Slice_Aggregate;
  /** An array relationship */
  bucket_base_parameters: Array<Bucket_Base_Parameter>;
  /** An aggregate relationship */
  bucket_base_parameters_aggregate: Bucket_Base_Parameter_Aggregate;
  /** An object relationship */
  bucket_maximum?: Maybe<Bucket_Maximum>;
  /** An object relationship */
  bucket_minimum?: Maybe<Bucket_Minimum>;
  /** An object relationship */
  bucket_offset?: Maybe<Bucket_Offset>;
  /** An array relationship */
  bucket_rate_factors: Array<Bucket_Rate_Factor>;
  /** An aggregate relationship */
  bucket_rate_factors_aggregate: Bucket_Rate_Factor_Aggregate;
  /** An array relationship */
  bucket_rates: Array<Bucket_Rate>;
  /** An aggregate relationship */
  bucket_rates_aggregate: Bucket_Rate_Aggregate;
  /** An object relationship */
  bucket_rounding?: Maybe<Bucket_Rounding>;
  cms_bucket_number?: Maybe<Scalars['smallint']>;
  /** An object relationship */
  configured_offset?: Maybe<Bucket_Offset>;
  has_saved_rates: Scalars['Boolean'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  /** An object relationship */
  payee: Payee;
  payee_id: Scalars['bigint'];
  /** An object relationship */
  product: Product;
  product_id: Scalars['bigint'];
  reserves: Scalars['Boolean'];
  sort_order: Scalars['Int'];
  uses_base_slices: Scalars['Boolean'];
};


/** columns and relationships of "bucket" */
export type BucketBase_SlicesArgs = {
  distinct_on?: InputMaybe<Array<Rate_Slice_Base_Slice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rate_Slice_Base_Slice_Order_By>>;
  where?: InputMaybe<Rate_Slice_Base_Slice_Bool_Exp>;
};


/** columns and relationships of "bucket" */
export type BucketBase_Slices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rate_Slice_Base_Slice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Rate_Slice_Base_Slice_Order_By>>;
  where?: InputMaybe<Rate_Slice_Base_Slice_Bool_Exp>;
};


/** columns and relationships of "bucket" */
export type BucketBucket_Base_ParametersArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Base_Parameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Base_Parameter_Order_By>>;
  where?: InputMaybe<Bucket_Base_Parameter_Bool_Exp>;
};


/** columns and relationships of "bucket" */
export type BucketBucket_Base_Parameters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Base_Parameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Base_Parameter_Order_By>>;
  where?: InputMaybe<Bucket_Base_Parameter_Bool_Exp>;
};


/** columns and relationships of "bucket" */
export type BucketBucket_Rate_FactorsArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rate_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Factor_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Factor_Bool_Exp>;
};


/** columns and relationships of "bucket" */
export type BucketBucket_Rate_Factors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rate_Factor_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Factor_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Factor_Bool_Exp>;
};


/** columns and relationships of "bucket" */
export type BucketBucket_RatesArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Bool_Exp>;
};


/** columns and relationships of "bucket" */
export type BucketBucket_Rates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Bool_Exp>;
};

/** aggregated selection of "bucket" */
export type Bucket_Aggregate = {
  __typename?: 'bucket_aggregate';
  aggregate?: Maybe<Bucket_Aggregate_Fields>;
  nodes: Array<Bucket>;
};

/** aggregate fields of "bucket" */
export type Bucket_Aggregate_Fields = {
  __typename?: 'bucket_aggregate_fields';
  avg?: Maybe<Bucket_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bucket_Max_Fields>;
  min?: Maybe<Bucket_Min_Fields>;
  stddev?: Maybe<Bucket_Stddev_Fields>;
  stddev_pop?: Maybe<Bucket_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bucket_Stddev_Samp_Fields>;
  sum?: Maybe<Bucket_Sum_Fields>;
  var_pop?: Maybe<Bucket_Var_Pop_Fields>;
  var_samp?: Maybe<Bucket_Var_Samp_Fields>;
  variance?: Maybe<Bucket_Variance_Fields>;
};


/** aggregate fields of "bucket" */
export type Bucket_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bucket_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bucket" */
export type Bucket_Aggregate_Order_By = {
  avg?: InputMaybe<Bucket_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Bucket_Max_Order_By>;
  min?: InputMaybe<Bucket_Min_Order_By>;
  stddev?: InputMaybe<Bucket_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Bucket_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Bucket_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Bucket_Sum_Order_By>;
  var_pop?: InputMaybe<Bucket_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Bucket_Var_Samp_Order_By>;
  variance?: InputMaybe<Bucket_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "bucket" */
export type Bucket_Arr_Rel_Insert_Input = {
  data: Array<Bucket_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Bucket_On_Conflict>;
};

/** aggregate avg on columns */
export type Bucket_Avg_Fields = {
  __typename?: 'bucket_avg_fields';
  cms_bucket_number?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payee_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "bucket" */
export type Bucket_Avg_Order_By = {
  cms_bucket_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payee_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** columns and relationships of "bucket_base_parameter" */
export type Bucket_Base_Parameter = {
  __typename?: 'bucket_base_parameter';
  /** An object relationship */
  bucket: Bucket;
  bucket_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  parameter: Parameter;
  parameter_id: Scalars['bigint'];
  sort_order: Scalars['Int'];
};

/** aggregated selection of "bucket_base_parameter" */
export type Bucket_Base_Parameter_Aggregate = {
  __typename?: 'bucket_base_parameter_aggregate';
  aggregate?: Maybe<Bucket_Base_Parameter_Aggregate_Fields>;
  nodes: Array<Bucket_Base_Parameter>;
};

/** aggregate fields of "bucket_base_parameter" */
export type Bucket_Base_Parameter_Aggregate_Fields = {
  __typename?: 'bucket_base_parameter_aggregate_fields';
  avg?: Maybe<Bucket_Base_Parameter_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bucket_Base_Parameter_Max_Fields>;
  min?: Maybe<Bucket_Base_Parameter_Min_Fields>;
  stddev?: Maybe<Bucket_Base_Parameter_Stddev_Fields>;
  stddev_pop?: Maybe<Bucket_Base_Parameter_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bucket_Base_Parameter_Stddev_Samp_Fields>;
  sum?: Maybe<Bucket_Base_Parameter_Sum_Fields>;
  var_pop?: Maybe<Bucket_Base_Parameter_Var_Pop_Fields>;
  var_samp?: Maybe<Bucket_Base_Parameter_Var_Samp_Fields>;
  variance?: Maybe<Bucket_Base_Parameter_Variance_Fields>;
};


/** aggregate fields of "bucket_base_parameter" */
export type Bucket_Base_Parameter_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bucket_Base_Parameter_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bucket_base_parameter" */
export type Bucket_Base_Parameter_Aggregate_Order_By = {
  avg?: InputMaybe<Bucket_Base_Parameter_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Bucket_Base_Parameter_Max_Order_By>;
  min?: InputMaybe<Bucket_Base_Parameter_Min_Order_By>;
  stddev?: InputMaybe<Bucket_Base_Parameter_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Bucket_Base_Parameter_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Bucket_Base_Parameter_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Bucket_Base_Parameter_Sum_Order_By>;
  var_pop?: InputMaybe<Bucket_Base_Parameter_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Bucket_Base_Parameter_Var_Samp_Order_By>;
  variance?: InputMaybe<Bucket_Base_Parameter_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "bucket_base_parameter" */
export type Bucket_Base_Parameter_Arr_Rel_Insert_Input = {
  data: Array<Bucket_Base_Parameter_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Bucket_Base_Parameter_On_Conflict>;
};

/** aggregate avg on columns */
export type Bucket_Base_Parameter_Avg_Fields = {
  __typename?: 'bucket_base_parameter_avg_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parameter_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "bucket_base_parameter" */
export type Bucket_Base_Parameter_Avg_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bucket_base_parameter". All fields are combined with a logical 'AND'. */
export type Bucket_Base_Parameter_Bool_Exp = {
  _and?: InputMaybe<Array<Bucket_Base_Parameter_Bool_Exp>>;
  _not?: InputMaybe<Bucket_Base_Parameter_Bool_Exp>;
  _or?: InputMaybe<Array<Bucket_Base_Parameter_Bool_Exp>>;
  bucket?: InputMaybe<Bucket_Bool_Exp>;
  bucket_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  parameter?: InputMaybe<Parameter_Bool_Exp>;
  parameter_id?: InputMaybe<Bigint_Comparison_Exp>;
  sort_order?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "bucket_base_parameter" */
export enum Bucket_Base_Parameter_Constraint {
  /** unique or primary key constraint */
  BucketBaseParameterBucketIdParameterIdKey = 'bucket_base_parameter_bucket_id_parameter_id_key',
  /** unique or primary key constraint */
  BucketBaseParameterPkey = 'bucket_base_parameter_pkey'
}

/** input type for incrementing numeric columns in table "bucket_base_parameter" */
export type Bucket_Base_Parameter_Inc_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  parameter_id?: InputMaybe<Scalars['bigint']>;
  sort_order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "bucket_base_parameter" */
export type Bucket_Base_Parameter_Insert_Input = {
  bucket?: InputMaybe<Bucket_Obj_Rel_Insert_Input>;
  bucket_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  parameter?: InputMaybe<Parameter_Obj_Rel_Insert_Input>;
  parameter_id?: InputMaybe<Scalars['bigint']>;
  sort_order?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Bucket_Base_Parameter_Max_Fields = {
  __typename?: 'bucket_base_parameter_max_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  parameter_id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "bucket_base_parameter" */
export type Bucket_Base_Parameter_Max_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Bucket_Base_Parameter_Min_Fields = {
  __typename?: 'bucket_base_parameter_min_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  parameter_id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "bucket_base_parameter" */
export type Bucket_Base_Parameter_Min_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "bucket_base_parameter" */
export type Bucket_Base_Parameter_Mutation_Response = {
  __typename?: 'bucket_base_parameter_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bucket_Base_Parameter>;
};

/** on conflict condition type for table "bucket_base_parameter" */
export type Bucket_Base_Parameter_On_Conflict = {
  constraint: Bucket_Base_Parameter_Constraint;
  update_columns?: Array<Bucket_Base_Parameter_Update_Column>;
  where?: InputMaybe<Bucket_Base_Parameter_Bool_Exp>;
};

/** Ordering options when selecting data from "bucket_base_parameter". */
export type Bucket_Base_Parameter_Order_By = {
  bucket?: InputMaybe<Bucket_Order_By>;
  bucket_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter?: InputMaybe<Parameter_Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bucket_base_parameter */
export type Bucket_Base_Parameter_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "bucket_base_parameter" */
export enum Bucket_Base_Parameter_Select_Column {
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ParameterId = 'parameter_id',
  /** column name */
  SortOrder = 'sort_order'
}

/** input type for updating data in table "bucket_base_parameter" */
export type Bucket_Base_Parameter_Set_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  parameter_id?: InputMaybe<Scalars['bigint']>;
  sort_order?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Bucket_Base_Parameter_Stddev_Fields = {
  __typename?: 'bucket_base_parameter_stddev_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parameter_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "bucket_base_parameter" */
export type Bucket_Base_Parameter_Stddev_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Bucket_Base_Parameter_Stddev_Pop_Fields = {
  __typename?: 'bucket_base_parameter_stddev_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parameter_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "bucket_base_parameter" */
export type Bucket_Base_Parameter_Stddev_Pop_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Bucket_Base_Parameter_Stddev_Samp_Fields = {
  __typename?: 'bucket_base_parameter_stddev_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parameter_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "bucket_base_parameter" */
export type Bucket_Base_Parameter_Stddev_Samp_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Bucket_Base_Parameter_Sum_Fields = {
  __typename?: 'bucket_base_parameter_sum_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  parameter_id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "bucket_base_parameter" */
export type Bucket_Base_Parameter_Sum_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** update columns of table "bucket_base_parameter" */
export enum Bucket_Base_Parameter_Update_Column {
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ParameterId = 'parameter_id',
  /** column name */
  SortOrder = 'sort_order'
}

/** aggregate var_pop on columns */
export type Bucket_Base_Parameter_Var_Pop_Fields = {
  __typename?: 'bucket_base_parameter_var_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parameter_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "bucket_base_parameter" */
export type Bucket_Base_Parameter_Var_Pop_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Bucket_Base_Parameter_Var_Samp_Fields = {
  __typename?: 'bucket_base_parameter_var_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parameter_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "bucket_base_parameter" */
export type Bucket_Base_Parameter_Var_Samp_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Bucket_Base_Parameter_Variance_Fields = {
  __typename?: 'bucket_base_parameter_variance_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parameter_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "bucket_base_parameter" */
export type Bucket_Base_Parameter_Variance_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** columns and relationships of "bucket_base_rate" */
export type Bucket_Base_Rate = {
  __typename?: 'bucket_base_rate';
  base: Scalars['numeric'];
  bucket_rate_id: Scalars['bigint'];
  id: Scalars['bigint'];
};

/** aggregated selection of "bucket_base_rate" */
export type Bucket_Base_Rate_Aggregate = {
  __typename?: 'bucket_base_rate_aggregate';
  aggregate?: Maybe<Bucket_Base_Rate_Aggregate_Fields>;
  nodes: Array<Bucket_Base_Rate>;
};

/** aggregate fields of "bucket_base_rate" */
export type Bucket_Base_Rate_Aggregate_Fields = {
  __typename?: 'bucket_base_rate_aggregate_fields';
  avg?: Maybe<Bucket_Base_Rate_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bucket_Base_Rate_Max_Fields>;
  min?: Maybe<Bucket_Base_Rate_Min_Fields>;
  stddev?: Maybe<Bucket_Base_Rate_Stddev_Fields>;
  stddev_pop?: Maybe<Bucket_Base_Rate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bucket_Base_Rate_Stddev_Samp_Fields>;
  sum?: Maybe<Bucket_Base_Rate_Sum_Fields>;
  var_pop?: Maybe<Bucket_Base_Rate_Var_Pop_Fields>;
  var_samp?: Maybe<Bucket_Base_Rate_Var_Samp_Fields>;
  variance?: Maybe<Bucket_Base_Rate_Variance_Fields>;
};


/** aggregate fields of "bucket_base_rate" */
export type Bucket_Base_Rate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bucket_Base_Rate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bucket_base_rate" */
export type Bucket_Base_Rate_Aggregate_Order_By = {
  avg?: InputMaybe<Bucket_Base_Rate_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Bucket_Base_Rate_Max_Order_By>;
  min?: InputMaybe<Bucket_Base_Rate_Min_Order_By>;
  stddev?: InputMaybe<Bucket_Base_Rate_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Bucket_Base_Rate_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Bucket_Base_Rate_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Bucket_Base_Rate_Sum_Order_By>;
  var_pop?: InputMaybe<Bucket_Base_Rate_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Bucket_Base_Rate_Var_Samp_Order_By>;
  variance?: InputMaybe<Bucket_Base_Rate_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "bucket_base_rate" */
export type Bucket_Base_Rate_Arr_Rel_Insert_Input = {
  data: Array<Bucket_Base_Rate_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Bucket_Base_Rate_On_Conflict>;
};

/** aggregate avg on columns */
export type Bucket_Base_Rate_Avg_Fields = {
  __typename?: 'bucket_base_rate_avg_fields';
  base?: Maybe<Scalars['Float']>;
  bucket_rate_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "bucket_base_rate" */
export type Bucket_Base_Rate_Avg_Order_By = {
  base?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bucket_base_rate". All fields are combined with a logical 'AND'. */
export type Bucket_Base_Rate_Bool_Exp = {
  _and?: InputMaybe<Array<Bucket_Base_Rate_Bool_Exp>>;
  _not?: InputMaybe<Bucket_Base_Rate_Bool_Exp>;
  _or?: InputMaybe<Array<Bucket_Base_Rate_Bool_Exp>>;
  base?: InputMaybe<Numeric_Comparison_Exp>;
  bucket_rate_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "bucket_base_rate" */
export enum Bucket_Base_Rate_Constraint {
  /** unique or primary key constraint */
  BucketBaseRateBucketRateIdKey = 'bucket_base_rate_bucket_rate_id_key',
  /** unique or primary key constraint */
  BucketBaseRatePkey = 'bucket_base_rate_pkey'
}

/** input type for incrementing numeric columns in table "bucket_base_rate" */
export type Bucket_Base_Rate_Inc_Input = {
  base?: InputMaybe<Scalars['numeric']>;
  bucket_rate_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "bucket_base_rate" */
export type Bucket_Base_Rate_Insert_Input = {
  base?: InputMaybe<Scalars['numeric']>;
  bucket_rate_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Bucket_Base_Rate_Max_Fields = {
  __typename?: 'bucket_base_rate_max_fields';
  base?: Maybe<Scalars['numeric']>;
  bucket_rate_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "bucket_base_rate" */
export type Bucket_Base_Rate_Max_Order_By = {
  base?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Bucket_Base_Rate_Min_Fields = {
  __typename?: 'bucket_base_rate_min_fields';
  base?: Maybe<Scalars['numeric']>;
  bucket_rate_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "bucket_base_rate" */
export type Bucket_Base_Rate_Min_Order_By = {
  base?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "bucket_base_rate" */
export type Bucket_Base_Rate_Mutation_Response = {
  __typename?: 'bucket_base_rate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bucket_Base_Rate>;
};

/** input type for inserting object relation for remote table "bucket_base_rate" */
export type Bucket_Base_Rate_Obj_Rel_Insert_Input = {
  data: Bucket_Base_Rate_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Bucket_Base_Rate_On_Conflict>;
};

/** on conflict condition type for table "bucket_base_rate" */
export type Bucket_Base_Rate_On_Conflict = {
  constraint: Bucket_Base_Rate_Constraint;
  update_columns?: Array<Bucket_Base_Rate_Update_Column>;
  where?: InputMaybe<Bucket_Base_Rate_Bool_Exp>;
};

/** Ordering options when selecting data from "bucket_base_rate". */
export type Bucket_Base_Rate_Order_By = {
  base?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bucket_base_rate */
export type Bucket_Base_Rate_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "bucket_base_rate" */
export enum Bucket_Base_Rate_Select_Column {
  /** column name */
  Base = 'base',
  /** column name */
  BucketRateId = 'bucket_rate_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "bucket_base_rate" */
export type Bucket_Base_Rate_Set_Input = {
  base?: InputMaybe<Scalars['numeric']>;
  bucket_rate_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Bucket_Base_Rate_Stddev_Fields = {
  __typename?: 'bucket_base_rate_stddev_fields';
  base?: Maybe<Scalars['Float']>;
  bucket_rate_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "bucket_base_rate" */
export type Bucket_Base_Rate_Stddev_Order_By = {
  base?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Bucket_Base_Rate_Stddev_Pop_Fields = {
  __typename?: 'bucket_base_rate_stddev_pop_fields';
  base?: Maybe<Scalars['Float']>;
  bucket_rate_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "bucket_base_rate" */
export type Bucket_Base_Rate_Stddev_Pop_Order_By = {
  base?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Bucket_Base_Rate_Stddev_Samp_Fields = {
  __typename?: 'bucket_base_rate_stddev_samp_fields';
  base?: Maybe<Scalars['Float']>;
  bucket_rate_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "bucket_base_rate" */
export type Bucket_Base_Rate_Stddev_Samp_Order_By = {
  base?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Bucket_Base_Rate_Sum_Fields = {
  __typename?: 'bucket_base_rate_sum_fields';
  base?: Maybe<Scalars['numeric']>;
  bucket_rate_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "bucket_base_rate" */
export type Bucket_Base_Rate_Sum_Order_By = {
  base?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "bucket_base_rate" */
export enum Bucket_Base_Rate_Update_Column {
  /** column name */
  Base = 'base',
  /** column name */
  BucketRateId = 'bucket_rate_id',
  /** column name */
  Id = 'id'
}

/** aggregate var_pop on columns */
export type Bucket_Base_Rate_Var_Pop_Fields = {
  __typename?: 'bucket_base_rate_var_pop_fields';
  base?: Maybe<Scalars['Float']>;
  bucket_rate_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "bucket_base_rate" */
export type Bucket_Base_Rate_Var_Pop_Order_By = {
  base?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Bucket_Base_Rate_Var_Samp_Fields = {
  __typename?: 'bucket_base_rate_var_samp_fields';
  base?: Maybe<Scalars['Float']>;
  bucket_rate_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "bucket_base_rate" */
export type Bucket_Base_Rate_Var_Samp_Order_By = {
  base?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Bucket_Base_Rate_Variance_Fields = {
  __typename?: 'bucket_base_rate_variance_fields';
  base?: Maybe<Scalars['Float']>;
  bucket_rate_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "bucket_base_rate" */
export type Bucket_Base_Rate_Variance_Order_By = {
  base?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bucket". All fields are combined with a logical 'AND'. */
export type Bucket_Bool_Exp = {
  _and?: InputMaybe<Array<Bucket_Bool_Exp>>;
  _not?: InputMaybe<Bucket_Bool_Exp>;
  _or?: InputMaybe<Array<Bucket_Bool_Exp>>;
  base_slices?: InputMaybe<Rate_Slice_Base_Slice_Bool_Exp>;
  bucket_base_parameters?: InputMaybe<Bucket_Base_Parameter_Bool_Exp>;
  bucket_maximum?: InputMaybe<Bucket_Maximum_Bool_Exp>;
  bucket_minimum?: InputMaybe<Bucket_Minimum_Bool_Exp>;
  bucket_offset?: InputMaybe<Bucket_Offset_Bool_Exp>;
  bucket_rate_factors?: InputMaybe<Bucket_Rate_Factor_Bool_Exp>;
  bucket_rates?: InputMaybe<Bucket_Rate_Bool_Exp>;
  bucket_rounding?: InputMaybe<Bucket_Rounding_Bool_Exp>;
  cms_bucket_number?: InputMaybe<Smallint_Comparison_Exp>;
  configured_offset?: InputMaybe<Bucket_Offset_Bool_Exp>;
  has_saved_rates?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  payee?: InputMaybe<Payee_Bool_Exp>;
  payee_id?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Product_Bool_Exp>;
  product_id?: InputMaybe<Bigint_Comparison_Exp>;
  reserves?: InputMaybe<Boolean_Comparison_Exp>;
  sort_order?: InputMaybe<Int_Comparison_Exp>;
  uses_base_slices?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "bucket" */
export enum Bucket_Constraint {
  /** unique or primary key constraint */
  BucketPkey = 'bucket_pkey',
  /** unique or primary key constraint */
  UnBucketNameProductId = 'un_bucket_name_product_id'
}

/** input type for incrementing numeric columns in table "bucket" */
export type Bucket_Inc_Input = {
  cms_bucket_number?: InputMaybe<Scalars['smallint']>;
  id?: InputMaybe<Scalars['bigint']>;
  payee_id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['bigint']>;
  sort_order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "bucket" */
export type Bucket_Insert_Input = {
  base_slices?: InputMaybe<Rate_Slice_Base_Slice_Arr_Rel_Insert_Input>;
  bucket_base_parameters?: InputMaybe<Bucket_Base_Parameter_Arr_Rel_Insert_Input>;
  bucket_maximum?: InputMaybe<Bucket_Maximum_Obj_Rel_Insert_Input>;
  bucket_minimum?: InputMaybe<Bucket_Minimum_Obj_Rel_Insert_Input>;
  bucket_offset?: InputMaybe<Bucket_Offset_Obj_Rel_Insert_Input>;
  bucket_rate_factors?: InputMaybe<Bucket_Rate_Factor_Arr_Rel_Insert_Input>;
  bucket_rates?: InputMaybe<Bucket_Rate_Arr_Rel_Insert_Input>;
  bucket_rounding?: InputMaybe<Bucket_Rounding_Obj_Rel_Insert_Input>;
  cms_bucket_number?: InputMaybe<Scalars['smallint']>;
  configured_offset?: InputMaybe<Bucket_Offset_Obj_Rel_Insert_Input>;
  has_saved_rates?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  payee?: InputMaybe<Payee_Obj_Rel_Insert_Input>;
  payee_id?: InputMaybe<Scalars['bigint']>;
  product?: InputMaybe<Product_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars['bigint']>;
  reserves?: InputMaybe<Scalars['Boolean']>;
  sort_order?: InputMaybe<Scalars['Int']>;
  uses_base_slices?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Bucket_Max_Fields = {
  __typename?: 'bucket_max_fields';
  cms_bucket_number?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  payee_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "bucket" */
export type Bucket_Max_Order_By = {
  cms_bucket_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  payee_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** columns and relationships of "bucket_maximum" */
export type Bucket_Maximum = {
  __typename?: 'bucket_maximum';
  bucket_id: Scalars['bigint'];
  id: Scalars['bigint'];
  is_enabled: Scalars['Boolean'];
  max: Scalars['numeric'];
};

/** aggregated selection of "bucket_maximum" */
export type Bucket_Maximum_Aggregate = {
  __typename?: 'bucket_maximum_aggregate';
  aggregate?: Maybe<Bucket_Maximum_Aggregate_Fields>;
  nodes: Array<Bucket_Maximum>;
};

/** aggregate fields of "bucket_maximum" */
export type Bucket_Maximum_Aggregate_Fields = {
  __typename?: 'bucket_maximum_aggregate_fields';
  avg?: Maybe<Bucket_Maximum_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bucket_Maximum_Max_Fields>;
  min?: Maybe<Bucket_Maximum_Min_Fields>;
  stddev?: Maybe<Bucket_Maximum_Stddev_Fields>;
  stddev_pop?: Maybe<Bucket_Maximum_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bucket_Maximum_Stddev_Samp_Fields>;
  sum?: Maybe<Bucket_Maximum_Sum_Fields>;
  var_pop?: Maybe<Bucket_Maximum_Var_Pop_Fields>;
  var_samp?: Maybe<Bucket_Maximum_Var_Samp_Fields>;
  variance?: Maybe<Bucket_Maximum_Variance_Fields>;
};


/** aggregate fields of "bucket_maximum" */
export type Bucket_Maximum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bucket_Maximum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Bucket_Maximum_Avg_Fields = {
  __typename?: 'bucket_maximum_avg_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "bucket_maximum". All fields are combined with a logical 'AND'. */
export type Bucket_Maximum_Bool_Exp = {
  _and?: InputMaybe<Array<Bucket_Maximum_Bool_Exp>>;
  _not?: InputMaybe<Bucket_Maximum_Bool_Exp>;
  _or?: InputMaybe<Array<Bucket_Maximum_Bool_Exp>>;
  bucket_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  max?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "bucket_maximum" */
export enum Bucket_Maximum_Constraint {
  /** unique or primary key constraint */
  BucketMaximumBucketIdKey = 'bucket_maximum_bucket_id_key',
  /** unique or primary key constraint */
  BucketMaximumPkey = 'bucket_maximum_pkey'
}

/** input type for incrementing numeric columns in table "bucket_maximum" */
export type Bucket_Maximum_Inc_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  max?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "bucket_maximum" */
export type Bucket_Maximum_Insert_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Bucket_Maximum_Max_Fields = {
  __typename?: 'bucket_maximum_max_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  max?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Bucket_Maximum_Min_Fields = {
  __typename?: 'bucket_maximum_min_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  max?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "bucket_maximum" */
export type Bucket_Maximum_Mutation_Response = {
  __typename?: 'bucket_maximum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bucket_Maximum>;
};

/** input type for inserting object relation for remote table "bucket_maximum" */
export type Bucket_Maximum_Obj_Rel_Insert_Input = {
  data: Bucket_Maximum_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Bucket_Maximum_On_Conflict>;
};

/** on conflict condition type for table "bucket_maximum" */
export type Bucket_Maximum_On_Conflict = {
  constraint: Bucket_Maximum_Constraint;
  update_columns?: Array<Bucket_Maximum_Update_Column>;
  where?: InputMaybe<Bucket_Maximum_Bool_Exp>;
};

/** Ordering options when selecting data from "bucket_maximum". */
export type Bucket_Maximum_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_enabled?: InputMaybe<Order_By>;
  max?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bucket_maximum */
export type Bucket_Maximum_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "bucket_maximum" */
export enum Bucket_Maximum_Select_Column {
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  Max = 'max'
}

/** input type for updating data in table "bucket_maximum" */
export type Bucket_Maximum_Set_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Bucket_Maximum_Stddev_Fields = {
  __typename?: 'bucket_maximum_stddev_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Bucket_Maximum_Stddev_Pop_Fields = {
  __typename?: 'bucket_maximum_stddev_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Bucket_Maximum_Stddev_Samp_Fields = {
  __typename?: 'bucket_maximum_stddev_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Bucket_Maximum_Sum_Fields = {
  __typename?: 'bucket_maximum_sum_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  max?: Maybe<Scalars['numeric']>;
};

/** update columns of table "bucket_maximum" */
export enum Bucket_Maximum_Update_Column {
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  Max = 'max'
}

/** aggregate var_pop on columns */
export type Bucket_Maximum_Var_Pop_Fields = {
  __typename?: 'bucket_maximum_var_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Bucket_Maximum_Var_Samp_Fields = {
  __typename?: 'bucket_maximum_var_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Bucket_Maximum_Variance_Fields = {
  __typename?: 'bucket_maximum_variance_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

/** aggregate min on columns */
export type Bucket_Min_Fields = {
  __typename?: 'bucket_min_fields';
  cms_bucket_number?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  payee_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "bucket" */
export type Bucket_Min_Order_By = {
  cms_bucket_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  payee_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** columns and relationships of "bucket_minimum" */
export type Bucket_Minimum = {
  __typename?: 'bucket_minimum';
  bucket_id: Scalars['bigint'];
  id: Scalars['bigint'];
  is_enabled: Scalars['Boolean'];
  min: Scalars['numeric'];
};

/** aggregated selection of "bucket_minimum" */
export type Bucket_Minimum_Aggregate = {
  __typename?: 'bucket_minimum_aggregate';
  aggregate?: Maybe<Bucket_Minimum_Aggregate_Fields>;
  nodes: Array<Bucket_Minimum>;
};

/** aggregate fields of "bucket_minimum" */
export type Bucket_Minimum_Aggregate_Fields = {
  __typename?: 'bucket_minimum_aggregate_fields';
  avg?: Maybe<Bucket_Minimum_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bucket_Minimum_Max_Fields>;
  min?: Maybe<Bucket_Minimum_Min_Fields>;
  stddev?: Maybe<Bucket_Minimum_Stddev_Fields>;
  stddev_pop?: Maybe<Bucket_Minimum_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bucket_Minimum_Stddev_Samp_Fields>;
  sum?: Maybe<Bucket_Minimum_Sum_Fields>;
  var_pop?: Maybe<Bucket_Minimum_Var_Pop_Fields>;
  var_samp?: Maybe<Bucket_Minimum_Var_Samp_Fields>;
  variance?: Maybe<Bucket_Minimum_Variance_Fields>;
};


/** aggregate fields of "bucket_minimum" */
export type Bucket_Minimum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bucket_Minimum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Bucket_Minimum_Avg_Fields = {
  __typename?: 'bucket_minimum_avg_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "bucket_minimum". All fields are combined with a logical 'AND'. */
export type Bucket_Minimum_Bool_Exp = {
  _and?: InputMaybe<Array<Bucket_Minimum_Bool_Exp>>;
  _not?: InputMaybe<Bucket_Minimum_Bool_Exp>;
  _or?: InputMaybe<Array<Bucket_Minimum_Bool_Exp>>;
  bucket_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  min?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "bucket_minimum" */
export enum Bucket_Minimum_Constraint {
  /** unique or primary key constraint */
  BucketMinimumBucketIdKey = 'bucket_minimum_bucket_id_key',
  /** unique or primary key constraint */
  BucketMinimumPkey = 'bucket_minimum_pkey'
}

/** input type for incrementing numeric columns in table "bucket_minimum" */
export type Bucket_Minimum_Inc_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  min?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "bucket_minimum" */
export type Bucket_Minimum_Insert_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  min?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Bucket_Minimum_Max_Fields = {
  __typename?: 'bucket_minimum_max_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  min?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Bucket_Minimum_Min_Fields = {
  __typename?: 'bucket_minimum_min_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  min?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "bucket_minimum" */
export type Bucket_Minimum_Mutation_Response = {
  __typename?: 'bucket_minimum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bucket_Minimum>;
};

/** input type for inserting object relation for remote table "bucket_minimum" */
export type Bucket_Minimum_Obj_Rel_Insert_Input = {
  data: Bucket_Minimum_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Bucket_Minimum_On_Conflict>;
};

/** on conflict condition type for table "bucket_minimum" */
export type Bucket_Minimum_On_Conflict = {
  constraint: Bucket_Minimum_Constraint;
  update_columns?: Array<Bucket_Minimum_Update_Column>;
  where?: InputMaybe<Bucket_Minimum_Bool_Exp>;
};

/** Ordering options when selecting data from "bucket_minimum". */
export type Bucket_Minimum_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_enabled?: InputMaybe<Order_By>;
  min?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bucket_minimum */
export type Bucket_Minimum_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "bucket_minimum" */
export enum Bucket_Minimum_Select_Column {
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  Min = 'min'
}

/** input type for updating data in table "bucket_minimum" */
export type Bucket_Minimum_Set_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  min?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Bucket_Minimum_Stddev_Fields = {
  __typename?: 'bucket_minimum_stddev_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Bucket_Minimum_Stddev_Pop_Fields = {
  __typename?: 'bucket_minimum_stddev_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Bucket_Minimum_Stddev_Samp_Fields = {
  __typename?: 'bucket_minimum_stddev_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Bucket_Minimum_Sum_Fields = {
  __typename?: 'bucket_minimum_sum_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  min?: Maybe<Scalars['numeric']>;
};

/** update columns of table "bucket_minimum" */
export enum Bucket_Minimum_Update_Column {
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  Min = 'min'
}

/** aggregate var_pop on columns */
export type Bucket_Minimum_Var_Pop_Fields = {
  __typename?: 'bucket_minimum_var_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Bucket_Minimum_Var_Samp_Fields = {
  __typename?: 'bucket_minimum_var_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Bucket_Minimum_Variance_Fields = {
  __typename?: 'bucket_minimum_variance_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

/** response of any mutation on the table "bucket" */
export type Bucket_Mutation_Response = {
  __typename?: 'bucket_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bucket>;
};

/** input type for inserting object relation for remote table "bucket" */
export type Bucket_Obj_Rel_Insert_Input = {
  data: Bucket_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Bucket_On_Conflict>;
};

/** columns and relationships of "bucket_offset" */
export type Bucket_Offset = {
  __typename?: 'bucket_offset';
  /** An object relationship */
  bucket: Bucket;
  bucket_id: Scalars['bigint'];
  id: Scalars['bigint'];
  /** An object relationship */
  offsetTypeByOffsetType: Offset_Type;
  /** An object relationship */
  offset_bucket: Bucket;
  offset_bucket_id: Scalars['bigint'];
  offset_type: Scalars['String'];
};

/** aggregated selection of "bucket_offset" */
export type Bucket_Offset_Aggregate = {
  __typename?: 'bucket_offset_aggregate';
  aggregate?: Maybe<Bucket_Offset_Aggregate_Fields>;
  nodes: Array<Bucket_Offset>;
};

/** aggregate fields of "bucket_offset" */
export type Bucket_Offset_Aggregate_Fields = {
  __typename?: 'bucket_offset_aggregate_fields';
  avg?: Maybe<Bucket_Offset_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bucket_Offset_Max_Fields>;
  min?: Maybe<Bucket_Offset_Min_Fields>;
  stddev?: Maybe<Bucket_Offset_Stddev_Fields>;
  stddev_pop?: Maybe<Bucket_Offset_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bucket_Offset_Stddev_Samp_Fields>;
  sum?: Maybe<Bucket_Offset_Sum_Fields>;
  var_pop?: Maybe<Bucket_Offset_Var_Pop_Fields>;
  var_samp?: Maybe<Bucket_Offset_Var_Samp_Fields>;
  variance?: Maybe<Bucket_Offset_Variance_Fields>;
};


/** aggregate fields of "bucket_offset" */
export type Bucket_Offset_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bucket_Offset_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Bucket_Offset_Avg_Fields = {
  __typename?: 'bucket_offset_avg_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  offset_bucket_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "bucket_offset". All fields are combined with a logical 'AND'. */
export type Bucket_Offset_Bool_Exp = {
  _and?: InputMaybe<Array<Bucket_Offset_Bool_Exp>>;
  _not?: InputMaybe<Bucket_Offset_Bool_Exp>;
  _or?: InputMaybe<Array<Bucket_Offset_Bool_Exp>>;
  bucket?: InputMaybe<Bucket_Bool_Exp>;
  bucket_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  offsetTypeByOffsetType?: InputMaybe<Offset_Type_Bool_Exp>;
  offset_bucket?: InputMaybe<Bucket_Bool_Exp>;
  offset_bucket_id?: InputMaybe<Bigint_Comparison_Exp>;
  offset_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "bucket_offset" */
export enum Bucket_Offset_Constraint {
  /** unique or primary key constraint */
  BucketOffsetBucketIdKey = 'bucket_offset_bucket_id_key',
  /** unique or primary key constraint */
  BucketOffsetPkey = 'bucket_offset_pkey'
}

/** input type for incrementing numeric columns in table "bucket_offset" */
export type Bucket_Offset_Inc_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  offset_bucket_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "bucket_offset" */
export type Bucket_Offset_Insert_Input = {
  bucket?: InputMaybe<Bucket_Obj_Rel_Insert_Input>;
  bucket_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  offsetTypeByOffsetType?: InputMaybe<Offset_Type_Obj_Rel_Insert_Input>;
  offset_bucket?: InputMaybe<Bucket_Obj_Rel_Insert_Input>;
  offset_bucket_id?: InputMaybe<Scalars['bigint']>;
  offset_type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Bucket_Offset_Max_Fields = {
  __typename?: 'bucket_offset_max_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  offset_bucket_id?: Maybe<Scalars['bigint']>;
  offset_type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Bucket_Offset_Min_Fields = {
  __typename?: 'bucket_offset_min_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  offset_bucket_id?: Maybe<Scalars['bigint']>;
  offset_type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "bucket_offset" */
export type Bucket_Offset_Mutation_Response = {
  __typename?: 'bucket_offset_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bucket_Offset>;
};

/** input type for inserting object relation for remote table "bucket_offset" */
export type Bucket_Offset_Obj_Rel_Insert_Input = {
  data: Bucket_Offset_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Bucket_Offset_On_Conflict>;
};

/** on conflict condition type for table "bucket_offset" */
export type Bucket_Offset_On_Conflict = {
  constraint: Bucket_Offset_Constraint;
  update_columns?: Array<Bucket_Offset_Update_Column>;
  where?: InputMaybe<Bucket_Offset_Bool_Exp>;
};

/** Ordering options when selecting data from "bucket_offset". */
export type Bucket_Offset_Order_By = {
  bucket?: InputMaybe<Bucket_Order_By>;
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  offsetTypeByOffsetType?: InputMaybe<Offset_Type_Order_By>;
  offset_bucket?: InputMaybe<Bucket_Order_By>;
  offset_bucket_id?: InputMaybe<Order_By>;
  offset_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bucket_offset */
export type Bucket_Offset_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "bucket_offset" */
export enum Bucket_Offset_Select_Column {
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  Id = 'id',
  /** column name */
  OffsetBucketId = 'offset_bucket_id',
  /** column name */
  OffsetType = 'offset_type'
}

/** input type for updating data in table "bucket_offset" */
export type Bucket_Offset_Set_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  offset_bucket_id?: InputMaybe<Scalars['bigint']>;
  offset_type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Bucket_Offset_Stddev_Fields = {
  __typename?: 'bucket_offset_stddev_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  offset_bucket_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Bucket_Offset_Stddev_Pop_Fields = {
  __typename?: 'bucket_offset_stddev_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  offset_bucket_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Bucket_Offset_Stddev_Samp_Fields = {
  __typename?: 'bucket_offset_stddev_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  offset_bucket_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Bucket_Offset_Sum_Fields = {
  __typename?: 'bucket_offset_sum_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  offset_bucket_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "bucket_offset" */
export enum Bucket_Offset_Update_Column {
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  Id = 'id',
  /** column name */
  OffsetBucketId = 'offset_bucket_id',
  /** column name */
  OffsetType = 'offset_type'
}

/** aggregate var_pop on columns */
export type Bucket_Offset_Var_Pop_Fields = {
  __typename?: 'bucket_offset_var_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  offset_bucket_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Bucket_Offset_Var_Samp_Fields = {
  __typename?: 'bucket_offset_var_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  offset_bucket_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Bucket_Offset_Variance_Fields = {
  __typename?: 'bucket_offset_variance_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  offset_bucket_id?: Maybe<Scalars['Float']>;
};

/** on conflict condition type for table "bucket" */
export type Bucket_On_Conflict = {
  constraint: Bucket_Constraint;
  update_columns?: Array<Bucket_Update_Column>;
  where?: InputMaybe<Bucket_Bool_Exp>;
};

/** Ordering options when selecting data from "bucket". */
export type Bucket_Order_By = {
  base_slices_aggregate?: InputMaybe<Rate_Slice_Base_Slice_Aggregate_Order_By>;
  bucket_base_parameters_aggregate?: InputMaybe<Bucket_Base_Parameter_Aggregate_Order_By>;
  bucket_maximum?: InputMaybe<Bucket_Maximum_Order_By>;
  bucket_minimum?: InputMaybe<Bucket_Minimum_Order_By>;
  bucket_offset?: InputMaybe<Bucket_Offset_Order_By>;
  bucket_rate_factors_aggregate?: InputMaybe<Bucket_Rate_Factor_Aggregate_Order_By>;
  bucket_rates_aggregate?: InputMaybe<Bucket_Rate_Aggregate_Order_By>;
  bucket_rounding?: InputMaybe<Bucket_Rounding_Order_By>;
  cms_bucket_number?: InputMaybe<Order_By>;
  configured_offset?: InputMaybe<Bucket_Offset_Order_By>;
  has_saved_rates?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  payee?: InputMaybe<Payee_Order_By>;
  payee_id?: InputMaybe<Order_By>;
  product?: InputMaybe<Product_Order_By>;
  product_id?: InputMaybe<Order_By>;
  reserves?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
  uses_base_slices?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bucket */
export type Bucket_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** columns and relationships of "bucket_rate" */
export type Bucket_Rate = {
  __typename?: 'bucket_rate';
  /** An object relationship */
  base_rate?: Maybe<Bucket_Base_Rate>;
  /** An array relationship */
  bucket_base_rates: Array<Bucket_Base_Rate>;
  /** An aggregate relationship */
  bucket_base_rates_aggregate: Bucket_Base_Rate_Aggregate;
  bucket_id: Scalars['bigint'];
  /** An array relationship */
  bucket_rate_offsets: Array<Bucket_Rate_Offset>;
  /** An aggregate relationship */
  bucket_rate_offsets_aggregate: Bucket_Rate_Offset_Aggregate;
  id: Scalars['bigint'];
  is_non_sellable: Scalars['Boolean'];
  /** An object relationship */
  product_parameter_key_combination?: Maybe<Product_Parameter_Key_Combination>;
  product_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  rate_offset?: Maybe<Bucket_Rate_Offset>;
  total: Scalars['numeric'];
};


/** columns and relationships of "bucket_rate" */
export type Bucket_RateBucket_Base_RatesArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Base_Rate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Base_Rate_Order_By>>;
  where?: InputMaybe<Bucket_Base_Rate_Bool_Exp>;
};


/** columns and relationships of "bucket_rate" */
export type Bucket_RateBucket_Base_Rates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Base_Rate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Base_Rate_Order_By>>;
  where?: InputMaybe<Bucket_Base_Rate_Bool_Exp>;
};


/** columns and relationships of "bucket_rate" */
export type Bucket_RateBucket_Rate_OffsetsArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rate_Offset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Offset_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Offset_Bool_Exp>;
};


/** columns and relationships of "bucket_rate" */
export type Bucket_RateBucket_Rate_Offsets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Rate_Offset_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Rate_Offset_Order_By>>;
  where?: InputMaybe<Bucket_Rate_Offset_Bool_Exp>;
};

/** aggregated selection of "bucket_rate" */
export type Bucket_Rate_Aggregate = {
  __typename?: 'bucket_rate_aggregate';
  aggregate?: Maybe<Bucket_Rate_Aggregate_Fields>;
  nodes: Array<Bucket_Rate>;
};

/** aggregate fields of "bucket_rate" */
export type Bucket_Rate_Aggregate_Fields = {
  __typename?: 'bucket_rate_aggregate_fields';
  avg?: Maybe<Bucket_Rate_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bucket_Rate_Max_Fields>;
  min?: Maybe<Bucket_Rate_Min_Fields>;
  stddev?: Maybe<Bucket_Rate_Stddev_Fields>;
  stddev_pop?: Maybe<Bucket_Rate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bucket_Rate_Stddev_Samp_Fields>;
  sum?: Maybe<Bucket_Rate_Sum_Fields>;
  var_pop?: Maybe<Bucket_Rate_Var_Pop_Fields>;
  var_samp?: Maybe<Bucket_Rate_Var_Samp_Fields>;
  variance?: Maybe<Bucket_Rate_Variance_Fields>;
};


/** aggregate fields of "bucket_rate" */
export type Bucket_Rate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bucket_Rate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bucket_rate" */
export type Bucket_Rate_Aggregate_Order_By = {
  avg?: InputMaybe<Bucket_Rate_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Bucket_Rate_Max_Order_By>;
  min?: InputMaybe<Bucket_Rate_Min_Order_By>;
  stddev?: InputMaybe<Bucket_Rate_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Bucket_Rate_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Bucket_Rate_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Bucket_Rate_Sum_Order_By>;
  var_pop?: InputMaybe<Bucket_Rate_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Bucket_Rate_Var_Samp_Order_By>;
  variance?: InputMaybe<Bucket_Rate_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "bucket_rate" */
export type Bucket_Rate_Arr_Rel_Insert_Input = {
  data: Array<Bucket_Rate_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Bucket_Rate_On_Conflict>;
};

/** aggregate avg on columns */
export type Bucket_Rate_Avg_Fields = {
  __typename?: 'bucket_rate_avg_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "bucket_rate" */
export type Bucket_Rate_Avg_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bucket_rate". All fields are combined with a logical 'AND'. */
export type Bucket_Rate_Bool_Exp = {
  _and?: InputMaybe<Array<Bucket_Rate_Bool_Exp>>;
  _not?: InputMaybe<Bucket_Rate_Bool_Exp>;
  _or?: InputMaybe<Array<Bucket_Rate_Bool_Exp>>;
  base_rate?: InputMaybe<Bucket_Base_Rate_Bool_Exp>;
  bucket_base_rates?: InputMaybe<Bucket_Base_Rate_Bool_Exp>;
  bucket_id?: InputMaybe<Bigint_Comparison_Exp>;
  bucket_rate_offsets?: InputMaybe<Bucket_Rate_Offset_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_non_sellable?: InputMaybe<Boolean_Comparison_Exp>;
  product_parameter_key_combination?: InputMaybe<Product_Parameter_Key_Combination_Bool_Exp>;
  product_parameter_key_combination_id?: InputMaybe<Bigint_Comparison_Exp>;
  rate_offset?: InputMaybe<Bucket_Rate_Offset_Bool_Exp>;
  total?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "bucket_rate" */
export enum Bucket_Rate_Constraint {
  /** unique or primary key constraint */
  BucketRateBucketIdProductParameterKeyCombinationIdKey = 'bucket_rate_bucket_id_product_parameter_key_combination_id_key',
  /** unique or primary key constraint */
  BucketRatePkey = 'bucket_rate_pkey'
}

/** columns and relationships of "bucket_rate_exception" */
export type Bucket_Rate_Exception = {
  __typename?: 'bucket_rate_exception';
  bucket_id: Scalars['bigint'];
  id: Scalars['bigint'];
  product_parameter_key_combination_id: Scalars['bigint'];
  value: Scalars['numeric'];
};

/** aggregated selection of "bucket_rate_exception" */
export type Bucket_Rate_Exception_Aggregate = {
  __typename?: 'bucket_rate_exception_aggregate';
  aggregate?: Maybe<Bucket_Rate_Exception_Aggregate_Fields>;
  nodes: Array<Bucket_Rate_Exception>;
};

/** aggregate fields of "bucket_rate_exception" */
export type Bucket_Rate_Exception_Aggregate_Fields = {
  __typename?: 'bucket_rate_exception_aggregate_fields';
  avg?: Maybe<Bucket_Rate_Exception_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bucket_Rate_Exception_Max_Fields>;
  min?: Maybe<Bucket_Rate_Exception_Min_Fields>;
  stddev?: Maybe<Bucket_Rate_Exception_Stddev_Fields>;
  stddev_pop?: Maybe<Bucket_Rate_Exception_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bucket_Rate_Exception_Stddev_Samp_Fields>;
  sum?: Maybe<Bucket_Rate_Exception_Sum_Fields>;
  var_pop?: Maybe<Bucket_Rate_Exception_Var_Pop_Fields>;
  var_samp?: Maybe<Bucket_Rate_Exception_Var_Samp_Fields>;
  variance?: Maybe<Bucket_Rate_Exception_Variance_Fields>;
};


/** aggregate fields of "bucket_rate_exception" */
export type Bucket_Rate_Exception_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bucket_Rate_Exception_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Bucket_Rate_Exception_Avg_Fields = {
  __typename?: 'bucket_rate_exception_avg_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "bucket_rate_exception". All fields are combined with a logical 'AND'. */
export type Bucket_Rate_Exception_Bool_Exp = {
  _and?: InputMaybe<Array<Bucket_Rate_Exception_Bool_Exp>>;
  _not?: InputMaybe<Bucket_Rate_Exception_Bool_Exp>;
  _or?: InputMaybe<Array<Bucket_Rate_Exception_Bool_Exp>>;
  bucket_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  product_parameter_key_combination_id?: InputMaybe<Bigint_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "bucket_rate_exception" */
export enum Bucket_Rate_Exception_Constraint {
  /** unique or primary key constraint */
  BucketRateExceptionPkey = 'bucket_rate_exception_pkey',
  /** unique or primary key constraint */
  BucketRateExceptionProductParameterKeyCombinationIdKey = 'bucket_rate_exception_product_parameter_key_combination_id__key'
}

/** input type for incrementing numeric columns in table "bucket_rate_exception" */
export type Bucket_Rate_Exception_Inc_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: InputMaybe<Scalars['bigint']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "bucket_rate_exception" */
export type Bucket_Rate_Exception_Insert_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: InputMaybe<Scalars['bigint']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Bucket_Rate_Exception_Max_Fields = {
  __typename?: 'bucket_rate_exception_max_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Bucket_Rate_Exception_Min_Fields = {
  __typename?: 'bucket_rate_exception_min_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "bucket_rate_exception" */
export type Bucket_Rate_Exception_Mutation_Response = {
  __typename?: 'bucket_rate_exception_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bucket_Rate_Exception>;
};

/** on conflict condition type for table "bucket_rate_exception" */
export type Bucket_Rate_Exception_On_Conflict = {
  constraint: Bucket_Rate_Exception_Constraint;
  update_columns?: Array<Bucket_Rate_Exception_Update_Column>;
  where?: InputMaybe<Bucket_Rate_Exception_Bool_Exp>;
};

/** Ordering options when selecting data from "bucket_rate_exception". */
export type Bucket_Rate_Exception_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bucket_rate_exception */
export type Bucket_Rate_Exception_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "bucket_rate_exception" */
export enum Bucket_Rate_Exception_Select_Column {
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProductParameterKeyCombinationId = 'product_parameter_key_combination_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "bucket_rate_exception" */
export type Bucket_Rate_Exception_Set_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: InputMaybe<Scalars['bigint']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Bucket_Rate_Exception_Stddev_Fields = {
  __typename?: 'bucket_rate_exception_stddev_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Bucket_Rate_Exception_Stddev_Pop_Fields = {
  __typename?: 'bucket_rate_exception_stddev_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Bucket_Rate_Exception_Stddev_Samp_Fields = {
  __typename?: 'bucket_rate_exception_stddev_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Bucket_Rate_Exception_Sum_Fields = {
  __typename?: 'bucket_rate_exception_sum_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['numeric']>;
};

/** update columns of table "bucket_rate_exception" */
export enum Bucket_Rate_Exception_Update_Column {
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProductParameterKeyCombinationId = 'product_parameter_key_combination_id',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type Bucket_Rate_Exception_Var_Pop_Fields = {
  __typename?: 'bucket_rate_exception_var_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Bucket_Rate_Exception_Var_Samp_Fields = {
  __typename?: 'bucket_rate_exception_var_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Bucket_Rate_Exception_Variance_Fields = {
  __typename?: 'bucket_rate_exception_variance_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "bucket_rate_factor" */
export type Bucket_Rate_Factor = {
  __typename?: 'bucket_rate_factor';
  bucket_id: Scalars['bigint'];
  group_id?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  operand: Scalars['numeric'];
  operator: Scalars['String'];
  parameter_key_ids: Scalars['_int8'];
  sort_order: Scalars['Int'];
};

/** aggregated selection of "bucket_rate_factor" */
export type Bucket_Rate_Factor_Aggregate = {
  __typename?: 'bucket_rate_factor_aggregate';
  aggregate?: Maybe<Bucket_Rate_Factor_Aggregate_Fields>;
  nodes: Array<Bucket_Rate_Factor>;
};

/** aggregate fields of "bucket_rate_factor" */
export type Bucket_Rate_Factor_Aggregate_Fields = {
  __typename?: 'bucket_rate_factor_aggregate_fields';
  avg?: Maybe<Bucket_Rate_Factor_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bucket_Rate_Factor_Max_Fields>;
  min?: Maybe<Bucket_Rate_Factor_Min_Fields>;
  stddev?: Maybe<Bucket_Rate_Factor_Stddev_Fields>;
  stddev_pop?: Maybe<Bucket_Rate_Factor_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bucket_Rate_Factor_Stddev_Samp_Fields>;
  sum?: Maybe<Bucket_Rate_Factor_Sum_Fields>;
  var_pop?: Maybe<Bucket_Rate_Factor_Var_Pop_Fields>;
  var_samp?: Maybe<Bucket_Rate_Factor_Var_Samp_Fields>;
  variance?: Maybe<Bucket_Rate_Factor_Variance_Fields>;
};


/** aggregate fields of "bucket_rate_factor" */
export type Bucket_Rate_Factor_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bucket_Rate_Factor_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bucket_rate_factor" */
export type Bucket_Rate_Factor_Aggregate_Order_By = {
  avg?: InputMaybe<Bucket_Rate_Factor_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Bucket_Rate_Factor_Max_Order_By>;
  min?: InputMaybe<Bucket_Rate_Factor_Min_Order_By>;
  stddev?: InputMaybe<Bucket_Rate_Factor_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Bucket_Rate_Factor_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Bucket_Rate_Factor_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Bucket_Rate_Factor_Sum_Order_By>;
  var_pop?: InputMaybe<Bucket_Rate_Factor_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Bucket_Rate_Factor_Var_Samp_Order_By>;
  variance?: InputMaybe<Bucket_Rate_Factor_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "bucket_rate_factor" */
export type Bucket_Rate_Factor_Arr_Rel_Insert_Input = {
  data: Array<Bucket_Rate_Factor_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Bucket_Rate_Factor_On_Conflict>;
};

/** aggregate avg on columns */
export type Bucket_Rate_Factor_Avg_Fields = {
  __typename?: 'bucket_rate_factor_avg_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  operand?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "bucket_rate_factor" */
export type Bucket_Rate_Factor_Avg_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operand?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bucket_rate_factor". All fields are combined with a logical 'AND'. */
export type Bucket_Rate_Factor_Bool_Exp = {
  _and?: InputMaybe<Array<Bucket_Rate_Factor_Bool_Exp>>;
  _not?: InputMaybe<Bucket_Rate_Factor_Bool_Exp>;
  _or?: InputMaybe<Array<Bucket_Rate_Factor_Bool_Exp>>;
  bucket_id?: InputMaybe<Bigint_Comparison_Exp>;
  group_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  operand?: InputMaybe<Numeric_Comparison_Exp>;
  operator?: InputMaybe<String_Comparison_Exp>;
  parameter_key_ids?: InputMaybe<_Int8_Comparison_Exp>;
  sort_order?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "bucket_rate_factor" */
export enum Bucket_Rate_Factor_Constraint {
  /** unique or primary key constraint */
  BucketRateFactorPkey = 'bucket_rate_factor_pkey'
}

/** input type for incrementing numeric columns in table "bucket_rate_factor" */
export type Bucket_Rate_Factor_Inc_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  group_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  operand?: InputMaybe<Scalars['numeric']>;
  sort_order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "bucket_rate_factor" */
export type Bucket_Rate_Factor_Insert_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  group_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  operand?: InputMaybe<Scalars['numeric']>;
  operator?: InputMaybe<Scalars['String']>;
  parameter_key_ids?: InputMaybe<Scalars['_int8']>;
  sort_order?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Bucket_Rate_Factor_Max_Fields = {
  __typename?: 'bucket_rate_factor_max_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  group_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  operand?: Maybe<Scalars['numeric']>;
  operator?: Maybe<Scalars['String']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "bucket_rate_factor" */
export type Bucket_Rate_Factor_Max_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operand?: InputMaybe<Order_By>;
  operator?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Bucket_Rate_Factor_Min_Fields = {
  __typename?: 'bucket_rate_factor_min_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  group_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  operand?: Maybe<Scalars['numeric']>;
  operator?: Maybe<Scalars['String']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "bucket_rate_factor" */
export type Bucket_Rate_Factor_Min_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operand?: InputMaybe<Order_By>;
  operator?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "bucket_rate_factor" */
export type Bucket_Rate_Factor_Mutation_Response = {
  __typename?: 'bucket_rate_factor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bucket_Rate_Factor>;
};

/** on conflict condition type for table "bucket_rate_factor" */
export type Bucket_Rate_Factor_On_Conflict = {
  constraint: Bucket_Rate_Factor_Constraint;
  update_columns?: Array<Bucket_Rate_Factor_Update_Column>;
  where?: InputMaybe<Bucket_Rate_Factor_Bool_Exp>;
};

/** Ordering options when selecting data from "bucket_rate_factor". */
export type Bucket_Rate_Factor_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operand?: InputMaybe<Order_By>;
  operator?: InputMaybe<Order_By>;
  parameter_key_ids?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bucket_rate_factor */
export type Bucket_Rate_Factor_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "bucket_rate_factor" */
export enum Bucket_Rate_Factor_Select_Column {
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Operand = 'operand',
  /** column name */
  Operator = 'operator',
  /** column name */
  ParameterKeyIds = 'parameter_key_ids',
  /** column name */
  SortOrder = 'sort_order'
}

/** input type for updating data in table "bucket_rate_factor" */
export type Bucket_Rate_Factor_Set_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  group_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  operand?: InputMaybe<Scalars['numeric']>;
  operator?: InputMaybe<Scalars['String']>;
  parameter_key_ids?: InputMaybe<Scalars['_int8']>;
  sort_order?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Bucket_Rate_Factor_Stddev_Fields = {
  __typename?: 'bucket_rate_factor_stddev_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  operand?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "bucket_rate_factor" */
export type Bucket_Rate_Factor_Stddev_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operand?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Bucket_Rate_Factor_Stddev_Pop_Fields = {
  __typename?: 'bucket_rate_factor_stddev_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  operand?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "bucket_rate_factor" */
export type Bucket_Rate_Factor_Stddev_Pop_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operand?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Bucket_Rate_Factor_Stddev_Samp_Fields = {
  __typename?: 'bucket_rate_factor_stddev_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  operand?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "bucket_rate_factor" */
export type Bucket_Rate_Factor_Stddev_Samp_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operand?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Bucket_Rate_Factor_Sum_Fields = {
  __typename?: 'bucket_rate_factor_sum_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  group_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  operand?: Maybe<Scalars['numeric']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "bucket_rate_factor" */
export type Bucket_Rate_Factor_Sum_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operand?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** update columns of table "bucket_rate_factor" */
export enum Bucket_Rate_Factor_Update_Column {
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Operand = 'operand',
  /** column name */
  Operator = 'operator',
  /** column name */
  ParameterKeyIds = 'parameter_key_ids',
  /** column name */
  SortOrder = 'sort_order'
}

/** aggregate var_pop on columns */
export type Bucket_Rate_Factor_Var_Pop_Fields = {
  __typename?: 'bucket_rate_factor_var_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  operand?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "bucket_rate_factor" */
export type Bucket_Rate_Factor_Var_Pop_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operand?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Bucket_Rate_Factor_Var_Samp_Fields = {
  __typename?: 'bucket_rate_factor_var_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  operand?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "bucket_rate_factor" */
export type Bucket_Rate_Factor_Var_Samp_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operand?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Bucket_Rate_Factor_Variance_Fields = {
  __typename?: 'bucket_rate_factor_variance_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  operand?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "bucket_rate_factor" */
export type Bucket_Rate_Factor_Variance_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operand?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "bucket_rate" */
export type Bucket_Rate_Inc_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: InputMaybe<Scalars['bigint']>;
  total?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "bucket_rate" */
export type Bucket_Rate_Insert_Input = {
  base_rate?: InputMaybe<Bucket_Base_Rate_Obj_Rel_Insert_Input>;
  bucket_base_rates?: InputMaybe<Bucket_Base_Rate_Arr_Rel_Insert_Input>;
  bucket_id?: InputMaybe<Scalars['bigint']>;
  bucket_rate_offsets?: InputMaybe<Bucket_Rate_Offset_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  is_non_sellable?: InputMaybe<Scalars['Boolean']>;
  product_parameter_key_combination?: InputMaybe<Product_Parameter_Key_Combination_Obj_Rel_Insert_Input>;
  product_parameter_key_combination_id?: InputMaybe<Scalars['bigint']>;
  rate_offset?: InputMaybe<Bucket_Rate_Offset_Obj_Rel_Insert_Input>;
  total?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Bucket_Rate_Max_Fields = {
  __typename?: 'bucket_rate_max_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
  total?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "bucket_rate" */
export type Bucket_Rate_Max_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Bucket_Rate_Min_Fields = {
  __typename?: 'bucket_rate_min_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
  total?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "bucket_rate" */
export type Bucket_Rate_Min_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "bucket_rate" */
export type Bucket_Rate_Mutation_Response = {
  __typename?: 'bucket_rate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bucket_Rate>;
};

/** columns and relationships of "bucket_rate_offset" */
export type Bucket_Rate_Offset = {
  __typename?: 'bucket_rate_offset';
  /** An object relationship */
  bucket_offset: Bucket_Offset;
  bucket_offset_id: Scalars['bigint'];
  bucket_rate_id: Scalars['bigint'];
  id: Scalars['bigint'];
  offset: Scalars['numeric'];
};

/** aggregated selection of "bucket_rate_offset" */
export type Bucket_Rate_Offset_Aggregate = {
  __typename?: 'bucket_rate_offset_aggregate';
  aggregate?: Maybe<Bucket_Rate_Offset_Aggregate_Fields>;
  nodes: Array<Bucket_Rate_Offset>;
};

/** aggregate fields of "bucket_rate_offset" */
export type Bucket_Rate_Offset_Aggregate_Fields = {
  __typename?: 'bucket_rate_offset_aggregate_fields';
  avg?: Maybe<Bucket_Rate_Offset_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bucket_Rate_Offset_Max_Fields>;
  min?: Maybe<Bucket_Rate_Offset_Min_Fields>;
  stddev?: Maybe<Bucket_Rate_Offset_Stddev_Fields>;
  stddev_pop?: Maybe<Bucket_Rate_Offset_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bucket_Rate_Offset_Stddev_Samp_Fields>;
  sum?: Maybe<Bucket_Rate_Offset_Sum_Fields>;
  var_pop?: Maybe<Bucket_Rate_Offset_Var_Pop_Fields>;
  var_samp?: Maybe<Bucket_Rate_Offset_Var_Samp_Fields>;
  variance?: Maybe<Bucket_Rate_Offset_Variance_Fields>;
};


/** aggregate fields of "bucket_rate_offset" */
export type Bucket_Rate_Offset_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bucket_Rate_Offset_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bucket_rate_offset" */
export type Bucket_Rate_Offset_Aggregate_Order_By = {
  avg?: InputMaybe<Bucket_Rate_Offset_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Bucket_Rate_Offset_Max_Order_By>;
  min?: InputMaybe<Bucket_Rate_Offset_Min_Order_By>;
  stddev?: InputMaybe<Bucket_Rate_Offset_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Bucket_Rate_Offset_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Bucket_Rate_Offset_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Bucket_Rate_Offset_Sum_Order_By>;
  var_pop?: InputMaybe<Bucket_Rate_Offset_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Bucket_Rate_Offset_Var_Samp_Order_By>;
  variance?: InputMaybe<Bucket_Rate_Offset_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "bucket_rate_offset" */
export type Bucket_Rate_Offset_Arr_Rel_Insert_Input = {
  data: Array<Bucket_Rate_Offset_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Bucket_Rate_Offset_On_Conflict>;
};

/** aggregate avg on columns */
export type Bucket_Rate_Offset_Avg_Fields = {
  __typename?: 'bucket_rate_offset_avg_fields';
  bucket_offset_id?: Maybe<Scalars['Float']>;
  bucket_rate_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "bucket_rate_offset" */
export type Bucket_Rate_Offset_Avg_Order_By = {
  bucket_offset_id?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  offset?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bucket_rate_offset". All fields are combined with a logical 'AND'. */
export type Bucket_Rate_Offset_Bool_Exp = {
  _and?: InputMaybe<Array<Bucket_Rate_Offset_Bool_Exp>>;
  _not?: InputMaybe<Bucket_Rate_Offset_Bool_Exp>;
  _or?: InputMaybe<Array<Bucket_Rate_Offset_Bool_Exp>>;
  bucket_offset?: InputMaybe<Bucket_Offset_Bool_Exp>;
  bucket_offset_id?: InputMaybe<Bigint_Comparison_Exp>;
  bucket_rate_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  offset?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "bucket_rate_offset" */
export enum Bucket_Rate_Offset_Constraint {
  /** unique or primary key constraint */
  BucketRateOffsetBucketRateIdKey = 'bucket_rate_offset_bucket_rate_id_key',
  /** unique or primary key constraint */
  BucketRateOffsetPkey = 'bucket_rate_offset_pkey'
}

/** input type for incrementing numeric columns in table "bucket_rate_offset" */
export type Bucket_Rate_Offset_Inc_Input = {
  bucket_offset_id?: InputMaybe<Scalars['bigint']>;
  bucket_rate_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  offset?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "bucket_rate_offset" */
export type Bucket_Rate_Offset_Insert_Input = {
  bucket_offset?: InputMaybe<Bucket_Offset_Obj_Rel_Insert_Input>;
  bucket_offset_id?: InputMaybe<Scalars['bigint']>;
  bucket_rate_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  offset?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Bucket_Rate_Offset_Max_Fields = {
  __typename?: 'bucket_rate_offset_max_fields';
  bucket_offset_id?: Maybe<Scalars['bigint']>;
  bucket_rate_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  offset?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "bucket_rate_offset" */
export type Bucket_Rate_Offset_Max_Order_By = {
  bucket_offset_id?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  offset?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Bucket_Rate_Offset_Min_Fields = {
  __typename?: 'bucket_rate_offset_min_fields';
  bucket_offset_id?: Maybe<Scalars['bigint']>;
  bucket_rate_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  offset?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "bucket_rate_offset" */
export type Bucket_Rate_Offset_Min_Order_By = {
  bucket_offset_id?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  offset?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "bucket_rate_offset" */
export type Bucket_Rate_Offset_Mutation_Response = {
  __typename?: 'bucket_rate_offset_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bucket_Rate_Offset>;
};

/** input type for inserting object relation for remote table "bucket_rate_offset" */
export type Bucket_Rate_Offset_Obj_Rel_Insert_Input = {
  data: Bucket_Rate_Offset_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Bucket_Rate_Offset_On_Conflict>;
};

/** on conflict condition type for table "bucket_rate_offset" */
export type Bucket_Rate_Offset_On_Conflict = {
  constraint: Bucket_Rate_Offset_Constraint;
  update_columns?: Array<Bucket_Rate_Offset_Update_Column>;
  where?: InputMaybe<Bucket_Rate_Offset_Bool_Exp>;
};

/** Ordering options when selecting data from "bucket_rate_offset". */
export type Bucket_Rate_Offset_Order_By = {
  bucket_offset?: InputMaybe<Bucket_Offset_Order_By>;
  bucket_offset_id?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  offset?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bucket_rate_offset */
export type Bucket_Rate_Offset_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "bucket_rate_offset" */
export enum Bucket_Rate_Offset_Select_Column {
  /** column name */
  BucketOffsetId = 'bucket_offset_id',
  /** column name */
  BucketRateId = 'bucket_rate_id',
  /** column name */
  Id = 'id',
  /** column name */
  Offset = 'offset'
}

/** input type for updating data in table "bucket_rate_offset" */
export type Bucket_Rate_Offset_Set_Input = {
  bucket_offset_id?: InputMaybe<Scalars['bigint']>;
  bucket_rate_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  offset?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Bucket_Rate_Offset_Stddev_Fields = {
  __typename?: 'bucket_rate_offset_stddev_fields';
  bucket_offset_id?: Maybe<Scalars['Float']>;
  bucket_rate_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "bucket_rate_offset" */
export type Bucket_Rate_Offset_Stddev_Order_By = {
  bucket_offset_id?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  offset?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Bucket_Rate_Offset_Stddev_Pop_Fields = {
  __typename?: 'bucket_rate_offset_stddev_pop_fields';
  bucket_offset_id?: Maybe<Scalars['Float']>;
  bucket_rate_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "bucket_rate_offset" */
export type Bucket_Rate_Offset_Stddev_Pop_Order_By = {
  bucket_offset_id?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  offset?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Bucket_Rate_Offset_Stddev_Samp_Fields = {
  __typename?: 'bucket_rate_offset_stddev_samp_fields';
  bucket_offset_id?: Maybe<Scalars['Float']>;
  bucket_rate_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "bucket_rate_offset" */
export type Bucket_Rate_Offset_Stddev_Samp_Order_By = {
  bucket_offset_id?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  offset?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Bucket_Rate_Offset_Sum_Fields = {
  __typename?: 'bucket_rate_offset_sum_fields';
  bucket_offset_id?: Maybe<Scalars['bigint']>;
  bucket_rate_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  offset?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "bucket_rate_offset" */
export type Bucket_Rate_Offset_Sum_Order_By = {
  bucket_offset_id?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  offset?: InputMaybe<Order_By>;
};

/** update columns of table "bucket_rate_offset" */
export enum Bucket_Rate_Offset_Update_Column {
  /** column name */
  BucketOffsetId = 'bucket_offset_id',
  /** column name */
  BucketRateId = 'bucket_rate_id',
  /** column name */
  Id = 'id',
  /** column name */
  Offset = 'offset'
}

/** aggregate var_pop on columns */
export type Bucket_Rate_Offset_Var_Pop_Fields = {
  __typename?: 'bucket_rate_offset_var_pop_fields';
  bucket_offset_id?: Maybe<Scalars['Float']>;
  bucket_rate_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "bucket_rate_offset" */
export type Bucket_Rate_Offset_Var_Pop_Order_By = {
  bucket_offset_id?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  offset?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Bucket_Rate_Offset_Var_Samp_Fields = {
  __typename?: 'bucket_rate_offset_var_samp_fields';
  bucket_offset_id?: Maybe<Scalars['Float']>;
  bucket_rate_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "bucket_rate_offset" */
export type Bucket_Rate_Offset_Var_Samp_Order_By = {
  bucket_offset_id?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  offset?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Bucket_Rate_Offset_Variance_Fields = {
  __typename?: 'bucket_rate_offset_variance_fields';
  bucket_offset_id?: Maybe<Scalars['Float']>;
  bucket_rate_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "bucket_rate_offset" */
export type Bucket_Rate_Offset_Variance_Order_By = {
  bucket_offset_id?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  offset?: InputMaybe<Order_By>;
};

/** on conflict condition type for table "bucket_rate" */
export type Bucket_Rate_On_Conflict = {
  constraint: Bucket_Rate_Constraint;
  update_columns?: Array<Bucket_Rate_Update_Column>;
  where?: InputMaybe<Bucket_Rate_Bool_Exp>;
};

/** Ordering options when selecting data from "bucket_rate". */
export type Bucket_Rate_Order_By = {
  base_rate?: InputMaybe<Bucket_Base_Rate_Order_By>;
  bucket_base_rates_aggregate?: InputMaybe<Bucket_Base_Rate_Aggregate_Order_By>;
  bucket_id?: InputMaybe<Order_By>;
  bucket_rate_offsets_aggregate?: InputMaybe<Bucket_Rate_Offset_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_non_sellable?: InputMaybe<Order_By>;
  product_parameter_key_combination?: InputMaybe<Product_Parameter_Key_Combination_Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
  rate_offset?: InputMaybe<Bucket_Rate_Offset_Order_By>;
  total?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bucket_rate */
export type Bucket_Rate_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "bucket_rate" */
export enum Bucket_Rate_Select_Column {
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsNonSellable = 'is_non_sellable',
  /** column name */
  ProductParameterKeyCombinationId = 'product_parameter_key_combination_id',
  /** column name */
  Total = 'total'
}

/** input type for updating data in table "bucket_rate" */
export type Bucket_Rate_Set_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_non_sellable?: InputMaybe<Scalars['Boolean']>;
  product_parameter_key_combination_id?: InputMaybe<Scalars['bigint']>;
  total?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Bucket_Rate_Stddev_Fields = {
  __typename?: 'bucket_rate_stddev_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "bucket_rate" */
export type Bucket_Rate_Stddev_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Bucket_Rate_Stddev_Pop_Fields = {
  __typename?: 'bucket_rate_stddev_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "bucket_rate" */
export type Bucket_Rate_Stddev_Pop_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Bucket_Rate_Stddev_Samp_Fields = {
  __typename?: 'bucket_rate_stddev_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "bucket_rate" */
export type Bucket_Rate_Stddev_Samp_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Bucket_Rate_Sum_Fields = {
  __typename?: 'bucket_rate_sum_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
  total?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "bucket_rate" */
export type Bucket_Rate_Sum_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** update columns of table "bucket_rate" */
export enum Bucket_Rate_Update_Column {
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsNonSellable = 'is_non_sellable',
  /** column name */
  ProductParameterKeyCombinationId = 'product_parameter_key_combination_id',
  /** column name */
  Total = 'total'
}

/** aggregate var_pop on columns */
export type Bucket_Rate_Var_Pop_Fields = {
  __typename?: 'bucket_rate_var_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "bucket_rate" */
export type Bucket_Rate_Var_Pop_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Bucket_Rate_Var_Samp_Fields = {
  __typename?: 'bucket_rate_var_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "bucket_rate" */
export type Bucket_Rate_Var_Samp_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Bucket_Rate_Variance_Fields = {
  __typename?: 'bucket_rate_variance_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "bucket_rate" */
export type Bucket_Rate_Variance_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** columns and relationships of "bucket_rounding" */
export type Bucket_Rounding = {
  __typename?: 'bucket_rounding';
  bucket_id: Scalars['bigint'];
  id: Scalars['bigint'];
  /** An object relationship */
  roundTypeByRoundType: Round_Type;
  round_by: Scalars['numeric'];
  round_type: Scalars['String'];
};

/** aggregated selection of "bucket_rounding" */
export type Bucket_Rounding_Aggregate = {
  __typename?: 'bucket_rounding_aggregate';
  aggregate?: Maybe<Bucket_Rounding_Aggregate_Fields>;
  nodes: Array<Bucket_Rounding>;
};

/** aggregate fields of "bucket_rounding" */
export type Bucket_Rounding_Aggregate_Fields = {
  __typename?: 'bucket_rounding_aggregate_fields';
  avg?: Maybe<Bucket_Rounding_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bucket_Rounding_Max_Fields>;
  min?: Maybe<Bucket_Rounding_Min_Fields>;
  stddev?: Maybe<Bucket_Rounding_Stddev_Fields>;
  stddev_pop?: Maybe<Bucket_Rounding_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bucket_Rounding_Stddev_Samp_Fields>;
  sum?: Maybe<Bucket_Rounding_Sum_Fields>;
  var_pop?: Maybe<Bucket_Rounding_Var_Pop_Fields>;
  var_samp?: Maybe<Bucket_Rounding_Var_Samp_Fields>;
  variance?: Maybe<Bucket_Rounding_Variance_Fields>;
};


/** aggregate fields of "bucket_rounding" */
export type Bucket_Rounding_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bucket_Rounding_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Bucket_Rounding_Avg_Fields = {
  __typename?: 'bucket_rounding_avg_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  round_by?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "bucket_rounding". All fields are combined with a logical 'AND'. */
export type Bucket_Rounding_Bool_Exp = {
  _and?: InputMaybe<Array<Bucket_Rounding_Bool_Exp>>;
  _not?: InputMaybe<Bucket_Rounding_Bool_Exp>;
  _or?: InputMaybe<Array<Bucket_Rounding_Bool_Exp>>;
  bucket_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  roundTypeByRoundType?: InputMaybe<Round_Type_Bool_Exp>;
  round_by?: InputMaybe<Numeric_Comparison_Exp>;
  round_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "bucket_rounding" */
export enum Bucket_Rounding_Constraint {
  /** unique or primary key constraint */
  BucketRoundingBucketIdKey = 'bucket_rounding_bucket_id_key',
  /** unique or primary key constraint */
  BucketRoundingPkey = 'bucket_rounding_pkey'
}

/** input type for incrementing numeric columns in table "bucket_rounding" */
export type Bucket_Rounding_Inc_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  round_by?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "bucket_rounding" */
export type Bucket_Rounding_Insert_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  roundTypeByRoundType?: InputMaybe<Round_Type_Obj_Rel_Insert_Input>;
  round_by?: InputMaybe<Scalars['numeric']>;
  round_type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Bucket_Rounding_Max_Fields = {
  __typename?: 'bucket_rounding_max_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  round_by?: Maybe<Scalars['numeric']>;
  round_type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Bucket_Rounding_Min_Fields = {
  __typename?: 'bucket_rounding_min_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  round_by?: Maybe<Scalars['numeric']>;
  round_type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "bucket_rounding" */
export type Bucket_Rounding_Mutation_Response = {
  __typename?: 'bucket_rounding_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bucket_Rounding>;
};

/** input type for inserting object relation for remote table "bucket_rounding" */
export type Bucket_Rounding_Obj_Rel_Insert_Input = {
  data: Bucket_Rounding_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Bucket_Rounding_On_Conflict>;
};

/** on conflict condition type for table "bucket_rounding" */
export type Bucket_Rounding_On_Conflict = {
  constraint: Bucket_Rounding_Constraint;
  update_columns?: Array<Bucket_Rounding_Update_Column>;
  where?: InputMaybe<Bucket_Rounding_Bool_Exp>;
};

/** Ordering options when selecting data from "bucket_rounding". */
export type Bucket_Rounding_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  roundTypeByRoundType?: InputMaybe<Round_Type_Order_By>;
  round_by?: InputMaybe<Order_By>;
  round_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bucket_rounding */
export type Bucket_Rounding_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "bucket_rounding" */
export enum Bucket_Rounding_Select_Column {
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  Id = 'id',
  /** column name */
  RoundBy = 'round_by',
  /** column name */
  RoundType = 'round_type'
}

/** input type for updating data in table "bucket_rounding" */
export type Bucket_Rounding_Set_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  round_by?: InputMaybe<Scalars['numeric']>;
  round_type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Bucket_Rounding_Stddev_Fields = {
  __typename?: 'bucket_rounding_stddev_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  round_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Bucket_Rounding_Stddev_Pop_Fields = {
  __typename?: 'bucket_rounding_stddev_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  round_by?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Bucket_Rounding_Stddev_Samp_Fields = {
  __typename?: 'bucket_rounding_stddev_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  round_by?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Bucket_Rounding_Sum_Fields = {
  __typename?: 'bucket_rounding_sum_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  round_by?: Maybe<Scalars['numeric']>;
};

/** update columns of table "bucket_rounding" */
export enum Bucket_Rounding_Update_Column {
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  Id = 'id',
  /** column name */
  RoundBy = 'round_by',
  /** column name */
  RoundType = 'round_type'
}

/** aggregate var_pop on columns */
export type Bucket_Rounding_Var_Pop_Fields = {
  __typename?: 'bucket_rounding_var_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  round_by?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Bucket_Rounding_Var_Samp_Fields = {
  __typename?: 'bucket_rounding_var_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  round_by?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Bucket_Rounding_Variance_Fields = {
  __typename?: 'bucket_rounding_variance_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  round_by?: Maybe<Scalars['Float']>;
};

/** select columns of table "bucket" */
export enum Bucket_Select_Column {
  /** column name */
  CmsBucketNumber = 'cms_bucket_number',
  /** column name */
  HasSavedRates = 'has_saved_rates',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PayeeId = 'payee_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Reserves = 'reserves',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  UsesBaseSlices = 'uses_base_slices'
}

/** input type for updating data in table "bucket" */
export type Bucket_Set_Input = {
  cms_bucket_number?: InputMaybe<Scalars['smallint']>;
  has_saved_rates?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  payee_id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['bigint']>;
  reserves?: InputMaybe<Scalars['Boolean']>;
  sort_order?: InputMaybe<Scalars['Int']>;
  uses_base_slices?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Bucket_Stddev_Fields = {
  __typename?: 'bucket_stddev_fields';
  cms_bucket_number?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payee_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "bucket" */
export type Bucket_Stddev_Order_By = {
  cms_bucket_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payee_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Bucket_Stddev_Pop_Fields = {
  __typename?: 'bucket_stddev_pop_fields';
  cms_bucket_number?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payee_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "bucket" */
export type Bucket_Stddev_Pop_Order_By = {
  cms_bucket_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payee_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Bucket_Stddev_Samp_Fields = {
  __typename?: 'bucket_stddev_samp_fields';
  cms_bucket_number?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payee_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "bucket" */
export type Bucket_Stddev_Samp_Order_By = {
  cms_bucket_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payee_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Bucket_Sum_Fields = {
  __typename?: 'bucket_sum_fields';
  cms_bucket_number?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['bigint']>;
  payee_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "bucket" */
export type Bucket_Sum_Order_By = {
  cms_bucket_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payee_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** update columns of table "bucket" */
export enum Bucket_Update_Column {
  /** column name */
  CmsBucketNumber = 'cms_bucket_number',
  /** column name */
  HasSavedRates = 'has_saved_rates',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PayeeId = 'payee_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Reserves = 'reserves',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  UsesBaseSlices = 'uses_base_slices'
}

/** aggregate var_pop on columns */
export type Bucket_Var_Pop_Fields = {
  __typename?: 'bucket_var_pop_fields';
  cms_bucket_number?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payee_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "bucket" */
export type Bucket_Var_Pop_Order_By = {
  cms_bucket_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payee_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Bucket_Var_Samp_Fields = {
  __typename?: 'bucket_var_samp_fields';
  cms_bucket_number?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payee_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "bucket" */
export type Bucket_Var_Samp_Order_By = {
  cms_bucket_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payee_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Bucket_Variance_Fields = {
  __typename?: 'bucket_variance_fields';
  cms_bucket_number?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  payee_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "bucket" */
export type Bucket_Variance_Order_By = {
  cms_bucket_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payee_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** columns and relationships of "commission_type" */
export type Commission_Type = {
  __typename?: 'commission_type';
  created_at: Scalars['timestamp'];
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "commission_type" */
export type Commission_Type_Aggregate = {
  __typename?: 'commission_type_aggregate';
  aggregate?: Maybe<Commission_Type_Aggregate_Fields>;
  nodes: Array<Commission_Type>;
};

/** aggregate fields of "commission_type" */
export type Commission_Type_Aggregate_Fields = {
  __typename?: 'commission_type_aggregate_fields';
  avg?: Maybe<Commission_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Commission_Type_Max_Fields>;
  min?: Maybe<Commission_Type_Min_Fields>;
  stddev?: Maybe<Commission_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Commission_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Commission_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Commission_Type_Sum_Fields>;
  var_pop?: Maybe<Commission_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Commission_Type_Var_Samp_Fields>;
  variance?: Maybe<Commission_Type_Variance_Fields>;
};


/** aggregate fields of "commission_type" */
export type Commission_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Commission_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Commission_Type_Avg_Fields = {
  __typename?: 'commission_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "commission_type". All fields are combined with a logical 'AND'. */
export type Commission_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Commission_Type_Bool_Exp>>;
  _not?: InputMaybe<Commission_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Commission_Type_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "commission_type" */
export enum Commission_Type_Constraint {
  /** unique or primary key constraint */
  CommissionTypeNameKey = 'commission_type_name_key',
  /** unique or primary key constraint */
  CommissionTypePkey = 'commission_type_pkey'
}

/** input type for incrementing numeric columns in table "commission_type" */
export type Commission_Type_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "commission_type" */
export type Commission_Type_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Commission_Type_Max_Fields = {
  __typename?: 'commission_type_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Commission_Type_Min_Fields = {
  __typename?: 'commission_type_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "commission_type" */
export type Commission_Type_Mutation_Response = {
  __typename?: 'commission_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Commission_Type>;
};

/** on conflict condition type for table "commission_type" */
export type Commission_Type_On_Conflict = {
  constraint: Commission_Type_Constraint;
  update_columns?: Array<Commission_Type_Update_Column>;
  where?: InputMaybe<Commission_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "commission_type". */
export type Commission_Type_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: commission_type */
export type Commission_Type_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "commission_type" */
export enum Commission_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "commission_type" */
export type Commission_Type_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Commission_Type_Stddev_Fields = {
  __typename?: 'commission_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Commission_Type_Stddev_Pop_Fields = {
  __typename?: 'commission_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Commission_Type_Stddev_Samp_Fields = {
  __typename?: 'commission_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Commission_Type_Sum_Fields = {
  __typename?: 'commission_type_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "commission_type" */
export enum Commission_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Commission_Type_Var_Pop_Fields = {
  __typename?: 'commission_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Commission_Type_Var_Samp_Fields = {
  __typename?: 'commission_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Commission_Type_Variance_Fields = {
  __typename?: 'commission_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "control_type" */
export type Control_Type = {
  __typename?: 'control_type';
  name: Scalars['String'];
};

/** aggregated selection of "control_type" */
export type Control_Type_Aggregate = {
  __typename?: 'control_type_aggregate';
  aggregate?: Maybe<Control_Type_Aggregate_Fields>;
  nodes: Array<Control_Type>;
};

/** aggregate fields of "control_type" */
export type Control_Type_Aggregate_Fields = {
  __typename?: 'control_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Control_Type_Max_Fields>;
  min?: Maybe<Control_Type_Min_Fields>;
};


/** aggregate fields of "control_type" */
export type Control_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Control_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "control_type". All fields are combined with a logical 'AND'. */
export type Control_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Type_Bool_Exp>>;
  _not?: InputMaybe<Control_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Type_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "control_type" */
export enum Control_Type_Constraint {
  /** unique or primary key constraint */
  ControlTypePkey = 'control_type_pkey'
}

/** input type for inserting data into table "control_type" */
export type Control_Type_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Control_Type_Max_Fields = {
  __typename?: 'control_type_max_fields';
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Control_Type_Min_Fields = {
  __typename?: 'control_type_min_fields';
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "control_type" */
export type Control_Type_Mutation_Response = {
  __typename?: 'control_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Type>;
};

/** on conflict condition type for table "control_type" */
export type Control_Type_On_Conflict = {
  constraint: Control_Type_Constraint;
  update_columns?: Array<Control_Type_Update_Column>;
  where?: InputMaybe<Control_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "control_type". */
export type Control_Type_Order_By = {
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: control_type */
export type Control_Type_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "control_type" */
export enum Control_Type_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "control_type" */
export type Control_Type_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "control_type" */
export enum Control_Type_Update_Column {
  /** column name */
  Name = 'name'
}

export type Create_Product_Msrp_Parameter_Key_Combinations_Args = {
  newparameterkeyids?: InputMaybe<Scalars['_int8']>;
  productid?: InputMaybe<Scalars['bigint']>;
};

export type Create_Product_Parameter_Key_Combinations_Args = {
  newparameterkeyids?: InputMaybe<Scalars['_int8']>;
  productid?: InputMaybe<Scalars['bigint']>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "dealer_product" */
export type Dealer_Product = {
  __typename?: 'dealer_product';
  dealer_id: Scalars['Int'];
  end_date?: Maybe<Scalars['timestamptz']>;
  enrollment_receipt_date?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  last_publish_date?: Maybe<Scalars['timestamptz']>;
  product_code: Scalars['String'];
  program_agent_code: Scalars['String'];
  published_end_date?: Maybe<Scalars['timestamptz']>;
  start_date: Scalars['timestamptz'];
};

/** aggregated selection of "dealer_product" */
export type Dealer_Product_Aggregate = {
  __typename?: 'dealer_product_aggregate';
  aggregate?: Maybe<Dealer_Product_Aggregate_Fields>;
  nodes: Array<Dealer_Product>;
};

/** aggregate fields of "dealer_product" */
export type Dealer_Product_Aggregate_Fields = {
  __typename?: 'dealer_product_aggregate_fields';
  avg?: Maybe<Dealer_Product_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dealer_Product_Max_Fields>;
  min?: Maybe<Dealer_Product_Min_Fields>;
  stddev?: Maybe<Dealer_Product_Stddev_Fields>;
  stddev_pop?: Maybe<Dealer_Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dealer_Product_Stddev_Samp_Fields>;
  sum?: Maybe<Dealer_Product_Sum_Fields>;
  var_pop?: Maybe<Dealer_Product_Var_Pop_Fields>;
  var_samp?: Maybe<Dealer_Product_Var_Samp_Fields>;
  variance?: Maybe<Dealer_Product_Variance_Fields>;
};


/** aggregate fields of "dealer_product" */
export type Dealer_Product_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dealer_Product_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dealer_Product_Avg_Fields = {
  __typename?: 'dealer_product_avg_fields';
  dealer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dealer_product". All fields are combined with a logical 'AND'. */
export type Dealer_Product_Bool_Exp = {
  _and?: InputMaybe<Array<Dealer_Product_Bool_Exp>>;
  _not?: InputMaybe<Dealer_Product_Bool_Exp>;
  _or?: InputMaybe<Array<Dealer_Product_Bool_Exp>>;
  dealer_id?: InputMaybe<Int_Comparison_Exp>;
  end_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  enrollment_receipt_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  last_publish_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  product_code?: InputMaybe<String_Comparison_Exp>;
  program_agent_code?: InputMaybe<String_Comparison_Exp>;
  published_end_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  start_date?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "dealer_product" */
export enum Dealer_Product_Constraint {
  /** unique or primary key constraint */
  DealerProductPkey = 'dealer_product_pkey'
}

/** input type for incrementing numeric columns in table "dealer_product" */
export type Dealer_Product_Inc_Input = {
  dealer_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "dealer_product" */
export type Dealer_Product_Insert_Input = {
  dealer_id?: InputMaybe<Scalars['Int']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  enrollment_receipt_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  last_publish_date?: InputMaybe<Scalars['timestamptz']>;
  product_code?: InputMaybe<Scalars['String']>;
  program_agent_code?: InputMaybe<Scalars['String']>;
  published_end_date?: InputMaybe<Scalars['timestamptz']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Dealer_Product_Max_Fields = {
  __typename?: 'dealer_product_max_fields';
  dealer_id?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  enrollment_receipt_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  last_publish_date?: Maybe<Scalars['timestamptz']>;
  product_code?: Maybe<Scalars['String']>;
  program_agent_code?: Maybe<Scalars['String']>;
  published_end_date?: Maybe<Scalars['timestamptz']>;
  start_date?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Dealer_Product_Min_Fields = {
  __typename?: 'dealer_product_min_fields';
  dealer_id?: Maybe<Scalars['Int']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  enrollment_receipt_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  last_publish_date?: Maybe<Scalars['timestamptz']>;
  product_code?: Maybe<Scalars['String']>;
  program_agent_code?: Maybe<Scalars['String']>;
  published_end_date?: Maybe<Scalars['timestamptz']>;
  start_date?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "dealer_product" */
export type Dealer_Product_Mutation_Response = {
  __typename?: 'dealer_product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dealer_Product>;
};

/** on conflict condition type for table "dealer_product" */
export type Dealer_Product_On_Conflict = {
  constraint: Dealer_Product_Constraint;
  update_columns?: Array<Dealer_Product_Update_Column>;
  where?: InputMaybe<Dealer_Product_Bool_Exp>;
};

/** Ordering options when selecting data from "dealer_product". */
export type Dealer_Product_Order_By = {
  dealer_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  enrollment_receipt_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_publish_date?: InputMaybe<Order_By>;
  product_code?: InputMaybe<Order_By>;
  program_agent_code?: InputMaybe<Order_By>;
  published_end_date?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dealer_product */
export type Dealer_Product_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "dealer_product" */
export enum Dealer_Product_Select_Column {
  /** column name */
  DealerId = 'dealer_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  EnrollmentReceiptDate = 'enrollment_receipt_date',
  /** column name */
  Id = 'id',
  /** column name */
  LastPublishDate = 'last_publish_date',
  /** column name */
  ProductCode = 'product_code',
  /** column name */
  ProgramAgentCode = 'program_agent_code',
  /** column name */
  PublishedEndDate = 'published_end_date',
  /** column name */
  StartDate = 'start_date'
}

/** input type for updating data in table "dealer_product" */
export type Dealer_Product_Set_Input = {
  dealer_id?: InputMaybe<Scalars['Int']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  enrollment_receipt_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  last_publish_date?: InputMaybe<Scalars['timestamptz']>;
  product_code?: InputMaybe<Scalars['String']>;
  program_agent_code?: InputMaybe<Scalars['String']>;
  published_end_date?: InputMaybe<Scalars['timestamptz']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Dealer_Product_Stddev_Fields = {
  __typename?: 'dealer_product_stddev_fields';
  dealer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dealer_Product_Stddev_Pop_Fields = {
  __typename?: 'dealer_product_stddev_pop_fields';
  dealer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dealer_Product_Stddev_Samp_Fields = {
  __typename?: 'dealer_product_stddev_samp_fields';
  dealer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Dealer_Product_Sum_Fields = {
  __typename?: 'dealer_product_sum_fields';
  dealer_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "dealer_product" */
export enum Dealer_Product_Update_Column {
  /** column name */
  DealerId = 'dealer_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  EnrollmentReceiptDate = 'enrollment_receipt_date',
  /** column name */
  Id = 'id',
  /** column name */
  LastPublishDate = 'last_publish_date',
  /** column name */
  ProductCode = 'product_code',
  /** column name */
  ProgramAgentCode = 'program_agent_code',
  /** column name */
  PublishedEndDate = 'published_end_date',
  /** column name */
  StartDate = 'start_date'
}

/** aggregate var_pop on columns */
export type Dealer_Product_Var_Pop_Fields = {
  __typename?: 'dealer_product_var_pop_fields';
  dealer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dealer_Product_Var_Samp_Fields = {
  __typename?: 'dealer_product_var_samp_fields';
  dealer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dealer_Product_Variance_Fields = {
  __typename?: 'dealer_product_variance_fields';
  dealer_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type Delete_Product_Msrp_Parameter_Key_Combinations_Args = {
  deletedparameterkeyids?: InputMaybe<Scalars['_int8']>;
  productid?: InputMaybe<Scalars['bigint']>;
};

export type Delete_Product_Parameter_Key_Combinations_Args = {
  deletedparameterkeyids?: InputMaybe<Scalars['_int8']>;
  productid?: InputMaybe<Scalars['bigint']>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "ledger_event" */
export type Ledger_Event = {
  __typename?: 'ledger_event';
  created_at: Scalars['timestamptz'];
  event_data: Scalars['json'];
  event_type: Scalars['String'];
  id: Scalars['bigint'];
  user_id: Scalars['bigint'];
};


/** columns and relationships of "ledger_event" */
export type Ledger_EventEvent_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "ledger_event" */
export type Ledger_Event_Aggregate = {
  __typename?: 'ledger_event_aggregate';
  aggregate?: Maybe<Ledger_Event_Aggregate_Fields>;
  nodes: Array<Ledger_Event>;
};

/** aggregate fields of "ledger_event" */
export type Ledger_Event_Aggregate_Fields = {
  __typename?: 'ledger_event_aggregate_fields';
  avg?: Maybe<Ledger_Event_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ledger_Event_Max_Fields>;
  min?: Maybe<Ledger_Event_Min_Fields>;
  stddev?: Maybe<Ledger_Event_Stddev_Fields>;
  stddev_pop?: Maybe<Ledger_Event_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ledger_Event_Stddev_Samp_Fields>;
  sum?: Maybe<Ledger_Event_Sum_Fields>;
  var_pop?: Maybe<Ledger_Event_Var_Pop_Fields>;
  var_samp?: Maybe<Ledger_Event_Var_Samp_Fields>;
  variance?: Maybe<Ledger_Event_Variance_Fields>;
};


/** aggregate fields of "ledger_event" */
export type Ledger_Event_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ledger_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ledger_Event_Avg_Fields = {
  __typename?: 'ledger_event_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ledger_event". All fields are combined with a logical 'AND'. */
export type Ledger_Event_Bool_Exp = {
  _and?: InputMaybe<Array<Ledger_Event_Bool_Exp>>;
  _not?: InputMaybe<Ledger_Event_Bool_Exp>;
  _or?: InputMaybe<Array<Ledger_Event_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_data?: InputMaybe<Json_Comparison_Exp>;
  event_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "ledger_event" */
export enum Ledger_Event_Constraint {
  /** unique or primary key constraint */
  LedgerEventPkey = 'ledger_event_pkey'
}

/** input type for incrementing numeric columns in table "ledger_event" */
export type Ledger_Event_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "ledger_event" */
export type Ledger_Event_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_data?: InputMaybe<Scalars['json']>;
  event_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Ledger_Event_Max_Fields = {
  __typename?: 'ledger_event_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Ledger_Event_Min_Fields = {
  __typename?: 'ledger_event_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "ledger_event" */
export type Ledger_Event_Mutation_Response = {
  __typename?: 'ledger_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ledger_Event>;
};

/** on conflict condition type for table "ledger_event" */
export type Ledger_Event_On_Conflict = {
  constraint: Ledger_Event_Constraint;
  update_columns?: Array<Ledger_Event_Update_Column>;
  where?: InputMaybe<Ledger_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "ledger_event". */
export type Ledger_Event_Order_By = {
  created_at?: InputMaybe<Order_By>;
  event_data?: InputMaybe<Order_By>;
  event_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ledger_event */
export type Ledger_Event_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "ledger_event" */
export enum Ledger_Event_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventData = 'event_data',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "ledger_event" */
export type Ledger_Event_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_data?: InputMaybe<Scalars['json']>;
  event_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Ledger_Event_Stddev_Fields = {
  __typename?: 'ledger_event_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ledger_Event_Stddev_Pop_Fields = {
  __typename?: 'ledger_event_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ledger_Event_Stddev_Samp_Fields = {
  __typename?: 'ledger_event_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Ledger_Event_Sum_Fields = {
  __typename?: 'ledger_event_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "ledger_event" */
export enum Ledger_Event_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventData = 'event_data',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Ledger_Event_Var_Pop_Fields = {
  __typename?: 'ledger_event_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ledger_Event_Var_Samp_Fields = {
  __typename?: 'ledger_event_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ledger_Event_Variance_Fields = {
  __typename?: 'ledger_event_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "non_sellable_combination" */
export type Non_Sellable_Combination = {
  __typename?: 'non_sellable_combination';
  id: Scalars['bigint'];
  /** An array relationship */
  non_sellable_combination_options: Array<Non_Sellable_Combination_Option>;
  /** An aggregate relationship */
  non_sellable_combination_options_aggregate: Non_Sellable_Combination_Option_Aggregate;
  parameter_key_ids: Scalars['jsonb'];
  product_id: Scalars['bigint'];
};


/** columns and relationships of "non_sellable_combination" */
export type Non_Sellable_CombinationNon_Sellable_Combination_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Non_Sellable_Combination_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Non_Sellable_Combination_Option_Order_By>>;
  where?: InputMaybe<Non_Sellable_Combination_Option_Bool_Exp>;
};


/** columns and relationships of "non_sellable_combination" */
export type Non_Sellable_CombinationNon_Sellable_Combination_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Non_Sellable_Combination_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Non_Sellable_Combination_Option_Order_By>>;
  where?: InputMaybe<Non_Sellable_Combination_Option_Bool_Exp>;
};


/** columns and relationships of "non_sellable_combination" */
export type Non_Sellable_CombinationParameter_Key_IdsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "non_sellable_combination" */
export type Non_Sellable_Combination_Aggregate = {
  __typename?: 'non_sellable_combination_aggregate';
  aggregate?: Maybe<Non_Sellable_Combination_Aggregate_Fields>;
  nodes: Array<Non_Sellable_Combination>;
};

/** aggregate fields of "non_sellable_combination" */
export type Non_Sellable_Combination_Aggregate_Fields = {
  __typename?: 'non_sellable_combination_aggregate_fields';
  avg?: Maybe<Non_Sellable_Combination_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Non_Sellable_Combination_Max_Fields>;
  min?: Maybe<Non_Sellable_Combination_Min_Fields>;
  stddev?: Maybe<Non_Sellable_Combination_Stddev_Fields>;
  stddev_pop?: Maybe<Non_Sellable_Combination_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Non_Sellable_Combination_Stddev_Samp_Fields>;
  sum?: Maybe<Non_Sellable_Combination_Sum_Fields>;
  var_pop?: Maybe<Non_Sellable_Combination_Var_Pop_Fields>;
  var_samp?: Maybe<Non_Sellable_Combination_Var_Samp_Fields>;
  variance?: Maybe<Non_Sellable_Combination_Variance_Fields>;
};


/** aggregate fields of "non_sellable_combination" */
export type Non_Sellable_Combination_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Non_Sellable_Combination_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "non_sellable_combination" */
export type Non_Sellable_Combination_Aggregate_Order_By = {
  avg?: InputMaybe<Non_Sellable_Combination_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Non_Sellable_Combination_Max_Order_By>;
  min?: InputMaybe<Non_Sellable_Combination_Min_Order_By>;
  stddev?: InputMaybe<Non_Sellable_Combination_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Non_Sellable_Combination_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Non_Sellable_Combination_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Non_Sellable_Combination_Sum_Order_By>;
  var_pop?: InputMaybe<Non_Sellable_Combination_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Non_Sellable_Combination_Var_Samp_Order_By>;
  variance?: InputMaybe<Non_Sellable_Combination_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Non_Sellable_Combination_Append_Input = {
  parameter_key_ids?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "non_sellable_combination" */
export type Non_Sellable_Combination_Arr_Rel_Insert_Input = {
  data: Array<Non_Sellable_Combination_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Non_Sellable_Combination_On_Conflict>;
};

/** aggregate avg on columns */
export type Non_Sellable_Combination_Avg_Fields = {
  __typename?: 'non_sellable_combination_avg_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "non_sellable_combination" */
export type Non_Sellable_Combination_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "non_sellable_combination". All fields are combined with a logical 'AND'. */
export type Non_Sellable_Combination_Bool_Exp = {
  _and?: InputMaybe<Array<Non_Sellable_Combination_Bool_Exp>>;
  _not?: InputMaybe<Non_Sellable_Combination_Bool_Exp>;
  _or?: InputMaybe<Array<Non_Sellable_Combination_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  non_sellable_combination_options?: InputMaybe<Non_Sellable_Combination_Option_Bool_Exp>;
  parameter_key_ids?: InputMaybe<Jsonb_Comparison_Exp>;
  product_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "non_sellable_combination" */
export enum Non_Sellable_Combination_Constraint {
  /** unique or primary key constraint */
  NonSellableCombinationPkey = 'non_sellable_combination_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Non_Sellable_Combination_Delete_At_Path_Input = {
  parameter_key_ids?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Non_Sellable_Combination_Delete_Elem_Input = {
  parameter_key_ids?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Non_Sellable_Combination_Delete_Key_Input = {
  parameter_key_ids?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "non_sellable_combination" */
export type Non_Sellable_Combination_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "non_sellable_combination" */
export type Non_Sellable_Combination_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  non_sellable_combination_options?: InputMaybe<Non_Sellable_Combination_Option_Arr_Rel_Insert_Input>;
  parameter_key_ids?: InputMaybe<Scalars['jsonb']>;
  product_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Non_Sellable_Combination_Max_Fields = {
  __typename?: 'non_sellable_combination_max_fields';
  id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "non_sellable_combination" */
export type Non_Sellable_Combination_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Non_Sellable_Combination_Min_Fields = {
  __typename?: 'non_sellable_combination_min_fields';
  id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "non_sellable_combination" */
export type Non_Sellable_Combination_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "non_sellable_combination" */
export type Non_Sellable_Combination_Mutation_Response = {
  __typename?: 'non_sellable_combination_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Non_Sellable_Combination>;
};

/** input type for inserting object relation for remote table "non_sellable_combination" */
export type Non_Sellable_Combination_Obj_Rel_Insert_Input = {
  data: Non_Sellable_Combination_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Non_Sellable_Combination_On_Conflict>;
};

/** on conflict condition type for table "non_sellable_combination" */
export type Non_Sellable_Combination_On_Conflict = {
  constraint: Non_Sellable_Combination_Constraint;
  update_columns?: Array<Non_Sellable_Combination_Update_Column>;
  where?: InputMaybe<Non_Sellable_Combination_Bool_Exp>;
};

/** columns and relationships of "non_sellable_combination_option" */
export type Non_Sellable_Combination_Option = {
  __typename?: 'non_sellable_combination_option';
  id: Scalars['bigint'];
  /** An object relationship */
  non_sellable_combination: Non_Sellable_Combination;
  non_sellable_combination_id: Scalars['bigint'];
  /** An object relationship */
  parameter_key: Parameter_Key;
  parameter_key_id: Scalars['bigint'];
};

/** aggregated selection of "non_sellable_combination_option" */
export type Non_Sellable_Combination_Option_Aggregate = {
  __typename?: 'non_sellable_combination_option_aggregate';
  aggregate?: Maybe<Non_Sellable_Combination_Option_Aggregate_Fields>;
  nodes: Array<Non_Sellable_Combination_Option>;
};

/** aggregate fields of "non_sellable_combination_option" */
export type Non_Sellable_Combination_Option_Aggregate_Fields = {
  __typename?: 'non_sellable_combination_option_aggregate_fields';
  avg?: Maybe<Non_Sellable_Combination_Option_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Non_Sellable_Combination_Option_Max_Fields>;
  min?: Maybe<Non_Sellable_Combination_Option_Min_Fields>;
  stddev?: Maybe<Non_Sellable_Combination_Option_Stddev_Fields>;
  stddev_pop?: Maybe<Non_Sellable_Combination_Option_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Non_Sellable_Combination_Option_Stddev_Samp_Fields>;
  sum?: Maybe<Non_Sellable_Combination_Option_Sum_Fields>;
  var_pop?: Maybe<Non_Sellable_Combination_Option_Var_Pop_Fields>;
  var_samp?: Maybe<Non_Sellable_Combination_Option_Var_Samp_Fields>;
  variance?: Maybe<Non_Sellable_Combination_Option_Variance_Fields>;
};


/** aggregate fields of "non_sellable_combination_option" */
export type Non_Sellable_Combination_Option_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Non_Sellable_Combination_Option_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "non_sellable_combination_option" */
export type Non_Sellable_Combination_Option_Aggregate_Order_By = {
  avg?: InputMaybe<Non_Sellable_Combination_Option_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Non_Sellable_Combination_Option_Max_Order_By>;
  min?: InputMaybe<Non_Sellable_Combination_Option_Min_Order_By>;
  stddev?: InputMaybe<Non_Sellable_Combination_Option_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Non_Sellable_Combination_Option_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Non_Sellable_Combination_Option_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Non_Sellable_Combination_Option_Sum_Order_By>;
  var_pop?: InputMaybe<Non_Sellable_Combination_Option_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Non_Sellable_Combination_Option_Var_Samp_Order_By>;
  variance?: InputMaybe<Non_Sellable_Combination_Option_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "non_sellable_combination_option" */
export type Non_Sellable_Combination_Option_Arr_Rel_Insert_Input = {
  data: Array<Non_Sellable_Combination_Option_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Non_Sellable_Combination_Option_On_Conflict>;
};

/** aggregate avg on columns */
export type Non_Sellable_Combination_Option_Avg_Fields = {
  __typename?: 'non_sellable_combination_option_avg_fields';
  id?: Maybe<Scalars['Float']>;
  non_sellable_combination_id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "non_sellable_combination_option" */
export type Non_Sellable_Combination_Option_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  non_sellable_combination_id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "non_sellable_combination_option". All fields are combined with a logical 'AND'. */
export type Non_Sellable_Combination_Option_Bool_Exp = {
  _and?: InputMaybe<Array<Non_Sellable_Combination_Option_Bool_Exp>>;
  _not?: InputMaybe<Non_Sellable_Combination_Option_Bool_Exp>;
  _or?: InputMaybe<Array<Non_Sellable_Combination_Option_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  non_sellable_combination?: InputMaybe<Non_Sellable_Combination_Bool_Exp>;
  non_sellable_combination_id?: InputMaybe<Bigint_Comparison_Exp>;
  parameter_key?: InputMaybe<Parameter_Key_Bool_Exp>;
  parameter_key_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "non_sellable_combination_option" */
export enum Non_Sellable_Combination_Option_Constraint {
  /** unique or primary key constraint */
  NonSellableCombinationOptionPkey = 'non_sellable_combination_option_pkey'
}

/** input type for incrementing numeric columns in table "non_sellable_combination_option" */
export type Non_Sellable_Combination_Option_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  non_sellable_combination_id?: InputMaybe<Scalars['bigint']>;
  parameter_key_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "non_sellable_combination_option" */
export type Non_Sellable_Combination_Option_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  non_sellable_combination?: InputMaybe<Non_Sellable_Combination_Obj_Rel_Insert_Input>;
  non_sellable_combination_id?: InputMaybe<Scalars['bigint']>;
  parameter_key?: InputMaybe<Parameter_Key_Obj_Rel_Insert_Input>;
  parameter_key_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Non_Sellable_Combination_Option_Max_Fields = {
  __typename?: 'non_sellable_combination_option_max_fields';
  id?: Maybe<Scalars['bigint']>;
  non_sellable_combination_id?: Maybe<Scalars['bigint']>;
  parameter_key_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "non_sellable_combination_option" */
export type Non_Sellable_Combination_Option_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  non_sellable_combination_id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Non_Sellable_Combination_Option_Min_Fields = {
  __typename?: 'non_sellable_combination_option_min_fields';
  id?: Maybe<Scalars['bigint']>;
  non_sellable_combination_id?: Maybe<Scalars['bigint']>;
  parameter_key_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "non_sellable_combination_option" */
export type Non_Sellable_Combination_Option_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  non_sellable_combination_id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "non_sellable_combination_option" */
export type Non_Sellable_Combination_Option_Mutation_Response = {
  __typename?: 'non_sellable_combination_option_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Non_Sellable_Combination_Option>;
};

/** on conflict condition type for table "non_sellable_combination_option" */
export type Non_Sellable_Combination_Option_On_Conflict = {
  constraint: Non_Sellable_Combination_Option_Constraint;
  update_columns?: Array<Non_Sellable_Combination_Option_Update_Column>;
  where?: InputMaybe<Non_Sellable_Combination_Option_Bool_Exp>;
};

/** Ordering options when selecting data from "non_sellable_combination_option". */
export type Non_Sellable_Combination_Option_Order_By = {
  id?: InputMaybe<Order_By>;
  non_sellable_combination?: InputMaybe<Non_Sellable_Combination_Order_By>;
  non_sellable_combination_id?: InputMaybe<Order_By>;
  parameter_key?: InputMaybe<Parameter_Key_Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: non_sellable_combination_option */
export type Non_Sellable_Combination_Option_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "non_sellable_combination_option" */
export enum Non_Sellable_Combination_Option_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  NonSellableCombinationId = 'non_sellable_combination_id',
  /** column name */
  ParameterKeyId = 'parameter_key_id'
}

/** input type for updating data in table "non_sellable_combination_option" */
export type Non_Sellable_Combination_Option_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  non_sellable_combination_id?: InputMaybe<Scalars['bigint']>;
  parameter_key_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Non_Sellable_Combination_Option_Stddev_Fields = {
  __typename?: 'non_sellable_combination_option_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  non_sellable_combination_id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "non_sellable_combination_option" */
export type Non_Sellable_Combination_Option_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  non_sellable_combination_id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Non_Sellable_Combination_Option_Stddev_Pop_Fields = {
  __typename?: 'non_sellable_combination_option_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  non_sellable_combination_id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "non_sellable_combination_option" */
export type Non_Sellable_Combination_Option_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  non_sellable_combination_id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Non_Sellable_Combination_Option_Stddev_Samp_Fields = {
  __typename?: 'non_sellable_combination_option_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  non_sellable_combination_id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "non_sellable_combination_option" */
export type Non_Sellable_Combination_Option_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  non_sellable_combination_id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Non_Sellable_Combination_Option_Sum_Fields = {
  __typename?: 'non_sellable_combination_option_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  non_sellable_combination_id?: Maybe<Scalars['bigint']>;
  parameter_key_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "non_sellable_combination_option" */
export type Non_Sellable_Combination_Option_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  non_sellable_combination_id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
};

/** update columns of table "non_sellable_combination_option" */
export enum Non_Sellable_Combination_Option_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  NonSellableCombinationId = 'non_sellable_combination_id',
  /** column name */
  ParameterKeyId = 'parameter_key_id'
}

/** aggregate var_pop on columns */
export type Non_Sellable_Combination_Option_Var_Pop_Fields = {
  __typename?: 'non_sellable_combination_option_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  non_sellable_combination_id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "non_sellable_combination_option" */
export type Non_Sellable_Combination_Option_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  non_sellable_combination_id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Non_Sellable_Combination_Option_Var_Samp_Fields = {
  __typename?: 'non_sellable_combination_option_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  non_sellable_combination_id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "non_sellable_combination_option" */
export type Non_Sellable_Combination_Option_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  non_sellable_combination_id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Non_Sellable_Combination_Option_Variance_Fields = {
  __typename?: 'non_sellable_combination_option_variance_fields';
  id?: Maybe<Scalars['Float']>;
  non_sellable_combination_id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "non_sellable_combination_option" */
export type Non_Sellable_Combination_Option_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  non_sellable_combination_id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "non_sellable_combination". */
export type Non_Sellable_Combination_Order_By = {
  id?: InputMaybe<Order_By>;
  non_sellable_combination_options_aggregate?: InputMaybe<Non_Sellable_Combination_Option_Aggregate_Order_By>;
  parameter_key_ids?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: non_sellable_combination */
export type Non_Sellable_Combination_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Non_Sellable_Combination_Prepend_Input = {
  parameter_key_ids?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "non_sellable_combination" */
export enum Non_Sellable_Combination_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ParameterKeyIds = 'parameter_key_ids',
  /** column name */
  ProductId = 'product_id'
}

/** input type for updating data in table "non_sellable_combination" */
export type Non_Sellable_Combination_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  parameter_key_ids?: InputMaybe<Scalars['jsonb']>;
  product_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Non_Sellable_Combination_Stddev_Fields = {
  __typename?: 'non_sellable_combination_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "non_sellable_combination" */
export type Non_Sellable_Combination_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Non_Sellable_Combination_Stddev_Pop_Fields = {
  __typename?: 'non_sellable_combination_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "non_sellable_combination" */
export type Non_Sellable_Combination_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Non_Sellable_Combination_Stddev_Samp_Fields = {
  __typename?: 'non_sellable_combination_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "non_sellable_combination" */
export type Non_Sellable_Combination_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Non_Sellable_Combination_Sum_Fields = {
  __typename?: 'non_sellable_combination_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "non_sellable_combination" */
export type Non_Sellable_Combination_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** update columns of table "non_sellable_combination" */
export enum Non_Sellable_Combination_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ParameterKeyIds = 'parameter_key_ids',
  /** column name */
  ProductId = 'product_id'
}

/** aggregate var_pop on columns */
export type Non_Sellable_Combination_Var_Pop_Fields = {
  __typename?: 'non_sellable_combination_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "non_sellable_combination" */
export type Non_Sellable_Combination_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Non_Sellable_Combination_Var_Samp_Fields = {
  __typename?: 'non_sellable_combination_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "non_sellable_combination" */
export type Non_Sellable_Combination_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Non_Sellable_Combination_Variance_Fields = {
  __typename?: 'non_sellable_combination_variance_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "non_sellable_combination" */
export type Non_Sellable_Combination_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** columns and relationships of "offset_type" */
export type Offset_Type = {
  __typename?: 'offset_type';
  offset_type: Scalars['String'];
};

/** aggregated selection of "offset_type" */
export type Offset_Type_Aggregate = {
  __typename?: 'offset_type_aggregate';
  aggregate?: Maybe<Offset_Type_Aggregate_Fields>;
  nodes: Array<Offset_Type>;
};

/** aggregate fields of "offset_type" */
export type Offset_Type_Aggregate_Fields = {
  __typename?: 'offset_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Offset_Type_Max_Fields>;
  min?: Maybe<Offset_Type_Min_Fields>;
};


/** aggregate fields of "offset_type" */
export type Offset_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Offset_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "offset_type". All fields are combined with a logical 'AND'. */
export type Offset_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Offset_Type_Bool_Exp>>;
  _not?: InputMaybe<Offset_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Offset_Type_Bool_Exp>>;
  offset_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "offset_type" */
export enum Offset_Type_Constraint {
  /** unique or primary key constraint */
  OffsetTypePkey = 'offset_type_pkey'
}

/** input type for inserting data into table "offset_type" */
export type Offset_Type_Insert_Input = {
  offset_type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Offset_Type_Max_Fields = {
  __typename?: 'offset_type_max_fields';
  offset_type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Offset_Type_Min_Fields = {
  __typename?: 'offset_type_min_fields';
  offset_type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "offset_type" */
export type Offset_Type_Mutation_Response = {
  __typename?: 'offset_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Offset_Type>;
};

/** input type for inserting object relation for remote table "offset_type" */
export type Offset_Type_Obj_Rel_Insert_Input = {
  data: Offset_Type_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Offset_Type_On_Conflict>;
};

/** on conflict condition type for table "offset_type" */
export type Offset_Type_On_Conflict = {
  constraint: Offset_Type_Constraint;
  update_columns?: Array<Offset_Type_Update_Column>;
  where?: InputMaybe<Offset_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "offset_type". */
export type Offset_Type_Order_By = {
  offset_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: offset_type */
export type Offset_Type_Pk_Columns_Input = {
  offset_type: Scalars['String'];
};

/** select columns of table "offset_type" */
export enum Offset_Type_Select_Column {
  /** column name */
  OffsetType = 'offset_type'
}

/** input type for updating data in table "offset_type" */
export type Offset_Type_Set_Input = {
  offset_type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "offset_type" */
export enum Offset_Type_Update_Column {
  /** column name */
  OffsetType = 'offset_type'
}

/** columns and relationships of "operator" */
export type Operator = {
  __typename?: 'operator';
  operator: Scalars['String'];
};

/** aggregated selection of "operator" */
export type Operator_Aggregate = {
  __typename?: 'operator_aggregate';
  aggregate?: Maybe<Operator_Aggregate_Fields>;
  nodes: Array<Operator>;
};

/** aggregate fields of "operator" */
export type Operator_Aggregate_Fields = {
  __typename?: 'operator_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Operator_Max_Fields>;
  min?: Maybe<Operator_Min_Fields>;
};


/** aggregate fields of "operator" */
export type Operator_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Operator_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "operator". All fields are combined with a logical 'AND'. */
export type Operator_Bool_Exp = {
  _and?: InputMaybe<Array<Operator_Bool_Exp>>;
  _not?: InputMaybe<Operator_Bool_Exp>;
  _or?: InputMaybe<Array<Operator_Bool_Exp>>;
  operator?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "operator" */
export enum Operator_Constraint {
  /** unique or primary key constraint */
  OperatorPkey = 'operator_pkey'
}

/** input type for inserting data into table "operator" */
export type Operator_Insert_Input = {
  operator?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Operator_Max_Fields = {
  __typename?: 'operator_max_fields';
  operator?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Operator_Min_Fields = {
  __typename?: 'operator_min_fields';
  operator?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "operator" */
export type Operator_Mutation_Response = {
  __typename?: 'operator_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Operator>;
};

/** on conflict condition type for table "operator" */
export type Operator_On_Conflict = {
  constraint: Operator_Constraint;
  update_columns?: Array<Operator_Update_Column>;
  where?: InputMaybe<Operator_Bool_Exp>;
};

/** Ordering options when selecting data from "operator". */
export type Operator_Order_By = {
  operator?: InputMaybe<Order_By>;
};

/** primary key columns input for table: operator */
export type Operator_Pk_Columns_Input = {
  operator: Scalars['String'];
};

/** select columns of table "operator" */
export enum Operator_Select_Column {
  /** column name */
  Operator = 'operator'
}

/** input type for updating data in table "operator" */
export type Operator_Set_Input = {
  operator?: InputMaybe<Scalars['String']>;
};

/** update columns of table "operator" */
export enum Operator_Update_Column {
  /** column name */
  Operator = 'operator'
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "parameter" */
export type Parameter = {
  __typename?: 'parameter';
  description: Scalars['String'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  /** An array relationship */
  parameter_keys: Array<Parameter_Key>;
  /** An aggregate relationship */
  parameter_keys_aggregate: Parameter_Key_Aggregate;
  /** An object relationship */
  parameter_type: Parameter_Type;
  parameter_type_id: Scalars['bigint'];
};


/** columns and relationships of "parameter" */
export type ParameterParameter_KeysArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Key_Order_By>>;
  where?: InputMaybe<Parameter_Key_Bool_Exp>;
};


/** columns and relationships of "parameter" */
export type ParameterParameter_Keys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Key_Order_By>>;
  where?: InputMaybe<Parameter_Key_Bool_Exp>;
};

/** aggregated selection of "parameter" */
export type Parameter_Aggregate = {
  __typename?: 'parameter_aggregate';
  aggregate?: Maybe<Parameter_Aggregate_Fields>;
  nodes: Array<Parameter>;
};

/** aggregate fields of "parameter" */
export type Parameter_Aggregate_Fields = {
  __typename?: 'parameter_aggregate_fields';
  avg?: Maybe<Parameter_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Parameter_Max_Fields>;
  min?: Maybe<Parameter_Min_Fields>;
  stddev?: Maybe<Parameter_Stddev_Fields>;
  stddev_pop?: Maybe<Parameter_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Parameter_Stddev_Samp_Fields>;
  sum?: Maybe<Parameter_Sum_Fields>;
  var_pop?: Maybe<Parameter_Var_Pop_Fields>;
  var_samp?: Maybe<Parameter_Var_Samp_Fields>;
  variance?: Maybe<Parameter_Variance_Fields>;
};


/** aggregate fields of "parameter" */
export type Parameter_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Parameter_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Parameter_Avg_Fields = {
  __typename?: 'parameter_avg_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_type_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "parameter". All fields are combined with a logical 'AND'. */
export type Parameter_Bool_Exp = {
  _and?: InputMaybe<Array<Parameter_Bool_Exp>>;
  _not?: InputMaybe<Parameter_Bool_Exp>;
  _or?: InputMaybe<Array<Parameter_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  parameter_keys?: InputMaybe<Parameter_Key_Bool_Exp>;
  parameter_type?: InputMaybe<Parameter_Type_Bool_Exp>;
  parameter_type_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "parameter" */
export enum Parameter_Constraint {
  /** unique or primary key constraint */
  ParameterPkey = 'parameter_pkey'
}

/** input type for incrementing numeric columns in table "parameter" */
export type Parameter_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  parameter_type_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "parameter" */
export type Parameter_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  parameter_keys?: InputMaybe<Parameter_Key_Arr_Rel_Insert_Input>;
  parameter_type?: InputMaybe<Parameter_Type_Obj_Rel_Insert_Input>;
  parameter_type_id?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "parameter_key" */
export type Parameter_Key = {
  __typename?: 'parameter_key';
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  key: Scalars['String'];
  /** An object relationship */
  parameter: Parameter;
  parameter_id: Scalars['bigint'];
  /** An array relationship */
  parameter_key_values: Array<Parameter_Key_Value>;
  /** An aggregate relationship */
  parameter_key_values_aggregate: Parameter_Key_Value_Aggregate;
  /** An array relationship */
  parameter_sub_keys: Array<Parameter_Sub_Key>;
  /** An aggregate relationship */
  parameter_sub_keys_aggregate: Parameter_Sub_Key_Aggregate;
  /** An object relationship */
  product: Product;
  product_id: Scalars['bigint'];
  sort_order: Scalars['Int'];
};


/** columns and relationships of "parameter_key" */
export type Parameter_KeyParameter_Key_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Key_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Key_Value_Order_By>>;
  where?: InputMaybe<Parameter_Key_Value_Bool_Exp>;
};


/** columns and relationships of "parameter_key" */
export type Parameter_KeyParameter_Key_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Key_Value_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Key_Value_Order_By>>;
  where?: InputMaybe<Parameter_Key_Value_Bool_Exp>;
};


/** columns and relationships of "parameter_key" */
export type Parameter_KeyParameter_Sub_KeysArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Sub_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Sub_Key_Order_By>>;
  where?: InputMaybe<Parameter_Sub_Key_Bool_Exp>;
};


/** columns and relationships of "parameter_key" */
export type Parameter_KeyParameter_Sub_Keys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Sub_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Sub_Key_Order_By>>;
  where?: InputMaybe<Parameter_Sub_Key_Bool_Exp>;
};

/** aggregated selection of "parameter_key" */
export type Parameter_Key_Aggregate = {
  __typename?: 'parameter_key_aggregate';
  aggregate?: Maybe<Parameter_Key_Aggregate_Fields>;
  nodes: Array<Parameter_Key>;
};

/** aggregate fields of "parameter_key" */
export type Parameter_Key_Aggregate_Fields = {
  __typename?: 'parameter_key_aggregate_fields';
  avg?: Maybe<Parameter_Key_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Parameter_Key_Max_Fields>;
  min?: Maybe<Parameter_Key_Min_Fields>;
  stddev?: Maybe<Parameter_Key_Stddev_Fields>;
  stddev_pop?: Maybe<Parameter_Key_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Parameter_Key_Stddev_Samp_Fields>;
  sum?: Maybe<Parameter_Key_Sum_Fields>;
  var_pop?: Maybe<Parameter_Key_Var_Pop_Fields>;
  var_samp?: Maybe<Parameter_Key_Var_Samp_Fields>;
  variance?: Maybe<Parameter_Key_Variance_Fields>;
};


/** aggregate fields of "parameter_key" */
export type Parameter_Key_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Parameter_Key_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "parameter_key" */
export type Parameter_Key_Aggregate_Order_By = {
  avg?: InputMaybe<Parameter_Key_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Parameter_Key_Max_Order_By>;
  min?: InputMaybe<Parameter_Key_Min_Order_By>;
  stddev?: InputMaybe<Parameter_Key_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Parameter_Key_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Parameter_Key_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Parameter_Key_Sum_Order_By>;
  var_pop?: InputMaybe<Parameter_Key_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Parameter_Key_Var_Samp_Order_By>;
  variance?: InputMaybe<Parameter_Key_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "parameter_key" */
export type Parameter_Key_Arr_Rel_Insert_Input = {
  data: Array<Parameter_Key_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Parameter_Key_On_Conflict>;
};

/** aggregate avg on columns */
export type Parameter_Key_Avg_Fields = {
  __typename?: 'parameter_key_avg_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "parameter_key" */
export type Parameter_Key_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "parameter_key". All fields are combined with a logical 'AND'. */
export type Parameter_Key_Bool_Exp = {
  _and?: InputMaybe<Array<Parameter_Key_Bool_Exp>>;
  _not?: InputMaybe<Parameter_Key_Bool_Exp>;
  _or?: InputMaybe<Array<Parameter_Key_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  parameter?: InputMaybe<Parameter_Bool_Exp>;
  parameter_id?: InputMaybe<Bigint_Comparison_Exp>;
  parameter_key_values?: InputMaybe<Parameter_Key_Value_Bool_Exp>;
  parameter_sub_keys?: InputMaybe<Parameter_Sub_Key_Bool_Exp>;
  product?: InputMaybe<Product_Bool_Exp>;
  product_id?: InputMaybe<Bigint_Comparison_Exp>;
  sort_order?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "parameter_key" */
export enum Parameter_Key_Constraint {
  /** unique or primary key constraint */
  ParameterKeyParameterIdProductIdKeyKey = 'parameter_key_parameter_id_product_id_key_key',
  /** unique or primary key constraint */
  ParameterKeyPkey = 'parameter_key_pkey'
}

/** input type for incrementing numeric columns in table "parameter_key" */
export type Parameter_Key_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  parameter_id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['bigint']>;
  sort_order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "parameter_key" */
export type Parameter_Key_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  key?: InputMaybe<Scalars['String']>;
  parameter?: InputMaybe<Parameter_Obj_Rel_Insert_Input>;
  parameter_id?: InputMaybe<Scalars['bigint']>;
  parameter_key_values?: InputMaybe<Parameter_Key_Value_Arr_Rel_Insert_Input>;
  parameter_sub_keys?: InputMaybe<Parameter_Sub_Key_Arr_Rel_Insert_Input>;
  product?: InputMaybe<Product_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars['bigint']>;
  sort_order?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Parameter_Key_Max_Fields = {
  __typename?: 'parameter_key_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  key?: Maybe<Scalars['String']>;
  parameter_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "parameter_key" */
export type Parameter_Key_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Parameter_Key_Min_Fields = {
  __typename?: 'parameter_key_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  key?: Maybe<Scalars['String']>;
  parameter_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "parameter_key" */
export type Parameter_Key_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "parameter_key" */
export type Parameter_Key_Mutation_Response = {
  __typename?: 'parameter_key_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Parameter_Key>;
};

/** input type for inserting object relation for remote table "parameter_key" */
export type Parameter_Key_Obj_Rel_Insert_Input = {
  data: Parameter_Key_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Parameter_Key_On_Conflict>;
};

/** on conflict condition type for table "parameter_key" */
export type Parameter_Key_On_Conflict = {
  constraint: Parameter_Key_Constraint;
  update_columns?: Array<Parameter_Key_Update_Column>;
  where?: InputMaybe<Parameter_Key_Bool_Exp>;
};

/** Ordering options when selecting data from "parameter_key". */
export type Parameter_Key_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  parameter?: InputMaybe<Parameter_Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  parameter_key_values_aggregate?: InputMaybe<Parameter_Key_Value_Aggregate_Order_By>;
  parameter_sub_keys_aggregate?: InputMaybe<Parameter_Sub_Key_Aggregate_Order_By>;
  product?: InputMaybe<Product_Order_By>;
  product_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** primary key columns input for table: parameter_key */
export type Parameter_Key_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "parameter_key" */
export enum Parameter_Key_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  ParameterId = 'parameter_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  SortOrder = 'sort_order'
}

/** input type for updating data in table "parameter_key" */
export type Parameter_Key_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  key?: InputMaybe<Scalars['String']>;
  parameter_id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['bigint']>;
  sort_order?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Parameter_Key_Stddev_Fields = {
  __typename?: 'parameter_key_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "parameter_key" */
export type Parameter_Key_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Parameter_Key_Stddev_Pop_Fields = {
  __typename?: 'parameter_key_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "parameter_key" */
export type Parameter_Key_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Parameter_Key_Stddev_Samp_Fields = {
  __typename?: 'parameter_key_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "parameter_key" */
export type Parameter_Key_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Parameter_Key_Sum_Fields = {
  __typename?: 'parameter_key_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  parameter_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "parameter_key" */
export type Parameter_Key_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** update columns of table "parameter_key" */
export enum Parameter_Key_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  ParameterId = 'parameter_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  SortOrder = 'sort_order'
}

/** columns and relationships of "parameter_key_value" */
export type Parameter_Key_Value = {
  __typename?: 'parameter_key_value';
  id: Scalars['bigint'];
  parameter_key_id: Scalars['bigint'];
  /** An object relationship */
  parameter_subtype: Parameter_Subtype;
  parameter_subtype_id: Scalars['bigint'];
  value: Scalars['String'];
};

/** aggregated selection of "parameter_key_value" */
export type Parameter_Key_Value_Aggregate = {
  __typename?: 'parameter_key_value_aggregate';
  aggregate?: Maybe<Parameter_Key_Value_Aggregate_Fields>;
  nodes: Array<Parameter_Key_Value>;
};

/** aggregate fields of "parameter_key_value" */
export type Parameter_Key_Value_Aggregate_Fields = {
  __typename?: 'parameter_key_value_aggregate_fields';
  avg?: Maybe<Parameter_Key_Value_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Parameter_Key_Value_Max_Fields>;
  min?: Maybe<Parameter_Key_Value_Min_Fields>;
  stddev?: Maybe<Parameter_Key_Value_Stddev_Fields>;
  stddev_pop?: Maybe<Parameter_Key_Value_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Parameter_Key_Value_Stddev_Samp_Fields>;
  sum?: Maybe<Parameter_Key_Value_Sum_Fields>;
  var_pop?: Maybe<Parameter_Key_Value_Var_Pop_Fields>;
  var_samp?: Maybe<Parameter_Key_Value_Var_Samp_Fields>;
  variance?: Maybe<Parameter_Key_Value_Variance_Fields>;
};


/** aggregate fields of "parameter_key_value" */
export type Parameter_Key_Value_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Parameter_Key_Value_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "parameter_key_value" */
export type Parameter_Key_Value_Aggregate_Order_By = {
  avg?: InputMaybe<Parameter_Key_Value_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Parameter_Key_Value_Max_Order_By>;
  min?: InputMaybe<Parameter_Key_Value_Min_Order_By>;
  stddev?: InputMaybe<Parameter_Key_Value_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Parameter_Key_Value_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Parameter_Key_Value_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Parameter_Key_Value_Sum_Order_By>;
  var_pop?: InputMaybe<Parameter_Key_Value_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Parameter_Key_Value_Var_Samp_Order_By>;
  variance?: InputMaybe<Parameter_Key_Value_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "parameter_key_value" */
export type Parameter_Key_Value_Arr_Rel_Insert_Input = {
  data: Array<Parameter_Key_Value_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Parameter_Key_Value_On_Conflict>;
};

/** aggregate avg on columns */
export type Parameter_Key_Value_Avg_Fields = {
  __typename?: 'parameter_key_value_avg_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  parameter_subtype_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "parameter_key_value" */
export type Parameter_Key_Value_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parameter_subtype_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "parameter_key_value". All fields are combined with a logical 'AND'. */
export type Parameter_Key_Value_Bool_Exp = {
  _and?: InputMaybe<Array<Parameter_Key_Value_Bool_Exp>>;
  _not?: InputMaybe<Parameter_Key_Value_Bool_Exp>;
  _or?: InputMaybe<Array<Parameter_Key_Value_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  parameter_key_id?: InputMaybe<Bigint_Comparison_Exp>;
  parameter_subtype?: InputMaybe<Parameter_Subtype_Bool_Exp>;
  parameter_subtype_id?: InputMaybe<Bigint_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "parameter_key_value" */
export enum Parameter_Key_Value_Constraint {
  /** unique or primary key constraint */
  ParameterKeyValueParameterSubtypeIdParameterKeyIdKey = 'parameter_key_value_parameter_subtype_id_parameter_key_id_key',
  /** unique or primary key constraint */
  ParameterKeyValuePkey = 'parameter_key_value_pkey'
}

/** input type for incrementing numeric columns in table "parameter_key_value" */
export type Parameter_Key_Value_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  parameter_key_id?: InputMaybe<Scalars['bigint']>;
  parameter_subtype_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "parameter_key_value" */
export type Parameter_Key_Value_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  parameter_key_id?: InputMaybe<Scalars['bigint']>;
  parameter_subtype?: InputMaybe<Parameter_Subtype_Obj_Rel_Insert_Input>;
  parameter_subtype_id?: InputMaybe<Scalars['bigint']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Parameter_Key_Value_Max_Fields = {
  __typename?: 'parameter_key_value_max_fields';
  id?: Maybe<Scalars['bigint']>;
  parameter_key_id?: Maybe<Scalars['bigint']>;
  parameter_subtype_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "parameter_key_value" */
export type Parameter_Key_Value_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parameter_subtype_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Parameter_Key_Value_Min_Fields = {
  __typename?: 'parameter_key_value_min_fields';
  id?: Maybe<Scalars['bigint']>;
  parameter_key_id?: Maybe<Scalars['bigint']>;
  parameter_subtype_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "parameter_key_value" */
export type Parameter_Key_Value_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parameter_subtype_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "parameter_key_value" */
export type Parameter_Key_Value_Mutation_Response = {
  __typename?: 'parameter_key_value_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Parameter_Key_Value>;
};

/** on conflict condition type for table "parameter_key_value" */
export type Parameter_Key_Value_On_Conflict = {
  constraint: Parameter_Key_Value_Constraint;
  update_columns?: Array<Parameter_Key_Value_Update_Column>;
  where?: InputMaybe<Parameter_Key_Value_Bool_Exp>;
};

/** Ordering options when selecting data from "parameter_key_value". */
export type Parameter_Key_Value_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parameter_subtype?: InputMaybe<Parameter_Subtype_Order_By>;
  parameter_subtype_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: parameter_key_value */
export type Parameter_Key_Value_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "parameter_key_value" */
export enum Parameter_Key_Value_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ParameterKeyId = 'parameter_key_id',
  /** column name */
  ParameterSubtypeId = 'parameter_subtype_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "parameter_key_value" */
export type Parameter_Key_Value_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  parameter_key_id?: InputMaybe<Scalars['bigint']>;
  parameter_subtype_id?: InputMaybe<Scalars['bigint']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Parameter_Key_Value_Stddev_Fields = {
  __typename?: 'parameter_key_value_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  parameter_subtype_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "parameter_key_value" */
export type Parameter_Key_Value_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parameter_subtype_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Parameter_Key_Value_Stddev_Pop_Fields = {
  __typename?: 'parameter_key_value_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  parameter_subtype_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "parameter_key_value" */
export type Parameter_Key_Value_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parameter_subtype_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Parameter_Key_Value_Stddev_Samp_Fields = {
  __typename?: 'parameter_key_value_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  parameter_subtype_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "parameter_key_value" */
export type Parameter_Key_Value_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parameter_subtype_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Parameter_Key_Value_Sum_Fields = {
  __typename?: 'parameter_key_value_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  parameter_key_id?: Maybe<Scalars['bigint']>;
  parameter_subtype_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "parameter_key_value" */
export type Parameter_Key_Value_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parameter_subtype_id?: InputMaybe<Order_By>;
};

/** update columns of table "parameter_key_value" */
export enum Parameter_Key_Value_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ParameterKeyId = 'parameter_key_id',
  /** column name */
  ParameterSubtypeId = 'parameter_subtype_id',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type Parameter_Key_Value_Var_Pop_Fields = {
  __typename?: 'parameter_key_value_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  parameter_subtype_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "parameter_key_value" */
export type Parameter_Key_Value_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parameter_subtype_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Parameter_Key_Value_Var_Samp_Fields = {
  __typename?: 'parameter_key_value_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  parameter_subtype_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "parameter_key_value" */
export type Parameter_Key_Value_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parameter_subtype_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Parameter_Key_Value_Variance_Fields = {
  __typename?: 'parameter_key_value_variance_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  parameter_subtype_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "parameter_key_value" */
export type Parameter_Key_Value_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parameter_subtype_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Parameter_Key_Var_Pop_Fields = {
  __typename?: 'parameter_key_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "parameter_key" */
export type Parameter_Key_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Parameter_Key_Var_Samp_Fields = {
  __typename?: 'parameter_key_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "parameter_key" */
export type Parameter_Key_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Parameter_Key_Variance_Fields = {
  __typename?: 'parameter_key_variance_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "parameter_key" */
export type Parameter_Key_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Parameter_Max_Fields = {
  __typename?: 'parameter_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  parameter_type_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Parameter_Min_Fields = {
  __typename?: 'parameter_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  parameter_type_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "parameter" */
export type Parameter_Mutation_Response = {
  __typename?: 'parameter_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Parameter>;
};

/** input type for inserting object relation for remote table "parameter" */
export type Parameter_Obj_Rel_Insert_Input = {
  data: Parameter_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Parameter_On_Conflict>;
};

/** on conflict condition type for table "parameter" */
export type Parameter_On_Conflict = {
  constraint: Parameter_Constraint;
  update_columns?: Array<Parameter_Update_Column>;
  where?: InputMaybe<Parameter_Bool_Exp>;
};

/** Ordering options when selecting data from "parameter". */
export type Parameter_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parameter_keys_aggregate?: InputMaybe<Parameter_Key_Aggregate_Order_By>;
  parameter_type?: InputMaybe<Parameter_Type_Order_By>;
  parameter_type_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: parameter */
export type Parameter_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "parameter" */
export enum Parameter_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParameterTypeId = 'parameter_type_id'
}

/** input type for updating data in table "parameter" */
export type Parameter_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  parameter_type_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Parameter_Stddev_Fields = {
  __typename?: 'parameter_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_type_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Parameter_Stddev_Pop_Fields = {
  __typename?: 'parameter_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_type_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Parameter_Stddev_Samp_Fields = {
  __typename?: 'parameter_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_type_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "parameter_sub_key" */
export type Parameter_Sub_Key = {
  __typename?: 'parameter_sub_key';
  /** An object relationship */
  child_parameter_key: Parameter_Key;
  child_parameter_key_id: Scalars['bigint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An array relationship */
  parameter_sub_key_bucket_discounts: Array<Parameter_Sub_Key_Bucket_Discount>;
  /** An aggregate relationship */
  parameter_sub_key_bucket_discounts_aggregate: Parameter_Sub_Key_Bucket_Discount_Aggregate;
  parent_parameter_key_id: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "parameter_sub_key" */
export type Parameter_Sub_KeyParameter_Sub_Key_Bucket_DiscountsArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Sub_Key_Bucket_Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Sub_Key_Bucket_Discount_Order_By>>;
  where?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Bool_Exp>;
};


/** columns and relationships of "parameter_sub_key" */
export type Parameter_Sub_KeyParameter_Sub_Key_Bucket_Discounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Sub_Key_Bucket_Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Sub_Key_Bucket_Discount_Order_By>>;
  where?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Bool_Exp>;
};

/** aggregated selection of "parameter_sub_key" */
export type Parameter_Sub_Key_Aggregate = {
  __typename?: 'parameter_sub_key_aggregate';
  aggregate?: Maybe<Parameter_Sub_Key_Aggregate_Fields>;
  nodes: Array<Parameter_Sub_Key>;
};

/** aggregate fields of "parameter_sub_key" */
export type Parameter_Sub_Key_Aggregate_Fields = {
  __typename?: 'parameter_sub_key_aggregate_fields';
  avg?: Maybe<Parameter_Sub_Key_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Parameter_Sub_Key_Max_Fields>;
  min?: Maybe<Parameter_Sub_Key_Min_Fields>;
  stddev?: Maybe<Parameter_Sub_Key_Stddev_Fields>;
  stddev_pop?: Maybe<Parameter_Sub_Key_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Parameter_Sub_Key_Stddev_Samp_Fields>;
  sum?: Maybe<Parameter_Sub_Key_Sum_Fields>;
  var_pop?: Maybe<Parameter_Sub_Key_Var_Pop_Fields>;
  var_samp?: Maybe<Parameter_Sub_Key_Var_Samp_Fields>;
  variance?: Maybe<Parameter_Sub_Key_Variance_Fields>;
};


/** aggregate fields of "parameter_sub_key" */
export type Parameter_Sub_Key_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Parameter_Sub_Key_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "parameter_sub_key" */
export type Parameter_Sub_Key_Aggregate_Order_By = {
  avg?: InputMaybe<Parameter_Sub_Key_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Parameter_Sub_Key_Max_Order_By>;
  min?: InputMaybe<Parameter_Sub_Key_Min_Order_By>;
  stddev?: InputMaybe<Parameter_Sub_Key_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Parameter_Sub_Key_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Parameter_Sub_Key_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Parameter_Sub_Key_Sum_Order_By>;
  var_pop?: InputMaybe<Parameter_Sub_Key_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Parameter_Sub_Key_Var_Samp_Order_By>;
  variance?: InputMaybe<Parameter_Sub_Key_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "parameter_sub_key" */
export type Parameter_Sub_Key_Arr_Rel_Insert_Input = {
  data: Array<Parameter_Sub_Key_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Parameter_Sub_Key_On_Conflict>;
};

/** aggregate avg on columns */
export type Parameter_Sub_Key_Avg_Fields = {
  __typename?: 'parameter_sub_key_avg_fields';
  child_parameter_key_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_parameter_key_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "parameter_sub_key" */
export type Parameter_Sub_Key_Avg_Order_By = {
  child_parameter_key_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_parameter_key_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "parameter_sub_key". All fields are combined with a logical 'AND'. */
export type Parameter_Sub_Key_Bool_Exp = {
  _and?: InputMaybe<Array<Parameter_Sub_Key_Bool_Exp>>;
  _not?: InputMaybe<Parameter_Sub_Key_Bool_Exp>;
  _or?: InputMaybe<Array<Parameter_Sub_Key_Bool_Exp>>;
  child_parameter_key?: InputMaybe<Parameter_Key_Bool_Exp>;
  child_parameter_key_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  parameter_sub_key_bucket_discounts?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Bool_Exp>;
  parent_parameter_key_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "parameter_sub_key_bucket_discount" */
export type Parameter_Sub_Key_Bucket_Discount = {
  __typename?: 'parameter_sub_key_bucket_discount';
  /** An object relationship */
  bucket: Bucket;
  bucket_id: Scalars['bigint'];
  discount?: Maybe<Scalars['Int']>;
  /** An object relationship */
  parameter_sub_key: Parameter_Sub_Key;
  parameter_sub_key_id: Scalars['bigint'];
};

/** aggregated selection of "parameter_sub_key_bucket_discount" */
export type Parameter_Sub_Key_Bucket_Discount_Aggregate = {
  __typename?: 'parameter_sub_key_bucket_discount_aggregate';
  aggregate?: Maybe<Parameter_Sub_Key_Bucket_Discount_Aggregate_Fields>;
  nodes: Array<Parameter_Sub_Key_Bucket_Discount>;
};

/** aggregate fields of "parameter_sub_key_bucket_discount" */
export type Parameter_Sub_Key_Bucket_Discount_Aggregate_Fields = {
  __typename?: 'parameter_sub_key_bucket_discount_aggregate_fields';
  avg?: Maybe<Parameter_Sub_Key_Bucket_Discount_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Parameter_Sub_Key_Bucket_Discount_Max_Fields>;
  min?: Maybe<Parameter_Sub_Key_Bucket_Discount_Min_Fields>;
  stddev?: Maybe<Parameter_Sub_Key_Bucket_Discount_Stddev_Fields>;
  stddev_pop?: Maybe<Parameter_Sub_Key_Bucket_Discount_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Parameter_Sub_Key_Bucket_Discount_Stddev_Samp_Fields>;
  sum?: Maybe<Parameter_Sub_Key_Bucket_Discount_Sum_Fields>;
  var_pop?: Maybe<Parameter_Sub_Key_Bucket_Discount_Var_Pop_Fields>;
  var_samp?: Maybe<Parameter_Sub_Key_Bucket_Discount_Var_Samp_Fields>;
  variance?: Maybe<Parameter_Sub_Key_Bucket_Discount_Variance_Fields>;
};


/** aggregate fields of "parameter_sub_key_bucket_discount" */
export type Parameter_Sub_Key_Bucket_Discount_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Parameter_Sub_Key_Bucket_Discount_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "parameter_sub_key_bucket_discount" */
export type Parameter_Sub_Key_Bucket_Discount_Aggregate_Order_By = {
  avg?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Max_Order_By>;
  min?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Min_Order_By>;
  stddev?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Sum_Order_By>;
  var_pop?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Var_Samp_Order_By>;
  variance?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "parameter_sub_key_bucket_discount" */
export type Parameter_Sub_Key_Bucket_Discount_Arr_Rel_Insert_Input = {
  data: Array<Parameter_Sub_Key_Bucket_Discount_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_On_Conflict>;
};

/** aggregate avg on columns */
export type Parameter_Sub_Key_Bucket_Discount_Avg_Fields = {
  __typename?: 'parameter_sub_key_bucket_discount_avg_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  parameter_sub_key_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "parameter_sub_key_bucket_discount" */
export type Parameter_Sub_Key_Bucket_Discount_Avg_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  parameter_sub_key_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "parameter_sub_key_bucket_discount". All fields are combined with a logical 'AND'. */
export type Parameter_Sub_Key_Bucket_Discount_Bool_Exp = {
  _and?: InputMaybe<Array<Parameter_Sub_Key_Bucket_Discount_Bool_Exp>>;
  _not?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Bool_Exp>;
  _or?: InputMaybe<Array<Parameter_Sub_Key_Bucket_Discount_Bool_Exp>>;
  bucket?: InputMaybe<Bucket_Bool_Exp>;
  bucket_id?: InputMaybe<Bigint_Comparison_Exp>;
  discount?: InputMaybe<Int_Comparison_Exp>;
  parameter_sub_key?: InputMaybe<Parameter_Sub_Key_Bool_Exp>;
  parameter_sub_key_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "parameter_sub_key_bucket_discount" */
export enum Parameter_Sub_Key_Bucket_Discount_Constraint {
  /** unique or primary key constraint */
  ParameterSubKeyBucketDiscountPkey = 'parameter_sub_key_bucket_discount_pkey'
}

/** input type for incrementing numeric columns in table "parameter_sub_key_bucket_discount" */
export type Parameter_Sub_Key_Bucket_Discount_Inc_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  discount?: InputMaybe<Scalars['Int']>;
  parameter_sub_key_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "parameter_sub_key_bucket_discount" */
export type Parameter_Sub_Key_Bucket_Discount_Insert_Input = {
  bucket?: InputMaybe<Bucket_Obj_Rel_Insert_Input>;
  bucket_id?: InputMaybe<Scalars['bigint']>;
  discount?: InputMaybe<Scalars['Int']>;
  parameter_sub_key?: InputMaybe<Parameter_Sub_Key_Obj_Rel_Insert_Input>;
  parameter_sub_key_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Parameter_Sub_Key_Bucket_Discount_Max_Fields = {
  __typename?: 'parameter_sub_key_bucket_discount_max_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  discount?: Maybe<Scalars['Int']>;
  parameter_sub_key_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "parameter_sub_key_bucket_discount" */
export type Parameter_Sub_Key_Bucket_Discount_Max_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  parameter_sub_key_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Parameter_Sub_Key_Bucket_Discount_Min_Fields = {
  __typename?: 'parameter_sub_key_bucket_discount_min_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  discount?: Maybe<Scalars['Int']>;
  parameter_sub_key_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "parameter_sub_key_bucket_discount" */
export type Parameter_Sub_Key_Bucket_Discount_Min_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  parameter_sub_key_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "parameter_sub_key_bucket_discount" */
export type Parameter_Sub_Key_Bucket_Discount_Mutation_Response = {
  __typename?: 'parameter_sub_key_bucket_discount_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Parameter_Sub_Key_Bucket_Discount>;
};

/** on conflict condition type for table "parameter_sub_key_bucket_discount" */
export type Parameter_Sub_Key_Bucket_Discount_On_Conflict = {
  constraint: Parameter_Sub_Key_Bucket_Discount_Constraint;
  update_columns?: Array<Parameter_Sub_Key_Bucket_Discount_Update_Column>;
  where?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Bool_Exp>;
};

/** Ordering options when selecting data from "parameter_sub_key_bucket_discount". */
export type Parameter_Sub_Key_Bucket_Discount_Order_By = {
  bucket?: InputMaybe<Bucket_Order_By>;
  bucket_id?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  parameter_sub_key?: InputMaybe<Parameter_Sub_Key_Order_By>;
  parameter_sub_key_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: parameter_sub_key_bucket_discount */
export type Parameter_Sub_Key_Bucket_Discount_Pk_Columns_Input = {
  bucket_id: Scalars['bigint'];
  parameter_sub_key_id: Scalars['bigint'];
};

/** select columns of table "parameter_sub_key_bucket_discount" */
export enum Parameter_Sub_Key_Bucket_Discount_Select_Column {
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  Discount = 'discount',
  /** column name */
  ParameterSubKeyId = 'parameter_sub_key_id'
}

/** input type for updating data in table "parameter_sub_key_bucket_discount" */
export type Parameter_Sub_Key_Bucket_Discount_Set_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  discount?: InputMaybe<Scalars['Int']>;
  parameter_sub_key_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Parameter_Sub_Key_Bucket_Discount_Stddev_Fields = {
  __typename?: 'parameter_sub_key_bucket_discount_stddev_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  parameter_sub_key_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "parameter_sub_key_bucket_discount" */
export type Parameter_Sub_Key_Bucket_Discount_Stddev_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  parameter_sub_key_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Parameter_Sub_Key_Bucket_Discount_Stddev_Pop_Fields = {
  __typename?: 'parameter_sub_key_bucket_discount_stddev_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  parameter_sub_key_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "parameter_sub_key_bucket_discount" */
export type Parameter_Sub_Key_Bucket_Discount_Stddev_Pop_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  parameter_sub_key_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Parameter_Sub_Key_Bucket_Discount_Stddev_Samp_Fields = {
  __typename?: 'parameter_sub_key_bucket_discount_stddev_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  parameter_sub_key_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "parameter_sub_key_bucket_discount" */
export type Parameter_Sub_Key_Bucket_Discount_Stddev_Samp_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  parameter_sub_key_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Parameter_Sub_Key_Bucket_Discount_Sum_Fields = {
  __typename?: 'parameter_sub_key_bucket_discount_sum_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  discount?: Maybe<Scalars['Int']>;
  parameter_sub_key_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "parameter_sub_key_bucket_discount" */
export type Parameter_Sub_Key_Bucket_Discount_Sum_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  parameter_sub_key_id?: InputMaybe<Order_By>;
};

/** update columns of table "parameter_sub_key_bucket_discount" */
export enum Parameter_Sub_Key_Bucket_Discount_Update_Column {
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  Discount = 'discount',
  /** column name */
  ParameterSubKeyId = 'parameter_sub_key_id'
}

/** aggregate var_pop on columns */
export type Parameter_Sub_Key_Bucket_Discount_Var_Pop_Fields = {
  __typename?: 'parameter_sub_key_bucket_discount_var_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  parameter_sub_key_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "parameter_sub_key_bucket_discount" */
export type Parameter_Sub_Key_Bucket_Discount_Var_Pop_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  parameter_sub_key_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Parameter_Sub_Key_Bucket_Discount_Var_Samp_Fields = {
  __typename?: 'parameter_sub_key_bucket_discount_var_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  parameter_sub_key_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "parameter_sub_key_bucket_discount" */
export type Parameter_Sub_Key_Bucket_Discount_Var_Samp_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  parameter_sub_key_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Parameter_Sub_Key_Bucket_Discount_Variance_Fields = {
  __typename?: 'parameter_sub_key_bucket_discount_variance_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  parameter_sub_key_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "parameter_sub_key_bucket_discount" */
export type Parameter_Sub_Key_Bucket_Discount_Variance_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  discount?: InputMaybe<Order_By>;
  parameter_sub_key_id?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "parameter_sub_key" */
export enum Parameter_Sub_Key_Constraint {
  /** unique or primary key constraint */
  ParameterSubKeyParentChild = 'parameter_sub_key_parent_child',
  /** unique or primary key constraint */
  ParameterSubKeyPkey = 'parameter_sub_key_pkey'
}

/** input type for incrementing numeric columns in table "parameter_sub_key" */
export type Parameter_Sub_Key_Inc_Input = {
  child_parameter_key_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  parent_parameter_key_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "parameter_sub_key" */
export type Parameter_Sub_Key_Insert_Input = {
  child_parameter_key?: InputMaybe<Parameter_Key_Obj_Rel_Insert_Input>;
  child_parameter_key_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  parameter_sub_key_bucket_discounts?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Arr_Rel_Insert_Input>;
  parent_parameter_key_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Parameter_Sub_Key_Max_Fields = {
  __typename?: 'parameter_sub_key_max_fields';
  child_parameter_key_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  parent_parameter_key_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "parameter_sub_key" */
export type Parameter_Sub_Key_Max_Order_By = {
  child_parameter_key_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_parameter_key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Parameter_Sub_Key_Min_Fields = {
  __typename?: 'parameter_sub_key_min_fields';
  child_parameter_key_id?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  parent_parameter_key_id?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "parameter_sub_key" */
export type Parameter_Sub_Key_Min_Order_By = {
  child_parameter_key_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_parameter_key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "parameter_sub_key" */
export type Parameter_Sub_Key_Mutation_Response = {
  __typename?: 'parameter_sub_key_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Parameter_Sub_Key>;
};

/** input type for inserting object relation for remote table "parameter_sub_key" */
export type Parameter_Sub_Key_Obj_Rel_Insert_Input = {
  data: Parameter_Sub_Key_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Parameter_Sub_Key_On_Conflict>;
};

/** on conflict condition type for table "parameter_sub_key" */
export type Parameter_Sub_Key_On_Conflict = {
  constraint: Parameter_Sub_Key_Constraint;
  update_columns?: Array<Parameter_Sub_Key_Update_Column>;
  where?: InputMaybe<Parameter_Sub_Key_Bool_Exp>;
};

/** Ordering options when selecting data from "parameter_sub_key". */
export type Parameter_Sub_Key_Order_By = {
  child_parameter_key?: InputMaybe<Parameter_Key_Order_By>;
  child_parameter_key_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_sub_key_bucket_discounts_aggregate?: InputMaybe<Parameter_Sub_Key_Bucket_Discount_Aggregate_Order_By>;
  parent_parameter_key_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: parameter_sub_key */
export type Parameter_Sub_Key_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "parameter_sub_key" */
export enum Parameter_Sub_Key_Select_Column {
  /** column name */
  ChildParameterKeyId = 'child_parameter_key_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ParentParameterKeyId = 'parent_parameter_key_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "parameter_sub_key" */
export type Parameter_Sub_Key_Set_Input = {
  child_parameter_key_id?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  parent_parameter_key_id?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Parameter_Sub_Key_Stddev_Fields = {
  __typename?: 'parameter_sub_key_stddev_fields';
  child_parameter_key_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_parameter_key_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "parameter_sub_key" */
export type Parameter_Sub_Key_Stddev_Order_By = {
  child_parameter_key_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_parameter_key_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Parameter_Sub_Key_Stddev_Pop_Fields = {
  __typename?: 'parameter_sub_key_stddev_pop_fields';
  child_parameter_key_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_parameter_key_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "parameter_sub_key" */
export type Parameter_Sub_Key_Stddev_Pop_Order_By = {
  child_parameter_key_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_parameter_key_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Parameter_Sub_Key_Stddev_Samp_Fields = {
  __typename?: 'parameter_sub_key_stddev_samp_fields';
  child_parameter_key_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_parameter_key_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "parameter_sub_key" */
export type Parameter_Sub_Key_Stddev_Samp_Order_By = {
  child_parameter_key_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_parameter_key_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Parameter_Sub_Key_Sum_Fields = {
  __typename?: 'parameter_sub_key_sum_fields';
  child_parameter_key_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  parent_parameter_key_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "parameter_sub_key" */
export type Parameter_Sub_Key_Sum_Order_By = {
  child_parameter_key_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_parameter_key_id?: InputMaybe<Order_By>;
};

/** update columns of table "parameter_sub_key" */
export enum Parameter_Sub_Key_Update_Column {
  /** column name */
  ChildParameterKeyId = 'child_parameter_key_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ParentParameterKeyId = 'parent_parameter_key_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Parameter_Sub_Key_Var_Pop_Fields = {
  __typename?: 'parameter_sub_key_var_pop_fields';
  child_parameter_key_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_parameter_key_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "parameter_sub_key" */
export type Parameter_Sub_Key_Var_Pop_Order_By = {
  child_parameter_key_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_parameter_key_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Parameter_Sub_Key_Var_Samp_Fields = {
  __typename?: 'parameter_sub_key_var_samp_fields';
  child_parameter_key_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_parameter_key_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "parameter_sub_key" */
export type Parameter_Sub_Key_Var_Samp_Order_By = {
  child_parameter_key_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_parameter_key_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Parameter_Sub_Key_Variance_Fields = {
  __typename?: 'parameter_sub_key_variance_fields';
  child_parameter_key_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parent_parameter_key_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "parameter_sub_key" */
export type Parameter_Sub_Key_Variance_Order_By = {
  child_parameter_key_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parent_parameter_key_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "parameter_subtype" */
export type Parameter_Subtype = {
  __typename?: 'parameter_subtype';
  control_type: Scalars['String'];
  id: Scalars['bigint'];
  is_global_unique: Scalars['Boolean'];
  is_identifier: Scalars['Boolean'];
  is_unique: Scalars['Boolean'];
  /** An array relationship */
  parameter_subtype_options: Array<Parameter_Subtype_Options>;
  /** An aggregate relationship */
  parameter_subtype_options_aggregate: Parameter_Subtype_Options_Aggregate;
  /** An object relationship */
  parameter_type: Parameter_Type;
  parameter_type_id: Scalars['bigint'];
  sort_order: Scalars['Int'];
  subtype: Scalars['String'];
  visible: Scalars['Boolean'];
};


/** columns and relationships of "parameter_subtype" */
export type Parameter_SubtypeParameter_Subtype_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Subtype_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Subtype_Options_Order_By>>;
  where?: InputMaybe<Parameter_Subtype_Options_Bool_Exp>;
};


/** columns and relationships of "parameter_subtype" */
export type Parameter_SubtypeParameter_Subtype_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Subtype_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Subtype_Options_Order_By>>;
  where?: InputMaybe<Parameter_Subtype_Options_Bool_Exp>;
};

/** aggregated selection of "parameter_subtype" */
export type Parameter_Subtype_Aggregate = {
  __typename?: 'parameter_subtype_aggregate';
  aggregate?: Maybe<Parameter_Subtype_Aggregate_Fields>;
  nodes: Array<Parameter_Subtype>;
};

/** aggregate fields of "parameter_subtype" */
export type Parameter_Subtype_Aggregate_Fields = {
  __typename?: 'parameter_subtype_aggregate_fields';
  avg?: Maybe<Parameter_Subtype_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Parameter_Subtype_Max_Fields>;
  min?: Maybe<Parameter_Subtype_Min_Fields>;
  stddev?: Maybe<Parameter_Subtype_Stddev_Fields>;
  stddev_pop?: Maybe<Parameter_Subtype_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Parameter_Subtype_Stddev_Samp_Fields>;
  sum?: Maybe<Parameter_Subtype_Sum_Fields>;
  var_pop?: Maybe<Parameter_Subtype_Var_Pop_Fields>;
  var_samp?: Maybe<Parameter_Subtype_Var_Samp_Fields>;
  variance?: Maybe<Parameter_Subtype_Variance_Fields>;
};


/** aggregate fields of "parameter_subtype" */
export type Parameter_Subtype_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Parameter_Subtype_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "parameter_subtype" */
export type Parameter_Subtype_Aggregate_Order_By = {
  avg?: InputMaybe<Parameter_Subtype_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Parameter_Subtype_Max_Order_By>;
  min?: InputMaybe<Parameter_Subtype_Min_Order_By>;
  stddev?: InputMaybe<Parameter_Subtype_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Parameter_Subtype_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Parameter_Subtype_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Parameter_Subtype_Sum_Order_By>;
  var_pop?: InputMaybe<Parameter_Subtype_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Parameter_Subtype_Var_Samp_Order_By>;
  variance?: InputMaybe<Parameter_Subtype_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "parameter_subtype" */
export type Parameter_Subtype_Arr_Rel_Insert_Input = {
  data: Array<Parameter_Subtype_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Parameter_Subtype_On_Conflict>;
};

/** aggregate avg on columns */
export type Parameter_Subtype_Avg_Fields = {
  __typename?: 'parameter_subtype_avg_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_type_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "parameter_subtype" */
export type Parameter_Subtype_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_type_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "parameter_subtype". All fields are combined with a logical 'AND'. */
export type Parameter_Subtype_Bool_Exp = {
  _and?: InputMaybe<Array<Parameter_Subtype_Bool_Exp>>;
  _not?: InputMaybe<Parameter_Subtype_Bool_Exp>;
  _or?: InputMaybe<Array<Parameter_Subtype_Bool_Exp>>;
  control_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_global_unique?: InputMaybe<Boolean_Comparison_Exp>;
  is_identifier?: InputMaybe<Boolean_Comparison_Exp>;
  is_unique?: InputMaybe<Boolean_Comparison_Exp>;
  parameter_subtype_options?: InputMaybe<Parameter_Subtype_Options_Bool_Exp>;
  parameter_type?: InputMaybe<Parameter_Type_Bool_Exp>;
  parameter_type_id?: InputMaybe<Bigint_Comparison_Exp>;
  sort_order?: InputMaybe<Int_Comparison_Exp>;
  subtype?: InputMaybe<String_Comparison_Exp>;
  visible?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "parameter_subtype" */
export enum Parameter_Subtype_Constraint {
  /** unique or primary key constraint */
  ParameterSubtypePkey = 'parameter_subtype_pkey'
}

/** input type for incrementing numeric columns in table "parameter_subtype" */
export type Parameter_Subtype_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  parameter_type_id?: InputMaybe<Scalars['bigint']>;
  sort_order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "parameter_subtype" */
export type Parameter_Subtype_Insert_Input = {
  control_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_global_unique?: InputMaybe<Scalars['Boolean']>;
  is_identifier?: InputMaybe<Scalars['Boolean']>;
  is_unique?: InputMaybe<Scalars['Boolean']>;
  parameter_subtype_options?: InputMaybe<Parameter_Subtype_Options_Arr_Rel_Insert_Input>;
  parameter_type?: InputMaybe<Parameter_Type_Obj_Rel_Insert_Input>;
  parameter_type_id?: InputMaybe<Scalars['bigint']>;
  sort_order?: InputMaybe<Scalars['Int']>;
  subtype?: InputMaybe<Scalars['String']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Parameter_Subtype_Max_Fields = {
  __typename?: 'parameter_subtype_max_fields';
  control_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  parameter_type_id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  subtype?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "parameter_subtype" */
export type Parameter_Subtype_Max_Order_By = {
  control_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_type_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
  subtype?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Parameter_Subtype_Min_Fields = {
  __typename?: 'parameter_subtype_min_fields';
  control_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  parameter_type_id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
  subtype?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "parameter_subtype" */
export type Parameter_Subtype_Min_Order_By = {
  control_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_type_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
  subtype?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "parameter_subtype" */
export type Parameter_Subtype_Mutation_Response = {
  __typename?: 'parameter_subtype_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Parameter_Subtype>;
};

/** input type for inserting object relation for remote table "parameter_subtype" */
export type Parameter_Subtype_Obj_Rel_Insert_Input = {
  data: Parameter_Subtype_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Parameter_Subtype_On_Conflict>;
};

/** on conflict condition type for table "parameter_subtype" */
export type Parameter_Subtype_On_Conflict = {
  constraint: Parameter_Subtype_Constraint;
  update_columns?: Array<Parameter_Subtype_Update_Column>;
  where?: InputMaybe<Parameter_Subtype_Bool_Exp>;
};

/** columns and relationships of "parameter_subtype_options" */
export type Parameter_Subtype_Options = {
  __typename?: 'parameter_subtype_options';
  id: Scalars['bigint'];
  name: Scalars['String'];
  parameter_subtype_id: Scalars['bigint'];
  sort_order: Scalars['Int'];
};

/** aggregated selection of "parameter_subtype_options" */
export type Parameter_Subtype_Options_Aggregate = {
  __typename?: 'parameter_subtype_options_aggregate';
  aggregate?: Maybe<Parameter_Subtype_Options_Aggregate_Fields>;
  nodes: Array<Parameter_Subtype_Options>;
};

/** aggregate fields of "parameter_subtype_options" */
export type Parameter_Subtype_Options_Aggregate_Fields = {
  __typename?: 'parameter_subtype_options_aggregate_fields';
  avg?: Maybe<Parameter_Subtype_Options_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Parameter_Subtype_Options_Max_Fields>;
  min?: Maybe<Parameter_Subtype_Options_Min_Fields>;
  stddev?: Maybe<Parameter_Subtype_Options_Stddev_Fields>;
  stddev_pop?: Maybe<Parameter_Subtype_Options_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Parameter_Subtype_Options_Stddev_Samp_Fields>;
  sum?: Maybe<Parameter_Subtype_Options_Sum_Fields>;
  var_pop?: Maybe<Parameter_Subtype_Options_Var_Pop_Fields>;
  var_samp?: Maybe<Parameter_Subtype_Options_Var_Samp_Fields>;
  variance?: Maybe<Parameter_Subtype_Options_Variance_Fields>;
};


/** aggregate fields of "parameter_subtype_options" */
export type Parameter_Subtype_Options_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Parameter_Subtype_Options_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "parameter_subtype_options" */
export type Parameter_Subtype_Options_Aggregate_Order_By = {
  avg?: InputMaybe<Parameter_Subtype_Options_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Parameter_Subtype_Options_Max_Order_By>;
  min?: InputMaybe<Parameter_Subtype_Options_Min_Order_By>;
  stddev?: InputMaybe<Parameter_Subtype_Options_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Parameter_Subtype_Options_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Parameter_Subtype_Options_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Parameter_Subtype_Options_Sum_Order_By>;
  var_pop?: InputMaybe<Parameter_Subtype_Options_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Parameter_Subtype_Options_Var_Samp_Order_By>;
  variance?: InputMaybe<Parameter_Subtype_Options_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "parameter_subtype_options" */
export type Parameter_Subtype_Options_Arr_Rel_Insert_Input = {
  data: Array<Parameter_Subtype_Options_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Parameter_Subtype_Options_On_Conflict>;
};

/** aggregate avg on columns */
export type Parameter_Subtype_Options_Avg_Fields = {
  __typename?: 'parameter_subtype_options_avg_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_subtype_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "parameter_subtype_options" */
export type Parameter_Subtype_Options_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_subtype_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "parameter_subtype_options". All fields are combined with a logical 'AND'. */
export type Parameter_Subtype_Options_Bool_Exp = {
  _and?: InputMaybe<Array<Parameter_Subtype_Options_Bool_Exp>>;
  _not?: InputMaybe<Parameter_Subtype_Options_Bool_Exp>;
  _or?: InputMaybe<Array<Parameter_Subtype_Options_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  parameter_subtype_id?: InputMaybe<Bigint_Comparison_Exp>;
  sort_order?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "parameter_subtype_options" */
export enum Parameter_Subtype_Options_Constraint {
  /** unique or primary key constraint */
  ParameterSubtypeOptionsPkey = 'parameter_subtype_options_pkey'
}

/** input type for incrementing numeric columns in table "parameter_subtype_options" */
export type Parameter_Subtype_Options_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  parameter_subtype_id?: InputMaybe<Scalars['bigint']>;
  sort_order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "parameter_subtype_options" */
export type Parameter_Subtype_Options_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  parameter_subtype_id?: InputMaybe<Scalars['bigint']>;
  sort_order?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Parameter_Subtype_Options_Max_Fields = {
  __typename?: 'parameter_subtype_options_max_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  parameter_subtype_id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "parameter_subtype_options" */
export type Parameter_Subtype_Options_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parameter_subtype_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Parameter_Subtype_Options_Min_Fields = {
  __typename?: 'parameter_subtype_options_min_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  parameter_subtype_id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "parameter_subtype_options" */
export type Parameter_Subtype_Options_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parameter_subtype_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "parameter_subtype_options" */
export type Parameter_Subtype_Options_Mutation_Response = {
  __typename?: 'parameter_subtype_options_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Parameter_Subtype_Options>;
};

/** on conflict condition type for table "parameter_subtype_options" */
export type Parameter_Subtype_Options_On_Conflict = {
  constraint: Parameter_Subtype_Options_Constraint;
  update_columns?: Array<Parameter_Subtype_Options_Update_Column>;
  where?: InputMaybe<Parameter_Subtype_Options_Bool_Exp>;
};

/** Ordering options when selecting data from "parameter_subtype_options". */
export type Parameter_Subtype_Options_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parameter_subtype_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** primary key columns input for table: parameter_subtype_options */
export type Parameter_Subtype_Options_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "parameter_subtype_options" */
export enum Parameter_Subtype_Options_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParameterSubtypeId = 'parameter_subtype_id',
  /** column name */
  SortOrder = 'sort_order'
}

/** input type for updating data in table "parameter_subtype_options" */
export type Parameter_Subtype_Options_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  parameter_subtype_id?: InputMaybe<Scalars['bigint']>;
  sort_order?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Parameter_Subtype_Options_Stddev_Fields = {
  __typename?: 'parameter_subtype_options_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_subtype_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "parameter_subtype_options" */
export type Parameter_Subtype_Options_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_subtype_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Parameter_Subtype_Options_Stddev_Pop_Fields = {
  __typename?: 'parameter_subtype_options_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_subtype_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "parameter_subtype_options" */
export type Parameter_Subtype_Options_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_subtype_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Parameter_Subtype_Options_Stddev_Samp_Fields = {
  __typename?: 'parameter_subtype_options_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_subtype_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "parameter_subtype_options" */
export type Parameter_Subtype_Options_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_subtype_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Parameter_Subtype_Options_Sum_Fields = {
  __typename?: 'parameter_subtype_options_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  parameter_subtype_id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "parameter_subtype_options" */
export type Parameter_Subtype_Options_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_subtype_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** update columns of table "parameter_subtype_options" */
export enum Parameter_Subtype_Options_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParameterSubtypeId = 'parameter_subtype_id',
  /** column name */
  SortOrder = 'sort_order'
}

/** aggregate var_pop on columns */
export type Parameter_Subtype_Options_Var_Pop_Fields = {
  __typename?: 'parameter_subtype_options_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_subtype_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "parameter_subtype_options" */
export type Parameter_Subtype_Options_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_subtype_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Parameter_Subtype_Options_Var_Samp_Fields = {
  __typename?: 'parameter_subtype_options_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_subtype_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "parameter_subtype_options" */
export type Parameter_Subtype_Options_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_subtype_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Parameter_Subtype_Options_Variance_Fields = {
  __typename?: 'parameter_subtype_options_variance_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_subtype_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "parameter_subtype_options" */
export type Parameter_Subtype_Options_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_subtype_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "parameter_subtype". */
export type Parameter_Subtype_Order_By = {
  control_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_global_unique?: InputMaybe<Order_By>;
  is_identifier?: InputMaybe<Order_By>;
  is_unique?: InputMaybe<Order_By>;
  parameter_subtype_options_aggregate?: InputMaybe<Parameter_Subtype_Options_Aggregate_Order_By>;
  parameter_type?: InputMaybe<Parameter_Type_Order_By>;
  parameter_type_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
  subtype?: InputMaybe<Order_By>;
  visible?: InputMaybe<Order_By>;
};

/** primary key columns input for table: parameter_subtype */
export type Parameter_Subtype_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "parameter_subtype" */
export enum Parameter_Subtype_Select_Column {
  /** column name */
  ControlType = 'control_type',
  /** column name */
  Id = 'id',
  /** column name */
  IsGlobalUnique = 'is_global_unique',
  /** column name */
  IsIdentifier = 'is_identifier',
  /** column name */
  IsUnique = 'is_unique',
  /** column name */
  ParameterTypeId = 'parameter_type_id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Subtype = 'subtype',
  /** column name */
  Visible = 'visible'
}

/** input type for updating data in table "parameter_subtype" */
export type Parameter_Subtype_Set_Input = {
  control_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_global_unique?: InputMaybe<Scalars['Boolean']>;
  is_identifier?: InputMaybe<Scalars['Boolean']>;
  is_unique?: InputMaybe<Scalars['Boolean']>;
  parameter_type_id?: InputMaybe<Scalars['bigint']>;
  sort_order?: InputMaybe<Scalars['Int']>;
  subtype?: InputMaybe<Scalars['String']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Parameter_Subtype_Stddev_Fields = {
  __typename?: 'parameter_subtype_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_type_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "parameter_subtype" */
export type Parameter_Subtype_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_type_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Parameter_Subtype_Stddev_Pop_Fields = {
  __typename?: 'parameter_subtype_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_type_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "parameter_subtype" */
export type Parameter_Subtype_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_type_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Parameter_Subtype_Stddev_Samp_Fields = {
  __typename?: 'parameter_subtype_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_type_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "parameter_subtype" */
export type Parameter_Subtype_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_type_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Parameter_Subtype_Sum_Fields = {
  __typename?: 'parameter_subtype_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  parameter_type_id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "parameter_subtype" */
export type Parameter_Subtype_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_type_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** update columns of table "parameter_subtype" */
export enum Parameter_Subtype_Update_Column {
  /** column name */
  ControlType = 'control_type',
  /** column name */
  Id = 'id',
  /** column name */
  IsGlobalUnique = 'is_global_unique',
  /** column name */
  IsIdentifier = 'is_identifier',
  /** column name */
  IsUnique = 'is_unique',
  /** column name */
  ParameterTypeId = 'parameter_type_id',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Subtype = 'subtype',
  /** column name */
  Visible = 'visible'
}

/** aggregate var_pop on columns */
export type Parameter_Subtype_Var_Pop_Fields = {
  __typename?: 'parameter_subtype_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_type_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "parameter_subtype" */
export type Parameter_Subtype_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_type_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Parameter_Subtype_Var_Samp_Fields = {
  __typename?: 'parameter_subtype_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_type_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "parameter_subtype" */
export type Parameter_Subtype_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_type_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Parameter_Subtype_Variance_Fields = {
  __typename?: 'parameter_subtype_variance_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_type_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "parameter_subtype" */
export type Parameter_Subtype_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_type_id?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Parameter_Sum_Fields = {
  __typename?: 'parameter_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  parameter_type_id?: Maybe<Scalars['bigint']>;
};

/** columns and relationships of "parameter_type" */
export type Parameter_Type = {
  __typename?: 'parameter_type';
  bundleable: Scalars['Boolean'];
  description: Scalars['String'];
  id: Scalars['bigint'];
  /** An array relationship */
  parameter_subtypes: Array<Parameter_Subtype>;
  /** An aggregate relationship */
  parameter_subtypes_aggregate: Parameter_Subtype_Aggregate;
  type: Scalars['String'];
};


/** columns and relationships of "parameter_type" */
export type Parameter_TypeParameter_SubtypesArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Subtype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Subtype_Order_By>>;
  where?: InputMaybe<Parameter_Subtype_Bool_Exp>;
};


/** columns and relationships of "parameter_type" */
export type Parameter_TypeParameter_Subtypes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Subtype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Subtype_Order_By>>;
  where?: InputMaybe<Parameter_Subtype_Bool_Exp>;
};

/** aggregated selection of "parameter_type" */
export type Parameter_Type_Aggregate = {
  __typename?: 'parameter_type_aggregate';
  aggregate?: Maybe<Parameter_Type_Aggregate_Fields>;
  nodes: Array<Parameter_Type>;
};

/** aggregate fields of "parameter_type" */
export type Parameter_Type_Aggregate_Fields = {
  __typename?: 'parameter_type_aggregate_fields';
  avg?: Maybe<Parameter_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Parameter_Type_Max_Fields>;
  min?: Maybe<Parameter_Type_Min_Fields>;
  stddev?: Maybe<Parameter_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Parameter_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Parameter_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Parameter_Type_Sum_Fields>;
  var_pop?: Maybe<Parameter_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Parameter_Type_Var_Samp_Fields>;
  variance?: Maybe<Parameter_Type_Variance_Fields>;
};


/** aggregate fields of "parameter_type" */
export type Parameter_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Parameter_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Parameter_Type_Avg_Fields = {
  __typename?: 'parameter_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "parameter_type". All fields are combined with a logical 'AND'. */
export type Parameter_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Parameter_Type_Bool_Exp>>;
  _not?: InputMaybe<Parameter_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Parameter_Type_Bool_Exp>>;
  bundleable?: InputMaybe<Boolean_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  parameter_subtypes?: InputMaybe<Parameter_Subtype_Bool_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "parameter_type" */
export enum Parameter_Type_Constraint {
  /** unique or primary key constraint */
  ParametertypeKeyKey = 'parametertype_key_key',
  /** unique or primary key constraint */
  ParametertypePkey = 'parametertype_pkey'
}

/** input type for incrementing numeric columns in table "parameter_type" */
export type Parameter_Type_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "parameter_type" */
export type Parameter_Type_Insert_Input = {
  bundleable?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  parameter_subtypes?: InputMaybe<Parameter_Subtype_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Parameter_Type_Max_Fields = {
  __typename?: 'parameter_type_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Parameter_Type_Min_Fields = {
  __typename?: 'parameter_type_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "parameter_type" */
export type Parameter_Type_Mutation_Response = {
  __typename?: 'parameter_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Parameter_Type>;
};

/** input type for inserting object relation for remote table "parameter_type" */
export type Parameter_Type_Obj_Rel_Insert_Input = {
  data: Parameter_Type_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Parameter_Type_On_Conflict>;
};

/** on conflict condition type for table "parameter_type" */
export type Parameter_Type_On_Conflict = {
  constraint: Parameter_Type_Constraint;
  update_columns?: Array<Parameter_Type_Update_Column>;
  where?: InputMaybe<Parameter_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "parameter_type". */
export type Parameter_Type_Order_By = {
  bundleable?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_subtypes_aggregate?: InputMaybe<Parameter_Subtype_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: parameter_type */
export type Parameter_Type_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "parameter_type" */
export enum Parameter_Type_Select_Column {
  /** column name */
  Bundleable = 'bundleable',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "parameter_type" */
export type Parameter_Type_Set_Input = {
  bundleable?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Parameter_Type_Stddev_Fields = {
  __typename?: 'parameter_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Parameter_Type_Stddev_Pop_Fields = {
  __typename?: 'parameter_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Parameter_Type_Stddev_Samp_Fields = {
  __typename?: 'parameter_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Parameter_Type_Sum_Fields = {
  __typename?: 'parameter_type_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "parameter_type" */
export enum Parameter_Type_Update_Column {
  /** column name */
  Bundleable = 'bundleable',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Parameter_Type_Var_Pop_Fields = {
  __typename?: 'parameter_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Parameter_Type_Var_Samp_Fields = {
  __typename?: 'parameter_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Parameter_Type_Variance_Fields = {
  __typename?: 'parameter_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** update columns of table "parameter" */
export enum Parameter_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParameterTypeId = 'parameter_type_id'
}

/** aggregate var_pop on columns */
export type Parameter_Var_Pop_Fields = {
  __typename?: 'parameter_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_type_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Parameter_Var_Samp_Fields = {
  __typename?: 'parameter_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_type_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Parameter_Variance_Fields = {
  __typename?: 'parameter_variance_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_type_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "payee" */
export type Payee = {
  __typename?: 'payee';
  company: Scalars['String'];
  id: Scalars['bigint'];
  payee_code: Scalars['String'];
  program_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "payee" */
export type Payee_Aggregate = {
  __typename?: 'payee_aggregate';
  aggregate?: Maybe<Payee_Aggregate_Fields>;
  nodes: Array<Payee>;
};

/** aggregate fields of "payee" */
export type Payee_Aggregate_Fields = {
  __typename?: 'payee_aggregate_fields';
  avg?: Maybe<Payee_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payee_Max_Fields>;
  min?: Maybe<Payee_Min_Fields>;
  stddev?: Maybe<Payee_Stddev_Fields>;
  stddev_pop?: Maybe<Payee_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payee_Stddev_Samp_Fields>;
  sum?: Maybe<Payee_Sum_Fields>;
  var_pop?: Maybe<Payee_Var_Pop_Fields>;
  var_samp?: Maybe<Payee_Var_Samp_Fields>;
  variance?: Maybe<Payee_Variance_Fields>;
};


/** aggregate fields of "payee" */
export type Payee_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Payee_Avg_Fields = {
  __typename?: 'payee_avg_fields';
  id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "payee". All fields are combined with a logical 'AND'. */
export type Payee_Bool_Exp = {
  _and?: InputMaybe<Array<Payee_Bool_Exp>>;
  _not?: InputMaybe<Payee_Bool_Exp>;
  _or?: InputMaybe<Array<Payee_Bool_Exp>>;
  company?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  payee_code?: InputMaybe<String_Comparison_Exp>;
  program_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "payee" */
export enum Payee_Constraint {
  /** unique or primary key constraint */
  PayeePayeeCodeKey = 'payee_payee_code_key',
  /** unique or primary key constraint */
  PayeePkey = 'payee_pkey'
}

/** input type for incrementing numeric columns in table "payee" */
export type Payee_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  program_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "payee" */
export type Payee_Insert_Input = {
  company?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  payee_code?: InputMaybe<Scalars['String']>;
  program_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Payee_Max_Fields = {
  __typename?: 'payee_max_fields';
  company?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  payee_code?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Payee_Min_Fields = {
  __typename?: 'payee_min_fields';
  company?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  payee_code?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "payee" */
export type Payee_Mutation_Response = {
  __typename?: 'payee_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payee>;
};

/** input type for inserting object relation for remote table "payee" */
export type Payee_Obj_Rel_Insert_Input = {
  data: Payee_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Payee_On_Conflict>;
};

/** on conflict condition type for table "payee" */
export type Payee_On_Conflict = {
  constraint: Payee_Constraint;
  update_columns?: Array<Payee_Update_Column>;
  where?: InputMaybe<Payee_Bool_Exp>;
};

/** Ordering options when selecting data from "payee". */
export type Payee_Order_By = {
  company?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payee_code?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payee */
export type Payee_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "payee" */
export enum Payee_Select_Column {
  /** column name */
  Company = 'company',
  /** column name */
  Id = 'id',
  /** column name */
  PayeeCode = 'payee_code',
  /** column name */
  ProgramId = 'program_id'
}

/** input type for updating data in table "payee" */
export type Payee_Set_Input = {
  company?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  payee_code?: InputMaybe<Scalars['String']>;
  program_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Payee_Stddev_Fields = {
  __typename?: 'payee_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Payee_Stddev_Pop_Fields = {
  __typename?: 'payee_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Payee_Stddev_Samp_Fields = {
  __typename?: 'payee_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Payee_Sum_Fields = {
  __typename?: 'payee_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  program_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "payee" */
export enum Payee_Update_Column {
  /** column name */
  Company = 'company',
  /** column name */
  Id = 'id',
  /** column name */
  PayeeCode = 'payee_code',
  /** column name */
  ProgramId = 'program_id'
}

/** aggregate var_pop on columns */
export type Payee_Var_Pop_Fields = {
  __typename?: 'payee_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Payee_Var_Samp_Fields = {
  __typename?: 'payee_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Payee_Variance_Fields = {
  __typename?: 'payee_variance_fields';
  id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "product" */
export type Product = {
  __typename?: 'product';
  /** An array relationship */
  buckets: Array<Bucket>;
  /** An aggregate relationship */
  buckets_aggregate: Bucket_Aggregate;
  code: Scalars['String'];
  /** An object relationship */
  dealer_rounding?: Maybe<Product_Dealer_Offset>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  major_version: Scalars['Int'];
  /** An array relationship */
  msrp_parameter_key_combinations: Array<Product_Msrp_Parameter_Key_Combination>;
  /** An aggregate relationship */
  msrp_parameter_key_combinations_aggregate: Product_Msrp_Parameter_Key_Combination_Aggregate;
  /** An array relationship */
  msrp_parameters: Array<Product_Msrp_Parameter>;
  /** An aggregate relationship */
  msrp_parameters_aggregate: Product_Msrp_Parameter_Aggregate;
  name: Scalars['String'];
  /** An array relationship */
  non_sellable_combinations: Array<Non_Sellable_Combination>;
  /** An aggregate relationship */
  non_sellable_combinations_aggregate: Non_Sellable_Combination_Aggregate;
  /** An array relationship */
  parameter_keys: Array<Parameter_Key>;
  /** An aggregate relationship */
  parameter_keys_aggregate: Parameter_Key_Aggregate;
  /** An object relationship */
  product_type: Product_Type;
  product_type_id: Scalars['bigint'];
  /** An object relationship */
  program: Program;
  program_id: Scalars['bigint'];
  risk_type?: Maybe<Scalars['String']>;
};


/** columns and relationships of "product" */
export type ProductBucketsArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Order_By>>;
  where?: InputMaybe<Bucket_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductBuckets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bucket_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bucket_Order_By>>;
  where?: InputMaybe<Bucket_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductMsrp_Parameter_Key_CombinationsArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Parameter_Key_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Parameter_Key_Combination_Order_By>>;
  where?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductMsrp_Parameter_Key_Combinations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Parameter_Key_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Parameter_Key_Combination_Order_By>>;
  where?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductMsrp_ParametersArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Parameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Parameter_Order_By>>;
  where?: InputMaybe<Product_Msrp_Parameter_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductMsrp_Parameters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Parameter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Parameter_Order_By>>;
  where?: InputMaybe<Product_Msrp_Parameter_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductNon_Sellable_CombinationsArgs = {
  distinct_on?: InputMaybe<Array<Non_Sellable_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Non_Sellable_Combination_Order_By>>;
  where?: InputMaybe<Non_Sellable_Combination_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductNon_Sellable_Combinations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Non_Sellable_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Non_Sellable_Combination_Order_By>>;
  where?: InputMaybe<Non_Sellable_Combination_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductParameter_KeysArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Key_Order_By>>;
  where?: InputMaybe<Parameter_Key_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductParameter_Keys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Parameter_Key_Order_By>>;
  where?: InputMaybe<Parameter_Key_Bool_Exp>;
};

/** aggregated selection of "product" */
export type Product_Aggregate = {
  __typename?: 'product_aggregate';
  aggregate?: Maybe<Product_Aggregate_Fields>;
  nodes: Array<Product>;
};

/** aggregate fields of "product" */
export type Product_Aggregate_Fields = {
  __typename?: 'product_aggregate_fields';
  avg?: Maybe<Product_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Max_Fields>;
  min?: Maybe<Product_Min_Fields>;
  stddev?: Maybe<Product_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Sum_Fields>;
  var_pop?: Maybe<Product_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Var_Samp_Fields>;
  variance?: Maybe<Product_Variance_Fields>;
};


/** aggregate fields of "product" */
export type Product_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product" */
export type Product_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Max_Order_By>;
  min?: InputMaybe<Product_Min_Order_By>;
  stddev?: InputMaybe<Product_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product" */
export type Product_Arr_Rel_Insert_Input = {
  data: Array<Product_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Product_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Avg_Fields = {
  __typename?: 'product_avg_fields';
  id?: Maybe<Scalars['Float']>;
  major_version?: Maybe<Scalars['Float']>;
  product_type_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product" */
export type Product_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  major_version?: InputMaybe<Order_By>;
  product_type_id?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product". All fields are combined with a logical 'AND'. */
export type Product_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Bool_Exp>>;
  _not?: InputMaybe<Product_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Bool_Exp>>;
  buckets?: InputMaybe<Bucket_Bool_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  dealer_rounding?: InputMaybe<Product_Dealer_Offset_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  major_version?: InputMaybe<Int_Comparison_Exp>;
  msrp_parameter_key_combinations?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Bool_Exp>;
  msrp_parameters?: InputMaybe<Product_Msrp_Parameter_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  non_sellable_combinations?: InputMaybe<Non_Sellable_Combination_Bool_Exp>;
  parameter_keys?: InputMaybe<Parameter_Key_Bool_Exp>;
  product_type?: InputMaybe<Product_Type_Bool_Exp>;
  product_type_id?: InputMaybe<Bigint_Comparison_Exp>;
  program?: InputMaybe<Program_Bool_Exp>;
  program_id?: InputMaybe<Bigint_Comparison_Exp>;
  risk_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product" */
export enum Product_Constraint {
  /** unique or primary key constraint */
  ProductCodeKey = 'product_code_key',
  /** unique or primary key constraint */
  ProductPkey = 'product_pkey'
}

/** columns and relationships of "vw_dealer_cost" */
export type Product_Dealer_Cost = {
  __typename?: 'product_dealer_cost';
  product_id?: Maybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
  rounding_value?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "vw_dealer_cost" */
export type Product_Dealer_Cost_Aggregate = {
  __typename?: 'product_dealer_cost_aggregate';
  aggregate?: Maybe<Product_Dealer_Cost_Aggregate_Fields>;
  nodes: Array<Product_Dealer_Cost>;
};

/** aggregate fields of "vw_dealer_cost" */
export type Product_Dealer_Cost_Aggregate_Fields = {
  __typename?: 'product_dealer_cost_aggregate_fields';
  avg?: Maybe<Product_Dealer_Cost_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Dealer_Cost_Max_Fields>;
  min?: Maybe<Product_Dealer_Cost_Min_Fields>;
  stddev?: Maybe<Product_Dealer_Cost_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Dealer_Cost_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Dealer_Cost_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Dealer_Cost_Sum_Fields>;
  var_pop?: Maybe<Product_Dealer_Cost_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Dealer_Cost_Var_Samp_Fields>;
  variance?: Maybe<Product_Dealer_Cost_Variance_Fields>;
};


/** aggregate fields of "vw_dealer_cost" */
export type Product_Dealer_Cost_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Dealer_Cost_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Product_Dealer_Cost_Avg_Fields = {
  __typename?: 'product_dealer_cost_avg_fields';
  product_id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  rounding_value?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "vw_dealer_cost". All fields are combined with a logical 'AND'. */
export type Product_Dealer_Cost_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Dealer_Cost_Bool_Exp>>;
  _not?: InputMaybe<Product_Dealer_Cost_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Dealer_Cost_Bool_Exp>>;
  product_id?: InputMaybe<Bigint_Comparison_Exp>;
  product_parameter_key_combination_id?: InputMaybe<Bigint_Comparison_Exp>;
  rounding_value?: InputMaybe<Numeric_Comparison_Exp>;
  total?: InputMaybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Product_Dealer_Cost_Max_Fields = {
  __typename?: 'product_dealer_cost_max_fields';
  product_id?: Maybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
  rounding_value?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Product_Dealer_Cost_Min_Fields = {
  __typename?: 'product_dealer_cost_min_fields';
  product_id?: Maybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
  rounding_value?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
};

/** Ordering options when selecting data from "vw_dealer_cost". */
export type Product_Dealer_Cost_Order_By = {
  product_id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
  rounding_value?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** select columns of table "vw_dealer_cost" */
export enum Product_Dealer_Cost_Select_Column {
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductParameterKeyCombinationId = 'product_parameter_key_combination_id',
  /** column name */
  RoundingValue = 'rounding_value',
  /** column name */
  Total = 'total'
}

/** aggregate stddev on columns */
export type Product_Dealer_Cost_Stddev_Fields = {
  __typename?: 'product_dealer_cost_stddev_fields';
  product_id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  rounding_value?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Product_Dealer_Cost_Stddev_Pop_Fields = {
  __typename?: 'product_dealer_cost_stddev_pop_fields';
  product_id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  rounding_value?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Product_Dealer_Cost_Stddev_Samp_Fields = {
  __typename?: 'product_dealer_cost_stddev_samp_fields';
  product_id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  rounding_value?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Product_Dealer_Cost_Sum_Fields = {
  __typename?: 'product_dealer_cost_sum_fields';
  product_id?: Maybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
  rounding_value?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Product_Dealer_Cost_Var_Pop_Fields = {
  __typename?: 'product_dealer_cost_var_pop_fields';
  product_id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  rounding_value?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Product_Dealer_Cost_Var_Samp_Fields = {
  __typename?: 'product_dealer_cost_var_samp_fields';
  product_id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  rounding_value?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Product_Dealer_Cost_Variance_Fields = {
  __typename?: 'product_dealer_cost_variance_fields';
  product_id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  rounding_value?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "product_dealer_offset" */
export type Product_Dealer_Offset = {
  __typename?: 'product_dealer_offset';
  created_at: Scalars['timestamptz'];
  id: Scalars['bigint'];
  /** An object relationship */
  offset_bucket: Bucket;
  offset_bucket_id: Scalars['bigint'];
  product_id: Scalars['bigint'];
  rounding_type: Scalars['String'];
  rounding_value: Scalars['numeric'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "product_dealer_offset" */
export type Product_Dealer_Offset_Aggregate = {
  __typename?: 'product_dealer_offset_aggregate';
  aggregate?: Maybe<Product_Dealer_Offset_Aggregate_Fields>;
  nodes: Array<Product_Dealer_Offset>;
};

/** aggregate fields of "product_dealer_offset" */
export type Product_Dealer_Offset_Aggregate_Fields = {
  __typename?: 'product_dealer_offset_aggregate_fields';
  avg?: Maybe<Product_Dealer_Offset_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Dealer_Offset_Max_Fields>;
  min?: Maybe<Product_Dealer_Offset_Min_Fields>;
  stddev?: Maybe<Product_Dealer_Offset_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Dealer_Offset_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Dealer_Offset_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Dealer_Offset_Sum_Fields>;
  var_pop?: Maybe<Product_Dealer_Offset_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Dealer_Offset_Var_Samp_Fields>;
  variance?: Maybe<Product_Dealer_Offset_Variance_Fields>;
};


/** aggregate fields of "product_dealer_offset" */
export type Product_Dealer_Offset_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Dealer_Offset_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Product_Dealer_Offset_Avg_Fields = {
  __typename?: 'product_dealer_offset_avg_fields';
  id?: Maybe<Scalars['Float']>;
  offset_bucket_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  rounding_value?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "product_dealer_offset". All fields are combined with a logical 'AND'. */
export type Product_Dealer_Offset_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Dealer_Offset_Bool_Exp>>;
  _not?: InputMaybe<Product_Dealer_Offset_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Dealer_Offset_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  offset_bucket?: InputMaybe<Bucket_Bool_Exp>;
  offset_bucket_id?: InputMaybe<Bigint_Comparison_Exp>;
  product_id?: InputMaybe<Bigint_Comparison_Exp>;
  rounding_type?: InputMaybe<String_Comparison_Exp>;
  rounding_value?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_dealer_offset" */
export enum Product_Dealer_Offset_Constraint {
  /** unique or primary key constraint */
  ProductDealerOffsetProductIdKey = 'product_dealer_offset_product_id_key',
  /** unique or primary key constraint */
  ProductDealerRoundingPkey = 'product_dealer_rounding_pkey'
}

/** input type for incrementing numeric columns in table "product_dealer_offset" */
export type Product_Dealer_Offset_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  offset_bucket_id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['bigint']>;
  rounding_value?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "product_dealer_offset" */
export type Product_Dealer_Offset_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  offset_bucket?: InputMaybe<Bucket_Obj_Rel_Insert_Input>;
  offset_bucket_id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['bigint']>;
  rounding_type?: InputMaybe<Scalars['String']>;
  rounding_value?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Product_Dealer_Offset_Max_Fields = {
  __typename?: 'product_dealer_offset_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  offset_bucket_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  rounding_type?: Maybe<Scalars['String']>;
  rounding_value?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Product_Dealer_Offset_Min_Fields = {
  __typename?: 'product_dealer_offset_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  offset_bucket_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  rounding_type?: Maybe<Scalars['String']>;
  rounding_value?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "product_dealer_offset" */
export type Product_Dealer_Offset_Mutation_Response = {
  __typename?: 'product_dealer_offset_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Dealer_Offset>;
};

/** input type for inserting object relation for remote table "product_dealer_offset" */
export type Product_Dealer_Offset_Obj_Rel_Insert_Input = {
  data: Product_Dealer_Offset_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Product_Dealer_Offset_On_Conflict>;
};

/** on conflict condition type for table "product_dealer_offset" */
export type Product_Dealer_Offset_On_Conflict = {
  constraint: Product_Dealer_Offset_Constraint;
  update_columns?: Array<Product_Dealer_Offset_Update_Column>;
  where?: InputMaybe<Product_Dealer_Offset_Bool_Exp>;
};

/** Ordering options when selecting data from "product_dealer_offset". */
export type Product_Dealer_Offset_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  offset_bucket?: InputMaybe<Bucket_Order_By>;
  offset_bucket_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  rounding_type?: InputMaybe<Order_By>;
  rounding_value?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_dealer_offset */
export type Product_Dealer_Offset_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "product_dealer_offset" */
export enum Product_Dealer_Offset_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OffsetBucketId = 'offset_bucket_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  RoundingType = 'rounding_type',
  /** column name */
  RoundingValue = 'rounding_value',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "product_dealer_offset" */
export type Product_Dealer_Offset_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  offset_bucket_id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['bigint']>;
  rounding_type?: InputMaybe<Scalars['String']>;
  rounding_value?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Product_Dealer_Offset_Stddev_Fields = {
  __typename?: 'product_dealer_offset_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  offset_bucket_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  rounding_value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Product_Dealer_Offset_Stddev_Pop_Fields = {
  __typename?: 'product_dealer_offset_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  offset_bucket_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  rounding_value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Product_Dealer_Offset_Stddev_Samp_Fields = {
  __typename?: 'product_dealer_offset_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  offset_bucket_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  rounding_value?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Product_Dealer_Offset_Sum_Fields = {
  __typename?: 'product_dealer_offset_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  offset_bucket_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  rounding_value?: Maybe<Scalars['numeric']>;
};

/** update columns of table "product_dealer_offset" */
export enum Product_Dealer_Offset_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OffsetBucketId = 'offset_bucket_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  RoundingType = 'rounding_type',
  /** column name */
  RoundingValue = 'rounding_value',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Product_Dealer_Offset_Var_Pop_Fields = {
  __typename?: 'product_dealer_offset_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  offset_bucket_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  rounding_value?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Product_Dealer_Offset_Var_Samp_Fields = {
  __typename?: 'product_dealer_offset_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  offset_bucket_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  rounding_value?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Product_Dealer_Offset_Variance_Fields = {
  __typename?: 'product_dealer_offset_variance_fields';
  id?: Maybe<Scalars['Float']>;
  offset_bucket_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  rounding_value?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "product" */
export type Product_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  major_version?: InputMaybe<Scalars['Int']>;
  product_type_id?: InputMaybe<Scalars['bigint']>;
  program_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product" */
export type Product_Insert_Input = {
  buckets?: InputMaybe<Bucket_Arr_Rel_Insert_Input>;
  code?: InputMaybe<Scalars['String']>;
  dealer_rounding?: InputMaybe<Product_Dealer_Offset_Obj_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  major_version?: InputMaybe<Scalars['Int']>;
  msrp_parameter_key_combinations?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Arr_Rel_Insert_Input>;
  msrp_parameters?: InputMaybe<Product_Msrp_Parameter_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  non_sellable_combinations?: InputMaybe<Non_Sellable_Combination_Arr_Rel_Insert_Input>;
  parameter_keys?: InputMaybe<Parameter_Key_Arr_Rel_Insert_Input>;
  product_type?: InputMaybe<Product_Type_Obj_Rel_Insert_Input>;
  product_type_id?: InputMaybe<Scalars['bigint']>;
  program?: InputMaybe<Program_Obj_Rel_Insert_Input>;
  program_id?: InputMaybe<Scalars['bigint']>;
  risk_type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Product_Max_Fields = {
  __typename?: 'product_max_fields';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  major_version?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  product_type_id?: Maybe<Scalars['bigint']>;
  program_id?: Maybe<Scalars['bigint']>;
  risk_type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "product" */
export type Product_Max_Order_By = {
  code?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  major_version?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  product_type_id?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
  risk_type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Min_Fields = {
  __typename?: 'product_min_fields';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  major_version?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  product_type_id?: Maybe<Scalars['bigint']>;
  program_id?: Maybe<Scalars['bigint']>;
  risk_type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "product" */
export type Product_Min_Order_By = {
  code?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  major_version?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  product_type_id?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
  risk_type?: InputMaybe<Order_By>;
};

/** columns and relationships of "product_msrp_operation" */
export type Product_Msrp_Operation = {
  __typename?: 'product_msrp_operation';
  id: Scalars['bigint'];
  operand: Scalars['numeric'];
  operator: Scalars['String'];
  product_msrp_parameter_key_combination_id: Scalars['bigint'];
};

/** aggregated selection of "product_msrp_operation" */
export type Product_Msrp_Operation_Aggregate = {
  __typename?: 'product_msrp_operation_aggregate';
  aggregate?: Maybe<Product_Msrp_Operation_Aggregate_Fields>;
  nodes: Array<Product_Msrp_Operation>;
};

/** aggregate fields of "product_msrp_operation" */
export type Product_Msrp_Operation_Aggregate_Fields = {
  __typename?: 'product_msrp_operation_aggregate_fields';
  avg?: Maybe<Product_Msrp_Operation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Msrp_Operation_Max_Fields>;
  min?: Maybe<Product_Msrp_Operation_Min_Fields>;
  stddev?: Maybe<Product_Msrp_Operation_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Msrp_Operation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Msrp_Operation_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Msrp_Operation_Sum_Fields>;
  var_pop?: Maybe<Product_Msrp_Operation_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Msrp_Operation_Var_Samp_Fields>;
  variance?: Maybe<Product_Msrp_Operation_Variance_Fields>;
};


/** aggregate fields of "product_msrp_operation" */
export type Product_Msrp_Operation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Msrp_Operation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_msrp_operation" */
export type Product_Msrp_Operation_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Msrp_Operation_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Msrp_Operation_Max_Order_By>;
  min?: InputMaybe<Product_Msrp_Operation_Min_Order_By>;
  stddev?: InputMaybe<Product_Msrp_Operation_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Msrp_Operation_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Msrp_Operation_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Msrp_Operation_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Msrp_Operation_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Msrp_Operation_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Msrp_Operation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_msrp_operation" */
export type Product_Msrp_Operation_Arr_Rel_Insert_Input = {
  data: Array<Product_Msrp_Operation_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Product_Msrp_Operation_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Msrp_Operation_Avg_Fields = {
  __typename?: 'product_msrp_operation_avg_fields';
  id?: Maybe<Scalars['Float']>;
  operand?: Maybe<Scalars['Float']>;
  product_msrp_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_msrp_operation" */
export type Product_Msrp_Operation_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  operand?: InputMaybe<Order_By>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_msrp_operation". All fields are combined with a logical 'AND'. */
export type Product_Msrp_Operation_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Msrp_Operation_Bool_Exp>>;
  _not?: InputMaybe<Product_Msrp_Operation_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Msrp_Operation_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  operand?: InputMaybe<Numeric_Comparison_Exp>;
  operator?: InputMaybe<String_Comparison_Exp>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_msrp_operation" */
export enum Product_Msrp_Operation_Constraint {
  /** unique or primary key constraint */
  ProductMsrpOperationOperatorProductMsrpParameterKeyComb = 'product_msrp_operation_operator_product_msrp_parameter_key_comb',
  /** unique or primary key constraint */
  ProductMsrpOperationPkey = 'product_msrp_operation_pkey'
}

/** input type for incrementing numeric columns in table "product_msrp_operation" */
export type Product_Msrp_Operation_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  operand?: InputMaybe<Scalars['numeric']>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product_msrp_operation" */
export type Product_Msrp_Operation_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  operand?: InputMaybe<Scalars['numeric']>;
  operator?: InputMaybe<Scalars['String']>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Product_Msrp_Operation_Max_Fields = {
  __typename?: 'product_msrp_operation_max_fields';
  id?: Maybe<Scalars['bigint']>;
  operand?: Maybe<Scalars['numeric']>;
  operator?: Maybe<Scalars['String']>;
  product_msrp_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "product_msrp_operation" */
export type Product_Msrp_Operation_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  operand?: InputMaybe<Order_By>;
  operator?: InputMaybe<Order_By>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Msrp_Operation_Min_Fields = {
  __typename?: 'product_msrp_operation_min_fields';
  id?: Maybe<Scalars['bigint']>;
  operand?: Maybe<Scalars['numeric']>;
  operator?: Maybe<Scalars['String']>;
  product_msrp_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "product_msrp_operation" */
export type Product_Msrp_Operation_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  operand?: InputMaybe<Order_By>;
  operator?: InputMaybe<Order_By>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_msrp_operation" */
export type Product_Msrp_Operation_Mutation_Response = {
  __typename?: 'product_msrp_operation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Msrp_Operation>;
};

/** on conflict condition type for table "product_msrp_operation" */
export type Product_Msrp_Operation_On_Conflict = {
  constraint: Product_Msrp_Operation_Constraint;
  update_columns?: Array<Product_Msrp_Operation_Update_Column>;
  where?: InputMaybe<Product_Msrp_Operation_Bool_Exp>;
};

/** Ordering options when selecting data from "product_msrp_operation". */
export type Product_Msrp_Operation_Order_By = {
  id?: InputMaybe<Order_By>;
  operand?: InputMaybe<Order_By>;
  operator?: InputMaybe<Order_By>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_msrp_operation */
export type Product_Msrp_Operation_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "product_msrp_operation" */
export enum Product_Msrp_Operation_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Operand = 'operand',
  /** column name */
  Operator = 'operator',
  /** column name */
  ProductMsrpParameterKeyCombinationId = 'product_msrp_parameter_key_combination_id'
}

/** input type for updating data in table "product_msrp_operation" */
export type Product_Msrp_Operation_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  operand?: InputMaybe<Scalars['numeric']>;
  operator?: InputMaybe<Scalars['String']>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Product_Msrp_Operation_Stddev_Fields = {
  __typename?: 'product_msrp_operation_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  operand?: Maybe<Scalars['Float']>;
  product_msrp_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_msrp_operation" */
export type Product_Msrp_Operation_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  operand?: InputMaybe<Order_By>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Msrp_Operation_Stddev_Pop_Fields = {
  __typename?: 'product_msrp_operation_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  operand?: Maybe<Scalars['Float']>;
  product_msrp_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_msrp_operation" */
export type Product_Msrp_Operation_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  operand?: InputMaybe<Order_By>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Msrp_Operation_Stddev_Samp_Fields = {
  __typename?: 'product_msrp_operation_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  operand?: Maybe<Scalars['Float']>;
  product_msrp_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_msrp_operation" */
export type Product_Msrp_Operation_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  operand?: InputMaybe<Order_By>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Product_Msrp_Operation_Sum_Fields = {
  __typename?: 'product_msrp_operation_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  operand?: Maybe<Scalars['numeric']>;
  product_msrp_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "product_msrp_operation" */
export type Product_Msrp_Operation_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  operand?: InputMaybe<Order_By>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** update columns of table "product_msrp_operation" */
export enum Product_Msrp_Operation_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Operand = 'operand',
  /** column name */
  Operator = 'operator',
  /** column name */
  ProductMsrpParameterKeyCombinationId = 'product_msrp_parameter_key_combination_id'
}

/** aggregate var_pop on columns */
export type Product_Msrp_Operation_Var_Pop_Fields = {
  __typename?: 'product_msrp_operation_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  operand?: Maybe<Scalars['Float']>;
  product_msrp_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_msrp_operation" */
export type Product_Msrp_Operation_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  operand?: InputMaybe<Order_By>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Msrp_Operation_Var_Samp_Fields = {
  __typename?: 'product_msrp_operation_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  operand?: Maybe<Scalars['Float']>;
  product_msrp_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_msrp_operation" */
export type Product_Msrp_Operation_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  operand?: InputMaybe<Order_By>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Msrp_Operation_Variance_Fields = {
  __typename?: 'product_msrp_operation_variance_fields';
  id?: Maybe<Scalars['Float']>;
  operand?: Maybe<Scalars['Float']>;
  product_msrp_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_msrp_operation" */
export type Product_Msrp_Operation_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  operand?: InputMaybe<Order_By>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "product_msrp_operator" */
export type Product_Msrp_Operator = {
  __typename?: 'product_msrp_operator';
  name: Scalars['String'];
  sort_order: Scalars['Int'];
};

/** aggregated selection of "product_msrp_operator" */
export type Product_Msrp_Operator_Aggregate = {
  __typename?: 'product_msrp_operator_aggregate';
  aggregate?: Maybe<Product_Msrp_Operator_Aggregate_Fields>;
  nodes: Array<Product_Msrp_Operator>;
};

/** aggregate fields of "product_msrp_operator" */
export type Product_Msrp_Operator_Aggregate_Fields = {
  __typename?: 'product_msrp_operator_aggregate_fields';
  avg?: Maybe<Product_Msrp_Operator_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Msrp_Operator_Max_Fields>;
  min?: Maybe<Product_Msrp_Operator_Min_Fields>;
  stddev?: Maybe<Product_Msrp_Operator_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Msrp_Operator_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Msrp_Operator_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Msrp_Operator_Sum_Fields>;
  var_pop?: Maybe<Product_Msrp_Operator_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Msrp_Operator_Var_Samp_Fields>;
  variance?: Maybe<Product_Msrp_Operator_Variance_Fields>;
};


/** aggregate fields of "product_msrp_operator" */
export type Product_Msrp_Operator_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Msrp_Operator_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Product_Msrp_Operator_Avg_Fields = {
  __typename?: 'product_msrp_operator_avg_fields';
  sort_order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "product_msrp_operator". All fields are combined with a logical 'AND'. */
export type Product_Msrp_Operator_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Msrp_Operator_Bool_Exp>>;
  _not?: InputMaybe<Product_Msrp_Operator_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Msrp_Operator_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  sort_order?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_msrp_operator" */
export enum Product_Msrp_Operator_Constraint {
  /** unique or primary key constraint */
  ProductMsrpOperatorPkey = 'product_msrp_operator_pkey'
}

/** input type for incrementing numeric columns in table "product_msrp_operator" */
export type Product_Msrp_Operator_Inc_Input = {
  sort_order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "product_msrp_operator" */
export type Product_Msrp_Operator_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  sort_order?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Product_Msrp_Operator_Max_Fields = {
  __typename?: 'product_msrp_operator_max_fields';
  name?: Maybe<Scalars['String']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Product_Msrp_Operator_Min_Fields = {
  __typename?: 'product_msrp_operator_min_fields';
  name?: Maybe<Scalars['String']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "product_msrp_operator" */
export type Product_Msrp_Operator_Mutation_Response = {
  __typename?: 'product_msrp_operator_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Msrp_Operator>;
};

/** on conflict condition type for table "product_msrp_operator" */
export type Product_Msrp_Operator_On_Conflict = {
  constraint: Product_Msrp_Operator_Constraint;
  update_columns?: Array<Product_Msrp_Operator_Update_Column>;
  where?: InputMaybe<Product_Msrp_Operator_Bool_Exp>;
};

/** Ordering options when selecting data from "product_msrp_operator". */
export type Product_Msrp_Operator_Order_By = {
  name?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_msrp_operator */
export type Product_Msrp_Operator_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "product_msrp_operator" */
export enum Product_Msrp_Operator_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  SortOrder = 'sort_order'
}

/** input type for updating data in table "product_msrp_operator" */
export type Product_Msrp_Operator_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  sort_order?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Product_Msrp_Operator_Stddev_Fields = {
  __typename?: 'product_msrp_operator_stddev_fields';
  sort_order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Product_Msrp_Operator_Stddev_Pop_Fields = {
  __typename?: 'product_msrp_operator_stddev_pop_fields';
  sort_order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Product_Msrp_Operator_Stddev_Samp_Fields = {
  __typename?: 'product_msrp_operator_stddev_samp_fields';
  sort_order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Product_Msrp_Operator_Sum_Fields = {
  __typename?: 'product_msrp_operator_sum_fields';
  sort_order?: Maybe<Scalars['Int']>;
};

/** update columns of table "product_msrp_operator" */
export enum Product_Msrp_Operator_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  SortOrder = 'sort_order'
}

/** aggregate var_pop on columns */
export type Product_Msrp_Operator_Var_Pop_Fields = {
  __typename?: 'product_msrp_operator_var_pop_fields';
  sort_order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Product_Msrp_Operator_Var_Samp_Fields = {
  __typename?: 'product_msrp_operator_var_samp_fields';
  sort_order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Product_Msrp_Operator_Variance_Fields = {
  __typename?: 'product_msrp_operator_variance_fields';
  sort_order?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "product_msrp_parameter" */
export type Product_Msrp_Parameter = {
  __typename?: 'product_msrp_parameter';
  id: Scalars['bigint'];
  parameter_id: Scalars['bigint'];
  product_id: Scalars['bigint'];
};

/** aggregated selection of "product_msrp_parameter" */
export type Product_Msrp_Parameter_Aggregate = {
  __typename?: 'product_msrp_parameter_aggregate';
  aggregate?: Maybe<Product_Msrp_Parameter_Aggregate_Fields>;
  nodes: Array<Product_Msrp_Parameter>;
};

/** aggregate fields of "product_msrp_parameter" */
export type Product_Msrp_Parameter_Aggregate_Fields = {
  __typename?: 'product_msrp_parameter_aggregate_fields';
  avg?: Maybe<Product_Msrp_Parameter_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Msrp_Parameter_Max_Fields>;
  min?: Maybe<Product_Msrp_Parameter_Min_Fields>;
  stddev?: Maybe<Product_Msrp_Parameter_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Msrp_Parameter_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Msrp_Parameter_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Msrp_Parameter_Sum_Fields>;
  var_pop?: Maybe<Product_Msrp_Parameter_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Msrp_Parameter_Var_Samp_Fields>;
  variance?: Maybe<Product_Msrp_Parameter_Variance_Fields>;
};


/** aggregate fields of "product_msrp_parameter" */
export type Product_Msrp_Parameter_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Msrp_Parameter_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_msrp_parameter" */
export type Product_Msrp_Parameter_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Msrp_Parameter_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Msrp_Parameter_Max_Order_By>;
  min?: InputMaybe<Product_Msrp_Parameter_Min_Order_By>;
  stddev?: InputMaybe<Product_Msrp_Parameter_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Msrp_Parameter_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Msrp_Parameter_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Msrp_Parameter_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Msrp_Parameter_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Msrp_Parameter_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Msrp_Parameter_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_msrp_parameter" */
export type Product_Msrp_Parameter_Arr_Rel_Insert_Input = {
  data: Array<Product_Msrp_Parameter_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Product_Msrp_Parameter_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Msrp_Parameter_Avg_Fields = {
  __typename?: 'product_msrp_parameter_avg_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_msrp_parameter" */
export type Product_Msrp_Parameter_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_msrp_parameter". All fields are combined with a logical 'AND'. */
export type Product_Msrp_Parameter_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Msrp_Parameter_Bool_Exp>>;
  _not?: InputMaybe<Product_Msrp_Parameter_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Msrp_Parameter_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  parameter_id?: InputMaybe<Bigint_Comparison_Exp>;
  product_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_msrp_parameter" */
export enum Product_Msrp_Parameter_Constraint {
  /** unique or primary key constraint */
  ProductMsrpParameterParameterIdProductIdKey = 'product_msrp_parameter_parameter_id_product_id_key',
  /** unique or primary key constraint */
  ProductMsrpParameterPkey = 'product_msrp_parameter_pkey'
}

/** input type for incrementing numeric columns in table "product_msrp_parameter" */
export type Product_Msrp_Parameter_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  parameter_id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product_msrp_parameter" */
export type Product_Msrp_Parameter_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  parameter_id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "product_msrp_parameter_key" */
export type Product_Msrp_Parameter_Key = {
  __typename?: 'product_msrp_parameter_key';
  id: Scalars['bigint'];
  parameter_key_id: Scalars['bigint'];
  product_msrp_parameter_key_combination_id: Scalars['bigint'];
};

/** aggregated selection of "product_msrp_parameter_key" */
export type Product_Msrp_Parameter_Key_Aggregate = {
  __typename?: 'product_msrp_parameter_key_aggregate';
  aggregate?: Maybe<Product_Msrp_Parameter_Key_Aggregate_Fields>;
  nodes: Array<Product_Msrp_Parameter_Key>;
};

/** aggregate fields of "product_msrp_parameter_key" */
export type Product_Msrp_Parameter_Key_Aggregate_Fields = {
  __typename?: 'product_msrp_parameter_key_aggregate_fields';
  avg?: Maybe<Product_Msrp_Parameter_Key_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Msrp_Parameter_Key_Max_Fields>;
  min?: Maybe<Product_Msrp_Parameter_Key_Min_Fields>;
  stddev?: Maybe<Product_Msrp_Parameter_Key_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Msrp_Parameter_Key_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Msrp_Parameter_Key_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Msrp_Parameter_Key_Sum_Fields>;
  var_pop?: Maybe<Product_Msrp_Parameter_Key_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Msrp_Parameter_Key_Var_Samp_Fields>;
  variance?: Maybe<Product_Msrp_Parameter_Key_Variance_Fields>;
};


/** aggregate fields of "product_msrp_parameter_key" */
export type Product_Msrp_Parameter_Key_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Msrp_Parameter_Key_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_msrp_parameter_key" */
export type Product_Msrp_Parameter_Key_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Msrp_Parameter_Key_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Msrp_Parameter_Key_Max_Order_By>;
  min?: InputMaybe<Product_Msrp_Parameter_Key_Min_Order_By>;
  stddev?: InputMaybe<Product_Msrp_Parameter_Key_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Msrp_Parameter_Key_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Msrp_Parameter_Key_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Msrp_Parameter_Key_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Msrp_Parameter_Key_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Msrp_Parameter_Key_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Msrp_Parameter_Key_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_msrp_parameter_key" */
export type Product_Msrp_Parameter_Key_Arr_Rel_Insert_Input = {
  data: Array<Product_Msrp_Parameter_Key_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Product_Msrp_Parameter_Key_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Msrp_Parameter_Key_Avg_Fields = {
  __typename?: 'product_msrp_parameter_key_avg_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  product_msrp_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_msrp_parameter_key" */
export type Product_Msrp_Parameter_Key_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_msrp_parameter_key". All fields are combined with a logical 'AND'. */
export type Product_Msrp_Parameter_Key_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Msrp_Parameter_Key_Bool_Exp>>;
  _not?: InputMaybe<Product_Msrp_Parameter_Key_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Msrp_Parameter_Key_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  parameter_key_id?: InputMaybe<Bigint_Comparison_Exp>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** columns and relationships of "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_Combination = {
  __typename?: 'product_msrp_parameter_key_combination';
  id: Scalars['bigint'];
  /** An array relationship */
  msrp_parameter_keys: Array<Product_Msrp_Parameter_Key>;
  /** An aggregate relationship */
  msrp_parameter_keys_aggregate: Product_Msrp_Parameter_Key_Aggregate;
  /** An array relationship */
  operations: Array<Product_Msrp_Operation>;
  /** An aggregate relationship */
  operations_aggregate: Product_Msrp_Operation_Aggregate;
  parameter_key_ids: Scalars['_int8'];
  product_id: Scalars['bigint'];
};


/** columns and relationships of "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_CombinationMsrp_Parameter_KeysArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Parameter_Key_Order_By>>;
  where?: InputMaybe<Product_Msrp_Parameter_Key_Bool_Exp>;
};


/** columns and relationships of "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_CombinationMsrp_Parameter_Keys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Parameter_Key_Order_By>>;
  where?: InputMaybe<Product_Msrp_Parameter_Key_Bool_Exp>;
};


/** columns and relationships of "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_CombinationOperationsArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Operation_Order_By>>;
  where?: InputMaybe<Product_Msrp_Operation_Bool_Exp>;
};


/** columns and relationships of "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_CombinationOperations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Msrp_Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Msrp_Operation_Order_By>>;
  where?: InputMaybe<Product_Msrp_Operation_Bool_Exp>;
};

/** aggregated selection of "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_Combination_Aggregate = {
  __typename?: 'product_msrp_parameter_key_combination_aggregate';
  aggregate?: Maybe<Product_Msrp_Parameter_Key_Combination_Aggregate_Fields>;
  nodes: Array<Product_Msrp_Parameter_Key_Combination>;
};

/** aggregate fields of "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_Combination_Aggregate_Fields = {
  __typename?: 'product_msrp_parameter_key_combination_aggregate_fields';
  avg?: Maybe<Product_Msrp_Parameter_Key_Combination_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Msrp_Parameter_Key_Combination_Max_Fields>;
  min?: Maybe<Product_Msrp_Parameter_Key_Combination_Min_Fields>;
  stddev?: Maybe<Product_Msrp_Parameter_Key_Combination_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Msrp_Parameter_Key_Combination_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Msrp_Parameter_Key_Combination_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Msrp_Parameter_Key_Combination_Sum_Fields>;
  var_pop?: Maybe<Product_Msrp_Parameter_Key_Combination_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Msrp_Parameter_Key_Combination_Var_Samp_Fields>;
  variance?: Maybe<Product_Msrp_Parameter_Key_Combination_Variance_Fields>;
};


/** aggregate fields of "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_Combination_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Msrp_Parameter_Key_Combination_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_Combination_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Max_Order_By>;
  min?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Min_Order_By>;
  stddev?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_Combination_Arr_Rel_Insert_Input = {
  data: Array<Product_Msrp_Parameter_Key_Combination_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Product_Msrp_Parameter_Key_Combination_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Msrp_Parameter_Key_Combination_Avg_Fields = {
  __typename?: 'product_msrp_parameter_key_combination_avg_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_Combination_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_msrp_parameter_key_combination". All fields are combined with a logical 'AND'. */
export type Product_Msrp_Parameter_Key_Combination_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Msrp_Parameter_Key_Combination_Bool_Exp>>;
  _not?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Msrp_Parameter_Key_Combination_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  msrp_parameter_keys?: InputMaybe<Product_Msrp_Parameter_Key_Bool_Exp>;
  operations?: InputMaybe<Product_Msrp_Operation_Bool_Exp>;
  parameter_key_ids?: InputMaybe<_Int8_Comparison_Exp>;
  product_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_msrp_parameter_key_combination" */
export enum Product_Msrp_Parameter_Key_Combination_Constraint {
  /** unique or primary key constraint */
  ProductMsrpParameterKeyCombinationPkey = 'product_msrp_parameter_key_combination_pkey'
}

/** input type for incrementing numeric columns in table "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_Combination_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_Combination_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  msrp_parameter_keys?: InputMaybe<Product_Msrp_Parameter_Key_Arr_Rel_Insert_Input>;
  operations?: InputMaybe<Product_Msrp_Operation_Arr_Rel_Insert_Input>;
  parameter_key_ids?: InputMaybe<Scalars['_int8']>;
  product_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Product_Msrp_Parameter_Key_Combination_Max_Fields = {
  __typename?: 'product_msrp_parameter_key_combination_max_fields';
  id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_Combination_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Msrp_Parameter_Key_Combination_Min_Fields = {
  __typename?: 'product_msrp_parameter_key_combination_min_fields';
  id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_Combination_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_Combination_Mutation_Response = {
  __typename?: 'product_msrp_parameter_key_combination_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Msrp_Parameter_Key_Combination>;
};

/** on conflict condition type for table "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_Combination_On_Conflict = {
  constraint: Product_Msrp_Parameter_Key_Combination_Constraint;
  update_columns?: Array<Product_Msrp_Parameter_Key_Combination_Update_Column>;
  where?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Bool_Exp>;
};

/** Ordering options when selecting data from "product_msrp_parameter_key_combination". */
export type Product_Msrp_Parameter_Key_Combination_Order_By = {
  id?: InputMaybe<Order_By>;
  msrp_parameter_keys_aggregate?: InputMaybe<Product_Msrp_Parameter_Key_Aggregate_Order_By>;
  operations_aggregate?: InputMaybe<Product_Msrp_Operation_Aggregate_Order_By>;
  parameter_key_ids?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_msrp_parameter_key_combination */
export type Product_Msrp_Parameter_Key_Combination_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "product_msrp_parameter_key_combination" */
export enum Product_Msrp_Parameter_Key_Combination_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ParameterKeyIds = 'parameter_key_ids',
  /** column name */
  ProductId = 'product_id'
}

/** input type for updating data in table "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_Combination_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  parameter_key_ids?: InputMaybe<Scalars['_int8']>;
  product_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Product_Msrp_Parameter_Key_Combination_Stddev_Fields = {
  __typename?: 'product_msrp_parameter_key_combination_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_Combination_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Msrp_Parameter_Key_Combination_Stddev_Pop_Fields = {
  __typename?: 'product_msrp_parameter_key_combination_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_Combination_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Msrp_Parameter_Key_Combination_Stddev_Samp_Fields = {
  __typename?: 'product_msrp_parameter_key_combination_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_Combination_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Product_Msrp_Parameter_Key_Combination_Sum_Fields = {
  __typename?: 'product_msrp_parameter_key_combination_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_Combination_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** update columns of table "product_msrp_parameter_key_combination" */
export enum Product_Msrp_Parameter_Key_Combination_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ParameterKeyIds = 'parameter_key_ids',
  /** column name */
  ProductId = 'product_id'
}

/** aggregate var_pop on columns */
export type Product_Msrp_Parameter_Key_Combination_Var_Pop_Fields = {
  __typename?: 'product_msrp_parameter_key_combination_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_Combination_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Msrp_Parameter_Key_Combination_Var_Samp_Fields = {
  __typename?: 'product_msrp_parameter_key_combination_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_Combination_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Msrp_Parameter_Key_Combination_Variance_Fields = {
  __typename?: 'product_msrp_parameter_key_combination_variance_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_msrp_parameter_key_combination" */
export type Product_Msrp_Parameter_Key_Combination_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "product_msrp_parameter_key" */
export enum Product_Msrp_Parameter_Key_Constraint {
  /** unique or primary key constraint */
  ProductMsrpParameterKeyPkey = 'product_msrp_parameter_key_pkey'
}

/** input type for incrementing numeric columns in table "product_msrp_parameter_key" */
export type Product_Msrp_Parameter_Key_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  parameter_key_id?: InputMaybe<Scalars['bigint']>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product_msrp_parameter_key" */
export type Product_Msrp_Parameter_Key_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  parameter_key_id?: InputMaybe<Scalars['bigint']>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Product_Msrp_Parameter_Key_Max_Fields = {
  __typename?: 'product_msrp_parameter_key_max_fields';
  id?: Maybe<Scalars['bigint']>;
  parameter_key_id?: Maybe<Scalars['bigint']>;
  product_msrp_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "product_msrp_parameter_key" */
export type Product_Msrp_Parameter_Key_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Msrp_Parameter_Key_Min_Fields = {
  __typename?: 'product_msrp_parameter_key_min_fields';
  id?: Maybe<Scalars['bigint']>;
  parameter_key_id?: Maybe<Scalars['bigint']>;
  product_msrp_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "product_msrp_parameter_key" */
export type Product_Msrp_Parameter_Key_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_msrp_parameter_key" */
export type Product_Msrp_Parameter_Key_Mutation_Response = {
  __typename?: 'product_msrp_parameter_key_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Msrp_Parameter_Key>;
};

/** on conflict condition type for table "product_msrp_parameter_key" */
export type Product_Msrp_Parameter_Key_On_Conflict = {
  constraint: Product_Msrp_Parameter_Key_Constraint;
  update_columns?: Array<Product_Msrp_Parameter_Key_Update_Column>;
  where?: InputMaybe<Product_Msrp_Parameter_Key_Bool_Exp>;
};

/** Ordering options when selecting data from "product_msrp_parameter_key". */
export type Product_Msrp_Parameter_Key_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_msrp_parameter_key */
export type Product_Msrp_Parameter_Key_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "product_msrp_parameter_key" */
export enum Product_Msrp_Parameter_Key_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ParameterKeyId = 'parameter_key_id',
  /** column name */
  ProductMsrpParameterKeyCombinationId = 'product_msrp_parameter_key_combination_id'
}

/** input type for updating data in table "product_msrp_parameter_key" */
export type Product_Msrp_Parameter_Key_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  parameter_key_id?: InputMaybe<Scalars['bigint']>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Product_Msrp_Parameter_Key_Stddev_Fields = {
  __typename?: 'product_msrp_parameter_key_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  product_msrp_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_msrp_parameter_key" */
export type Product_Msrp_Parameter_Key_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Msrp_Parameter_Key_Stddev_Pop_Fields = {
  __typename?: 'product_msrp_parameter_key_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  product_msrp_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_msrp_parameter_key" */
export type Product_Msrp_Parameter_Key_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Msrp_Parameter_Key_Stddev_Samp_Fields = {
  __typename?: 'product_msrp_parameter_key_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  product_msrp_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_msrp_parameter_key" */
export type Product_Msrp_Parameter_Key_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Product_Msrp_Parameter_Key_Sum_Fields = {
  __typename?: 'product_msrp_parameter_key_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  parameter_key_id?: Maybe<Scalars['bigint']>;
  product_msrp_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "product_msrp_parameter_key" */
export type Product_Msrp_Parameter_Key_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** update columns of table "product_msrp_parameter_key" */
export enum Product_Msrp_Parameter_Key_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ParameterKeyId = 'parameter_key_id',
  /** column name */
  ProductMsrpParameterKeyCombinationId = 'product_msrp_parameter_key_combination_id'
}

/** aggregate var_pop on columns */
export type Product_Msrp_Parameter_Key_Var_Pop_Fields = {
  __typename?: 'product_msrp_parameter_key_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  product_msrp_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_msrp_parameter_key" */
export type Product_Msrp_Parameter_Key_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Msrp_Parameter_Key_Var_Samp_Fields = {
  __typename?: 'product_msrp_parameter_key_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  product_msrp_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_msrp_parameter_key" */
export type Product_Msrp_Parameter_Key_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Msrp_Parameter_Key_Variance_Fields = {
  __typename?: 'product_msrp_parameter_key_variance_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  product_msrp_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_msrp_parameter_key" */
export type Product_Msrp_Parameter_Key_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  product_msrp_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Product_Msrp_Parameter_Max_Fields = {
  __typename?: 'product_msrp_parameter_max_fields';
  id?: Maybe<Scalars['bigint']>;
  parameter_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "product_msrp_parameter" */
export type Product_Msrp_Parameter_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Msrp_Parameter_Min_Fields = {
  __typename?: 'product_msrp_parameter_min_fields';
  id?: Maybe<Scalars['bigint']>;
  parameter_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "product_msrp_parameter" */
export type Product_Msrp_Parameter_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_msrp_parameter" */
export type Product_Msrp_Parameter_Mutation_Response = {
  __typename?: 'product_msrp_parameter_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Msrp_Parameter>;
};

/** on conflict condition type for table "product_msrp_parameter" */
export type Product_Msrp_Parameter_On_Conflict = {
  constraint: Product_Msrp_Parameter_Constraint;
  update_columns?: Array<Product_Msrp_Parameter_Update_Column>;
  where?: InputMaybe<Product_Msrp_Parameter_Bool_Exp>;
};

/** Ordering options when selecting data from "product_msrp_parameter". */
export type Product_Msrp_Parameter_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_msrp_parameter */
export type Product_Msrp_Parameter_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "product_msrp_parameter" */
export enum Product_Msrp_Parameter_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ParameterId = 'parameter_id',
  /** column name */
  ProductId = 'product_id'
}

/** input type for updating data in table "product_msrp_parameter" */
export type Product_Msrp_Parameter_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  parameter_id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Product_Msrp_Parameter_Stddev_Fields = {
  __typename?: 'product_msrp_parameter_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_msrp_parameter" */
export type Product_Msrp_Parameter_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Msrp_Parameter_Stddev_Pop_Fields = {
  __typename?: 'product_msrp_parameter_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_msrp_parameter" */
export type Product_Msrp_Parameter_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Msrp_Parameter_Stddev_Samp_Fields = {
  __typename?: 'product_msrp_parameter_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_msrp_parameter" */
export type Product_Msrp_Parameter_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Product_Msrp_Parameter_Sum_Fields = {
  __typename?: 'product_msrp_parameter_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  parameter_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "product_msrp_parameter" */
export type Product_Msrp_Parameter_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** update columns of table "product_msrp_parameter" */
export enum Product_Msrp_Parameter_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ParameterId = 'parameter_id',
  /** column name */
  ProductId = 'product_id'
}

/** aggregate var_pop on columns */
export type Product_Msrp_Parameter_Var_Pop_Fields = {
  __typename?: 'product_msrp_parameter_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_msrp_parameter" */
export type Product_Msrp_Parameter_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Msrp_Parameter_Var_Samp_Fields = {
  __typename?: 'product_msrp_parameter_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_msrp_parameter" */
export type Product_Msrp_Parameter_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Msrp_Parameter_Variance_Fields = {
  __typename?: 'product_msrp_parameter_variance_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_msrp_parameter" */
export type Product_Msrp_Parameter_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product" */
export type Product_Mutation_Response = {
  __typename?: 'product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product>;
};

/** input type for inserting object relation for remote table "product" */
export type Product_Obj_Rel_Insert_Input = {
  data: Product_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Product_On_Conflict>;
};

/** on conflict condition type for table "product" */
export type Product_On_Conflict = {
  constraint: Product_Constraint;
  update_columns?: Array<Product_Update_Column>;
  where?: InputMaybe<Product_Bool_Exp>;
};

/** Ordering options when selecting data from "product". */
export type Product_Order_By = {
  buckets_aggregate?: InputMaybe<Bucket_Aggregate_Order_By>;
  code?: InputMaybe<Order_By>;
  dealer_rounding?: InputMaybe<Product_Dealer_Offset_Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  major_version?: InputMaybe<Order_By>;
  msrp_parameter_key_combinations_aggregate?: InputMaybe<Product_Msrp_Parameter_Key_Combination_Aggregate_Order_By>;
  msrp_parameters_aggregate?: InputMaybe<Product_Msrp_Parameter_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  non_sellable_combinations_aggregate?: InputMaybe<Non_Sellable_Combination_Aggregate_Order_By>;
  parameter_keys_aggregate?: InputMaybe<Parameter_Key_Aggregate_Order_By>;
  product_type?: InputMaybe<Product_Type_Order_By>;
  product_type_id?: InputMaybe<Order_By>;
  program?: InputMaybe<Program_Order_By>;
  program_id?: InputMaybe<Order_By>;
  risk_type?: InputMaybe<Order_By>;
};

/** columns and relationships of "product_parameter_key" */
export type Product_Parameter_Key = {
  __typename?: 'product_parameter_key';
  id: Scalars['bigint'];
  /** An object relationship */
  parameter_key: Parameter_Key;
  parameter_key_id: Scalars['bigint'];
  product_parameter_key_combination_id: Scalars['bigint'];
};

/** aggregated selection of "product_parameter_key" */
export type Product_Parameter_Key_Aggregate = {
  __typename?: 'product_parameter_key_aggregate';
  aggregate?: Maybe<Product_Parameter_Key_Aggregate_Fields>;
  nodes: Array<Product_Parameter_Key>;
};

/** aggregate fields of "product_parameter_key" */
export type Product_Parameter_Key_Aggregate_Fields = {
  __typename?: 'product_parameter_key_aggregate_fields';
  avg?: Maybe<Product_Parameter_Key_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Parameter_Key_Max_Fields>;
  min?: Maybe<Product_Parameter_Key_Min_Fields>;
  stddev?: Maybe<Product_Parameter_Key_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Parameter_Key_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Parameter_Key_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Parameter_Key_Sum_Fields>;
  var_pop?: Maybe<Product_Parameter_Key_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Parameter_Key_Var_Samp_Fields>;
  variance?: Maybe<Product_Parameter_Key_Variance_Fields>;
};


/** aggregate fields of "product_parameter_key" */
export type Product_Parameter_Key_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Parameter_Key_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_parameter_key" */
export type Product_Parameter_Key_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Parameter_Key_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Parameter_Key_Max_Order_By>;
  min?: InputMaybe<Product_Parameter_Key_Min_Order_By>;
  stddev?: InputMaybe<Product_Parameter_Key_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Parameter_Key_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Parameter_Key_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Parameter_Key_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Parameter_Key_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Parameter_Key_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Parameter_Key_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_parameter_key" */
export type Product_Parameter_Key_Arr_Rel_Insert_Input = {
  data: Array<Product_Parameter_Key_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Product_Parameter_Key_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Parameter_Key_Avg_Fields = {
  __typename?: 'product_parameter_key_avg_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_parameter_key" */
export type Product_Parameter_Key_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_parameter_key". All fields are combined with a logical 'AND'. */
export type Product_Parameter_Key_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Parameter_Key_Bool_Exp>>;
  _not?: InputMaybe<Product_Parameter_Key_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Parameter_Key_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  parameter_key?: InputMaybe<Parameter_Key_Bool_Exp>;
  parameter_key_id?: InputMaybe<Bigint_Comparison_Exp>;
  product_parameter_key_combination_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** columns and relationships of "product_parameter_key_combination" */
export type Product_Parameter_Key_Combination = {
  __typename?: 'product_parameter_key_combination';
  combination_key?: Maybe<Scalars['String']>;
  dealer_cost?: Maybe<Scalars['numeric']>;
  id: Scalars['bigint'];
  is_non_sellable: Scalars['Boolean'];
  is_publishable: Scalars['Boolean'];
  legacy_non_sellable: Scalars['Boolean'];
  msrp?: Maybe<Scalars['numeric']>;
  parameter_key_ids?: Maybe<Scalars['_int8']>;
  parameter_key_ids_json: Scalars['jsonb'];
  /** An array relationship */
  parameter_keys: Array<Product_Parameter_Key>;
  /** An aggregate relationship */
  parameter_keys_aggregate: Product_Parameter_Key_Aggregate;
  /** An object relationship */
  product: Product;
  product_id: Scalars['bigint'];
  /** An array relationship */
  product_parameter_key_sub_combinations: Array<Product_Parameter_Key_Sub_Combination>;
  /** An aggregate relationship */
  product_parameter_key_sub_combinations_aggregate: Product_Parameter_Key_Sub_Combination_Aggregate;
};


/** columns and relationships of "product_parameter_key_combination" */
export type Product_Parameter_Key_CombinationParameter_Key_Ids_JsonArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "product_parameter_key_combination" */
export type Product_Parameter_Key_CombinationParameter_KeysArgs = {
  distinct_on?: InputMaybe<Array<Product_Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Parameter_Key_Order_By>>;
  where?: InputMaybe<Product_Parameter_Key_Bool_Exp>;
};


/** columns and relationships of "product_parameter_key_combination" */
export type Product_Parameter_Key_CombinationParameter_Keys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Parameter_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Parameter_Key_Order_By>>;
  where?: InputMaybe<Product_Parameter_Key_Bool_Exp>;
};


/** columns and relationships of "product_parameter_key_combination" */
export type Product_Parameter_Key_CombinationProduct_Parameter_Key_Sub_CombinationsArgs = {
  distinct_on?: InputMaybe<Array<Product_Parameter_Key_Sub_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Parameter_Key_Sub_Combination_Order_By>>;
  where?: InputMaybe<Product_Parameter_Key_Sub_Combination_Bool_Exp>;
};


/** columns and relationships of "product_parameter_key_combination" */
export type Product_Parameter_Key_CombinationProduct_Parameter_Key_Sub_Combinations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Parameter_Key_Sub_Combination_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Parameter_Key_Sub_Combination_Order_By>>;
  where?: InputMaybe<Product_Parameter_Key_Sub_Combination_Bool_Exp>;
};

/** aggregated selection of "product_parameter_key_combination" */
export type Product_Parameter_Key_Combination_Aggregate = {
  __typename?: 'product_parameter_key_combination_aggregate';
  aggregate?: Maybe<Product_Parameter_Key_Combination_Aggregate_Fields>;
  nodes: Array<Product_Parameter_Key_Combination>;
};

/** aggregate fields of "product_parameter_key_combination" */
export type Product_Parameter_Key_Combination_Aggregate_Fields = {
  __typename?: 'product_parameter_key_combination_aggregate_fields';
  avg?: Maybe<Product_Parameter_Key_Combination_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Parameter_Key_Combination_Max_Fields>;
  min?: Maybe<Product_Parameter_Key_Combination_Min_Fields>;
  stddev?: Maybe<Product_Parameter_Key_Combination_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Parameter_Key_Combination_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Parameter_Key_Combination_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Parameter_Key_Combination_Sum_Fields>;
  var_pop?: Maybe<Product_Parameter_Key_Combination_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Parameter_Key_Combination_Var_Samp_Fields>;
  variance?: Maybe<Product_Parameter_Key_Combination_Variance_Fields>;
};


/** aggregate fields of "product_parameter_key_combination" */
export type Product_Parameter_Key_Combination_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Parameter_Key_Combination_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Product_Parameter_Key_Combination_Append_Input = {
  parameter_key_ids_json?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Product_Parameter_Key_Combination_Avg_Fields = {
  __typename?: 'product_parameter_key_combination_avg_fields';
  dealer_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  msrp?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "product_parameter_key_combination". All fields are combined with a logical 'AND'. */
export type Product_Parameter_Key_Combination_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Parameter_Key_Combination_Bool_Exp>>;
  _not?: InputMaybe<Product_Parameter_Key_Combination_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Parameter_Key_Combination_Bool_Exp>>;
  combination_key?: InputMaybe<String_Comparison_Exp>;
  dealer_cost?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_non_sellable?: InputMaybe<Boolean_Comparison_Exp>;
  is_publishable?: InputMaybe<Boolean_Comparison_Exp>;
  legacy_non_sellable?: InputMaybe<Boolean_Comparison_Exp>;
  msrp?: InputMaybe<Numeric_Comparison_Exp>;
  parameter_key_ids?: InputMaybe<_Int8_Comparison_Exp>;
  parameter_key_ids_json?: InputMaybe<Jsonb_Comparison_Exp>;
  parameter_keys?: InputMaybe<Product_Parameter_Key_Bool_Exp>;
  product?: InputMaybe<Product_Bool_Exp>;
  product_id?: InputMaybe<Bigint_Comparison_Exp>;
  product_parameter_key_sub_combinations?: InputMaybe<Product_Parameter_Key_Sub_Combination_Bool_Exp>;
};

/** unique or primary key constraints on table "product_parameter_key_combination" */
export enum Product_Parameter_Key_Combination_Constraint {
  /** unique or primary key constraint */
  ProductParameterKeyCombinationPkey = 'product_parameter_key_combination_pkey',
  /** unique or primary key constraint */
  ProductParameterKeyCombinationProductIdCombinationKeyKe = 'product_parameter_key_combination_product_id_combination_key_ke'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Product_Parameter_Key_Combination_Delete_At_Path_Input = {
  parameter_key_ids_json?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Product_Parameter_Key_Combination_Delete_Elem_Input = {
  parameter_key_ids_json?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Product_Parameter_Key_Combination_Delete_Key_Input = {
  parameter_key_ids_json?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "product_parameter_key_combination" */
export type Product_Parameter_Key_Combination_Inc_Input = {
  dealer_cost?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['bigint']>;
  msrp?: InputMaybe<Scalars['numeric']>;
  product_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product_parameter_key_combination" */
export type Product_Parameter_Key_Combination_Insert_Input = {
  combination_key?: InputMaybe<Scalars['String']>;
  dealer_cost?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_non_sellable?: InputMaybe<Scalars['Boolean']>;
  is_publishable?: InputMaybe<Scalars['Boolean']>;
  legacy_non_sellable?: InputMaybe<Scalars['Boolean']>;
  msrp?: InputMaybe<Scalars['numeric']>;
  parameter_key_ids?: InputMaybe<Scalars['_int8']>;
  parameter_key_ids_json?: InputMaybe<Scalars['jsonb']>;
  parameter_keys?: InputMaybe<Product_Parameter_Key_Arr_Rel_Insert_Input>;
  product?: InputMaybe<Product_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars['bigint']>;
  product_parameter_key_sub_combinations?: InputMaybe<Product_Parameter_Key_Sub_Combination_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Product_Parameter_Key_Combination_Max_Fields = {
  __typename?: 'product_parameter_key_combination_max_fields';
  combination_key?: Maybe<Scalars['String']>;
  dealer_cost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  msrp?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Product_Parameter_Key_Combination_Min_Fields = {
  __typename?: 'product_parameter_key_combination_min_fields';
  combination_key?: Maybe<Scalars['String']>;
  dealer_cost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  msrp?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "product_parameter_key_combination" */
export type Product_Parameter_Key_Combination_Mutation_Response = {
  __typename?: 'product_parameter_key_combination_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Parameter_Key_Combination>;
};

/** input type for inserting object relation for remote table "product_parameter_key_combination" */
export type Product_Parameter_Key_Combination_Obj_Rel_Insert_Input = {
  data: Product_Parameter_Key_Combination_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Product_Parameter_Key_Combination_On_Conflict>;
};

/** on conflict condition type for table "product_parameter_key_combination" */
export type Product_Parameter_Key_Combination_On_Conflict = {
  constraint: Product_Parameter_Key_Combination_Constraint;
  update_columns?: Array<Product_Parameter_Key_Combination_Update_Column>;
  where?: InputMaybe<Product_Parameter_Key_Combination_Bool_Exp>;
};

/** Ordering options when selecting data from "product_parameter_key_combination". */
export type Product_Parameter_Key_Combination_Order_By = {
  combination_key?: InputMaybe<Order_By>;
  dealer_cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_non_sellable?: InputMaybe<Order_By>;
  is_publishable?: InputMaybe<Order_By>;
  legacy_non_sellable?: InputMaybe<Order_By>;
  msrp?: InputMaybe<Order_By>;
  parameter_key_ids?: InputMaybe<Order_By>;
  parameter_key_ids_json?: InputMaybe<Order_By>;
  parameter_keys_aggregate?: InputMaybe<Product_Parameter_Key_Aggregate_Order_By>;
  product?: InputMaybe<Product_Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_parameter_key_sub_combinations_aggregate?: InputMaybe<Product_Parameter_Key_Sub_Combination_Aggregate_Order_By>;
};

/** primary key columns input for table: product_parameter_key_combination */
export type Product_Parameter_Key_Combination_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Product_Parameter_Key_Combination_Prepend_Input = {
  parameter_key_ids_json?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "product_parameter_key_combination" */
export enum Product_Parameter_Key_Combination_Select_Column {
  /** column name */
  CombinationKey = 'combination_key',
  /** column name */
  DealerCost = 'dealer_cost',
  /** column name */
  Id = 'id',
  /** column name */
  IsNonSellable = 'is_non_sellable',
  /** column name */
  IsPublishable = 'is_publishable',
  /** column name */
  LegacyNonSellable = 'legacy_non_sellable',
  /** column name */
  Msrp = 'msrp',
  /** column name */
  ParameterKeyIds = 'parameter_key_ids',
  /** column name */
  ParameterKeyIdsJson = 'parameter_key_ids_json',
  /** column name */
  ProductId = 'product_id'
}

/** input type for updating data in table "product_parameter_key_combination" */
export type Product_Parameter_Key_Combination_Set_Input = {
  combination_key?: InputMaybe<Scalars['String']>;
  dealer_cost?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_non_sellable?: InputMaybe<Scalars['Boolean']>;
  is_publishable?: InputMaybe<Scalars['Boolean']>;
  legacy_non_sellable?: InputMaybe<Scalars['Boolean']>;
  msrp?: InputMaybe<Scalars['numeric']>;
  parameter_key_ids?: InputMaybe<Scalars['_int8']>;
  parameter_key_ids_json?: InputMaybe<Scalars['jsonb']>;
  product_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Product_Parameter_Key_Combination_Stddev_Fields = {
  __typename?: 'product_parameter_key_combination_stddev_fields';
  dealer_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  msrp?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Product_Parameter_Key_Combination_Stddev_Pop_Fields = {
  __typename?: 'product_parameter_key_combination_stddev_pop_fields';
  dealer_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  msrp?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Product_Parameter_Key_Combination_Stddev_Samp_Fields = {
  __typename?: 'product_parameter_key_combination_stddev_samp_fields';
  dealer_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  msrp?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Product_Parameter_Key_Combination_Sum_Fields = {
  __typename?: 'product_parameter_key_combination_sum_fields';
  dealer_cost?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  msrp?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "product_parameter_key_combination" */
export enum Product_Parameter_Key_Combination_Update_Column {
  /** column name */
  CombinationKey = 'combination_key',
  /** column name */
  DealerCost = 'dealer_cost',
  /** column name */
  Id = 'id',
  /** column name */
  IsNonSellable = 'is_non_sellable',
  /** column name */
  IsPublishable = 'is_publishable',
  /** column name */
  LegacyNonSellable = 'legacy_non_sellable',
  /** column name */
  Msrp = 'msrp',
  /** column name */
  ParameterKeyIds = 'parameter_key_ids',
  /** column name */
  ParameterKeyIdsJson = 'parameter_key_ids_json',
  /** column name */
  ProductId = 'product_id'
}

/** aggregate var_pop on columns */
export type Product_Parameter_Key_Combination_Var_Pop_Fields = {
  __typename?: 'product_parameter_key_combination_var_pop_fields';
  dealer_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  msrp?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Product_Parameter_Key_Combination_Var_Samp_Fields = {
  __typename?: 'product_parameter_key_combination_var_samp_fields';
  dealer_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  msrp?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Product_Parameter_Key_Combination_Variance_Fields = {
  __typename?: 'product_parameter_key_combination_variance_fields';
  dealer_cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  msrp?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** unique or primary key constraints on table "product_parameter_key" */
export enum Product_Parameter_Key_Constraint {
  /** unique or primary key constraint */
  ProductParameterKeyPkey = 'product_parameter_key_pkey',
  /** unique or primary key constraint */
  ProductParameterKeyProductParameterCombinationIdParamete = 'product_parameter_key_product_parameter_combination_id_paramete'
}

/** input type for incrementing numeric columns in table "product_parameter_key" */
export type Product_Parameter_Key_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  parameter_key_id?: InputMaybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product_parameter_key" */
export type Product_Parameter_Key_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  parameter_key?: InputMaybe<Parameter_Key_Obj_Rel_Insert_Input>;
  parameter_key_id?: InputMaybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Product_Parameter_Key_Max_Fields = {
  __typename?: 'product_parameter_key_max_fields';
  id?: Maybe<Scalars['bigint']>;
  parameter_key_id?: Maybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "product_parameter_key" */
export type Product_Parameter_Key_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Parameter_Key_Min_Fields = {
  __typename?: 'product_parameter_key_min_fields';
  id?: Maybe<Scalars['bigint']>;
  parameter_key_id?: Maybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "product_parameter_key" */
export type Product_Parameter_Key_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_parameter_key" */
export type Product_Parameter_Key_Mutation_Response = {
  __typename?: 'product_parameter_key_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Parameter_Key>;
};

/** on conflict condition type for table "product_parameter_key" */
export type Product_Parameter_Key_On_Conflict = {
  constraint: Product_Parameter_Key_Constraint;
  update_columns?: Array<Product_Parameter_Key_Update_Column>;
  where?: InputMaybe<Product_Parameter_Key_Bool_Exp>;
};

/** Ordering options when selecting data from "product_parameter_key". */
export type Product_Parameter_Key_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key?: InputMaybe<Parameter_Key_Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_parameter_key */
export type Product_Parameter_Key_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "product_parameter_key" */
export enum Product_Parameter_Key_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ParameterKeyId = 'parameter_key_id',
  /** column name */
  ProductParameterKeyCombinationId = 'product_parameter_key_combination_id'
}

/** input type for updating data in table "product_parameter_key" */
export type Product_Parameter_Key_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  parameter_key_id?: InputMaybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Product_Parameter_Key_Stddev_Fields = {
  __typename?: 'product_parameter_key_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_parameter_key" */
export type Product_Parameter_Key_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Parameter_Key_Stddev_Pop_Fields = {
  __typename?: 'product_parameter_key_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_parameter_key" */
export type Product_Parameter_Key_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Parameter_Key_Stddev_Samp_Fields = {
  __typename?: 'product_parameter_key_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_parameter_key" */
export type Product_Parameter_Key_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "product_parameter_key_sub_combination" */
export type Product_Parameter_Key_Sub_Combination = {
  __typename?: 'product_parameter_key_sub_combination';
  bucket_id: Scalars['bigint'];
  child_parameter_key_combination_id: Scalars['bigint'];
  id: Scalars['bigint'];
  parameter_key_id: Scalars['bigint'];
  parent_parameter_key_combination_id: Scalars['bigint'];
  total?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "product_parameter_key_sub_combination" */
export type Product_Parameter_Key_Sub_Combination_Aggregate = {
  __typename?: 'product_parameter_key_sub_combination_aggregate';
  aggregate?: Maybe<Product_Parameter_Key_Sub_Combination_Aggregate_Fields>;
  nodes: Array<Product_Parameter_Key_Sub_Combination>;
};

/** aggregate fields of "product_parameter_key_sub_combination" */
export type Product_Parameter_Key_Sub_Combination_Aggregate_Fields = {
  __typename?: 'product_parameter_key_sub_combination_aggregate_fields';
  avg?: Maybe<Product_Parameter_Key_Sub_Combination_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Parameter_Key_Sub_Combination_Max_Fields>;
  min?: Maybe<Product_Parameter_Key_Sub_Combination_Min_Fields>;
  stddev?: Maybe<Product_Parameter_Key_Sub_Combination_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Parameter_Key_Sub_Combination_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Parameter_Key_Sub_Combination_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Parameter_Key_Sub_Combination_Sum_Fields>;
  var_pop?: Maybe<Product_Parameter_Key_Sub_Combination_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Parameter_Key_Sub_Combination_Var_Samp_Fields>;
  variance?: Maybe<Product_Parameter_Key_Sub_Combination_Variance_Fields>;
};


/** aggregate fields of "product_parameter_key_sub_combination" */
export type Product_Parameter_Key_Sub_Combination_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Parameter_Key_Sub_Combination_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_parameter_key_sub_combination" */
export type Product_Parameter_Key_Sub_Combination_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Parameter_Key_Sub_Combination_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Parameter_Key_Sub_Combination_Max_Order_By>;
  min?: InputMaybe<Product_Parameter_Key_Sub_Combination_Min_Order_By>;
  stddev?: InputMaybe<Product_Parameter_Key_Sub_Combination_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Parameter_Key_Sub_Combination_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Parameter_Key_Sub_Combination_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Parameter_Key_Sub_Combination_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Parameter_Key_Sub_Combination_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Parameter_Key_Sub_Combination_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Parameter_Key_Sub_Combination_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_parameter_key_sub_combination" */
export type Product_Parameter_Key_Sub_Combination_Arr_Rel_Insert_Input = {
  data: Array<Product_Parameter_Key_Sub_Combination_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Product_Parameter_Key_Sub_Combination_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Parameter_Key_Sub_Combination_Avg_Fields = {
  __typename?: 'product_parameter_key_sub_combination_avg_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  child_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  parent_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_parameter_key_sub_combination" */
export type Product_Parameter_Key_Sub_Combination_Avg_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  child_parameter_key_combination_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parent_parameter_key_combination_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_parameter_key_sub_combination". All fields are combined with a logical 'AND'. */
export type Product_Parameter_Key_Sub_Combination_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Parameter_Key_Sub_Combination_Bool_Exp>>;
  _not?: InputMaybe<Product_Parameter_Key_Sub_Combination_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Parameter_Key_Sub_Combination_Bool_Exp>>;
  bucket_id?: InputMaybe<Bigint_Comparison_Exp>;
  child_parameter_key_combination_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  parameter_key_id?: InputMaybe<Bigint_Comparison_Exp>;
  parent_parameter_key_combination_id?: InputMaybe<Bigint_Comparison_Exp>;
  total?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_parameter_key_sub_combination" */
export enum Product_Parameter_Key_Sub_Combination_Constraint {
  /** unique or primary key constraint */
  ProductParameterKeySubCombinationPkey = 'product_parameter_key_sub_combination_pkey'
}

/** input type for incrementing numeric columns in table "product_parameter_key_sub_combination" */
export type Product_Parameter_Key_Sub_Combination_Inc_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  child_parameter_key_combination_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  parameter_key_id?: InputMaybe<Scalars['bigint']>;
  parent_parameter_key_combination_id?: InputMaybe<Scalars['bigint']>;
  total?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "product_parameter_key_sub_combination" */
export type Product_Parameter_Key_Sub_Combination_Insert_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  child_parameter_key_combination_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  parameter_key_id?: InputMaybe<Scalars['bigint']>;
  parent_parameter_key_combination_id?: InputMaybe<Scalars['bigint']>;
  total?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Product_Parameter_Key_Sub_Combination_Max_Fields = {
  __typename?: 'product_parameter_key_sub_combination_max_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  child_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  parameter_key_id?: Maybe<Scalars['bigint']>;
  parent_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
  total?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "product_parameter_key_sub_combination" */
export type Product_Parameter_Key_Sub_Combination_Max_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  child_parameter_key_combination_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parent_parameter_key_combination_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Parameter_Key_Sub_Combination_Min_Fields = {
  __typename?: 'product_parameter_key_sub_combination_min_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  child_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  parameter_key_id?: Maybe<Scalars['bigint']>;
  parent_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
  total?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "product_parameter_key_sub_combination" */
export type Product_Parameter_Key_Sub_Combination_Min_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  child_parameter_key_combination_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parent_parameter_key_combination_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_parameter_key_sub_combination" */
export type Product_Parameter_Key_Sub_Combination_Mutation_Response = {
  __typename?: 'product_parameter_key_sub_combination_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Parameter_Key_Sub_Combination>;
};

/** on conflict condition type for table "product_parameter_key_sub_combination" */
export type Product_Parameter_Key_Sub_Combination_On_Conflict = {
  constraint: Product_Parameter_Key_Sub_Combination_Constraint;
  update_columns?: Array<Product_Parameter_Key_Sub_Combination_Update_Column>;
  where?: InputMaybe<Product_Parameter_Key_Sub_Combination_Bool_Exp>;
};

/** Ordering options when selecting data from "product_parameter_key_sub_combination". */
export type Product_Parameter_Key_Sub_Combination_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  child_parameter_key_combination_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parent_parameter_key_combination_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_parameter_key_sub_combination */
export type Product_Parameter_Key_Sub_Combination_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "product_parameter_key_sub_combination" */
export enum Product_Parameter_Key_Sub_Combination_Select_Column {
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  ChildParameterKeyCombinationId = 'child_parameter_key_combination_id',
  /** column name */
  Id = 'id',
  /** column name */
  ParameterKeyId = 'parameter_key_id',
  /** column name */
  ParentParameterKeyCombinationId = 'parent_parameter_key_combination_id',
  /** column name */
  Total = 'total'
}

/** input type for updating data in table "product_parameter_key_sub_combination" */
export type Product_Parameter_Key_Sub_Combination_Set_Input = {
  bucket_id?: InputMaybe<Scalars['bigint']>;
  child_parameter_key_combination_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  parameter_key_id?: InputMaybe<Scalars['bigint']>;
  parent_parameter_key_combination_id?: InputMaybe<Scalars['bigint']>;
  total?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Product_Parameter_Key_Sub_Combination_Stddev_Fields = {
  __typename?: 'product_parameter_key_sub_combination_stddev_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  child_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  parent_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_parameter_key_sub_combination" */
export type Product_Parameter_Key_Sub_Combination_Stddev_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  child_parameter_key_combination_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parent_parameter_key_combination_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Parameter_Key_Sub_Combination_Stddev_Pop_Fields = {
  __typename?: 'product_parameter_key_sub_combination_stddev_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  child_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  parent_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_parameter_key_sub_combination" */
export type Product_Parameter_Key_Sub_Combination_Stddev_Pop_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  child_parameter_key_combination_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parent_parameter_key_combination_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Parameter_Key_Sub_Combination_Stddev_Samp_Fields = {
  __typename?: 'product_parameter_key_sub_combination_stddev_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  child_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  parent_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_parameter_key_sub_combination" */
export type Product_Parameter_Key_Sub_Combination_Stddev_Samp_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  child_parameter_key_combination_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parent_parameter_key_combination_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Product_Parameter_Key_Sub_Combination_Sum_Fields = {
  __typename?: 'product_parameter_key_sub_combination_sum_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  child_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  parameter_key_id?: Maybe<Scalars['bigint']>;
  parent_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
  total?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "product_parameter_key_sub_combination" */
export type Product_Parameter_Key_Sub_Combination_Sum_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  child_parameter_key_combination_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parent_parameter_key_combination_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** update columns of table "product_parameter_key_sub_combination" */
export enum Product_Parameter_Key_Sub_Combination_Update_Column {
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  ChildParameterKeyCombinationId = 'child_parameter_key_combination_id',
  /** column name */
  Id = 'id',
  /** column name */
  ParameterKeyId = 'parameter_key_id',
  /** column name */
  ParentParameterKeyCombinationId = 'parent_parameter_key_combination_id',
  /** column name */
  Total = 'total'
}

/** aggregate var_pop on columns */
export type Product_Parameter_Key_Sub_Combination_Var_Pop_Fields = {
  __typename?: 'product_parameter_key_sub_combination_var_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  child_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  parent_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_parameter_key_sub_combination" */
export type Product_Parameter_Key_Sub_Combination_Var_Pop_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  child_parameter_key_combination_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parent_parameter_key_combination_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Parameter_Key_Sub_Combination_Var_Samp_Fields = {
  __typename?: 'product_parameter_key_sub_combination_var_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  child_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  parent_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_parameter_key_sub_combination" */
export type Product_Parameter_Key_Sub_Combination_Var_Samp_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  child_parameter_key_combination_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parent_parameter_key_combination_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Parameter_Key_Sub_Combination_Variance_Fields = {
  __typename?: 'product_parameter_key_sub_combination_variance_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  child_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  parent_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_parameter_key_sub_combination" */
export type Product_Parameter_Key_Sub_Combination_Variance_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  child_parameter_key_combination_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parent_parameter_key_combination_id?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Product_Parameter_Key_Sum_Fields = {
  __typename?: 'product_parameter_key_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  parameter_key_id?: Maybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "product_parameter_key" */
export type Product_Parameter_Key_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** update columns of table "product_parameter_key" */
export enum Product_Parameter_Key_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ParameterKeyId = 'parameter_key_id',
  /** column name */
  ProductParameterKeyCombinationId = 'product_parameter_key_combination_id'
}

/** aggregate var_pop on columns */
export type Product_Parameter_Key_Var_Pop_Fields = {
  __typename?: 'product_parameter_key_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_parameter_key" */
export type Product_Parameter_Key_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Parameter_Key_Var_Samp_Fields = {
  __typename?: 'product_parameter_key_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_parameter_key" */
export type Product_Parameter_Key_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Parameter_Key_Variance_Fields = {
  __typename?: 'product_parameter_key_variance_fields';
  id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_parameter_key" */
export type Product_Parameter_Key_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product */
export type Product_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "product" */
export enum Product_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MajorVersion = 'major_version',
  /** column name */
  Name = 'name',
  /** column name */
  ProductTypeId = 'product_type_id',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  RiskType = 'risk_type'
}

/** input type for updating data in table "product" */
export type Product_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  major_version?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  product_type_id?: InputMaybe<Scalars['bigint']>;
  program_id?: InputMaybe<Scalars['bigint']>;
  risk_type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Product_Stddev_Fields = {
  __typename?: 'product_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  major_version?: Maybe<Scalars['Float']>;
  product_type_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product" */
export type Product_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  major_version?: InputMaybe<Order_By>;
  product_type_id?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Stddev_Pop_Fields = {
  __typename?: 'product_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  major_version?: Maybe<Scalars['Float']>;
  product_type_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product" */
export type Product_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  major_version?: InputMaybe<Order_By>;
  product_type_id?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Stddev_Samp_Fields = {
  __typename?: 'product_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  major_version?: Maybe<Scalars['Float']>;
  product_type_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product" */
export type Product_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  major_version?: InputMaybe<Order_By>;
  product_type_id?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Product_Sum_Fields = {
  __typename?: 'product_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  major_version?: Maybe<Scalars['Int']>;
  product_type_id?: Maybe<Scalars['bigint']>;
  program_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "product" */
export type Product_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  major_version?: InputMaybe<Order_By>;
  product_type_id?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "product_type" */
export type Product_Type = {
  __typename?: 'product_type';
  description?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  /** An array relationship */
  products: Array<Product>;
  /** An aggregate relationship */
  products_aggregate: Product_Aggregate;
  type: Scalars['String'];
};


/** columns and relationships of "product_type" */
export type Product_TypeProductsArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


/** columns and relationships of "product_type" */
export type Product_TypeProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};

/** aggregated selection of "product_type" */
export type Product_Type_Aggregate = {
  __typename?: 'product_type_aggregate';
  aggregate?: Maybe<Product_Type_Aggregate_Fields>;
  nodes: Array<Product_Type>;
};

/** aggregate fields of "product_type" */
export type Product_Type_Aggregate_Fields = {
  __typename?: 'product_type_aggregate_fields';
  avg?: Maybe<Product_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Type_Max_Fields>;
  min?: Maybe<Product_Type_Min_Fields>;
  stddev?: Maybe<Product_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Type_Sum_Fields>;
  var_pop?: Maybe<Product_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Type_Var_Samp_Fields>;
  variance?: Maybe<Product_Type_Variance_Fields>;
};


/** aggregate fields of "product_type" */
export type Product_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Product_Type_Avg_Fields = {
  __typename?: 'product_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "product_type". All fields are combined with a logical 'AND'. */
export type Product_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Product_Type_Bool_Exp>>;
  _not?: InputMaybe<Product_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Product_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  products?: InputMaybe<Product_Bool_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_type" */
export enum Product_Type_Constraint {
  /** unique or primary key constraint */
  ProductTypePkey = 'product_type_pkey'
}

/** input type for incrementing numeric columns in table "product_type" */
export type Product_Type_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "product_type" */
export type Product_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  products?: InputMaybe<Product_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Product_Type_Max_Fields = {
  __typename?: 'product_type_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Product_Type_Min_Fields = {
  __typename?: 'product_type_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "product_type" */
export type Product_Type_Mutation_Response = {
  __typename?: 'product_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Type>;
};

/** input type for inserting object relation for remote table "product_type" */
export type Product_Type_Obj_Rel_Insert_Input = {
  data: Product_Type_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Product_Type_On_Conflict>;
};

/** on conflict condition type for table "product_type" */
export type Product_Type_On_Conflict = {
  constraint: Product_Type_Constraint;
  update_columns?: Array<Product_Type_Update_Column>;
  where?: InputMaybe<Product_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "product_type". */
export type Product_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  products_aggregate?: InputMaybe<Product_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: product_type */
export type Product_Type_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "product_type" */
export enum Product_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "product_type" */
export type Product_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Product_Type_Stddev_Fields = {
  __typename?: 'product_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Product_Type_Stddev_Pop_Fields = {
  __typename?: 'product_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Product_Type_Stddev_Samp_Fields = {
  __typename?: 'product_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Product_Type_Sum_Fields = {
  __typename?: 'product_type_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "product_type" */
export enum Product_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Product_Type_Var_Pop_Fields = {
  __typename?: 'product_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Product_Type_Var_Samp_Fields = {
  __typename?: 'product_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Product_Type_Variance_Fields = {
  __typename?: 'product_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** update columns of table "product" */
export enum Product_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MajorVersion = 'major_version',
  /** column name */
  Name = 'name',
  /** column name */
  ProductTypeId = 'product_type_id',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  RiskType = 'risk_type'
}

/** aggregate var_pop on columns */
export type Product_Var_Pop_Fields = {
  __typename?: 'product_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  major_version?: Maybe<Scalars['Float']>;
  product_type_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product" */
export type Product_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  major_version?: InputMaybe<Order_By>;
  product_type_id?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Var_Samp_Fields = {
  __typename?: 'product_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  major_version?: Maybe<Scalars['Float']>;
  product_type_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product" */
export type Product_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  major_version?: InputMaybe<Order_By>;
  product_type_id?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Variance_Fields = {
  __typename?: 'product_variance_fields';
  id?: Maybe<Scalars['Float']>;
  major_version?: Maybe<Scalars['Float']>;
  product_type_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product" */
export type Product_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  major_version?: InputMaybe<Order_By>;
  product_type_id?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "program" */
export type Program = {
  __typename?: 'program';
  agent_code: Scalars['String'];
  can_add_remove_buckets: Scalars['Boolean'];
  can_edit_parameters: Scalars['Boolean'];
  cms: Scalars['Boolean'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  /** An array relationship */
  products: Array<Product>;
  /** An aggregate relationship */
  products_aggregate: Product_Aggregate;
};


/** columns and relationships of "program" */
export type ProgramProductsArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


/** columns and relationships of "program" */
export type ProgramProducts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};

/** aggregated selection of "program" */
export type Program_Aggregate = {
  __typename?: 'program_aggregate';
  aggregate?: Maybe<Program_Aggregate_Fields>;
  nodes: Array<Program>;
};

/** aggregate fields of "program" */
export type Program_Aggregate_Fields = {
  __typename?: 'program_aggregate_fields';
  avg?: Maybe<Program_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Program_Max_Fields>;
  min?: Maybe<Program_Min_Fields>;
  stddev?: Maybe<Program_Stddev_Fields>;
  stddev_pop?: Maybe<Program_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Program_Stddev_Samp_Fields>;
  sum?: Maybe<Program_Sum_Fields>;
  var_pop?: Maybe<Program_Var_Pop_Fields>;
  var_samp?: Maybe<Program_Var_Samp_Fields>;
  variance?: Maybe<Program_Variance_Fields>;
};


/** aggregate fields of "program" */
export type Program_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Program_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Program_Avg_Fields = {
  __typename?: 'program_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "program". All fields are combined with a logical 'AND'. */
export type Program_Bool_Exp = {
  _and?: InputMaybe<Array<Program_Bool_Exp>>;
  _not?: InputMaybe<Program_Bool_Exp>;
  _or?: InputMaybe<Array<Program_Bool_Exp>>;
  agent_code?: InputMaybe<String_Comparison_Exp>;
  can_add_remove_buckets?: InputMaybe<Boolean_Comparison_Exp>;
  can_edit_parameters?: InputMaybe<Boolean_Comparison_Exp>;
  cms?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  products?: InputMaybe<Product_Bool_Exp>;
};

/** unique or primary key constraints on table "program" */
export enum Program_Constraint {
  /** unique or primary key constraint */
  ProgramAgentCodeKey = 'program_agent_code_key',
  /** unique or primary key constraint */
  ProgramPkey = 'program_pkey'
}

/** input type for incrementing numeric columns in table "program" */
export type Program_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "program" */
export type Program_Insert_Input = {
  agent_code?: InputMaybe<Scalars['String']>;
  can_add_remove_buckets?: InputMaybe<Scalars['Boolean']>;
  can_edit_parameters?: InputMaybe<Scalars['Boolean']>;
  cms?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Product_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Program_Max_Fields = {
  __typename?: 'program_max_fields';
  agent_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Program_Min_Fields = {
  __typename?: 'program_min_fields';
  agent_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "program" */
export type Program_Mutation_Response = {
  __typename?: 'program_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Program>;
};

/** input type for inserting object relation for remote table "program" */
export type Program_Obj_Rel_Insert_Input = {
  data: Program_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Program_On_Conflict>;
};

/** on conflict condition type for table "program" */
export type Program_On_Conflict = {
  constraint: Program_Constraint;
  update_columns?: Array<Program_Update_Column>;
  where?: InputMaybe<Program_Bool_Exp>;
};

/** Ordering options when selecting data from "program". */
export type Program_Order_By = {
  agent_code?: InputMaybe<Order_By>;
  can_add_remove_buckets?: InputMaybe<Order_By>;
  can_edit_parameters?: InputMaybe<Order_By>;
  cms?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  products_aggregate?: InputMaybe<Product_Aggregate_Order_By>;
};

/** primary key columns input for table: program */
export type Program_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "program" */
export enum Program_Select_Column {
  /** column name */
  AgentCode = 'agent_code',
  /** column name */
  CanAddRemoveBuckets = 'can_add_remove_buckets',
  /** column name */
  CanEditParameters = 'can_edit_parameters',
  /** column name */
  Cms = 'cms',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "program" */
export type Program_Set_Input = {
  agent_code?: InputMaybe<Scalars['String']>;
  can_add_remove_buckets?: InputMaybe<Scalars['Boolean']>;
  can_edit_parameters?: InputMaybe<Scalars['Boolean']>;
  cms?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Program_Stddev_Fields = {
  __typename?: 'program_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Program_Stddev_Pop_Fields = {
  __typename?: 'program_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Program_Stddev_Samp_Fields = {
  __typename?: 'program_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Program_Sum_Fields = {
  __typename?: 'program_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "program" */
export enum Program_Update_Column {
  /** column name */
  AgentCode = 'agent_code',
  /** column name */
  CanAddRemoveBuckets = 'can_add_remove_buckets',
  /** column name */
  CanEditParameters = 'can_edit_parameters',
  /** column name */
  Cms = 'cms',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Program_Var_Pop_Fields = {
  __typename?: 'program_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Program_Var_Samp_Fields = {
  __typename?: 'program_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Program_Variance_Fields = {
  __typename?: 'program_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "publish_complete" */
export type Publish_Complete = {
  __typename?: 'publish_complete';
  event_date_utc: Scalars['timestamp'];
  event_id?: Maybe<Scalars['uuid']>;
  publish_complete_id: Scalars['Int'];
  publish_event_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "publish_complete" */
export type Publish_Complete_Aggregate = {
  __typename?: 'publish_complete_aggregate';
  aggregate?: Maybe<Publish_Complete_Aggregate_Fields>;
  nodes: Array<Publish_Complete>;
};

/** aggregate fields of "publish_complete" */
export type Publish_Complete_Aggregate_Fields = {
  __typename?: 'publish_complete_aggregate_fields';
  avg?: Maybe<Publish_Complete_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Publish_Complete_Max_Fields>;
  min?: Maybe<Publish_Complete_Min_Fields>;
  stddev?: Maybe<Publish_Complete_Stddev_Fields>;
  stddev_pop?: Maybe<Publish_Complete_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Publish_Complete_Stddev_Samp_Fields>;
  sum?: Maybe<Publish_Complete_Sum_Fields>;
  var_pop?: Maybe<Publish_Complete_Var_Pop_Fields>;
  var_samp?: Maybe<Publish_Complete_Var_Samp_Fields>;
  variance?: Maybe<Publish_Complete_Variance_Fields>;
};


/** aggregate fields of "publish_complete" */
export type Publish_Complete_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Publish_Complete_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Publish_Complete_Avg_Fields = {
  __typename?: 'publish_complete_avg_fields';
  publish_complete_id?: Maybe<Scalars['Float']>;
  publish_event_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "publish_complete". All fields are combined with a logical 'AND'. */
export type Publish_Complete_Bool_Exp = {
  _and?: InputMaybe<Array<Publish_Complete_Bool_Exp>>;
  _not?: InputMaybe<Publish_Complete_Bool_Exp>;
  _or?: InputMaybe<Array<Publish_Complete_Bool_Exp>>;
  event_date_utc?: InputMaybe<Timestamp_Comparison_Exp>;
  event_id?: InputMaybe<Uuid_Comparison_Exp>;
  publish_complete_id?: InputMaybe<Int_Comparison_Exp>;
  publish_event_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "publish_complete" */
export enum Publish_Complete_Constraint {
  /** unique or primary key constraint */
  PublishCompletePkey = 'publish_complete_pkey'
}

/** input type for incrementing numeric columns in table "publish_complete" */
export type Publish_Complete_Inc_Input = {
  publish_complete_id?: InputMaybe<Scalars['Int']>;
  publish_event_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "publish_complete" */
export type Publish_Complete_Insert_Input = {
  event_date_utc?: InputMaybe<Scalars['timestamp']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  publish_complete_id?: InputMaybe<Scalars['Int']>;
  publish_event_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Publish_Complete_Max_Fields = {
  __typename?: 'publish_complete_max_fields';
  event_date_utc?: Maybe<Scalars['timestamp']>;
  event_id?: Maybe<Scalars['uuid']>;
  publish_complete_id?: Maybe<Scalars['Int']>;
  publish_event_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Publish_Complete_Min_Fields = {
  __typename?: 'publish_complete_min_fields';
  event_date_utc?: Maybe<Scalars['timestamp']>;
  event_id?: Maybe<Scalars['uuid']>;
  publish_complete_id?: Maybe<Scalars['Int']>;
  publish_event_id?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "publish_complete" */
export type Publish_Complete_Mutation_Response = {
  __typename?: 'publish_complete_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Publish_Complete>;
};

/** on conflict condition type for table "publish_complete" */
export type Publish_Complete_On_Conflict = {
  constraint: Publish_Complete_Constraint;
  update_columns?: Array<Publish_Complete_Update_Column>;
  where?: InputMaybe<Publish_Complete_Bool_Exp>;
};

/** Ordering options when selecting data from "publish_complete". */
export type Publish_Complete_Order_By = {
  event_date_utc?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  publish_complete_id?: InputMaybe<Order_By>;
  publish_event_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: publish_complete */
export type Publish_Complete_Pk_Columns_Input = {
  publish_complete_id: Scalars['Int'];
};

/** select columns of table "publish_complete" */
export enum Publish_Complete_Select_Column {
  /** column name */
  EventDateUtc = 'event_date_utc',
  /** column name */
  EventId = 'event_id',
  /** column name */
  PublishCompleteId = 'publish_complete_id',
  /** column name */
  PublishEventId = 'publish_event_id'
}

/** input type for updating data in table "publish_complete" */
export type Publish_Complete_Set_Input = {
  event_date_utc?: InputMaybe<Scalars['timestamp']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  publish_complete_id?: InputMaybe<Scalars['Int']>;
  publish_event_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Publish_Complete_Stddev_Fields = {
  __typename?: 'publish_complete_stddev_fields';
  publish_complete_id?: Maybe<Scalars['Float']>;
  publish_event_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Publish_Complete_Stddev_Pop_Fields = {
  __typename?: 'publish_complete_stddev_pop_fields';
  publish_complete_id?: Maybe<Scalars['Float']>;
  publish_event_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Publish_Complete_Stddev_Samp_Fields = {
  __typename?: 'publish_complete_stddev_samp_fields';
  publish_complete_id?: Maybe<Scalars['Float']>;
  publish_event_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Publish_Complete_Sum_Fields = {
  __typename?: 'publish_complete_sum_fields';
  publish_complete_id?: Maybe<Scalars['Int']>;
  publish_event_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "publish_complete" */
export enum Publish_Complete_Update_Column {
  /** column name */
  EventDateUtc = 'event_date_utc',
  /** column name */
  EventId = 'event_id',
  /** column name */
  PublishCompleteId = 'publish_complete_id',
  /** column name */
  PublishEventId = 'publish_event_id'
}

/** aggregate var_pop on columns */
export type Publish_Complete_Var_Pop_Fields = {
  __typename?: 'publish_complete_var_pop_fields';
  publish_complete_id?: Maybe<Scalars['Float']>;
  publish_event_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Publish_Complete_Var_Samp_Fields = {
  __typename?: 'publish_complete_var_samp_fields';
  publish_complete_id?: Maybe<Scalars['Float']>;
  publish_event_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Publish_Complete_Variance_Fields = {
  __typename?: 'publish_complete_variance_fields';
  publish_complete_id?: Maybe<Scalars['Float']>;
  publish_event_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "publish_product" */
export type Publish_Product = {
  __typename?: 'publish_product';
  agent_code?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  effective_date: Scalars['date'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  product_code?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['bigint']>;
  publish_id: Scalars['uuid'];
  snapshot?: Maybe<Scalars['json']>;
  status: Scalars['String'];
  user_email?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};


/** columns and relationships of "publish_product" */
export type Publish_ProductSnapshotArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "publish_product" */
export type Publish_Product_Aggregate = {
  __typename?: 'publish_product_aggregate';
  aggregate?: Maybe<Publish_Product_Aggregate_Fields>;
  nodes: Array<Publish_Product>;
};

/** aggregate fields of "publish_product" */
export type Publish_Product_Aggregate_Fields = {
  __typename?: 'publish_product_aggregate_fields';
  avg?: Maybe<Publish_Product_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Publish_Product_Max_Fields>;
  min?: Maybe<Publish_Product_Min_Fields>;
  stddev?: Maybe<Publish_Product_Stddev_Fields>;
  stddev_pop?: Maybe<Publish_Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Publish_Product_Stddev_Samp_Fields>;
  sum?: Maybe<Publish_Product_Sum_Fields>;
  var_pop?: Maybe<Publish_Product_Var_Pop_Fields>;
  var_samp?: Maybe<Publish_Product_Var_Samp_Fields>;
  variance?: Maybe<Publish_Product_Variance_Fields>;
};


/** aggregate fields of "publish_product" */
export type Publish_Product_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Publish_Product_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Publish_Product_Avg_Fields = {
  __typename?: 'publish_product_avg_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "publish_product". All fields are combined with a logical 'AND'. */
export type Publish_Product_Bool_Exp = {
  _and?: InputMaybe<Array<Publish_Product_Bool_Exp>>;
  _not?: InputMaybe<Publish_Product_Bool_Exp>;
  _or?: InputMaybe<Array<Publish_Product_Bool_Exp>>;
  agent_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  effective_date?: InputMaybe<Date_Comparison_Exp>;
  error?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  product_code?: InputMaybe<String_Comparison_Exp>;
  product_id?: InputMaybe<Bigint_Comparison_Exp>;
  publish_id?: InputMaybe<Uuid_Comparison_Exp>;
  snapshot?: InputMaybe<Json_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  user_email?: InputMaybe<String_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "publish_product" */
export enum Publish_Product_Constraint {
  /** unique or primary key constraint */
  PublishProductPkey = 'publish_product_pkey'
}

/** input type for incrementing numeric columns in table "publish_product" */
export type Publish_Product_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "publish_product" */
export type Publish_Product_Insert_Input = {
  agent_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  effective_date?: InputMaybe<Scalars['date']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  product_code?: InputMaybe<Scalars['String']>;
  product_id?: InputMaybe<Scalars['bigint']>;
  publish_id?: InputMaybe<Scalars['uuid']>;
  snapshot?: InputMaybe<Scalars['json']>;
  status?: InputMaybe<Scalars['String']>;
  user_email?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Publish_Product_Max_Fields = {
  __typename?: 'publish_product_max_fields';
  agent_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  effective_date?: Maybe<Scalars['date']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  product_code?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['bigint']>;
  publish_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  user_email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Publish_Product_Min_Fields = {
  __typename?: 'publish_product_min_fields';
  agent_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  effective_date?: Maybe<Scalars['date']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  product_code?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['bigint']>;
  publish_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  user_email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "publish_product" */
export type Publish_Product_Mutation_Response = {
  __typename?: 'publish_product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Publish_Product>;
};

/** on conflict condition type for table "publish_product" */
export type Publish_Product_On_Conflict = {
  constraint: Publish_Product_Constraint;
  update_columns?: Array<Publish_Product_Update_Column>;
  where?: InputMaybe<Publish_Product_Bool_Exp>;
};

/** Ordering options when selecting data from "publish_product". */
export type Publish_Product_Order_By = {
  agent_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  effective_date?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_code?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  publish_id?: InputMaybe<Order_By>;
  snapshot?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user_email?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: publish_product */
export type Publish_Product_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "publish_product" */
export enum Publish_Product_Select_Column {
  /** column name */
  AgentCode = 'agent_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EffectiveDate = 'effective_date',
  /** column name */
  Error = 'error',
  /** column name */
  Id = 'id',
  /** column name */
  ProductCode = 'product_code',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  PublishId = 'publish_id',
  /** column name */
  Snapshot = 'snapshot',
  /** column name */
  Status = 'status',
  /** column name */
  UserEmail = 'user_email',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "publish_product" */
export type Publish_Product_Set_Input = {
  agent_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  effective_date?: InputMaybe<Scalars['date']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  product_code?: InputMaybe<Scalars['String']>;
  product_id?: InputMaybe<Scalars['bigint']>;
  publish_id?: InputMaybe<Scalars['uuid']>;
  snapshot?: InputMaybe<Scalars['json']>;
  status?: InputMaybe<Scalars['String']>;
  user_email?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Publish_Product_Stddev_Fields = {
  __typename?: 'publish_product_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Publish_Product_Stddev_Pop_Fields = {
  __typename?: 'publish_product_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Publish_Product_Stddev_Samp_Fields = {
  __typename?: 'publish_product_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Publish_Product_Sum_Fields = {
  __typename?: 'publish_product_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "publish_product" */
export enum Publish_Product_Update_Column {
  /** column name */
  AgentCode = 'agent_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EffectiveDate = 'effective_date',
  /** column name */
  Error = 'error',
  /** column name */
  Id = 'id',
  /** column name */
  ProductCode = 'product_code',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  PublishId = 'publish_id',
  /** column name */
  Snapshot = 'snapshot',
  /** column name */
  Status = 'status',
  /** column name */
  UserEmail = 'user_email',
  /** column name */
  Username = 'username'
}

/** aggregate var_pop on columns */
export type Publish_Product_Var_Pop_Fields = {
  __typename?: 'publish_product_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Publish_Product_Var_Samp_Fields = {
  __typename?: 'publish_product_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Publish_Product_Variance_Fields = {
  __typename?: 'publish_product_variance_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "rate_slice_base_slice" */
export type Rate_Slice_Base_Slice = {
  __typename?: 'rate_slice_base_slice';
  base_slice_id: Scalars['bigint'];
  id: Scalars['bigint'];
  rate_slice_id: Scalars['bigint'];
};

/** aggregated selection of "rate_slice_base_slice" */
export type Rate_Slice_Base_Slice_Aggregate = {
  __typename?: 'rate_slice_base_slice_aggregate';
  aggregate?: Maybe<Rate_Slice_Base_Slice_Aggregate_Fields>;
  nodes: Array<Rate_Slice_Base_Slice>;
};

/** aggregate fields of "rate_slice_base_slice" */
export type Rate_Slice_Base_Slice_Aggregate_Fields = {
  __typename?: 'rate_slice_base_slice_aggregate_fields';
  avg?: Maybe<Rate_Slice_Base_Slice_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Rate_Slice_Base_Slice_Max_Fields>;
  min?: Maybe<Rate_Slice_Base_Slice_Min_Fields>;
  stddev?: Maybe<Rate_Slice_Base_Slice_Stddev_Fields>;
  stddev_pop?: Maybe<Rate_Slice_Base_Slice_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rate_Slice_Base_Slice_Stddev_Samp_Fields>;
  sum?: Maybe<Rate_Slice_Base_Slice_Sum_Fields>;
  var_pop?: Maybe<Rate_Slice_Base_Slice_Var_Pop_Fields>;
  var_samp?: Maybe<Rate_Slice_Base_Slice_Var_Samp_Fields>;
  variance?: Maybe<Rate_Slice_Base_Slice_Variance_Fields>;
};


/** aggregate fields of "rate_slice_base_slice" */
export type Rate_Slice_Base_Slice_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rate_Slice_Base_Slice_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "rate_slice_base_slice" */
export type Rate_Slice_Base_Slice_Aggregate_Order_By = {
  avg?: InputMaybe<Rate_Slice_Base_Slice_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rate_Slice_Base_Slice_Max_Order_By>;
  min?: InputMaybe<Rate_Slice_Base_Slice_Min_Order_By>;
  stddev?: InputMaybe<Rate_Slice_Base_Slice_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rate_Slice_Base_Slice_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rate_Slice_Base_Slice_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rate_Slice_Base_Slice_Sum_Order_By>;
  var_pop?: InputMaybe<Rate_Slice_Base_Slice_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rate_Slice_Base_Slice_Var_Samp_Order_By>;
  variance?: InputMaybe<Rate_Slice_Base_Slice_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rate_slice_base_slice" */
export type Rate_Slice_Base_Slice_Arr_Rel_Insert_Input = {
  data: Array<Rate_Slice_Base_Slice_Insert_Input>;
  /** on conflict condition */
  on_conflict?: InputMaybe<Rate_Slice_Base_Slice_On_Conflict>;
};

/** aggregate avg on columns */
export type Rate_Slice_Base_Slice_Avg_Fields = {
  __typename?: 'rate_slice_base_slice_avg_fields';
  base_slice_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rate_slice_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "rate_slice_base_slice" */
export type Rate_Slice_Base_Slice_Avg_Order_By = {
  base_slice_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rate_slice_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rate_slice_base_slice". All fields are combined with a logical 'AND'. */
export type Rate_Slice_Base_Slice_Bool_Exp = {
  _and?: InputMaybe<Array<Rate_Slice_Base_Slice_Bool_Exp>>;
  _not?: InputMaybe<Rate_Slice_Base_Slice_Bool_Exp>;
  _or?: InputMaybe<Array<Rate_Slice_Base_Slice_Bool_Exp>>;
  base_slice_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  rate_slice_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "rate_slice_base_slice" */
export enum Rate_Slice_Base_Slice_Constraint {
  /** unique or primary key constraint */
  RateSliceBaseSlicePkey = 'rate_slice_base_slice_pkey',
  /** unique or primary key constraint */
  RateSliceBaseSliceRateSliceIdBaseSliceIdKey = 'rate_slice_base_slice_rate_slice_id_base_slice_id_key'
}

/** input type for incrementing numeric columns in table "rate_slice_base_slice" */
export type Rate_Slice_Base_Slice_Inc_Input = {
  base_slice_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  rate_slice_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "rate_slice_base_slice" */
export type Rate_Slice_Base_Slice_Insert_Input = {
  base_slice_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  rate_slice_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Rate_Slice_Base_Slice_Max_Fields = {
  __typename?: 'rate_slice_base_slice_max_fields';
  base_slice_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  rate_slice_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "rate_slice_base_slice" */
export type Rate_Slice_Base_Slice_Max_Order_By = {
  base_slice_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rate_slice_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rate_Slice_Base_Slice_Min_Fields = {
  __typename?: 'rate_slice_base_slice_min_fields';
  base_slice_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  rate_slice_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "rate_slice_base_slice" */
export type Rate_Slice_Base_Slice_Min_Order_By = {
  base_slice_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rate_slice_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rate_slice_base_slice" */
export type Rate_Slice_Base_Slice_Mutation_Response = {
  __typename?: 'rate_slice_base_slice_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rate_Slice_Base_Slice>;
};

/** on conflict condition type for table "rate_slice_base_slice" */
export type Rate_Slice_Base_Slice_On_Conflict = {
  constraint: Rate_Slice_Base_Slice_Constraint;
  update_columns?: Array<Rate_Slice_Base_Slice_Update_Column>;
  where?: InputMaybe<Rate_Slice_Base_Slice_Bool_Exp>;
};

/** Ordering options when selecting data from "rate_slice_base_slice". */
export type Rate_Slice_Base_Slice_Order_By = {
  base_slice_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rate_slice_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rate_slice_base_slice */
export type Rate_Slice_Base_Slice_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "rate_slice_base_slice" */
export enum Rate_Slice_Base_Slice_Select_Column {
  /** column name */
  BaseSliceId = 'base_slice_id',
  /** column name */
  Id = 'id',
  /** column name */
  RateSliceId = 'rate_slice_id'
}

/** input type for updating data in table "rate_slice_base_slice" */
export type Rate_Slice_Base_Slice_Set_Input = {
  base_slice_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  rate_slice_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Rate_Slice_Base_Slice_Stddev_Fields = {
  __typename?: 'rate_slice_base_slice_stddev_fields';
  base_slice_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rate_slice_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "rate_slice_base_slice" */
export type Rate_Slice_Base_Slice_Stddev_Order_By = {
  base_slice_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rate_slice_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rate_Slice_Base_Slice_Stddev_Pop_Fields = {
  __typename?: 'rate_slice_base_slice_stddev_pop_fields';
  base_slice_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rate_slice_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "rate_slice_base_slice" */
export type Rate_Slice_Base_Slice_Stddev_Pop_Order_By = {
  base_slice_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rate_slice_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rate_Slice_Base_Slice_Stddev_Samp_Fields = {
  __typename?: 'rate_slice_base_slice_stddev_samp_fields';
  base_slice_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rate_slice_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "rate_slice_base_slice" */
export type Rate_Slice_Base_Slice_Stddev_Samp_Order_By = {
  base_slice_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rate_slice_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rate_Slice_Base_Slice_Sum_Fields = {
  __typename?: 'rate_slice_base_slice_sum_fields';
  base_slice_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  rate_slice_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "rate_slice_base_slice" */
export type Rate_Slice_Base_Slice_Sum_Order_By = {
  base_slice_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rate_slice_id?: InputMaybe<Order_By>;
};

/** update columns of table "rate_slice_base_slice" */
export enum Rate_Slice_Base_Slice_Update_Column {
  /** column name */
  BaseSliceId = 'base_slice_id',
  /** column name */
  Id = 'id',
  /** column name */
  RateSliceId = 'rate_slice_id'
}

/** aggregate var_pop on columns */
export type Rate_Slice_Base_Slice_Var_Pop_Fields = {
  __typename?: 'rate_slice_base_slice_var_pop_fields';
  base_slice_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rate_slice_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "rate_slice_base_slice" */
export type Rate_Slice_Base_Slice_Var_Pop_Order_By = {
  base_slice_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rate_slice_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rate_Slice_Base_Slice_Var_Samp_Fields = {
  __typename?: 'rate_slice_base_slice_var_samp_fields';
  base_slice_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rate_slice_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "rate_slice_base_slice" */
export type Rate_Slice_Base_Slice_Var_Samp_Order_By = {
  base_slice_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rate_slice_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rate_Slice_Base_Slice_Variance_Fields = {
  __typename?: 'rate_slice_base_slice_variance_fields';
  base_slice_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rate_slice_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "rate_slice_base_slice" */
export type Rate_Slice_Base_Slice_Variance_Order_By = {
  base_slice_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rate_slice_id?: InputMaybe<Order_By>;
};

export type Reset_Product_Msrp_Parameter_Key_Combinations_Args = {
  productid?: InputMaybe<Scalars['bigint']>;
};

export type Reset_Product_Parameter_Key_Combinations_Args = {
  productid?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "risk_type" */
export type Risk_Type = {
  __typename?: 'risk_type';
  risk_type_code: Scalars['String'];
  risk_type_description: Scalars['String'];
};

/** aggregated selection of "risk_type" */
export type Risk_Type_Aggregate = {
  __typename?: 'risk_type_aggregate';
  aggregate?: Maybe<Risk_Type_Aggregate_Fields>;
  nodes: Array<Risk_Type>;
};

/** aggregate fields of "risk_type" */
export type Risk_Type_Aggregate_Fields = {
  __typename?: 'risk_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Risk_Type_Max_Fields>;
  min?: Maybe<Risk_Type_Min_Fields>;
};


/** aggregate fields of "risk_type" */
export type Risk_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Risk_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "risk_type". All fields are combined with a logical 'AND'. */
export type Risk_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Risk_Type_Bool_Exp>>;
  _not?: InputMaybe<Risk_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Type_Bool_Exp>>;
  risk_type_code?: InputMaybe<String_Comparison_Exp>;
  risk_type_description?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "risk_type" */
export enum Risk_Type_Constraint {
  /** unique or primary key constraint */
  RiskTypePkey = 'risk_type_pkey',
  /** unique or primary key constraint */
  RiskTypeRiskTypeDescriptionKey = 'risk_type_risk_type_description_key'
}

/** input type for inserting data into table "risk_type" */
export type Risk_Type_Insert_Input = {
  risk_type_code?: InputMaybe<Scalars['String']>;
  risk_type_description?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Risk_Type_Max_Fields = {
  __typename?: 'risk_type_max_fields';
  risk_type_code?: Maybe<Scalars['String']>;
  risk_type_description?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Risk_Type_Min_Fields = {
  __typename?: 'risk_type_min_fields';
  risk_type_code?: Maybe<Scalars['String']>;
  risk_type_description?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "risk_type" */
export type Risk_Type_Mutation_Response = {
  __typename?: 'risk_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk_Type>;
};

/** on conflict condition type for table "risk_type" */
export type Risk_Type_On_Conflict = {
  constraint: Risk_Type_Constraint;
  update_columns?: Array<Risk_Type_Update_Column>;
  where?: InputMaybe<Risk_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "risk_type". */
export type Risk_Type_Order_By = {
  risk_type_code?: InputMaybe<Order_By>;
  risk_type_description?: InputMaybe<Order_By>;
};

/** primary key columns input for table: risk_type */
export type Risk_Type_Pk_Columns_Input = {
  risk_type_code: Scalars['String'];
};

/** select columns of table "risk_type" */
export enum Risk_Type_Select_Column {
  /** column name */
  RiskTypeCode = 'risk_type_code',
  /** column name */
  RiskTypeDescription = 'risk_type_description'
}

/** input type for updating data in table "risk_type" */
export type Risk_Type_Set_Input = {
  risk_type_code?: InputMaybe<Scalars['String']>;
  risk_type_description?: InputMaybe<Scalars['String']>;
};

/** update columns of table "risk_type" */
export enum Risk_Type_Update_Column {
  /** column name */
  RiskTypeCode = 'risk_type_code',
  /** column name */
  RiskTypeDescription = 'risk_type_description'
}

/** columns and relationships of "round_type" */
export type Round_Type = {
  __typename?: 'round_type';
  round_type: Scalars['String'];
};

/** aggregated selection of "round_type" */
export type Round_Type_Aggregate = {
  __typename?: 'round_type_aggregate';
  aggregate?: Maybe<Round_Type_Aggregate_Fields>;
  nodes: Array<Round_Type>;
};

/** aggregate fields of "round_type" */
export type Round_Type_Aggregate_Fields = {
  __typename?: 'round_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Round_Type_Max_Fields>;
  min?: Maybe<Round_Type_Min_Fields>;
};


/** aggregate fields of "round_type" */
export type Round_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Round_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "round_type". All fields are combined with a logical 'AND'. */
export type Round_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Round_Type_Bool_Exp>>;
  _not?: InputMaybe<Round_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Round_Type_Bool_Exp>>;
  round_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "round_type" */
export enum Round_Type_Constraint {
  /** unique or primary key constraint */
  RoundTypePkey = 'round_type_pkey'
}

/** input type for inserting data into table "round_type" */
export type Round_Type_Insert_Input = {
  round_type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Round_Type_Max_Fields = {
  __typename?: 'round_type_max_fields';
  round_type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Round_Type_Min_Fields = {
  __typename?: 'round_type_min_fields';
  round_type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "round_type" */
export type Round_Type_Mutation_Response = {
  __typename?: 'round_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Round_Type>;
};

/** input type for inserting object relation for remote table "round_type" */
export type Round_Type_Obj_Rel_Insert_Input = {
  data: Round_Type_Insert_Input;
  /** on conflict condition */
  on_conflict?: InputMaybe<Round_Type_On_Conflict>;
};

/** on conflict condition type for table "round_type" */
export type Round_Type_On_Conflict = {
  constraint: Round_Type_Constraint;
  update_columns?: Array<Round_Type_Update_Column>;
  where?: InputMaybe<Round_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "round_type". */
export type Round_Type_Order_By = {
  round_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: round_type */
export type Round_Type_Pk_Columns_Input = {
  round_type: Scalars['String'];
};

/** select columns of table "round_type" */
export enum Round_Type_Select_Column {
  /** column name */
  RoundType = 'round_type'
}

/** input type for updating data in table "round_type" */
export type Round_Type_Set_Input = {
  round_type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "round_type" */
export enum Round_Type_Update_Column {
  /** column name */
  RoundType = 'round_type'
}

export type Set_Bucket_Rate_Totals_Args = {
  bucketid?: InputMaybe<Scalars['bigint']>;
};

export type Set_Is_Non_Sellable_Args = {
  productid?: InputMaybe<Scalars['bigint']>;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

export type Update_Product_Parameter_Key_Combinations_Args = {
  productid?: InputMaybe<Scalars['bigint']>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  avg?: Maybe<User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
  stddev?: Maybe<User_Stddev_Fields>;
  stddev_pop?: Maybe<User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Stddev_Samp_Fields>;
  sum?: Maybe<User_Sum_Fields>;
  var_pop?: Maybe<User_Var_Pop_Fields>;
  var_samp?: Maybe<User_Var_Samp_Fields>;
  variance?: Maybe<User_Variance_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Avg_Fields = {
  __typename?: 'user_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint */
  UserPkey = 'user_pkey'
}

/** input type for incrementing numeric columns in table "user" */
export type User_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** on conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type User_Stddev_Fields = {
  __typename?: 'user_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Stddev_Pop_Fields = {
  __typename?: 'user_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Stddev_Samp_Fields = {
  __typename?: 'user_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type User_Sum_Fields = {
  __typename?: 'user_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type User_Var_Pop_Fields = {
  __typename?: 'user_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Var_Samp_Fields = {
  __typename?: 'user_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Variance_Fields = {
  __typename?: 'user_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "vw_final_costs" */
export type Vw_Final_Costs = {
  __typename?: 'vw_final_costs';
  dealer_cost?: Maybe<Scalars['numeric']>;
  msrp?: Maybe<Scalars['numeric']>;
  product_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "vw_final_costs" */
export type Vw_Final_Costs_Aggregate = {
  __typename?: 'vw_final_costs_aggregate';
  aggregate?: Maybe<Vw_Final_Costs_Aggregate_Fields>;
  nodes: Array<Vw_Final_Costs>;
};

/** aggregate fields of "vw_final_costs" */
export type Vw_Final_Costs_Aggregate_Fields = {
  __typename?: 'vw_final_costs_aggregate_fields';
  avg?: Maybe<Vw_Final_Costs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vw_Final_Costs_Max_Fields>;
  min?: Maybe<Vw_Final_Costs_Min_Fields>;
  stddev?: Maybe<Vw_Final_Costs_Stddev_Fields>;
  stddev_pop?: Maybe<Vw_Final_Costs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vw_Final_Costs_Stddev_Samp_Fields>;
  sum?: Maybe<Vw_Final_Costs_Sum_Fields>;
  var_pop?: Maybe<Vw_Final_Costs_Var_Pop_Fields>;
  var_samp?: Maybe<Vw_Final_Costs_Var_Samp_Fields>;
  variance?: Maybe<Vw_Final_Costs_Variance_Fields>;
};


/** aggregate fields of "vw_final_costs" */
export type Vw_Final_Costs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vw_Final_Costs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Vw_Final_Costs_Avg_Fields = {
  __typename?: 'vw_final_costs_avg_fields';
  dealer_cost?: Maybe<Scalars['Float']>;
  msrp?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "vw_final_costs". All fields are combined with a logical 'AND'. */
export type Vw_Final_Costs_Bool_Exp = {
  _and?: InputMaybe<Array<Vw_Final_Costs_Bool_Exp>>;
  _not?: InputMaybe<Vw_Final_Costs_Bool_Exp>;
  _or?: InputMaybe<Array<Vw_Final_Costs_Bool_Exp>>;
  dealer_cost?: InputMaybe<Numeric_Comparison_Exp>;
  msrp?: InputMaybe<Numeric_Comparison_Exp>;
  product_parameter_key_combination_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Vw_Final_Costs_Max_Fields = {
  __typename?: 'vw_final_costs_max_fields';
  dealer_cost?: Maybe<Scalars['numeric']>;
  msrp?: Maybe<Scalars['numeric']>;
  product_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Vw_Final_Costs_Min_Fields = {
  __typename?: 'vw_final_costs_min_fields';
  dealer_cost?: Maybe<Scalars['numeric']>;
  msrp?: Maybe<Scalars['numeric']>;
  product_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "vw_final_costs". */
export type Vw_Final_Costs_Order_By = {
  dealer_cost?: InputMaybe<Order_By>;
  msrp?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
};

/** select columns of table "vw_final_costs" */
export enum Vw_Final_Costs_Select_Column {
  /** column name */
  DealerCost = 'dealer_cost',
  /** column name */
  Msrp = 'msrp',
  /** column name */
  ProductParameterKeyCombinationId = 'product_parameter_key_combination_id'
}

/** aggregate stddev on columns */
export type Vw_Final_Costs_Stddev_Fields = {
  __typename?: 'vw_final_costs_stddev_fields';
  dealer_cost?: Maybe<Scalars['Float']>;
  msrp?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Vw_Final_Costs_Stddev_Pop_Fields = {
  __typename?: 'vw_final_costs_stddev_pop_fields';
  dealer_cost?: Maybe<Scalars['Float']>;
  msrp?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Vw_Final_Costs_Stddev_Samp_Fields = {
  __typename?: 'vw_final_costs_stddev_samp_fields';
  dealer_cost?: Maybe<Scalars['Float']>;
  msrp?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Vw_Final_Costs_Sum_Fields = {
  __typename?: 'vw_final_costs_sum_fields';
  dealer_cost?: Maybe<Scalars['numeric']>;
  msrp?: Maybe<Scalars['numeric']>;
  product_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Vw_Final_Costs_Var_Pop_Fields = {
  __typename?: 'vw_final_costs_var_pop_fields';
  dealer_cost?: Maybe<Scalars['Float']>;
  msrp?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Vw_Final_Costs_Var_Samp_Fields = {
  __typename?: 'vw_final_costs_var_samp_fields';
  dealer_cost?: Maybe<Scalars['Float']>;
  msrp?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Vw_Final_Costs_Variance_Fields = {
  __typename?: 'vw_final_costs_variance_fields';
  dealer_cost?: Maybe<Scalars['Float']>;
  msrp?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "vw_parameter_values" */
export type Vw_Parameter_Values = {
  __typename?: 'vw_parameter_values';
  parameter_id?: Maybe<Scalars['bigint']>;
  parameter_key?: Maybe<Scalars['String']>;
  parameter_key_id?: Maybe<Scalars['bigint']>;
  parameter_name?: Maybe<Scalars['String']>;
  parameter_type?: Maybe<Scalars['String']>;
  parameter_type_id?: Maybe<Scalars['bigint']>;
  product_code?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['bigint']>;
  product_name?: Maybe<Scalars['String']>;
  program_agent_code?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['bigint']>;
  program_name?: Maybe<Scalars['String']>;
  sort_order?: Maybe<Scalars['Int']>;
  value1?: Maybe<Scalars['String']>;
  value2?: Maybe<Scalars['String']>;
  value3?: Maybe<Scalars['String']>;
  value4?: Maybe<Scalars['String']>;
};

/** aggregated selection of "vw_parameter_values" */
export type Vw_Parameter_Values_Aggregate = {
  __typename?: 'vw_parameter_values_aggregate';
  aggregate?: Maybe<Vw_Parameter_Values_Aggregate_Fields>;
  nodes: Array<Vw_Parameter_Values>;
};

/** aggregate fields of "vw_parameter_values" */
export type Vw_Parameter_Values_Aggregate_Fields = {
  __typename?: 'vw_parameter_values_aggregate_fields';
  avg?: Maybe<Vw_Parameter_Values_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vw_Parameter_Values_Max_Fields>;
  min?: Maybe<Vw_Parameter_Values_Min_Fields>;
  stddev?: Maybe<Vw_Parameter_Values_Stddev_Fields>;
  stddev_pop?: Maybe<Vw_Parameter_Values_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vw_Parameter_Values_Stddev_Samp_Fields>;
  sum?: Maybe<Vw_Parameter_Values_Sum_Fields>;
  var_pop?: Maybe<Vw_Parameter_Values_Var_Pop_Fields>;
  var_samp?: Maybe<Vw_Parameter_Values_Var_Samp_Fields>;
  variance?: Maybe<Vw_Parameter_Values_Variance_Fields>;
};


/** aggregate fields of "vw_parameter_values" */
export type Vw_Parameter_Values_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vw_Parameter_Values_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Vw_Parameter_Values_Avg_Fields = {
  __typename?: 'vw_parameter_values_avg_fields';
  parameter_id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  parameter_type_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "vw_parameter_values". All fields are combined with a logical 'AND'. */
export type Vw_Parameter_Values_Bool_Exp = {
  _and?: InputMaybe<Array<Vw_Parameter_Values_Bool_Exp>>;
  _not?: InputMaybe<Vw_Parameter_Values_Bool_Exp>;
  _or?: InputMaybe<Array<Vw_Parameter_Values_Bool_Exp>>;
  parameter_id?: InputMaybe<Bigint_Comparison_Exp>;
  parameter_key?: InputMaybe<String_Comparison_Exp>;
  parameter_key_id?: InputMaybe<Bigint_Comparison_Exp>;
  parameter_name?: InputMaybe<String_Comparison_Exp>;
  parameter_type?: InputMaybe<String_Comparison_Exp>;
  parameter_type_id?: InputMaybe<Bigint_Comparison_Exp>;
  product_code?: InputMaybe<String_Comparison_Exp>;
  product_id?: InputMaybe<Bigint_Comparison_Exp>;
  product_name?: InputMaybe<String_Comparison_Exp>;
  program_agent_code?: InputMaybe<String_Comparison_Exp>;
  program_id?: InputMaybe<Bigint_Comparison_Exp>;
  program_name?: InputMaybe<String_Comparison_Exp>;
  sort_order?: InputMaybe<Int_Comparison_Exp>;
  value1?: InputMaybe<String_Comparison_Exp>;
  value2?: InputMaybe<String_Comparison_Exp>;
  value3?: InputMaybe<String_Comparison_Exp>;
  value4?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Vw_Parameter_Values_Max_Fields = {
  __typename?: 'vw_parameter_values_max_fields';
  parameter_id?: Maybe<Scalars['bigint']>;
  parameter_key?: Maybe<Scalars['String']>;
  parameter_key_id?: Maybe<Scalars['bigint']>;
  parameter_name?: Maybe<Scalars['String']>;
  parameter_type?: Maybe<Scalars['String']>;
  parameter_type_id?: Maybe<Scalars['bigint']>;
  product_code?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['bigint']>;
  product_name?: Maybe<Scalars['String']>;
  program_agent_code?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['bigint']>;
  program_name?: Maybe<Scalars['String']>;
  sort_order?: Maybe<Scalars['Int']>;
  value1?: Maybe<Scalars['String']>;
  value2?: Maybe<Scalars['String']>;
  value3?: Maybe<Scalars['String']>;
  value4?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Vw_Parameter_Values_Min_Fields = {
  __typename?: 'vw_parameter_values_min_fields';
  parameter_id?: Maybe<Scalars['bigint']>;
  parameter_key?: Maybe<Scalars['String']>;
  parameter_key_id?: Maybe<Scalars['bigint']>;
  parameter_name?: Maybe<Scalars['String']>;
  parameter_type?: Maybe<Scalars['String']>;
  parameter_type_id?: Maybe<Scalars['bigint']>;
  product_code?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['bigint']>;
  product_name?: Maybe<Scalars['String']>;
  program_agent_code?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['bigint']>;
  program_name?: Maybe<Scalars['String']>;
  sort_order?: Maybe<Scalars['Int']>;
  value1?: Maybe<Scalars['String']>;
  value2?: Maybe<Scalars['String']>;
  value3?: Maybe<Scalars['String']>;
  value4?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "vw_parameter_values". */
export type Vw_Parameter_Values_Order_By = {
  parameter_id?: InputMaybe<Order_By>;
  parameter_key?: InputMaybe<Order_By>;
  parameter_key_id?: InputMaybe<Order_By>;
  parameter_name?: InputMaybe<Order_By>;
  parameter_type?: InputMaybe<Order_By>;
  parameter_type_id?: InputMaybe<Order_By>;
  product_code?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  program_agent_code?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
  program_name?: InputMaybe<Order_By>;
  sort_order?: InputMaybe<Order_By>;
  value1?: InputMaybe<Order_By>;
  value2?: InputMaybe<Order_By>;
  value3?: InputMaybe<Order_By>;
  value4?: InputMaybe<Order_By>;
};

/** select columns of table "vw_parameter_values" */
export enum Vw_Parameter_Values_Select_Column {
  /** column name */
  ParameterId = 'parameter_id',
  /** column name */
  ParameterKey = 'parameter_key',
  /** column name */
  ParameterKeyId = 'parameter_key_id',
  /** column name */
  ParameterName = 'parameter_name',
  /** column name */
  ParameterType = 'parameter_type',
  /** column name */
  ParameterTypeId = 'parameter_type_id',
  /** column name */
  ProductCode = 'product_code',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductName = 'product_name',
  /** column name */
  ProgramAgentCode = 'program_agent_code',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  ProgramName = 'program_name',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  Value1 = 'value1',
  /** column name */
  Value2 = 'value2',
  /** column name */
  Value3 = 'value3',
  /** column name */
  Value4 = 'value4'
}

/** aggregate stddev on columns */
export type Vw_Parameter_Values_Stddev_Fields = {
  __typename?: 'vw_parameter_values_stddev_fields';
  parameter_id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  parameter_type_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Vw_Parameter_Values_Stddev_Pop_Fields = {
  __typename?: 'vw_parameter_values_stddev_pop_fields';
  parameter_id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  parameter_type_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Vw_Parameter_Values_Stddev_Samp_Fields = {
  __typename?: 'vw_parameter_values_stddev_samp_fields';
  parameter_id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  parameter_type_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Vw_Parameter_Values_Sum_Fields = {
  __typename?: 'vw_parameter_values_sum_fields';
  parameter_id?: Maybe<Scalars['bigint']>;
  parameter_key_id?: Maybe<Scalars['bigint']>;
  parameter_type_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  program_id?: Maybe<Scalars['bigint']>;
  sort_order?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Vw_Parameter_Values_Var_Pop_Fields = {
  __typename?: 'vw_parameter_values_var_pop_fields';
  parameter_id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  parameter_type_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Vw_Parameter_Values_Var_Samp_Fields = {
  __typename?: 'vw_parameter_values_var_samp_fields';
  parameter_id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  parameter_type_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Vw_Parameter_Values_Variance_Fields = {
  __typename?: 'vw_parameter_values_variance_fields';
  parameter_id?: Maybe<Scalars['Float']>;
  parameter_key_id?: Maybe<Scalars['Float']>;
  parameter_type_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
  sort_order?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "vw_product_parameter_key_combination_dealer_cost_rounding" */
export type Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding = {
  __typename?: 'vw_product_parameter_key_combination_dealer_cost_rounding';
  id?: Maybe<Scalars['bigint']>;
  rounding_value?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "vw_product_parameter_key_combination_dealer_cost_rounding" */
export type Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Aggregate = {
  __typename?: 'vw_product_parameter_key_combination_dealer_cost_rounding_aggregate';
  aggregate?: Maybe<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Aggregate_Fields>;
  nodes: Array<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding>;
};

/** aggregate fields of "vw_product_parameter_key_combination_dealer_cost_rounding" */
export type Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Aggregate_Fields = {
  __typename?: 'vw_product_parameter_key_combination_dealer_cost_rounding_aggregate_fields';
  avg?: Maybe<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Max_Fields>;
  min?: Maybe<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Min_Fields>;
  stddev?: Maybe<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Stddev_Fields>;
  stddev_pop?: Maybe<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Stddev_Samp_Fields>;
  sum?: Maybe<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Sum_Fields>;
  var_pop?: Maybe<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Var_Pop_Fields>;
  var_samp?: Maybe<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Var_Samp_Fields>;
  variance?: Maybe<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Variance_Fields>;
};


/** aggregate fields of "vw_product_parameter_key_combination_dealer_cost_rounding" */
export type Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Avg_Fields = {
  __typename?: 'vw_product_parameter_key_combination_dealer_cost_rounding_avg_fields';
  id?: Maybe<Scalars['Float']>;
  rounding_value?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "vw_product_parameter_key_combination_dealer_cost_rounding". All fields are combined with a logical 'AND'. */
export type Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Bool_Exp = {
  _and?: InputMaybe<Array<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Bool_Exp>>;
  _not?: InputMaybe<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Bool_Exp>;
  _or?: InputMaybe<Array<Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  rounding_value?: InputMaybe<Numeric_Comparison_Exp>;
  total?: InputMaybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Max_Fields = {
  __typename?: 'vw_product_parameter_key_combination_dealer_cost_rounding_max_fields';
  id?: Maybe<Scalars['bigint']>;
  rounding_value?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Min_Fields = {
  __typename?: 'vw_product_parameter_key_combination_dealer_cost_rounding_min_fields';
  id?: Maybe<Scalars['bigint']>;
  rounding_value?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
};

/** Ordering options when selecting data from "vw_product_parameter_key_combination_dealer_cost_rounding". */
export type Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Order_By = {
  id?: InputMaybe<Order_By>;
  rounding_value?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** select columns of table "vw_product_parameter_key_combination_dealer_cost_rounding" */
export enum Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  RoundingValue = 'rounding_value',
  /** column name */
  Total = 'total'
}

/** aggregate stddev on columns */
export type Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Stddev_Fields = {
  __typename?: 'vw_product_parameter_key_combination_dealer_cost_rounding_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  rounding_value?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Stddev_Pop_Fields = {
  __typename?: 'vw_product_parameter_key_combination_dealer_cost_rounding_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rounding_value?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Stddev_Samp_Fields = {
  __typename?: 'vw_product_parameter_key_combination_dealer_cost_rounding_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rounding_value?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Sum_Fields = {
  __typename?: 'vw_product_parameter_key_combination_dealer_cost_rounding_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  rounding_value?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Var_Pop_Fields = {
  __typename?: 'vw_product_parameter_key_combination_dealer_cost_rounding_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  rounding_value?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Var_Samp_Fields = {
  __typename?: 'vw_product_parameter_key_combination_dealer_cost_rounding_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  rounding_value?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Vw_Product_Parameter_Key_Combination_Dealer_Cost_Rounding_Variance_Fields = {
  __typename?: 'vw_product_parameter_key_combination_dealer_cost_rounding_variance_fields';
  id?: Maybe<Scalars['Float']>;
  rounding_value?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "vw_rate_bucket" */
export type Vw_Rate_Bucket = {
  __typename?: 'vw_rate_bucket';
  bucket_id?: Maybe<Scalars['bigint']>;
  bucket_name?: Maybe<Scalars['String']>;
  bucket_rate_id?: Maybe<Scalars['bigint']>;
  cms_bucket_number?: Maybe<Scalars['smallint']>;
  dealer_tier?: Maybe<Scalars['String']>;
  payee_code?: Maybe<Scalars['String']>;
  payee_id?: Maybe<Scalars['bigint']>;
  product_code?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
  program_agent_code?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['bigint']>;
  program_name?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "vw_rate_bucket" */
export type Vw_Rate_Bucket_Aggregate = {
  __typename?: 'vw_rate_bucket_aggregate';
  aggregate?: Maybe<Vw_Rate_Bucket_Aggregate_Fields>;
  nodes: Array<Vw_Rate_Bucket>;
};

/** aggregate fields of "vw_rate_bucket" */
export type Vw_Rate_Bucket_Aggregate_Fields = {
  __typename?: 'vw_rate_bucket_aggregate_fields';
  avg?: Maybe<Vw_Rate_Bucket_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Vw_Rate_Bucket_Max_Fields>;
  min?: Maybe<Vw_Rate_Bucket_Min_Fields>;
  stddev?: Maybe<Vw_Rate_Bucket_Stddev_Fields>;
  stddev_pop?: Maybe<Vw_Rate_Bucket_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vw_Rate_Bucket_Stddev_Samp_Fields>;
  sum?: Maybe<Vw_Rate_Bucket_Sum_Fields>;
  var_pop?: Maybe<Vw_Rate_Bucket_Var_Pop_Fields>;
  var_samp?: Maybe<Vw_Rate_Bucket_Var_Samp_Fields>;
  variance?: Maybe<Vw_Rate_Bucket_Variance_Fields>;
};


/** aggregate fields of "vw_rate_bucket" */
export type Vw_Rate_Bucket_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vw_Rate_Bucket_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Vw_Rate_Bucket_Avg_Fields = {
  __typename?: 'vw_rate_bucket_avg_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  bucket_rate_id?: Maybe<Scalars['Float']>;
  cms_bucket_number?: Maybe<Scalars['Float']>;
  payee_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "vw_rate_bucket". All fields are combined with a logical 'AND'. */
export type Vw_Rate_Bucket_Bool_Exp = {
  _and?: InputMaybe<Array<Vw_Rate_Bucket_Bool_Exp>>;
  _not?: InputMaybe<Vw_Rate_Bucket_Bool_Exp>;
  _or?: InputMaybe<Array<Vw_Rate_Bucket_Bool_Exp>>;
  bucket_id?: InputMaybe<Bigint_Comparison_Exp>;
  bucket_name?: InputMaybe<String_Comparison_Exp>;
  bucket_rate_id?: InputMaybe<Bigint_Comparison_Exp>;
  cms_bucket_number?: InputMaybe<Smallint_Comparison_Exp>;
  dealer_tier?: InputMaybe<String_Comparison_Exp>;
  payee_code?: InputMaybe<String_Comparison_Exp>;
  payee_id?: InputMaybe<Bigint_Comparison_Exp>;
  product_code?: InputMaybe<String_Comparison_Exp>;
  product_id?: InputMaybe<Bigint_Comparison_Exp>;
  product_parameter_key_combination_id?: InputMaybe<Bigint_Comparison_Exp>;
  program_agent_code?: InputMaybe<String_Comparison_Exp>;
  program_id?: InputMaybe<Bigint_Comparison_Exp>;
  program_name?: InputMaybe<String_Comparison_Exp>;
  total?: InputMaybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Vw_Rate_Bucket_Max_Fields = {
  __typename?: 'vw_rate_bucket_max_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  bucket_name?: Maybe<Scalars['String']>;
  bucket_rate_id?: Maybe<Scalars['bigint']>;
  cms_bucket_number?: Maybe<Scalars['smallint']>;
  dealer_tier?: Maybe<Scalars['String']>;
  payee_code?: Maybe<Scalars['String']>;
  payee_id?: Maybe<Scalars['bigint']>;
  product_code?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
  program_agent_code?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['bigint']>;
  program_name?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Vw_Rate_Bucket_Min_Fields = {
  __typename?: 'vw_rate_bucket_min_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  bucket_name?: Maybe<Scalars['String']>;
  bucket_rate_id?: Maybe<Scalars['bigint']>;
  cms_bucket_number?: Maybe<Scalars['smallint']>;
  dealer_tier?: Maybe<Scalars['String']>;
  payee_code?: Maybe<Scalars['String']>;
  payee_id?: Maybe<Scalars['bigint']>;
  product_code?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
  program_agent_code?: Maybe<Scalars['String']>;
  program_id?: Maybe<Scalars['bigint']>;
  program_name?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['numeric']>;
};

/** Ordering options when selecting data from "vw_rate_bucket". */
export type Vw_Rate_Bucket_Order_By = {
  bucket_id?: InputMaybe<Order_By>;
  bucket_name?: InputMaybe<Order_By>;
  bucket_rate_id?: InputMaybe<Order_By>;
  cms_bucket_number?: InputMaybe<Order_By>;
  dealer_tier?: InputMaybe<Order_By>;
  payee_code?: InputMaybe<Order_By>;
  payee_id?: InputMaybe<Order_By>;
  product_code?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_parameter_key_combination_id?: InputMaybe<Order_By>;
  program_agent_code?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
  program_name?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** select columns of table "vw_rate_bucket" */
export enum Vw_Rate_Bucket_Select_Column {
  /** column name */
  BucketId = 'bucket_id',
  /** column name */
  BucketName = 'bucket_name',
  /** column name */
  BucketRateId = 'bucket_rate_id',
  /** column name */
  CmsBucketNumber = 'cms_bucket_number',
  /** column name */
  DealerTier = 'dealer_tier',
  /** column name */
  PayeeCode = 'payee_code',
  /** column name */
  PayeeId = 'payee_id',
  /** column name */
  ProductCode = 'product_code',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductParameterKeyCombinationId = 'product_parameter_key_combination_id',
  /** column name */
  ProgramAgentCode = 'program_agent_code',
  /** column name */
  ProgramId = 'program_id',
  /** column name */
  ProgramName = 'program_name',
  /** column name */
  Total = 'total'
}

/** aggregate stddev on columns */
export type Vw_Rate_Bucket_Stddev_Fields = {
  __typename?: 'vw_rate_bucket_stddev_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  bucket_rate_id?: Maybe<Scalars['Float']>;
  cms_bucket_number?: Maybe<Scalars['Float']>;
  payee_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Vw_Rate_Bucket_Stddev_Pop_Fields = {
  __typename?: 'vw_rate_bucket_stddev_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  bucket_rate_id?: Maybe<Scalars['Float']>;
  cms_bucket_number?: Maybe<Scalars['Float']>;
  payee_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Vw_Rate_Bucket_Stddev_Samp_Fields = {
  __typename?: 'vw_rate_bucket_stddev_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  bucket_rate_id?: Maybe<Scalars['Float']>;
  cms_bucket_number?: Maybe<Scalars['Float']>;
  payee_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Vw_Rate_Bucket_Sum_Fields = {
  __typename?: 'vw_rate_bucket_sum_fields';
  bucket_id?: Maybe<Scalars['bigint']>;
  bucket_rate_id?: Maybe<Scalars['bigint']>;
  cms_bucket_number?: Maybe<Scalars['smallint']>;
  payee_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['bigint']>;
  product_parameter_key_combination_id?: Maybe<Scalars['bigint']>;
  program_id?: Maybe<Scalars['bigint']>;
  total?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Vw_Rate_Bucket_Var_Pop_Fields = {
  __typename?: 'vw_rate_bucket_var_pop_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  bucket_rate_id?: Maybe<Scalars['Float']>;
  cms_bucket_number?: Maybe<Scalars['Float']>;
  payee_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Vw_Rate_Bucket_Var_Samp_Fields = {
  __typename?: 'vw_rate_bucket_var_samp_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  bucket_rate_id?: Maybe<Scalars['Float']>;
  cms_bucket_number?: Maybe<Scalars['Float']>;
  payee_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Vw_Rate_Bucket_Variance_Fields = {
  __typename?: 'vw_rate_bucket_variance_fields';
  bucket_id?: Maybe<Scalars['Float']>;
  bucket_rate_id?: Maybe<Scalars['Float']>;
  cms_bucket_number?: Maybe<Scalars['Float']>;
  payee_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_parameter_key_combination_id?: Maybe<Scalars['Float']>;
  program_id?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

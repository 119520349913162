import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { IUpsertBucketRateExceptionsResponse } from './upsert-bucket-rate-exceptions.interface';
import {
  UpsertBucketRateException,
  UpsertBucketRateExceptionsVariables,
} from './upsert-bucket-rate-exceptions.variable';
import { upsertBucketRateExceptionsMutation } from './upsert-bucket-rate-exceptions.mutation';

export class UpsertBucketRateExceptionsData {
  static upsertBucketRateExceptions(
    apollo: Apollo,
    exceptions: UpsertBucketRateException[]
  ): Observable<IUpsertBucketRateExceptionsResponse> {
    return (
      apollo
        .mutate<
          IUpsertBucketRateExceptionsResponse,
          UpsertBucketRateExceptionsVariables
        >({
          mutation: upsertBucketRateExceptionsMutation,
          variables: { exceptions },
        })
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .pipe(map((res) => res.data!))
    );
  }
}

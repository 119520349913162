import { gql } from 'apollo-angular';

export interface AddProductVariables {
  product: ProductInput;
}

export interface ProductInput {
  code: string;
  description: string;
  name: string;
  risk_type: string;
  product_type_id: number;
  program_id: number;
}

export interface AddProductResponse {
  insert_product_one: InsertOneProductResponse;
}

export interface InsertOneProductResponse {
  id: number;
}

export const addProductMutation = gql`
  mutation AddProduct($product: product_insert_input!) {
    insert_product_one(object: $product) {
      id
    }
  }
`;

export interface AddParameterVariables {
  name: string;
  description: string;
  parameterTypeId: number;
}

export interface AddParameterResponse {
  insert_parameter_one: InsertOneParameterResponse;
}

export interface InsertOneParameterResponse {
  id: number;
  name: string;
  description: string;
  parameterTypeId: number;
}

export const addParameterMutation = gql`
  mutation AddParameter(
    $name: String!
    $description: String!
    $parameterTypeId: bigint!
  ) {
    insert_parameter_one(
      object: {
        name: $name
        description: $description
        parameter_type_id: $parameterTypeId
      }
    ) {
      id
      name
      description
      parameterTypeId: parameter_type_id
    }
  }
`;

import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UpsertProductSettingsResponse } from './upsert-product-settings.interface';
import { upsertProductSettingsMutation } from './upsert-product-settings.mutation';
import { UpsertProductSettingsVariable } from './upsert-product-settings.variable';

export class UpsertProductSettingsData {
  static upsertProductSettings(
    apollo: Apollo,
    productId: number,
    productTypeId: number,
    programId: number,
    name: string,
    description: string,
    code: string,
    riskType: string
  ): Observable<UpsertProductSettingsResponse> {
    const upsertVariable: UpsertProductSettingsVariable = {
      product: {
        id: productId,
        product_type_id: productTypeId,
        program_id: programId,
        code,
        description,
        name,
        risk_type: riskType,
      },
    };
    return (
      apollo
        .mutate<UpsertProductSettingsResponse>({
          mutation: upsertProductSettingsMutation,
          variables: upsertVariable,
        })
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .pipe(map((res) => res.data!))
    );
  }
}

import { ProductMsrpOperation } from '../../apollo/queries/products/get-product-rates/get-product-rates.interface';
import {
  roundDownTo,
  roundUpTo,
  roundToNearest,
  applyMaxMargin,
  applyMinMargin,
} from '@roadrunner/util-math';

export function calculateMsrp(
  total: number,
  operations: Record<string, ProductMsrpOperation>
) {
  let msrp = total;
  if (operations['Multiply']) {
    const operand = operations['Multiply'].operand;
    msrp = msrp * operand;
  }
  if (operations['AddBeforeMargin']) {
    const operand = operations['AddBeforeMargin'].operand;
    msrp = msrp + operand;
  }
  if (operations['MinMargin']) {
    const operand = operations['MinMargin'].operand;
    msrp = applyMinMargin(total, msrp, operand);
  }
  if (operations['MaxMargin']) {
    const operand = operations['MaxMargin'].operand;
    msrp = applyMaxMargin(total, msrp, operand);
  }
  if (operations['RoundUp']) {
    const operand = operations['RoundUp'].operand;
    msrp = roundUpTo(operand, msrp);
  }
  if (operations['RoundToNearest']) {
    const operand = operations['RoundToNearest'].operand;
    msrp = roundToNearest(operand, msrp);
  }
  if (operations['RoundDown']) {
    const operand = operations['RoundDown'].operand;
    msrp = roundDownTo(operand, msrp);
  }
  if (operations['AddAfterMargin']) {
    const operand = operations['AddAfterMargin'].operand;
    msrp += operand;
  }
  if (operations['Equals']) {
    const operand = operations['Equals'].operand;
    msrp = operand;
  }
  return msrp;
}

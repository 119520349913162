/**
 * Interface for the 'Parameter Type' data
 */
export interface ParameterType {
  id: number;
  description: string;
  type: string;
  bundleable: boolean;
}

export interface ParameterSubtype {
  id: number;
  parameterTypeId: number;
  subtype: string;
  // GraphQL treats this type as a string instead of an enum, so we allow both here even though in practice it'll always be a ControlType
  // TODO: can we fix graphql to properly return the enum type?
  controlType: ControlType | string;
  visible: boolean;
  isIdentifier: boolean;
  isUnique: boolean;
  isGlobalUnique: boolean;
  sortOrder: number;
  parameterSubtypeOptions: ParameterSubtypeOptions[];
}

export interface ParameterSubtypeOptions {
  id: number;
  parameterSubtypeId: number;
  name: string;
  sortOrder: number;
}

export interface GetAllParameterTypesResponse {
  parameterTypes: ParameterType[];
  parameterSubtypes: ParameterSubtype[];
}

export enum ControlType {
  Text = 'Text',
  Checkbox = 'Checkbox',
  SingleSelect = 'SingleSelect',
}

<roadrunner-dialog-header [headerText]="data.title"></roadrunner-dialog-header>
<mat-dialog-content>
  {{ data.message }}
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button cdkFocusInitial type="button" mat-button [matDialogClose]="false">
    No
  </button>
  <button
    type="button"
    mat-raised-button
    color="primary"
    [matDialogClose]="true"
  >
    Yes
  </button>
</mat-dialog-actions>

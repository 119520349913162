import { Injectable } from '@angular/core';
import { AuthConfig } from '@roadrunner/shared/auth';
import { LoggingConfig } from '@roadrunner/shared/client-logging';

@Injectable()
export class AppConfig {
  readonly api!: ApiConfig;
  readonly azure!: Omit<AuthConfig, 'redirectUri'>;
  readonly logRocket!: LoggingConfig;
  readonly enableReduxDevTools!: boolean;
  readonly rateLookupUrl: string | null = null;
}

@Injectable()
export class ApiConfig {
  readonly apiUrl!: string;
  readonly trpcUrl!: string;
  readonly graphqlUrl!: string;
}

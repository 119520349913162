import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UiToggleService {
  public isOpened = new BehaviorSubject<boolean>(false);

  public toggle() {   
    this.isOpened.next(!this.isOpened.value);    
  }
}

import { getSelectors, RouterReducerState } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
} = getSelectors((s: object) => (s as { router: RouterReducerState }).router);

export const selectQueryParamTabName = createSelector(
  selectQueryParam('tab'),
  (tab) => tab ?? ''
);

import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { selectBucket } from '../../store/bucket/bucket.selectors';
import * as BucketActions from '../../store/bucket/bucket.actions';

@Injectable({ providedIn: 'root' })
export class BucketGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    return this.getFromStoreOrApi(+route.params.id).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  private getFromStoreOrApi(bucketId: number) {
    return this.store.pipe(
      select(selectBucket),
      tap((data) => {
        if (!data || data.id !== bucketId) {
          this.store.dispatch(BucketActions.loadBucket({ bucketId }));
        }
      }),
      take(1)
    );
  }
}

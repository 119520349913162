<div class="w-full mx-auto">
  <div class="p-11">
    <div class="pb-4 text-center">
      <h2 class="text-2xl font-thin">Choose your program</h2>
    </div>
    <form [formGroup]="form" (ngSubmit)="chooseProgram()">
      <div class="flex items-center">
        <mat-form-field class="w-full grow-[9]">
          <mat-label>Select Program</mat-label>
          <input
            #searchElement
            type="text"
            matInput
            [matAutocomplete]="programAutocomplete"
            formControlName="program"
            required
            name="program"
            placeholder="Search for a program..."
            data-testid="choose-program-input"
          />
          <mat-autocomplete
            autoActiveFirstOption
            autoSelectActiveOption
            #programAutocomplete
            [displayWith]="displayProgram"
            data-testid="choose-program-program-autocomplete"
          >
            <mat-option
              *ngFor="let program of filteredPrograms$ | async"
              [value]="program"
            >
              {{ program.name }}
            </mat-option>
            <mat-option *ngIf="noPrograms$ | async" disabled>
              No Programs Found
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="form.hasError('required', 'program')">
            Please select a program
          </mat-error>
        </mat-form-field>
        <button
          type="button"
          class="grow-[1] !ml-1 !mb-[18px]"
          data-testid="add-program-button"
          mat-flat-button
          (click)="onAddProgramClick()"
        >
          Add Program
        </button>
      </div>

      <button
        mat-raised-button
        color="primary"
        class="w-full py-4 mt-3"
        [class.mat-button-disabled]="form.invalid"
        data-testid="choose-program-continue"
      >
        Continue
      </button>
    </form>
  </div>
</div>

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { uniqueValidator } from '@roadrunner/shared/util-forms';
import { startWith, Subject, takeUntil } from 'rxjs';

import {
  CopyProductDialogData,
  ProgramListItem,
} from './copy-product-dialog-data';
import { CopyProductDialogResult } from './copy-product-dialog-result';

@Component({
  selector: 'rating-copy-product',
  templateUrl: './copy-product.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyProductComponent implements OnDestroy, OnInit {
  form: UntypedFormGroup;

  headerText: string;

  programTriggerText = '';

  private codeControl = new UntypedFormControl(this.data.product.code);

  private programIdControl = new UntypedFormControl(
    this.data.programId,
    Validators.required
  );

  private destroyed$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CopyProductDialogData,
    private dialogRef: MatDialogRef<
      CopyProductDialogData,
      CopyProductDialogResult
    >
  ) {
    this.headerText = `Copy Product ${data.product.code}`;
    this.form = new UntypedFormGroup({
      programId: this.programIdControl,
      code: this.codeControl,
      name: new UntypedFormControl(data.product.name, Validators.required),
      description: new UntypedFormControl(data.product.description),
    });
  }

  ngOnInit(): void {
    const programs = new Map<number, ProgramListItem>(
      this.data.programs.map((p) => [p.id, p])
    );

    this.programIdControl.valueChanges
      .pipe(startWith(this.programIdControl.value), takeUntil(this.destroyed$))
      .subscribe((programId: number | null) => {
        const program = programs.get(programId as number);
        if (!program) {
          return;
        }
        this.codeControl.setValidators(
          this.buildCodeValidators(program.productCodes)
        );
        this.codeControl.updateValueAndValidity();

        this.programTriggerText = `${program.name} - ${program.agentCode}`;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.dialogRef.close({
        productId: this.data.product.id,
        programId: this.programIdControl.value,
        code: this.codeControl.value,
        name: this.form.value.name,
        description: this.form.value.description,
      });
    }
  }

  private buildCodeValidators(productCodes: string[]): ValidatorFn[] {
    return [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(4),
      uniqueValidator(productCodes),
    ];
  }
}
